/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AppErrorCrashUploadFile } from './app-error-crash-upload-file.model';

/**
 * AppErrorCrashUploadsTableResponse
 * @export
 * @class AppErrorCrashUploadsTableResponse
 */
@Serializable
export class AppErrorCrashUploadsTableResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AppErrorCrashUploadFile })
  public results: AppErrorCrashUploadFile[] = [];
}
