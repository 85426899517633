/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ROUTE_NAMES } from '@ws1c/intelligence-models/app.routes.names';
import { LazyComponentType } from '@ws1c/intelligence-models/lazy-load';
import { SourceObjectType } from '@ws1c/intelligence-models/source-object-type.enum';
import { NavigationPreviewModalButtonData } from './navigation-preview-modal-button-data.interface';
import { NavigationPreviewModalType } from './navigation-preview-modal-type.enum';

/**
 * NavigationPreviewModalConfig
 * @export
 * @class NavigationPreviewModalConfig
 */
export class NavigationPreviewModalConfig {
  public static readonly lazyComponentTypeByModalType: Record<NavigationPreviewModalType, LazyComponentType> = {
    [NavigationPreviewModalType.AUTOMATION]: LazyComponentType.AUTOMATION_NAV_PREVIEW_CONTENT,
    [NavigationPreviewModalType.INCIDENT]: LazyComponentType.INCIDENT_NAV_PREVIEW_CONTENT,
    [NavigationPreviewModalType.INSIGHT]: LazyComponentType.INSIGHT_NAV_PREVIEW_CONTENT,
    [NavigationPreviewModalType.SURVEY]: LazyComponentType.SURVEY_NAV_PREVIEW_CONTENT,
  };
  public static readonly buttonDataByModalType: Record<NavigationPreviewModalType, (id?: string) => NavigationPreviewModalButtonData> = {
    [NavigationPreviewModalType.AUTOMATION]: (id: string) => ({
      primary: {
        text: 'NAVIGATION.GO_TO_WORKFLOW',
        route: `/${ROUTE_NAMES.AUTOMATION.OVERVIEW(id)}`,
      },
      secondary: {
        text: 'NAVIGATION.VIEW_ALL_WORKFLOWS',
        route: `/${ROUTE_NAMES.AUTOMATION.AUTOMATION_WORKFLOW}`,
      },
    }),
    [NavigationPreviewModalType.INCIDENT]: (id: string) => ({
      primary: {
        text: 'NAVIGATION.GO_TO_INCIDENT',
        route: `/${ROUTE_NAMES.SOLUTIONS_DEEM_INCIDENTS_ID(id)}`,
      },
      secondary: {
        text: 'NAVIGATION.VIEW_ALL_INCIDENTS',
        route: `/${ROUTE_NAMES.SOLUTIONS_DEEM_INCIDENTS}`,
      },
    }),
    [NavigationPreviewModalType.INSIGHT]: (id: string) => ({
      primary: {
        text: 'NAVIGATION.GO_TO_INSIGHT',
        route: `/${ROUTE_NAMES.SOLUTIONS_DEEM_INSIGHTS_ID(id)}`,
      },
      secondary: {
        text: 'NAVIGATION.VIEW_ALL_INSIGHTS',
        route: `/${ROUTE_NAMES.SOLUTIONS_DEEM_INSIGHTS}`,
      },
    }),
    [NavigationPreviewModalType.SURVEY]: (id: string) => ({
      primary: {
        text: 'NAVIGATION.GO_TO_SURVEY',
        route: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SURVEYS_ID(id)}`,
      },
      secondary: {
        text: 'NAVIGATION.VIEW_ALL_SURVEYS',
        route: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SURVEYS}`,
      },
    }),
  };
  public static readonly modalTypeBySourceObjectType: Record<string, NavigationPreviewModalType> = {
    [SourceObjectType.INCIDENT]: NavigationPreviewModalType.INCIDENT,
    [SourceObjectType.SURVEY]: NavigationPreviewModalType.SURVEY,
  };
}
