/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * PendoEventCustomDashboardsSelectedSource
 * @export
 * @enum {string}
 */
export enum PendoEventCustomDashboardsSelectedSource {
  VIEW_DASHBOARD = 'View Dashboard',
  VIEW_WIDGET = 'View Widget',
}
