/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * UagDashboardType
 * @export
 * @enum {string}
 */
export enum UagDashboardType {
  // For UAG Tunnel Dashboard
  UAG_TUNNEL = 'UAG_TUNNEL',
}

/**
 * UagWidgetSubtype
 * @export
 * @enum {string}
 */
export enum UagWidgetSubtype {
  // For UAG Tunnel Dashboard
  TUNNEL_DESTINATION_TRAFFIC_LAST_7_DAYS = 'TUNNEL_DESTINATION_TRAFFIC_LAST_7_DAYS',
  BYTES_UPLOADED_BY_DESTINATION_LAST_7_DAYS = 'BYTES_UPLOADED_BY_DESTINATION_LAST_7_DAYS',
  TUNNEL_APPLICATIONS_LAST_7_DAYS = 'TUNNEL_APPLICATIONS_LAST_7_DAYS',
  BYTES_UPLOADED_BY_APPLICATION_LAST_7_DAYS = 'BYTES_UPLOADED_BY_APPLICATION_LAST_7_DAYS',
  TUNNEL_SESSIONS_PER_DEVICE_LAST_7_DAYS = 'TUNNEL_SESSIONS_PER_DEVICE_LAST_7_DAYS',
  BYTES_UPLOADED_PER_DEVICE_LAST_7_DAYS = 'BYTES_UPLOADED_PER_DEVICE_LAST_7_DAYS',
  SESSIONS_PER_USER_LAST_7_DAYS = 'SESSIONS_PER_USER_LAST_7_DAYS',
  USERS_WITH_MULTIPLE_DEVICES_LAST_7_DAYS = 'USERS_WITH_MULTIPLE_DEVICES_LAST_7_DAYS',
  DESTINATION_PORT_DISTRIBUTION_LAST_7_DAYS = 'DESTINATION_PORT_DISTRIBUTION_LAST_7_DAYS',
  TRAFFIC_DISTRIBUTION_ACROSS_UAG_LAST_14_DAYS = 'TRAFFIC_DISTRIBUTION_ACROSS_UAG_LAST_14_DAYS',
  TUNNEL_USERS_LAST_7_DAYS = 'TUNNEL_USERS_LAST_7_DAYS',
  TUNNEL_DEVICES_LAST_7_DAYS = 'TUNNEL_DEVICES_LAST_7_DAYS',
  TUNNEL_APPS_LAST_7_DAYS = 'TUNNEL_APPS_LAST_7_DAYS',
  TUNNEL_SESSIONS_LAST_7_DAYS = 'TUNNEL_SESSIONS_LAST_7_DAYS',
  TUNNEL_TOTAL_USERS_LAST_7_DAYS = 'TUNNEL_TOTAL_USERS_LAST_7_DAYS',
  TUNNEL_TOTAL_DEVICES_LAST_7_DAYS = 'TUNNEL_TOTAL_DEVICES_LAST_7_DAYS',
  TUNNEL_TOTAL_APPS_LAST_7_DAYS = 'TUNNEL_TOTAL_APPS_LAST_7_DAYS',
  TUNNEL_TOTAL_SESSIONS_LAST_7_DAYS = 'TUNNEL_TOTAL_SESSIONS_LAST_7_DAYS',
}
