/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * LodashSortOrder
 * used by lodash's orderBy function
 * @export
 * @enum {number}
 */
export enum LodashSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
