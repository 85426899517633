/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * PendoMetadata
 * @export
 */
export class PendoMetadata {
  public donotprocess: boolean = false;
  public blacklistguides: boolean = false;

  /**
   * Creates an instance of PendoMetadata
   * @param {Array<Partial<PendoMetadata>>} args
   */
  constructor(...args: Array<Partial<PendoMetadata>>) {
    Object.assign(this, ...args);
  }
}

/**
 * PendoMetadataFields
 * @export
 * @enum {string}
 */
export enum PendoMetadataFields {
  do_not_process = 'donotprocess',
  blacklist_guides = 'blacklistguides',
}
