/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AppErrorCrashUploadFile
 * @export
 * @class AppErrorCrashUploadFile
 */
@Serializable
export class AppErrorCrashUploadFile {
  @JsonProperty('app_platform')
  public appPlatform: string = undefined;

  @JsonProperty('app_version')
  public appVersion: string = undefined;

  @JsonProperty('apteligent_app_id')
  public apteligentAppID: string = undefined;

  @JsonProperty('backfill_processed')
  public backfillProcessed: string = undefined;

  @JsonProperty('file_exists')
  public fileExists: boolean = undefined;

  @JsonProperty('created_at')
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty('dsym_id')
  public dsymId: string = undefined;

  @JsonProperty('id')
  public id: string = undefined;
}
