/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { v5 as uuidv5 } from 'uuid';

/**
 * Bookmark Model
 *
 * @export
 * @class Bookmark
 */
@Serializable
export class Bookmark {
  @JsonProperty('category')
  public category: string = undefined;

  @JsonProperty('feature_key')
  public featureKey: string = undefined;

  @JsonProperty('is_resource_object')
  public isResourceObject: boolean = undefined;

  @JsonProperty('resource_id')
  public resourceId: string = undefined;

  @JsonProperty('resource_path')
  public resourcePath: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  /**
   * Creates an instance of Bookmark
   * @param {Array<Partial<Bookmark>>} args - Initialization arguments for bookmark object
   * @memberof Bookmark
   */
  constructor(...args: Array<Partial<Bookmark>>) {
    Object.assign(this, ...args);
  }

  /**
   * Returns bookmark instance populated with fallback values for
   * properties for which values were not provided
   * @param {string} bookmarkPath - Holds current route path to be bookmarked
   * @returns {Bookmark}
   * @memberof Bookmark
   */
  public getPayloadWithFallbackValues(bookmarkPath: string): Bookmark {
    const resourcePath = this.resourcePath || bookmarkPath;

    return new Bookmark({
      ...this,
      isResourceObject: this.isResourceObject ?? !!this.resourceId,
      resourceId: this.resourceId || uuidv5(resourcePath, uuidv5.URL),
      resourcePath,
    });
  }
}
