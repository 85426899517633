/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

import { ReportTemplate } from './report-template.model';

/**
 * ReportTemplateSearchResponse
 *
 * @export
 * @class ReportTemplateSearchResponse
 * @implements {PagedResponse}
 */
export class ReportTemplateSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: ReportTemplate })
  public results: ReportTemplate[] = [];

  /**
   * Creates an instance of ReportTemplateSearchResponse
   * @param {Array<Partial<ReportTemplateSearchResponse>>} args
   * @memberof ReportTemplateSearchResponse
   */
  constructor(...args: Array<Partial<ReportTemplateSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
