/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Org Detail Model Object
 *
 * @export
 * @class OrgHealthMetric
 */
@Serializable
export class OrgDetail {
  @JsonProperty('id')
  public value: number = undefined;

  @JsonProperty('name')
  public text: string = undefined;

  /**
   * Creates an instance of OrgDetail.
   * @param {Array<Partial<OrgDetail>>} args
   * @memberof OrgDetail
   */
  constructor(...args: Array<Partial<OrgDetail>>) {
    Object.assign(this, ...args);
  }
}
