/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { stripNonNumericConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';

/**
 * Trial Address Model
 *
 * @export
 * @class PostalAddress
 */
@Serializable
export class PostalAddress {
  @JsonProperty('address')
  public address: string = undefined;

  @JsonProperty('city')
  public city: string = undefined;

  @JsonProperty('state')
  public state: string = undefined;

  @JsonProperty('country')
  public country: string = undefined;

  @JsonProperty('postal_code')
  public postalCode: string = undefined;

  /**
   * Creates an instance of PostalAddress
   * @param {any} args
   * @memberof PostalAddress
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * Accept Trial Model Object
 *
 * @export
 * @class AcceptTrial
 */
@Serializable
export class AcceptTrial {
  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  @JsonProperty('email_address')
  public emailAddress: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('company_name')
  public companyName: string = undefined;

  @JsonProperty({ name: 'postal_address', cls: PostalAddress })
  public postalAddress: PostalAddress = undefined;

  @JsonProperty({ name: 'telephone_number', customConverter: stripNonNumericConverterFactory() })
  public phoneNumber: string = undefined;

  /**
   * Creates an instance of AcceptTrial
   * @param {any} args
   * @memberof AcceptTrial
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
