/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { WidgetTemplate } from '@ws1c/intelligence-models/template/widget/widget-template.model';

/**
 * Widget Template Response Model Object
 * Response format for list widget templates API
 *
 * @export
 * @class WidgetTemplateSearchResponse
 */
@Serializable
export class WidgetTemplateSearchResponse {
  @JsonProperty({ name: 'data', cls: WidgetTemplate })
  public data: WidgetTemplate[] = [];

  /**
   * Creates an instance of WidgetTemplateSearchResponse.
   * @param {...Array<Partial<WidgetTemplateSearchResponse>>} args
   * @memberof WidgetTemplateSearchResponse
   */
  constructor(...args: Array<Partial<WidgetTemplateSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
