/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

export const ALERT_BANNER_TYPE = {
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  DANGER: 'danger',
};
