/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';

/**
 * MostRecent
 * @export
 * @class MostRecent
 */
@Serializable
export class MostRecent {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;
}

/**
 * WidgetSummary
 * @export
 * @class WidgetSummary
 */
@Serializable
export class WidgetSummary {
  @JsonProperty('total_widgets')
  public total: number = undefined;

  @JsonProperty({ name: 'most_recent', cls: MostRecent })
  public mostRecent: MostRecent = undefined;
}
