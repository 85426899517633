/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * UserProfileTabType
 * @export
 * @enum {string}
 */
export enum UserProfileTabType {
  OVERVIEW = 'overview',
  EXPERIENCE = 'experience',
  SECURITY = 'security',
}
