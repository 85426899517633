/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { each } from 'lodash-es';

import { DataAccessPolicyAssignPreview } from './data-access-policy-assign-preview.model';
import { DataAccessPolicy } from './data-access-policy.model';

/**
 * dataAccessPolicyAssignPreviewConverter
 * @export
 * @type {CustomConverter}
 */
export const dataAccessPolicyAssignPreviewConverter: CustomConverter = {
  fromJson(data: GenericObject): DataAccessPolicyAssignPreview {
    const previewObject = data[Object.keys(data)[0]];
    const accountsToMove = {};
    const accountsToRemove = previewObject.accounts_to_remove;
    each(previewObject.accounts_to_move, (value: any[], key) => {
      accountsToMove[key] = value.map((dapObject: any) => deserialize(DataAccessPolicy, dapObject));
    });
    return new DataAccessPolicyAssignPreview({
      accountsToMove,
      accountsToRemove,
    });
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * DataAccessPolicyAssignPreviewResponse
 * @export
 * @class DataAccessPolicyAssignPreviewResponse
 */
@Serializable
export class DataAccessPolicyAssignPreviewResponse {
  @JsonProperty({ name: 'data', customConverter: dataAccessPolicyAssignPreviewConverter })
  public preview: DataAccessPolicyAssignPreview = undefined;

  /**
   * Creates an instance of DataAccessPolicyAssignPreviewResponse.
   * @param {Array<Partial<DataAccessPolicyAssignPreviewResponse>>} args
   * @memberof DataAccessPolicyAssignPreviewResponse
   */
  constructor(...args: Array<Partial<DataAccessPolicyAssignPreviewResponse>>) {
    Object.assign(this, ...args);
  }
}
