/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { AutomationRunHistoryStatus } from './automation-run-history-status.interface';

/**
 * executionStatusConverter
 * @export
 * @type {CustomConverter}
 */
export const executionStatusConverter: CustomConverter = {
  fromJson(data: GenericObject[]): AutomationRunHistoryStatus | undefined {
    if (!data || !Object.keys(data).length) {
      return;
    }
    return Object.keys(data).reduce((acc: AutomationRunHistoryStatus, key) => {
      acc[data[key].execution_status] = data[key].count;
      return acc;
    }, {});
  },
  toJson(): GenericObject {
    return undefined;
  },
};

/**
 * AutomationRunHistory
 *
 * @export
 * @class AutomationRunHistory
 */
@Serializable
export class AutomationRunHistory {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('active')
  public active: boolean = false;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'execution_statuses', customConverter: executionStatusConverter, excludeToJson: true })
  public executionStatuses: AutomationRunHistoryStatus = undefined;

  /**
   * Creates an instance of AutomationRunHistory.
   * @param {...Array<Partial<AutomationRunHistory>>} args
   * @memberof ConnectorAdminActivity
   */
  constructor(...args: Array<Partial<AutomationRunHistory>>) {
    Object.assign(this, ...args);
  }
}
