/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Deserialize, JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory, filterConditionConverter, filterConditionToRuleGroupConverter } from '@ws1c/intelligence-models/converters';
import { RuleGroup } from '@ws1c/intelligence-models/filter';

export enum ConditionType {
  SIMPLE_FILTER = 'SIMPLE_FILTER',
}

/**
 * AutomationCondition
 * @export
 * @class AutomationCondition
 */
@Serializable
export class AutomationCondition {
  @JsonProperty({ name: 'type', customConverter: enumConverterFactory(ConditionType) })
  public conditionType: string = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', customConverter: filterConditionConverter, excludeToJson: true })
  public filterCondition: any = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', customConverter: filterConditionToRuleGroupConverter, excludeToJson: true })
  public ruleGroup?: RuleGroup = undefined;

  /**
   * Creates an instance of AutomationCondition
   *
   * @param {Array<Partial<AutomationCondition>>} args
   * @memberof AutomationCondition
   */
  constructor(...args: Array<Partial<AutomationCondition>>) {
    Object.assign(this, ...args);
  }

  /**
   * updateConditionType
   * @memberof AutomationCondition
   */
  @Deserialize.after()
  public updateConditionType() {
    this.conditionType = ConditionType.SIMPLE_FILTER;
  }
}
