/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * LOAD_STATE
 * @exports
 * @enum {string}
 */
export enum LOAD_STATE {
  NONE,
  IN_FLIGHT,
  SUCCESS,
  FAILURE,
}
