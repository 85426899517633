/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from './converters/date-format.converter';
import { GenericSearchRequest } from './generic-search-request.model';
import { UserDetails } from './user-details.model';

/**
 * Action taken as specified in an audit-able event.
 * @export
 * @enum {number}
 */
export enum AuditAction {
  CREATED,
  DELETED,
  DOWNLOADED,
  UPDATED,
  GENERATION,
  UNKNOWN,
}

/**
 * AuditEventType
 * @export
 * @enum {string}
 */
export enum AuditEventType {
  CREATE = 'CREATE',
}

/**
 * Object type specified in an audit log record.
 * @export
 * @enum {number}
 */
export enum AuditObjectType {
  INCIDENT,
  REPORT,
  REPORT_GENERATION,
  REPORT_SCHEDULE,
  REPORT_DOWNLOAD,
  UNKNOWN,
}

/**
 * Schedule type specified in an audit log record
 *
 * @export
 * @enum {number}
 */
export enum AuditScheduleType {
  ADHOC,
  SCHEDULED,
}

/**
 * Audit Log Record.
 * @export
 * @class AuditLog
 */
@Serializable
export class AuditLog {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('object_type')
  public objectType: string = undefined;

  @JsonProperty('action')
  public action: string = undefined;

  @JsonProperty('report_id')
  public reportId: string = undefined;

  @JsonProperty('report_name')
  public reportName: string = undefined;

  @JsonProperty('report_exists')
  public reportExists: boolean = undefined;

  @JsonProperty('report_schedule_id')
  public reportScheduleId: string = undefined;

  @JsonProperty('report_schedule_name')
  public reportScheduleName: string = undefined;

  @JsonProperty('report_schedule_type')
  public reportScheduleType: string = undefined;

  @JsonProperty('report_tracking_id')
  public reportTrackingId: string = undefined;

  @JsonProperty('report_tracking_status')
  public reportTrackingStatus: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;
}

/**
 * AuditLogSearchRequest
 * @export
 * @class AuditLogSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class AuditLogSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'report_id', excludeToJson: true })
  public reportId?: string = undefined;

  /**
   * Creates an instance of AuditLogSearchRequest.
   * @param {any} args
   * @memberof AuditLogSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * AuditLogSearchResponse
 *
 * @export
 * @class AuditLogSearchResponse
 */
export class AuditLogSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AuditLog })
  public results: AuditLog[] = [];

  /**
   * Creates an instance of AuditLogSearchResponse.
   * @param {any} args
   * @memberof AuditLogSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
