/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { IntegrationFilterStatus } from './integration-filter-status.interface';

/**
 * AvailableEventForwarder
 * AvailableEventForwarder model is processed slightly differently as compared to backend JSON.
 * Apart from four properties which are directly required like id, name, status_check_success and
 * modified_at, all other properties will be pushed to metaFormFields to remove dependency of
 * maintaining all those form properties as they are meta-data (template based) driven.
 *
 * @export
 * @class AvailableEventForwarder
 */
@Serializable
export class AvailableEventForwarder {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('status_check_success')
  public statusCheckSuccess: boolean = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  public metaFormFields: GenericObject = undefined;

  /**
   * Creates an instance of AvailableEventForwarder
   * @param {any} args
   * @memberof AvailableEventForwarder
   */
  constructor(...args: Array<Partial<AvailableEventForwarder>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter to determine whether config is authorized
   * @readonly
   * @type {boolean}
   * @returns {boolean}
   * @memberof AvailableEventForwarder
   */
  public get isAuthorized(): boolean {
    return !!this.id;
  }

  /**
   * Getter to determine whether config is not connected
   * @readonly
   * @type {boolean}
   * @returns {boolean}
   * @memberof AvailableEventForwarder
   */
  public get isFailedConnection(): boolean {
    return this.isAuthorized && !this.statusCheckSuccess;
  }

  /**
   * Getter getStatusFilter
   * @readonly
   * @type {IntegrationFilterStatus}
   * @returns {IntegrationFilterStatus}
   * @memberof AvailableEventForwarder
   */
  public get getStatusFilter(): IntegrationFilterStatus {
    return { isAuthorized: this.isAuthorized, isFailedConnection: this.isFailedConnection };
  }
}
