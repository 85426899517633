/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedRequest, Serializable, SortOn } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';

/**
 * CommentsRequest
 * @export
 * @class CommentsRequest
 * @extends {PagedRequest}
 */
@Serializable
export class CommentsRequest extends PagedRequest {
  /**
   * The list of fields on which to sort the paged request.
   */
  @JsonProperty({ name: 'sort_ons', cls: SortOn })
  public sortOns?: SortOn[] = undefined;

  @JsonProperty('ignore_case')
  public ignoreCase: boolean = true;

  @JsonProperty({ name: 'resource_id', excludeToJson: true })
  public resourceId: string = undefined;

  /**
   * Generate a CommentsRequest from the given parameters
   * @param {string} resourceId
   * @param {number} from
   * @param {number} size
   * @returns {CommentsRequest}
   * @memberof CommentsRequest
   */
  public static generate(resourceId: string, from: number = 0, size: number = 100): CommentsRequest {
    return new CommentsRequest({
      resourceId,
      from,
      size,
      sortOns: [
        new SortOn({
          by: COLUMN_NAMES.byName.created_at,
          reverse: false,
        }),
      ],
    });
  }

  /**
   * Creates an instance of CommentsRequest.
   * @param {...Array<Partial<CommentsRequest>>} args
   * @memberof CommentsRequest
   */
  constructor(...args: Array<Partial<CommentsRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
