/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Language } from './language.enum';

/**
 * LANGUAGE_FILE_EXTENSIONS
 * @export
 * @enum {string}
 */
export const LANGUAGE_FILE_EXTENSIONS: Record<string, string> = {
  [Language.BASH]: 'sh',
  [Language.POWERSHELL]: 'ps1',
  [Language.PYTHON]: 'py',
  [Language.ZSH]: 'sh',
};
