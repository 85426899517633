/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, JsonProperty, Serializable } from '@dpa/ui-common';

import { AppCrashUser } from './app-crash-user.model';

/**
 * appCrashUsersDataConverter
 * @export
 * @type {CustomConverter}
 */
export const appCrashUsersDataConverter: CustomConverter = {
  fromJson(data: any): AppCrashUser[] {
    return data.users.map((userData: any) => deserialize(AppCrashUser, userData));
  },
  toJson(data: any): any {
    return data;
  },
};

/**
 * AppCrashUsersResponse
 * @export
 * @class AppCrashUsersResponse
 */
@Serializable
export class AppCrashUsersResponse {
  @JsonProperty({ name: 'data', customConverter: appCrashUsersDataConverter })
  public results: AppCrashUser[] = undefined;

  /**
   * Creates an instance of AppCrashUsersResponse.
   * @param {...Array<Partial<AppCrashUsersResponse>>} args
   * @memberof AppCrashUsersResponse
   */
  constructor(...args: Array<Partial<AppCrashUsersResponse>>) {
    Object.assign(this, ...args);
  }
}
