/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Solution
 * @export
 * @enum {string}
 */
export enum Solution {
  DEEM = 'deem',
  CVE = 'cve',
}

/**
 * SolutionConfigType
 * @export
 * @enum {string}
 */
export enum SolutionConfigType {
  CVE = 'cve',
  DEEM_V2 = 'deem_v2',
}

/**
 * SolutionSubmodule
 * @export
 * @enum {string}
 */
export enum SolutionSubmodule {
  DESKTOP_EXPERIENCE_MANAGEMENT = 'desktop_experience_management',
  MOBILE_EXPERIENCE_MANAGEMENT = 'mobile_experience_management',
  HORIZON_CLOUD_SERVICES = 'horizon_cloud_services',
  FRONTLINE_WORKER_MANAGEMENT = 'frontline_worker_management',
}
