/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { get, set } from 'lodash-es';

import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { BucketingAttribute } from './bucketing-attribute.model';
import { Counter } from './counter.model';

/**
 * Trend Result Model Object
 *
 * @export
 * @class TrendResult
 */
@Serializable
export class TrendResult {
  @JsonProperty('start_millis')
  public startMillis: number = undefined;

  @JsonProperty('end_millis')
  public endMillis: number = undefined;

  @JsonProperty({ name: 'bucketing_attributes', cls: BucketingAttribute })
  public bucketingAttributes: BucketingAttribute[] = undefined;

  @JsonProperty({ name: 'counters', cls: Counter })
  public counters: Counter[] = undefined;

  /**
   * constructor
   * @param {Array<Partial<TrendResult>>} args
   * @memberof TrendResult
   */
  constructor(...args: Array<Partial<TrendResult>>) {
    Object.assign(this, ...args);
  }

  /**
   * getFirstCounterValue
   * @returns {number}
   * @memberof TrendResult
   */
  public getFirstCounterValue(): number {
    return get(this, ['counters', '0', 'result', 'value']);
  }

  /**
   * setFirstCounterValue
   * @param {number} counterValue
   * @returns {any}
   * @memberof TrendResult
   */
  public setFirstCounterValue(counterValue: number) {
    return set(this, ['counters', '0', 'result', 'value'], counterValue);
  }

  /**
   * setFirstCounterDataType
   * @param {DataType} dataType
   * @returns {any}
   * @memberof TrendResult
   */
  public setFirstCounterDataType(dataType: DataType) {
    return set(this, ['counters', '0', 'result', 'reportColumnView', 'dataType'], DataType[dataType]);
  }
}
