/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from './converters/date-format.converter';

/**
 * TouchDetails
 * @export
 * @class TouchDetails
 */
@Serializable
export class TouchDetails {
  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  /**
   * constructor
   * @param {Array<Partial<TouchDetails>>} args
   * @memberof TouchDetails
   */
  constructor(...args: Array<Partial<TouchDetails>>) {
    Object.assign(this, ...args);
  }
}
