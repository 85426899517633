/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppPlatform
 * @export
 * @class AppPlatform
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const AppPlatform = {
  ANDROID: 'Android',
  APPLE_IOS: 'Apple iOS',
  APPLE_MACOS: 'Apple macOS',
  APPLE_TVOS: 'Apple tvOS',
  CHROME_OS: 'Chrome OS',
  DESKTOP: 'Desktop',
  QNX: 'QNX',
  WINDOWS_7: 'Windows 7',
  WINDOWS_DESKTOP: 'Windows Desktop',
  WINDOWS_PHONE: 'Windows Phone',
  WINDOWS_RUGGED: 'Windows Rugged',
} as const;
