/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ALERT_BANNER_TYPE } from '@ws1c/intelligence-models/alert-banner';

/**
 * http test result status into alert type
 *
 * @export
 * @param {number} status
 * @returns {string}
 */
export function getTestResultType(status: number): string {
  if (status < 199) {
    return ALERT_BANNER_TYPE.WARNING;
  } else if (status < 299) {
    return ALERT_BANNER_TYPE.SUCCESS;
  } else if (status < 399) {
    return ALERT_BANNER_TYPE.WARNING;
  } else {
    return ALERT_BANNER_TYPE.DANGER;
  }
}
