/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AutomationTemplate } from './automation-template.model';

/**
 * AutomationTemplate Search Response Model Object
 * Response format for list AutomationTemplate API
 *
 * @export
 * @class AutomationTemplateSearchResponse
 */
@Serializable
export class AutomationTemplateSearchResponse {
  @JsonProperty({ name: 'data', cls: AutomationTemplate })
  public data: AutomationTemplate[] = [];
}
