/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { keyBy } from 'lodash-es';

import { Column } from '@ws1c/intelligence-models/integration-meta/column.model';

/**
 * convertSearchResponseToCsvRows
 * @export
 * @param {Column[]} columns
 * @param {GenericObject[]} results
 * @returns {any[]}
 */
export function convertSearchResponseToCsvRows(columns: Column[], results: GenericObject[]): any {
  const columnsByName = keyBy(columns, 'attributeName');
  const validKeys = columns.map((column) => column.attributeName);
  const labelRow = validKeys.map((key: string) => columnsByName[key]?.label).filter((label: string) => !!label);
  const csvRows = [labelRow];
  results.forEach((result: GenericObject) => {
    const csvRow = validKeys.map((key: string) => getCSVCellValue(result[key]));
    csvRows.push(csvRow);
  });
  return csvRows;
}

/**
 * getCSVCellValue
 * @export
 * @param {any} colValue
 * @returns {any}
 */
export function getCSVCellValue(colValue: any): any {
  if (Array.isArray(colValue)) {
    colValue = colValue.join(', ');
  }
  if (typeof colValue !== 'string') {
    return colValue;
  }
  if (colValue.includes('"')) {
    return `"${colValue.replace(/"/g, '""')}"`;
  }
  // If the value contains a comma, enclosing it within "" will display the entire content in same column
  if (colValue.includes(',')) {
    return `"${colValue}"`;
  }
  return colValue;
}
