/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ResourceDeployRequest - Request model for script and sensors deployment.
 * @export
 * @class ResourceDeployRequest
 */
@Serializable
export class ResourceDeployRequest {
  @JsonProperty('name')
  public templateName: string = undefined;

  /**
   * Creates an instance of ResourceDeployRequest
   * @param {Array<Partial<ResourceDeployRequest>>} args
   * @memberof ResourceDeployRequest
   */
  constructor(...args: Array<Partial<ResourceDeployRequest>>) {
    Object.assign(this, ...args);
  }
}
