/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { every, isUndefined, some } from 'lodash-es';

import { MetaFormFieldPresentationType } from './meta-form-field-presentation-type.enum';
import { MetaFormField } from './meta-form-field.model';
import { MetaFormSection } from './meta-form-section.model';

/**
 * MetaForm
 * @export
 * @class MetaForm
 */
@Serializable
export class MetaForm {
  @JsonProperty({ name: 'fields', cls: MetaFormField })
  public fields?: MetaFormField[] = [];

  @JsonProperty({ name: 'sections', cls: MetaFormSection })
  public sections?: MetaFormSection[] = undefined;

  /**
   * Creates an instance of MetaForm
   * @param {*} args
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * isEmpty
   * @returns {boolean}
   * @memberof MetaForm
   */
  public get isEmpty(): boolean {
    const { fields, sections } = this;

    if (!isUndefined(sections) && sections.length) {
      return every(sections, (section: MetaFormSection) =>
        every(section.fields, (field: MetaFormField) => field.presentationType === MetaFormFieldPresentationType.HIDDEN),
      );
    }
    return !some(fields, (metaFormField: MetaFormField) => {
      return metaFormField.presentationType !== MetaFormFieldPresentationType.HIDDEN;
    });
  }
}
