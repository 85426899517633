/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * MetaFormFieldPresentationType
 *
 * @export
 * @enum {number}
 */
export enum MetaFormFieldPresentationType {
  CHECKBOX,
  EMAIL,
  HIDDEN,
  INPUT,
  LIST,
  NUMBER,
  PASSWORD,
  RADIO,
  TEXTAREA,
  URL,
  PHONE,
  RICH_TEXT_EDITOR,
  EMAIL_MULTISELECT,
  // These constants are only used by the UI in the MetaFormComponent and FilterValueComponent
  TEXT_AREA_WITH_COLUMN_VARIABLES,
  TEXT_INPUT,
  IPV4_INPUT,
  IPV4_LIST_INPUT,
  IPV4_RANGE_INPUT,
  WITHIN,
  TREE,
  MULTISELECT,
  SELECT,
  LOOKUP,
}
