/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject } from '@dpa/ui-common';
import { mapValues } from 'lodash-es';

import { FacetCount } from '@ws1c/intelligence-models/marketplace/facet-count.model';

/**
 * facetMapConverter
 * @export
 */
export const facetMapConverter: CustomConverter = {
  fromJson(data: GenericObject): Record<string, FacetCount[]> {
    return mapValues(data ?? {}, (facetCounts: GenericObject[]) =>
      facetCounts.map((facetCount: GenericObject) => deserialize(FacetCount, facetCount)),
    );
  },
  toJson(data: object): object {
    return data;
  },
};
