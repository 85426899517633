/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { DataConverterConfig, DataDecorator, DataUnit } from '@dpa/ui-common';

import { BucketingAttribute } from '@ws1c/intelligence-models/dashboard/bucketing-attribute.model';
import { ChartDrilldownEvent } from '@ws1c/intelligence-models/dashboard/chart-drilldown-event.interface';
import { Counter } from '@ws1c/intelligence-models/dashboard/counter.model';
import { AggregationFunction } from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { TrendResult } from '@ws1c/intelligence-models/dashboard/trend-result.model';
import { ColumnIndex } from '@ws1c/intelligence-models/integration-meta';
import { getShortColumnName } from '@ws1c/intelligence-models/utils/attributes-utils';
import { ReportColumnMapper } from '@ws1c/intelligence-models/utils/report-column-mapper';
import { StartEndDateStr, StartEndDateStrIndex, StartEndMillisIndex } from './ngx-common.interface';
import { NgxTrendResultFlattener } from './ngx-trend-result-flattener.model';

/**
 * NgxDatasetIndices
 * @export
 * @class NgxDatasetIndices
 */
export class NgxDatasetIndices {
  // This index is needed to convert time strings back into timestamps when drilling down
  public dataTypesByBucketingAttributeKey: { [bucketingAttributeKey: string]: string } = {};
  public dataUnitsByBucketingAttributeKey: { [bucketetingAttributeKey: string]: DataUnit } = {};
  public dataDecoratorsByCounterKey: { [counteKey: string]: DataDecorator } = {};
  public startEndDrilldownByDateValue: StartEndMillisIndex;
  public startEndDateStrByDateValue: StartEndDateStrIndex;

  /**
   * Creates an instance of NgxDatasetIndices.
   * @param {TrendResult[]} trendResults
   * @param {any[]} flatTrendResults
   * @param {boolean} isHistorical
   * @param {ColumnIndex} [allColumnsByName={}]
   * @memberof NgxDatasetIndices
   */
  constructor(
    private trendResults: TrendResult[],
    private flatTrendResults: any[],
    private isHistorical: boolean,
    private allColumnsByName: ColumnIndex = {},
  ) {
    this.setFlatTrendResultIndices();
  }

  /**
   * getDataTypesByBucketingAttributeKey
   * The datatype metadata is only available in the trendResults
   * This scans the trendResults to extract the datatypes
   * @param {TrendResult[]} trendResults
   * @returns {{ [bucketingAttributeKey: string]: string }}
   */
  public getDataTypesByBucketingAttributeKey(trendResults: TrendResult[]): { [bucketingAttributeKey: string]: string } {
    const dataTypesByBucketingAttributeKey = {};

    trendResults?.forEach((trendResult: TrendResult) => {
      trendResult.bucketingAttributes.forEach((bucketingAttribute: BucketingAttribute) => {
        const bucketName = bucketingAttribute.reportColumnView.name;
        const dataType = bucketingAttribute.reportColumnView.dataType;
        dataTypesByBucketingAttributeKey[bucketName] = dataType;
      });

      trendResult.counters.forEach((counter: Counter, index: number) => {
        const counterKey = NgxTrendResultFlattener.getCounterKey(index);
        dataTypesByBucketingAttributeKey[counterKey] = counter.result.reportColumnView.dataType;
      });
    });
    return dataTypesByBucketingAttributeKey;
  }

  /**
   * getDataUnitsByBucketingAttributeKey
   * @param {TrendResult[]} trendResults
   * @returns {{ [bucketingAttributeKey: string]: DataUnit }}
   * @memberof NgxDatasetIndices
   */
  public getDataUnitsByBucketingAttributeKey(trendResults: TrendResult[]): { [bucketingAttributeKey: string]: DataUnit } {
    const dataUnitsByBucketingAttributeKey = {};

    trendResults?.forEach((trendResult: TrendResult) => {
      const bucketingAttributeNames: string[] = trendResult.bucketingAttributes.map(
        (bucketingAttribute: BucketingAttribute) => bucketingAttribute.reportColumnView.name,
      );
      const bucketingAttributeDataUnits = this.getDataUnits(bucketingAttributeNames);
      bucketingAttributeDataUnits.forEach((dataUnit: DataUnit, index: number) => {
        dataUnitsByBucketingAttributeKey[bucketingAttributeNames[index]] = dataUnit;
      });

      const countersNames: string[] = trendResult.counters.map((counter: Counter) => counter.definition.aggregateAttribute);
      const countersDataUnits = this.getDataUnits(countersNames);
      countersDataUnits.forEach((dataUnit: DataUnit, index: number) => {
        const counterKey = NgxTrendResultFlattener.getCounterKey(index);
        dataUnitsByBucketingAttributeKey[counterKey] = dataUnit;
      });
    });
    return dataUnitsByBucketingAttributeKey;
  }

  /**
   * getDataDecoratorsByCounterKey
   * @param {TrendResult[]} trendResults
   * @returns {{ [counterKey: string]: DataDecorator }}
   * @memberof NgxDatasetIndices
   */
  public getDataDecoratorsByCounterKey(trendResults: TrendResult[]): { [counterKey: string]: DataDecorator } {
    const dataDecoratorsByCounterKey = {};
    trendResults?.forEach((trendResult: TrendResult) => {
      trendResult.counters.forEach((counter: Counter, index: number) => {
        const counterKey = NgxTrendResultFlattener.getCounterKey(index);
        const counterName = counter.definition.aggregateAttribute;
        const shortenedColName = getShortColumnName(counterName);
        if (ReportColumnMapper.percentBarFormatFieldSet.has(shortenedColName)) {
          dataDecoratorsByCounterKey[counterKey] = DataDecorator.PERCENTAGE_BAR;
        } else {
          dataDecoratorsByCounterKey[counterKey] =
            DataConverterConfig.valueDecoratorsByDataUnit[this.dataUnitsByBucketingAttributeKey[counterKey]] ||
            DataConverterConfig.dataTypeToDecoratorMap[this.dataTypesByBucketingAttributeKey[counterKey]];
        }
        if (
          counter.definition.aggregationFunction === AggregationFunction.COUNT ||
          counter.definition.aggregationFunction === AggregationFunction.COUNT_DISTINCT
        ) {
          dataDecoratorsByCounterKey[counterKey] = DataDecorator.INTEGER;
        }
      });
    });
    return dataDecoratorsByCounterKey;
  }

  /**
   * getDataUnits
   * @param {string[]} attributeNames
   * @returns {DataUnit[]}
   * @memberof NgxDatasetIndices
   */
  public getDataUnits(attributeNames: string[]): DataUnit[] {
    const dataUnits = [];
    attributeNames.forEach((attributeName: string) => {
      const colName = getShortColumnName(attributeName);
      const valueIsBitPerSecDatatype = ReportColumnMapper.bitPerSecFormatFieldSet.has(colName);
      const valueIsBytePerSecDatatype = ReportColumnMapper.bytePerSecFormatFieldSet.has(colName);
      const valueIsStringPercentFormatFieldSet = ReportColumnMapper.stringPercentFormatFieldSet.has(colName);
      const valueIsRateFormatFieldSet = ReportColumnMapper.rateFormatFieldSet.has(colName);
      let dataUnit: DataUnit = this.allColumnsByName[attributeName]?.dataUnit;
      // Not all DataUnits are supported by backend yet, so until then they must be manually set based on ReportColumnMapper sets.
      if (valueIsBitPerSecDatatype) {
        dataUnit = DataUnit.BITS_PER_SEC;
      }
      if (valueIsBytePerSecDatatype) {
        dataUnit = DataUnit.BYTES_PER_SEC;
      }
      if (valueIsStringPercentFormatFieldSet) {
        dataUnit = DataUnit.PERCENT;
      }
      if (valueIsRateFormatFieldSet) {
        dataUnit = DataUnit.RATE;
      }
      dataUnits.push(dataUnit);
    });
    return dataUnits;
  }

  /**
   * setFlatTrendResultIndices
   * @memberof NgxDatasetIndices
   */
  private setFlatTrendResultIndices() {
    this.dataTypesByBucketingAttributeKey = this.getDataTypesByBucketingAttributeKey(this.trendResults);
    this.dataUnitsByBucketingAttributeKey = this.getDataUnitsByBucketingAttributeKey(this.trendResults);
    this.dataDecoratorsByCounterKey = this.getDataDecoratorsByCounterKey(this.trendResults);
    this.startEndDrilldownByDateValue = {};
    this.startEndDateStrByDateValue = {};
    const isHistorical = this.isHistorical;
    this.flatTrendResults?.forEach((flatTrendResult: any) => {
      if (isHistorical) {
        const dateValue = flatTrendResult[NgxTrendResultFlattener.DATE_KEY];
        const startDateMillis = flatTrendResult[NgxTrendResultFlattener.START_DRILLDOWN_KEY];
        const endDateMillis = flatTrendResult[NgxTrendResultFlattener.END_DRILLDOWN_KEY];
        this.startEndDrilldownByDateValue[dateValue] = {
          startDateMillis,
          endDateMillis,
        } as Partial<ChartDrilldownEvent>;

        const startDateStr = flatTrendResult[NgxTrendResultFlattener.START_STR];
        const endDateStr = flatTrendResult[NgxTrendResultFlattener.END_STR];
        this.startEndDateStrByDateValue[dateValue] = {
          startDateStr,
          endDateStr,
        } as StartEndDateStr;
      }
    });
  }
}
