/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * ActionTypeV2
 * @export
 * @enum {string}
 */
export enum ActionTypeV2 {
  OPENAPI = 'OPENAPI',
  SYSTEM_GROUP = 'SYSTEM_GROUP',
  SYSTEM_SWITCH = 'SYSTEM_SWITCH',
  SYSTEM_TERMINATE = 'SYSTEM_TERMINATE',
}
