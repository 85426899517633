/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Connector } from './connector.model';

/**
 * ConnectorActionType
 * @exports
 * @enum {number}
 */
export enum ConnectorActionType {
  ADD,
  CREATE,
  DEAUTHORIZE,
  DELETE,
  EDIT,
  RENAME,
  SET_UP,
  VIEW,
  VIEW_ACTIONS,
  VIEW_ERRORS,
}

/**
 * ConnectorActionEvent
 * @export
 * @interface ConnectorActionEvent
 */
export interface ConnectorActionEvent {
  action: ConnectorActionType;
  data?: Connector;
}
