/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * UserFlow
 * @export
 * @class UserFlow
 */
@Serializable
export class UserFlow {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('failure_count')
  public failureCount: number = undefined;

  @JsonProperty('success_count')
  public successCount: number = undefined;

  @JsonProperty('total_count')
  public totalCount: number = undefined;

  @JsonProperty('success_ratio')
  public successRatio: number = undefined;

  @JsonProperty('average_time')
  public averageTime: number = undefined;

  /**
   * constructor
   * @param {Partial<UserFlow>[]} args
   * @memberof UserFlow
   */
  constructor(...args: Array<Partial<UserFlow>>) {
    Object.assign(this, ...args);
  }
}

/**
 * UserFlowMetricsByName
 * @export
 * @interface UserFlowMetricsByName
 */
export interface UserFlowMetricsByName {
  [userFlowName: string]: UserFlowMetric;
}

/**
 * UserFlowMetric
 * @export
 * @class UserFlowMetric
 */
@Serializable
export class UserFlowMetric {
  public static readonly FAILURE = 'Failure';
  public static readonly SUCCESS = 'Success';

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('count')
  public count: number = undefined;

  @JsonProperty('previous_count')
  public previousCount: number = undefined;

  @JsonProperty('change_percent')
  public changePercent: number = undefined;
}
