/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceNetworkCrumb
 * @export
 * @class DeviceNetworkCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceNetworkCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_friendly_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_ssid)
  public ssid: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_wifi_signal_range)
  public wifiSignalRange: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_type)
  public nicType: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_nic_description)
  public nicDescription: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_physical_address)
  public physicalAddress: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_mode)
  public mode: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_rssi)
  public rssi: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_net_event_dot11_frequency)
  public frequency: number = undefined;

  /**
   * Creates an instance of DeviceNetworkCrumb.
   * @param {...Array<Partial<DeviceNetworkCrumb>>} args
   * @memberof DeviceNetworkCrumb
   */
  constructor(...args: Array<Partial<DeviceNetworkCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
