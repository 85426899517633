/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Chrono Unit
 *
 * @export
 * @enum {number}
 */
export enum ChronoUnit {
  MILLIS,
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
  WEEKS,
  MONTHS,
  YEARS,
}
