/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * GlobalSearchType
 * @export
 * @enum {string}
 */
export enum GlobalSearchType {
  DEVICE = 'DEVICE',
  USER = 'USER',
}
