/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Org Disabled Config
 *
 * @export
 * @class OrgDisabledConfig
 */
@Serializable
export class OrgDisabledConfig {
  @JsonProperty('error_type')
  public errorType: string = undefined;

  @JsonProperty('ws1c_org')
  public isWs1cOrg: boolean = undefined;

  @JsonProperty('contact_sale_url')
  public contactSalesUrl: string = undefined;

  @JsonProperty('return_url')
  public returnUrl: string = undefined;

  @JsonProperty('ws1_support_url')
  public ws1SupportUrl: string = undefined;

  /**
   * Initializes instance of OrgDisabledConfig
   * @param {Array<Partial<OrgDisabledConfig>>} args - initialization arguments for OrgDisabledConfig
   * @memberof OrgDisabledConfig
   */
  constructor(...args: Array<Partial<OrgDisabledConfig>>) {
    Object.assign(this, ...args);
  }
}
