/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { PendoMetadata } from './pendo-metadata';

/**
 * Pendo Visitor Metadata
 *
 * @export
 * @class PendoVisitorMetadata
 */
export class PendoVisitorMetadata {
  public visitorId: string;
  public values: PendoMetadata;

  /**
   * Initializes instance of PendoVisitorMetadata
   * @param {Array<Partial<PendoVisitorMetadata>>} args - object initialization arguments
   * @memberof PendoVisitorMetadata
   */
  constructor(...args: Array<Partial<PendoVisitorMetadata>>) {
    Object.assign(this, ...args);
  }
}
