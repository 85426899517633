/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { JsonSchemaMetadata } from './json-schema-metadata.model';
import { JsonSchemaNode } from './json-schema-node.interface';

/**
 * @export
 * @class ConnectorSchema
 */
@Serializable
export class ConnectorSchema {
  /**
   * Unique ID of the schema.
   */
  @JsonProperty('id')
  public id: string = undefined;

  /**
   * Connector schema used to describe data type, required properties, child-parent relationships of a field, enumerations if present.
   * At time of comment, `json_schema` field expects an object following a modified Json Schema Draft 7 specification
   */
  @JsonProperty({ name: 'json_schema', customConverter: defaultValueConverterFactory() })
  public jsonSchema: JsonSchemaNode = undefined;

  /**
   * Used to describe metadata properties for a given field like label, description, tooltip etc.
   */
  @JsonProperty({ name: 'metadata', cls: JsonSchemaMetadata })
  public metadata: JsonSchemaMetadata[] = undefined;

  /**
   * {@link AccountIdentifier} of the user who created the entity.
   */
  @JsonProperty('created_by')
  public createdBy: string = undefined;

  /**
   * {@link AccountIdentifier} of the user who last modified the entity.
   */
  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  /**
   * {@link Date} timestamp of entity creation.
   */
  @JsonProperty('created_at')
  public createdAt: string = undefined;

  /**
   * {@link Date} timestamp of last entity update.
   */
  @JsonProperty('modified_at')
  public modifiedAt: string = undefined;

  /**
   * Creates an instance of ConnectorSchema.
   * @param {...Array<Partial<ConnectorSchema>>} args
   * @memberof ConnectorSchema
   */
  constructor(...args: Array<Partial<ConnectorSchema>>) {
    Object.assign(this, ...args);
  }
}
