/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * MarketplaceResourceProperty
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceResourceProperty {
  CHART_TYPE = 'chart_type',
  CODE = 'code',
  ENTITIES_BY_INTEGRATION = 'entities_by_integration',
  EXECUTION_ARCHITECTURE = 'execution_architecture',
  EXECUTION_CONTEXT = 'execution_context',
  INTEGRATIONS_REQUIRED = 'integrations_required',
  LANGUAGE = 'language',
  PLATFORM = 'platform',
  PREREQUISITES = 'prerequisites',
  REPORT_TYPE = 'report_type',
  RESPONSE_DATA_TYPE = 'response_data_type',
  SERVICE_TYPES = 'service_types',
  TEMPLATE_ID = 'template_id',
  TREND_MODE = 'trend_mode',
  VARIABLES = 'variables',
  WIDGET_COUNT = 'widget_count',
  WIDGET_IMAGE_URLS = 'widget_image_urls',
}
