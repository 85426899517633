/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumUpperCaseConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { MarketplaceResourceProperty } from './marketplace-resource-property.enum';

/**
 * MarketplaceResourceMetadata
 * @export
 * @class MarketplaceResourceMetadata
 */
@Serializable
export class MarketplaceResourceMetadata {
  @JsonProperty({ name: 'property', customConverter: enumUpperCaseConverterFactory(MarketplaceResourceProperty) })
  public property: MarketplaceResourceProperty = undefined;

  @JsonProperty('value')
  public value: string = undefined;

  /**
   * Creates an instance of MarketplaceResourceMetadata.
   * @param {Array<Partial<MarketplaceResourceMetadata>>} args
   * @memberof MarketplaceResourceMetadata
   */
  constructor(...args: Array<Partial<MarketplaceResourceMetadata>>) {
    Object.assign(this, ...args);
  }
}
