/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedRequest, Serializable, SortOn } from '@dpa/ui-common';

import { Trend } from './dashboard/trend.model';
import { COLUMN_NAMES } from './integration-meta';
import { SearchTerm } from './search-term.model';
import { TrendMode } from './trend-mode.enum';

/**
 * PreviewReportContentRequest
 * @export
 * @class PreviewReportContentRequest
 */
@Serializable
export class PreviewReportContentRequest {
  @JsonProperty({ name: 'entity', excludeToJson: true })
  public entity: string = undefined;

  @JsonProperty({ name: 'integration', excludeToJson: true })
  public integration: string = undefined;

  @JsonProperty('entities_by_integration')
  public entitiesByIntegration: any = undefined;

  @JsonProperty({ name: 'trend_mode', excludeToJson: true })
  public trendMode: TrendMode = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty('offset')
  public offset: number = undefined;

  @JsonProperty('page_size')
  public pageSize: number = undefined;

  @JsonProperty('fields')
  public fields: string[] = undefined;

  @JsonProperty({ name: 'search_term', cls: SearchTerm })
  public searchTerm?: SearchTerm = undefined;

  @JsonProperty('sort_ons')
  public sortOns: any[] = undefined;

  @JsonProperty('start_date_millis')
  public startDateMillis: number = undefined;

  @JsonProperty('end_date_millis')
  public endDateMillis: number = undefined;

  @JsonProperty('ignore_case')
  public ignoreCase?: boolean = undefined;

  /**
   * Creates an instance of PreviewReportContentRequest.
   * @param {any} args
   * @memberof PreviewReportContentRequest
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * getPreviewReportAnyRequest
   *
   * @param {Trend} previewChartData
   * @param {PagedRequest} previewPage
   * @param {Set<string>} fieldNameSet
   * @returns {PreviewReportContentRequest}
   * @memberof PreviewReportContentRequest
   */
  public static getPreviewReportAnyRequest(
    previewChartData: Trend,
    previewPage: PagedRequest,
    fieldNameSet: Set<string>,
  ): PreviewReportContentRequest {
    const eventTimeSortOn = new SortOn({
      by: COLUMN_NAMES.byName.event_timestamp,
      reverse: true,
    });
    return new PreviewReportContentRequest({
      trendMode: TrendMode.HISTORICAL,
      entitiesByIntegration: previewChartData.trendDefinition.entitiesByIntegration,
      filter: previewChartData.trendDefinition.filter,
      fields: Array.from(fieldNameSet),
      offset: previewPage?.from ?? 0,
      pageSize: previewPage?.size ?? 10,
      sortOns: fieldNameSet.has(COLUMN_NAMES.byName.event_timestamp) ? [eventTimeSortOn] : [],
      startDateMillis: previewChartData.trendDefinition.dateRange?.startDateMillis,
      endDateMillis: previewChartData.trendDefinition.dateRange?.endDateMillis,
    });
  }

  /**
   * isReportPreviewEnabled
   * @readonly
   * @type {boolean}
   * @memberof PreviewReportContentRequest
   */
  public get isReportPreviewEnabled(): boolean {
    return this.trendMode !== TrendMode.SNAPSHOT_PERIODICAL;
  }
}
