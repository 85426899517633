/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * FeatureOperator
 * @export
 * @enum {string}
 */
export enum FeatureOperator {
  AND = 'AND',
  OR = 'OR',
}
