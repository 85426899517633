/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AutomationRunHistory } from './automation-run-history.model';

/**
 * AutomationRunHistoryResponse
 * @export
 * @class AutomationRunHistoryResponse
 * @implements {PagedResponse}
 */
@Serializable
export class AutomationRunHistoryResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AutomationRunHistory })
  public results: AutomationRunHistory[] = [];

  /**
   * Creates an instance of AutomationRunHistoryResponse.
   * @param {Array<Partial<AutomationRunHistoryResponse>>} args
   * @memberof AutomationRunHistoryResponse
   */
  constructor(...args: Array<Partial<AutomationRunHistoryResponse>>) {
    Object.assign(this, ...args);
  }
}
