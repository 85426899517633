/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable, TrendSpan } from '@dpa/ui-common';
import { isUndefined } from 'lodash-es';

import { RollingWindow } from './rolling-window.model';

/**
 * Trend Date Range Model Object
 * Defines a structure containing all attributes needed to perform date range bucketing.
 * A trend spans over a date range, bucketed in sampling frequency units.
 *
 * @export
 * @class TrendDateRange
 */
@Serializable
export class TrendDateRange {
  // 31 because last 30 days could return 30 buckets
  public static readonly maximumPeriodCount: number = 31;
  public static readonly timeUnitStickiness: number = 5;

  @JsonProperty({ name: 'trend_span', cls: TrendSpan })
  public trendSpan: TrendSpan = undefined;

  @JsonProperty({ name: 'sampling_frequency', cls: TrendSpan })
  public samplingFrequency: TrendSpan = undefined;

  @JsonProperty('start_date_millis')
  public startDateMillis: number = undefined;

  @JsonProperty('end_date_millis')
  public endDateMillis: number = undefined;

  @JsonProperty({ name: 'rolling_window', cls: RollingWindow })
  public rollingWindow: RollingWindow = undefined;

  @JsonProperty('date_range_order')
  public dateRangeOrder: string = undefined;

  @JsonProperty('round_end_timestamp')
  public roundEndTimestamp: boolean = undefined;

  @JsonProperty('date_attribute_name')
  public dateAttributeName: string = undefined;

  /**
   * constructor
   * @param {Partial<TrendDateRange>[]} args
   * @memberof TrendDateRange
   */
  constructor(...args: Array<Partial<TrendDateRange>>) {
    Object.assign(this, ...args);
  }

  /**
   * getStartEndMillis
   * @returns {Partial<TrendDateRange>}
   * @memberof TrendDateRange
   */
  public getStartEndMillis(): Partial<TrendDateRange> {
    return {
      startDateMillis: isUndefined(this.startDateMillis) ? Date.now() - this.trendSpan?.getUnitInMillis() : this.startDateMillis,
      endDateMillis: isUndefined(this.endDateMillis) ? Date.now() : this.endDateMillis,
    };
  }

  /**
   * getSamplingFrequency - is actually samplingPeriod or samplingInterval
   * @returns {TrendSpan}
   * @memberof TrendDateRange
   */
  public getSamplingFrequency(): TrendSpan {
    return this.rollingWindow ? this.rollingWindow.rollingWindowInterval : this.samplingFrequency;
  }

  /**
   * getSamplingFrequencyInMillis
   * @returns {number}
   * @memberof TrendDateRange
   */
  public getSamplingFrequencyInMillis(): number {
    const samplingFrequency = this.getSamplingFrequency();
    return samplingFrequency ? samplingFrequency.getUnitInMillis() : 0;
  }

  /**
   * useGeneratedSamplingFrequency
   * @memberof TrendDateRange
   */
  public useGeneratedSamplingFrequency() {
    const timeSpanInMillis = this.endDateMillis - this.startDateMillis;

    // starts at 1 to ignore millisecond unit when finding sampling frequency
    let unitIndex = 1;

    let timeUnit = TrendSpan.timeUnits[unitIndex];
    let unitCount = 1;
    let periodCount = timeSpanInMillis / (timeUnit.value * unitCount);
    while (periodCount > TrendDateRange.maximumPeriodCount) {
      unitCount++;
      const timeSpanValue = unitCount * timeUnit.value * TrendDateRange.timeUnitStickiness;
      const nextTimeUnit = TrendSpan.timeUnits[unitIndex + 1];
      const isTimeUnitTooSmall = nextTimeUnit && timeSpanValue >= nextTimeUnit.value;
      if (nextTimeUnit && isTimeUnitTooSmall) {
        // Shift up to next timeUnit
        unitIndex++;
        timeUnit = nextTimeUnit;
        unitCount = 1;
      }
      periodCount = timeSpanInMillis / (timeUnit.value * unitCount);
    }

    const samplingFrequency = Object.assign(new TrendSpan(), {
      unit: timeUnit.name,
      duration: unitCount,
    });
    if (this.rollingWindow) {
      this.rollingWindow = Object.assign(new RollingWindow(), {
        ...this.rollingWindow,
        rollingWindowInterval: samplingFrequency,
      });
    } else {
      this.samplingFrequency = samplingFrequency;
    }
  }

  /**
   * isTrendSpanUndefined
   * @returns {boolean}
   * @memberof TrendDateRange
   */
  public isTrendSpanUndefined(): boolean {
    return isUndefined(this.trendSpan);
  }

  /**
   * isSamplingFrequencyUndefined
   * @returns {boolean}
   * @memberof TrendDateRange
   */
  public isSamplingFrequencyUndefined(): boolean {
    return isUndefined(this.samplingFrequency);
  }

  /**
   * isStartDateMillisUndefined
   * @returns {boolean}
   * @memberof TrendDateRange
   */
  public isStartDateMillisUndefined(): boolean {
    return isUndefined(this.startDateMillis);
  }

  /**
   * resetDateMillis
   * @memberof TrendDateRange
   */
  public resetDateMillis() {
    this.startDateMillis = undefined;
    this.endDateMillis = undefined;
  }
}
