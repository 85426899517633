/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AccountGroup } from './account-group.model';

/**
 * AccountGroupSearchResponse
 *
 * @export
 * @class AccountGroupSearchResponse
 */
@Serializable
export class AccountGroupSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AccountGroup })
  public results: AccountGroup[] = [];

  /**
   * Creates an instance of AccountGroupSearchResponse.
   * @param {Array<Partial<AccountGroupSearchResponse>>} args
   * @memberof AccountGroupSearchResponse
   */
  constructor(...args: Array<Partial<AccountGroupSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
