/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * TransferOwnershipActionOrigin
 * @export
 * @enum {string}
 */
export enum TransferOwnershipActionOrigin {
  SYSTEM_LIMITS_PAGE = 'SYSTEM_LIMITS_PAGE',
}
