/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * Represents frequency of a quartz job.
 * @export
 * @enum {number}
 */
export enum Frequency {
  ONCE,
  HOURLY,
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
}
