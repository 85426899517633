/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Intelligence Feature
 *
 * @export
 * @enum {number}
 */
export enum IntelligenceFeature {
  AUTOMATION,
  DASHBOARD,
  REPORT,
  DEEM,
  RISK_ANALYTICS,
}
