/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * PendoEventOrchestratorView
 *
 * @export
 * @enum {string}
 */
export enum PendoEventOrchestratorView {
  CANVAS = 'Canvas',
  LIST = 'List',
}
