/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, JsonProperty, Serializable, SortOn } from '@dpa/ui-common';

import { defaultValueConverterFactory, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { CompositeTrendDefinition } from './composite-trend-definition.model';
import { CounterDefinition } from './counter-definition.model';
import { AggregationWidgetChartType } from './dashboard.enum';
import { TrendDefinition } from './trend-definition.model';

/**
 * StandardWidgetDrilldownEvent
 */
export interface StandardWidgetDrilldownEvent {
  widgetSubType: string;
  widgetDetailDefinition: Partial<WidgetDetailDefinition>;
}

/**
 * WidgetDetailDefinition
 * @export
 * @class WidgetDetailDefinition
 */
@Serializable
export class WidgetDetailDefinition {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('widget_id')
  public widgetId: string = undefined;

  @JsonProperty('dashboard_id')
  public dashboardId: string = undefined;

  @JsonProperty('dashboard_name')
  public dashboardName: string = undefined;

  @JsonProperty('chart_title')
  public chartTitle: string = undefined;

  @JsonProperty({ name: 'return_crumbs', cls: BreadCrumb })
  public returnCrumbs: BreadCrumb[] = undefined;

  @JsonProperty({ name: 'trend_definition', cls: TrendDefinition })
  public trendDefinition: TrendDefinition = undefined;

  @JsonProperty({ name: 'overlay_trend_definition', cls: TrendDefinition })
  public overlayTrendDefinition: TrendDefinition = undefined;

  @JsonProperty({ name: 'chart_type', customConverter: enumConverterFactory(AggregationWidgetChartType) })
  public chartType: AggregationWidgetChartType = undefined;

  @JsonProperty('data_projection_fields')
  public widgetColumns: string[] = undefined;

  @JsonProperty('show_series_names')
  public showSeriesNames: boolean = undefined;

  @JsonProperty('show_table')
  public showTable: boolean = undefined;

  @JsonProperty('hide_date_range')
  public hideDateRange: boolean = undefined;

  @JsonProperty('hide_chart')
  public hideChart: boolean = undefined;

  @JsonProperty('show_detail_view')
  public showDetailView: boolean = undefined;

  @JsonProperty('grouped_table_attr')
  public groupedTableAttrs: string[] = undefined;

  @JsonProperty('hidden_grouped_table_attr')
  public hiddenGroupedTableAttrs: string[] = undefined;

  @JsonProperty({ name: 'grouped_table_counters', cls: CounterDefinition })
  public groupedTableCounters: CounterDefinition[] = undefined;

  @JsonProperty('detail_table_attr')
  public detailTableAttrs?: string[] = undefined;

  @JsonProperty('detail_table_hidden_attr')
  public detailTableHiddenAttrs?: string[] = undefined;

  @JsonProperty({ name: 'detail_table_counters', cls: CounterDefinition })
  public detailTableCounters?: CounterDefinition[] = undefined;

  @JsonProperty('disable_drilldown')
  public disableDrilldown: boolean = undefined;

  @JsonProperty('disable_column_selector')
  public disableColumnSelector: boolean = undefined;

  @JsonProperty('y_axis_label_override')
  public yAxisLabelOverride: string = undefined;

  @JsonProperty({ name: 'composite_trend_definition', cls: CompositeTrendDefinition })
  public compositeTrendDefinition: CompositeTrendDefinition = undefined;

  @JsonProperty('custom_colors')
  public customColors: any[] = undefined;

  @JsonProperty('no_bucketing_color')
  public noBucketingColor: string = undefined;

  @JsonProperty({ name: 'sort_by_counter', cls: SortOn })
  public sortByCounter: SortOn = undefined;

  @JsonProperty('show_invert_mode')
  public showInvertMode: boolean = undefined;

  @JsonProperty('is_cross_category')
  public isCrossCategory: boolean = false;

  @JsonProperty({ name: 'additional_parameters', customConverter: defaultValueConverterFactory() })
  public additionalParams: Record<string, string> = undefined;

  /**
   * Creates an instance of WidgetDetailDefinition
   * @param {any} args
   * @memberof WidgetDetailDefinition
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * isV2
   * @readonly
   * @type {boolean}
   * @memberof WidgetDetailDefinition
   */
  public get isV2(): boolean {
    // Except SECURITY_THREAT widget, this has to return true
    return this.trendDefinition?.isV2 ?? true;
  }

  /**
   * lastBreadcrumbUrl
   * @readonly
   * @type {string}
   * @memberof WidgetDetailDefinition
   */
  public get lastBreadcrumbUrl(): string {
    return this.returnCrumbs?.slice(-1)[0]?.pathUrl || '';
  }
}
