/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * DashboardTemplateImportRequest
 * @export
 * @class DashboardTemplateImportRequest
 */
@Serializable
export class DashboardTemplateImportRequest {
  @JsonProperty('template_ids')
  public templateIds: string[] = undefined;

  /**
   * Creates an instance of DashboardTemplateImportRequest.
   * @param {...Array<Partial<DashboardTemplateImportRequest>>} args
   * @memberof DashboardTemplateImportRequest
   */
  constructor(...args: Array<Partial<DashboardTemplateImportRequest>>) {
    Object.assign(this, ...args);
  }
}
