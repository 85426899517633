/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { App, AppPlatform } from '@ws1c/intelligence-models/app-loads';

/**
 * getAppFromRouteParams
 * @param {any} queryParams
 * @param {any} params
 * @returns {App}
 */
export function getAppFromRouteParams(queryParams: any, params: any): App {
  // Right now, the platform passed in can be "Android", or "ANDROID"
  // For IOS, it can be "IOS" or "Apple"
  // This normalizes onto "Android" and "Apple"
  // Remove when everything is cleaned up
  const platformOverrides = {
    ANDROID: AppPlatform.ANDROID,
    IOS: AppPlatform.APPLE_IOS,
    Apple: AppPlatform.APPLE_IOS,
  };

  const platform = platformOverrides[params.platform] || params.platform;
  return Object.assign(new App(), {
    id: params.id,
    platform,
    apteligentAppId: queryParams.apteligentAppId,
    name: queryParams.name,
    appType: queryParams.appType,
    integration: queryParams.integration,
    // queryParam values are always strings, this is needed to convert to boolean
    isProductivityApp: queryParams.isProductivityApp === 'true',
  });
}
