/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { each, size } from 'lodash-es';

import {
  dateFormatConverter,
  defaultValueConverterFactory,
  enumConverterFactory,
  filterConditionConverter,
} from '@ws1c/intelligence-models/converters';
import { QueryBuilder, RuleGroup } from '@ws1c/intelligence-models/filter';
import { NameLabel } from '@ws1c/intelligence-models/name-label.model';
import { ReportConfig } from '@ws1c/intelligence-models/reports/report-config.model';
import { ReportDateRange } from '@ws1c/intelligence-models/reports/report-date-range.model';
import { ReportType } from '@ws1c/intelligence-models/reports/report.enum';
import { Template } from '@ws1c/intelligence-models/template/template.model';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';
import { ReportTemplateType } from './report-template-type.enum';

/**
 * ReportTemplate
 * @export
 * @class ReportTemplate
 * @extends Template
 */
@Serializable
export class ReportTemplate extends Template {
  @JsonProperty('name')
  public _name: string = undefined;

  @JsonProperty('description')
  public _description: string = undefined;

  @JsonProperty('i18n_name')
  public i18nName: string = undefined;

  @JsonProperty('i18n_description')
  public i18nDescription: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty({ name: 'entity' })
  public entity: string = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', customConverter: filterConditionConverter, excludeToJson: true })
  public filterCondition: any = undefined;

  @JsonProperty('column_names')
  public _columnNames: string[] = undefined;

  @JsonProperty({ name: 'join_entities_by_integration', customConverter: defaultValueConverterFactory() })
  public joinEntitiesByIntegration: any = {};

  @JsonProperty('starter')
  public starter: boolean = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty({ name: 'report_type', customConverter: enumConverterFactory(ReportType) })
  public reportType: ReportType = undefined;

  @JsonProperty({ name: 'date_range', cls: ReportDateRange })
  public dateRange: ReportDateRange = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'subtags', cls: NameLabel })
  public subtags: NameLabel[] = undefined;

  @JsonProperty({ name: 'report_config', cls: ReportConfig })
  public reportConfig?: ReportConfig = undefined;

  public filterRuleGroup: RuleGroup;

  private _ruleNames: string[] = [];

  /**
   * Creates an instance of ReportTemplate.
   * @param {Array<Partial<ReportTemplate>>} args
   * @memberof ReportTemplate
   */
  constructor(...args: Array<Partial<ReportTemplate>>) {
    super(...args);
    Object.assign(this, ...args);
    if (!size(this.joinEntitiesByIntegration)) {
      this.setJoinEntitiesByIntegration();
    }
    if (this.filterCondition) {
      this.filterRuleGroup = QueryBuilder.parseRuleDefinitionTree(this.filterCondition);
    }
  }

  /**
   * Getter to get name
   *
   * @readonly
   * @type {string}
   * @memberof ReportTemplate
   */
  public get name() {
    return this.i18nName || this._name;
  }

  /**
   * setter for name
   * @param {string} name
   * @memberof ReportTemplate
   */
  public set name(name: string) {
    this._name = name;
  }

  /**
   * label
   * @readonly
   * @type {string}
   * @memberof ReportTemplate
   */
  public get label(): string {
    return this.name;
  }

  /**
   * Getter to get descrition
   *
   * @readonly
   * @type {string}
   * @memberof ReportTemplate
   */
  public get description() {
    return this.i18nDescription || this._description;
  }

  /**
   * setter for description
   * @param {string} description
   * @memberof ReportTemplate
   */
  public set description(description: string) {
    this._description = description;
  }

  /**
   * Getter to get unique categoryId
   *
   * @readonly
   * @type {string}
   * @memberof ReportTemplate
   */
  public get categoryId(): string {
    return getUniqueId(this.integration, this.entity);
  }

  /**
   * Getter for columnNames
   * @type {string[]}
   * @memberof ReportTemplate
   */
  public get columnNames(): string[] {
    return this._columnNames;
  }

  /**
   * setter for columnNames
   * @param {string[]} columnNames
   * @memberof ReportTemplate
   */
  public set columnNames(columnNames: string[]) {
    this._columnNames = columnNames;
    this.setJoinEntitiesByIntegration();
  }

  /**
   * Setter for ruleNames
   * @readonly
   * @param {string[]} ruleNames
   * @memberof ReportTemplate
   */
  public set ruleNames(ruleNames: string[]) {
    this._ruleNames = ruleNames;
    this.setJoinEntitiesByIntegration();
  }

  /**
   * isCrossCategoryTemplateReport
   * @returns {boolean}
   * @memberof ReportTemplate
   */
  public get isCrossCategoryTemplateReport(): boolean {
    const integrations = Object.keys(this.joinEntitiesByIntegration);
    return size(integrations) > 1 || size(this.joinEntitiesByIntegration[integrations[0]]) > 1;
  }

  /**
   * isCustomTemplate
   *
   * @readonly
   * @type {boolean}
   * @memberof ReportTemplate
   */
  public get isCustomTemplate(): boolean {
    return this.type === ReportTemplateType.CUSTOM;
  }

  /**
   * templateId
   *
   * @readonly
   * @type {string}
   * @memberof ReportTemplate
   */
  public get templateId(): string {
    return this._name || this.id;
  }

  /**
   * isPredefinedReportTemplate
   * @readonly
   * @type {boolean}
   * @memberof ReportTemplate
   */
  public get isPredefinedReportTemplate(): boolean {
    return this.reportType === ReportType.PREDEFINED;
  }

  /**
   * templateKey
   * @readonly
   * @type {string}
   * @memberof ReportTemplate
   */
  public get templateKey(): string {
    return this._name || this.id;
  }

  /**
   * setJoinEntitiesByIntegration
   * @memberof ReportTemplate
   */
  private setJoinEntitiesByIntegration() {
    this.joinEntitiesByIntegration = {};
    // Add Base Entity/Integration
    this.joinEntitiesByIntegration[this.integration] = [this.entity];
    const formJoinEntitiesByIntegration = (names: string[]) => {
      each(names, (name: string) => {
        if (name) {
          const [integration, entity] = name.split('.');
          if (!this.joinEntitiesByIntegration[integration]) {
            this.joinEntitiesByIntegration[integration] = [];
          }
          if (!this.joinEntitiesByIntegration[integration].includes(entity)) {
            this.joinEntitiesByIntegration[integration].push(entity);
          }
        }
      });
    };
    formJoinEntitiesByIntegration(this._columnNames);
    formJoinEntitiesByIntegration(this._ruleNames);
  }
}
