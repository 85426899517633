/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * CarbonBlackDashboardType
 * @export
 * @enum {string}
 */
export enum CarbonBlackDashboardType {
  // For Carbon Black Dashboard
  CARBON_BLACK = 'CARBON_BLACK',
}

/**
 * CarbonBlackWidgetSubtype
 * @export
 * @enum {string}
 */
export enum CarbonBlackWidgetSubtype {
  // For Carbon Black Dashboard
  CARBON_BLACK_ALERT_SEVERITY_SCORE = 'CARBON_BLACK_ALERT_SEVERITY_SCORE',
  CARBON_BLACK_DEVICES_DISTINCT_COUNT = 'CARBON_BLACK_DEVICES_DISTINCT_COUNT',
  CARBON_BLACK_KILL_CHAIN_STATUS = 'CARBON_BLACK_KILL_CHAIN_STATUS',
  CARBON_BLACK_THREAT_REASON = 'CARBON_BLACK_THREAT_REASON',
  CARBON_BLACK_THREAT_SUMMARY = 'CARBON_BLACK_THREAT_SUMMARY',
  CARBON_BLACK_THREAT_TYPE = 'CARBON_BLACK_THREAT_TYPE',
  DEVICE_POLICY_APPLIED_OR_HIGH_THREAT_ALERT_SEVERITY = 'DEVICE_POLICY_APPLIED_OR_HIGH_THREAT_ALERT_SEVERITY',
  DEVICE_WITH_HIGH_THREATS_AND_POLICY_NOT_APPLIED = 'DEVICE_WITH_HIGH_THREATS_AND_POLICY_NOT_APPLIED',
  ENDPOINTS_BY_EVENT_TYPE = 'ENDPOINTS_BY_EVENT_TYPE',
  ENDPOINTS_BY_PRODUCT_NAME = 'ENDPOINTS_BY_PRODUCT_NAME',
  ENDPOINTS_WITH_ALERTED = 'ENDPOINTS_WITH_ALERTED',
  EXTERNAL_NETWORK_CONNECTIONS_PORTS = 'EXTERNAL_NETWORK_CONNECTIONS_PORTS',
  NETWORK_CONNECTION_FROM_CRITICAL_PROTOCOLS_USING_LOCAL_PORTS = 'NETWORK_CONNECTION_FROM_CRITICAL_PROTOCOLS_USING_LOCAL_PORTS',
  NETWORK_CONNECTION_FROM_CRITICAL_PROTOCOLS_USING_REMOTE_PORTS = 'NETWORK_CONNECTION_FROM_CRITICAL_PROTOCOLS_USING_REMOTE_PORTS',
  NETWORK_DNS_CONNECTIONS = 'NETWORK_DNS_CONNECTIONS',
  PROCESS_REPUTATION = 'PROCESS_REPUTATION',
  SENSOR_POLICY_COUNTS_BY_PREVENTION_ACTIONS = 'SENSOR_POLICY_COUNTS_BY_PREVENTION_ACTIONS',
  SENSOR_POLICY_PREVENTION_ACTIONS = 'SENSOR_POLICY_PREVENTION_ACTIONS',
  SENSOR_POLICY_PREVENTION_ACTIONS_BY_DEVICES = 'SENSOR_POLICY_PREVENTION_ACTIONS_BY_DEVICES',
  SENSOR_POLICY_PREVENTION_ACTIONS_BY_USER = 'SENSOR_POLICY_PREVENTION_ACTIONS_BY_USER',
  THREAT_COUNTS_BY_DEVICE = 'THREAT_COUNTS_BY_DEVICE',
  THREAT_COUNTS_BY_PLATFORM = 'THREAT_COUNTS_BY_PLATFORM',
  WATCHLIST_HITS = 'WATCHLIST_HITS',
  WATCHLIST_HITS_BY_DEVICE = 'WATCHLIST_HITS_BY_DEVICE',
  WATCHLIST_HITS_BY_USERNAME = 'WATCHLIST_HITS_BY_USERNAME',
  WATCHLIST_HITS_COUNTS = 'WATCHLIST_HITS_COUNTS',
}
