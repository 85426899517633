/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { Dashboard } from './dashboard.model';

/**
 * DashboardSearchResponse
 *
 * @export
 * @class DashboardSearchResponse
 */
@Serializable
export class DashboardSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: Dashboard })
  public results: Dashboard[] = [];

  /**
   * Creates an instance of DashboardSearchResponse.
   * @param {any} args
   * @memberof DashboardSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
