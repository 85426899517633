/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';
import { cloneDeep, every, get, intersectionWith, isEmpty, isEqual, isUndefined, reduce, setWith, uniq } from 'lodash-es';

import { BucketingAttribute } from '@ws1c/intelligence-models/dashboard/bucketing-attribute.model';
import { CounterDefinition } from '@ws1c/intelligence-models/dashboard/counter-definition.model';
import { Counter } from '@ws1c/intelligence-models/dashboard/counter.model';
import { DashboardConfig } from '@ws1c/intelligence-models/dashboard/dashboard.config';
import { AggregationFunction, ComposeFunction } from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { TrendResult } from '@ws1c/intelligence-models/dashboard/trend-result.model';
import { Trend } from '@ws1c/intelligence-models/dashboard/trend.model';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { MetaFormField } from '@ws1c/intelligence-models/meta-form/meta-form-field.model';
import { CounterResult } from './counter-result.model';
import { ComposeConfig, ComposeConfigIndex } from './trend-composer.interface';

/**
 * TrendComposer
 * @export
 * @class TrendComposer
 */
export class TrendComposer {
  public static readonly defaultConfigIndex: ComposeConfigIndex = reduce(
    DashboardConfig.COMPOSE_CONFIGS_BY_STANDARD_DASHBOARD,
    (flatConfigs: ComposeConfigIndex, configs: ComposeConfigIndex) => {
      return {
        ...flatConfigs,
        ...configs,
      };
    },
    {},
  );

  /**
   * getAllTrendDefinitionDependencies
   * @param {string[]} subtypes
   * @param {ComposeConfigIndex} composeConfigsBySubtype
   * @returns {string[]}
   */
  public static getAllTrendDefinitionDependencies(
    subtypes: string[],
    composeConfigsBySubtype: ComposeConfigIndex = TrendComposer.defaultConfigIndex,
  ): string[] {
    const tc = new TrendComposer(composeConfigsBySubtype);
    return tc.getAllTrendDefinitionDependencies(subtypes);
  }

  /**
   * constructor
   * @param {ComposeConfigIndex} composeConfigsBySubtype
   */
  constructor(private composeConfigsBySubtype: ComposeConfigIndex = TrendComposer.defaultConfigIndex) {}

  /**
   * getAllTrendDefinitionDependencies
   * includes duplicates which are needed when tracking visible widgets
   * @param {string[]} subtypes
   * @returns {string[]}
   * @memberof TrendComposer
   */
  public getAllTrendDefinitionDependencies(subtypes: string[]): string[] {
    return subtypes.reduce((dependencies: string[], subtype: string) => {
      dependencies.push(...this.getTrendDefinitionDependencies(subtype));
      return dependencies;
    }, []);
  }

  /**
   * getTrendDefinitionDependencies
   * looks up dependencies for composed widget subtypes
   * @param {string} subType
   * @returns {string[]}
   * @memberof TrendComposer
   */
  public getTrendDefinitionDependencies(subType: string): string[] {
    const composedConfig = this.composeConfigsBySubtype[subType];
    if (!composedConfig) {
      return [subType];
    }
    const subtypesToLoad = composedConfig.dependencies.reduce((allSubtypes: string[], dependencySubtype: string) => {
      allSubtypes.push(...this.getTrendDefinitionDependencies(dependencySubtype));
      return allSubtypes;
    }, []);
    return uniq(subtypesToLoad);
  }

  /**
   * getSubtypeComposeConfigDependencies
   * @param {string} subtype
   * @returns {string[]}
   * @memberof TrendComposer
   */
  public getSubtypeComposeConfigDependencies(subtype: string): string[] {
    const composedConfig = this.composeConfigsBySubtype[subtype];
    if (!composedConfig) {
      return [];
    }
    const composeConfigDependencies = composedConfig.dependencies.reduce(
      (dependencies: string[], dependencySubtype: string) => {
        dependencies.push(...this.getSubtypeComposeConfigDependencies(dependencySubtype));
        return dependencies;
      },
      [subtype],
    );
    return uniq(composeConfigDependencies);
  }

  /**
   * getTrendForSubtype
   * recursively compose trends
   * @param {string} subType
   * @param {Map<string, Trend>} standardDashboardData
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public getTrendForSubtype(subType: string, standardDashboardData: Map<string, Trend>): Trend {
    const composedConfig = this.composeConfigsBySubtype[subType];
    if (!composedConfig) {
      return standardDashboardData.get(subType);
    }

    const resolvedDependencies = composedConfig.dependencies.map((dependencySubtype: string) => {
      const trendForSubtype = this.getTrendForSubtype(dependencySubtype, standardDashboardData);
      // A trend with an undefined trendDefinition is used to signify missing data after requests have been made
      return trendForSubtype && trendForSubtype.trendDefinition ? trendForSubtype : undefined;
    });

    return every(resolvedDependencies) ? this.compose(resolvedDependencies, composedConfig) : new Trend();
  }

  /**
   * compose
   * @param {Trend[]} dependencies
   * @param {ComposeConfig} composeConfig
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public compose(dependencies: Trend[], composeConfig: ComposeConfig): Trend {
    switch (composeConfig.composeFunction) {
      case ComposeFunction.SET_TREND_MODE:
        return this.setTrendMode(dependencies, composeConfig.composeFunctionParams);
      case ComposeFunction.SUM_ALL_COUNT:
        return this.sumAllCount(dependencies);
      case ComposeFunction.COUNT_RATIO:
        return this.countRatio(dependencies);
      case ComposeFunction.DIVIDE_BY_COUNT:
        return this.divideByCount(dependencies);
      case ComposeFunction.RENAME_COUNTERS:
        return this.renameCounters(dependencies, composeConfig.composeFunctionParams);
      case ComposeFunction.RENAME_COUNTER_LABELS:
        return this.renameCounterLabels(dependencies, composeConfig.composeFunctionParams);
      case ComposeFunction.COUNT_CHANGE:
        return this.countChange(dependencies);
      case ComposeFunction.DIFF_BY_BUCKETS:
        return this.diffByBuckets(dependencies);
      case ComposeFunction.SUM_BY_TIME_AND_BUCKETS:
        return this.sumByTimeAndBuckets(dependencies);
      case ComposeFunction.RATIO_BY_TIME_AND_BUCKETS:
        return this.ratioByTimeAndBuckets(dependencies);
      case ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES:
        return this.mergePreviousPeriodSeries(dependencies);
      case ComposeFunction.FLATTEN_BUCKETS:
        return this.flattenBuckets(dependencies);
      case ComposeFunction.MERGE_COUNTERS:
        return this.mergeCounters(dependencies);
      case ComposeFunction.MERGE_RESULTS:
        return this.mergeResults(dependencies);
      case ComposeFunction.MERGE_SERIES:
        return this.mergeSeries(dependencies, composeConfig.composeFunctionParams);
      case ComposeFunction.MERGE_BUCKETING_ATTRS:
        return this.mergeBucketingAttrs(dependencies, composeConfig.composeFunctionParams);
      case ComposeFunction.SET_LATEST_BY_COUNTER:
        return this.setLatestByCounter(dependencies, composeConfig.composeFunctionParams);
      case ComposeFunction.REMOVE_ZEROS:
        return this.removeZeros(dependencies);
      default:
        return dependencies[0];
    }
  }

  /**
   * setTrendMode
   * @param {Trend[]} dependencies
   * @param {any} composeFunctionParams
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public setTrendMode(dependencies: Trend[], composeFunctionParams: any): Trend {
    const replacedModeTrend = cloneDeep(dependencies[0]);
    replacedModeTrend.trendDefinition.trendMode = composeFunctionParams.toMode;
    return replacedModeTrend;
  }

  /**
   * sumAllCount
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public sumAllCount(dependencies: Trend[]): Trend {
    const nextTrend = cloneDeep(dependencies[0]);
    const totalSum = dependencies.reduce((allTrendSum: number, trend: Trend) => {
      return (
        allTrendSum +
        trend.trendResults?.reduce((trendSum: number, trendResult: TrendResult) => {
          return trendSum + (trendResult.counters[0].result.value || 0);
        }, 0)
      );
    }, 0);
    nextTrend.trendResults.length = 1;
    nextTrend.setFirstCounterValue(totalSum);
    return nextTrend;
  }

  /**
   * countRatio
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public countRatio(dependencies: Trend[]): Trend {
    const nextTrend = cloneDeep(dependencies[0]);
    const firstVal = dependencies[0].getFirstCounterValue();
    const secondVal = dependencies[1].getFirstCounterValue();

    // This is important because (NaN !== NaN)
    // The inequality sets off "ExpressionChangedAfterItHasBeenCheckedError" errors when binding
    const nextVal = firstVal / secondVal;
    if (!Number.isNaN(nextVal)) {
      nextTrend.trendResults.length = 1;
    }
    nextTrend.setFirstCounterValue(Number.isFinite(nextVal) ? nextVal : undefined);
    return nextTrend;
  }

  /**
   * divideByCount
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public divideByCount(dependencies: Trend[]): Trend {
    const nextTrend = cloneDeep(dependencies[0]);
    const count = dependencies[1].getFirstCounterValue();

    // Count aggregationFunctions convert dataType to integer, switch to AVG to avoid this conversion
    nextTrend.trendDefinition.counterDefinitions.forEach((counterDefinition: CounterDefinition) => {
      counterDefinition.aggregationFunction = AggregationFunction.AVG;
    });
    nextTrend.trendResults?.forEach((trendResult: TrendResult) => {
      trendResult.counters.forEach((counter: Counter) => {
        counter.definition.aggregationFunction = AggregationFunction.AVG;
        counter.result.value /= count;
        if (isNaN(counter.result.value)) {
          counter.result.value = undefined;
        }
        counter.result.reportColumnView.dataType = DataType[DataType.DOUBLE];
      });
    });

    return nextTrend;
  }

  /**
   * renameCounters
   * @param {Trend[]} dependencies
   * @param {GenericObject} composeFunctionParams
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public renameCounters(dependencies: Trend[], composeFunctionParams: GenericObject): Trend {
    const nextTrend = cloneDeep(dependencies[0]);
    nextTrend.trendDefinition.counterDefinitions.forEach((counterDefinition: CounterDefinition, index: number) => {
      counterDefinition.aggregateAttribute = composeFunctionParams.counterAttributes[index] || counterDefinition.aggregateAttribute;
    });
    nextTrend.trendResults?.forEach((trendResult: TrendResult) => {
      trendResult.counters.forEach((counter: Counter, index: number) => {
        counter.definition.aggregateAttribute = composeFunctionParams.counterAttributes[index] || counter.definition.aggregateAttribute;
      });
    });
    return nextTrend;
  }

  /**
   * renameCounterLabels
   * @param {Trend[]} dependencies
   * @param {GenericObject} composeFunctionParams
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public renameCounterLabels(dependencies: Trend[], composeFunctionParams: GenericObject): Trend {
    const nextTrend = cloneDeep(dependencies[0]);
    nextTrend.trendResults?.forEach((trendResult: TrendResult) => {
      trendResult.counters.forEach((counter: Counter, index: number) => {
        counter.result.reportColumnView.label = composeFunctionParams.counterLabels[index] || counter.result.reportColumnView.label;
      });
    });
    return nextTrend;
  }

  /**
   * countChange
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public countChange(dependencies: Trend[]): Trend {
    const nextTrend = cloneDeep(dependencies[0]);
    const firstVal = dependencies[0].getFirstCounterValue();
    const secondVal = dependencies[1].getFirstCounterValue();
    nextTrend.trendResults.length = 1;

    // This is important because (NaN !== NaN)
    // The inequality sets off "ExpressionChangedAfterItHasBeenCheckedError" errors when binding
    const nextVal = firstVal / secondVal - 1;
    nextTrend.setFirstCounterValue(isNaN(nextVal) ? undefined : nextVal);
    return nextTrend;
  }

  /**
   * diffByBuckets
   * @description Subtracts first counter values between trend results that share the same bucketing values
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public diffByBuckets(dependencies: Trend[]): Trend {
    const minuendTrend = dependencies[0];
    const subtrahendTrend = dependencies[1];
    const [minuendTrendResults, subtrahendTrendResults] = this.getCommonTrendResultsByBuckets(
      minuendTrend.trendResults,
      subtrahendTrend.trendResults,
    ).map((trendResults: TrendResult[]) => {
      return [...trendResults]?.sort((a: TrendResult, b: TrendResult) => a.bucketingAttributes[0].value - b.bucketingAttributes[0].value);
    });

    const newTrend: Trend = cloneDeep(minuendTrend);
    newTrend.trendResults = cloneDeep(minuendTrendResults);
    newTrend.trendResults?.forEach((trendResult: TrendResult, index: number) => {
      trendResult.setFirstCounterValue(
        minuendTrendResults[index].getFirstCounterValue() - subtrahendTrendResults[index].getFirstCounterValue(),
      );
    });
    return newTrend;
  }

  /**
   * sumByTimeAndBuckets
   * @param  {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public sumByTimeAndBuckets(dependencies: Trend[]): Trend {
    const orderedDependencies = [...dependencies].sort((t1: Trend, t2: Trend) => t2.trendResults?.length - t1.trendResults?.length);
    const firstTrend = orderedDependencies[0];
    const secondTrend = orderedDependencies[1];

    const secondTrendCache = this.cacheByTimeAndBuckets(secondTrend);
    const firstTrendClone = cloneDeep(firstTrend);
    firstTrendClone.trendResults?.forEach((trendResult: TrendResult) => {
      const firstTrendCount = trendResult.getFirstCounterValue();
      const cachePath = [
        trendResult.endMillis,
        ...trendResult.bucketingAttributes.map((bucketingAttribute: BucketingAttribute) => bucketingAttribute.value),
      ];
      const secondTrendCount = get(secondTrendCache, cachePath) || 0;
      trendResult.setFirstCounterValue(firstTrendCount + secondTrendCount);
    });
    return firstTrendClone;
  }

  /**
   * ratioByTimeAndBuckets
   * @param  {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public ratioByTimeAndBuckets(dependencies: Trend[]): Trend {
    const numeratorTrend = dependencies[0];
    const denomiatorTrend = dependencies[1];

    const denominatorTrendCache = this.cacheByTimeAndBuckets(denomiatorTrend);
    const numeratorTrendClone = cloneDeep(numeratorTrend);
    numeratorTrendClone.trendResults?.forEach((trendResult: TrendResult) => {
      const numeratorTrendCount = trendResult.getFirstCounterValue();

      // adjusts cachePath if denomiatorTrend has fewer bucketingAttributes
      const maxBuckets = denomiatorTrend.trendDefinition.bucketingAttributes
        ? denomiatorTrend.trendDefinition.bucketingAttributes.length
        : 0;
      const clippedBuckets = trendResult.bucketingAttributes.slice(0, maxBuckets);
      const cachePath = [
        trendResult.endMillis,
        ...clippedBuckets.map((bucketingAttribute: BucketingAttribute) => bucketingAttribute.value),
      ];
      const denominatorTrendCount = get(denominatorTrendCache, cachePath);

      // This is important because (NaN !== NaN)
      // The inequality sets off "ExpressionChangedAfterItHasBeenCheckedError" errors when binding
      const nextVal = denominatorTrendCount ? numeratorTrendCount / denominatorTrendCount : 0;
      trendResult.setFirstCounterValue(isNaN(nextVal) ? 0 : nextVal);
      trendResult.setFirstCounterDataType(DataType.PERCENTAGE);
    });
    return numeratorTrendClone;
  }

  /**
   * mergePreviousPeriodSeries
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public mergePreviousPeriodSeries(dependencies: Trend[]): Trend {
    const firstTrend = dependencies[0];
    const secondTrend = dependencies[1];

    const firstBucketingAttribute = Object.assign(new BucketingAttribute(), {
      reportColumnView: Object.assign(new MetaFormField(), {
        name: DashboardConfig.timePeriodBucketName,
        label: DashboardConfig.timePeriodBucketLabel,
        dataType: DataType[DataType.STRING],
      }),
      value: DashboardConfig.selectedTimePeriodName,
    });

    const firstModifiedResults = firstTrend.trendResults?.map((trendResult: TrendResult) => {
      return Object.assign(new TrendResult(), {
        ...trendResult,
        bucketingAttributes: [...trendResult.bucketingAttributes, firstBucketingAttribute],
      });
    });

    const secondBucketingAttribute = Object.assign(new BucketingAttribute(), {
      reportColumnView: Object.assign(new MetaFormField(), {
        name: DashboardConfig.timePeriodBucketName,
        label: DashboardConfig.timePeriodBucketLabel,
        dataType: DataType[DataType.STRING],
      }),
      value: DashboardConfig.previousTimePeriodName,
    });

    const secondModifiedResults = secondTrend.trendResults
      .slice(0, firstTrend.trendResults?.length)
      .map((trendResult: TrendResult, index: number) => {
        return Object.assign(new TrendResult(), {
          ...trendResult,
          bucketingAttributes: [secondBucketingAttribute, ...trendResult.bucketingAttributes],
          startMillis: firstTrend.trendResults?.[index].startMillis,
          endMillis: firstTrend.trendResults?.[index].endMillis,
        });
      });

    const mergedTrend = cloneDeep(firstTrend);
    if (this.isBucketingAttributesAvailable(mergedTrend)) {
      mergedTrend.trendDefinition.bucketingAttributes.unshift(DashboardConfig.timePeriodBucketName);
    }
    mergedTrend.trendResults = firstModifiedResults.concat(secondModifiedResults);
    mergedTrend.trendResults?.sort((result1: TrendResult, result2: TrendResult) => result1.startMillis - result2.startMillis);

    return mergedTrend;
  }

  /**
   * flattenBuckets
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public flattenBuckets(dependencies: Trend[]): Trend {
    const trend = cloneDeep(dependencies[0]);
    trend.trendDefinition.bucketingAttributes.splice(1, 1);
    trend.trendResults?.forEach((trendResult: TrendResult) => {
      if (trendResult.bucketingAttributes.length < 2) {
        return;
      }
      const firstBucketingAttribute = trendResult.bucketingAttributes.shift();
      const secondBucketingAttribute = trendResult.bucketingAttributes.shift();
      firstBucketingAttribute.value = `${firstBucketingAttribute.value}: ${secondBucketingAttribute.value}`;

      // 'STRING' describes the new dataType of the first bucket values
      // For example, a status_code value of 403 could become "403: Forbidden"
      firstBucketingAttribute.reportColumnView.dataType = 'STRING';
      trendResult.bucketingAttributes.unshift(firstBucketingAttribute);
    });
    return trend;
  }

  /**
   * mergeResults
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public mergeResults(dependencies: Trend[]): Trend {
    const mergedTrend = cloneDeep(dependencies[0]);
    mergedTrend.trendResults = dependencies.reduce((trendResults: TrendResult[], trend: Trend) => {
      trendResults?.push(...trend.trendResults);
      return trendResults;
    }, []);
    mergedTrend.trendResults?.sort((result1: TrendResult, result2: TrendResult) => result1.startMillis - result2.startMillis);

    return mergedTrend;
  }

  /**
   * mergeSeries
   * @param  {Trend[]} dependencies
   * @param  {any}     composeFunctionParams
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public mergeSeries(dependencies: Trend[], composeFunctionParams: any): Trend {
    const mergedTrend = cloneDeep(dependencies[0]);
    const newBucketingAttributes = this.isBucketingAttributesAvailable(mergedTrend)
      ? [...mergedTrend.trendDefinition.bucketingAttributes]
      : [];
    newBucketingAttributes.splice(composeFunctionParams.insertSeriesIndex, 0, composeFunctionParams.seriesName);
    if (this.isBucketingAttributesAvailable(mergedTrend)) {
      mergedTrend.trendDefinition.bucketingAttributes = newBucketingAttributes;
    }

    mergedTrend.trendResults = dependencies.reduce((trendResults: TrendResult[], trend: Trend, index: number) => {
      const seriesValue = composeFunctionParams.seriesValues[index];
      const namedTrendResults = trend.trendResults?.map((trendResult: TrendResult) => {
        const mergedBucketingAttribute = Object.assign(new BucketingAttribute(), {
          value: seriesValue,
          reportColumnView: Object.assign(new MetaFormField(), {
            name: composeFunctionParams.seriesName,
            dataType: composeFunctionParams.seriesDataType,
            label: composeFunctionParams.legendLabel,
          }),
        });
        const nextBucketingAttributes = [...trendResult.bucketingAttributes];
        nextBucketingAttributes.splice(composeFunctionParams.insertSeriesIndex, 0, mergedBucketingAttribute);

        return Object.assign(new TrendResult(), {
          ...trendResult,
          bucketingAttributes: nextBucketingAttributes,
        });
      });

      trendResults?.push(...namedTrendResults);
      return trendResults;
    }, []);
    mergedTrend.trendResults?.sort((result1: TrendResult, result2: TrendResult) => result1.startMillis - result2.startMillis);
    return mergedTrend;
  }

  /**
   * setLatestByCounter
   * @param  {Trend[]} dependencies - 0 to be dup-removed
   * @param  {any}     composeFunctionParams
   *                   1. bucketIndices: match bucket by indices ([0, 1, etc])
   *                   2. bucketOverrideIndex: copy bucket override by index (4,5 etc - usually next one after bucketIndices)
   *                   3. counterIndex: latest counter by index (0,1 etc)
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public setLatestByCounter(dependencies: Trend[], composeFunctionParams: any): Trend {
    const trend = cloneDeep(dependencies[0]);
    const trendResults = trend.trendResults?.reduce(
      (uniqueResults: TrendResult[], trendResult: TrendResult) => {
        let matched;
        for (const result of uniqueResults) {
          // dup match in buckets (0,1,2, etc) with latest counter (timestamp, etc)
          matched = false;
          if (
            isEmpty(composeFunctionParams.bucketIndices) ||
            composeFunctionParams.bucketIndices.every(
              (i) => result.bucketingAttributes[i].value === trendResult.bucketingAttributes[i].value,
            )
          ) {
            matched = true;
            // keep the latest and update
            if (
              result.counters[composeFunctionParams.counterIndex].result.value <
              trendResult.counters[composeFunctionParams.counterIndex].result.value
            ) {
              result.bucketingAttributes[composeFunctionParams.bucketOverrideIndex] =
                trendResult.bucketingAttributes[composeFunctionParams.bucketOverrideIndex];
              result.counters = trendResult.counters;
            }
            break;
          }
        }
        // no match then add
        if (!matched) {
          uniqueResults.push(trendResult);
        }
        return uniqueResults;
      },
      trend.trendResults?.[0] ? [trend.trendResults?.[0]] : [],
    );
    trend.trendResults = trendResults;
    return trend;
  }

  /**
   * mergeBucketingAttrs
   * @param  {Trend[]} dependencies - 0 to be merged, 1 to be bucketTrend
   * @param  {any}     composeFunctionParams
   *                   1. mergedBucketMatchIndices: merge match index by [0, 1, etc]
   *                   2. mergedBucketDefault: bucket's possible string value { value1: count1, value2: count2, etc }
   *                   3. mergedBucketName: new bucket name
   *                   4. mergedBucketIndex
   *                   5. mergedCounterIndex
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public mergeBucketingAttrs(dependencies: Trend[], composeFunctionParams: any): Trend {
    const mergedList = cloneDeep(dependencies[0]);
    mergedList.trendDefinition.bucketingAttributes.push(composeFunctionParams.mergedBucketName);
    mergedList.trendResults?.forEach((result: TrendResult) => {
      const extracted = dependencies[1].trendResults?.filter((lookFor: TrendResult) =>
        composeFunctionParams.mergedBucketMatchIndices.every(
          (i) => result.bucketingAttributes[i].value === lookFor.bucketingAttributes[i].value,
        ),
      );
      let total = 0;
      const mergedValue = { ...composeFunctionParams.mergedBucketDefault };
      extracted.forEach((extractedResult: TrendResult) => {
        const counterValue = extractedResult.counters[composeFunctionParams.mergedCounterIndex].result.value;
        mergedValue[extractedResult.bucketingAttributes[composeFunctionParams.mergedBucketIndex].value] = counterValue;
        total = total + counterValue;
      });
      mergedValue.Total = total;

      result.bucketingAttributes.push(
        new BucketingAttribute({
          reportColumnView: new MetaFormField({ name: composeFunctionParams.mergedBucketName }),
          value: mergedValue,
        }),
      );
    });
    return mergedList;
  }

  /**
   * mergeCounters
   * @param {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public mergeCounters(dependencies: Trend[]): Trend {
    const mergedTrend: Trend = cloneDeep(dependencies[0]);

    mergedTrend.trendDefinition.counterDefinitions = dependencies.reduce(
      (counterDefinitions: CounterDefinition[], trend: Trend): CounterDefinition[] => {
        return [...counterDefinitions, ...trend.trendDefinition.counterDefinitions];
      },
      [],
    );

    mergedTrend.trendResults = mergedTrend.trendResults?.map((trendResult: TrendResult) => {
      trendResult = cloneDeep(trendResult);
      return new TrendResult({
        ...trendResult,
        counters: dependencies.reduce((counters: Counter[], trend: Trend) => {
          let matchingResult = trend.trendResults?.find((otherTrendResult: TrendResult) => {
            return isEqual(trendResult.bucketingAttributes, otherTrendResult.bucketingAttributes);
          });
          if (!matchingResult) {
            // no match then copy counter definition into dummy counter with value 0
            const dummyCounters = [];
            trend.trendDefinition.counterDefinitions.forEach((definition) => {
              dummyCounters.push(
                new Counter({
                  definition,
                  result: new CounterResult({
                    reportColumnView: new MetaFormField({
                      name: definition.aggregateAttribute,
                    }),
                    value: 0,
                  }),
                }),
              );
            });
            matchingResult = new TrendResult({
              counters: dummyCounters,
            });
          }
          return [...counters, ...matchingResult.counters];
        }, []),
      });
    });

    return mergedTrend;
  }

  /**
   * removeZeros
   * @param  {Trend[]} dependencies
   * @returns {Trend}
   * @memberof TrendComposer
   */
  public removeZeros(dependencies: Trend[]): Trend {
    const noZeroTrend = cloneDeep(dependencies[0]);
    noZeroTrend.trendResults = noZeroTrend.trendResults?.filter((trendResult: TrendResult) => {
      return trendResult.counters.some((counter: Counter) => counter.result.value);
    });
    return noZeroTrend;
  }

  /**
   * cacheByTimeAndBuckets
   * @param {Trend} trend
   * @returns {any}
   * @memberof TrendComposer
   */
  public cacheByTimeAndBuckets(trend: Trend): any {
    const cache = {};
    trend.trendResults?.forEach((trendResult: TrendResult) => {
      const cachePath = [
        // Omits startMillis because rolling trendResults "startMillis" values depend on rollingWindowInterval
        trendResult.endMillis,
        ...trendResult.bucketingAttributes.map((bucketingAttribute: BucketingAttribute) => bucketingAttribute.value),
      ];
      const counterValue = trendResult.getFirstCounterValue();

      // Remove this block when INTEL-15038 is fixed
      // Right now, there are is a chance the endMillis is duplicated and the cachePath is the same
      // This just drops that value instead of overwriting it
      if (!isUndefined(get(cache, cachePath))) {
        return;
      }

      setWith(cache, cachePath, counterValue, (object) => object || {});
    });
    return cache;
  }

  /**
   * getCommonTrendResultsByBuckets
   * @param {...TrendResult[][]} trendResultsList
   * @returns {TrendResult[][]}
   * @memberof TrendComposer
   */
  public getCommonTrendResultsByBuckets(...trendResultsList: TrendResult[][]): TrendResult[][] {
    const commonBucketValues = intersectionWith(
      ...trendResultsList.map((trendResults: TrendResult[]) => {
        return trendResults?.map((trendResult: TrendResult) => {
          return trendResult.bucketingAttributes.map((attr: BucketingAttribute) => attr.value);
        });
      }),
      isEqual,
    );
    return trendResultsList.map((trendResults: TrendResult[]) => {
      return trendResults?.filter((trendResult: TrendResult) => {
        return commonBucketValues.some((bucketValues: any[]) => {
          return isEqual(
            bucketValues,
            trendResult.bucketingAttributes.map((attr: BucketingAttribute) => attr.value),
          );
        });
      });
    });
  }

  /**
   * isBucketingAttributesAvailable
   * @param {Trend} trend
   * @returns {boolean}
   * @memberof TrendComposer
   */
  private isBucketingAttributesAvailable = (trend: Trend) => {
    return !!(trend.trendDefinition && trend.trendDefinition.bucketingAttributes);
  };
}
