/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemPlaybookStepItemType
 * @export
 * @enum {string}
 */
export enum DeemPlaybookStepItemType {
  WIDGET = 'WIDGET',
  REPORT = 'REPORT',
  QUICK_ACTION = 'QUICK_ACTION',
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  RCA = 'RCA',
  DEVICE_TIMELINE = 'DEVICE_TIMELINE',
}
