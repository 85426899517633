/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * WidgetColorSchema
 * @export
 * @class WidgetColorSchema
 */
@Serializable
export class WidgetColorSchema {
  @JsonProperty('attribute_value')
  public name: string = undefined;

  @JsonProperty('color')
  public value: string = undefined;

  /**
   * constructor
   * @param {Partial<WidgetColorSchema>[]} args
   * @memberof WidgetColorSchema
   */
  constructor(...args: Array<Partial<WidgetColorSchema>>) {
    Object.assign(this, ...args);
  }
}
