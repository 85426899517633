/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { each } from 'lodash-es';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { ConnectorAuthType } from './connector-auth-type.enum';
import { VariableValue } from './variable-value.model';

/**
 * ConnectorConfigData
 * @export
 * @class ConnectorConfigData
 */
@Serializable
export class ConnectorConfigData {
  @JsonProperty('base_url')
  public baseUrl: string = undefined;

  @JsonProperty({ name: 'auth_config', customConverter: defaultValueConverterFactory() })
  public authConfig: GenericObject = undefined;

  @JsonProperty({ name: 'variables', customConverter: defaultValueConverterFactory() })
  public variables: Record<string, VariableValue> = undefined;

  /**
   * Creates an instance of ConnectorConfigData.
   * @param {...Array<Partial<ConnectorConfigData>>} args
   * @memberof ConnectorConfigData
   */
  constructor(...args: Array<Partial<ConnectorConfigData>>) {
    Object.assign(this, ...args);
  }

  /**
   * getConnectorConfigDataFromConfigForm
   * @param {GenericObject} configFormValue
   * @returns {ConnectorConfigData}
   * @memberof ConnectorConfigData
   */
  public static getConnectorConfigDataFromConfigForm(configFormValue: GenericObject): ConnectorConfigData {
    const { base_url: baseUrl, auth_config, variables } = configFormValue;
    const authConfig = { auth_type: auth_config.auth_type };
    each(auth_config, (value: any, key: string) => {
      if (value) {
        authConfig[key] = value;
      }
    });
    return new ConnectorConfigData({
      baseUrl,
      ...(authConfig.auth_type !== ConnectorAuthType.NOAUTH
        ? {
            authConfig,
          }
        : {}),
      variables,
    });
  }

  /**
   * getConfigFormFromConnectorConfigData
   * @param {ConnectorConfigData} configData
   * @returns {GenericObject}
   * @memberof ConnectorConfigData
   */
  public static getConfigFormFromConnectorConfigData(configData: ConnectorConfigData): GenericObject {
    const { baseUrl: base_url, authConfig: auth_config, variables } = configData;
    return {
      base_url,
      ...(!auth_config ? { auth_config: { auth_type: ConnectorAuthType.NOAUTH } } : { auth_config }),
      variables,
    };
  }
}
