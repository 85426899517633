/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dataBySectionConverter } from './data-by-section.converter';

/**
 * AutomationActionLookupRequest
 * @export
 * @class AutomationActionLookupRequest
 */
@Serializable
export class AutomationActionLookupRequest {
  @JsonProperty('text_extraction_expression')
  public textExtractionExpression: string = undefined;

  @JsonProperty('value_extraction_expression')
  public valueExtractionExpression: string = undefined;

  @JsonProperty({ name: 'connector_config_id', excludeToJson: true })
  public configId: string = undefined;

  @JsonProperty('action_template_id')
  public actionTemplateId: string = undefined;

  @JsonProperty('connector_config_id')
  public connectorConfigId: string = undefined;

  @JsonProperty({ name: 'dataBySection', customConverter: dataBySectionConverter })
  public dataBySection: any = {};

  /**
   * Creates an instance of AutomationActionLookupRequest
   *
   * @param {Array<Partial<AutomationActionLookupRequest>>} args
   * @memberof AutomationActionFieldLookupRequest
   */
  constructor(...args: Array<Partial<AutomationActionLookupRequest>>) {
    Object.assign(this, ...args);
  }
}
