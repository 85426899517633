/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { RequestStatusType } from './account/request-status-type.enum';
import { enumConverterFactory } from './converters/converter-factory';

/**
 * Csp Account Model
 *
 * @export
 * @class CspAccount
 */
@Serializable
export class CspAccount {
  @JsonProperty('account_id')
  public accountId: string = undefined;

  @JsonProperty('org_id')
  public orgId: string = undefined;

  @JsonProperty('csp_org_id')
  public cspOrgId: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty({ name: 'csp_migration_status', customConverter: enumConverterFactory(RequestStatusType) })
  public cspMigrationStatus: RequestStatusType = undefined;

  /**
   * Creates an instance of CspAccount
   * @param {Array<Partial<CspAccount>>} args - Initialization arguments for CspAccount
   * @memberof CspAccount
   */
  constructor(...args: Array<Partial<CspAccount>>) {
    Object.assign(this, ...args);
  }
}
