/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * MarketplaceResourceType
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceResourceType {
  AUTOMATION_TEMPLATE = 'Automation Template',
  DASHBOARD_TEMPLATE = 'Dashboard Template',
  REPORT_TEMPLATE = 'Report Template',
  SCRIPT_TEMPLATE = 'Script Template',
  SENSOR_TEMPLATE = 'Sensor Template',
  UEM_FREESTYLE_WORKFLOW_TEMPLATE = 'UEM Freestyle Workflow Template',
  WIDGET_TEMPLATE = 'Widget Template',
  WORKFLOW_CONNECTOR = 'Workflow Connector',
}
