/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AccountRoleUpdateWrapper } from './account-role-update-wrapper.model';

/**
 * BulkUpdateRoleAssignmentsRequest
 * @export
 * @class BulkUpdateRoleAssignmentsRequest
 */
@Serializable
export class BulkUpdateRoleAssignmentsRequest {
  @JsonProperty({ name: 'account_role_update_wrappers', cls: AccountRoleUpdateWrapper })
  public accountRoleUpdateWrappers: AccountRoleUpdateWrapper[] = undefined;

  /**
   * Initializes instance of BulkUpdateRoleAssignmentsRequest
   * @param {Array<Partial<BulkUpdateRoleAssignmentsRequest>>} args - object initialization arguments
   * @memberof BulkUpdateRoleAssignmentsRequest
   */
  constructor(...args: Array<Partial<BulkUpdateRoleAssignmentsRequest>>) {
    Object.assign(this, ...args);
  }
}
