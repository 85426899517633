/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * SolutionFilterTag
 * @export
 * @enum {string}
 */
export enum SolutionFilterTag {
  ANDROID = 'android',
  DESK_APP = 'desktop_app',
  DEVICE = 'device',
  HORIZON_APP = 'horizon_app',
  HORIZON_INFRASTRUCTURE = 'horizon_infrastructure',
  IOS = 'ios',
  MACOS = 'macos',
  MOBILE_APP = 'mobile_app',
  ORGANIZATION = 'organization',
  PLATFORM = 'platform',
  VIRTUAL = 'virtual',
  VIRTUAL_SESSION = 'virtual_session',
  WINDOWS = 'windows',
}
