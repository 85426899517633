/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * NetworkInsightsSearchRequest
 * @export
 * @class NetworkInsightsSearchRequest
 */
@Serializable
export class NetworkInsightsSearchRequest extends GenericSearchRequest {
  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty('start_date_millis')
  public startDateMillis: number = undefined;

  @JsonProperty('end_date_millis')
  public endDateMillis: number = undefined;
}
