/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * Defines report types.
 *
 * @export
 * @enum {number}
 */
export enum ReportType {
  HISTORICAL,
  SNAPSHOT,
  PREDEFINED,
}
