/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * DashboardSearchRequest
 * @export
 * @class DashboardSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class DashboardSearchRequest extends GenericSearchRequest {
  /**
   * Creates an instance of DashboardSearchRequest.
   * @param {any} args
   * @memberof DashboardSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}
