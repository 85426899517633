/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { RoleScopeSet } from './role-scope-set.model';

/**
 * ScopeSetSearchResponse
 * @export
 * @class ScopeSetSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class ScopeSetSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: RoleScopeSet })
  public results: RoleScopeSet[] = undefined;

  /**
   * Initializes instance of ScopeSetSearchResponse
   * @param {Array<Partial<ScopeSetSearchResponse>>} args - object initialization arguments
   * @memberof ScopeSetSearchResponse
   */
  constructor(...args: Array<Partial<ScopeSetSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
