/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Widget Position Model Object
 * Widget position in an M rows x N columns layout in a dashboard.
 *
 * @export
 * @class WidgetPosition
 */
@Serializable
export class WidgetPosition {
  @JsonProperty('column_number')
  public columnNumber: number = undefined;

  @JsonProperty('row_number')
  public rowNumber: number = undefined;

  /**
   * Creates an instance of WidgetPosition.
   * @param {...Array<Partial<WidgetPosition>>} args
   * @memberof WidgetPosition
   */
  constructor(...args: Array<Partial<WidgetPosition>>) {
    Object.assign(this, ...args);
  }
}
