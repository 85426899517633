/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { find, isEmpty } from 'lodash-es';

import { MetaFormFieldPresentationType } from './meta-form-field-presentation-type.enum';
import { ChoiceValue, MetaFormField } from './meta-form-field.model';
import { MetaForm } from './meta-form.model';
import { FieldsMetaForm } from './meta-form.types';

/**
 * isFieldDisplayed
 *
 * @param {UntypedFormGroup} formGroup
 * @param {FieldsMetaForm|MetaForm} metaForm
 * @param {MetaFormField} field
 * @param {boolean} displayHidden
 * @returns {boolean}
 */
export function isFieldDisplayed(
  formGroup: UntypedFormGroup,
  metaForm: FieldsMetaForm | MetaForm,
  field: MetaFormField,
  displayHidden: boolean = false,
): boolean {
  if (!formGroup || (!displayHidden && field.presentationType === MetaFormFieldPresentationType.HIDDEN)) {
    return false;
  }
  // Field doesn't have any associatedDisplayField should be always visible
  if (!field.associatedDisplayField) {
    return true;
  }
  // If field has associatedDisplayField that doesn't have any choice values then it should be always visible.
  const associatedDisplayField: MetaFormField = find(
    metaForm.fields,
    (metaField: MetaFormField) => metaField.name === field.associatedDisplayField,
  );
  if (!associatedDisplayField || !associatedDisplayField.choiceValues.length) {
    return true;
  }
  // In case associatedDisplayField has multiple choices
  const associatedControl: AbstractControl = formGroup.get([field.associatedDisplayField]);
  const associatedValue = associatedControl ? associatedControl.value : associatedDisplayField.defaultValue;
  // Make sure current associatedDisplayField is visible first then check for matching associatedValue value
  if (!isFieldDisplayed(formGroup, metaForm, associatedDisplayField)) {
    return false;
  }
  return associatedDisplayField.choiceValues.some((choice: ChoiceValue) => {
    if (choice.value !== associatedValue) {
      return false;
    }
    if (isEmpty(choice.associatedDisplayFields)) {
      return false;
    }
    return choice.associatedDisplayFields.includes(field.name);
  });
}
