/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import base64 from 'base-64';
import utf8 from 'utf8';

/**
 * decodes base64 encoded value
 *
 * @param {string} encoded
 * @returns {string}
 */
export const decodeAsString = (encoded: string): string => {
  const bytes = base64.decode(encoded);
  return utf8.decode(bytes);
};
