/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, JsonProperty, Serializable } from '@dpa/ui-common';
import { sortBy } from 'lodash-es';

/**
 * networkInsightsUrlRowConverter
 */
export const networkInsightsUrlRowConverter: CustomConverter = {
  fromJson(data: string[]): Array<{ url: string }> {
    return data.map((url: string) => ({ url }));
  },
  toJson(rows: Array<{ url: string }>): string[] {
    return rows.map((row: any) => row.url);
  },
};

/**
 * NetworkInsightsUrlResponse
 * @export
 * @class NetworkInsightsUrlResponse
 */
@Serializable
export class NetworkInsightsUrlResponse {
  @JsonProperty({ name: 'data', customConverter: networkInsightsUrlRowConverter, excludeToJson: true })
  public results: any[] = undefined;

  /**
   * constructor
   * @param {any} args
   * @memberof NetworkInsightsUrlResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * getSortedUrlResponse
   * @returns {NetworkInsightsUrlResponse}
   * @memberof NetworkInsightsUrlResponse
   */
  public getSortedUrlResponse(): NetworkInsightsUrlResponse {
    const nextResults = sortBy(this.results, 'url');
    return new NetworkInsightsUrlResponse(this, {
      results: nextResults,
    });
  }
}
