/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Feature Category Type
 *
 * @export
 * @enum {string}
 */
export enum FeatureCategoryType {
  HORIZON_APP_MANAGEMENT = 'HORIZON_APP_MANAGEMENT',
  HORIZON_AZURE_SERVICE = 'HORIZON_AZURE_SERVICE',
  HORIZON_DESKTOP_SERVICE = 'HORIZON_DESKTOP_SERVICE',
  INTELLIGENCE_AUTOMATION = 'INTELLIGENCE_AUTOMATION',
  INTELLIGENCE_APPS_ENGAGEMENT = 'INTELLIGENCE_APPS_ENGAGEMENT',
  INTELLIGENCE_CONSUMER_APPS = 'INTELLIGENCE_CONSUMER_APPS',
  INTELLIGENCE_CRASH_REPORTING = 'INTELLIGENCE_CRASH_REPORTING',
  INTELLIGENCE_DIGITAL_EXPERIENCE = 'INTELLIGENCE_DIGITAL_EXPERIENCE',
  INTELLIGENCE_EXTENDED_SERVICES = 'INTELLIGENCE_EXTENDED_SERVICES',
  INTELLIGENCE_GAIN_VISIBILITY = 'INTELLIGENCE_GAIN_VISIBILITY',
  INTELLIGENCE_MANAGE_APPS = 'INTELLIGENCE_MANAGE_APPS',
  INTELLIGENCE_NETWORK_INSIGHT = 'INTELLIGENCE_NETWORK_INSIGHT',
  INTELLIGENCE_RBAC = 'INTELLIGENCE_RBAC',
  INTELLIGENCE_SECURITY = 'INTELLIGENCE_SECURITY',
  INTELLIGENCE_USER_FLOWS = 'INTELLIGENCE_USER_FLOWS',
}

/**
 * Feature Setup Id
 *
 * @export
 * @enum {string}
 */
export enum FeatureSetupId {
  CSP_ADMIN_INVITATION_SETUP = 'csp.admin.invitation.setup',
  CSP_FEDERATION_USERS_SETUP = 'csp.federation.users.setup',
  INTELLIGENCE_RBAC_SETUP = 'intelligence.rbac.setup',
  INTELLIGENCE_REPORT_CUSTOM_SETUP = 'intelligence.report.custom.setup',
}

/**
 * Feature Setup Type
 *
 * @export
 * @enum {string}
 */
export enum FeatureSetupType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

/**
 * Integrated Service Type
 *
 * @export
 * @enum {string}
 */
export enum IntegratedServiceType {
  HUB_SERVICES = 'HUB_SERVICES',
  HORIZON = 'HORIZON',
  INTELLIGENCE = 'INTELLIGENCE',
  WS1_ACCESS = 'WS1_ACCESS',
  WS1_UEM = 'WS1_UEM',
}

/**
 * ServiceMode
 *
 * @export
 * @enum {number}
 */
export enum ServiceMode {
  ALL_SERVICES,
  ENTITLED_SERVICES,
}
