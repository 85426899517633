/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Params } from '@angular/router';

import { Device } from '@ws1c/intelligence-models/device.model';

/**
 * getDeviceFromRouteParams
 * @param {Params} queryParams
 * @param {Params} params
 * @returns {Device}
 */
export function getDeviceFromRouteParams(queryParams: Params, params?: Params): Device {
  return new Device({
    id: queryParams?.id ?? params?.id,
    name: queryParams?.name,
    model: queryParams?.model,
    platform: queryParams?.platform,
    serialNumber: queryParams?.serialNumber,
    integration: queryParams?.integration,
  });
}
