/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SolutionConfigType } from './solution.enum';

export const SOLUTION_ACCORDION_WIZARD_BY_ID: Record<string, Record<string, string>> = {
  [SolutionConfigType.CVE]: {
    CVE_DEFINE_DATA_SOURCE: 'CVE_DEFINE_DATA_SOURCE',
    REVIEW_SETTINGS: 'REVIEW_SETTINGS',
  },
  [SolutionConfigType.DEEM_V2]: {
    DEEM_V2_DESKTOP: 'DEEM_V2_DESKTOP',
    DEEM_V2_MOBILE: 'DEEM_V2_MOBILE',
    DEEM_V2_MOBILE_APP: 'DEEM_V2_MOBILE_APP',
    DEEM_V2_HORIZON: 'DEEM_V2_HORIZON',
    DEEM_V2_FRONTLINE: 'DEEM_V2_FRONTLINE',
  },
};

export const SOLUTION_ACCORDION_WIZARD_IDS: Record<string, string[]> = {
  [SolutionConfigType.CVE]: ['CVE_DEFINE_DATA_SOURCE', 'REVIEW_SETTINGS'],
  [SolutionConfigType.DEEM_V2]: ['DEEM_V2_DESKTOP', 'DEEM_V2_MOBILE', 'DEEM_V2_HORIZON', 'DEEM_V2_FRONTLINE'],
};
