/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { OrgHealthMetric } from './org/org-health-metric.model';

/**
 * Integration Health Report Model Object
 *
 * @export
 * @class IntegrationHealthReport
 */
@Serializable
export class IntegrationHealthReport {
  @JsonProperty('org_identifier')
  public orgIdentifier: string = undefined;

  @JsonProperty({ name: 'statistics_metrics', cls: OrgHealthMetric })
  public metrics: OrgHealthMetric[] = undefined;

  /**
   * Creates an instance of IntegrationHealthReport
   * @param {Array<Partial<IntegrationHealthReport>>} args - initialization arguments for IntegrationHealthReport object
   * @memberof IntegrationHealthReport
   */
  constructor(...args: Array<Partial<IntegrationHealthReport>>) {
    Object.assign(this, ...args);
  }
}
