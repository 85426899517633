/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserAccess } from '@ws1c/intelligence-models/common/user-access.model';

/**
 * Report Share
 * @export
 * @class ReportShare
 */
@Serializable
export class ReportShare {
  @JsonProperty('report_id')
  public reportId: string = undefined;

  @JsonProperty({ name: 'recipients', cls: UserAccess })
  public reportRecipients: UserAccess[] = undefined;

  /**
   * Creates an instance of ReportShare.
   * @param {any} args
   * @memberof ReportShare
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
