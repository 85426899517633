/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * ApteligentCrumbType
 * @export
 * @enum {string}
 */
export enum ApteligentCrumbType {
  CUSTOM_EVENT = 'CUSTOM_EVENT',
  NET_ERROR = 'NET_ERROR',
  NET_EVENT = 'NET_EVENT',
  HANDLED_EXCEPTION = 'HANDLED_EXCEPTION',
  PLUGIN_EXCEPTION = 'PLUGIN_EXCEPTION',
  CRASH = 'CRASH',
}
