/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ErrorHandlingConfig
 * @export
 * @class ErrorHandlingConfig
 */
@Serializable
export class ErrorHandlingConfig {
  @JsonProperty('http_max_retry_count')
  public httpMaxRetryCount: number = undefined;

  @JsonProperty('http_max_retry_millis')
  public httpMaxTimeoutMillis: number = undefined;

  @JsonProperty('max_historical_concurrent_requests_per_action')
  public maxHistoricalConcurrentRequestsPerAction: number = undefined;

  @JsonProperty('max_incoming_concurrent_requests_per_action')
  public maxIncomingConcurrentRequestsPerAction: number = undefined;

  @JsonProperty('workflow_max_retry_count')
  public workflowMaxRetryCount: number = undefined;

  @JsonProperty('workflow_retry_timeout_millis')
  public workflowRetryTimeoutMillis: number = undefined;

  @JsonProperty('terminal_error_codes')
  public terminalErrorCodes: string[] = [];

  @JsonProperty('retryable_error_codes')
  public retryableErrorCodes: string[] = [];

  /**
   * Creates an instance of ErrorHandlingConfig
   *
   * @param {Array<Partial<ErrorHandlingConfig>>} args
   * @memberof ErrorHandlingConfig
   */
  constructor(...args: Array<Partial<ErrorHandlingConfig>>) {
    Object.assign(this, ...args);
  }
}
