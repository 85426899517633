/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { MetaFormField } from '@ws1c/intelligence-models/meta-form';
import { AutomationActionFieldForLookup } from './automation-action-field-for-lookup.model';

/**
 * AutomationActionFieldLookupRequestPayload
 * @export
 * @class AutomationActionFieldLookupRequestPayload
 */
@Serializable
export class AutomationActionFieldLookupRequestPayload {
  @JsonProperty({ name: 'fieldForLookup', cls: AutomationActionFieldForLookup })
  public fieldForLookup: AutomationActionFieldForLookup = undefined;

  @JsonProperty({ name: 'selectedLookupValue', cls: MetaFormField })
  public selectedLookupValue?: Partial<MetaFormField> = undefined;

  @JsonProperty('query')
  public query?: string = undefined;

  /**
   * Creates an instance of AutomationActionFieldLookupRequestPayload
   * @param {Array<Partial<AutomationActionFieldLookupRequestPayload>>} args
   * @memberof AutomationActionFieldLookupRequestPayload
   */
  constructor(...args: Array<Partial<AutomationActionFieldLookupRequestPayload>>) {
    Object.assign(this, ...args);
  }
}
