/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */
import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory, enumUpperCaseConverterFactory } from '@ws1c/intelligence-models/converters';
import { StandardDashboardType } from './dashboard.enum';
import { WidgetPreference } from './widget-preferences.model';

/**
 * widgetAttributePreferencesConverter
 * converts multi WidgetPreference data to Record of widgetId and WidgetPreference array
 */
export const widgetAttributePreferencesConverter: CustomConverter = {
  fromJson(data: GenericObject): Record<string, WidgetPreference[]> {
    if (!data) {
      return;
    }
    return Object.keys(data).reduce((accum: GenericObject, key: string) => {
      accum[key] = data[key].map((widgetPreference: WidgetPreference) => deserialize(WidgetPreference, widgetPreference));
      return accum;
    }, {});
  },
  toJson(): GenericObject {
    return undefined;
  },
};

/**
 * Standard Dashboard Model Object
 *
 * @export
 * @class StandardDashboard
 */
@Serializable
export class StandardDashboard {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'dashboard_type', customConverter: enumUpperCaseConverterFactory(StandardDashboardType) })
  public dashboardType: string = undefined;

  @JsonProperty({ name: 'data_projection_fields', customConverter: defaultValueConverterFactory() })
  public dataProjectionFields: Record<string, string[]> = {};

  @JsonProperty({ name: 'trend_definition_map', customConverter: defaultValueConverterFactory() })
  public trendDefinitionMap: any = undefined;

  @JsonProperty({ name: 'user_widget_attribute_preferences', customConverter: widgetAttributePreferencesConverter })
  public widgetAttributePreferences: Record<string, WidgetPreference[]> = undefined;

  /**
   * Creates an instance of StandardDashboard
   * @param {Partial<StandardDashboard>} args
   * @memberOf StandardDashboard
   */
  constructor(...args: Array<Partial<StandardDashboard>>) {
    Object.assign(this, ...args);
  }
}
