/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * MetaFormEnum
 *
 * @export
 * @enum {string}
 */
export enum MetaFormType {
  FIELDS = 'fields',
  SECTIONS = 'sections',
}
