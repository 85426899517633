/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * CompatibilityResponse
 * @export
 * @class CompatibilityResponse
 */
@Serializable
export class CompatibilityResponse {
  @JsonProperty('compatible_actions')
  public compatibleActions: string[] = [];

  /**
   * Creates an instance of CompatibilityResponse
   * @param {Array<Partial<CompatibilityResponse>>} args
   * @memberof CompatibilityResponse
   */
  constructor(...args: Array<Partial<CompatibilityResponse>>) {
    Object.assign(this, ...args);
  }
}
