/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * TrustNetworkPartnerType
 * Enum of trust network partners which are supported in the UI.
 *
 * @export
 * @enum {string}
 */
export enum TrustNetworkPartnerType {
  BETTERMOBILE = 'BETTERMOBILE',
  CHECKPOINT = 'CHECKPOINT',
  CROWDSTRIKE = 'CROWDSTRIKE',
  EXTREME_NETWORKS = 'EXTREME_NETWORKS',
  PRADEO = 'PRADEO',
  WANDERA = 'WANDERA',
  ZIMPERIUM = 'ZIMPERIUM',
}

/**
 * TrustNetworkPartnerTypeDisplay
 * Enum mapping of UI display string for trust network partners.
 *
 * @export
 * @enum {string}
 */
export enum TrustNetworkPartnerTypeDisplay {
  BETTERMOBILE = 'BETTER Mobile',
  CHECKPOINT = 'Check Point',
  CROWDSTRIKE = 'CrowdStrike',
  EXTREME_NETWORKS = 'Extreme Networks',
  PRADEO = 'Pradeo',
  WANDERA = 'Wandera',
  ZIMPERIUM = 'Zimperium',
}

/**
 * TrustNetworkPartnerConnectionModalMode
 * Enum for maintaining different modal flows for Trust Network Partner connections.
 *
 * @export
 * @enum {number}
 */
export enum TrustNetworkPartnerConnectionModalMode {
  CLOSE,
  DEAUTHORIZE,
  SETUP_OR_EDIT,
}

/**
 * TrustNetworkPartnerConnectionModalSection
 * Enum for maintaining different accordian section inside modal for
 * Trust Network Partner connections.
 *
 * @export
 * @enum {number}
 */
export enum TrustNetworkPartnerConnectionModalSection {
  ACKNOWLEDGE,
  CREDENTIALS,
  NONE,
  SETUP_OR_EDIT,
}
