/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { DeviceAppsCrumb } from './device-apps-crumb.model';
import { DeviceCrumbType } from './device-crumb-type.enum';
import { DeviceDevicePerformanceCrumb } from './device-device-performance-crumb.model';
import { DeviceDevicesCrumb } from './device-devices-crumb.model';
import { DeviceDisplayCrumb } from './device-display-crumb.model';
import { DeviceNetworkCrumb } from './device-network-crumb.model';
import { DeviceOsUpdatesCrumb } from './device-os-updates-crumb.model';
import { DeviceServicesCrumb } from './device-services-crumb.model';
import { DeviceUserActionsCrumb } from './device-user-actions-crumb.model';

/**
 * DeviceCrumb
 * @export
 * @class DeviceCrumb
 */
export class DeviceCrumb {
  public crumbType: DeviceCrumbType;
  // eslint-disable-next-line max-len
  public crumb:
    | DeviceAppsCrumb
    | DeviceDevicesCrumb
    | DeviceDevicePerformanceCrumb
    | DeviceDisplayCrumb
    | DeviceNetworkCrumb
    | DeviceOsUpdatesCrumb
    | DeviceServicesCrumb
    | DeviceUserActionsCrumb;

  /**
   * Creates an instance of DeviceCrumb.
   * @param {...Array<Partial<DeviceCrumb>>} args
   * @memberof DeviceCrumb
   */
  constructor(...args: Array<Partial<DeviceCrumb>>) {
    Object.assign(this, ...args);
  }

  /**
   * getCrumbEventCategory
   * EventCategorys used to group together crumbs, used by dpa-devices-profile-device-event-crumbs
   * @returns {DeviceCrumbType}
   * @memberof DeviceCrumb
   */
  public getCrumbEventCategory(): DeviceCrumbType {
    return DeviceCrumbType[this.crumbType];
  }

  /**
   * isCrumbLinkVisible
   * Used to determine if the crumb has a visible link in dpa-user-flows-crumbs
   * @returns {boolean}
   * @memberof DeviceCrumb
   */
  public isCrumbLinkVisible(): boolean {
    return [].includes(this.crumbType);
  }

  /**
   * getCrumbLabelKey
   * @returns {string}
   * @memberof DeviceCrumb
   */
  public getCrumbLabelKey(): string {
    return this.crumb.name;
  }
}
