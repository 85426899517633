/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * UserGroupDescriptor
 * @export
 * @class UserGroupDescriptor
 */
@Serializable
export class UserGroupDescriptor {
  @JsonProperty('directory_group_id')
  public directoryGroupId: string = undefined;

  @JsonProperty('directory_id')
  public directoryId: string = undefined;

  @JsonProperty('directory_type')
  public directoryType: string = undefined;

  /**
   * Creates an instance of UserGroupDescriptor.
   * @param { Array<Partial<UserGroupDescriptor>} args
   * @memberof UserGroupDescriptor
   */
  constructor(...args: Array<Partial<UserGroupDescriptor>>) {
    Object.assign(this, ...args);
  }
}
