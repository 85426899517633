/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppErrorType
 * @export
 * @enum {string}
 */
export enum AppErrorType {
  CRASH = 'CRASH',
  HANDLED_EXCEPTION = 'HANDLED_EXCEPTION',
  PLUGIN_EXCEPTION = 'PLUGIN_EXCEPTION',
}
