/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * ThresholdDatatype
 * @export
 * @enum {string}
 */
export enum ThresholdDatatype {
  BITRATE = 'BITRATE',
  NUMBER = 'NUMBER',
  TIME = 'TIME',
}
