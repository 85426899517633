/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { SolutionSettingMetricDetails } from '@ws1c/intelligence-models/solution/solution-setting-metric-details.model';
import { RawThresholdAttribute } from '@ws1c/intelligence-models/threshold';
import { REVERSED_THRESHOLD_ATTRIBUTES } from '@ws1c/intelligence-models/threshold/reversed-threshold-attributes';

/**
 * getThresholdsByMetricByEntity
 * @param {SolutionSettingMetricDetails[]} metricDetails
 * @returns {Record<string, Record<string, SolutionSettingMetricDetails[]>>}
 */
export const getThresholdsByMetricByEntity = (
  metricDetails: SolutionSettingMetricDetails[],
): Record<string, Record<string, SolutionSettingMetricDetails[]>> => {
  return metricDetails.reduce(
    (result: Record<string, Record<string, SolutionSettingMetricDetails[]>>, settingMetric: SolutionSettingMetricDetails) => {
      const entity = settingMetric.metricKey.solutionSetting;
      const metric = settingMetric.metricKey.metricName;

      settingMetric.metricHolder.threshold.isReversed = REVERSED_THRESHOLD_ATTRIBUTES.includes(metric as RawThresholdAttribute);

      if (!result[entity]) {
        result[entity] = {};
      }

      if (!result[entity][metric]) {
        result[entity][metric] = [];
      }

      result[entity][metric].push(settingMetric);

      return result;
    },
    {},
  );
};
