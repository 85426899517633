/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NetworkDashboardType
 * @export
 * @enum {string}
 */
export enum NetworkDashboardType {
  // For Network Insight Dashboard
  NETWORK_INSIGHTS = 'NETWORK_INSIGHTS',
}

/**
 * NetworkWidgetSubtype
 * @export
 * @enum {string}
 */
export enum NetworkWidgetSubtype {
  // For Network Insight Dashboard
  __NETWORK_INSIGHTS_TOTAL_ERROR_COUNT = '__NETWORK_INSIGHTS_TOTAL_ERROR_COUNT',
  __NETWORK_INSIGHTS_TOTAL_ERROR = '__NETWORK_INSIGHTS_TOTAL_ERROR',
  __NETWORK_INSIGHTS_TOTAL_COUNT = '__NETWORK_INSIGHTS_TOTAL_COUNT',
  __NETWORK_INSIGHTS_TOTAL = '__NETWORK_INSIGHTS_TOTAL',
  __NETWORK_INSIGHTS_ERROR_RATE = '__NETWORK_INSIGHTS_ERROR_RATE',
  __NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL = '__NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL',
  __NETWORK_INSIGHTS_TOTAL_ERROR_BOTH_PERIODS = '__NETWORK_INSIGHTS_TOTAL_ERROR_BOTH_PERIODS',
  __NETWORK_INSIGHTS_TOTAL_BOTH_PERIODS = '__NETWORK_INSIGHTS_TOTAL_BOTH_PERIODS',
  __NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL_BOTH_PERIODS = '__NETWORK_INSIGHTS_ERROR_RATE_HISTORICAL_BOTH_PERIODS',
  __NETWORK_INSIGHTS_AVG_LATENCY_BOTH_PERIODS = '__NETWORK_INSIGHTS_AVG_LATENCY_BOTH_PERIODS',
  __NETWORK_INSIGHTS_BYTES_RECEIVED_BOTH_PERIODS = '__NETWORK_INSIGHTS_BYTES_RECEIVED_BOTH_PERIODS',
  __NETWORK_INSIGHTS_BYTES_SENT_BOTH_PERIODS = '__NETWORK_INSIGHTS_BYTES_SENT_BOTH_PERIODS',

  __NETWORK_INSIGHTS_TOTAL_ERROR_BY_APP_VERSION = '__NETWORK_INSIGHTS_TOTAL_ERROR_BY_APP_VERSION',
  __NETWORK_INSIGHTS_TOTAL_COUNT_BY_APP_VERSION = '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_APP_VERSION',
  __NETWORK_INSIGHTS_ERROR_RATE_BY_APP_VERSION = '__NETWORK_INSIGHTS_ERROR_RATE_BY_APP_VERSION',
  __NETWORK_INSIGHTS_TOTAL_ERROR_BY_SYSTEM_VERSION = '__NETWORK_INSIGHTS_TOTAL_ERROR_BY_SYSTEM_VERSION',
  __NETWORK_INSIGHTS_TOTAL_COUNT_BY_SYSTEM_VERSION = '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_SYSTEM_VERSION',
  __NETWORK_INSIGHTS_ERROR_RATE_BY_SYSTEM_VERSION = '__NETWORK_INSIGHTS_ERROR_RATE_BY_SYSTEM_VERSION',
  __NETWORK_INSIGHTS_TOTAL_ERROR_BY_CARRIER = '__NETWORK_INSIGHTS_TOTAL_ERROR_BY_CARRIER',
  __NETWORK_INSIGHTS_TOTAL_COUNT_BY_CARRIER = '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_CARRIER',
  __NETWORK_INSIGHTS_ERROR_RATE_BY_CARRIER = '__NETWORK_INSIGHTS_ERROR_RATE_BY_CARRIER',
  __NETWORK_INSIGHTS_TOTAL_ERROR_BY_DEVICE_MODEL = '__NETWORK_INSIGHTS_TOTAL_ERROR_BY_DEVICE_MODEL',
  __NETWORK_INSIGHTS_TOTAL_COUNT_BY_DEVICE_MODEL = '__NETWORK_INSIGHTS_TOTAL_COUNT_BY_DEVICE_MODEL',
  __NETWORK_INSIGHTS_ERROR_RATE_BY_DEVICE_MODEL = '__NETWORK_INSIGHTS_ERROR_RATE_BY_DEVICE_MODEL',
  __NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_IOS = '__NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_IOS',
  // eslint-disable-next-line max-len
  __NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_ANDROID = '__NETWORK_INSIGHTS_TOTAL_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT_ANDROID',
  __NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT = '__NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE_FLAT',
  __NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE_FLAT = '__NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE_FLAT',

  NETWORK_INSIGHTS_NET_ERRORS_BY_APP_VERSION = 'NETWORK_INSIGHTS_NET_ERRORS_BY_APP_VERSION',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_APP_VERSION = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_APP_VERSION',
  NETWORK_INSIGHTS_NET_EVENT_BY_APP_VERSION = 'NETWORK_INSIGHTS_NET_EVENT_BY_APP_VERSION',
  NETWORK_INSIGHTS_NET_ERRORS_BY_SYSTEM_VERSION = 'NETWORK_INSIGHTS_NET_ERRORS_BY_SYSTEM_VERSION',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_SYSTEM_VERSION = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_SYSTEM_VERSION',
  NETWORK_INSIGHTS_NET_ERRORS_BY_CARRIER = 'NETWORK_INSIGHTS_NET_ERRORS_BY_CARRIER',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_CARRIER = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_CARRIER',
  NETWORK_INSIGHTS_NET_ERRORS_BY_DEVICE_MODEL = 'NETWORK_INSIGHTS_NET_ERRORS_BY_DEVICE_MODEL',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_DEVICE_MODEL = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_DEVICE_MODEL',

  NETWORK_INSIGHTS_AVG_LATENCY_BY_APP_VERSION = 'NETWORK_INSIGHTS_AVG_LATENCY_BY_APP_VERSION',
  NETWORK_INSIGHTS_AVG_LATENCY_BY_SYSTEM_VERSION = 'NETWORK_INSIGHTS_AVG_LATENCY_BY_SYSTEM_VERSION',
  NETWORK_INSIGHTS_AVG_LATENCY_BY_CARRIER = 'NETWORK_INSIGHTS_AVG_LATENCY_BY_CARRIER',
  NETWORK_INSIGHTS_AVG_LATENCY_BY_DEVICE_MODEL = 'NETWORK_INSIGHTS_AVG_LATENCY_BY_DEVICE_MODEL',

  NETWORK_INSIGHTS_BYTES_RECEIVED_BY_APP_VERSION = 'NETWORK_INSIGHTS_BYTES_RECEIVED_BY_APP_VERSION',
  NETWORK_INSIGHTS_BYTES_RECEIVED_BY_SYSTEM_VERSION = 'NETWORK_INSIGHTS_BYTES_RECEIVED_BY_SYSTEM_VERSION',
  NETWORK_INSIGHTS_BYTES_RECEIVED_BY_CARRIER = 'NETWORK_INSIGHTS_BYTES_RECEIVED_BY_CARRIER',
  NETWORK_INSIGHTS_BYTES_RECEIVED_BY_DEVICE_MODEL = 'NETWORK_INSIGHTS_BYTES_RECEIVED_BY_DEVICE_MODEL',

  NETWORK_INSIGHTS_BYTES_SENT_BY_APP_VERSION = 'NETWORK_INSIGHTS_BYTES_SENT_BY_APP_VERSION',
  NETWORK_INSIGHTS_BYTES_SENT_BY_SYSTEM_VERSION = 'NETWORK_INSIGHTS_BYTES_SENT_BY_SYSTEM_VERSION',
  NETWORK_INSIGHTS_BYTES_SENT_BY_CARRIER = 'NETWORK_INSIGHTS_BYTES_SENT_BY_CARRIER',
  NETWORK_INSIGHTS_BYTES_SENT_BY_DEVICE_MODEL = 'NETWORK_INSIGHTS_BYTES_SENT_BY_DEVICE_MODEL',

  NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE = 'NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE_BY_CODE',
  NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE = 'NETWORK_INSIGHTS_NET_ERRORS_BY_ERROR_MESSAGE',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_BY_STATUS_MESSAGE_BY_CODE',

  NETWORK_INSIGHTS_AVG_LATENCY_COUNT = 'NETWORK_INSIGHTS_AVG_LATENCY_COUNT',
  NETWORK_INSIGHTS_AVG_LATENCY = 'NETWORK_INSIGHTS_AVG_LATENCY',
  NETWORK_INSIGHTS_BYTES_RECEIVED_COUNT = 'NETWORK_INSIGHTS_BYTES_RECEIVED_COUNT',
  NETWORK_INSIGHTS_BYTES_RECEIVED = 'NETWORK_INSIGHTS_BYTES_RECEIVED',
  NETWORK_INSIGHTS_BYTES_SENT_COUNT = 'NETWORK_INSIGHTS_BYTES_SENT_COUNT',
  NETWORK_INSIGHTS_BYTES_SENT = 'NETWORK_INSIGHTS_BYTES_SENT',
  NETWORK_INSIGHTS_NET_ERRORS_COUNT = 'NETWORK_INSIGHTS_NET_ERRORS_COUNT',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_COUNT = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_COUNT',
  NETWORK_INSIGHTS_NET_EVENT_COUNT = 'NETWORK_INSIGHTS_NET_EVENT_COUNT',
  NETWORK_INSIGHTS_NET_ERRORS = 'NETWORK_INSIGHTS_NET_ERRORS',
  NETWORK_INSIGHTS_NET_EVENT_ERROR = 'NETWORK_INSIGHTS_NET_EVENT_ERROR',
  NETWORK_INSIGHTS_NET_EVENT = 'NETWORK_INSIGHTS_NET_EVENT',
  NETWORK_INSIGHTS_NET_ERRORS_PREVIOUS_PERIOD = 'NETWORK_INSIGHTS_NET_ERRORS_PREVIOUS_PERIOD',
  NETWORK_INSIGHTS_NET_EVENT_ERROR_PREVIOUS_PERIOD = 'NETWORK_INSIGHTS_NET_EVENT_ERROR_PREVIOUS_PERIOD',
}
