/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * UserDetails
 * @export
 * @class UserDetails
 */
@Serializable
export class UserDetails {
  @JsonProperty({ name: 'id' })
  public id: string = undefined;

  @JsonProperty({ name: 'display_name' })
  public displayName?: string = undefined;

  /**
   * Creates an instance of UserDetails.
   * @param {any} args
   * @memberof UserDetails
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * userInfo
   * Default to empty string if displayName is not available
   *
   * @readonly
   * @type {string}
   * @memberof UserDetails
   */
  public get userInfo(): string {
    return this.displayName || '';
  }
}
