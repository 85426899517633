/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, Deserialize, GenericObject, JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { SourceObjectType } from '@ws1c/intelligence-models/source-object-type.enum';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { getUniqueId } from '@ws1c/intelligence-models/utils';
import { AutomationAction } from './automation-action.model';
import { AutomationCondition } from './automation-condition.model';
import { AutomationLastRunDetails } from './automation-last-run-details.model';
import { AutomationSchedule } from './automation-schedule.model';
import { AutomationActionType, ResultState, WorkflowConditionBranch, WorkflowConditionBranchKey } from './automation.enum';
import { EvaluationType } from './evaluation-type.enum';

/**
 * Emitted event that contains the automation and the type of action
 * @export
 * @interface AutomationActionEvent
 */
export interface AutomationActionEvent {
  action: AutomationActionType;
  data: Automation;
}

/**
 * automationConditionConverter
 * @type {CustomConverter}
 */
export const automationConditionConverter: CustomConverter = {
  fromJson(data: any): any {
    return deserialize(AutomationCondition, data);
  },
  toJson(data: any): any {
    if (!data?.filter) {
      return null;
    }
    return data;
  },
};

/**
 * Automation Model Object
 *
 * @export
 * @class Automation
 */
@Serializable
export class Automation {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('active')
  public active: boolean = undefined;

  @JsonProperty({ name: 'status_change_message', excludeToJson: true })
  public statusChangeMessage: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'last_run_details', cls: AutomationLastRunDetails, excludeToJson: true })
  public lastRunDetails: AutomationLastRunDetails = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('target_entity')
  public targetEntity: string = undefined;

  @JsonProperty({ name: 'condition', customConverter: automationConditionConverter })
  public condition: AutomationCondition = undefined;

  @JsonProperty('evaluation_type')
  public evaluationType: string = undefined;

  @JsonProperty({ name: 'schedule', cls: AutomationSchedule })
  public schedule?: AutomationSchedule = undefined;

  @JsonProperty('repeat_trigger')
  public repeatTrigger: boolean = false;

  @JsonProperty('source_object_id')
  public sourceObjectId: string = undefined;

  @JsonProperty('icon_links')
  public iconLinks?: string[] = undefined;

  @JsonProperty({ name: 'source_object_type', customConverter: enumConverterFactory(SourceObjectType) })
  public sourceObjectType: SourceObjectType = undefined;

  @JsonProperty({ name: 'actions', cls: AutomationAction })
  public actions: AutomationAction[] = [];

  @JsonProperty('source_template_id')
  public sourceTemplateId: string = undefined;

  public allActions: AutomationAction[] = [];

  public taskByUiIdMap: Map<string, AutomationAction> = new Map();

  /**
   * Getter to get unique categoryId
   *
   * @readonly
   * @type {string}
   * @memberof Automation
   */
  public get categoryId(): string {
    return getUniqueId(this.integration, this.targetEntity);
  }

  /**
   * Creates an instance of Automation.
   * @param {Array<Partial<Automation>>} args
   * @memberof Automation
   */
  constructor(...args: Array<Partial<Automation>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter to determine whether the automation can be enabled or not
   * @readonly
   * @type {boolean}
   * @memberof Automation
   */
  public get isToggleDisabled(): boolean {
    if (this.sourceObjectType) {
      return true;
    }
    if (this.active) {
      return false;
    }
    return this.allActions?.length === 0;
  }

  /**
   * isSystemDisabled
   * @returns {boolean}
   * @memberof Automation
   */
  public get isSystemDisabled(): boolean {
    return !this.active && !!this.statusChangeMessage;
  }

  /**
   * lastRunIconShape
   * @returns {string}
   * @memberof Automation
   */
  public get lastRunIconShape(): string {
    if (!this.lastRunDetails) {
      return '';
    }
    if (this.lastRunDetails.status === ResultState.COMPLETED) {
      return 'success-standard';
    } else if (this.lastRunDetails.status === ResultState.ERROR) {
      return 'error-standard';
    }
    return 'info-circle';
  }

  /**
   * lastRunStatus
   * @returns {string}
   * @memberof Automation
   */
  public get lastRunStatus(): string {
    if (!this.lastRunDetails) {
      return '';
    }
    if (this.lastRunDetails.status === ResultState.COMPLETED) {
      return 'success';
    } else if (this.lastRunDetails.status === ResultState.ERROR) {
      return 'danger';
    }
  }

  /**
   * isLastRunFailed
   * @type {boolean}
   * @memberof Automation
   */
  public get isLastRunFailed(): boolean {
    if (!this.lastRunDetails) {
      return false;
    }
    return this.lastRunDetails.status === ResultState.ERROR;
  }

  /**
   * Getter to get entitiesByIntegration
   *
   * @readonly
   * @type {GenericObject}
   * @memberOf Automation
   */
  public get entitiesByIntegration(): GenericObject {
    return {
      [this.integration]: [this.targetEntity],
    };
  }

  /**
   * isAutomatic
   * @type {boolean}
   * @memberOf Automation
   */
  public get isAutomatic(): boolean {
    return this.evaluationType === EvaluationType.AUTOMATIC;
  }

  /**
   * hasSourceObject
   * @readonly
   * @type {boolean}
   * @memberof Automation
   */
  public get hasSourceObject(): boolean {
    return !!this.sourceObjectType;
  }

  /**
   * updateTaskByUiIdMap
   * @param {AutomationAction[]} actions
   * @param {AutomationAction} parent
   * @param {WorkflowConditionBranch} branch
   * @memberof Automation
   */
  @Deserialize.after()
  public updateTaskByUiIdMap(actions: AutomationAction[] = this.actions, parent?: AutomationAction, branch?: WorkflowConditionBranch) {
    if (!this.taskByUiIdMap) {
      this.taskByUiIdMap = new Map();
    }

    if (!actions?.length) {
      return;
    }

    (actions || []).forEach((action: AutomationAction) => {
      this.taskByUiIdMap.set(action.uiId, action);
      if (parent) {
        action.parentInfo = {
          uiId: parent.uiId,
          branch,
        };
      }

      if (action.isGroup) {
        (action.actionData.groupedActions ?? []).forEach((groupedAction: AutomationAction) => {
          this.updateTaskByUiIdMap([groupedAction], action);
        });
      } else if (action.isCondition) {
        if (action.actionData.decisionCases) {
          this.updateTaskByUiIdMap(
            action.actionData.decisionCases[WorkflowConditionBranchKey.TRUE_BRANCH] ?? [],
            action,
            WorkflowConditionBranch.TRUE_BRANCH,
          );
          this.updateTaskByUiIdMap(
            action.actionData.decisionCases[WorkflowConditionBranchKey.FALSE_BRANCH] ?? [],
            action,
            WorkflowConditionBranch.FALSE_BRANCH,
          );
        }
      }
    });
  }

  /**
   * getTaskByUiId
   * @param {string} uiId
   * @returns {AutomationAction}
   * @memberof Automation
   */
  public getTaskByUiId(uiId: string): AutomationAction {
    return this.taskByUiIdMap.get(uiId);
  }

  /**
   * setTaskByUiId
   * Updates the value in the map.
   * When the second argument is undefined, key will be removed from the map
   * @param {string} uiId
   * @param {AutomationAction} task
   * @memberof Automation
   */
  public setTaskByUiId(uiId: string, task?: AutomationAction) {
    if (!task) {
      this.taskByUiIdMap.delete(uiId);
      return;
    }
    this.taskByUiIdMap.set(uiId, task);
  }

  /**
   * updateActions
   * @param {any} data
   * @memberof Automation
   */
  @Deserialize.before()
  public updateActions(data: any): any {
    data.actions = data?.workflow?.actions;
  }
}

/**
 * AutomationSearchResponse
 *
 * @export
 * @class AutomationSearchResponse
 */
export class AutomationSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: Automation })
  public results: Automation[] = [];

  /**
   * Creates an instance of AutomationSearchResponse.
   * @param {any} args
   * @memberof AutomationSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
