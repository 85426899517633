/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { AddOnType, IntegratedServiceType } from '@ws1c/intelligence-models/integrated-services';

export const TRIAL_SERVICES_MODAL_TITLE: Partial<Record<IntegratedServiceType | AddOnType, string>> = {
  [IntegratedServiceType.INTELLIGENCE]: 'TRIAL_SERVICES.INTELLIGENCE_PREMIUM_TRIAL_TITLE',
  [AddOnType.INTELLIGENCE_DEEM]: 'TRIAL_SERVICES.EXPERIENCE_MANAGEMENT_TRIAL_TITLE',
};
