/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable, TrendSpan } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { ChronoUnit } from '@ws1c/intelligence-models/dashboard/chrono-unit.enum';
import { TrendDateRange } from '@ws1c/intelligence-models/dashboard/trend-date-range.model';
import { FilterRule } from '@ws1c/intelligence-models/filter';
import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta';
import { LodashSortOrder } from '@ws1c/intelligence-models/lodash-sort-order.enum';
import { InsightActionType } from './insight-action-type.enum';
import { InsightObservationValue } from './insight-observation-value.model';
import { InsightOutlierType } from './insight-outlier-type.enum';
import { InsightStatus } from './insight-status.enum';
import { InsightType } from './insight-type.enum';
import { InsightUnit } from './insight-unit.enum';
import { InsightsConfig } from './insights.config';

/**
 * Emitted event that contains the insight and the type of action
 * @export
 * @interface InsightActionEvent
 */
export interface InsightActionEvent {
  action: InsightActionType;
  data: Insight;
}

/**
 * Insight
 * @export
 * @class Insight
 */
@Serializable
export class Insight {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('observation_name')
  public observationName: string = undefined;

  @JsonProperty({ name: 'observation_value', cls: InsightObservationValue })
  public observationValue: InsightObservationValue = undefined;

  @JsonProperty('observation_measure')
  public observationMeasure: string = undefined;

  @JsonProperty({ name: 'outlier_type', customConverter: enumConverterFactory(InsightOutlierType) })
  public outlierType: InsightOutlierType = undefined;

  @JsonProperty({ name: 'insight_type', customConverter: enumConverterFactory(InsightType) })
  public insightType: InsightType = undefined;

  @JsonProperty('category_id')
  public categoryId: string = undefined;

  @JsonProperty('incident_id')
  public incidentId: string = undefined;

  @JsonProperty({ name: 'last_triggered_at', customConverter: dateFormatConverter })
  public lastTriggeredAt: number = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(InsightStatus) })
  public status: InsightStatus = undefined;

  @JsonProperty('pinned')
  public pinned: boolean = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('comment_count')
  public commentCount: number = undefined;

  @JsonProperty('version')
  public version: string = undefined;

  @JsonProperty('rca_types')
  public rcaTypes: string[] = undefined;

  /**
   * Creates an instance of Insight
   * @param {Partial<Insight>} args
   * @memberOf Insight
   */
  constructor(...args: Array<Partial<Insight>>) {
    Object.assign(this, ...args);
  }

  /**
   * isActive
   * @readonly
   * @type {boolean}
   * @memberof Insight
   */
  public get isActive(): boolean {
    return this.status === InsightStatus.ACTIVE;
  }

  /**
   * isDismissed
   * @readonly
   * @type {boolean}
   * @memberof Insight
   */
  public get isDismissed(): boolean {
    return this.status === InsightStatus.DISMISSED;
  }

  /**
   * observationValueUnit
   * @readonly
   * @type {string}
   * @memberof Insight
   */
  public get observationValueUnit(): string {
    return InsightsConfig.insightsPropertiesMap[this.insightType].unit === InsightUnit.PERCENT ? '%' : '';
  }

  /**
   * observationValueMillis
   * @readonly
   * @type {number}
   * @memberof Insight
   */
  public get observationValueMillis(): number {
    const unit: InsightUnit = InsightsConfig.insightsPropertiesMap[this.insightType].unit;
    switch (unit) {
      case InsightUnit.MILLIS:
        return this.observationValue.value;
      case InsightUnit.SECONDS:
        return this.observationValue.value * 1000;
    }
  }

  /**
   * getFilterRules
   * @returns {FilterRule[]}
   * @memberOf Insight
   */
  public getFilterRules(): FilterRule[] {
    return FilterRule.listFromKeyValue({
      [COLUMN_NAMES.byFullyQualifiedName.internal_insights_observation_name]: this.observationName,
      [COLUMN_NAMES.byFullyQualifiedName.internal_insights_platform]: this.platform,
      [COLUMN_NAMES.byFullyQualifiedName.internal_insights_insight_type]: this.insightType,
      [COLUMN_NAMES.byFullyQualifiedName.internal_insights_version]: this.version,
    });
  }

  /**
   * getDashboardTrendDateRange - last 24 hours from last_triggered_at with hourly sampling frequency
   * @returns {TrendDateRange}
   * @memberOf Insight
   */
  public getDashboardTrendDateRange(): TrendDateRange {
    // Start date is 24 hours before insight lastTriggeredAt
    const startDateMillis = this.lastTriggeredAt - TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.HOURS]].value * 24;
    // End date is maximum 5 days after the insight lastTriggeredAt time
    const targetEndDateMillis = this.lastTriggeredAt + TrendSpan.timeUnitsByName[ChronoUnit[ChronoUnit.DAYS]].value * 5;
    const currentEndDateMillis = Date.now();
    return new TrendDateRange({
      endDateMillis: currentEndDateMillis < targetEndDateMillis ? currentEndDateMillis : targetEndDateMillis,
      startDateMillis,
      dateRangeOrder: LodashSortOrder.ASC,
      samplingFrequency: new TrendSpan({
        duration: 1,
        unit: ChronoUnit[ChronoUnit.HOURS],
      }),
    });
  }
}
