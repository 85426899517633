/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ComposeFunction } from './dashboard.enum';

/**
 * UserProfileDashboardConfig
 * @export
 * @class UserProfileDashboardConfig
 */
export class UserProfileDashboardConfig {
  public static readonly failedSuccessfulLoginColors = [
    {
      name: 'Failed Login',
      value: '#f54f47',
    },
    {
      name: 'Successful Login',
      value: '#00d4b8',
    },
  ];

  public static readonly COMPOSE_CONFIGS_BY_USER_PROFILE_DASHBOARD = {
    USER_PROFILE: {
      __USER_PROFILE_EXPERIENCE_DEVICE_HEALTH: {
        dependencies: [
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_CRASH',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_BOOT_TIME',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_SHUTDOWN_TIME',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_BATTERY',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_CPU',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_GPU',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_MEMORY',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_WIFI',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_DOWNLOAD',
          'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_UPLOAD',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesName: 'issue',
          seriesValues: [
            'OS Crashes',
            'Boot Time',
            'Shutdown Time',
            'Battery Health',
            'CPU Health',
            'GPU Health',
            'Memory Health',
            'Wifi Strength',
            'Download Speed',
            'Upload Speed',
          ],
        },
      },
      __USER_PROFILE_EXPERIENCE_HEALTH_OVER_TIME: {
        dependencies: ['USER_PROFILE_EXPERIENCE_HEALTH_OVER_TIME'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __USER_PROFILE_HEALTH_OVER_TIME: {
        dependencies: ['USER_PROFILE_HEALTH_OVER_TIME'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __USER_PROFILE_USER_RISK_OVER_TIME: {
        dependencies: ['USER_PROFILE_USER_RISK_OVER_TIME'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __USER_PROFILE_LOGIN_OVER_TIME: {
        dependencies: ['USER_PROFILE_USER_LOGIN_FAILED_OVER_TIME', 'USER_PROFILE_USER_LOGIN_SUCCESS_OVER_TIME'],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'INTEGER',
          legendLabel: 'Login Status',
          seriesName: '_merged_series',
          seriesValues: ['Failed Login', 'Successful Login'],
        },
      },
      __USER_PROFILE_USER_LOGIN_OVER_TIME: {
        dependencies: ['USER_PROFILE_USER_LOGIN_OVER_TIME'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __USER_PROFILE_RISK_OVER_TIME: {
        dependencies: ['USER_PROFILE_RISK_OVER_TIME'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __USER_PROFILE_DEVICE_HEALTH: {
        dependencies: ['USER_PROFILE_DEVICE_HEALTH'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
      __USER_PROFILE_RISK_SCORE: {
        dependencies: ['USER_PROFILE_RISK_SCORE'],
        composeFunction: ComposeFunction.REMOVE_ZEROS,
      },
    },
  };
}
