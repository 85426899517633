/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AccessType } from './access-type.enum';

export const ACCESS_TYPES = [
  {
    name: AccessType.READ,
    labelKey: 'COMMON_MESSAGES.ACCESS_LEVEL_READ',
  },
  {
    name: AccessType.FULL,
    labelKey: 'COMMON_MESSAGES.ACCESS_LEVEL_FULL',
  },
];
