/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * OptInFeatureFlag
 * @export
 * @enum {string}
 */
export enum OptInFeatureFlag {
  DATA_SEGMENTATION = 'data_segmentation',
  DEEM = 'deem',
  MOBILE_TELEMETRY = 'mobile_telemetry',
}

/**
 * OptInFeatureFlag -> Flag name mapping
 * The mapped name should match to the property in Org Model
 *
 * @type {Record<OptInFeatureFlag, string>}
 */
export const OPT_IN_FEATURE_TO_FLAG_MAPPING: Record<OptInFeatureFlag, string> = {
  [OptInFeatureFlag.DATA_SEGMENTATION]: 'dataSegmentationEnabled',
  [OptInFeatureFlag.DEEM]: 'deemEnabled',
  [OptInFeatureFlag.MOBILE_TELEMETRY]: 'mobileTelemetryEnabled',
};
