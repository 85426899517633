/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * CrashCrumb
 * @export
 * @class CrashCrumb
 */
@Serializable
export class CrashCrumb {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('error_reason')
  public errorReason: string = undefined;

  // for IOS only
  @JsonProperty('error_type')
  public errorType: string = undefined;

  @JsonProperty('error_code')
  public errorCode: string = undefined;

  // for grouped crash crumbs only
  @JsonProperty('threshold_86_0')
  public crashGroupApple: string = undefined;

  @JsonProperty('threshold_92_5')
  public crashGroupAndroid: string = undefined;

  @JsonProperty('crash_group')
  public crashGroup: string = undefined;

  /**
   * Creates an instance of CrashCrumb.
   * @param {...Array<Partial<CrashCrumb>>} args
   * @memberof CrashCrumb
   */
  constructor(...args: Array<Partial<CrashCrumb>>) {
    Object.assign(this, ...args);
  }

  /**
   * getCrashCrumbName
   * @returns {string}
   * @memberof CrashCrumb
   */
  public getCrashCrumbName(): string {
    return this.name || this.errorType;
  }

  /**
   * getCrashCrumbLabelKey
   * @returns {string}
   * @memberof CrashCrumb
   */
  public getCrashCrumbLabelKey(): string {
    const crashedlabel = [this.getCrashCrumbName(), this.errorReason || this.errorCode].filter(Boolean).join('. ');
    return crashedlabel || 'APTELIGENT.NO_CRASH_DETAILS_AVAILABLE';
  }
}
