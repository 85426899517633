/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AccountRole } from '@ws1c/intelligence-models/account-role.model';
import { DataAccessPolicy } from '@ws1c/intelligence-models/data-access-policy.model';
import { User } from './user.model';

/**
 * UserAccount
 * @export
 * @class UserAccount
 * @extends {User}
 */
@Serializable
export class UserAccount extends User {
  @JsonProperty('airwatch_core_user_id')
  public airwatchCoreUserId: number = undefined;

  @JsonProperty('airwatch_user_name')
  public airwatchUsername: string = undefined;

  @JsonProperty('user_name')
  public userName: string = undefined;

  @JsonProperty({ name: 'data_access_policies', cls: DataAccessPolicy, excludeToJson: true })
  public dataAccessPolicies?: DataAccessPolicy[] = [];

  @JsonProperty({ name: 'roles', cls: AccountRole })
  public roles: AccountRole[] = undefined;

  @JsonProperty('access_requested')
  public accessRequested: boolean = undefined;

  /**
   * Creates an instance of UserAccount.
   * @param {...Array<Partial<UserAccount>>} args
   * @memberof UserAccount
   */
  constructor(...args: Array<Partial<UserAccount>>) {
    super(...args);
    Object.assign(this, ...args);
  }

  /**
   * Getter for isValid - returns true if user contact details are present
   *
   * @readonly
   * @returns {boolean}
   * @memberof UserAccount
   */
  public get isValid(): boolean {
    return !!this.email && !!this.firstName && !!this.lastName;
  }
}
