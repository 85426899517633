/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * @exports
 * @enum {number}
 */
export enum DeviceDesktopTabType {
  OVERVIEW,
  BOOT_SHUTDOWN,
  DEVICE_PERFORMANCE,
  SYSTEM_ERRORS,
  NETWORK,
}
