/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * Add On Type
 * @export
 * @enum {string}
 */
export enum AddOnType {
  INTELLIGENCE_DEEM = 'INTELLIGENCE_DEEM',
  INTELLIGENCE_MOBILE_THREAT_DEFENSE = 'INTELLIGENCE_MOBILE_THREAT_DEFENSE',
}
