/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

export enum PendoEventTriggeredBy {
  CLICK = 'Click',
  DRAG_DROP = 'DragDrop',
  SEARCH = 'Search',
  TOGGLE = 'Toggle',
}
