/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ItemGroup } from './item-group.model';

/**
 * ItemGroupCollection
 * @export
 * @class ItemGroupCollection
 */
export class ItemGroupCollection {
  public groupBy?: string;
  public groupByLabel?: string;
  public groups: ItemGroup[];

  /**
   * Creates an instance of ItemGroupCollection.
   * @param {...Array<Partial<ItemGroupCollection>>} args
   * @memberof ItemGroupCollection
   */
  constructor(...args: Array<Partial<ItemGroupCollection>>) {
    Object.assign(this, ...args);
  }
}
