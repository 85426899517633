/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import {
  DataConverterConfig,
  DataUnit,
  DateTimeFormat,
  DEFAULT_LOCALE,
  GenericConverter,
  Lang,
  SUPPORTED_LANGUAGES,
  TimeDurationConverter,
} from '@dpa/ui-common';
import { get } from 'lodash-es';
import moment from 'moment';

import { BucketingAttribute } from '@ws1c/intelligence-models/dashboard/bucketing-attribute.model';
import { KeyValuePair } from '@ws1c/intelligence-models/dashboard/chart/ngx-common.interface';
import { ChronoUnit } from '@ws1c/intelligence-models/dashboard/chrono-unit.enum';
import { Counter } from '@ws1c/intelligence-models/dashboard/counter.model';
import { DashboardConfig } from '@ws1c/intelligence-models/dashboard/dashboard.config';
import { AggregationFunction } from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { TrendDateRange } from '@ws1c/intelligence-models/dashboard/trend-date-range.model';
import { TrendResult } from '@ws1c/intelligence-models/dashboard/trend-result.model';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { MetaFormField } from '@ws1c/intelligence-models/meta-form';
import { getNumberFormatter } from '@ws1c/intelligence-models/utils/number-formatter';
import { NgxTrendResultFlattener } from './ngx-trend-result-flattener.model';

/**
 * NgxChartLabels
 * @export
 * @class NgxChartLabels
 */
export class NgxChartLabels {
  public byFlatKey: { [flatKey: string]: string };
  public xAxis: string;

  public counter: string;
  public firstGroupBy: string;
  public secondGroupBy: string;
  public formattersByBucketingAttributeKey: Record<string, (value: number | string) => string> = {};
  public counterFormatter: any;
  public totalCountFormatter: any;
  public xAxisDateFormatter: any;
  public dataTypeToFormatterMap: Record<
    string,
    (valuePrecessFunc: (value: number) => number, dataType: string) => (value: number) => string
  >;
  public dataUnitToFormatterMap: Record<
    string,
    (valuePrecessFunc: (value: number) => number, dataType: string) => (value: number) => string
  >;
  public readonly TIME_CONVERSION_MAP = {
    [DataUnit.SECONDS]: 1000,
    [DataUnit.MINUTES]: 60000,
    [DataUnit.HOURS]: 3600000,
  };

  private numberFormatter: Intl.NumberFormat;

  /**
   * Creates an instance of NgxChartLabels.
   * @param {string[]} groupBys
   * @param {TrendResult[]} trendResults
   * @param {any} translators
   * @param {any} dataTypesByBucketingAttributeKey
   * @param {TrendDateRange} dateRange
   * @param {string} currentLocale
   * @param {boolean} isInvertMode
   * @param {Record<string, DataUnit>} [dataUnitsByBucketingAttributeKey={}]
   * @param {number} [xAxisLabelFactor=1]
   * @memberof NgxChartLabels
   */
  public constructor(
    private groupBys: string[],
    private trendResults: TrendResult[],
    private translators: any,
    private dataTypesByBucketingAttributeKey: any,
    private dateRange: TrendDateRange,
    private currentLocale: string,
    private isInvertMode: boolean,
    private dataUnitsByBucketingAttributeKey: Record<string, DataUnit> = {},
    private xAxisLabelFactor: number = 1,
  ) {
    this.numberFormatter = getNumberFormatter(currentLocale, { maximumFractionDigits: 2 });
    this.byFlatKey = this.getLabelsByFlatKey();

    this.counter = this.getCounter();
    this.firstGroupBy = this.getFirstGroupBy();
    this.secondGroupBy = this.getSecondGroupBy();
    this.dataUnitToFormatterMap = {
      [DataUnit.BYTES]: this.getByteSizeConvertedValue,
      [DataUnit.KILOBYTES]: this.getByteSizeConvertedValue,
      [DataUnit.MEGABYTES]: this.getByteSizeConvertedValue,
      [DataUnit.GIGABYTES]: this.getByteSizeConvertedValue,
      [DataUnit.BITS]: this.getBitSizeConvertedValue,
      [DataUnit.KILOBITS]: this.getBitSizeConvertedValue,
      [DataUnit.MEGABITS]: this.getBitSizeConvertedValue,
      [DataUnit.GIGABITS]: this.getBitSizeConvertedValue,
      [DataUnit.HERTZ]: this.getFrequencyConvertedValue,
      [DataUnit.KILOHERTZ]: this.getFrequencyConvertedValue,
      [DataUnit.MEGAHERTZ]: this.getFrequencyConvertedValue,
      [DataUnit.GIGAHERTZ]: this.getFrequencyConvertedValue,
      [DataUnit.MILLIJOULE]: this.getJouleConvertedValue,
      [DataUnit.MILLIVOLT]: this.getVoltageConvertedValue,
      [DataUnit.MILLIWATT_HOUR]: this.getWattageConvertedValue,
      [DataUnit.MILLISECONDS]: this.getTimeDurationConvertedValue,
      [DataUnit.SECONDS]: this.getTimeDurationConvertedValue,
      [DataUnit.MINUTES]: this.getTimeDurationConvertedValue,
      [DataUnit.HOURS]: this.getTimeDurationConvertedValue,
      [DataUnit.FAHRENHEIT]: this.getTemperatureConvertedValue,
      [DataUnit.CELSIUS]: this.getTemperatureConvertedValue,
      [DataUnit.DECIBEL_MILLIWATTS]: this.getPowerLevelConvertedValue,
      [DataUnit.PERCENT]: this.getPercentageFormatter,
      [DataUnit.RATE]: this.getPercentageFormatter,
      [DataUnit.BITS_PER_SEC]: this.getTransferNetworkConvertedValue,
      [DataUnit.BYTES_PER_SEC]: this.getTransferNetworkConvertedValue,
    };
    this.dataTypeToFormatterMap = {
      [DataType[DataType.FLOAT]]: this.getNumberConvertedValue,
      [DataType[DataType.DOUBLE]]: this.getNumberConvertedValue,
      [DataType[DataType.INTEGER]]: this.getNumberConvertedValue,
      [DataType[DataType.LONG]]: this.getNumberConvertedValue,
      [DataType[DataType.DATETIME]]: this.getFastMomentFormatter,
      [DataType[DataType.COUNTER_INTEGER]]: this.formatCounterInteger,
      [DataType[DataType.STRING_INTEGER]]: this.defaultFormatter,
      [DataType[DataType.STRING]]: this.defaultFormatter,
    };
    Object.keys(this.dataUnitsByBucketingAttributeKey).forEach((bucketingAttributeKey: string) => {
      const dataUnit = this.dataUnitsByBucketingAttributeKey[bucketingAttributeKey];
      const dataType = this.dataTypesByBucketingAttributeKey[bucketingAttributeKey];
      if (dataUnit) {
        this.formattersByBucketingAttributeKey[bucketingAttributeKey] = this.getFormatterForDataUnit(dataUnit);
      } else {
        // If DataUnit is not present for the attribute, get the formatter by DataType.
        this.formattersByBucketingAttributeKey[bucketingAttributeKey] = this.getFormatterForDataType(dataType);
      }

      // Do not do extra data formatting on COUNT or COUNT_DISTINCT
      if (
        this.trendResults?.length &&
        (this.trendResults[0]?.counters[0]?.definition.aggregationFunction === AggregationFunction.COUNT ||
          this.trendResults[0]?.counters[0]?.definition.aggregationFunction === AggregationFunction.COUNT_DISTINCT)
      ) {
        this.formattersByBucketingAttributeKey[NgxTrendResultFlattener.COUNTER_KEY] = this.getFormatterForDataType(
          DataType[DataType.COUNTER_INTEGER],
        );
      }
    });
    this.counterFormatter = this.formattersByBucketingAttributeKey[NgxTrendResultFlattener.COUNTER_KEY];
    this.totalCountFormatter = this.getTotalCount.bind(this);

    this.xAxisDateFormatter = this.formatXAxisDate.bind(this);
  }

  /**
   * getFormatterForDataUnit
   * @param {DataUnit} dataUnit
   * @param {boolean} [needInvert=true]
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getFormatterForDataUnit(dataUnit: DataUnit, needInvert: boolean = true): (value: number) => string {
    const valuePrecessFunc = this.isInvertMode && needInvert ? (value: number) => 1 / value : (value: number) => value;
    const converterFunction = this.dataUnitToFormatterMap[dataUnit];
    if (!converterFunction) {
      return (value: any) => valuePrecessFunc(value).toLocaleString();
    }
    return this.dataUnitToFormatterMap[dataUnit].bind(this, valuePrecessFunc, dataUnit)();
  }

  /**
   * getFormatterForDataType
   * @param {string} dataType
   * @param {boolean} [needInvert=true]
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getFormatterForDataType(dataType: string, needInvert: boolean = true): (value: number) => string {
    const valuePrecessFunc = this.isInvertMode && needInvert ? (value: number) => 1 / value : (value: number) => value;
    const converterFunction = this.dataTypeToFormatterMap[dataType];
    if (!converterFunction) {
      return (value: any) => valuePrecessFunc(value).toLocaleString();
    }
    return this.dataTypeToFormatterMap[dataType].bind(this, valuePrecessFunc, dataType)();
  }

  /**
   * formatCounterInteger
   * @param {(value: number) => number} valuePrecessFunc
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public formatCounterInteger(valuePrecessFunc: (value: number) => number): (value: number) => string {
    return (value: number) => {
      // this formatter can be passed on to yAxis which will try non-integer values
      // in that case, non-integer values shouldn't be displayed
      if (!this.isInvertMode && !Number.isInteger(value)) {
        return '';
      }

      return this.numberFormatter.format(valuePrecessFunc(value));
    };
  }

  /**
   * defaultFormatter
   * @param {(value: number) => number} valuePrecessFunc
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public defaultFormatter(valuePrecessFunc: (value: number) => number): (value: number) => string {
    // this formatter is required when the number needs to be represented as string like Windows KB Article number
    return (value: number) => {
      // this formatter is required when the number needs to be represented as string like Windows KB Article number
      return `${valuePrecessFunc(value)}`;
    };
  }

  /**
   * getNumberConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {string} dataType
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getNumberConvertedValue(valuePrecessFunc: (value: number) => number, dataType: string): (value: number) => string {
    switch (dataType) {
      case DataType[DataType.FLOAT]:
      case DataType[DataType.DOUBLE]:
        return (value: number) => Number(valuePrecessFunc(value)).toFixed(2);
      case DataType[DataType.INTEGER]:
      case DataType[DataType.LONG]:
        return (value: number) => {
          if (!Number.isInteger(Number(value))) {
            return valuePrecessFunc(Number(value)).toLocaleString(this.currentLocale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
          return valuePrecessFunc(value).toLocaleString();
        };
    }
  }

  /**
   * getPercentageFormatter
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getPercentageFormatter(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.PERCENT:
        return (value: number) => `${this.numberFormatter.format(valuePrecessFunc(value))}%`;
      case DataUnit.RATE:
        return (value: number) => `${this.numberFormatter.format(valuePrecessFunc(value * 100))}%`;
    }
  }

  /**
   * getByteSizeConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getByteSizeConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.BYTES:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 0, 1024, DataConverterConfig.byteUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.KILOBYTES:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 0, 1, 1024, DataConverterConfig.byteUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.MEGABYTES:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 0, 2, 1024, DataConverterConfig.byteUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.GIGABYTES:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 0, 3, 1024, DataConverterConfig.byteUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
    }
  }

  /**
   * getBitSizeConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getBitSizeConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.BITS:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 0, 1000, DataConverterConfig.bitUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.KILOBITS:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 0, 1, 1000, DataConverterConfig.bitUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.MEGABITS:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 0, 2, 1000, DataConverterConfig.bitUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.GIGABITS:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 0, 3, 1000, DataConverterConfig.bitUnitList);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
    }
  }

  /**
   * getFrequencyConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getFrequencyConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.HERTZ:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 0, 1000, DataConverterConfig.freqUnitKeys);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.KILOHERTZ:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 1, 1000, DataConverterConfig.freqUnitKeys);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.MEGAHERTZ:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 2, 1000, DataConverterConfig.freqUnitKeys);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.GIGAHERTZ:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 3, 1000, DataConverterConfig.freqUnitKeys);
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
    }
  }

  /**
   * getVoltageConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getVoltageConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    if (dataUnit === DataUnit.MILLIVOLT) {
      return (value: number) => {
        const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 0, 1000, DataConverterConfig.voltUnitKeys);
        return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
      };
    }
  }

  /**
   * getJouleConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getJouleConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    if (dataUnit === DataUnit.MILLIJOULE) {
      return (value: number) => {
        const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 0, 1000, DataConverterConfig.jouleUnitKeys);
        return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
      };
    }
  }

  /**
   * getWattageConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getWattageConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    if (dataUnit === DataUnit.MILLIWATT_HOUR) {
      return (value: number) => {
        const convertedUnit = GenericConverter.getConvertedUnit(valuePrecessFunc(value), 2, 0, 1000, DataConverterConfig.wattUnitKeys);
        return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
      };
    }
  }

  /**
   * getTimeDurationConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getTimeDurationConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.MILLISECONDS:
        return (value: number) => {
          const { formattedTimeValue, unitName } = TimeDurationConverter.convert(value);
          return this.translators[unitName](formattedTimeValue);
        };
      case DataUnit.SECONDS:
      case DataUnit.MINUTES:
      case DataUnit.HOURS:
        return (value: number) => {
          const { formattedTimeValue, unitName } = TimeDurationConverter.convert(value * this.TIME_CONVERSION_MAP[dataUnit]);
          return this.translators[unitName](formattedTimeValue);
        };
    }
  }

  /**
   * getTemperatureConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getTemperatureConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.FAHRENHEIT:
        return (value: number) => `${this.numberFormatter.format(valuePrecessFunc(value))} \u00B0F`;
      case DataUnit.CELSIUS:
        return (value: number) => `${this.numberFormatter.format(valuePrecessFunc(value))} \u00B0C`;
    }
  }

  /**
   * getTransferNetworkConvertedValue
   * @param {(value: number) => number} valuePrecessFunc
   * @param {DataUnit} dataUnit
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getTransferNetworkConvertedValue(valuePrecessFunc: (value: number) => number, dataUnit: DataUnit): (value: number) => string {
    switch (dataUnit) {
      case DataUnit.BITS_PER_SEC:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(
            valuePrecessFunc(value),
            2,
            0,
            1000,
            DataConverterConfig.bitNetworkUnitKeys,
          );
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
      case DataUnit.BYTES_PER_SEC:
        return (value: number) => {
          const convertedUnit = GenericConverter.getConvertedUnit(
            valuePrecessFunc(value),
            2,
            0,
            1000,
            DataConverterConfig.byteNetworkUnitKeys,
          );
          return this.translators[convertedUnit.unitLabelKey](convertedUnit.value);
        };
    }
  }

  /**
   * getPowerLevelConvertedValue
   * @returns {(value: number) => string}
   * @memberof NgxChartLabels
   */
  public getPowerLevelConvertedValue(): (value: number) => string {
    return (value: number) => {
      return this.translators[DataConverterConfig.powerLevelDBKeys[0]](value);
    };
  }

  /**
   * getTotalCount
   * If it's not invert mode, total count just needs to sum up the values of all the items.
   * If it's in invert mode, each value need to be invert back by 1/x and then sum them up.
   * @param {KeyValuePair[]} ngxTooltipItems
   * @returns {string}
   * @memberof NgxChartLabels
   */
  public getTotalCount(ngxTooltipItems: KeyValuePair[]): string {
    const processValueFunc = this.isInvertMode ? (value: number) => (value !== 0 ? 1 / value : 0) : (value: number) => value;
    const total = ngxTooltipItems.reduce((sum: number, ngxTooltipItem: KeyValuePair) => {
      return sum + processValueFunc(ngxTooltipItem.value);
    }, 0);
    const dataType: string = this.dataTypesByBucketingAttributeKey[NgxTrendResultFlattener.COUNTER_KEY];
    const dataUnit: DataUnit = this.dataUnitsByBucketingAttributeKey[NgxTrendResultFlattener.COUNTER_KEY];
    if (dataUnit) {
      return this.getFormatterForDataUnit(dataUnit, false)(total);
    } else {
      return this.getFormatterForDataType(dataType, false)(total);
    }
  }

  /**
   * getFastMomentFormatter
   * creating a moment object has a lot of overhead like reading the locale
   * This creates a moment object for each column instead of for each cell
   * based on https://medium.com/whereto-engineering/a-story-about-moment-js-performance-aa796e086b2e
   * @memberof NgxChartLabels
   * @returns {(timestamp: number) => string}
   */
  public getFastMomentFormatter(): (timestamp: number) => string {
    const defaultMoment = moment() as any;
    const fakeMoment = {} as any;
    fakeMoment.__proto__ = moment.prototype;
    fakeMoment._isAMomentObject = true;
    fakeMoment._isUTC = true;
    fakeMoment._locale = defaultMoment._locale;
    fakeMoment._isValid = true;
    return (timestamp: number) => {
      if (!Number.isInteger(timestamp)) {
        return timestamp;
      }
      const date = new Date(timestamp);
      fakeMoment._d = date;
      return fakeMoment.format(DateTimeFormat.MOMENT_DATE_FORMAT);
    };
  }

  /**
   * getLabelsByFlatKey
   * collects the labels scattered throughout trendResults,
   * @returns {Record<string, string>}
   * @memberof NgxChartLabels
   */
  public getLabelsByFlatKey(): Record<string, string> {
    const counterLabelsByKey: Record<string, string> = {};
    this.trendResults?.[0]?.counters.forEach((counter: Counter, index: number) => {
      const counterKey = NgxTrendResultFlattener.getCounterKey(index);
      counterLabelsByKey[counterKey] = this.getCounterLabel(counter);
    });

    const bucketLabelByKey: Record<string, string> = {};
    this.trendResults?.forEach((trendResult: TrendResult) => {
      trendResult.bucketingAttributes.forEach((bucketingAttribute: BucketingAttribute) => {
        const flatKey = bucketingAttribute.reportColumnView.name;
        const label = bucketingAttribute.reportColumnView.label || flatKey;
        bucketLabelByKey[flatKey] = label;
      });
    });

    return {
      [NgxTrendResultFlattener.DATE_KEY]: this.translators.DATE(),
      ...counterLabelsByKey,
      ...bucketLabelByKey,
    };
  }

  /**
   * getCounterLabel
   * @param {Counter} counter
   * @returns {string}
   * @memberof NgxChartLabels
   */
  public getCounterLabel(counter: Counter): string {
    const aggregationFunction: string = counter.definition.aggregationFunction;
    const aggregationAttribute: string = counter.definition.aggregateAttribute;
    const counterColumnView: MetaFormField = counter.result.reportColumnView;
    const counterColumnName: string = counterColumnView.label || counterColumnView.name;

    const counterLabelOverride = get(DashboardConfig.counterLabelOverridesByFuncByAttr, [
      aggregationAttribute,
      AggregationFunction[aggregationFunction],
    ]);
    return counterLabelOverride ? this.translators.RAW(counterLabelOverride) : this.translators[aggregationFunction](counterColumnName);
  }

  /**
   * getCounter
   * @returns {string}
   * @memberof NgxChartLabels
   */
  public getCounter(): string {
    return this.byFlatKey[NgxTrendResultFlattener.COUNTER_KEY] || '';
  }

  /**
   * getFirstGroupBy
   * @returns {string}
   * @memberof NgxChartLabels
   */
  public getFirstGroupBy(): string {
    return this.groupBys[0] === NgxTrendResultFlattener.DATE_KEY ? '' : this.byFlatKey[this.groupBys[0]];
  }

  /**
   * getSecondGroupBy
   * @returns {string}
   * @memberof NgxChartLabels
   */
  public getSecondGroupBy(): string {
    return this.groupBys[1] === NgxTrendResultFlattener.DATE_KEY ? '' : this.byFlatKey[this.groupBys[1]];
  }

  /**
   * getChartLabelDateFormat
   * uses the currentLocale and returns an object that has input and output date format
   * @returns {Lang}
   * @memberof NgxChartLabels
   */
  public getChartLabelDateFormat(): Lang {
    const currentLocale = this.currentLocale.replace('-', '_');
    let dateFormat = SUPPORTED_LANGUAGES.find((lang: Lang) => lang.code === currentLocale);
    // Handle invalid locales or fallback to similar locale if we don't have full match
    if (!dateFormat) {
      dateFormat = SUPPORTED_LANGUAGES.find((lang: Lang) => lang.code === DEFAULT_LOCALE);
    }
    return dateFormat;
  }

  /**
   * formatXAxisDate - used to return the dateTimeVal or an empty string to hide the x-axis label
   * @param {string} dateTimeVal date string with format from NgxTrendResultFlattener.dateFormatByChronoUnit[unit]
   * @returns {string} returnDate with custom format or '' to hide the label
   * @memberof NgxChartLabels
   */
  // eslint-disable-next-line complexity
  private formatXAxisDate(dateTimeVal: string): string {
    // if there's no dateRange return the value
    // ComposeFunction.SET_TREND_MODE can convert charts with a dateRange to snapshot-format graphs
    // In that case, it will have a dateRange with just a start/end time and no samplingFrequency
    if (!this.dateRange || !this.dateRange.getSamplingFrequency()) {
      return dateTimeVal;
    }
    const dateFormat = this.getChartLabelDateFormat();
    const startDate = moment(this.dateRange.startDateMillis);
    const endDate = moment(this.dateRange.endDateMillis);
    const unit: string = this.dateRange.getSamplingFrequency().unit;
    const momentUnit: moment.unitOfTime.Diff = unit.toLowerCase() as moment.unitOfTime.Diff;
    const duration: number = this.dateRange.getSamplingFrequency().duration;
    const totalDataPoints = endDate.diff(startDate, momentUnit) / duration + 1;
    const currentDate = moment(dateTimeVal, NgxTrendResultFlattener.dateFormatByChronoUnit[unit]);
    const distanceFromStartDate: number = Math.ceil(currentDate.diff(startDate, momentUnit, true) / duration);
    let returnDate: string = currentDate.format(dateFormat.ngxXAxisLabelOutputFormat);

    switch (unit) {
      case ChronoUnit[ChronoUnit.MONTHS]:
        // Last 18 months/This year/Last Year 1st day of each month [01 May, 01 Aug, 01 Nov, 01 Feb], 4 months increments
        if (totalDataPoints <= 18) {
          return distanceFromStartDate % 3 === 0 ? returnDate : '';
        }
        // Last 24 months, 1st day of each month [01 Jan, 01 July, 01 Jan, 01 July], 6 months increments
        if (totalDataPoints <= 24) {
          return distanceFromStartDate % 5 === 0 ? returnDate : '';
        }
        // Otherwise, 12 months increments
        return distanceFromStartDate % 11 === 0 ? returnDate : '';
      case ChronoUnit[ChronoUnit.WEEKS]:
        // Last 8 weeks [15 Apr, 22, 29, 06 May] - 1 week increment
        if (totalDataPoints <= 8) {
          return returnDate;
        }
        // Last 16 weeks, [15 Apr, 22, 29, 06 May] - 2 weeks increments
        if (totalDataPoints <= 16) {
          return distanceFromStartDate % 2 === 0 ? returnDate : '';
        }
        // Otherwise, 6 weeks increments
        return distanceFromStartDate % 5 === 0 ? returnDate : '';
      case ChronoUnit[ChronoUnit.DAYS]:
        // Last 7 days/ This week/ Last Week [01 Mar, 02, 03, 04, 05, 06, 07] - 1 day increment
        if (totalDataPoints <= 8) {
          return returnDate;
        }
        // Last 14 days [27 Apr, 29, 01 May, 03, 05, 07, 09] - 2 days increments
        if (totalDataPoints <= 16) {
          return distanceFromStartDate % 2 === 0 ? returnDate : '';
        }
        // Last 31 days - 7 days increments
        if (totalDataPoints <= 32) {
          return distanceFromStartDate % 7 === 0 ? returnDate : '';
        }
        // Otherwise - 14 days increments
        return distanceFromStartDate % 14 === 0 ? returnDate : '';
      case ChronoUnit[ChronoUnit.HOURS]:
        const isSameDay = startDate.isSame(endDate, 'date');
        // Change hours time format to remove seconds.
        returnDate = currentDate.format(DateTimeFormat.MOMENT_TIME_FORMAT);
        // Last 8 hours
        if (totalDataPoints <= 8) {
          return returnDate;
        }
        // Last 12 hours - 3 hours increments
        if (totalDataPoints <= 12) {
          return distanceFromStartDate % 3 === 0 ? returnDate : '';
        }
        // Today/ Yesterday/ Last 24 hours [0, 12AM, 06AM, 12PM, 06PM, 12AM] - 4 hours increments
        if (totalDataPoints <= 24) {
          return distanceFromStartDate % (4 * this.xAxisLabelFactor) === 0
            ? this.returnDateWithHours(isSameDay, distanceFromStartDate, duration, 4, currentDate, returnDate)
            : '';
        }
        // 8 hour increments
        if (totalDataPoints <= 48) {
          return distanceFromStartDate % (8 * this.xAxisLabelFactor) === 0
            ? this.returnDateWithHours(isSameDay, distanceFromStartDate, duration, 8, currentDate, returnDate)
            : '';
        }
        // 12 hour increments
        if (totalDataPoints <= 72) {
          return distanceFromStartDate % (12 * this.xAxisLabelFactor) === 0
            ? this.returnDateWithHours(isSameDay, distanceFromStartDate, duration, 12, currentDate, returnDate)
            : '';
        }
        // Otherwise - 24 hours increments
        return distanceFromStartDate % (24 * this.xAxisLabelFactor) === 0
          ? this.returnDateWithHours(isSameDay, distanceFromStartDate, duration, 24, currentDate, returnDate)
          : '';
      case ChronoUnit[ChronoUnit.MINUTES]:
        // Change hours time format to remove seconds.
        returnDate = currentDate.format(DateTimeFormat.MOMENT_TIME_FORMAT);
        // Last 7 minutes
        if (totalDataPoints <= 7) {
          return returnDate;
        }
        // Otherwise - 10 minutues increments
        return distanceFromStartDate % 5 === 0 ? returnDate : '';
      case ChronoUnit[ChronoUnit.SECONDS]:
        returnDate = currentDate.format(DateTimeFormat.MOMENT_TIME_WITH_SECOND_FORMAT);
        // Last 7 seconds
        if (totalDataPoints <= 7) {
          return returnDate;
        }
        // Otherwise - 10 seconds increments
        return distanceFromStartDate % 10 === 0 ? returnDate : '';
      default:
        // Everything else, fallback to all labels
        return dateTimeVal;
    }
  }

  /**
   * returnDateWithHours
   * @private
   * @param {boolean} isSameDay
   * @param {number} distanceFromStartDate
   * @param {number} duration
   * @param {number} increments
   * @param {moment.Moment} currentDate
   * @param {string} defaultReturnDate
   * @returns {*}  {string}
   * @memberof NgxChartLabels
   */
  private returnDateWithHours(
    isSameDay: boolean,
    distanceFromStartDate: number,
    duration: number,
    increments: number,
    currentDate: moment.Moment,
    defaultReturnDate: string,
  ): string {
    const curTime = currentDate.hour();
    if (isSameDay) {
      return defaultReturnDate;
    }
    const isFirstTimeOnStartDate = distanceFromStartDate === 0;
    const isFirstTimeOnNextDate = curTime - duration * increments < 0;
    if (isFirstTimeOnStartDate || isFirstTimeOnNextDate) {
      return currentDate.format(DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT);
    }
    return defaultReturnDate;
  }
}
