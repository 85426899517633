/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ConnectorConfigData } from './connector-config-data.model';

/**
 * ConnectorCreateParams
 * @export
 * @class ConnectorCreateParams
 */
@Serializable
export class ConnectorCreateParams {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'config_data', cls: ConnectorConfigData })
  public configData: ConnectorConfigData = undefined;

  /**
   * Creates an instance of ConnectorCreateParams.
   * @param {...Array<Partial<ConnectorCreateParams>>} args
   * @memberof ConnectorCreateParams
   */
  constructor(...args: Array<Partial<ConnectorCreateParams>>) {
    Object.assign(this, ...args);
  }
}

/**
 * ConnectorUpdateParams
 * @export
 * @class ConnectorUpdateParams
 */
@Serializable
export class ConnectorUpdateParams {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  /**
   * Creates an instance of ConnectorUpdateParams.
   * @param {...Array<Partial<ConnectorUpdateParams>>} args
   * @memberof ConnectorUpdateParams
   */
  constructor(...args: Array<Partial<ConnectorUpdateParams>>) {
    Object.assign(this, ...args);
  }
}

/**
 * ConnectorConfigUpdateParams
 * @export
 * @class ConnectorConfigUpdateParams
 */
@Serializable
export class ConnectorConfigUpdateParams {
  @JsonProperty({ name: 'config_data', cls: ConnectorConfigData })
  public configData: ConnectorConfigData = undefined;

  /**
   * Creates an instance of ConnectorConfigUpdateParams.
   * @param {...Array<Partial<ConnectorConfigUpdateParams>>} args
   * @memberof ConnectorConfigUpdateParams
   */
  constructor(...args: Array<Partial<ConnectorConfigUpdateParams>>) {
    Object.assign(this, ...args);
  }
}
