/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize } from '@dpa/ui-common';
import { mapValues } from 'lodash-es';

import { Threshold } from '@ws1c/intelligence-models/threshold/threshold.model';

/**
 * thresholdsByAttributeConverter
 */
export const thresholdsByAttributeConverter: CustomConverter = {
  fromJson(data: any): {} {
    return mapValues(data, (thresholdData: any) => {
      return deserialize(Threshold, thresholdData);
    });
  },
  toJson(thresholdsByAttribute: any): any {
    return thresholdsByAttribute;
  },
};
