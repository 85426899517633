/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { ScriptTemplate } from './script-template.model';

/**
 * ScriptTemplateSearchResponse
 *
 * @export
 * @class ScriptTemplateSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class ScriptTemplateSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: ScriptTemplate })
  public results: ScriptTemplate[] = [];

  /**
   * Creates an instance of ScriptTemplateSearchResponse
   * @param {Array<Partial<ScriptTemplateSearchResponse>>} args
   * @memberof ScriptTemplateSearchResponse
   */
  constructor(...args: Array<Partial<ScriptTemplateSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
