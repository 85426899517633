/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SystemLimitsFeatureTag } from './system-limits-feature-tag.enum';

/**
 * SystemLimitsUsageDetails
 * @export
 * @class SystemLimitsUsageDetails
 */
@Serializable
export class SystemLimitsUsageDetails {
  @JsonProperty('feature_tag')
  public featureTag: SystemLimitsFeatureTag = undefined;

  @JsonProperty('count')
  public count: number = undefined;

  @JsonProperty('max')
  public max: number = undefined;

  /**
   * Creates an instance of SystemLimitsUsageDetails.
   * @param {Array<Partial<SystemLimitsUsageDetails>>} args
   * @memberof SystemLimitsUsageDetails
   */
  constructor(...args: Array<Partial<SystemLimitsUsageDetails>>) {
    Object.assign(this, ...args);
  }
}
