/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserDescriptor } from './user-descriptor.model';

/**
 * UserDescriptorsRoles
 * @exports
 * @class UserDescriptorsRoles
 */
@Serializable
export class UserDescriptorsRoles {
  @JsonProperty({ name: 'user_descriptors', cls: UserDescriptorsRoles })
  public userDescriptors: UserDescriptor[] = undefined;

  @JsonProperty('role_identifiers')
  public roleIds: string[] = undefined;
  /**
   * Creates an instance of UserDescriptorsRoles.
   * @param {Array<Partial<UserDescriptorsRoles>>} args
   * @memberof UserDescriptorsRoles
   */
  constructor(...args: Array<Partial<UserDescriptorsRoles>>) {
    Object.assign(this, ...args);
  }
}
