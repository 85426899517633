/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from './generic-search-request.model';
import { UserDetails } from './user-details.model';

/**
 * Share Type.
 * @export
 * @enum
 */
export enum ShareType {
  REPORT = 'REPORT',
}

/**
 * ShareData
 * @export
 * @interface ShareData
 */
export interface ShareData {
  shareId: string;
  objectId: string;
  searchRequest?: GenericSearchRequest;
}

/**
 * Share View
 * @export
 * @class ShareView
 */
@Serializable
export class ShareView {
  @JsonProperty({ name: 'created_at', excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'share_id' })
  public shareId: string = undefined;

  @JsonProperty({ name: 'shareable_link' })
  public shareableLink: string = undefined;

  /**
   * Creates an instance of ShareView.
   * @param {any} args
   * @memberof ShareView
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
