/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { AggregationWidget } from './aggregation-widget.model';

/**
 * Global Search Widget
 *
 * @export
 * @class GlobalSearchWidget
 */
export class GlobalSearchWidget extends AggregationWidget {
  public dashboardName: string;
  public dashboardId: string;
  public integration: string;
}
