/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { FeatureSetup } from './feature-setup.model';

/**
 * FeatureCategory Model
 *
 * @export
 * @class FeatureCategory
 */
@Serializable
export class FeatureCategory {
  @JsonProperty({ name: 'setups', cls: FeatureSetup })
  public setups: FeatureSetup[] = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('type')
  public type: string = undefined;

  /**
   * Creates an instance of FeatureCategory
   * @param {Array<Partial<FeatureCategory>>} args - Initialization arguments for FeatureCategory object
   * @memberof FeatureCategory
   */
  constructor(...args: Array<Partial<FeatureCategory>>) {
    Object.assign(this, ...args);
  }
}
