/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * IncrementalLoadingResponseTrendStatus
 * @export
 * @enum {string}
 */
export enum IncrementalLoadingResponseTrendStatus {
  COMPLETED_WITH_SUCCESS = 'COMPLETED_WITH_SUCCESS',
  COMPLETED_WITH_FAILURE = 'COMPLETED_WITH_FAILURE',
  CANCELLED = 'CANCELLED',
  INPROGRESS = 'INPROGRESS',
  INPROGRESS_DO_NOT_CANCEL = 'INPROGRESS_DO_NOT_CANCEL',
}
