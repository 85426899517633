/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationDashboardType
 * @export
 * @enum {string}
 */
export enum AutomationDashboardType {
  // For Automation Dashboard
  AUTOMATION_RUN_HISTORY = 'AUTOMATION_RUN_HISTORY',
  // For Organziation Overview
  DEEM_AUTOMATIONS_OVERVIEW = 'DEEM_AUTOMATIONS_OVERVIEW',
}

/**
 * AutomationWidgetSubtype
 * @export
 * @enum {string}
 */
export enum AutomationWidgetSubtype {
  // For Automation Dashboard
  AUTOMATION_RUN_HISTORY_SUMMARY = 'AUTOMATION_RUN_HISTORY_SUMMARY',
  AUTOMATION_RUN_HISTORY_CHART = 'AUTOMATION_RUN_HISTORY_CHART',
  WORKFLOW_RUN_HISTORY_FOR_EXECUTION_ID = 'WORKFLOW_RUN_HISTORY_FOR_EXECUTION_ID',
  // For Organization Overview
  AUTOMATIONS_OVERVIEW_TOTAL_EXECUTIONS = 'AUTOMATIONS_OVERVIEW_TOTAL_EXECUTIONS',
  AUTOMATIONS_OVERVIEW_TOTAL_EXECUTIONS_PREVIOUS_PERIOD = 'AUTOMATIONS_OVERVIEW_TOTAL_EXECUTIONS_PREVIOUS_PERIOD',
  AUTOMATIONS_OVERVIEW_TOTAL_ACTIONS = 'AUTOMATIONS_OVERVIEW_TOTAL_ACTIONS',
  AUTOMATIONS_OVERVIEW_TOTAL_ACTIONS_PREVIOUS_PERIOD = 'AUTOMATIONS_OVERVIEW_TOTAL_ACTIONS_PREVIOUS_PERIOD',
  AUTOMATIONS_OVERVIEW_EXECUTION_ERRORS = 'AUTOMATIONS_OVERVIEW_EXECUTION_ERRORS',
  AUTOMATIONS_OVERVIEW_EXECUTION_ERRORS_PREVIOUS_PERIOD = 'AUTOMATIONS_OVERVIEW_EXECUTION_ERRORS_PREVIOUS_PERIOD',
  __AUTOMATIONS_OVERVIEW_EXECUTION_ERROR_RATE = '__AUTOMATIONS_OVERVIEW_EXECUTION_ERROR_RATE',
  __AUTOMATIONS_OVERVIEW_EXECUTION_ERROR_RATE_PREVIOUS_PERIOD = '__AUTOMATIONS_OVERVIEW_EXECUTION_ERROR_RATE_PREVIOUS_PERIOD',
}
