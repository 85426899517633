/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/**
 * TreeViewItem
 * @export
 * @class TreeViewItem
 */
export class TreeViewItem {
  public children: TreeViewItem[];

  public label: string;
  public name: string;
  public id: number;
  public isNormalized: boolean = false;
  public selected: number = 0;
  public isExpanded: boolean = true;
  public styledString?: string;
  public tooltip: string;

  /**
   * Creates an instance of TreeViewItem.
   * @param {Array<Partial<TreeViewItem>>} args
   * @memberof TreeViewItem
   */
  constructor(...args: Array<Partial<TreeViewItem>>) {
    Object.assign(this, ...args);
  }
}
