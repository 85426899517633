/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SolutionSettingMetricHolder } from '@ws1c/intelligence-models/solution/solution-setting-metric-holder.model';
import { CveSlaRangeSetting } from './cve-sla-range-setting.model';

/**
 * SlaMetricHolder
 * @export
 * @class SlaMetricHolder
 */
@Serializable
export class SlaMetricHolder extends SolutionSettingMetricHolder {
  @JsonProperty({ name: 'setting', cls: CveSlaRangeSetting })
  public setting: CveSlaRangeSetting[] = undefined;

  /**
   * Creates an instance of SlaMetricHolder.
   * @param {Array<Partial<SlaMetricHolder>>} args
   * @memberof SlaMetricHolder
   */
  constructor(...args: Array<Partial<SlaMetricHolder>>) {
    super();
    Object.assign(this, ...args);
  }
}
