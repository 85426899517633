/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { IncrementalLoadingResponseTrendStatus } from './incremental-loading-response-trend-status.enum';
import { Trend } from './trend.model';

/**
 * TrendWithStatus
 * @export
 * @class TrendWithStatus
 */
@Serializable
export class TrendWithStatus {
  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(IncrementalLoadingResponseTrendStatus) })
  public status: IncrementalLoadingResponseTrendStatus = undefined;

  @JsonProperty({ name: 'trend', cls: Trend })
  public trend: Trend = undefined;

  /**
   * Creates an instance of TrendWithStatus.
   * @param {Array<Partial<TrendWithStatus>>} args
   * @memberof TrendWithStatus
   */
  constructor(...args: Array<Partial<TrendWithStatus>>) {
    Object.assign(this, ...args);
  }
}
