/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserDescriptor } from './user-descriptor.model';

/**
 * DataAccessPolicyBulkAssignRequest
 * @export
 * @class DataAccessPolicyBulkAssignRequest
 */
@Serializable
export class DataAccessPolicyBulkAssignRequest {
  @JsonProperty('data_access_policy_ids')
  public policyIds: string[] = [];

  @JsonProperty({ name: 'user_descriptors', cls: UserDescriptor })
  public accountIds: UserDescriptor[] = [];

  public redirectToPolicyHome: boolean = true;
  public successMessageKey: string = 'DATA_ACCESS_POLICY.SAVE_SUCCESS';

  /**
   * Creates an instance of DataAccessPolicyBulkAssignRequest.
   * @param {Array<Partial<DataAccessPolicyBulkAssignRequest>>} args
   * @memberof DataAccessPolicyBulkAssignRequest
   */
  constructor(...args: Array<Partial<DataAccessPolicyBulkAssignRequest>>) {
    Object.assign(this, ...args);
  }
}
