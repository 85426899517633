/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { MarketplaceResourceLabelType } from './marketplace-resource-label-type.enum';

/**
 * MarketplaceResourceLabel
 * @export
 * @class MarketplaceResourceLabel
 */
@Serializable
export class MarketplaceResourceLabel {
  @JsonProperty({ name: 'name', customConverter: enumConverterFactory(MarketplaceResourceLabelType) })
  public name: MarketplaceResourceLabelType = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  /**
   * Creates an instance of MarketplaceResourceLabel.
   * @param {Array<Partial<MarketplaceResourceLabel>>} args
   * @memberof MarketplaceResourceLabel
   */
  constructor(...args: Array<Partial<MarketplaceResourceLabel>>) {
    Object.assign(this, ...args);
  }
}
