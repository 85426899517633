/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { AggregationWidgetChartType } from './dashboard.enum';

/**
 * WidgetConfig
 * @export
 * @class WidgetConfig
 */
export class WidgetConfig {
  public static OVERLAY_ALLOWED_CHART_TYPES = [AggregationWidgetChartType.VERTICAL, AggregationWidgetChartType.LINE];

  public static readonly OUT_OF_FOCUS_OPACITY = 0.3;
}
