/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Availability Status
 *
 * @export
 * @enum {string}
 */
export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}
