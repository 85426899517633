/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/**
 * ServiceType
 * @export
 * @enum {number}
 */
export enum ServiceType {
  AIRWATCH_API_V1,
  CARBONBLACK,
  LOOKOUT,
  NETSKOPE,
  OPENAPI,
  SERVICE_NOW_V1,
  SLACK,
  INTELLIGENCE,
}

/**
 * ActionType
 * @export
 * @enum {string}
 */
export enum ActionType {
  OPENAPI = 'OPENAPI',
  OPENAPI_HELPER = 'OPENAPI_HELPER',
  OPENAPI_WITH_CALLBACK = 'OPENAPI_WITH_CALLBACK',
}

/**
 * ConnectionTestMode
 *
 * @export
 * @enum {number}
 */
export enum ConnectionTestMode {
  IDLE,
  TESTING,
  SUCCESS,
  FAILURE,
}

/**
 * ConnectionModalMode
 *
 * @export
 * @enum {number}
 */
export enum ConnectionModalMode {
  CLOSE,
  SETUP,
  EDIT,
  EDIT_CONFIRM,
  DEAUTHORIZE,
}

/**
 * ConnectionModalSection
 *
 * @export
 * @enum {number}
 */
export enum ConnectionModalSection {
  AUTHORITIES,
  CREDENTIALS,
  NONE,
  RESULT,
  SETUP_CONNECTION,
  TEST,
  RESOLVE_DYNAMIC_VALUES,
}

/**
 * ServicePermissionType
 *
 * @export
 * @enum {number}
 */
export enum ServicePermissionType {
  ACTION,
  ACCESS,
}

/**
 * ConnectionTestType
 *
 * @export
 * @enum {string}
 */
export enum ConnectionTestType {
  AUTOMATION = 'AUTOMATION',
  CUSTOM = 'CUSTOM',
}
