/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { DeemPlaybookState } from './deem-playbook-state.enum';
import { DeemPlaybookStep } from './deem-playbook-step.model';

/**
 * DeemPlaybookVersion
 * @export
 * @class DeemPlaybookVersion
 */
@Serializable
export class DeemPlaybookVersion {
  @JsonProperty('playbook_id')
  public playbookId: string = undefined;

  @JsonProperty({ name: 'state', customConverter: enumConverterFactory(DeemPlaybookState) })
  public state: DeemPlaybookState = undefined;

  @JsonProperty({ name: 'steps', cls: DeemPlaybookStep })
  public steps: DeemPlaybookStep[] = [];

  /**
   * Initializes instance of DeemPlaybookVersion
   * @param {Array<Partial<DeemPlaybookVersion>>} args
   * @memberof DeemPlaybookVersion
   */
  constructor(...args: Array<Partial<DeemPlaybookVersion>>) {
    Object.assign(this, ...args);
  }
}
