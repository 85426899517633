/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Automation Quota Type
 *
 * @export
 * @enum {string}
 */
export enum AutomationQuotaType {
  EMAIL_EXECUTIONS = 'EMAILS_SENT_PER_DAY',
}
