/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { CrashCrumb } from './crash-crumb.model';

/**
 * GroupedCrashAndroidCrumb
 * @export
 * @class GroupedCrashAndroidCrumb
 */
@Serializable
export class GroupedCrashAndroidCrumb extends CrashCrumb {
  // for grouped crash crumbs only
  @JsonProperty('apteligent.grouped_crash_android.threshold_86_0')
  public crashGroupApple: string = undefined;

  @JsonProperty('apteligent.grouped_crash_android.threshold_92_5')
  public crashGroupAndroid: string = undefined;

  @JsonProperty('apteligent.grouped_crash_android.crash_group')
  public crashGroup: string = undefined;

  /**
   * Creates an instance of GroupedCrashAndroidCrumb.
   * @param {...Array<Partial<GroupedCrashAndroidCrumb>>} args
   * @memberof GroupedCrashAndroidCrumb
   */
  constructor(...args: Array<Partial<GroupedCrashAndroidCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
