/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * LazyComponentType
 *
 * @export
 * @enum {LazyComponentType}
 * @enum {string}
 */
export enum LazyComponentType {
  AUTOMATION_NAV_PREVIEW_CONTENT = 'AUTOMATION_NAV_PREVIEW_CONTENT',
  INCIDENT_NAV_PREVIEW_CONTENT = 'INCIDENT_NAV_PREVIEW_CONTENT',
  INSIGHT_NAV_PREVIEW_CONTENT = 'INSIGHT_NAV_PREVIEW_CONTENT',
  SURVEY_NAV_PREVIEW_CONTENT = 'SURVEY_NAV_PREVIEW_CONTENT',
}
