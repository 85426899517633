/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { DeemPlaybookStepItemType } from './deem-playbook-step-item-type.enum';
import {
  DeemPlaybookStepItemDeviceTimeline,
  DeemPlaybookStepItemImage,
  DeemPlaybookStepItemLink,
  DeemPlaybookStepItemRca,
  DeemPlaybookStepItemReport,
  DeemPlaybookStepItemVideo,
  DeemPlaybookStepItemWidget,
} from './deem-playbook-step-items';

/**
 * DeemPlaybookComponentConfig
 * @export
 * @class DeemPlaybookComponentConfig
 */
export class DeemPlaybookComponentConfig {
  public static stepComponentTypeToModel = {
    [DeemPlaybookStepItemType.DEVICE_TIMELINE]: DeemPlaybookStepItemDeviceTimeline,
    [DeemPlaybookStepItemType.IMAGE]: DeemPlaybookStepItemImage,
    [DeemPlaybookStepItemType.LINK]: DeemPlaybookStepItemLink,
    [DeemPlaybookStepItemType.RCA]: DeemPlaybookStepItemRca,
    [DeemPlaybookStepItemType.REPORT]: DeemPlaybookStepItemReport,
    [DeemPlaybookStepItemType.VIDEO]: DeemPlaybookStepItemVideo,
    [DeemPlaybookStepItemType.WIDGET]: DeemPlaybookStepItemWidget,
  };
}
