/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';
/**
 * AppErrorCrashUploadsTableRequest
 * @export
 * @class AppErrorCrashUploadsTableRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class AppErrorCrashUploadsTableRequest extends GenericSearchRequest {
  /**
   * constructor
   * @param {any} args
   * @memberof AppErrorCrashUploadsTableRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}
