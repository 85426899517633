/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * PrecomputedAggregation
 * @export
 * @class PrecomputedAggregation
 */
@Serializable
export class PrecomputedAggregation {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('i18n_description')
  public description: string = undefined;

  @JsonProperty('i18n_name')
  public label: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('enabled_for_query')
  public enabledForQuery: boolean = undefined;

  /**
   * Creates an instance of PrecomputedAggregation.
   * @param {Array<Partial<PrecomputedAggregation>>} args
   * @memberof PrecomputedAggregation
   */
  constructor(...args: Array<Partial<PrecomputedAggregation>>) {
    Object.assign(this, ...args);
  }
}
