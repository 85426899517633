/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { LookupConfig } from '@ws1c/intelligence-models/connection';
import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { MetaFormFieldPresentationType } from '@ws1c/intelligence-models/meta-form/meta-form-field-presentation-type.enum';

/**
 * @export
 * @class JsonSchemaMetadata
 */
@Serializable
export class JsonSchemaMetadata {
  /**
   * The scope of this metadata instance - a JSON pointer to a particular
   * property of a JSON schema object.
   */
  @JsonProperty('scope')
  public scope: string = undefined;

  /**
   * A human readable string providing a summary of a given object's property.
   */
  @JsonProperty('label')
  public label: string = undefined;

  /**
   * A human readable string meant to provide additional information about
   * a given object's property.
   */
  @JsonProperty('description')
  public description?: string = undefined;

  /**
   * A human readable string meant to provide additional information about
   * a given object's property.
   */
  @JsonProperty('tooltip')
  public tooltip?: string = undefined;

  /**
   * A UI presentation type associated with a given object's property.
   * This presentation type can used to render a form.
   */
  @JsonProperty({ name: 'presentation_type', customConverter: enumConverterFactory(MetaFormFieldPresentationType) })
  public presentationType?: MetaFormFieldPresentationType = MetaFormFieldPresentationType.INPUT;

  /**
   * Specifies if a given property can hold a dynamic variable.
   */
  @JsonProperty('template_allowed')
  public templateAllowed?: boolean = false;

  /**
   * Specifies the searchable action field configuration of a property.
   */
  @JsonProperty({ name: 'lookup_config', cls: LookupConfig })
  public lookupConfig?: LookupConfig = undefined;

  /**
   * A human readable string meant to show a placeholder value.
   */
  @JsonProperty('placeholder_value')
  public placeholderValue?: string = undefined;

  @JsonProperty('note')
  public note?: string = undefined;

  /**
   * Creates an instance of JsonSchemaMetadata
   *
   * @param {...Array<Partial<JsonSchemaMetadata>>} args
   * @memberof JsonSchemaMetadata
   */
  constructor(...args: Array<Partial<JsonSchemaMetadata>>) {
    Object.assign(this, ...args);
  }

  /**
   * name
   * @readonly
   * @type {string}
   * @memberof JsonSchemaMetadata
   */
  public get name(): string {
    return this.scope?.split('/').pop() ?? this.scope;
  }
}
