/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AggregationWidget } from './aggregation-widget.model';

/**
 * Dashboard Layout Update Request Model Object
 * Request format for dashboard layout API
 *
 * @export
 * @class DashboardUpdateLayoutRequest
 */
@Serializable
export class DashboardUpdateLayoutRequest {
  @JsonProperty({ name: 'widgets', cls: AggregationWidget })
  public widgets: AggregationWidget[] = undefined;

  /**
   * Creates an instance of DashboardUpdateLayoutRequest.
   * @param {...Array<Partial<DashboardUpdateLayoutRequest>>} args
   * @memberof DashboardUpdateLayoutRequest
   */
  constructor(...args: Array<Partial<DashboardUpdateLayoutRequest>>) {
    Object.assign(this, ...args);
  }
}
