/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from './converters/converter-factory';
import { DirectoryType } from './directory-type.enum';

/**
 * UserAdminAccountSearchRequest
 * @exports
 * @class UserAdminAccountSearchRequest
 */
@Serializable
export class UserAdminAccountSearchRequest {
  @JsonProperty('search_term')
  public searchTerm: string = '';

  @JsonProperty({ name: 'directory_type', customConverter: enumConverterFactory(DirectoryType) })
  public directoryType: DirectoryType = undefined;

  /**
   * Creates an instance of UserAdminAccountSearchRequest.
   * @param {Array<Partial<UserAdminAccountSearchRequest>>} args
   * @memberof UserAdminAccountSearchRequest
   */
  constructor(...args: Array<Partial<UserAdminAccountSearchRequest>>) {
    Object.assign(this, ...args);
  }
}
