/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';
import { Integration } from '@ws1c/intelligence-models/integration.model';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { AppType } from './app-type.enum';

/**
 * Application Model Object for Apteligent
 *
 * @export
 * @class App
 */
@Serializable
export class App {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('apteligent_app_id')
  public apteligentAppId?: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('type')
  public appType?: string = undefined;

  @JsonProperty('active')
  public active: boolean = undefined;

  @JsonProperty('publisher')
  public publisher: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('sdk')
  public sdk: string = undefined;

  @JsonProperty('optout')
  public optout: boolean = undefined;

  @JsonProperty('integration')
  public integration?: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty('productivity_app')
  public isProductivityApp?: boolean = undefined;

  @JsonProperty('package_id')
  public packageId?: string = undefined;

  /**
   * isInternalApp
   * @readonly
   * @type {boolean}
   * @memberOf App
   */
  public get isInternalApp(): boolean {
    return (this.appType || '').toUpperCase() === AppType.INTERNAL;
  }

  /**
   * Creates an instance of App.
   * @param {...Array<Partial<App>>} args
   * @memberof App
   */
  constructor(...args: Array<Partial<App>>) {
    Object.assign(this, ...args);
  }

  /**
   * services getter
   *
   * @readonly
   * @type {string[]}
   * @memberof App
   */
  public get services(): string[] {
    const services = [(this.integration || '').toUpperCase()];
    if (this.apteligentAppId && this.integration !== Integration.APTELIGENT) {
      services.push('APTELIGENT');
    }
    return services;
  }

  /**
   * getAppType
   * @returns {string}
   * @memberof App
   */
  public getAppType(): string {
    return this.appType || AppType.UNKNOWN;
  }
}

/**
 * AppSearchRequest
 * @export
 * @class AppSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class AppSearchRequest extends GenericSearchRequest {
  /**
   * Creates an instance of AppSearchRequest.
   * @param {any} args
   * @memberof AppSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * AppSearchResponse
 *
 * @export
 * @class AppSearchResponse
 */
export class AppSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty('results')
  public results: any[] = [];

  /**
   * Creates an instance of AppSearchResponse.
   * @param {...Array<Partial<AppSearchResponse>>} args
   * @memberof AppSearchResponse
   */
  constructor(...args: Array<Partial<AppSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
