/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * GlobalSearchCategory
 * @export
 * @enum {string}
 */
export enum GlobalSearchCategory {
  APP = 'app',
  APP_APTELIGENT = 'app_apteligent',
  AUTOMATION = 'automation',
  DASHBOARD = 'dashboard',
  DEVICE = 'device',
  OS = 'os',
  REPORT = 'report',
  USER = 'user',
  WIDGET = 'widget',
}
