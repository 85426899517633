/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Directory
 * @export
 * @class Directory
 */
@Serializable
export class Directory {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('directory_id')
  public directoryId: string = undefined;

  @JsonProperty('directory_type')
  public directoryType: string = undefined;

  @JsonProperty('directory_user_id')
  public directoryUserId: string = undefined;

  @JsonProperty('created_at')
  public createdAt: string = undefined;

  @JsonProperty('display_name')
  public tenantName: string = undefined;

  @JsonProperty('enabled')
  public enabled: boolean = undefined;

  @JsonProperty('org_id')
  public orgId: string = undefined;

  /**
   * Creates an instance of Directory.
   * @param {Array<Partial<Directory>>} args
   * @memberof Directory
   */
  constructor(...args: Array<Partial<Directory>>) {
    Object.assign(this, ...args);
  }
}
