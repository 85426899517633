/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * convertToJsonBlob
 * @param {any} obj
 * @returns {Blob}
 */
export function convertToJsonBlob(obj: any): Blob {
  const jsonStr: string[] = [JSON.stringify(obj)];
  return new Blob(jsonStr, {
    type: 'application/json',
  });
}
