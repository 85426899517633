/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from './converters/date-format.converter';
import { UserDetails } from './user-details.model';

/**
 * ServiceAccount
 * @export
 * @class ServiceAccount
 */
@Serializable
export class ServiceAccount {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'token_endpoint' })
  public tokenEndpoint: string = undefined;

  @JsonProperty({ name: 'client_id' })
  public clientId: string = undefined;

  @JsonProperty({ name: 'client_secret' })
  public clientSecret: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'authorized_grant_types' })
  public authorizedGrantType: string[] = undefined;

  @JsonProperty({ name: 'resource_ids' })
  public resourceIds: string[] = undefined;

  /**
   * Creates an instance of ServiceAccount.
   * @param {Array<Partial<ServiceAccount>>} args
   * @memberof ServiceAccount
   */
  constructor(...args: Array<Partial<ServiceAccount>>) {
    Object.assign(this, ...args);
  }
}
