/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import { AccountRole } from '@ws1c/intelligence-models/account-role.model';
import { AccountFeature } from './account-feature.enum';
import { AccountPermission } from './account-perm.enum';
import { roleToFeatureMapping } from './administrator.constant';

/**
 * getFeaturePermissionFromRoles
 * @export
 * @param {AccountFeature} feature
 * @param {Array<AccountRole | GenericObject>} roles
 * @returns {AccountPermission}
 */
export function getFeaturePermissionFromRoles(feature: AccountFeature, roles: Array<AccountRole | GenericObject>): AccountPermission {
  if (roles.length === 0) {
    return AccountPermission.NONE;
  }
  // Get Array of possible permission for the feature based on roles assinged
  const featureArray = roles.map((role: { name: string }) => {
    const roleMapping = roleToFeatureMapping[role.name];
    return roleMapping[feature] ? roleMapping[feature] : AccountPermission.NONE;
  });
  if (featureArray.includes(AccountPermission.READ_WRITE) && featureArray.includes(AccountPermission.READ_MANAGE)) {
    featureArray.push(AccountPermission.READ_WRITE_MANAGE);
  }
  // Sort the permissions based on priority and return the first element
  featureArray.sort((feature1, feature2) => {
    return feature2 - feature1; // Sort in descending order
  });
  return featureArray[0];
}
