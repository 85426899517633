/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { deserialize } from '@dpa/ui-common';
import { sortBy } from 'lodash-es';

import { CustomReportPreviewSearchResponse } from '@ws1c/intelligence-models/custom-report-preview.model';
import { ApteligentCrumbListLocator } from '@ws1c/intelligence-models/dashboard/apteligent-crumb/apteligent-crumb-list-locator.model';
import { DeviceAppsCrumb } from './device-apps-crumb.model';
import { DeviceCrumbType } from './device-crumb-type.enum';
import { DeviceCrumb } from './device-crumb.model';
import { DeviceDevicePerformanceCrumb } from './device-device-performance-crumb.model';
import { DeviceDevicesCrumb } from './device-devices-crumb.model';
import { DeviceDisplayCrumb } from './device-display-crumb.model';
import { DeviceNetworkCrumb } from './device-network-crumb.model';
import { DeviceOsUpdatesCrumb } from './device-os-updates-crumb.model';
import { DeviceServicesCrumb } from './device-services-crumb.model';
import { DeviceUserActionsCrumb } from './device-user-actions-crumb.model';

/**
 * DeviceCrumbListLocator
 * @export
 * @class DeviceCrumbListLocator
 */
export class DeviceCrumbListLocator extends ApteligentCrumbListLocator {
  /**
   * getDeviceCrumbList
   * @param {any} crumbListStore
   * @returns {DeviceCrumb[]}
   * @memberof DeviceCrumbListLocator
   */
  public getDeviceCrumbList(crumbListStore: any): DeviceCrumb[] {
    const customReportPreviewSearchResponses = this.getCrumbResponses(crumbListStore);
    if (!customReportPreviewSearchResponses) {
      return;
    }
    const crumbTypesByResponseIndex = [
      DeviceCrumbType.APPS,
      DeviceCrumbType.DEVICES,
      DeviceCrumbType.DEVICE_PERFORMANCE,
      DeviceCrumbType.DISPLAY,
      DeviceCrumbType.NET_EVENT,
      DeviceCrumbType.OS_UPDATES,
      DeviceCrumbType.SERVICES,
      DeviceCrumbType.USER_ACTIONS,
    ];
    const modelsByCrumbType = {
      [DeviceCrumbType.APPS]: DeviceAppsCrumb,
      [DeviceCrumbType.DEVICES]: DeviceDevicesCrumb,
      [DeviceCrumbType.DEVICE_PERFORMANCE]: DeviceDevicePerformanceCrumb,
      [DeviceCrumbType.DISPLAY]: DeviceDisplayCrumb,
      [DeviceCrumbType.NET_EVENT]: DeviceNetworkCrumb,
      [DeviceCrumbType.OS_UPDATES]: DeviceOsUpdatesCrumb,
      [DeviceCrumbType.SERVICES]: DeviceServicesCrumb,
      [DeviceCrumbType.USER_ACTIONS]: DeviceUserActionsCrumb,
    };

    const responses = [...customReportPreviewSearchResponses];

    const unsortedCrumbs = responses.reduce(
      (list: DeviceCrumb[], customReportPreviewSearchResponse: CustomReportPreviewSearchResponse, index: number) => {
        if (!customReportPreviewSearchResponse.results) {
          customReportPreviewSearchResponse.results = [];
        }
        return [
          ...list,
          ...customReportPreviewSearchResponse.results.map((searchData: any) => {
            const crumbType = crumbTypesByResponseIndex[index];
            const model = modelsByCrumbType[crumbType];
            const crumb = deserialize(model, searchData);
            return new DeviceCrumb({
              crumbType,
              crumb,
            });
          }),
        ];
      },
      [],
    );

    return sortBy(unsortedCrumbs, 'crumb.eventTimestamp');
  }
}
