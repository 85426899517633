/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * PendoCategory
 *
 * @export
 * @enum {string}
 */
export enum PendoCategory {
  CUSTOM_DASHBOARDS = 'Custom Dashboards',
  INSIGHTS = 'Insights',
  MARKETPLACE = 'Marketplace',
  ORCHESTRATOR = 'Orchestrator',
  SAM = 'Software Assets Management',
  WORKSPACE_ONE_CLOUD = 'Workspace One Cloud',
}
