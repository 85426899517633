/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ServiceLimitsName } from './service-limits-name.enum';
import { ServiceLimitsOverrideType } from './service-limits-override-type.enum';

/**
 * ServiceLimits
 * @export
 * @class ServiceLimits
 */
@Serializable
export class ServiceLimits {
  @JsonProperty('name')
  public name: ServiceLimitsName = undefined;

  @JsonProperty('override_type')
  public overrideType: ServiceLimitsOverrideType = undefined;

  @JsonProperty('value')
  public value: number = undefined;

  /**
   * Creates an instance of ServiceLimits.
   * @param {Array<Partial<ServiceLimits>>} args
   * @memberof ServiceLimits
   */
  constructor(...args: Array<Partial<ServiceLimits>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for isOrgLimit
   * @readonly
   * @type {boolean}
   * @memberof ServiceLimits
   */
  public get isOrgLimit(): boolean {
    return this.overrideType === ServiceLimitsOverrideType.ORG;
  }

  /**
   * Getter for isAccountLimit
   * @readonly
   * @type {boolean}
   * @memberof ServiceLimits
   */
  public get isAccountLimit(): boolean {
    return this.overrideType === ServiceLimitsOverrideType.ACCOUNT;
  }

  /**
   * Getter for isSpecificAccountLimit
   * @readonly
   * @type {boolean}
   * @memberof ServiceLimits
   */
  public get isSpecificAccountLimit(): boolean {
    return this.overrideType === ServiceLimitsOverrideType.SPECIFIC_ACCOUNT;
  }
}
