/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * delete request for data access policy
 * @export
 * @class DataAccessPolicyDeleteRequest
 */
@Serializable
export class DataAccessPolicyDeleteRequest {
  @JsonProperty('policy_ids')
  public policyIds: string[] = undefined;

  /**
   * Creates an instance of DataAccessPolicyDeleteRequest.
   * @param {Array<Partial<DataAccessPolicyDeleteRequest>>} args
   * @memberof DataAccessPolicyDeleteRequest
   */
  constructor(...args: Array<Partial<DataAccessPolicyDeleteRequest>>) {
    Object.assign(this, ...args);
  }
}
