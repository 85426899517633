/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * StatusIcon
 * @export
 * @enum {string}
 */
export enum StatusIcon {
  OK = 'check-circle',
  INFO = 'info-circle',
  WARN = 'exclamation-triangle',
  ERROR = 'exclamation-circle',
}
