/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { WS1BrokerLink } from './ws1-broker-link.model';

/**
 * Directory Model Object
 * @export
 * @class Ws1BrokerDirectory
 */
@Serializable
export class Ws1BrokerDirectory {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty({ name: '_links', cls: WS1BrokerLink })
  public links: WS1BrokerLink = undefined;

  /**
   * constructor
   * @param {Array<Partial<Ws1BrokerDirectory>>} args
   * @memberof Ws1BrokerDirectory
   */
  constructor(...args: Array<Partial<Ws1BrokerDirectory>>) {
    Object.assign(this, ...args);
  }
}
