/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntegrationRegistrationSource
 * @exports
 * @enum {string}
 */
export enum IntegrationRegistrationSource {
  ADMIN = 'ADMIN',
  GEM = 'GEM',
}
