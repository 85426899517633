/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Org } from './org.model';
import { UISettings } from './ui-settings.model';
import { UI } from './ui.model';
import { UserAccount } from './user-account.model';

/**
 * UserPreference
 *
 * @export
 * @class UserPreference
 */
@Serializable
export class UserPreference {
  @JsonProperty({ name: 'ui', cls: UI })
  public ui: UI = undefined;

  @JsonProperty({ name: 'org', cls: Org })
  public org: Org = undefined;

  @JsonProperty({ name: 'user_account', cls: UserAccount })
  public userAccount: UserAccount = undefined;

  @JsonProperty({ name: 'uiSettings', cls: UISettings })
  public uiSettings: UISettings = undefined;

  /**
   * Creates an instance of UserPreference.
   * @param {...Array<Partial<UserPreference>>} args
   * @memberof UserPreference
   */
  constructor(...args: Array<Partial<UserPreference>>) {
    Object.assign(this, ...args);
  }
}
