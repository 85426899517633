/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';
import { TEMPLATE_TYPE } from './template/template-type.enum';
import { TrendMode } from './trend-mode.enum';

/**
 * I18nMetadataHolder
 * @export
 * @class I18nMetadataHolder
 */
@Serializable
export class I18nMetadataHolder {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  /**
   * Creates an instance of I18nMetadataHolder
   * @param {Array<Partial<I18nMetadataHolder>>} args
   * @memberof I18nMetadataHolder
   */
  constructor(...args: Array<Partial<I18nMetadataHolder>>) {
    Object.assign(this, ...args);
  }
}

/**
 * Category
 * @export
 * @class Category
 */
@Serializable
export class Category {
  /**
   * fromEntityIntegrationNames
   * @param {string} integrationName
   * @param {string} entityName
   * @returns {Category}
   */
  public static fromEntityIntegrationNames(integrationName: string, entityName: string): Category {
    return Object.assign(new Category(), {
      integration: Object.assign(new I18nMetadataHolder(), {
        name: integrationName,
      }),
      entity: Object.assign(new I18nMetadataHolder(), {
        name: entityName,
      }),
    });
  }

  @JsonProperty({ name: 'entity', cls: I18nMetadataHolder })
  public entity: I18nMetadataHolder = undefined;

  @JsonProperty({ name: 'integration', cls: I18nMetadataHolder })
  public integration: I18nMetadataHolder = undefined;

  @JsonProperty('supported_trend_modes')
  public supportedTrendModes: string[] = undefined;

  @JsonProperty('supports_reporting')
  public supportsReporting: boolean = undefined;

  @JsonProperty('supports_automation')
  public supportsAutomation: boolean = undefined;

  @JsonProperty('supports_dashboards')
  public supportsDashboards: boolean = undefined;

  @JsonProperty('supports_timeseries_event')
  public supportsTimeseriesReporting: boolean = undefined;

  @JsonProperty('supported_aggregation_counter_types')
  public supportedCounters: string[] = undefined;

  @JsonProperty('supports_scripts')
  public supportsScripts: boolean = undefined;

  @JsonProperty('supports_sensors')
  public supportsSensors: boolean = undefined;

  /**
   * Creates an instance of Category.
   * @param {Array<Partial<Category>>} args
   * @memberof Category
   */
  constructor(...args: Array<Partial<Category>>) {
    Object.assign(this, ...args);
  }

  /**
   * Compares two categories based on their unique categoryIds
   *
   * @param {Category} category
   * @returns {boolean}
   * @memberof Category
   */
  public isSameAs(category: Category): boolean {
    if (!category) {
      return false;
    }
    return this.categoryId === category.categoryId;
  }

  /**
   * Getter for entity name
   *
   * @readonly
   * @type {string}
   * @memberof Category
   */
  public get name(): string {
    return this.entity.name;
  }

  /**
   * Getter for entity label
   *
   * @readonly
   * @type {string}
   * @memberof Category
   */
  public get label(): string {
    return this.entity.label;
  }

  /**
   * description
   * @readonly
   * @type {string}
   * @memberof Category
   */
  public get description(): string {
    return this.entity.description;
  }

  /**
   * Getter for full category label
   *
   * @readonly
   * @type {string}
   * @memberof Category
   */
  public get fullLabel(): string {
    return `${this.integration.label} \u2192 ${this.label}`;
  }

  /**
   * dataType
   * @readonly
   * @returns {string}
   * @memberof Category
   */
  public get dataType(): string {
    return this.supportedTrendModes[0];
  }

  /**
   * Getter for unique categoryId
   *
   * @readonly
   * @type {string}
   * @memberof Category
   */
  public get categoryId(): string {
    return getUniqueId(this.integration.name, this.entity.name);
  }

  /**
   * isHistorical
   * @readonly
   * @type {boolean}
   * @memberof Category
   */
  public get isHistorical(): boolean {
    return (
      this.supportedTrendModes.includes(TrendMode[TrendMode.HISTORICAL]) ||
      this.supportedTrendModes.includes(TrendMode[TrendMode.SNAPSHOT_PERIODICAL])
    );
  }

  /**
   * isTemplateTypeSupported
   * @param {TEMPLATE_TYPE} type
   * @returns {boolean}
   * @memberof Category
   */
  public isTemplateTypeSupported(type: TEMPLATE_TYPE): boolean {
    switch (type) {
      case TEMPLATE_TYPE.AUTOMATIONS: {
        return !!this.supportsAutomation;
      }
      case TEMPLATE_TYPE.REPORTS: {
        return !!this.supportsReporting;
      }
      case TEMPLATE_TYPE.WIDGETS: {
        return !!this.supportsDashboards;
      }
      case TEMPLATE_TYPE.SCRIPTS: {
        return !!this.supportsScripts;
      }
    }
  }
}

export interface IntegrationCategories {
  integration: I18nMetadataHolder;
  categories: Category[];
}
