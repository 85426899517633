/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceDevicePerformanceCrumb
 * @export
 * @class DeviceDevicePerformanceCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceDevicePerformanceCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_user)
  public user: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_make)
  public deviceMake: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_device_model)
  public deviceModel: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_processor_total_processor_totaltime)
  public cpu: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_gpu_usage0)
  public gpu: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_device_performance_memory_usage_percent)
  public memory: number = undefined;

  /**
   * Creates an instance of DeviceDevicePerformanceCrumb.
   * @param {...Array<Partial<DeviceDevicePerformanceCrumb>>} args
   * @memberof DeviceDevicePerformanceCrumb
   */
  constructor(...args: Array<Partial<DeviceDevicePerformanceCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
