/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable, TrendSpan } from '@dpa/ui-common';

/**
 * RollingWindow
 * @export
 * @class RollingWindow
 */
@Serializable
export class RollingWindow {
  @JsonProperty({ name: 'rolling_window_interval', cls: TrendSpan })
  public rollingWindowInterval: TrendSpan = undefined;

  @JsonProperty({ name: 'rolling_window_size', cls: TrendSpan })
  public rollingWindowSize: TrendSpan = undefined;

  /**
   * Creates an instance of RollingWindow.
   * @param {Array<Partial<RollingWindow>>} args
   * @memberof RollingWindow
   */
  constructor(...args: Array<Partial<RollingWindow>>) {
    Object.assign(this, ...args);
  }
}
