/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * NetworkInsightsErrorTab
 * @export
 * @class NetworkInsightsErrorTab
 */
export class NetworkInsightsErrorTab {
  public static readonly BY_ERROR = 'BY_ERROR';
  public static readonly BY_TOTAL = 'BY_TOTAL';
}
