/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ROUTE_NAMES } from '@ws1c/intelligence-models/app.routes.names';
import { IntelligenceFeature } from '@ws1c/intelligence-models/org/intelligence-feature.enum';

/**
 * IntegratedServicesConfig
 * @export
 * @class IntegratedServicesConfig
 */
export class IntegratedServicesConfig {
  public static readonly FEATURE_DETAILS_MAP: Record<
    IntelligenceFeature,
    {
      labelKey: string;
      route: string;
    }
  > = {
    [IntelligenceFeature.AUTOMATION]: {
      labelKey: 'NAVIGATION.FREESTYLE',
      route: `/${ROUTE_NAMES.AUTOMATION.HOME}`,
    },
    [IntelligenceFeature.DASHBOARD]: {
      labelKey: 'NAVIGATION.DASHBOARDS',
      route: `/${ROUTE_NAMES.DASHBOARD.HOME}`,
    },
    [IntelligenceFeature.REPORT]: {
      labelKey: 'NAVIGATION.REPORTS',
      route: `/${ROUTE_NAMES.REPORT.HOME}`,
    },
    [IntelligenceFeature.DEEM]: {
      labelKey: 'NAVIGATION.DEEM',
      route: ROUTE_NAMES.MARKETPLACE.SOLUTIONS_DEEM,
    },
    [IntelligenceFeature.RISK_ANALYTICS]: {
      labelKey: 'NAVIGATION.RISK_ANALYTICS',
      route: `/${ROUTE_NAMES.DASHBOARD.USER_RISK}`,
    },
  } as const;
}
