/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AppRegistration } from '@ws1c/intelligence-models/app-loads/app-registration.model';

/**
 * Application Model Object for Apteligent
 *
 * @export
 * @class App
 */
@Serializable
export class RegisteredAppsList {
  @JsonProperty({ name: 'data', cls: AppRegistration })
  public data: AppRegistration[] = undefined;

  /**
   * Creates an instance of RegisteredAppsList.
   * @param {...Array<Partial<RegisteredAppsList>>} args
   * @memberof RegisteredAppsList
   */
  constructor(...args: Array<Partial<RegisteredAppsList>>) {
    Object.assign(this, ...args);
  }
}

/**
 * RegisteredAppSearchResponse
 *
 * @export
 * @class RegisteredAppSearchResponse
 */
@Serializable
export class RegisteredAppSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AppRegistration })
  public results: AppRegistration[] = [];

  /**
   * Creates an instance of RegisteredAppSearchResponse.
   * @param {...Array<Partial<RegisteredAppSearchResponse>>} args
   * @memberof RegisteredAppSearchResponse
   */
  constructor(...args: Array<Partial<RegisteredAppSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
