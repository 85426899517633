/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationRunHistoryState
 *
 * @export
 * @enum {string}
 */
export enum AutomationRunHistoryState {
  ACTIVE = 'ACTIVE',
  CALLBACK_PENDING = 'CALLBACK_PENDING',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

/**
 * RunHistoryStatusIcon
 *
 * @export
 * @enum {string}
 */
export enum RunHistoryStatusIcon {
  SUCCESS = 'success-standard',
  ERROR = 'error-standard',
  PENDING = 'clock',
}

export const AUTOMATION_SUCCESS_STATUS = [AutomationRunHistoryState.COMPLETED];
export const AUTOMATION_ERROR_STATUS = [
  AutomationRunHistoryState.CANCELLED,
  AutomationRunHistoryState.ERROR,
  AutomationRunHistoryState.EXPIRED,
];
export const AUTOMATION_PENDING_STATUS = [AutomationRunHistoryState.ACTIVE, AutomationRunHistoryState.CALLBACK_PENDING];
