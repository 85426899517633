/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntegrationDetailView
 * @export
 * @class IntegrationDetailView
 */
export class IntegrationDetailView {
  public id: string;
  public displayName: string;
  public integration: string;
  public status: boolean;
  public modified: number;
  public tags: string[];

  // tenantId and tenantName are getting used for
  // 1. Trust Network Partners with multi-tenancy enabled
  // 2. GEM Provisioned integration, as of now Access
  public tenantId: string;
  public tenantName: string;

  // sourceSystemId is getting used only for GEM Provisioned integration
  public sourceSystemId: string;

  /**
   * hasTenantForGemIntegration
   * @returns {boolean}
   * @memberof IntegrationDetailView
   */
  public get hasTenantForGemIntegration(): boolean {
    return !!this.tenantId && !!this.sourceSystemId;
  }

  /**
   * Creates an instance of IntegrationDetailView.
   * @param {Array<Partial<IntegrationDetailView>>} args
   * @memberof IntegrationDetailView
   */
  constructor(...args: Array<Partial<IntegrationDetailView>>) {
    Object.assign(this, ...args);
  }
}
