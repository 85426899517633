/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * ReportTemplateType
 * @export
 * @enum {string}
 */
export enum ReportTemplateType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}
