/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * DevicesDesktopErrorDetailsPage
 * @export
 * @class DevicesDesktopErrorDetailsPage
 */
@Serializable
export class DevicesDesktopErrorDetailsPage {
  @JsonProperty('module')
  public module: string = undefined;

  @JsonProperty('error')
  public error: string = undefined;

  @JsonProperty('process')
  public process: string = undefined;

  @JsonProperty('crash_path')
  public crashPath: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty({ name: 'bread_crumbs', cls: BreadCrumb })
  public breadCrumbs: BreadCrumb[] = undefined;

  /**
   * Creates an instance of DevicesDesktopErrorDetailsPage.
   * @param {...Array<Partial<DevicesDesktopErrorDetailsPage>>} args
   * @memberof DevicesDesktopErrorDetailsPage
   */
  constructor(...args: Array<Partial<DevicesDesktopErrorDetailsPage>>) {
    Object.assign(this, ...args);
  }
}
