/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AccountRole } from '@ws1c/intelligence-models/account-role.model';
import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';

/**
 * AccountGroup
 * @export
 * @class AccountGroup
 */
@Serializable
export class AccountGroup {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('group_id')
  public groupId: string = undefined;

  @JsonProperty('azure_object_id')
  public azureObjectId: string = undefined;

  @JsonProperty('enabled')
  public active: boolean = false;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty({ name: 'integration', excludeToJson: true })
  public integration: string = undefined;

  @JsonProperty('integration_id')
  public integrationId: string = undefined;

  @JsonProperty({ name: 'roles', cls: AccountRole, excludeToJson: true })
  public roles: AccountRole[] = undefined;

  @JsonProperty('role_ids')
  public roleIds: string[] = undefined;

  @JsonProperty('directory_type')
  public directoryType: string = undefined;

  @JsonProperty('directory_id')
  public directoryId: string = undefined;

  @JsonProperty('directory_group_id')
  public directoryGroupId: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'last_synced_at', customConverter: dateFormatConverter, excludeToJson: true })
  public lastSyncedAt: number = undefined;

  /**
   * Creates an instance of AccountGroup.
   * @param {Array<Partial<AccountGroup>>} args
   * @memberof AccountGroup
   */
  constructor(...args: Array<Partial<AccountGroup>>) {
    Object.assign(this, ...args);
  }
}
