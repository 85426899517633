/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * UserRiskDashboardType
 * @export
 * @enum {string}
 */
export enum UserRiskDashboardType {
  // For User Risk Dashboard
  USER_RISK = 'USER_RISK',
}

/**
 * UserRiskWidgetSubtype
 * @export
 * @enum {string}
 */
export enum UserRiskWidgetSubtype {
  // For User Risk Dashboard
  USERS_AT_RISK = 'USERS_AT_RISK',
  USERS_AT_HIGH_RISK = 'USERS_AT_HIGH_RISK',
  USERS_AT_HIGH_RISK_OFFSET_1 = 'USERS_AT_HIGH_RISK_OFFSET_1',
  USERS_AT_LOW_RISK = 'USERS_AT_LOW_RISK',
  USERS_AT_LOW_RISK_OFFSET_1 = 'USERS_AT_LOW_RISK_OFFSET_1',
  USERS_AT_MEDIUM_RISK = 'USERS_AT_MEDIUM_RISK',
  USERS_AT_MEDIUM_RISK_OFFSET_1 = 'USERS_AT_MEDIUM_RISK_OFFSET_1',
}
