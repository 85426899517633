/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { getUniqueId } from '@ws1c/intelligence-models/utils';

/**
 * Template
 * @export
 * @abstract
 * @class Template
 */
@Serializable
export abstract class Template {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty({ name: 'tags', customConverter: defaultValueConverterFactory() })
  public tags: any = undefined;

  @JsonProperty('bookmarked')
  public bookmarked: boolean = false;

  public isStartActionDisabled?: boolean = false;

  /**
   * Creates an instance of Template.
   * @param {any} args
   * @memberof Template
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * templateTags
   * @type {string[]}
   * @memberof Template
   */
  public get templateTags(): string[] {
    const tags = [];
    if (this.tags) {
      Object.keys(this.tags).forEach((tagName: string) => {
        tags.push(...this.tags[tagName].map((tag: string) => `${tagName}_${tag}`));
      });
    }
    return tags;
  }

  /**
   * templateId
   * @readonly
   * @abstract
   * @type {string}
   * @memberof Template
   */
  public abstract get templateId(): string;

  /**
   * label
   * @readonly
   * @abstract
   * @type {string}
   * @memberof Template
   */
  public abstract get label(): string;

  /**
   * description
   * @readonly
   * @abstract
   * @type {string}
   * @memberof Template
   */
  public abstract get description(): string;

  /**
   * integration
   * @readonly
   * @abstract
   * @type {string}
   * @memberof Template
   */
  public abstract get integration(): string;

  /**
   * entity
   * @readonly
   * @abstract
   * @type {string}
   * @memberof Template
   */
  public abstract get entity(): string;

  /**
   * templateKey
   * @readonly
   * @abstract
   * @type {string}
   * @memberof Template
   */
  public abstract get templateKey(): string;

  /**
   * marketplaceResourceId
   * @readonly
   * @type {string}
   * @memberof Template
   */
  public get marketplaceResourceId(): string {
    if (this.integration && this.entity) {
      return getUniqueId(this.integration, this.entity, this.templateKey);
    }
    return getUniqueId(this.templateKey);
  }
}
