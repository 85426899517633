/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, parseQueryString, Serializable } from '@dpa/ui-common';

import { FeatureStep } from './feature-step.model';

/**
 * Feature Setup Model
 * @export
 * @class FeatureSetup
 */
@Serializable
export class FeatureSetup {
  @JsonProperty('action_label')
  public actionLabel: string = undefined;

  @JsonProperty('action_note')
  public actionNote: string = undefined;

  @JsonProperty('beta')
  public beta: boolean = undefined;

  @JsonProperty('key')
  public key: string = undefined;

  @JsonProperty('show_in_home_screen')
  public showInHomeScreen: boolean = undefined;

  @JsonProperty({ name: 'steps', cls: FeatureStep })
  public steps: FeatureStep[] = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty('url')
  public url: string = undefined;

  @JsonProperty('id')
  public id: string = undefined;

  /**
   * Getter for hasBothActionLabelAndUrl
   *
   * @readonly
   * @type {boolean}
   * @memberof FeatureSetup
   */
  public get hasBothActionLabelAndUrl(): boolean {
    return !!this.actionLabel && !!this.url;
  }

  /**
   * Getter for routerLink
   *
   * @readonly
   * @type {string}
   * @memberof FeatureSetup
   */
  public get routerLink(): string {
    return this.url ? this.url.split('?')[0] : '';
  }

  /**
   * Getter for queryParams
   *
   * @readonly
   * @type {object}
   * @memberof FeatureSetup
   */
  public get queryParams(): object {
    const queryString = this.url ? this.url.split('?')[1] : '';
    return parseQueryString(queryString);
  }

  /**
   * Creates an instance of FeatureSetup
   * @param {any} args - initialization arguments for object
   * @memberof FeatureSetup
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
