/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { PendoEventOrchestratorView } from './pendo-event-orchestrator.enum';
import { PendoEventTriggeredBy } from './pendo-event-triggered-by.enum';

/**
 * PendoEventOrchestrator
 *
 * @export
 * @class PendoEventOrchestrator
 */
@Serializable
export class PendoEventOrchestrator {
  @JsonProperty('view')
  public view: PendoEventOrchestratorView;

  @JsonProperty('selectedSource')
  public selectedSource: string;

  @JsonProperty('triggeredBy')
  public triggeredBy: PendoEventTriggeredBy;

  @JsonProperty('multiSelection')
  public multiSelection: boolean;

  @JsonProperty('metadata')
  public metadata: GenericObject;

  /**
   * Initializes instance of PendoEventOrchestrator
   * @param {Array<Partial<PendoEventOrchestrator>>} args - object initialization arguments
   * @memberof PendoEventOrchestrator
   */
  constructor(...args: Array<Partial<PendoEventOrchestrator>>) {
    Object.assign(this, ...args);
  }
}
