/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { each, map } from 'lodash-es';

import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';
import { Column } from './column.model';

/**
 * columnsByCategoryConverter
 * converts multi category and entity data to Record of categoryId and Columns array
 */
export const columnsByCategoryConverter: CustomConverter = {
  fromJson(data: any): Record<string, Column[]> {
    const categoryColumns = {};
    each(data, (integrationColumns: GenericObject, integrationName: string) => {
      each(integrationColumns, (entityColumns: GenericObject, entityName: string) => {
        const categoryId = getUniqueId(integrationName, entityName);
        categoryColumns[categoryId] = map(entityColumns, (entityColumn: any) => deserialize(Column, entityColumn));
      });
    });
    return categoryColumns;
  },
  toJson(categoryColumns: Record<string, Column[]>): any {
    return categoryColumns;
  },
};

/**
 * CategoryColumns
 * @export
 * @class CategoryColumns
 */
@Serializable
export class CategoryColumns {
  @JsonProperty({ name: 'data', customConverter: columnsByCategoryConverter })
  public columnsByCategoryId: Record<string, Column[]> = {};
}
