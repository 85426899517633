/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * SystemPerformanceType
 * @export
 * @enum {string}
 */
export enum SystemPerformanceType {
  BATTERY_LEVEL = 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_LEVEL',
  BATTERY_VOLTAGE = 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_VOLTAGE',
  BATTERY_TEMPERATURE = 'STANDARD_DASHBOARD.DEVICES_DETAILS.BATTERY_TEMPERATURE',
  CPU = 'STANDARD_DASHBOARD.DEVICES_DETAILS.CPU',
  CPU_TEMPERATURE = 'STANDARD_DASHBOARD.DEVICES_DETAILS.CPU_TEMPERATURE',
  GPU = 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU',
  GPU1 = 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU1',
  GPU2 = 'STANDARD_DASHBOARD.DEVICES_DETAILS.GPU2',
  MEMORY = 'STANDARD_DASHBOARD.DEVICES_DETAILS.MEMORY',
  NETWORK_JITTER = 'STANDARD_DASHBOARD.DEVICES_DETAILS.NETWORK_JITTER',
  NETWORK_LATENCY = 'STANDARD_DASHBOARD.DEVICES_DETAILS.NETWORK_LATENCY',
}
