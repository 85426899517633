/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

/**
 * CustomReportPreviewSearchResponse
 *
 * @export
 * @class CustomReportPreviewSearchResponse
 */
export class CustomReportPreviewSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty('results')
  public results: any[] = [];

  public valid?: boolean;

  public isTimedout?: boolean = true;

  /**
   * Creates an instance of CustomReportPreviewSearchResponse.
   * @param {Array<Partial<CustomReportPreviewSearchResponse>>} args
   * @memberof CustomReportPreviewSearchResponse
   */
  constructor(...args: Array<Partial<CustomReportPreviewSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
