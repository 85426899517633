/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * Defines report config types.
 *
 * @export
 * @enum {number}
 */
export enum ReportConfigType {
  GENERIC_REPORT_CONFIG,
  PREDEFINED_REPORT_CONFIG,
}
