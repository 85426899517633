/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';

/**
 * AutomationLastRunDetails Model Object
 *
 * @export
 * @class AutomationLastRunDetails
 */
@Serializable
export class AutomationLastRunDetails {
  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public runDate: number = undefined;

  @JsonProperty('status')
  public status: string = undefined;

  /**
   * Creates an instance of AutomationLastRunDetails.
   * @param {Array<Partial<AutomationLastRunDetails>>} args
   * @memberof AutomationLastRunDetails
   */
  constructor(...args: Array<Partial<AutomationLastRunDetails>>) {
    Object.assign(this, ...args);
  }
}
