/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AccessConfiguration } from './access-configuration.model';
import { dateFormatConverter } from './converters/date-format.converter';
import { FilterConfiguration } from './filter-configuration.model';
import { UserDetails } from './user-details.model';

/**
 * DataAccessPolicy
 * @export
 * @class DataAccessPolicy
 */
@Serializable
export class DataAccessPolicy {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'assigned_user_count', excludeToJson: true })
  public assignedUserCount: number = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty({ name: 'filter_configuration', cls: FilterConfiguration })
  public filterConfiguration: FilterConfiguration = undefined;

  @JsonProperty({ name: 'access_configuration', cls: AccessConfiguration })
  public accessConfiguration: AccessConfiguration = undefined;

  @JsonProperty('enabled')
  public enabled: boolean = false;

  /**
   * Creates an instance of DataAccessPolicy.
   * @param {Array<Partial<DataAccessPolicy>>} args
   * @memberof DataAccessPolicy
   */
  constructor(...args: Array<Partial<DataAccessPolicy>>) {
    Object.assign(this, ...args);
  }

  /**
   * isDefaultPolicyAssigned
   * @param {string} defaultPermitAllSystemId
   * @returns {boolean}
   * @memberof DataAccessPolicy
   */
  public isDefaultPolicyAssigned(defaultPermitAllSystemId: string): boolean {
    return defaultPermitAllSystemId && this.id === defaultPermitAllSystemId;
  }
}
