/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * EventForwarderType
 * Enum of event forwarders connections which are supported in the UI.
 *
 * @export
 * @enum {string}
 */
export enum EventForwarderType {
  CARBONBLACK = 'CARBONBLACK',
}

/**
 * EventForwarderConnectionModalMode
 * Enum for maintaining different modal flows for Event Forwarders connections.
 *
 * @export
 * @enum {number}
 */
export enum EventForwarderConnectionModalMode {
  CLOSE,
  SETUP_OR_EDIT,
  CONFIRM,
  DEAUTHORIZE,
}

/**
 * EventForwarderConnectionModalSection
 * Enum for maintaining different accordian section inside modal for Event Forwarders connections.
 *
 * @export
 * @enum {number}
 */
export enum EventForwarderConnectionModalSection {
  NONE,
  AUTHORITIES,
  CREDENTIALS,
}

/**
 * EventForwarderConnectionPrimaryProperties
 * Enum of event forwarders connections primary properties which will be used directly
 * in card or modal and doesn't come with templates.
 *
 * @export
 * @enum {string}
 */
export enum EventForwarderConnectionPrimaryProperties {
  ID = 'id',
  NAME = 'name',
  STATUS_CHECK_SUCCESS = 'status_check_success',
  MODIFIED_AT = 'modified_at',
}
