/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * IntegrationUser
 * @export
 * @class IntegrationUser
 */
@Serializable
export class IntegrationUser {
  @JsonProperty('azure_object_id')
  public azureObjectId: string = undefined;

  @JsonProperty('azure_on_premises_immutable_id')
  public azureOnPremImmutableId: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('active')
  public active: boolean = false;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  /**
   * Creates an instance of IntegrationUser.
   * @param {any} args
   * @memberof IntegrationUser
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
