/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSamplingFrequency
 *
 * @export
 * @enum {number}
 */
export enum DeemSamplingFrequency {
  DAILY = 1,
}
