/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Params } from '@angular/router';

import { AppConstants } from '@ws1c/intelligence-models/app.constants';
import { ROUTE_NAMES } from '@ws1c/intelligence-models/app.routes.names';
import { DeemSolutionRelatedServices } from '@ws1c/intelligence-models/deem/deem-learn-more-related-services.enum';
import { MarketplaceResourceTag } from '@ws1c/intelligence-models/marketplace/marketplace-resource-tag.model';
import { SolutionSetupCardType } from '@ws1c/intelligence-models/solution/solution-setup-card-type.enum';
import { SOLUTION_ACCORDION_WIZARD_BY_ID } from './solution-accordion-wizard-ids.constant';
import { SolutionSetupCard } from './solution-setup-card.interface';
import { SolutionStepGroup } from './solution-step-group.enum';
import { SolutionConfigType, SolutionSubmodule } from './solution.enum';

/**
 * SolutionConfig
 * @export
 * @class SolutionConfig
 */
export class SolutionConfig {
  public static stepGroupAndSolutionConfigTypeByWizardId = {
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.CVE].CVE_DEFINE_DATA_SOURCE]: [
      SolutionConfigType.CVE,
      SolutionStepGroup.DEFINE_DATA_SOURCE,
    ],
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.CVE].REVIEW_SETTINGS]: [SolutionConfigType.CVE, SolutionStepGroup.REVIEW_SETTINGS],
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_DESKTOP]: [
      SolutionConfigType.DEEM_V2,
      SolutionStepGroup.DESKTOP_EXPERIENCE_MANAGEMENT,
    ],
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_MOBILE]: [
      SolutionConfigType.DEEM_V2,
      SolutionStepGroup.MOBILE_EXPERIENCE_MANAGEMENT,
    ],
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_MOBILE_APP]: [
      SolutionConfigType.DEEM_V2,
      SolutionStepGroup.MOBILE_APP_TELEMETRY,
    ],
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_HORIZON]: [
      SolutionConfigType.DEEM_V2,
      SolutionStepGroup.HORIZON_CLOUD_SERVICES,
    ],
    [SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_FRONTLINE]: [
      SolutionConfigType.DEEM_V2,
      SolutionStepGroup.FRONTLINE_WORKER_MANAGEMENT,
    ],
  };

  public static cveStepNames = {
    [SolutionStepGroup.DEFINE_DATA_SOURCE]: ['workspace_one_uem'],
    // filtered by feature flag in solution setup selector
    [SolutionStepGroup.REVIEW_SETTINGS]: ['sla_windows', 'sla_ios'],
  };

  public static deemV2StepNames = {
    [SolutionStepGroup.DESKTOP_EXPERIENCE_MANAGEMENT]: ['workspace_one_uem', 'desktop_advanced_telemetry'],
    [SolutionStepGroup.MOBILE_EXPERIENCE_MANAGEMENT]: ['workspace_one_uem', 'mobile_advanced_telemetry', 'mobile_apps'],
    [SolutionStepGroup.MOBILE_APP_TELEMETRY]: ['mobile_apps'],
    [SolutionStepGroup.HORIZON_CLOUD_SERVICES]: ['horizon'],
    [SolutionStepGroup.FRONTLINE_WORKER_MANAGEMENT]: ['workspace_one_uem', 'mobile_apps', 'enable_frontline'],
  };

  public static stepNamesByGroupNameBySolutionConfigType = {
    [SolutionConfigType.CVE]: SolutionConfig.cveStepNames,
    [SolutionConfigType.DEEM_V2]: SolutionConfig.deemV2StepNames,
  };

  public static dataGenerationTimeBySolutionV2 = {
    [SolutionConfigType.DEEM_V2]: 4 * AppConstants.ONE_HOUR_IN_MILLIS,
    [SolutionConfigType.CVE]: 24 * AppConstants.ONE_HOUR_IN_MILLIS,
  };

  public static accordionIdBySubmodule = {
    [SolutionSubmodule.DESKTOP_EXPERIENCE_MANAGEMENT]: SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_DESKTOP,
    [SolutionSubmodule.MOBILE_EXPERIENCE_MANAGEMENT]: SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_MOBILE,
    [SolutionSubmodule.HORIZON_CLOUD_SERVICES]: SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_HORIZON,
    [SolutionSubmodule.FRONTLINE_WORKER_MANAGEMENT]: SOLUTION_ACCORDION_WIZARD_BY_ID[SolutionConfigType.DEEM_V2].DEEM_V2_FRONTLINE,
  };

  public static marketplaceFrontlineLabel: string = 'Frontline';

  public static marketplaceFrontlineQueryParams: Params = {
    resource_tags: JSON.stringify([
      new MarketplaceResourceTag({
        category: SolutionConfig.marketplaceFrontlineLabel,
      }),
    ]),
  };

  public static deemSetupCardByType: Record<SolutionSetupCardType, SolutionSetupCard> = {
    [SolutionSetupCardType.DESKTOP_EXPERIENCE_MANAGEMENT]: {
      submodule: SolutionSubmodule.DESKTOP_EXPERIENCE_MANAGEMENT,
      titleKeyV2: 'MARKETPLACE.DEEM.DESKTOP_DEVICES_APPS',
      descriptionKeyV2: 'MARKETPLACE.DEEM.DESKTOP_DEVICES_APPS_DESC',
      icon: 'application',
      learnMoreLink: `/${ROUTE_NAMES.MARKETPLACE.DEEM_DESKTOP_LEARN_MORE}`,
      learnMoreDesc: 'MARKETPLACE.DEEM.SCORES_DESC',
      relatedServices: [
        DeemSolutionRelatedServices.INTELLIGENT_HUB,
        DeemSolutionRelatedServices.WORKSPACE_ONE_ASSIST,
        DeemSolutionRelatedServices.ITSM_CONNECTOR,
      ],
      setupLink: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SETUP_DESKTOP}`,
      viewLink: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SUMMARY_PHYSICAL_DESKTOP_APPS}`,
    },
    [SolutionSetupCardType.MOBILE_EXPERIENCE_MANAGEMENT]: {
      submodule: SolutionSubmodule.MOBILE_EXPERIENCE_MANAGEMENT,
      titleKeyV2: 'MARKETPLACE.DEEM.MOBILE_DEVICES_APPS',
      descriptionKeyV2: 'MARKETPLACE.DEEM.MOBILE_DEVICES_APPS_DESC',
      icon: 'mobile',
      learnMoreLink: `/${ROUTE_NAMES.MARKETPLACE.DEEM_MOBILE_LEARN_MORE}`,
      learnMoreDesc: 'MARKETPLACE.DEEM.MOBILE_DEVICES_APPS_LEARN_MORE_DESC',
      relatedServices: [
        DeemSolutionRelatedServices.INTELLIGENT_HUB,
        DeemSolutionRelatedServices.WORKSPACE_ONE_CONTENT_ANDORID,
        DeemSolutionRelatedServices.WORKSPACE_ONE_CONTENT_IOS,
        DeemSolutionRelatedServices.WORKSPACE_ONE_ASSIST,
        DeemSolutionRelatedServices.ITSM_CONNECTOR,
      ],
      setupLink: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SETUP_MOBILE}`,
      viewLink: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SUMMARY_PHYSICAL_MOBILE_APPS}`,
    },
    [SolutionSetupCardType.HORIZON_CLOUD_SERVICES]: {
      submodule: SolutionSubmodule.HORIZON_CLOUD_SERVICES,
      titleKeyV2: 'MARKETPLACE.DEEM.VIRTUAL_DESKTOP_APPS',
      descriptionKeyV2: 'MARKETPLACE.DEEM.VIRTUAL_DESKTOP_APPS_DESC',
      icon: 'cloud-network',
      learnMoreLink: `/${ROUTE_NAMES.MARKETPLACE.DEEM_VIRTUAL_LEARN_MORE}`,
      learnMoreDesc: 'SOLUTIONS.HORIZON_CLOUD_SERVICES_DESC',
      relatedServices: [DeemSolutionRelatedServices.INTELLIGENT_HUB, DeemSolutionRelatedServices.WORKSPACE_ONE_ASSIST],
      setupLink: `/${ROUTE_NAMES.MARKETPLACE.SOLUTIONS_DEEM}`,
      setupLinkQueryParams: { enable_horizon: true },
      viewLink: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SUMMARY_VIRTUAL_V2}`,
    },
    [SolutionSetupCardType.FRONTLINE_WORKER_MANAGEMENT]: {
      submodule: SolutionSubmodule.FRONTLINE_WORKER_MANAGEMENT,
      titleKeyV2: 'MARKETPLACE.DEEM.FRONTLINE_WORKERS_ADD_ON',
      descriptionKeyV2: 'MARKETPLACE.DEEM.FRONTLINE_WORKERS_ADD_ON_DESC',
      icon: 'employee',
      learnMoreLink: `/${ROUTE_NAMES.MARKETPLACE.DEEM_FRONTLINE_LEARN_MORE}`,
      learnMoreDesc: 'MARKETPLACE.DEEM.FRONTLINE_WORKERS_ADD_ON_DESC',
      relatedServices: [
        DeemSolutionRelatedServices.INTELLIGENT_HUB,
        DeemSolutionRelatedServices.WORKSPACE_ONE_CONTENT_ANDORID,
        DeemSolutionRelatedServices.WORKSPACE_ONE_CONTENT_IOS,
        DeemSolutionRelatedServices.WORKSPACE_ONE_ASSIST,
        DeemSolutionRelatedServices.ITSM_CONNECTOR,
      ],
      setupLink: `/${ROUTE_NAMES.SOLUTIONS_DEEM_SETUP_FRONTLINE}`,
      viewLink: `/${ROUTE_NAMES.MARKETPLACE.TEMPLATES_DASHBOARDS}`,
      viewLinkQueryParams: SolutionConfig.marketplaceFrontlineQueryParams,
    },
  };

  /**
   * getDeemSetupCard
   * @static
   * @param {SolutionSetupCardType} cardType
   * @param {boolean} isReady
   * @param {boolean} isInProgress
   * @param {boolean} isEnabled
   * @returns {SolutionSetupCard}
   */
  public static getDeemSetupCard(
    cardType: SolutionSetupCardType,
    isReady?: boolean,
    isInProgress?: boolean,
    isEnabled?: boolean,
  ): SolutionSetupCard {
    const card = SolutionConfig.deemSetupCardByType[cardType];
    card.isReady = isReady;
    card.isInProgress = isInProgress;
    card.isEnabled = isEnabled;
    return card;
  }
}
