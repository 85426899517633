/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { deserialize, GenericObject } from '@dpa/ui-common';
import { cloneDeep, get, last, setWith, sortBy } from 'lodash-es';

import { AppPlatform } from '@ws1c/intelligence-models/app-loads/app-platform.model';
import { CustomReportPreviewSearchResponse } from '@ws1c/intelligence-models/custom-report-preview.model';
import { ApteligentCrumbType } from './apteligent-crumb-type.enum';
import { ApteligentCrumb } from './apteligent-crumb.model';
import { CrashAndroidCrumb } from './crash-android-crumb.model';
import { CrashIosCrumb } from './crash-ios-crumb.model';
import { CustomEventCrumb } from './custom-event-crumb.model';
import { GroupedCrashAndroidCrumb } from './grouped-crash-android-crumb.model';
import { GroupedCrashIosCrumb } from './grouped-crash-ios-crumb.model';
import { HandledExceptionAndroidCrumb } from './handled-exception-android-crumb.model';
import { HandledExceptionIosCrumb } from './handled-exception-ios-crumb.model';
import { NetErrorCrumb } from './net-error-crumb.model';
import { NetEventCrumb } from './net-event-crumb.model';
import { PluginExceptionCrumb } from './plugin-exception-crumb.model';

type ApteligentCrumbListLocation = [string, string, number, number, string, string, string];

/**
 * ApteligentCrumbListLocator
 * @export
 * @class ApteligentCrumbListLocator
 */
export class ApteligentCrumbListLocator {
  /**
   * constructor
   * @param {string} deviceUuid
   * @param {string} appId
   * @param {number} startTime
   * @param {number} endTime
   * @param {string} stateName
   * @param {string} platform
   * @param {string} crashId
   * @param {string} eventId
   * @memberof ApteligentCrumbListLocator
   */
  constructor(
    public deviceUuid: string,
    public appId: string,
    public startTime: number,
    public endTime: number,
    public stateName: string,
    public platform: string,
    public crashId: string,
    public eventId?: string,
  ) {}

  /**
   * getCrumbList
   * @param {any} crumbListStore
   * @returns {ApteligentCrumb[]}
   * @memberof ApteligentCrumbListLocator
   */
  public getCrumbList(crumbListStore: any): ApteligentCrumb[] {
    const customReportPreviewSearchResponses = this.getCrumbResponses(crumbListStore);
    if (!customReportPreviewSearchResponses) {
      return;
    }
    const crumbTypesByResponseIndex = [
      ApteligentCrumbType.NET_EVENT,
      ApteligentCrumbType.NET_ERROR,
      ApteligentCrumbType.CUSTOM_EVENT,
      ApteligentCrumbType.HANDLED_EXCEPTION,
      ApteligentCrumbType.PLUGIN_EXCEPTION,
      ApteligentCrumbType.CRASH,
    ];
    const modelsByCrumbType = {
      [ApteligentCrumbType.NET_EVENT]: NetEventCrumb,
      [ApteligentCrumbType.NET_ERROR]: NetErrorCrumb,
      [ApteligentCrumbType.CUSTOM_EVENT]: CustomEventCrumb,
      [ApteligentCrumbType.HANDLED_EXCEPTION]:
        this.platform.toLowerCase() === AppPlatform.ANDROID.toLowerCase() ? HandledExceptionAndroidCrumb : HandledExceptionIosCrumb,
      [ApteligentCrumbType.PLUGIN_EXCEPTION]: PluginExceptionCrumb,
      [ApteligentCrumbType.CRASH]: this.platform.toLowerCase() === AppPlatform.ANDROID.toLowerCase() ? CrashAndroidCrumb : CrashIosCrumb,
    };

    // Remove groupedCrash query, this will be used to augment the crash data
    const nonGroupedResponses = [...customReportPreviewSearchResponses];
    const groupedCrashResponse = nonGroupedResponses.pop();

    const unsortedCrumbs = nonGroupedResponses.reduce(
      (list: any[], customReportPreviewSearchResponse: CustomReportPreviewSearchResponse, index: number) => {
        if (!customReportPreviewSearchResponse.results) {
          customReportPreviewSearchResponse.results = [];
        }
        return [
          ...list,
          ...customReportPreviewSearchResponse.results.map((searchData: GenericObject) => {
            const crumbType = crumbTypesByResponseIndex[index];
            const model: any = modelsByCrumbType[crumbType];
            const crumb = deserialize(model, searchData);
            return Object.assign(new ApteligentCrumb(), {
              crumbType,
              crumb,
            });
          }),
        ];
      },
      [],
    );

    // If this crash is symbolicated, add the crashGroup to the crashCrumb
    if (groupedCrashResponse?.results?.length) {
      const groupedCrashCrumb =
        this.platform.toLowerCase() === AppPlatform.ANDROID.toLowerCase()
          ? deserialize(GroupedCrashAndroidCrumb, groupedCrashResponse.results[0])
          : deserialize(GroupedCrashIosCrumb, groupedCrashResponse.results[0]);
      const crashCrumb = last(unsortedCrumbs);
      crashCrumb.crumb.crashGroup = groupedCrashCrumb.crashGroupApple || groupedCrashCrumb.crashGroupAndroid;
    }

    return sortBy(unsortedCrumbs, 'crumb.eventTimestamp');
  }

  /**
   * getCrumbResponses
   * @param {any} crumbResponseListStore
   * @returns {CustomReportPreviewSearchResponse[]}
   * @memberof ApteligentCrumbListLocator
   */
  public getCrumbResponses(crumbResponseListStore: any): CustomReportPreviewSearchResponse[] {
    return get(crumbResponseListStore, this.getLocation());
  }

  /**
   * setCrumbResponses
   * @param {any} crumbResponseListStore
   * @param {CustomReportPreviewSearchResponse[]} customReportPreviewSearchResponses
   * @returns {any}
   * @memberof ApteligentCrumbListLocator
   */
  public setCrumbResponses(crumbResponseListStore: any, customReportPreviewSearchResponses: CustomReportPreviewSearchResponse[]): any {
    const cloneStore = cloneDeep(crumbResponseListStore);
    setWith(cloneStore, this.getLocation(), customReportPreviewSearchResponses, (object) => object || {});
    return cloneStore;
  }

  /**
   * getLocation
   * @returns {ApteligentCrumbListLocation}
   * @memberof ApteligentCrumbListLocator
   */
  public getLocation(): ApteligentCrumbListLocation {
    return [this.deviceUuid, this.appId, this.startTime, this.endTime, this.stateName, this.platform, this.crashId];
  }
}
