/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable, TrendSpan } from '@dpa/ui-common';
import { isUndefined } from 'lodash-es';

/**
 * @export
 * @class ReportDateRange
 */
@Serializable
export class ReportDateRange {
  @JsonProperty('start_date_millis')
  public startDateMillis: number = undefined;

  @JsonProperty('end_date_millis')
  public endDateMillis: number = undefined;

  // Keeping this trendSpan to keep it consistent with dashboard
  @JsonProperty({ name: 'time_span', cls: TrendSpan })
  public trendSpan: TrendSpan = undefined;

  /**
   * constructor
   * @param {Partial<ReportDateRange>[]} args
   * @memberof ReportDateRange
   */
  constructor(...args: Array<Partial<ReportDateRange>>) {
    Object.assign(this, ...args);
  }

  /**
   * getStartEndMillis
   * @returns {Partial<ReportDateRange>}
   * @memberof TrendDateRange
   */
  public getStartEndMillis(): Partial<ReportDateRange> {
    return {
      startDateMillis: isUndefined(this.startDateMillis) ? Date.now() - this.trendSpan?.getUnitInMillis() : this.startDateMillis,
      endDateMillis: isUndefined(this.endDateMillis) ? Date.now() : this.endDateMillis,
    };
  }
}
