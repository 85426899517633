/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntegrationObjectsType
 * @exports
 * @enum {string}
 */
export enum IntegrationObjectsType {
  DASHBOARD = 'dashboard',
  AUTOMATION = 'automation',
  REPORT = 'report',
}
