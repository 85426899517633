/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { IntelligenceFeature } from './intelligence-feature.enum';
import { OrgPreference } from './org-preference.enum';

export const INTELLIGENCE_FEATURES: IntelligenceFeature[] = [
  IntelligenceFeature.AUTOMATION,
  IntelligenceFeature.DASHBOARD,
  IntelligenceFeature.REPORT,
  IntelligenceFeature.DEEM,
  IntelligenceFeature.RISK_ANALYTICS,
];

export const ONBOARDING_PREFERENCES: OrgPreference[] = [
  OrgPreference.TRIAL_CUSTOMER_QUESTIONNAIRE_STEP_COMPLETED,
  OrgPreference.ACCESS_TENANT_REPROVISION_STEP_COMPLETED,
];
