/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Operator
 * @export
 * @class Operator
 */
@Serializable
export class Operator {
  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('value')
  public value: string = undefined;

  @JsonProperty('single')
  public single: boolean = undefined;

  @JsonProperty('value_required')
  public valueRequired: boolean = undefined;

  @JsonProperty('min_length')
  public minLength: number = undefined;

  /**
   * Creates an instance of Operator.
   * @param {Array<Partial<Operator>>} args
   * @memberof Operator
   */
  constructor(...args: Array<Partial<Operator>>) {
    Object.assign(this, ...args);
  }
}
