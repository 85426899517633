/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dataBySectionConverter } from './data-by-section.converter';

/**
 * AutomationTestActionRequest
 * @export
 * @class AutomationTestActionRequest
 */
@Serializable
export class AutomationTestActionRequest {
  @JsonProperty('connector_config_id')
  public connectorConfigId: string = undefined;

  @JsonProperty('action_template_id')
  public actionTemplateId: string = undefined;

  @JsonProperty('connector_integration')
  public connectorIntegration: string = undefined;

  @JsonProperty('action_type')
  public actionType: string = undefined;

  @JsonProperty({ name: 'dataBySection', customConverter: dataBySectionConverter })
  public dataBySection: any = {};

  /**
   * Creates an instance of AutomationTestActionRequest
   * @param {Array<Partial<AutomationTestActionRequest>>} args
   * @memberof AutomationTestActionRequest
   */
  constructor(...args: Array<Partial<AutomationTestActionRequest>>) {
    Object.assign(this, ...args);
  }
}
