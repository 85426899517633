/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * HomePageSectionType
 * @export
 * @enum {number}
 */
export enum HomePageSectionType {
  SERVICES,
  TRIALS,
  GETTING_STARTED,
  BOOKMARKS,
  DASHBOARDS,
}
