/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { WidgetTemplate } from '@ws1c/intelligence-models/template/widget/widget-template.model';
import { AggregationWidget } from './aggregation-widget.model';
import { TrendDefinition } from './trend-definition.model';

/**
 * Widget Preview Data Requset Model Object
 * Request format for previewing widget data
 *
 * @export
 * @class WidgetUpdateDataRequest
 */
export class WidgetUpdateDataRequest {
  public widget: WidgetTemplate | AggregationWidget = null;
  public trendDefinition: TrendDefinition;

  /**
   * Creates an instance of WidgetUpdateDataRequest
   * @param {WidgetTemplate | AggregationWidget} widget
   * @param {TrendDefinition} trendDefinition
   * @memberOf WidgetUpdateDataRequest
   */
  constructor(widget: WidgetTemplate | AggregationWidget, trendDefinition: TrendDefinition) {
    this.widget = widget;
    this.trendDefinition = trendDefinition;
  }
}
