/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, JsonProperty, Serializable } from '@dpa/ui-common';

import { TrendDateRange } from '@ws1c/intelligence-models/dashboard/trend-date-range.model';
import { QueryBuilder, RuleGroup } from '@ws1c/intelligence-models/filter';

export const filterConverter: CustomConverter = {
  fromJson: () => {
    return undefined;
  },
  toJson: (ruleGroup: RuleGroup) => {
    const queryBuilder = new QueryBuilder(ruleGroup);
    return queryBuilder.getQueryString();
  },
};

/**
 * Standard Dashboard Global Filter Model Object
 *
 * @export
 * @class StandardDashboardGlobalFilter
 */
@Serializable
export class StandardDashboardGlobalFilter {
  @JsonProperty({ name: 'filter', customConverter: filterConverter })
  public ruleGroup: RuleGroup = undefined;

  @JsonProperty({ name: 'date_range', cls: TrendDateRange })
  public dateRange: TrendDateRange = undefined;

  /**
   * Creates an instance of StandardDashboardGlobalFilter.
   * @param {Array<Partial<StandardDashboardGlobalFilter>>} args
   * @memberof StandardDashboardGlobalFilter
   */
  constructor(...args: Array<Partial<StandardDashboardGlobalFilter>>) {
    Object.assign(this, ...args);
  }
}
