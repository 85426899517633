/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * SearchTagFilterField
 *
 * @exports
 * @enum {string}
 */
export enum SearchTagFilterField {
  BOOKMARKED = 'bookmarked',
  IS_SHARED_DASHBOARD = 'is_shared_dashboard',
  IS_OWNER = 'is_owner',
  IS_ORPHANED = 'is_orphaned',
  IS_SHARED_REPORT = 'shared_report',
  IS_FRONTLINE = 'is_frontline',
}
