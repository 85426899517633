/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AvailableService } from './available-service.model';

/**
 * AvailableServiceSearchResponse
 * @export
 * @class AvailableServiceSearchResponse
 */
@Serializable
export class AvailableServiceSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AvailableService })
  public results: AvailableService[] = [];

  /**
   * Creates an instance of AvailableServiceSearchResponse
   * @param {any} args
   * @memberof AvailableServiceSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
