/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

export const DEVICE_PLATFORM: any = {
  ANDROID: 'Android',
  APPLE: 'Apple iOS',
  APPLE_MACOS: 'Apple macOS',
  WINDOWS: 'Windows Desktop',
};

export const DEVICE_TYPE: any = {
  MOBILE: 'Mobile',
  DESKTOP: 'Desktop',
};
