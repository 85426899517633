/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * DashboardTemplateImport
 * @export
 * @class DashboardTemplateImport
 */
@Serializable
export class DashboardTemplateImport {
  @JsonProperty('template_id')
  public templateId: string = undefined;

  @JsonProperty('dashboard_name')
  public name: string = undefined;

  @JsonProperty('dashboard_description')
  public description: string = undefined;

  /**
   * Creates an instance of DashboardTemplateImport.
   * @param {Array<Partial<DashboardTemplateImport>>} args
   * @memberof DashboardTemplateImport
   */
  constructor(...args: Array<Partial<DashboardTemplateImport>>) {
    Object.assign(this, ...args);
  }
}
