/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppErrorDetailTabType
 * @export
 * @enum {string}
 */
export enum AppErrorDetailTabType {
  SUMMARY = 'SUMMARY',
  STACKTRACE = 'STACKTRACE',
  BREADCRUMB = 'BREADCRUMB',
  USER = 'USER',
}
