/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

export enum TooltipSeverity {
  INFO,
  WARNING,
  DANGER,
}

/**
 * Tooltip
 */
export interface Tooltip {
  textKey: string;
  severity: TooltipSeverity;
}
