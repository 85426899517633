/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * Trial Status
 *
 * @export
 * @enum {string}
 */
export enum TrialStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  REQUESTED = 'REQUESTED',
}
