/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppInsightType
 * @export
 * @enum {string}
 */
export enum AppInsightType {
  CRASH = 'CRASH',
  DAU = 'DAU',
  HANDLED_EXCEPTION = 'HANDLED_EXCEPTION',
  HANG = 'HANG',
  LOAD = 'LOAD',
  NETWORK_ERROR = 'NETWORK_ERROR',
  USAGE = 'USAGE',
}

/**
 * AppInsightTypeToInsightName
 * @export
 * @enum {string} i18n key
 */
export enum AppInsightTypeToInsightName {
  CRASH = 'COMMON_MESSAGES.CRASH_RATE',
  DAU = 'COMMON_MESSAGES.DAU',
  HANDLED_EXCEPTION = 'COMMON_MESSAGES.NUMBER_OF_HANDLED_EXCEPTIONS',
  HANG = 'COMMON_MESSAGES.NUMBER_OF_APPLICATION_HANG_EVENTS',
  LOAD = 'COMMON_MESSAGES.NUMBER_OF_APP_LOADS',
  NETWORK_ERROR = 'COMMON_MESSAGES.NETWORK_ERROR',
  USAGE = 'COMMON_MESSAGES.APPLICATION_USAGE',
}

/**
 * WidgetSubTypeToPageTitle
 * @export
 * @enum {string} i18n key
 */
export enum WidgetSubTypeToPageTitle {
  APP_EXPERIENCE_APP_CRASH_OVER_TIME = 'COMMON_MESSAGES.CRASHES',
  APP_EXPERIENCE_APP_LOADS_OVER_TIME = 'COMMON_MESSAGES.NUMBER_OF_APP_LOADS',
  APP_EXPERIENCE_APP_DAU_OVER_TIME = 'COMMON_MESSAGES.DAU',
  APP_EXPERIENCE_APP_NETWORK_ERROR_OVER_TIME = 'COMMON_MESSAGES.NETWORK_ERROR',
  APP_EXPERIENCE_APP_HANDLED_EXCEPTION_OVER_TIME = 'COMMON_MESSAGES.NUMBER_OF_HANDLED_EXCEPTIONS',
  __DEEM_APP_CRASH_RATE_HISTORICAL = 'COMMON_MESSAGES.CRASH_RATE',
  DEEM_APP_HANG = 'COMMON_MESSAGES.APPLICATION_HANG',
  DEEM_APP_USAGE = 'COMMON_MESSAGES.APPLICATION_USAGE',
}
