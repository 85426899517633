/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AccessType } from '@ws1c/intelligence-models/common/access-type.enum';
import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { AggregationWidget } from '@ws1c/intelligence-models/dashboard/aggregation-widget.model';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';

/**
 * Dashboard View Model Object.
 * Dashboard definition with widgets list.
 *
 * @export
 * @class DashboardView
 */
@Serializable
export class DashboardView {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('dashboard_type')
  public dashboardType: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'widgets', cls: AggregationWidget })
  public widgets: AggregationWidget[] = undefined;

  @JsonProperty({ name: 'last_widget_added_at', customConverter: dateFormatConverter })
  public lastWidgetAddedAt: number = undefined;

  @JsonProperty('total_widgets')
  public totalWidgets: number = undefined;

  @JsonProperty('thumbnail_widget_id')
  public thumbnailId: string = undefined;

  @JsonProperty({ name: 'shared_dashboard', excludeToJson: true })
  public isSharedDashboard: boolean = false;

  @JsonProperty({ name: 'owner', excludeToJson: true })
  public isOwner: boolean = false;

  @JsonProperty({ name: 'share_count', excludeToJson: true })
  public shareCount: number = undefined;

  @JsonProperty({ name: 'account_access_level', customConverter: enumConverterFactory(AccessType), excludeToJson: true })
  public accessLevel: AccessType = undefined;

  public bookmarked: boolean = false;

  /**
   * Creates an instance of DashboardView.
   * @param {...Array<Partial<DashboardView>>} args
   * @memberof DashboardView
   */
  constructor(...args: Array<Partial<DashboardView>>) {
    Object.assign(this, ...args);
  }

  /**
   * isOwnerOrHasFullAccess
   * @readonly
   * @type {boolean}
   * @memberof DashboardView
   */
  public get isOwnerOrHasFullAccess(): boolean {
    return this.isOwner || this.accessLevel === AccessType.FULL;
  }

  /**
   * isOwnerOrIsShared
   * @readonly
   * @type {boolean}
   * @memberof DashboardView
   */
  public get isOwnerOrIsShared(): boolean {
    return this.isOwner || this.isSharedDashboard;
  }

  /**
   * objectAccessLevel
   * @readonly
   * @type {AccessType}
   * @memberof DashboardView
   */
  public get objectAccessLevel(): AccessType {
    if (this.isOwner) {
      return AccessType.OWNER;
    }
    if (this.isSharedDashboard) {
      return this.accessLevel;
    }
    // if the user is not owner and it is not shared with him then access level is Manage
    return AccessType.MANAGE;
  }
}
