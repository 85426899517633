/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { ConnectorConfigData } from './connector-config-data.model';
import { ConnectorConfigStatus } from './connector-config-status.enum';

/**
 * ConnectorConfig
 * @export
 * @class ConnectorConfig
 */
@Serializable
export class ConnectorConfig {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('connector_id')
  public connectorId: string = undefined;

  @JsonProperty({ name: 'config_data', cls: ConnectorConfigData })
  public configData: ConnectorConfigData = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(ConnectorConfigStatus) })
  public status: ConnectorConfigStatus = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  /**
   * Creates an instance of ConnectorConfig.
   * @param {...Array<Partial<ConnectorConfig>>} args
   * @memberof ConnectorConfig
   */
  constructor(...args: Array<Partial<ConnectorConfig>>) {
    Object.assign(this, ...args);
  }
}
