/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ReportFormat
 * @export
 * @enum {string}
 */
export enum ReportFormat {
  CSV = 'CSV',
  JSONL = 'JSONL',
}
