/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * MemoryTab
 * @export
 * @enum {string}
 */
export enum MemoryTab {
  MEMORY_USAGE = 'MEMORY_USAGE',
  DISK_SPACE_FREE = 'DISK_SPACE_FREE',
  DISK_SPACE_TOTAL = 'DISK_SPACE_TOTAL',
}
