/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * SolutionPlatform
 * @export
 * @enum {string}
 */
export enum SolutionPlatform {
  WINDOWS = 'windows',
  IOS = 'ios',
  MACOS = 'macos',
}
