/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightType
 * @export
 * @enum {string}
 */
export enum InsightType {
  APP_CRASHES = 'APP_CRASHES',
  APP_HANGS = 'APP_HANGS',
  APP_INSTALL_FAILED = 'APP_INSTALL_FAILED',
  APP_INSTALL_PENDING = 'APP_INSTALL_PENDING',
  APP_USAGE = 'APP_USAGE',
  APTELIGENT_CRASH_ANDROID = 'APTELIGENT_CRASH_ANDROID',
  APTELIGENT_CRASH_IOS = 'APTELIGENT_CRASH_IOS',
  APTELIGENT_NET_ERROR = 'APTELIGENT_NET_ERROR',
  BOOT_DURATION = 'BOOT_DURATION',
  CPU_UTILIZATION = 'CPU_UTILIZATION',
  FAILED_SSO_LOGIN = 'FAILED_SSO_LOGIN',
  HORIZON_SESSION_LOGON_DURATION = 'HORIZON_SESSION_LOGON_DURATION',
  OS_CRASHES = 'OS_CRASHES',
  SHUTDOWN_DURATION = 'SHUTDOWN_DURATION',
}
