/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * SolutionSettingMetricKey
 *
 * @exports
 * @class SolutionSettingMetricKey
 */
@Serializable
export class SolutionSettingMetricKey {
  @JsonProperty('solution_type')
  public solutionType: string = undefined;

  @JsonProperty('solution_setting')
  public solutionSetting: string = undefined;

  @JsonProperty('metric_name')
  public metricName: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  /**
   * Creates an instance of SolutionSettingMetricKey.
   * @param {Array<Partial<SolutionSettingMetricKey>>} args
   * @memberof SolutionSettingMetricKey
   */
  constructor(...args: Array<Partial<SolutionSettingMetricKey>>) {
    Object.assign(this, ...args);
  }
}
