/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Pendo Update Response
 *
 * @export
 * @class PendoUpdateResponse
 */
@Serializable
export class PendoUpdateResponse {
  @JsonProperty('failed')
  public failed: number = undefined;

  @JsonProperty('total')
  public total: number = undefined;

  @JsonProperty('updated')
  public updated: number = undefined;

  /**
   * Initializes instance of PendoUpdateResponse
   * @param {Array<Partial<PendoUpdateResponse>>} args - object initialization arguments
   * @memberof PendoUpdateResponse
   */
  constructor(...args: Array<Partial<PendoUpdateResponse>>) {
    Object.assign(this, ...args);
  }
}
