/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

export const CLARITY_SIGNPOST_POSITION = {
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_MIDDLE: 'bottom-middle',
  BOTTOM_RIGHT: 'bottom-right',
  LEFT_BOTTOM: 'left-bottom',
  LEFT_MIDDLE: 'left-middle',
  LEFT_TOP: 'left-top',
  RIGHT: 'right',
  RIGHT_BOTTOM: 'right-bottom',
  RIGHT_MIDDLE: 'right-middle',
  RIGHT_TOP: 'right-top',
  TOP_LEFT: 'top-left',
  TOP_MIDDLE: 'top-middle',
  TOP_RIGHT: 'top-right',
};
