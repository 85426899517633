/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { SlaMetricHolder } from '@ws1c/intelligence-models/cve/sla-metric-holder.model';
import { ThresholdMetricHolder } from '@ws1c/intelligence-models/deem/threshold-metric-holder.model';
import { TouchDetails } from '@ws1c/intelligence-models/touch-details.model';
import { SolutionSettingMetricKey } from './solution-setting-metric-key.model';
import { SolutionSettingMetricType } from './solution-setting-metric-type.enum';

/**
 * metricHolderConverter
 * @export
 * @type {CustomConverter}
 */
export const metricHolderConverter: CustomConverter = {
  fromJson(data: GenericObject): GenericObject {
    if (data?.type === SolutionSettingMetricType.DEEM_EXPERIENCE_SCORE) {
      return deserialize(ThresholdMetricHolder, data);
    }
    if (data?.type === SolutionSettingMetricType.CVE_SLA) {
      return deserialize(SlaMetricHolder, data);
    }
    return data;
  },
  toJson(data: GenericObject): GenericObject {
    return data;
  },
};

/**
 * SolutionSettingMetricDetails
 * @export
 * @class SolutionSettingMetricDetails
 * @extends {TouchDetails}
 */
@Serializable
export class SolutionSettingMetricDetails extends TouchDetails {
  @JsonProperty({ name: 'solution_setting_metric_key', cls: SolutionSettingMetricKey })
  public metricKey: SolutionSettingMetricKey = undefined;

  @JsonProperty({ name: 'metric_holder', customConverter: metricHolderConverter })
  public metricHolder: any = undefined;

  /**
   * Creates an instance of SolutionSettingMetricDetails.
   * @param {Array<Partial<SolutionSettingMetricDetails>>} args
   * @memberof SolutionSettingMetricDetails
   */
  constructor(...args: Array<Partial<SolutionSettingMetricDetails>>) {
    super();
    Object.assign(this, ...args);
  }
}
