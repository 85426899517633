/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AppCrashUser
 * @export
 * @class AppCrashUser
 */
@Serializable
export class AppCrashUser {
  @JsonProperty('user_name')
  public userName: string = undefined;

  @JsonProperty('first_occurred')
  public firstOccurred: number = undefined;

  @JsonProperty('last_occurred')
  public lastOccurred: number = undefined;

  @JsonProperty('num_crashes')
  public numCrashes: number = undefined;

  @JsonProperty('device_model')
  public deviceModel: string = undefined;

  /**
   * Creates an instance of AppCrashUser.
   * @param {...Array<Partial<AppCrashUser>>} args
   * @memberof AppCrashUser
   */
  constructor(...args: Array<Partial<AppCrashUser>>) {
    Object.assign(this, ...args);
  }
}
