/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import moment from 'moment';

/**
 * getFormattedFileName
 * @export
 * @param {string} selectedTemplateName
 * @returns {string}
 */
export function getFormattedFileName(selectedTemplateName: string): string {
  const FILE_NAME_PATTERN: RegExp = new RegExp(/[^a-zA-Z0-9]/, 'g');
  const fileNameWithNoSpecialChars = selectedTemplateName.replaceAll(FILE_NAME_PATTERN, '');
  const currentDate = moment().format('MMDDYY');
  const formattedFileName: string = `${fileNameWithNoSpecialChars}_${currentDate}.json`;
  return formattedFileName;
}
