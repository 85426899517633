/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * HandledExceptionIosCrumb
 * @export
 * @class HandledExceptionIosCrumb
 */
@Serializable
export class HandledExceptionIosCrumb {
  @JsonProperty('apteligent.handled_exception_ios.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.handled_exception_ios._error_name')
  public errorName: string = undefined;

  @JsonProperty('apteligent.handled_exception_ios.error_reason')
  public errorReason: string = undefined;

  /**
   * Creates an instance of HandledExceptionIosCrumb.
   * @param {...Array<Partial<HandledExceptionIosCrumb>>} args
   * @memberof HandledExceptionIosCrumb
   */
  constructor(...args: Array<Partial<HandledExceptionIosCrumb>>) {
    Object.assign(this, ...args);
  }
}
