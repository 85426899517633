/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { MetaFormField } from '@ws1c/intelligence-models/meta-form/meta-form-field.model';
import { MetaFormSection } from '@ws1c/intelligence-models/meta-form/meta-form-section.model';

/**
 * ActionTemplate Model Object
 * @export
 * @class ActionTemplate
 */
@Serializable
export class ActionTemplate {
  @JsonProperty({ name: 'field_views', cls: MetaFormField })
  public fields: MetaFormField[] = [];

  @JsonProperty({ name: 'section_views', cls: MetaFormSection })
  public sections: MetaFormSection[] = [];
}
