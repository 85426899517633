/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * UserProfile Dashboard Type
 * @export
 * @enum {string}
 */
export enum UserProfileDashboardType {
  USERS = 'USERS',
  USER_PROFILE = 'USER_PROFILE',
  USER_PROFILE_EXPERIENCE = 'USER_PROFILE_EXPERIENCE',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH',
  USER_PROFILE_DEVICES_BY_USER = 'USER_PROFILE_DEVICES_BY_USER',
  USER_PROFILE_IOS = 'USER_PROFILE_IOS',
  USER_PROFILE_ANDROID = 'USER_PROFILE_ANDROID',
  USER_PROFILE_SECURITY = 'USER_PROFILE_SECURITY',
}

/**
 * UserProfileWidgetSubtype
 * @export
 * @enum {string}
 */
export enum UserProfileWidgetSubtype {
  // summary for users
  USERS_BY_DEVICE_COUNT = 'USERS_BY_DEVICE_COUNT',
  USER_PROFILE_TOTAL_APP_LOADS_COUNT = 'USER_PROFILE_TOTAL_APP_LOADS_COUNT',
  USER_PROFILE_TOTAL_CRASHES_COUNT = 'USER_PROFILE_TOTAL_CRASHES_COUNT',
  USER_PROFILE_TOTAL_NETWORK_ERRORS_COUNT = 'USER_PROFILE_TOTAL_NETWORK_ERRORS_COUNT',
  USER_PROFILE_CRASHES_HISTORICAL = 'USER_PROFILE_CRASHES_HISTORICAL',
  USER_PROFILE_HE_HISTORICAL = 'USER_PROFILE_HE_HISTORICAL',
  _USER_PROFILE_NETWORK_FAILURE = '_USER_PROFILE_NETWORK_FAILURE',
  USER_PROFILE_NETWORK_FAILURE = 'USER_PROFILE_NETWORK_FAILURE',
  _USER_PROFILE_SUCCESS_USER_FLOWS = '_USER_PROFILE_SUCCESS_USER_FLOWS',
  USER_PROFILE_SUCCESS_USER_FLOWS = 'USER_PROFILE_SUCCESS_USER_FLOWS',
  _USER_PROFILE_FAILURE_USER_FLOWS = '_USER_PROFILE_FAILURE_USER_FLOWS',
  USER_PROFILE_FAILURE_USER_FLOWS = 'USER_PROFILE_FAILURE_USER_FLOWS',
  USER_PROFILE_DEVICES_BY_USER = 'USER_PROFILE_DEVICES_BY_USER',
  USER_PROFILE_DEVICE_DETAILS = 'USER_PROFILE_DEVICE_DETAILS',

  // USER PROFILE - overview
  __USER_PROFILE_HEALTH_OVER_TIME = '__USER_PROFILE_HEALTH_OVER_TIME',
  __USER_PROFILE_RISK_OVER_TIME = '__USER_PROFILE_RISK_OVER_TIME',
  USER_PROFILE_EXPERIENCE_HEALTH_OVER_TIME = 'USER_PROFILE_EXPERIENCE_HEALTH_OVER_TIME',
  USER_PROFILE_HEALTH_OVER_TIME = 'USER_PROFILE_HEALTH_OVER_TIME',
  USER_PROFILE_RISK_OVER_TIME = 'USER_PROFILE_RISK_OVER_TIME',
  USER_PROFILE_DEVICE_LIST = 'USER_PROFILE_DEVICE_LIST',
  __USER_PROFILE_DEVICE_HEALTH = '__USER_PROFILE_DEVICE_HEALTH',
  __USER_PROFILE_RISK_SCORE = '__USER_PROFILE_RISK_SCORE',
  USER_PROFILE_DEVICE_HEALTH = 'USER_PROFILE_DEVICE_HEALTH',
  USER_PROFILE_RISK_SCORE = 'USER_PROFILE_RISK_SCORE',
  USER_PROFILE_LAST_ACTIVE_BY_APPS = 'USER_PROFILE_LAST_ACTIVE_BY_APPS',
  USER_PROFILE_LAST_ACTIVE_BY_USER_ACTIONS = 'USER_PROFILE_LAST_ACTIVE_BY_USER_ACTIONS',

  // USER PROFILE - experience
  __USER_PROFILE_EXPERIENCE_DEVICE_HEALTH = '__USER_PROFILE_EXPERIENCE_DEVICE_HEALTH',
  __USER_PROFILE_EXPERIENCE_HEALTH_OVER_TIME = '__USER_PROFILE_EXPERIENCE_HEALTH_OVER_TIME',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_CRASH = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_CRASH',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_BOOT_TIME = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_BOOT_TIME',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_SHUTDOWN_TIME = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_SHUTDOWN_TIME',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_BATTERY = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_BATTERY',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_CPU = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_CPU',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_GPU = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_GPU',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_MEMORY = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_MEMORY',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_WIFI = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_WIFI',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_DOWNLOAD = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_DOWNLOAD',
  USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_UPLOAD = 'USER_PROFILE_EXPERIENCE_DEVICE_HEALTH_UPLOAD',

  // USER PROFILE - security
  __USER_PROFILE_USER_RISK_OVER_TIME = '__USER_PROFILE_USER_RISK_OVER_TIME',
  __USER_PROFILE_LOGIN_OVER_TIME = '__USER_PROFILE_LOGIN_OVER_TIME',
  __USER_PROFILE_USER_LOGIN_OVER_TIME = '__USER_PROFILE_USER_LOGIN_OVER_TIME',
  USER_PROFILE_USER_RISK_OVER_TIME = 'USER_PROFILE_USER_RISK_OVER_TIME',
  USER_PROFILE_USER_LOGIN_FAILED_OVER_TIME = 'USER_PROFILE_USER_LOGIN_FAILED_OVER_TIME',
  USER_PROFILE_USER_LOGIN_SUCCESS_OVER_TIME = 'USER_PROFILE_USER_LOGIN_SUCCESS_OVER_TIME',
  USER_PROFILE_USER_LOGIN_OVER_TIME = 'USER_PROFILE_USER_LOGIN_OVER_TIME',
  USER_PROFILE_USER_LOGIN_CARBON_BLACK = 'USER_PROFILE_USER_LOGIN_CARBON_BLACK',
  USER_PROFILE_USER_ACTIVITIES = 'USER_PROFILE_USER_ACTIVITIES',
}

/**
 * UserDetailPageType
 * @export
 * @enum {number}
 */
export enum UserDetailPageType {
  USER_PROFILE,
  DEEM_USER,
}
