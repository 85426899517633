/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, JsonProperty, Serializable } from '@dpa/ui-common';

export enum CustomEventCrumbEventType {
  USER_DEFINED = 'USER_DEFINED',
  SYSTEM_DEFINED = 'SYSTEM_DEFINED',
  NETWORK = 'NETWORK',
  ERROR = 'ERROR',
  CRASH = 'CRASH',
}

const crumbsEventTypeConverter = {
  fromJson: (enumerator: number) => {
    // CustomEventCrumb comes with a customEventType that is a number value
    // This explicitly maps those numbers to an CustomEventCrumbEventType
    const customEventCrumbEventTypeByEnumerator = {
      0: CustomEventCrumbEventType.USER_DEFINED,
      1: CustomEventCrumbEventType.SYSTEM_DEFINED,
      2: CustomEventCrumbEventType.NETWORK,
      3: CustomEventCrumbEventType.ERROR,
    };
    // Crash events do not come with an customEventType value
    return customEventCrumbEventTypeByEnumerator[enumerator] || CustomEventCrumbEventType[CustomEventCrumbEventType.CRASH];
  },
  toJson: (eventTypeString: string) => {
    return CustomEventCrumbEventType[eventTypeString];
  },
} as CustomConverter;

/**
 * CustomEventCrumb
 * @export
 * @class CustomEventCrumb
 */
@Serializable
export class CustomEventCrumb {
  @JsonProperty({ name: 'apteligent.custom_event.custom_event_type', customConverter: crumbsEventTypeConverter })
  public customEventType: string = undefined;

  @JsonProperty('apteligent.custom_event.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.custom_event.name')
  public name: string = undefined;

  /**
   * Creates an instance of CustomEventCrumb.
   * @param {...Array<Partial<CustomEventCrumb>>} args
   * @memberof CustomEventCrumb
   */
  constructor(...args: Array<Partial<CustomEventCrumb>>) {
    Object.assign(this, ...args);
  }
}
