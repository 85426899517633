/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { ThresholdsByAttribute } from '@ws1c/intelligence-models/threshold/thresholds-by-attribute.interface';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { thresholdsByAttributeConverter } from './dashboard-thresholds.converter';

/**
 * DashboardThresholdSummary
 * @export
 * @class DashboardThresholdSummary
 */
@Serializable
export class DashboardThresholdSummary {
  @JsonProperty('standard_dashboard_type')
  public standardDashboardType: string = undefined;

  @JsonProperty({ name: 'thresholds', customConverter: thresholdsByAttributeConverter })
  public thresholds: ThresholdsByAttribute = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * Creates an instance of DashboardThresholdSummary
   * @param {any} args
   * @memberof DashboardThresholdSummary
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
