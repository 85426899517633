/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceDevicesCrumb
 * @export
 * @class DeviceDevicesCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceDevicesCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_user)
  public user: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_module)
  public module: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_reason)
  public reason: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_process)
  public process: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_devices_duration_millis)
  public duration: number = undefined;

  /**
   * Creates an instance of DeviceDevicesCrumb.
   * @param {...Array<Partial<DeviceDevicesCrumb>>} args
   * @memberof DeviceDevicesCrumb
   */
  constructor(...args: Array<Partial<DeviceDevicesCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
