/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { EventCategory } from '@ws1c/intelligence-models/dashboard/apteligent-crumb/event-category.enum';
import { ApteligentCrumbType } from './apteligent-crumb-type.enum';
import { CrashAndroidCrumb } from './crash-android-crumb.model';
import { CrashIosCrumb } from './crash-ios-crumb.model';
import { CustomEventCrumb, CustomEventCrumbEventType } from './custom-event-crumb.model';
import { GroupedCrashAndroidCrumb } from './grouped-crash-android-crumb.model';
import { GroupedCrashIosCrumb } from './grouped-crash-ios-crumb.model';
import { HandledExceptionAndroidCrumb } from './handled-exception-android-crumb.model';
import { HandledExceptionIosCrumb } from './handled-exception-ios-crumb.model';
import { NetErrorCrumb } from './net-error-crumb.model';
import { NetEventCrumb } from './net-event-crumb.model';
import { PluginExceptionCrumb } from './plugin-exception-crumb.model';

/**
 * ApteligentCrumb
 * @export
 * @class ApteligentCrumb
 */
export class ApteligentCrumb {
  public crumbType: ApteligentCrumbType;
  public crumb:
    | NetErrorCrumb
    | NetEventCrumb
    | CustomEventCrumb
    | CrashAndroidCrumb
    | CrashIosCrumb
    | GroupedCrashAndroidCrumb
    | GroupedCrashIosCrumb
    | HandledExceptionAndroidCrumb
    | HandledExceptionIosCrumb
    | PluginExceptionCrumb;

  /**
   * Creates an instance of ApteligentCrumb.
   * @param {...Array<Partial<ApteligentCrumb>>} args
   * @memberof ApteligentCrumb
   */
  constructor(...args: Array<Partial<ApteligentCrumb>>) {
    Object.assign(this, ...args);
  }

  /**
   * getCrumbEventCategory
   * EventCategorys used to group together crumbs, used by dpa-user-flows-crumbs
   * Network Events contains both NET_EVENT and NET_ERROR types
   * CUSTOM_EVENT types are split into User Defined Events and System Events
   * @returns {EventCategory}
   * @memberof ApteligentCrumb
   */
  public getCrumbEventCategory(): EventCategory {
    if (this.crumbType === ApteligentCrumbType.CRASH) {
      return EventCategory.CRASH;
    }
    if ([ApteligentCrumbType.NET_ERROR, ApteligentCrumbType.NET_EVENT].includes(this.crumbType)) {
      return EventCategory.NETWORK_EVENTS;
    }
    if (this.crumbType === ApteligentCrumbType.HANDLED_EXCEPTION) {
      return EventCategory.HANDLED_EXCEPTIONS;
    }
    if (this.crumbType === ApteligentCrumbType.PLUGIN_EXCEPTION) {
      return EventCategory.PLUGIN_EXCEPTIONS;
    }
    if (this.crumbType === ApteligentCrumbType.CUSTOM_EVENT) {
      const customEventCrumb = this.crumb as CustomEventCrumb;
      if (customEventCrumb.customEventType === CustomEventCrumbEventType.USER_DEFINED) {
        return EventCategory.USER_DEFINED;
      }
      // Only CustomEventCrumbEventType.SYSTEM_DEFINED left
      return EventCategory.SYSTEM_EVENTS;
    }
  }

  /**
   * isCrumbLinkVisible
   * Used to determine if the crumb has a visible link in dpa-user-flows-crumbs
   * @returns {boolean}
   * @memberof ApteligentCrumb
   */
  public isCrumbLinkVisible(): boolean {
    // crashGroup is required to link to crash detail page
    if (this.crumbType === ApteligentCrumbType.CRASH) {
      const crashCrumb = this.crumb as GroupedCrashAndroidCrumb | GroupedCrashIosCrumb;
      return Boolean(crashCrumb.crashGroup);
    }

    return [
      ApteligentCrumbType.NET_ERROR,
      ApteligentCrumbType.NET_EVENT,
      ApteligentCrumbType.HANDLED_EXCEPTION,
      ApteligentCrumbType.PLUGIN_EXCEPTION,
    ].includes(this.crumbType);
  }

  /**
   * getCrumbLabelKey
   * @returns {string}
   * @memberof ApteligentCrumb
   */
  public getCrumbLabelKey(): string {
    switch (this.crumbType) {
      case ApteligentCrumbType.CUSTOM_EVENT:
        return (this.crumb as CustomEventCrumb).name;
      case ApteligentCrumbType.NET_ERROR:
        return (this.crumb as NetErrorCrumb).url;
      case ApteligentCrumbType.NET_EVENT:
        return (this.crumb as NetEventCrumb).url;
      case ApteligentCrumbType.HANDLED_EXCEPTION:
        return (this.crumb as HandledExceptionAndroidCrumb | HandledExceptionIosCrumb).errorName;
      case ApteligentCrumbType.PLUGIN_EXCEPTION:
        return (this.crumb as PluginExceptionCrumb).errorName;
      case ApteligentCrumbType.CRASH:
        return (this.crumb as CrashAndroidCrumb | CrashIosCrumb | GroupedCrashAndroidCrumb | GroupedCrashIosCrumb).getCrashCrumbLabelKey();
    }
    return '';
  }
}
