/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { WidgetDetailPageSkinType } from './widget-detail-page.interface';

/**
 * DashboardSkinTypeConfig
 * @export
 * @class DashboardSkinTypeConfig
 */
export class DashboardSkinTypeConfig {
  public static SKIN_TYPE_BY_CATEGORY_ID = {
    AIRWATCH_WINDOWSPATCH: WidgetDetailPageSkinType.CVE,
    APTELIGENT_USER_FLOW: WidgetDetailPageSkinType.USER_FLOWS,
  };
}
