/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * AccountPermission
 *
 * @export
 * @enum {number}
 */
export enum AccountPermission {
  NONE,
  READ,
  RESTRICTED_READ_WRITE,
  READ_MANAGE,
  READ_WRITE,
  READ_WRITE_MANAGE,
}
