/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

/* eslint-disable max-classes-per-file */
import {
  CustomConverter,
  deserialize,
  GenericObject,
  JsonProperty,
  PagedResponse,
  Serializable,
  serialize,
  TimeOfDay,
} from '@dpa/ui-common';
import moment from 'moment';

import { AppConstants } from './app.constants';
import { dateFormatConverter } from './converters/date-format.converter';
import { Frequency } from './frequency.enum';
import { GenericSearchRequest } from './generic-search-request.model';
import { UserDetails } from './user-details.model';

/**
 * Schedule Type.
 * @export
 * @enum {number}
 */
export enum ScheduleType {
  CRON,
  ADHOC,
}

/**
 * Enum that represents days of a week. We can't use the inbuilt DayOfWeek as it starts with Monday and not Sunday.
 * Please do not change the order of the enum as the value of SUN should be 1 and SAT should be 7.
 * @export
 * @enum {number}
 */
export enum DayOfWeek {
  SUN = 1,
  MON = 2,
  TUE = 3,
  WED = 4,
  THU = 5,
  FRI = 6,
  SAT = 7,
}

/**
 * Enum that represents the 12 months of the year
 * January, February, March, April, May, June, July, August, September, October,
 * November and December.
 * @export
 * @enum {number}
 */
export enum Month {
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

/**
 * Details of a hourly frequency.
 * @export
 * @class Hourly
 */
@Serializable
export class Hourly {
  @JsonProperty('interval')
  public interval: number = undefined;

  /**
   * Creates an instance of Hourly.
   * @param {any} args
   * @memberof Hourly
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * Details of a weekly frequency.
 * @export
 * @class Weekly
 */
@Serializable
export class Weekly {
  @JsonProperty('days_of_week')
  public daysOfWeek: string[] = undefined;

  /**
   * Creates an instance of Weekly.
   * @param {any} args
   * @memberof Weekly
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * Details of monthly frequency.
 * @export
 * @class Monthly
 */
@Serializable
export class Monthly {
  @JsonProperty('day_of_month')
  public dayOfMonth: number = undefined;

  /**
   * Creates an instance of Monthly.
   * @param {any} args
   * @memberof Monthly
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * Details of yearly frequency.
 * @export
 * @class Yearly
 */
@Serializable
export class Yearly {
  @JsonProperty('month')
  public month: number = undefined;

  @JsonProperty('day_of_month')
  public dayOfMonth: number = undefined;
}

/**
 * CronExpressionDetail
 * @export
 * @class CronExpressionDetail
 */
@Serializable
export class CronExpressionDetail {
  @JsonProperty('frequency')
  public frequency: string = undefined;

  @JsonProperty('hour')
  public hour?: number = undefined;

  @JsonProperty('minute')
  public minute?: number = undefined;

  @JsonProperty({ name: 'hourly', cls: Hourly })
  public hourly?: Hourly = undefined;

  @JsonProperty({ name: 'weekly', cls: Weekly })
  public weekly?: Weekly = undefined;

  @JsonProperty({ name: 'monthly', cls: Monthly })
  public monthly?: Monthly = undefined;

  @JsonProperty({ name: 'yearly', cls: Yearly })
  public yearly?: Yearly = undefined;

  /**
   * Creates an instance of CronExpressionDetail.
   * @param {any} args
   * @memberof CronExpressionDetail
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * CronExpressionData
 * The SchedulerComponent requires data to be in this form to render the UI.
 * The functions in cron-expression-util convert between this form & CronExpressionDetail from the server.
 */
export interface CronExpressionData {
  frequency: Frequency;
  startTimeOfDay: TimeOfDay;
  end: Date;
  requiredEnDate: boolean;
  weekly?: {
    sun: boolean;
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
  };
  hourly?: Hourly;
  monthly?: Monthly;
}

/**
 * cronExpressionDetailConverter
 * Convert cronExpression in UTC to browser locale
 */
export const cronExpressionDetailConverter: CustomConverter = {
  fromJson(cronData: GenericObject): CronExpressionDetail {
    if (!cronData) {
      return new CronExpressionDetail();
    }
    const cronExpression = deserialize(CronExpressionDetail, cronData);
    // Get minute of the day from cron expression
    const minutes = cronExpression.hour * AppConstants.SECONDS_PER_MINUTE + cronExpression.minute;
    // Apply offset
    const offsetMinutes = moment().utcOffset() + minutes;
    if (offsetMinutes >= 0 && offsetMinutes < AppConstants.MINUTES_PER_DAY) {
      return cronExpression;
    }
    // offsetNumber to increase or decrease the day
    const offsetNumber = offsetMinutes < 0 ? -1 : 1;
    switch (cronExpression.frequency) {
      case Frequency[Frequency.MONTHLY]:
        const lastDayOfMonth = Number(moment().endOf('month').format('D'));
        cronExpression.monthly.dayOfMonth += offsetNumber;
        if (cronExpression.monthly.dayOfMonth === 0) {
          cronExpression.monthly.dayOfMonth = lastDayOfMonth;
        }
        if (cronExpression.monthly.dayOfMonth > lastDayOfMonth) {
          cronExpression.monthly.dayOfMonth = 1;
        }
        break;
      case Frequency[Frequency.WEEKLY]:
        cronExpression.weekly.daysOfWeek = cronExpression.weekly.daysOfWeek.map((day: string) => {
          const dayOfWeek = DayOfWeek[day];
          let offsetDayOfWeek = dayOfWeek + offsetNumber;
          if (offsetDayOfWeek === 0) {
            offsetDayOfWeek = 7;
          }
          if (offsetDayOfWeek === 8) {
            offsetDayOfWeek = 1;
          }
          return DayOfWeek[offsetDayOfWeek];
        });
        break;
    }
    return cronExpression;
  },
  toJson(cronExpressionDetail: CronExpressionDetail): CronExpressionDetail {
    return serialize(cronExpressionDetail);
  },
};

/**
 * Scheduled Report Record.
 * @export
 * @class ScheduledReport
 */
@Serializable
export class ScheduledReport {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('report_id')
  public reportId: string = undefined;

  @JsonProperty('report_name')
  public reportName: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('schedule_type')
  public scheduleType: string = undefined;

  @JsonProperty('active')
  public active: boolean = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'start', customConverter: dateFormatConverter })
  public start: number = undefined;

  @JsonProperty({ name: 'end', customConverter: dateFormatConverter })
  public end?: number = undefined;

  @JsonProperty({ name: 'last_run', customConverter: dateFormatConverter })
  public lastRun: number = undefined;

  @JsonProperty({ name: 'cron_expression_detail', customConverter: cronExpressionDetailConverter })
  public cronExpressionDetail: CronExpressionDetail = undefined;

  /**
   * Creates an instance of ScheduledReport.
   * @param {any} args
   * @memberof ScheduledReport
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * ScheduledReportSearchRequest
 * @export
 * @class ScheduledReportSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class ScheduledReportSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'report_id', excludeToJson: true })
  public reportId?: string = undefined;

  /**
   * Creates an instance of ScheduledReportSearchRequest.
   * @param {any} args
   * @memberof ScheduledReportSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * ScheduledReportSearchResponse
 *
 * @export
 * @class ScheduledReportSearchResponse
 */
export class ScheduledReportSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: ScheduledReport })
  public results: ScheduledReport[] = [];

  /**
   * Creates an instance of ScheduledReportSearchResponse.
   * @param {any} args
   * @memberof ScheduledReportSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
