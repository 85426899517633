/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * LoginRiskDashboardType
 * @export
 * @enum {string}
 */
export enum LoginRiskDashboardType {
  // For Login Risk Dashboard
  LOGIN_RISK = 'LOGIN_RISK',
}

/**
 * LoginRiskWidgetSubtype
 * @export
 * @enum {string}
 */
export enum LoginRiskWidgetSubtype {
  // For Login Risk Dashboard
  SUCCESSFUL_LOGINS = 'SUCCESSFUL_LOGINS',
  FAILED_LOGINS = 'FAILED_LOGINS',
  HIGH_RISK_LOGINS = 'HIGH_RISK_LOGINS',
  LOGIN_RISK = 'LOGIN_RISK',
  LOGIN_RISK_SCORE_BY_USER = 'LOGIN_RISK_SCORE_BY_USER',
  USERS_WITH_HIGH_LOGIN_RISK = 'USERS_WITH_HIGH_LOGIN_RISK',
  FAILED_LOGINS_BY_USER = 'FAILED_LOGINS_BY_USER',
  SUCCESSFUL_LOGINS_BY_USER = 'SUCCESSFUL_LOGINS_BY_USER',
  LOGIN_ATTEMPTS_STATUS = 'LOGIN_ATTEMPTS_STATUS',
  FAILED_LOGIN_BY_ERRORS = 'FAILED_LOGIN_BY_ERRORS',
  CONNECTION_ATTEMPTS_BY_DEVICE_TYPE = 'CONNECTION_ATTEMPTS_BY_DEVICE_TYPE',
  LOGIN_BY_USER_AGENT = 'LOGIN_BY_USER_AGENT',
  LOGIN_BY_DOMAIN = 'LOGIN_BY_DOMAIN',
  LANGUAGE_PREFERENCE_FOR_LOGIN = 'LANGUAGE_PREFERENCE_FOR_LOGIN',
}
