/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * ScoreUnit
 *
 * @export
 * @enum {string}
 */
export enum ScoreUnit {
  PERCENTAGE = 'PERCENTAGE',
  COUNT = 'COUNT',
  DAYS = 'DAYS',
  FAHRENHEIT = 'FAHRENHEIT',
  MILLIS = 'MILLIS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  MBPS = 'MBPS',
  CELSIUS = 'CELSIUS',
}
