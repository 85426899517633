/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * SolutionSettingType
 * @export
 * @enum {string}
 */
export enum SolutionSettingType {
  SLA = 'SLA',
}
