/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * IntelOrg
 *
 * @export
 * @class IntelOrg
 */
@Serializable
export class IntelOrg {
  @JsonProperty('id')
  public userName: string = undefined;

  @JsonProperty('org_id')
  public id: string = undefined;

  @JsonProperty('org_name')
  public label: string = undefined;

  /**
   * Creates an instance of IntelOrg
   * @param {...Array<Partial<IntelOrg>>} args
   * @memberof IntelOrg
   */
  constructor(...args: Array<Partial<IntelOrg>>) {
    Object.assign(this, ...args);
  }
}
