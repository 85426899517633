/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Dashboard } from './dashboard.model';

/**
 * DashboardActionPermission
 *
 * @export
 * @class DashboardActionPermission
 */
export class DashboardActionPermission {
  public hasDashboardWritePerm: boolean = false;

  public hasDashboardManagePerm: boolean = false;

  /**
   * constructor
   * @param {Array<Partial<DashboardActionPermission>>} args
   * @memberof DashboardActionPermission
   */
  constructor(...args: Array<Partial<DashboardActionPermission>>) {
    Object.assign(this, ...args);
  }

  /**
   * isAddWidgetDisabled
   * Add widget is allowed if the user is the owner or has full access to shared dashboards
   * @param {Dashboard} dashboard
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isAddWidgetDisabled = (dashboard: Dashboard): boolean => {
    return !dashboard?.isOwnerOrHasFullAccess;
  };

  /**
   * isRenameDisabled
   * Rename is allowed to users only when they are owner or has full access to shared dashboards
   * @param {Dashboard} dashboard
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isRenameDisabled = (dashboard: Dashboard): boolean => {
    return !dashboard.isOwnerOrHasFullAccess;
  };

  /**
   * isShareDisabled
   * Share is always allowed to users having manage permission and allowed to users
   * with write permission when they are owner or have full access to shared dashboards
   * @param {Dashboard} dashboard
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isShareDisabled = (dashboard: Dashboard): boolean => {
    return !(this.hasDashboardManagePerm || (this.hasDashboardWritePerm && dashboard?.isOwnerOrHasFullAccess));
  };

  /**
   * isDuplicateDisabled
   * Duplicate is only allowed to users having write permission
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isDuplicateDisabled = (): boolean => {
    return !this.hasDashboardWritePerm;
  };

  /**
   * isTransferOwnershipDisabled
   * Transfer Ownership is only allowed to users having manage permission
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isTransferOwnershipDisabled = (): boolean => {
    return !this.hasDashboardManagePerm;
  };

  /**
   * isExportDisabled
   * Export is only allowed to users having write permission
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isExportDisabled = (): boolean => {
    return !this.hasDashboardWritePerm;
  };

  /**
   * isDeleteDisabled
   * Delete is always allowed to users having manage permission and allowed to users with
   * write permission when they are owner or have full access to shared dashboards
   * @param {Dashboard} dashboard
   * @returns {boolean}
   * @memberof DashboardActionPermission
   */
  public isDeleteDisabled = (dashboard: Dashboard): boolean => {
    return !(this.hasDashboardManagePerm || (this.hasDashboardWritePerm && dashboard?.isOwnerOrHasFullAccess));
  };
}
