/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * RuleType
 *
 * @export
 * @enum {string}
 */
export enum RuleType {
  RuleSet = 'RuleSet',
  Rule = 'Rule',
}
