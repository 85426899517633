/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * UserDescriptor
 * @exports
 * @class UserDescriptor
 */
@Serializable
export class UserDescriptor {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('directory_id')
  public directoryId: string = undefined;

  @JsonProperty('directory_type')
  public directoryType: string = undefined;

  @JsonProperty('directory_user_id')
  public directoryUserId: string = undefined;

  @JsonProperty('account_id')
  public accountId?: string = undefined;

  /**
   * Creates an instance of UserDescriptor
   * @param {Array<Partial<UserDescriptor>>} args
   * @memberof UserDescriptor
   */
  constructor(...args: Array<Partial<UserDescriptor>>) {
    Object.assign(this, ...args);
  }
}
