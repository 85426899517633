/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Subject } from 'rxjs';

import { IWS1BrokerConfig, WS1BrokerEvent } from './ws1-broker-config.types';

export const ws1BrokerConfig: IWS1BrokerConfig = {
  endpoint: '/brokergateway', // broker proxy APIs gateway path
  event$: new Subject<WS1BrokerEvent>(),
  integrationPath: 'settings/', // integration path of WS1C where broker UI card is integrated
};
