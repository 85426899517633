/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * PluginExceptionCrumb
 * @export
 * @class PluginExceptionCrumb
 */
@Serializable
export class PluginExceptionCrumb {
  @JsonProperty('apteligent.plugin_exception.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.plugin_exception._error_name')
  public errorName: string = undefined;

  @JsonProperty('apteligent.plugin_exception.error_reason')
  public errorReason: string = undefined;

  /**
   * Creates an instance of PluginExceptionCrumb.
   * @param {...Array<Partial<PluginExceptionCrumb>>} args
   * @memberof PluginExceptionCrumb
   */
  constructor(...args: Array<Partial<PluginExceptionCrumb>>) {
    Object.assign(this, ...args);
  }
}
