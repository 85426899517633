/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * IdentifiersRequest
 * @exports
 * @class IdentifiersRequest
 */
@Serializable
export class IdentifiersRequest {
  @JsonProperty('identifiers')
  public identifiers: string[] = undefined;

  /**
   * Creates an instance of IdentifiersRequest
   * @param {Array<Partial<IdentifiersRequest>>} args
   * @memberof IdentifiersRequest
   */
  constructor(...args: Array<Partial<IdentifiersRequest>>) {
    Object.assign(this, ...args);
  }
}
