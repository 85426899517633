/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * WidgetType
 * @export
 * @enum {string}
 */
export enum WidgetType {
  CUSTOM = 'CUSTOM',
  STANDARD = 'STANDARD',
}
