/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

/**
 * FacetCount
 * @export
 * @class FacetCount
 */
export class FacetCount {
  @JsonProperty('field_name')
  public fieldName: string = undefined;

  @JsonProperty('field_value')
  public fieldValue: string = undefined;

  @JsonProperty('count')
  public count: number = undefined;

  @JsonProperty({ name: 'sub_facet_counts', cls: FacetCount })
  public subFacetCounts?: FacetCount[] = undefined;

  /**
   * Creates an instance of FacetCount.
   * @param {Array<Partial<FacetCount>>} args
   * @memberof FacetCount
   */
  constructor(...args: Array<Partial<FacetCount>>) {
    Object.assign(this, ...args);
  }
}
