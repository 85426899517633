/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Automation Event Type
 *
 * @export
 * @enum {string}
 */
export enum AutomationEventType {
  RUN = 'RUN',
  ACTION = 'ACTION',
}
