/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { RawThresholdAttribute } from './raw-threshold-attribute.enum';

// attributes where higher values are better
export const REVERSED_THRESHOLD_ATTRIBUTES = [
  RawThresholdAttribute.BATTERY_HEALTH,
  RawThresholdAttribute.DOT11_SIGNAL_QUALITY,
  RawThresholdAttribute.WIFI_STRENGTH,
  RawThresholdAttribute.DOWNLOAD_SPEED,
  RawThresholdAttribute.UPLOAD_SPEED,
  RawThresholdAttribute.DAYS_BEFORE_CERTIFICATE_EXPIRATION,
];
