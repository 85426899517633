/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * FilterRuleConfig
 * @export
 * @class FilterRuleConfig
 */
export class FilterRuleConfig {
  public static readonly IPV4_PATTERN: RegExp = new RegExp(
    /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/,
  );
  public static readonly NUMBER_PATTERN: RegExp = new RegExp(/^[+-]?\d+(\.\d+)?$/);
}
