/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { WidgetDataset } from '@ws1c/intelligence-models/dashboard/widget-dataset.model';
import { DeemPlaybookStepItemBase } from './deem-playbook-step-item-base.model';

/**
 * DeemPlaybookStepItemWidget
 * @export
 * @class DeemPlaybookStepItemWidget
 * @extends DeemPlaybookStepItemBase
 */
@Serializable
export class DeemPlaybookStepItemWidget extends DeemPlaybookStepItemBase {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('use_incident_filter')
  public useIncidentFilter: boolean = undefined;

  @JsonProperty({ name: 'widget_datasets', cls: WidgetDataset })
  public widgetDatasets: WidgetDataset[] = [];

  /**
   * constructor
   * @param {Partial<DeemPlaybookStepItemLink>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStepItemWidget>>) {
    super();
    Object.assign(this, ...args);
  }
}
