/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { keys } from 'lodash-es';

import { MetaFormField } from '@ws1c/intelligence-models/meta-form/meta-form-field.model';

const dataConverter: CustomConverter = {
  fromJson: (obj: GenericObject): ReadonlyArray<Partial<MetaFormField>> => {
    return Object.keys(obj).map((key: string) => ({
      name: key,
      value: obj[key],
    }));
  },
  toJson: (arr: MetaFormField[]): GenericObject => {
    return arr.reduce((obj: any, field: any) => {
      obj[field.name] = field.value;
      return obj;
    }, {});
  },
};

const dataBySectionConverter: CustomConverter = {
  fromJson: (obj: GenericObject): GenericObject => {
    return keys(obj).map((sectionKey: string) => dataConverter.fromJson(obj[sectionKey]));
  },
  toJson: (obj: GenericObject): GenericObject => {
    const sectionKeys = keys(obj);
    // If there are no sections, dataBySection property should not be set
    if (!sectionKeys.length) {
      return undefined;
    }
    return sectionKeys.reduce((result: GenericObject, sectionKey: string): GenericObject => {
      result[sectionKey] = dataConverter.toJson(obj[sectionKey]);
      return result;
    }, {});
  },
};

/**
 * TestAction
 * @export
 * @class TestAction
 */
@Serializable
export class TestAction {
  @JsonProperty('action_type')
  public key: string = undefined;

  @JsonProperty({ name: 'data', customConverter: dataConverter })
  public data: any[] = [];

  @JsonProperty({ name: 'dataBySection', customConverter: dataBySectionConverter })
  public dataBySection: any = {};

  /**
   * Creates an instance of TestAction
   * @param {any} args
   * @memberof TestAction
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * ServiceAction
 * @export
 * @class ServiceAction
 */
@Serializable
export class ServiceAction {
  @JsonProperty('service_type')
  public serviceKey: string = undefined;

  @JsonProperty('service_action_subtype')
  public serviceActionSubtype: string = undefined;

  @JsonProperty({ name: 'action', cls: TestAction })
  public action: TestAction = undefined;

  /**
   * Creates an instance of ServiceAction
   * @param {any} args
   * @memberof ServiceAction
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * AutomationConnectionTest
 * @export
 * @class AutomationConnectionTest
 */
@Serializable
export class AutomationConnectionTest {
  @JsonProperty('service_id')
  public serviceId: string = undefined;

  @JsonProperty({ name: 'service_action', cls: ServiceAction })
  public serviceAction: ServiceAction = undefined;

  @JsonProperty('workflow_action_template_id')
  public actionTemplateId?: string = undefined;

  /**
   * Creates an instance of AutomationConnectionTest
   * @param {any} args
   * @memberof AutomationConnectionTest
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
