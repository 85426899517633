/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceAppsCrumb
 * @export
 * @class DeviceAppsCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceAppsCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_user)
  public user: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_application_name)
  public application: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_module)
  public module: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_code)
  public exceptionCode: string = '';

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_exception_offset)
  public exceptionOffset: string = '';

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_data)
  public data: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_apps_agent_version)
  public agent: string = undefined;

  /**
   * Creates an instance of DeviceAppsCrumb.
   * @param {...Array<Partial<DeviceAppsCrumb>>} args
   * @memberof DeviceAppsCrumb
   */
  constructor(...args: Array<Partial<DeviceAppsCrumb>>) {
    super();
    Object.assign(this, ...args);
  }

  /**
   * exception
   * @readonly
   * @type {string}
   * @memberof DeviceAppsCrumb
   */
  public get exception(): string {
    const exception: string[] = [];
    if (this.exceptionCode) {
      exception.push(this.exceptionCode);
    }
    if (this.exceptionOffset) {
      exception.push(this.exceptionOffset);
    }
    return exception.toString();
  }
}
