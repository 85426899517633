/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * InsightObservationValue
 * @export
 * @class InsightObservationValue
 */
@Serializable
export class InsightObservationValue {
  @JsonProperty('key')
  public key: string = undefined;

  @JsonProperty('value')
  public value: number = undefined;

  /**
   * Creates an instance of InsightObservationValue.
   * @param {Array<Partial<InsightObservationValue>>} args
   * @memberof InsightObservationValue
   */
  constructor(...args: Array<Partial<InsightObservationValue>>) {
    Object.assign(this, ...args);
  }
}
