/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * RatioIndicatorColors
 *
 * @export
 * @enum {string}
 */
export enum RatioIndicatorColors {
  SUCCESS = '#00d4b8',
  ERROR = '#f54f47',
  ACTIVE = '#61717d',
}
