/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * RequestStatusType
 * @export
 * @enum {string}
 */
export enum RequestStatusType {
  GRANTED = 'GRANTED',
  NONE = 'NONE',
  REQUESTED = 'REQUESTED',
}
