/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * Language
 * @export
 * @enum {string}
 */
export enum Language {
  PYTHON = 'python',
  POWERSHELL = 'powershell',
  BASH = 'bash',
  ZSH = 'zsh',
}
