/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

import { facetMapConverter } from '@ws1c/intelligence-models/converters';
import { FacetCount } from './facet-count.model';
import { MarketplaceSearchPagedResponse } from './marketplace-search-paged-response.model';

/**
 * MarketplaceSearchResponse
 * @export
 * @class MarketplaceSearchResponse
 */
export class MarketplaceSearchResponse {
  @JsonProperty({ name: 'search_results', cls: MarketplaceSearchPagedResponse })
  public searchResults: MarketplaceSearchPagedResponse = undefined;

  @JsonProperty({ name: 'facet_counts_by_facet_name', cls: FacetCount, customConverter: facetMapConverter })
  public facetCountsByType: Record<string, FacetCount[]> = undefined;

  /**
   * Creates an instance of MarketplaceSearchResponse.
   * @param {Array<Partial<MarketplaceSearchResponse>>} args
   * @memberof MarketplaceSearchResponse
   */
  constructor(...args: Array<Partial<MarketplaceSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
