/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ManagedConnector } from './managed-connector.model';

/**
 * AllManagedConnectorsResponse
 * @export
 * @class AllManagedConnectorsResponse
 */
@Serializable
export class AllManagedConnectorsResponse {
  @JsonProperty({ name: 'data', cls: ManagedConnector })
  public results: ManagedConnector[] = [];

  /**
   * Creates an instance of AllManagedConnectorsResponse.
   * @param {Array<Partial<AllManagedConnectorsResponse>>} args
   * @memberof AllManagedConnectorsResponse
   */
  constructor(...args: Array<Partial<AllManagedConnectorsResponse>>) {
    Object.assign(this, ...args);
  }
}
