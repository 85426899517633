/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * LazyFeatureStoreType
 *
 * @export
 * @enum {LazyFeatureStoreType}
 * @enum {string}
 */
export enum LazyFeatureStoreType {
  REPORT = 'REPORT',
}
