/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable, WebErrorMessage } from '@dpa/ui-common';
import { get } from 'lodash-es';

import { TrendDefinition } from './trend-definition.model';
import { TrendResult } from './trend-result.model';

/**
 * Widget information.
 *
 * @export
 * @class Trend
 */
@Serializable
export class Trend {
  @JsonProperty({ name: 'trend_definition', cls: TrendDefinition })
  public trendDefinition: TrendDefinition = undefined;

  @JsonProperty('precomputed_aggregation_id')
  public precomputedAggregationId?: string = undefined;

  @JsonProperty({ name: 'trend_results', cls: TrendResult, excludeToJson: true })
  public trendResults: TrendResult[] = undefined;

  @JsonProperty({ name: 'error_message', cls: WebErrorMessage, excludeToJson: true })
  public errorMessage: WebErrorMessage = undefined;

  /**
   * Creates an instance of Trend.
   * @param {Array<Partial<Trend>>} args
   * @memberof Trend
   */
  constructor(...args: Array<Partial<Trend>>) {
    Object.assign(this, ...args);
  }

  /**
   * hasTrendResults
   * @readonly
   * @type {boolean}
   * @memberof Trend
   */
  public get hasTrendResults(): boolean {
    return this.trendResults?.length > 0;
  }

  /**
   * hasTrendDefinition
   * @readonly
   * @type {boolean}
   * @memberof Trend
   */
  public get hasTrendDefinition(): boolean {
    return !!this.trendDefinition;
  }

  /**
   * getFirstCounterValue
   * @returns {number}
   * @memberof Trend
   */
  public getFirstCounterValue(): number {
    if (!get(this, ['trendResults', '0'])) {
      return 0;
    }
    return get(this, ['trendResults', '0']).getFirstCounterValue();
  }

  /**
   * setFirstCounterValue
   * @param {number} counterValue
   * @returns {any}
   * @memberof Trend
   */
  public setFirstCounterValue(counterValue: number) {
    if (!get(this, ['trendResults', '0'])) {
      return 0;
    }
    get(this, ['trendResults', '0']).setFirstCounterValue(counterValue);
  }
}
