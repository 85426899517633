/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * SystemLimitsFeatureTag
 * @export
 * @enum {string}
 */
export enum SystemLimitsFeatureTag {
  AUTOMATIONS = 'AUTOMATIONS',
  REPORTS = 'REPORTS',
  DASHBOARDS = 'DASHBOARDS',
}
