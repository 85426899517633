/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from './converters';
import { IntegrationReprovisionStatusType } from './integration/integration-reprovisioning-status-type.enum';
import { UserDetails } from './user-details.model';

/**
 * Integration Reprovision Status Model
 *
 * @export
 * @class IntegrationReprovisionStatus
 */
@Serializable
export class IntegrationReprovisionStatus {
  @JsonProperty('integration_id')
  public integrationId: string = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(IntegrationReprovisionStatusType), excludeToJson: true })
  public status: IntegrationReprovisionStatusType = undefined;

  @JsonProperty('message')
  public message: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * Creates an instance of IntegrationReprovisionStatus
   * @param {Array<Partial<IntegrationReprovisionStatus>>} args - Initialization arguments for IntegrationReprovisionStatus
   * @memberof IntegrationReprovisionStatus
   */
  constructor(...args: Array<Partial<IntegrationReprovisionStatus>>) {
    Object.assign(this, ...args);
  }
}
