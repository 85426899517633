/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';

/**
 * AppRegistration
 * @export
 * @class AppRegistration
 */
@Serializable
export class AppRegistration {
  @JsonProperty('package_id')
  public packageId: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('apteligent_app_id')
  public apteligentAppId: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('productivity_app')
  public isProductivityApp: boolean = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  /**
   * Creates an instance of AppRegistration.
   * @param {...Array<Partial<AppRegistration>>} args
   * @memberof AppRegistration
   */
  constructor(...args: Array<Partial<AppRegistration>>) {
    Object.assign(this, ...args);
  }
}
