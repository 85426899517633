/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { each } from 'lodash-es';

import { TrendWithStatus } from './incremental-loading-trend-with-status.model';
import { TrendPreviewResponse } from './trend-preview-response.model';
import { Trend } from './trend.model';

const trendByIdConverter: CustomConverter = {
  fromJson(data: GenericObject): Record<string, Trend> {
    const trendById = {};
    each(data, (value: GenericObject, key: string) => {
      trendById[key] = deserialize(TrendWithStatus, value);
    });
    return trendById;
  },
  toJson() {
    return undefined;
  },
};

/**
 * IncrementalLoadingTrendPreviewResponse
 * @export
 * @class IncrementalLoadingTrendPreviewResponse
 */
@Serializable
export class IncrementalLoadingTrendPreviewResponse {
  @JsonProperty({ name: 'data', customConverter: trendByIdConverter })
  public trendById: Record<string, TrendWithStatus> = {};

  /**
   * Creates an instance of IncrementalLoadingTrendPreviewResponse.
   * @param {Array<Partial<IncrementalLoadingTrendPreviewResponse>>} args
   * @memberof IncrementalLoadingTrendPreviewResponse
   */
  constructor(...args: Array<Partial<IncrementalLoadingTrendPreviewResponse>>) {
    Object.assign(this, ...args);
  }

  /**
   * getTrendPreviewResponse
   * @returns {TrendPreviewResponse}
   * @memberof IncrementalLoadingTrendPreviewResponse
   */
  public toTrendPreviewResponse(): TrendPreviewResponse {
    const trendPreviewResponse = new TrendPreviewResponse();
    Object.keys(this.trendById ?? {}).forEach((trendId: string) => {
      trendPreviewResponse.trendById[trendId] = this.trendById[trendId].trend;
    });
    return trendPreviewResponse;
  }

  /**
   * getTrend
   * @param {string} trendId
   * @returns {Trend}
   * @memberof IncrementalLoadingTrendPreviewResponse
   */
  public getTrend(trendId: string): Trend {
    return this.trendById[trendId]?.trend;
  }
}
