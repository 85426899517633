/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { isUndefined, pickBy } from 'lodash-es';

/**
 * SearchTerm
 * Represents the query value and the columns to search for them
 * @class SearchTerm
 */
@Serializable
export class SearchTerm {
  // Must be truthy or some endpoints will not work (POST /v1/user/airwatch)
  @JsonProperty('value')
  public value: string = undefined;

  @JsonProperty('fields')
  public fields?: string[] = undefined;

  /**
   * Creates an instance of SearchTerm.
   * @param {Array<Partial<SearchTerm>>} args
   * @memberof SearchTerm
   */
  constructor(...args: Array<Partial<SearchTerm>>) {
    Object.assign(this, ...args);
  }

  /**
   * Returns array of SearchTerm gor given list of { key: value }
   * @param {any} listFilter
   * @returns {SearchTerm[]}
   * @memberof SearchTerm
   */
  public static fromFilter(listFilter: any): SearchTerm[] {
    const create = (item) => {
      return new SearchTerm({
        value: listFilter[item],
        fields: item.split(','),
      });
    };
    const hasLength = (filter: string): boolean => {
      return filter.length > 0;
    };

    return Object.keys(pickBy(listFilter, (item: boolean | undefined) => !isUndefined(item)))
      .filter(hasLength)
      .map(create);
  }
}
