/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Action } from '@ngrx/store';

import { IncrementalLoadingTrendPreviewResponse } from './incremental-loading-trend-preview-response.model';
import { TrendDefinition } from './trend-definition.model';

/**
 * IncrementailLoadingWidgetId
 * @export
 * @enum {string}
 */
export enum IncrementailLoadingWidgetId {
  WIDGET_DETAIL_MAIN = 'widget_detail.main',
  WIDGET_DETAIL_OVERLAY = 'widget_detail.overlay',
  WIDGET_PREVIEW_MAIN = 'widget_preview.main',
  WIDGET_PREVIEW_OVERLAY = 'widget_preview.overlay',
}

export interface IncrementalLoadingWidgetDetails {
  trendDefinition?: TrendDefinition;
  onSuccess?: (trendPreviewPartialResponse: IncrementalLoadingTrendPreviewResponse) => Action;
}
