/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { isUndefined, map } from 'lodash-es';

import { BucketSelection, ChartDrilldownEvent, FocusedSeries } from '@ws1c/intelligence-models/dashboard/chart-drilldown-event.interface';
import { RollingWindow } from '@ws1c/intelligence-models/dashboard/rolling-window.model';
import { TrendDateRange } from '@ws1c/intelligence-models/dashboard/trend-date-range.model';
import { TrendDefinition } from '@ws1c/intelligence-models/dashboard/trend-definition.model';
import { FeatureOperator } from '@ws1c/intelligence-models/feature/feature-operator.enum';
import { FilterRule } from '@ws1c/intelligence-models/filter/filter-rule.model';
import { QueryBuilder } from '@ws1c/intelligence-models/filter/query-builder.model';
import { isV2Attribute } from '@ws1c/intelligence-models/utils/attributes-utils';

/**
 * TrendDefinitionDrilldownApplier
 * @export
 * @class TrendDefinitionDrilldownApplier
 */
export class TrendDefinitionDrilldownApplier {
  public readonly defaultGroupByCardinality: number = 500;

  /**
   * applyDrilldownEventsToTrendDefinition
   * @param {TrendDefinition} initialTrendDefinition
   * @param {ChartDrilldownEvent[]} drilldownEvents
   * @returns {TrendDefinition}
   * @memberof TrendDefinitionDrilldownApplier
   */
  public applyDrilldownEventsToTrendDefinition(
    initialTrendDefinition: TrendDefinition,
    drilldownEvents: ChartDrilldownEvent[],
  ): TrendDefinition {
    return drilldownEvents.reduce((trendDefinition: TrendDefinition, drilldownEvent: ChartDrilldownEvent) => {
      return this.applyDrilldownEventToTrendDefinition(trendDefinition, drilldownEvent);
    }, initialTrendDefinition);
  }

  /**
   * applyDrilldownEventToTrendDefinition
   * @param {TrendDefinition} initialTrendDefinition
   * @param {ChartDrilldownEvent} drilldownEvent
   * @returns {TrendDefinition}
   * @memberof TrendDefinitionDrilldownApplier
   */
  public applyDrilldownEventToTrendDefinition(
    initialTrendDefinition: TrendDefinition,
    drilldownEvent: ChartDrilldownEvent,
  ): TrendDefinition {
    const trendDefinition = Object.assign(new TrendDefinition(), initialTrendDefinition);

    trendDefinition.bucketingAttributes = this.getMinimumBucketingAttributes(
      trendDefinition.bucketingAttributes || [],
      drilldownEvent.selectedBuckets,
    );

    if (drilldownEvent.bucketAttributeChange) {
      const attributeIndex = drilldownEvent.bucketAttributeChange.bucketAttributeIndex;
      const nextAttributeName = drilldownEvent.bucketAttributeChange.nextAttributeName;
      trendDefinition.bucketingAttributes[attributeIndex] = nextAttributeName;
      if (isUndefined(nextAttributeName)) {
        trendDefinition.bucketingAttributes.splice(attributeIndex, 1);
      } else if (isV2Attribute(nextAttributeName)) {
        const joinEntitiesByIntegration = {};
        trendDefinition.bucketingAttributes.forEach((attribute: string) => {
          const [integration, entity]: string[] = attribute.split('.');
          joinEntitiesByIntegration[integration] = joinEntitiesByIntegration[integration]?.add(entity) ?? new Set([entity]);
        });
        Object.keys(joinEntitiesByIntegration).forEach((integration: string) => {
          joinEntitiesByIntegration[integration] = Array.from(joinEntitiesByIntegration[integration]);
        });
        trendDefinition.joinEntitiesByIntegration = joinEntitiesByIntegration;
        trendDefinition.cardinality = this.defaultGroupByCardinality;
      }
    }

    if (drilldownEvent.setFilters) {
      trendDefinition.filter = '';
      drilldownEvent.setFilters.forEach((addFilter: FilterRule) => {
        const ruleString = QueryBuilder.getRuleString(addFilter);
        trendDefinition.filter = trendDefinition.filter ? `${trendDefinition.filter} AND ${ruleString}` : ruleString;
      });
    }

    if (drilldownEvent.addFilters) {
      trendDefinition.filter = trendDefinition.filter ? `(${trendDefinition.filter})` : '';
      drilldownEvent.addFilters.forEach((addFilter: FilterRule) => {
        const ruleString = QueryBuilder.getRuleString(addFilter);
        trendDefinition.filter = [trendDefinition.filter, ruleString].filter(Boolean).join(' AND ');
      });
    }

    if (drilldownEvent.setRuleGroup) {
      trendDefinition.filter = QueryBuilder.buildQueryString(drilldownEvent.setRuleGroup, {});
    }

    // Removes duplicates if drilldownEvent contains same filters
    trendDefinition.filter = this.getShortestFilterString(trendDefinition.filter, drilldownEvent.selectedBuckets);

    // Converts TrendSpan based dateRange to start/end dateRange
    trendDefinition.dateRange = this.getDrilldownDateRange(
      trendDefinition.dateRange,
      drilldownEvent.startDateMillis,
      drilldownEvent.endDateMillis,
    );

    if (drilldownEvent.trendDateRangeOverride) {
      const rollingDateRangeOverride = Object.assign(new TrendDateRange(), {
        ...drilldownEvent.trendDateRangeOverride,
        samplingFrequency: undefined,
        rollingWindow: Object.assign(new RollingWindow(), {
          ...trendDefinition?.dateRange?.rollingWindow,
          rollingWindowInterval: drilldownEvent.trendDateRangeOverride.samplingFrequency,
        }),
      });
      trendDefinition.dateRange = trendDefinition?.dateRange?.rollingWindow
        ? rollingDateRangeOverride
        : drilldownEvent.trendDateRangeOverride;
    }
    return trendDefinition;
  }

  /**
   * getMinimumBucketingAttributes
   * @param {string[]} bucketingAttributes
   * @param {BucketSelection[]} selectedBuckets
   * @returns {string[]}
   * @memberof TrendDefinitionDrilldownApplier
   */
  public getMinimumBucketingAttributes(bucketingAttributes: string[], selectedBuckets: BucketSelection[]): string[] {
    const appendedFilterAttributeNameSet = new Set(
      map(selectedBuckets, (selection: BucketSelection) => {
        return selection.bucketName;
      }),
    );
    return bucketingAttributes.filter((bucketingAttribute: string) => {
      return !appendedFilterAttributeNameSet.has(bucketingAttribute);
    });
  }

  /**
   * getShortestFilterString
   * @param {string} filter
   * @param {BucketSelection[]} selectedBuckets
   * @returns {string}
   * @memberof TrendDefinitionDrilldownApplier
   */
  public getShortestFilterString(filter: string, selectedBuckets: BucketSelection[] = []): string {
    if (selectedBuckets.length === 0) {
      return filter;
    }
    const appendedFilters = map(selectedBuckets, (selection: BucketSelection) => {
      return QueryBuilder.queryStringFromKeyValue(
        { [selection.bucketName]: selection.selectedValue },
        { [selection.bucketName]: selection.bucketDataType },
      ).trim();
    });
    const tokenFilters = filter ? [`( ${filter.trim()} )`] : [];
    return [...tokenFilters, ...appendedFilters].join(` ${FeatureOperator.AND} `);
  }

  /**
   * getDrilldownDateRange
   * @param {TrendDateRange} dateRange
   * @param {number} startDateMillis
   * @param {number} endDateMillis
   * @returns {TrendDateRange}
   * @memberof TrendDefinitionDrilldownApplier
   */
  public getDrilldownDateRange(dateRange: TrendDateRange, startDateMillis: number, endDateMillis: number): TrendDateRange {
    if (!startDateMillis && !endDateMillis) {
      return dateRange;
    }
    const drilldownDateRange = Object.assign(new TrendDateRange(), {
      ...dateRange,
      trendSpan: undefined,
      startDateMillis,
      endDateMillis,
    });
    drilldownDateRange.useGeneratedSamplingFrequency();
    return drilldownDateRange;
  }

  /**
   * getDrilldownFocusedSeries
   * @param {ChartDrilldownEvent[]} activeDrilldownEvents
   * @returns {FocusedSeries}
   * @memberof TrendDefinitionDrilldownApplier
   */
  public getDrilldownFocusedSeries(activeDrilldownEvents: ChartDrilldownEvent[] = []): FocusedSeries {
    for (let i = activeDrilldownEvents.length - 1; i >= 0; i--) {
      const activeDrilldownEvent = activeDrilldownEvents[i];
      if (activeDrilldownEvent.setFocusedSeries) {
        return activeDrilldownEvent.setFocusedSeries;
      }

      // These cases invalidate older setFocusedSeries events
      if (activeDrilldownEvent.bucketAttributeChange || activeDrilldownEvent.selectedBuckets) {
        return;
      }
    }
  }
}
