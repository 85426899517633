/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty } from '@dpa/ui-common';

import { COLUMN_NAMES } from './integration-meta/column-names.constant';

/**
 * AutomationLog
 *
 * @export
 * @class AutomationLog
 */
export class AutomationLog {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_action_name)
  public actionName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_created_at)
  public createdAt: number = undefined;

  @JsonProperty('document_id')
  public documentId: string = undefined;

  @JsonProperty('entity_name')
  public entityName: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_service_name)
  public serviceName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_status)
  public status: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_target_id)
  public targetId: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_target_type)
  public targetType: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_target_count)
  public targetCount: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_details)
  public details: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_workflow_execution_context_id)
  public workflowExecutionContextId: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_start_time)
  public startTime: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_end_time)
  public endTime: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_rule_target_keys)
  public ruleTargetKeysString: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_workflow_execution_group_name)
  public groupName?: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_workflow_execution_switch_name)
  public conditionName?: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.internal_workflowexecution_workflow_execution_switch_branch_name)
  public conditionBranchName?: string = undefined;

  /**
   * Creates an instance of AutomationLog.
   * @param {Array<Partial<AutomationLog>>} args
   * @memberof AutomationLog
   */
  constructor(...args: Array<Partial<AutomationLog>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for ruleTargetKeys
   * @readonly
   * @type {GenericObject}
   * @memberof AutomationLog
   */
  public get ruleTargetKeys(): GenericObject {
    if (!this.ruleTargetKeysString) {
      return;
    }
    return JSON.parse(this.ruleTargetKeysString);
  }
}
