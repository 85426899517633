/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * TemplateGroupBy
 * @export
 * @enum {string}
 */
export enum TemplateGroupBy {
  CATEGORY = 'CATEGORY',
  INTEGRATION = 'INTEGRATION',
}
