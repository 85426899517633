/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * AdminRoleType
 * @export
 * @enum {string}
 */
export enum AdminRoleType {
  CUSTOM = 'CUSTOM',
  PRECANNED = 'PRECANNED',
}
