/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Column } from './column.model';

/**
 * AggregationColumn
 * @export
 * @class AggregationColumn
 * @extends {Column}
 */
@Serializable
export class AggregationColumn extends Column {
  @JsonProperty('aggregation_function')
  public aggregationFunction: string[] = undefined;

  /**
   * Creates an instance of AggregationColumn.
   * @param {Array<Partial<AggregationColumn>>} args
   * @memberof AggregationColumn
   */
  constructor(...args: Array<Partial<AggregationColumn>>) {
    super(...args);
    Object.assign(this, ...args);
  }
}
