/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { ResourceTemplate } from '@ws1c/intelligence-models/template/resource-template.model';

/**
 * ScriptTemplate
 * @export
 * @class ScriptTemplate
 * @extends ResourceTemplate
 */
@Serializable
export class ScriptTemplate extends ResourceTemplate {}
