/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Template type
 *
 * @export
 * @enum {string}
 */
export enum TEMPLATE_TYPE {
  AUTOMATIONS = 'automations',
  DASHBOARDS = 'dashboards',
  REPORTS = 'reports',
  SCRIPTS = 'scripts',
  SENSORS = 'sensors',
  WIDGETS = 'widgets',
}
