/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { DeemPlaybookStepItemBase } from './deem-playbook-step-item-base.model';

/**
 * DeemPlaybookStepItemRca
 * @export
 * @class DeemPlaybookStepItemRca
 * @extends DeemPlaybookStepItemBase
 */
@Serializable
export class DeemPlaybookStepItemRca extends DeemPlaybookStepItemBase {
  /**
   * constructor
   * @param {Partial<DeemPlaybookStepItemRca>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStepItemRca>>) {
    super();
    Object.assign(this, ...args);
  }
}
