/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * AutomationPreview Model Object
 *
 * @export
 * @class AutomationPreview
 */
@Serializable
export class AutomationPreview {
  @JsonProperty('friendly_name')
  public friendlyName: string = undefined;

  @JsonProperty({ name: 'last_seen', customConverter: dateFormatConverter })
  public lastSeen: number = undefined;

  @JsonProperty('device_location_group_name')
  public deviceLocationGroupName: string = undefined;

  @JsonProperty('compliance_status')
  public complianceStatus: string = undefined;
}

/**
 * AutomationPreviewRequest Model Object
 *
 * @export
 * @class AutomationPreviewRequest
 */
@Serializable
export class AutomationPreviewRequest extends GenericSearchRequest {
  /**
   * Creates an instance of AutomationPreviewRequest.
   * @param {any} args
   * @memberof AutomationPreviewRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * AutomationPreviewResponse Model Object
 *
 * @export
 * @class AutomationPreviewResponse
 */
export class AutomationPreviewResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: AutomationPreview })
  public results: AutomationPreview[] = [];

  /**
   * Creates an instance of AutomationPreviewResponse.
   * @param {any} args
   * @memberof AutomationPreviewResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
