/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppType
 *
 * @export
 * @enum {string}
 */
export enum AppType {
  INTERNAL = 'INTERNAL',
  PUBLIC = 'PUBLIC',
  WEB = 'WEB',
  PURCHASED = 'PURCHASED',
  UNMANAGED = 'UNMANAGED',
  UNKNOWN = 'UNKNOWN',
}
