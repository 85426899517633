/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Automation Action Lookup Response Data Model Object
 * Response format for Lookup action API
 *
 * @export
 * @class AutomationActionFieldLookupResponseData
 */
@Serializable
export class AutomationActionFieldLookupResponseData {
  @JsonProperty('text')
  public text: string = undefined;

  @JsonProperty('value')
  public value: string = undefined;

  /**
   * Creates an instance of AutomationActionFieldLookupResponseData.
   * @param {...Array<Partial<AutomationActionFieldLookupResponseData>>} args
   * @memberof AutomationActionFieldLookupResponseData
   */
  constructor(...args: Array<Partial<AutomationActionFieldLookupResponseData>>) {
    Object.assign(this, ...args);
  }
}
