/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { flatten, values } from 'lodash-es';

import { DeviceUser } from '@ws1c/intelligence-models/dashboard/device-user.model';
import { Integration } from '@ws1c/intelligence-models/integration.model';

/**
 * userIndexFlattener
 * @export
 * @type {CustomConverter}
 */
export const userIndexFlattener: CustomConverter = {
  fromJson(data: object): DeviceUser[] {
    const flattenedData = flatten(values(data));
    const users: DeviceUser[] = [];
    flattenedData.forEach((trend: GenericObject) => {
      const integration = Object.keys(trend.trend_definition.entities_by_integration)[0];
      if (integration === Integration.AIRWATCH) {
        trend.trend_results.forEach((result) => {
          const lastActive = result.counters[0].result.value;
          if (lastActive) {
            const user = new DeviceUser({
              id: result.bucketing_attributes[0].value,
              userName: result.bucketing_attributes[1].value,
              firstName: result.bucketing_attributes[2].value,
              lastName: result.bucketing_attributes[3].value,
              email: result.bucketing_attributes[4].value,
              integration,
              lastActive,
            });
            users.push(user);
          }
        });
      }
    });
    return users;
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * UserSearchResponse
 * @export
 * @class UserSearchResponse
 */
@Serializable
export class UserSearchResponse {
  @JsonProperty({ name: 'data', customConverter: userIndexFlattener })
  public data: DeviceUser[] = undefined;

  /**
   * Creates an instance of UserSearchResponse
   * @param {any} args
   * @memberof UserSearchResponse
   */
  constructor(...args: Array<Partial<UserSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
