/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ManagedConnector
 *
 * @export
 * @class ManagedConnector
 */
@Serializable
export class ManagedConnector {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('icon_link')
  public iconLink: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('config_required')
  public configRequired: boolean = undefined;

  @JsonProperty('setup_info_url')
  public setupInfoUrl?: boolean = undefined;

  @JsonProperty({ name: 'config_schema_id', excludeToJson: true })
  public configSchemaId: string = undefined;

  /**
   * Creates an instance of ManagedConnector.
   * @param {...Array<Partial<ManagedConnector>>} args
   * @memberof ManagedConnector
   */
  constructor(...args: Array<Partial<ManagedConnector>>) {
    Object.assign(this, ...args);
  }

  /**
   * iconBackgroundImage
   * @readonly
   * @type {string}
   * @memberof ManagedConnector
   */
  public get iconBackgroundImage(): string {
    return `url('${this.iconLink}')`;
  }
}
