/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * CarbonBlackDashboardTab
 * @export
 * @enum {string}
 */
export enum CarbonBlackDashboardTab {
  THREATS = 'threats',
  ENDPOINT_EVENTS = 'endpoint-events',
  WATCHLIST = 'watchlist',
}
