/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { DeemPlaybookStepItemType } from '@ws1c/intelligence-models/deem/deem-playbook-step-item-type.enum';

/**
 * DeemPlaybookStepItemBase
 * @export
 * @abstract
 * @class DeemPlaybookStepItemBase
 */
@Serializable
export abstract class DeemPlaybookStepItemBase {
  @JsonProperty({ name: 'type', customConverter: enumConverterFactory(DeemPlaybookStepItemType) })
  public type: DeemPlaybookStepItemType = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  /**
   * constructor
   * @param {Partial<DeemPlaybookStepItemBase>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStepItemBase>>) {
    Object.assign(this, ...args);
  }
}
