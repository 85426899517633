/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AppCrashGroup } from './app-crash-group.model';

/**
 * AppCrashGroupResponse
 * @export
 * @class AppCrashGroupResponse
 */
@Serializable
export class AppCrashGroupResponse {
  @JsonProperty({ name: 'data', cls: AppCrashGroup })
  public results: AppCrashGroup[] = undefined;

  /**
   * constructor
   * @param {Partial<AppCrashGroupResponse>[]} args
   * @memberof AppCrashGroupResponse
   */
  constructor(...args: Array<Partial<AppCrashGroupResponse>>) {
    Object.assign(this, ...args);
  }
}
