/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * MarketplaceResourceAggregateType
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceResourceAggregateType {
  DEPLOYED = 'deployed',
  SAVED = 'saved',
}
