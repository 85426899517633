/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { DateTimeFormat, JsonProperty, Serializable } from '@dpa/ui-common';
import { toString } from 'lodash-es';
import moment from 'moment';

import { DataType } from '@ws1c/intelligence-models/integration-meta';
import { MetaFormField } from '@ws1c/intelligence-models/meta-form/meta-form-field.model';

/**
 * BucketingAttribute Model Object
 * A bucketing field name and its value held.
 * <p>
 * Consider the following aggregation result.
 * In this example, two instances of {@link BucketingAttribute} will form a list of bucketing fields.
 * Under each date range bucket, such a list will form further bucketing space where aggregate results will be kept.
 * For example:
 * One of such Effective Bucket is from the first record shown in example. It is combination of:
 *  date_range_bucket: [2017-July-27:00:00:00 - 2017-July-27:23:59:59],
 *  bucketingfield: [name: _device_platform, value: android],
 *  bucketingfield: [name: os_version, value: 5.2.1]
 * In this bucket: avg(battery_level): 2.5 and avg(signal_strength): 2.8 will be kept.
 * <p>
 * The inputs (query parameters) to arrive at the results is abstracted in {@link TrendDefinition}
 * <p>
 * Each result from the example go into {@link TrendResult} which is a combination of
 * date range, bucketing attributes and counter definitions.
 * <p>
 * <pre>
 * date_range_bucket                             _device_platform  os_version       avg(battery_level)  avg(signal_strength)
 * --------------------------------------------- -------------    ---------------  ------------------  --------------------
 * 2017-July-27:00:00:00 - 2017-July-27:23:59:59 android          5.2.1            2.5                 2.8
 * 2017-July-27:00:00:00 - 2017-July-27:23:59:59 android          5.1.1            2.5                 2.8
 * </pre>
 *
 * @export
 * @class BucketingAttribute
 */
@Serializable
export class BucketingAttribute {
  @JsonProperty({ name: 'column_basic_view', cls: MetaFormField })
  public reportColumnView: MetaFormField = undefined;

  public value: any = undefined;

  /**
   * formattedValue
   * @readonly
   * @type {string}
   * @memberOf BucketingAttribute
   */
  public get formattedValue(): string {
    const dataType = DataType[this.reportColumnView.dataType];
    switch (dataType) {
      case DataType.DATETIME:
        return moment(this.value).format(DateTimeFormat.MOMENT_DATE_FORMAT);
      case DataType.BOOLEAN:
        // Boolean is returning 0,1 so need to convert to true/false value as string
        return this.value ? 'true' : 'false';
      default:
        return toString(this.value);
    }
  }

  /**
   * constructor
   * @param {Array<Partial<BucketingAttribute>>} args
   * @memberof BucketingAttribute
   */
  constructor(...args: Array<Partial<BucketingAttribute>>) {
    Object.assign(this, ...args);
  }
}
