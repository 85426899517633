/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { OptInFeatures } from './opt-in-features.model';
import { TrialBanner } from './trial-banner.model';

export interface FeatureControls {
  [key: string]: boolean;
}

/**
 * Org
 *
 * @export
 * @class Org
 */
@Serializable
export class Org {
  @JsonProperty('org_id')
  public orgId: string = undefined;

  @JsonProperty('org_name')
  public orgName: string = undefined;

  @JsonProperty('active_skus')
  public activeSkus: string[] = undefined;

  @JsonProperty({ name: 'brownfield_cutoff_date', customConverter: dateFormatConverter })
  public brownfieldCutoffDate: number = undefined;

  @JsonProperty({ name: 'feature_controls', customConverter: defaultValueConverterFactory() })
  public featureControls: FeatureControls = undefined;

  @JsonProperty({ name: 'trial_banner', cls: TrialBanner })
  public trialBanner: TrialBanner = undefined;

  @JsonProperty({ name: 'integrations_details', customConverter: defaultValueConverterFactory() })
  public integrationsDetails: GenericObject = undefined;

  @JsonProperty('eula_accepted')
  public eulaAccepted: boolean = undefined;

  @JsonProperty('csp_linked_org')
  public cspLinkedOrg: boolean = undefined;

  @JsonProperty('show_questionnaire')
  public isTrialQuestionnaireEnabled: boolean = undefined;

  @JsonProperty('brownfield_ws1c_customer')
  public isBrownfieldWorkspaceOneCloudCustomer: boolean = undefined;

  @JsonProperty('csp_base_url')
  public cspBaseUrl: string = undefined;

  @JsonProperty('csp_user_groups_add_link')
  public cspUserGroupsAddUrl: string = undefined;

  @JsonProperty('additional_skus')
  public additionalSkus: string[] = undefined;

  @JsonProperty({ name: 'opt_in_features', cls: OptInFeatures })
  public optInFeatures: OptInFeatures = undefined;

  @JsonProperty('premium_license_present')
  public hasPremiumLicense: boolean = undefined;

  // stores org name to display to handle CSP vs UEM login case
  public displayName: string = undefined;

  // Extra fields to store info from UEM Console
  // These values are from Cookies.
  // I know this is very messsy, don't ask why!
  // If you want to know, check with @Pallavi :-)
  public externalOrgName: string = undefined;
  public externalOrgUrl: string = undefined;

  /**
   * Creates an instance of Org
   * @param {...Array<Partial<Org>>} args
   * @memberof Org
   */
  constructor(...args: Array<Partial<Org>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for id
   * @readonly
   * @type {string}
   * @memberof Org
   */
  public get id(): string {
    return this.orgId;
  }

  /**
   * Getter for label
   * @readonly
   * @type {string}
   * @memberof Org
   */
  public get label(): string {
    return this.displayName;
  }
}
