/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemPlaybookState
 * @export
 * @enum {string}
 */
export enum DeemPlaybookState {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}
