/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, serialize } from '@dpa/ui-common';
import { map } from 'lodash-es';

import { hasSections } from '@ws1c/intelligence-models/helpers';
import { MetaFormField } from './meta-form-field.model';
import { MetaFormSection } from './meta-form-section.model';
import { MetaForm } from './meta-form.model';

const metaFormFieldsConverter = (fields: MetaFormField[], extra: Partial<MetaFormField> = { useTemplate: false }) =>
  map(fields, (field) =>
    deserialize(MetaFormField, {
      ...field,
      ...extra,
    }),
  );

/**
 * metaFormConverter
 * Convert JSON payload_metadata to/from appropriate underlying format
 * @export
 */
export const metaFormConverter: CustomConverter = {
  fromJson(data: any): MetaForm {
    if (hasSections(data)) {
      return new MetaForm({
        sections: map(
          data.sections,
          (section: MetaFormSection): MetaFormSection =>
            new MetaFormSection({
              fields: metaFormFieldsConverter(section.fields),
              label: section.label,
              type: section.type,
            }),
        ),
        fields: metaFormFieldsConverter(data.fields || []),
      });
    }

    return new MetaForm({
      fields: metaFormFieldsConverter(data.fields || []),
    });
  },
  toJson(data: MetaForm): GenericObject {
    if (hasSections(data)) {
      return {
        sections: map(data.sections, serialize),
        fields: map(data.fields, serialize),
      };
    }
    return {
      fields: map(data.fields, serialize),
    };
  },
};
