/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * AppCrashGroupRequest
 * @export
 * @class AppCrashGroupRequest
 */
@Serializable
export class AppCrashGroupRequest extends GenericSearchRequest {
  @JsonProperty('end_date_millis')
  public endDateMillis: number = undefined;

  @JsonProperty('start_date_millis')
  public startDateMillis: number = undefined;

  @JsonProperty({ name: 'entities_by_integration', customConverter: defaultValueConverterFactory() })
  public entitiesByIntegration: any = undefined;

  /**
   * constructor
   * @param {Array<Partial<AppCrashGroupRequest>>} args
   * @memberof AppCrashGroupRequest
   */
  constructor(...args: Array<Partial<AppCrashGroupRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
