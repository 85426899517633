/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeviceCrumbType
 * @export
 * @enum {string}
 */
export enum DeviceCrumbType {
  APPS = 'APPS',
  DEVICES = 'DEVICES',
  DEVICE_PERFORMANCE = 'DEVICE_PERFORMANCE',
  DISPLAY = 'DISPLAY',
  NET_EVENT = 'NET_EVENT',
  OS_UPDATES = 'OS_UPDATES',
  SERVICES = 'SERVICES',
  USER_ACTIONS = 'USER_ACTIONS',
}
