/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { WidgetType } from './widget-type.enum';

/**
 * WidgetDatasetPreferenceRequest
 * @export
 * @class WidgetDatasetPreferenceRequest
 */
@Serializable
export class WidgetDatasetPreferenceRequest {
  @JsonProperty('data_projection_fields')
  public columns: string[] = [];

  @JsonProperty('widget_dataset_id')
  public widgetDatasetId: string = undefined;

  @JsonProperty({ name: 'widget_type', customConverter: enumConverterFactory(WidgetType) })
  public widgetType: WidgetType = WidgetType.CUSTOM;

  /**
   * Creates an instance of WidgetDatasetPreferenceRequest.
   * @param {...Array<Partial<WidgetDatasetPreferenceRequest>>} args
   * @memberof WidgetDatasetPreferenceRequest
   */
  constructor(...args: Array<Partial<WidgetDatasetPreferenceRequest>>) {
    Object.assign(this, ...args);
  }
}
