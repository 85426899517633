/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AccountUsageDetails
 * @export
 * @class AccountUsageDetails
 */
@Serializable
export class AccountUsageDetails {
  @JsonProperty('account_id')
  public accountId: string = undefined;

  @JsonProperty('resource_count')
  public resourceCount: number = undefined;

  /**
   * Creates an instance of AccountUsageDetails.
   * @param {Array<Partial<AccountUsageDetails>>} args
   * @memberof AccountUsageDetails
   */
  constructor(...args: Array<Partial<AccountUsageDetails>>) {
    Object.assign(this, ...args);
  }
}
