/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * AccountFeature
 *
 * @export
 * @enum {number}
 */
export enum AccountFeature {
  DASHBOARDS,
  AUTOMATIONS,
  REPORTS,
  INTEGRATIONS,
  SETTINGS,
}
