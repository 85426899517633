/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Category } from './category.model';

/**
 * CategoryList
 *
 * @export
 * @class CategoryList
 */
@Serializable
export class CategoryList {
  @JsonProperty({ name: 'data', cls: Category })
  public data: Category[] = [];
}

/**
 * CategoryIndex
 * @export
 */
export interface CategoryIndex {
  [key: string]: Category;
}
