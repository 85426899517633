/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Bookmark Category
 *
 * @export
 * @enum {BookmarkCategory}
 */
export enum BookmarkCategory {
  APP = 'APP',
  AUTOMATION = 'AUTOMATION',
  DASHBOARD = 'DASHBOARD',
  DASHBOARD_WIDGET = 'DASHBOARD_WIDGET',
  INSIGHT = 'INSIGHT',
  REPORT = 'REPORT',
}
