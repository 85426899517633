/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemSolutionRelatedServices
 * @export
 * @enum {string}
 */
export enum DeemSolutionRelatedServices {
  INTELLIGENT_HUB = 'MARKETPLACE.DEEM.INTELLIGENT_HUB',
  WORKSPACE_ONE_CONTENT_ANDORID = 'MARKETPLACE.DEEM.WORKSPACE_ONE_CONTENT_ANDORID',
  WORKSPACE_ONE_CONTENT_IOS = 'MARKETPLACE.DEEM.WORKSPACE_ONE_CONTENT_IOS',
  WORKSPACE_ONE_ASSIST = 'INTEGRATIONS.ASSIST.TITLE',
  ITSM_CONNECTOR = 'MARKETPLACE.DEEM.ITSM_CONNECTOR',
}
