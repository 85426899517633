/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AutomationActionFieldLookupResponseData } from './automation-action-field-lookup-response-data.model';

/**
 * Automation Action Field Lookup Response Model Object
 * Response format for Lookup action field API
 *
 * @export
 * @class AutomationActionFieldLookupResponse
 */
@Serializable
export class AutomationActionFieldLookupResponse {
  @JsonProperty({ name: 'data', cls: AutomationActionFieldLookupResponseData })
  public data: AutomationActionFieldLookupResponseData[] = [];

  /**
   * Creates an instance of AutomationActionFieldLookupResponse.
   * @param {Array<Partial<AutomationActionFieldLookupResponse>>} args
   * @memberof AutomationActionFieldLookupResponse
   */
  constructor(...args: Array<Partial<AutomationActionFieldLookupResponse>>) {
    Object.assign(this, ...args);
  }
}
