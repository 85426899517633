/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Model for a single link
 * @export
 * @class WS1BrokerLink
 */
@Serializable
export class WS1BrokerLink {
  @JsonProperty('href')
  public href: string = undefined;

  /**
   * constructor
   * @param {Array<Partial<WS1BrokerLink>>} args
   * @memberof WS1BrokerLink
   */
  constructor(...args: Array<Partial<WS1BrokerLink>>) {
    Object.assign(this, ...args);
  }
}
