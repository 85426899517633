/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * OptInFeatures
 *
 * @export
 * @class OptInFeatures
 */
@Serializable
export class OptInFeatures {
  @JsonProperty('deem_enabled')
  public deemEnabled: boolean = undefined;

  @JsonProperty('data_segmentation_enabled')
  public dataSegmentationEnabled: boolean = undefined;

  @JsonProperty('mobile_telemetry_enabled')
  public mobileTelemetryEnabled: boolean = undefined;

  /**
   * Creates an instance of OptInFeatures.
   * @param {...Array<Partial<OptInFeatures>>} args
   * @memberof OptInFeatures
   */
  constructor(...args: Array<Partial<OptInFeatures>>) {
    Object.assign(this, ...args);
  }
}
