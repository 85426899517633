/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from './converters/date-format.converter';
import { UserDetails } from './user-details.model';

/**
 * IntegrationRegistration.
 * @export
 * @class IntegrationRegistration
 */
@Serializable
export class IntegrationRegistration {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('org_id')
  public orgId: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('active')
  public status: boolean = false;

  @JsonProperty('tenant_display_name')
  public tenantDisplayName: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * Creates an instance of IntegrationRegistration.
   * @param {any} args
   * @memberof IntegrationRegistration
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
