/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserDetails } from '@ws1c/intelligence-models/user-details.model';

/**
 * Integration Object
 * @export
 * @class IntegrationObject
 */
@Serializable
export class IntegrationObject {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public author: UserDetails = undefined;

  /**
   * Creates an instance of IntegrationObject
   * @param {Array<Partial<IntegrationObject>>} args
   * @memberof IntegrationObject
   */
  constructor(...args: Array<Partial<IntegrationObject>>) {
    Object.assign(this, ...args);
  }
}
