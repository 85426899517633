/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ResourceDeployResponse - Response model for script and sensors deployment.
 * @export
 * @class ResourceDeployResponse
 */
@Serializable
export class ResourceDeployResponse {
  @JsonProperty('resource_edit_url')
  public resourceEditUrl: string = undefined;

  /**
   * Creates an instance of ResourceDeployResponse
   * @param {Array<Partial<ResourceDeployResponse>>} args
   * @memberof ResourceDeployResponse
   */
  constructor(...args: Array<Partial<ResourceDeployResponse>>) {
    Object.assign(this, ...args);
  }
}
