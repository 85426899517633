/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * CreateCustomRoleRequest
 * @export
 * @class CreateRoleCustomRequest
 */
@Serializable
export class CreateCustomRoleRequest {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description_text')
  public description: string = undefined;

  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('scope_set_ids')
  public scopeSetIds: Record<string, boolean> = undefined;

  /**
   * Initializes instance of CreateCustomRoleRequest
   * @param {Array<Partial<CreateCustomRoleRequest>>} args - object initialization arguments
   * @memberof CreateCustomRoleRequest
   */
  constructor(...args: Array<Partial<CreateCustomRoleRequest>>) {
    Object.assign(this, ...args);
  }
}
