/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { CrashCrumb } from './crash-crumb.model';

/**
 * CrashAndroidCrumb
 * @export
 * @class CrashAndroidCrumb
 */
@Serializable
export class CrashAndroidCrumb extends CrashCrumb {
  @JsonProperty('apteligent.crash_android.name')
  public name: string = undefined;

  @JsonProperty('apteligent.crash_android.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.crash_android.error_reason')
  public errorReason: string = undefined;

  @JsonProperty('apteligent.crash_android.crash_group')
  public crashGroup: string = undefined;

  /**
   * Creates an instance of CrashAndroidCrumb.
   * @param {...Array<Partial<CrashAndroidCrumb>>} args
   * @memberof CrashAndroidCrumb
   */
  constructor(...args: Array<Partial<CrashAndroidCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
