/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * SolutionSettingMetricType
 * @export
 * @enum {string}
 */
export enum SolutionSettingMetricType {
  DEEM_EXPERIENCE_SCORE = 'DEEM_EXPERIENCE_SCORE',
  CVE_SLA = 'CVE_SLA',
}
