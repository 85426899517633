/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { DateTimeFormat, Deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import moment from 'moment';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { FeatureSetupType } from '@ws1c/intelligence-models/integrated-services/integrated-service.enum';
import { TrialDurationColors } from './trial-duration-color.constant';
import { TrialStatus } from './trial-status.enum';

/**
 * Trial Detail Model
 *
 * @export
 * @class TrialDetail
 */
@Serializable
export class TrialDetail {
  @JsonProperty('allow_extension')
  public allowExtension: boolean = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'end_date', customConverter: dateFormatConverter, excludeToJson: true })
  public endDate: number = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(TrialStatus), excludeToJson: true })
  public status: TrialStatus = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('integrated_service_type')
  public integratedServiceType: string = undefined;

  public duration: number;

  public durationColor: string;

  public durationLeft: number;

  public expireDate: string;

  public isActive: boolean;

  public isEntitled: boolean;

  public isExpired: boolean;

  public isFailed: boolean;

  public isRequested: boolean;

  public startDate: string;

  public url: string = undefined;

  public urlType: FeatureSetupType = undefined;

  /**
   * Creates an instance of TrialDetail
   * @param {Array<Partial<TrialDetail>>} args - Initialization arguments for object
   * @memberof TrialDetail
   */
  constructor(...args: Array<Partial<TrialDetail>>) {
    Object.assign(this, ...args);
  }

  /**
   * updateProperties
   * @memberof TrialDetail
   */
  @Deserialize.after()
  public updateProperties() {
    this.duration = moment(this.endDate).diff(moment(this.createdAt), 'days');
    this.durationLeft = moment(this.endDate).diff(Date.now(), 'days');
    this.isActive = this.status === TrialStatus.ACTIVE;
    this.isExpired = this.status === TrialStatus.EXPIRED;
    this.isFailed = this.status === TrialStatus.FAILED;
    this.isRequested = this.status === TrialStatus.REQUESTED;
    this.startDate = moment(this.createdAt).format(DateTimeFormat.MOMENT_FULL_DATE_FORMAT);
    this.expireDate = moment(this.endDate).format(DateTimeFormat.MOMENT_FULL_DATE_FORMAT);
    this.durationColor = this.getTrialDurationColor();
  }

  /**
   * getTrialDurationColor
   * @returns {string}
   * @memberof TrialDetail
   */
  private getTrialDurationColor(): string {
    if (this.isExpired) {
      return TrialDurationColors.EXPIRED;
    }
    if (!this.durationLeft || !this.duration) {
      return TrialDurationColors.PERCENT_LEFT_0_TO_25;
    }
    const durationLeftPercent = this.durationLeft / this.duration;
    if (durationLeftPercent >= 0.75) {
      return TrialDurationColors.PERCENT_LEFT_MORE_THAN_75;
    }
    if (durationLeftPercent >= 0.5) {
      return TrialDurationColors.PERCENT_LEFT_50_TO_75;
    }
    if (durationLeftPercent >= 0.25) {
      return TrialDurationColors.PERCENT_LEFT_25_TO_50;
    }
    return TrialDurationColors.PERCENT_LEFT_0_TO_25;
  }
}
