/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntegrationReprovisionStatusType
 * @export
 * @enum {string}
 */
export enum IntegrationReprovisionStatusType {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NONE = 'NONE',
  QUEUED = 'QUEUED',
  SUCCEEDED = 'SUCCEEDED',
}
