/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { some } from 'lodash-es';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { MetaFormFieldPresentationType } from '@ws1c/intelligence-models/meta-form/meta-form-field-presentation-type.enum';
import { JsonSchemaMetadata } from './json-schema-metadata.model';
import { JsonSchemaNode } from './json-schema-node.interface';

/**
 * ConnectorActionSection
 * @export
 * @class ConnectorActionSection
 */
@Serializable
export class ConnectorActionSection {
  @JsonProperty('section_type')
  public sectionType: string = undefined;

  @JsonProperty({ name: 'section_schema', customConverter: defaultValueConverterFactory() })
  public schema: JsonSchemaNode = undefined;

  @JsonProperty({ name: 'metadata', cls: JsonSchemaMetadata })
  public metadata: JsonSchemaMetadata[] = undefined;

  /**
   * Creates an instance of ConnectorActionSection.
   * @param {...Array<Partial<ConnectorActionSection>>} args
   * @memberof ConnectorActionSection
   */
  constructor(...args: Array<Partial<ConnectorActionSection>>) {
    Object.assign(this, ...args);
  }

  /**
   * isVisible - checks that there is a visible section field
   *
   * @returns {boolean}
   */
  public get isVisible(): boolean {
    return some(this.metadata, (metadata: JsonSchemaMetadata) => metadata.presentationType !== MetaFormFieldPresentationType.HIDDEN);
  }
}
