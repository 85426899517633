/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SuggestionFilterBy } from './suggestion-filter-by.model';

/**
 * SuggestionCriteria
 * @export
 * @class SuggestionCriteria
 */
@Serializable
export class SuggestionCriteria {
  @JsonProperty({ name: 'filters', cls: SuggestionFilterBy })
  public filterBys: SuggestionFilterBy[];

  @JsonProperty('search_field_query_attribute')
  public columnName: string;

  @JsonProperty('prefix_search_term')
  public query: string;

  @JsonProperty('group_field_query_attribute')
  public groupFieldName: string;

  @JsonProperty('size')
  public size: number;

  /**
   * Creates an instance of SuggestionCriteria.
   * @param {Array<Partial<SuggestionCriteria>>} args
   * @memberof SuggestionCriteria
   */
  constructor(...args: Array<Partial<SuggestionCriteria>>) {
    Object.assign(this, ...args);
  }
}
