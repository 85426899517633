/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserDescriptor } from './user-descriptor.model';

/**
 * AccountRoleUpdateWrapper
 * @export
 * @class AccountRoleUpdateWrapper
 */
@Serializable
export class AccountRoleUpdateWrapper {
  @JsonProperty({ name: 'user_descriptor', cls: UserDescriptor })
  public userDescriptor: UserDescriptor = undefined;

  @JsonProperty('role_ids')
  public roleIds: string[] = undefined;

  /**
   * Initializes instance of AccountRoleUpdateWrapper
   * @param {Array<Partial<AccountRoleUpdateWrapper>>} args - object initialization arguments
   * @memberof AccountRoleUpdateWrapper
   */
  constructor(...args: Array<Partial<AccountRoleUpdateWrapper>>) {
    Object.assign(this, ...args);
  }
}
