/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { TouchDetails } from './touch-details.model';
import { UserDetails } from './user-details.model';

/**
 * UserTouchDetails
 * @export
 * @class UserTouchDetails
 * @extends {TouchDetails}
 */
@Serializable
export class UserTouchDetails extends TouchDetails {
  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * constructor
   * @param {Array<Partial<UserTouchDetails>>} args
   * @memberof UserTouchDetails
   */
  constructor(...args: Array<Partial<UserTouchDetails>>) {
    super();
    Object.assign(this, ...args);
  }

  /**
   * getUserTouchDetails
   * @returns {UserTouchDetails}
   * @memberof UserTouchDetails
   */
  public getUserTouchDetails(): UserTouchDetails {
    return new UserTouchDetails({
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      createdByDetails: this.createdByDetails,
      modifiedAt: this.modifiedAt,
      modifiedBy: this.modifiedBy,
      modifiedByDetails: this.modifiedByDetails,
    });
  }
}
