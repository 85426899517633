/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';
import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta';
import { SearchTerm } from '@ws1c/intelligence-models/search-term.model';

/**
 * getModifiedSearchRequest
 * @export
 * @param {GenericSearchRequest} request
 * @param {GenericObject} filter
 * @param {string} type
 * @returns {GenericSearchRequest}
 */
export function getModifiedSearchRequest(request: GenericSearchRequest, filter: GenericObject, type?: string): GenericSearchRequest {
  const requestSearchTerms = request.searchTerms ? request.searchTerms : [];
  const searchTypeTerms = type ? [new SearchTerm({ value: type, fields: [COLUMN_NAMES.byName.dashboard_type] })] : [];
  const searchTerms = [...requestSearchTerms, ...SearchTerm.fromFilter(filter), ...searchTypeTerms];
  return new GenericSearchRequest({
    ...request,
    searchTerms: searchTerms.length ? searchTerms : undefined,
  });
}

/**
 * SEARCH_QUERY_FIELDS
 * fields to be queried when the user enter a search string as filter
 * @export
 * @constant SEARCH_QUERY_FIELDS
 */
export const SEARCH_QUERY_FIELDS = [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.description];

/**
 * SEARCH_QUERY_FIELDS_FOR_SYNTHETIC_URL_MONITORING
 * fields to be queried when the user enter a search string as filter
 * @export
 * @constant SEARCH_QUERY_FIELDS_FOR_SYNTHETIC_URL_MONITORING
 */
export const SEARCH_QUERY_FIELDS_FOR_SYNTHETIC_URL_MONITORING = [COLUMN_NAMES.byName.name, COLUMN_NAMES.byName.address];
