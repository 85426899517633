/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { WidgetColorSchema } from './widget-color-schema.model';
import { WidgetRangeFilter } from './widget-range-filter.model';

/**
 * WidgetPreference
 * @export
 * @class WidgetPreference
 */
@Serializable
export class WidgetPreference {
  @JsonProperty('attribute_name')
  public attributeName: string = undefined;

  @JsonProperty({ name: 'color_schemas', cls: WidgetColorSchema })
  public colorSchemas: WidgetColorSchema[] = undefined;

  @JsonProperty({ name: 'aggregation_range_preference', cls: WidgetRangeFilter })
  public rangeFilter: WidgetRangeFilter = undefined;

  /**
   * Creates an instance of WidgetPreference.
   * @param {...Array<Partial<WidgetPreference>>} args
   * @memberof WidgetPreference
   */
  constructor(...args: Array<Partial<WidgetPreference>>) {
    Object.assign(this, ...args);
  }
}

/**
 * WidgetPreferences
 * @export
 * @class WidgetPreferences
 */
@Serializable
export class WidgetPreferences {
  @JsonProperty('data_projection_fields')
  public dataProjectionFields: string[] = undefined;

  @JsonProperty({ name: 'widget_attribute_preferences', cls: WidgetPreference })
  public widgetAttributePreferences: WidgetPreference[] = undefined;

  /**
   * Creates an instance of WidgetPreferences.
   * @param {...Array<Partial<WidgetPreference>>} args
   * @memberof WidgetPreferences
   */
  constructor(...args: Array<Partial<WidgetPreferences>>) {
    Object.assign(this, ...args);
  }
}
