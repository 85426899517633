/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

/**
 * Control State For All Wizard Dialogs
 *
 * @export
 * @enum {number}
 */
export enum WizardDialogMode {
  ADD,
  EDIT,
  CLOSE,
  CANCEL,
  CREATE_FROM_OTHER_SOURCE,
}

/**
 * all filter tags for settings page
 *
 * @export
 * @enum {number}
 */
export enum SettingsFilterTag {
  AUTOMATION,
  EMPLOYEE_EXPERIENCE,
  INTEGRATION,
  TRUST_NETWORK,
  CUSTOM,
}

/**
 * list of media matcher breakpoints to be used with MediaMatcher(@angular/cdk/layout)
 *
 * @export
 * @enum {string}
 */
export enum MEDIA_MATCHER_BREAKPOINTS {
  XS = '(max-width: 576px)',
  SM = '(max-width: 768px)',
  MD = '(max-width: 992px)',
  LG = '(max-width: 1200px)',
}
