/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * MarketplaceResourceLabelType
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceResourceLabelType {
  NEW = 'NEW',
  UPDATED = 'UPDATED',
}
