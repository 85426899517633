/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { DataAccessPolicy } from './data-access-policy.model';
import { UserDescriptor } from './user-descriptor.model';

/**
 * UserAdminAccountRoles
 * @exports
 * @class UserAdminAccountRoles
 */
@Serializable
export class UserAdminAccountRoles {
  @JsonProperty({ name: 'user_descriptor', cls: UserDescriptor })
  public userDescriptor: UserDescriptor = undefined;

  @JsonProperty('role_ids')
  public roleIds: string[] = undefined;

  @JsonProperty({ name: 'active', excludeToJson: true })
  public active: boolean = undefined;

  public dataAccessPolicies: DataAccessPolicy[] = [];

  /**
   * Creates an instance of UserAdminAccountRoles.
   * @param {Array<Partial<UserAdminAccountRoles>>} args
   * @memberof UserAdminAccountRoles
   */
  constructor(...args: Array<Partial<UserAdminAccountRoles>>) {
    Object.assign(this, ...args);
  }
}
