/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { isEmpty, isUndefined, keys } from 'lodash-es';

import { defaultValueConverterFactory, filterConditionConverter } from '@ws1c/intelligence-models/converters';
import { FilterRule, QueryBuilder, RuleGroup } from '@ws1c/intelligence-models/filter';
import { ColumnIndex } from '@ws1c/intelligence-models/integration-meta/column.model';
import { Integration } from '@ws1c/intelligence-models/integration.model';
import { TrendMode } from '@ws1c/intelligence-models/trend-mode.enum';
import { getUniqueId, isV2Attribute } from '@ws1c/intelligence-models/utils';
import { CounterDefinition } from './counter-definition.model';
import { DashboardConfig } from './dashboard.config';
import { TrendDateRange } from './trend-date-range.model';

/**
 * Trend Definition Model Object
 *
 * @export
 * @class TrendDefinition
 */
@Serializable
export class TrendDefinition {
  @JsonProperty('trend_mode')
  public trendMode: string = undefined;

  @JsonProperty('accumulate')
  public accumulate: boolean = undefined;

  @JsonProperty({ name: 'date_range', cls: TrendDateRange })
  public dateRange: TrendDateRange = undefined;

  @JsonProperty('bucketing_attributes')
  public bucketingAttributes: string[] = undefined;

  @JsonProperty({ name: 'counter_definitions', cls: CounterDefinition })
  public counterDefinitions: CounterDefinition[] = undefined;

  @JsonProperty('precomputed_aggregation_filter')
  public precomputedAggregationFilter?: string = undefined;

  @JsonProperty({ name: 'precomputed_aggregation_filter_condition', customConverter: filterConditionConverter, excludeToJson: true })
  public precomputedAggregationFilterCondition?: any = undefined;

  @JsonProperty({ name: 'entities_by_integration', customConverter: defaultValueConverterFactory() })
  public entitiesByIntegration: any = undefined;

  @JsonProperty({ name: 'join_entities_by_integration', customConverter: defaultValueConverterFactory() })
  public joinEntitiesByIntegration: any = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty('cardinality')
  public cardinality: number = undefined;

  public ruleGroup: any = undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  public filter_condition: any = undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public filter_condition_nested_rules: any = undefined;
  public isCategoryAutomationSupported: boolean = false;
  public precomputedAggregationId?: string = undefined;

  private static readonly INTEGRATION_REGEX = new RegExp(DashboardConfig.Integration, 'g');
  private static readonly ENTITY_REGEX = new RegExp(DashboardConfig.Entity, 'g');

  private isAutomationFeatureEnabled: boolean = false;

  private _isCrossEntityOrIntegration = undefined;

  /**
   * Creates an instance of TrendDefinition.
   * @param {Array<Partial<TrendDefinition>>} args
   * @memberof TrendDefinition
   */
  constructor(...args: Array<Partial<TrendDefinition>>) {
    Object.assign(this, ...args);
  }

  /**
   * toV2 - UI workaround, returns new TrendDefinition with fully qualified names for filter attributes
   *
   * @static
   * @param {TrendDefinition} trendDefinition
   * @param {ColumnIndex} columnsByName
   * @returns {TrendDefinition}
   * @memberof TrendDefinition
   */
  public static toV2(trendDefinition: TrendDefinition, columnsByName: ColumnIndex): TrendDefinition {
    if (!trendDefinition.filter_condition_nested_rules) {
      return trendDefinition;
    }
    const rules = TrendDefinition.getNestedRulesV2(trendDefinition.filter_condition_nested_rules.rules, columnsByName);
    const filterConditionV2 = {
      ...trendDefinition.filter_condition_nested_rules,
      rules,
    };
    return new TrendDefinition({
      ...trendDefinition,
      filter: QueryBuilder.filterConditionToQueryString(filterConditionV2),
      filter_condition_nested_rules: filterConditionV2,
    });
  }

  /**
   * Setter for isAutomationFeatureEnabled
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public set automationEnabled(isEnabled: boolean) {
    this.isAutomationFeatureEnabled = isEnabled;
  }

  /**
   * Getter for isAutomationFeatureEnabled
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public get automationEnabled(): boolean {
    return this.isAutomationFeatureEnabled;
  }

  /**
   * filterCondition
   * @readonly
   * @type {*}
   * @memberOf TrendDefinition
   */
  public get filterCondition() {
    return this.filter_condition_nested_rules ?? this.filter_condition;
  }

  /**
   * getTrendDefinitionRuleGroup
   * @returns {RuleGroup}
   * @memberof TrendDefinition
   */
  public getTrendDefinitionRuleGroup(): RuleGroup {
    return QueryBuilder.parseRuleDefinitionTree(this.filterCondition);
  }

  /**
   * Get integration for single integration/entity map
   * @readonly
   * @type {string}
   * @memberof TrendDefinition
   */
  public get integration(): string {
    return this.entitiesByIntegration ? keys(this.entitiesByIntegration)[0] : '';
  }

  /**
   * Get all integrations
   * @readonly
   * @type {string[]}
   * @memberof TrendDefinition
   */
  public get integrations(): string[] {
    return this.entitiesByIntegration ? keys(this.entitiesByIntegration) : [];
  }

  /**
   * Get entity for single integration/entity map
   * @readonly
   * @type {string}
   * @memberof TrendDefinition
   */
  public get entity(): string {
    return this.integration ? this.entitiesByIntegration[this.integration][0] : '';
  }

  /**
   * Getter to get unique categoryId
   *
   * @readonly
   * @type {string}
   * @memberof TrendDefinition
   */
  public get categoryId(): string {
    return this.integration ? getUniqueId(this.integration, this.entity) : '';
  }

  /**
   * Getter to return array of all categories
   *
   * @readonly
   * @type {string[]}
   * @memberof TrendDefinition
   */
  public get categories(): string[] {
    const categories: string[] = [];
    if (this.entitiesByIntegration) {
      Object.entries(this.entitiesByIntegration).forEach(([integration, entities]: [string, string[]]) => {
        entities.forEach((entity) => categories.push(getUniqueId(integration, entity)));
      });
    }
    return categories;
  }

  /**
   * getIsRolling
   * @returns {boolean}
   * @memberof TrendDefinition
   */
  public getIsRolling(): boolean {
    return Boolean(this.dateRange && this.dateRange.rollingWindow);
  }

  /**
   * Currently joinEntitiesByIntegration object existance in the trend defination
   * is the only way to identify that a particular widget is based on joins
   *
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public get isJoinEnabled(): boolean {
    return !isEmpty(this.joinEntitiesByIntegration);
  }

  /**
   * hasV2Attributes
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public get hasV2Attributes(): boolean {
    return this.bucketingAttributes?.some(isV2Attribute) || isV2Attribute(this.counterDefinitions?.[0]?.aggregateAttribute);
  }

  /**
   * isV2
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public get isV2(): boolean {
    return this.isJoinEnabled || this.hasV2Attributes;
  }

  /**
   * Getter for isSnapshotPeriodical
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public get isSnapshotPeriodical(): boolean {
    return this.trendMode === TrendMode[TrendMode.SNAPSHOT_PERIODICAL];
  }

  /**
   * Getter for isCrossEntityOrIntegration
   * @readonly
   * @type {boolean}
   * @memberof TrendDefinition
   */
  public get isCrossEntityOrIntegration(): boolean {
    if (!isUndefined(this._isCrossEntityOrIntegration)) {
      return this._isCrossEntityOrIntegration;
    }
    if (!this.isV2) {
      this._isCrossEntityOrIntegration = false;
      return this._isCrossEntityOrIntegration;
    }
    const entities = new Set();
    const integrations = new Set(this.integrations);
    if (this.entity) {
      entities.add(this.entity);
    }
    let filterAttributes = [];
    if (this.filterCondition) {
      filterAttributes = Array.from(QueryBuilder.getAllAttributes(QueryBuilder.parseRuleDefinitionTree(this.filterCondition)));
    }
    const attributesIncounterDefinitions =
      this.counterDefinitions?.map((counterDefinition: CounterDefinition) => counterDefinition.aggregateAttribute) || [];
    [...attributesIncounterDefinitions, ...(this.bucketingAttributes || []), ...filterAttributes].forEach((attr: string) => {
      if (isV2Attribute(attr)) {
        const [integration, entity] = attr.split('.');
        integrations.add(integration);
        entities.add(entity);
      }
    });
    this._isCrossEntityOrIntegration = entities.size > 1 || integrations.size > 1;
    return this._isCrossEntityOrIntegration;
  }

  /**
   * Getter for isCopyDisabled
   * @param {string} widgetId
   * @returns {boolean}
   * @memberof TrendDefinition
   */
  public isCopyDisabled(widgetId: string = ''): boolean {
    // all widgets except SECURITY_THREAT uses v2 attributes.
    return (
      this.isSnapshotPeriodical ||
      this.isCompositeTrend(widgetId) ||
      this.isJoinEnabled ||
      !this.hasV2Attributes ||
      this.isCrossEntityOrIntegration
    );
  }

  /**
   * isCompositeTrend
   * @param {string} widgetId
   * @returns {boolean}
   * @memberof TrendDefinition
   */
  public isCompositeTrend(widgetId: string = ''): boolean {
    return widgetId.startsWith('_');
  }

  /**
   * replaceIntegrationEntity
   * @static
   * @param {TrendDefinition} trendDefinition
   * @memberof TrendDefinition
   */
  public static replaceIntegrationEntity(trendDefinition: TrendDefinition) {
    if (!trendDefinition.filter?.includes(DashboardConfig.Integration)) {
      return;
    }
    const integration = Object.keys(trendDefinition.entitiesByIntegration)[0];
    const entity = trendDefinition.entitiesByIntegration[integration][0];
    trendDefinition.filter = trendDefinition.filter.replace(TrendDefinition.INTEGRATION_REGEX, integration);
    trendDefinition.filter = trendDefinition.filter.replace(TrendDefinition.ENTITY_REGEX, entity);
    if (trendDefinition.integration === Integration.EMPLOYEE_EXPERIENCE) {
      const ruleGroup = trendDefinition.getTrendDefinitionRuleGroup();
      const transformed = RuleGroup.transformGroupForAllCategories(ruleGroup, integration, entity);
      trendDefinition.filter = QueryBuilder.buildQueryString(transformed, {});
    }
  }

  /**
   * getNestedRulesV2
   *
   * @static
   * @param {Array<FilterRule | RuleGroup>} rules
   * @param {ColumnIndex} columnsByName
   * @returns {Array<FilterRule | RuleGroup>}
   * @memberof TrendDefinition
   */
  private static getNestedRulesV2(rules: Array<FilterRule | RuleGroup>, columnsByName: ColumnIndex): Array<FilterRule | RuleGroup> {
    const rulesV2 = [];
    rules?.forEach((rule: any) => {
      if (rule.rules?.length) {
        rulesV2.push({
          ...rule,
          rules: this.getNestedRulesV2(rule.rules, columnsByName),
        });
      } else {
        rulesV2.push({
          ...rule,
          attribute: columnsByName[rule.attribute]?.sourceAttribute ?? rule.attribute,
        });
      }
    });
    return rulesV2;
  }
}
