/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Trial User Contact Details Model
 *
 * @export
 * @class TrialUserContactDetails
 */
@Serializable
export class TrialUserContactDetails {
  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  /**
   * Creates an instance of TrialUserContactDetails
   * @param {Array<Partial<TrialUserContactDetails>>} args - Initialization arguments for object
   * @memberof TrialUserContactDetails
   */
  constructor(...args: Array<Partial<TrialUserContactDetails>>) {
    Object.assign(this, ...args);
  }
}
