/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AppHeGroup } from './app-he-group.model';

/**
 * AppHeGroupResponse
 * @export
 * @class AppHeGroupResponse
 */
@Serializable
export class AppHeGroupResponse {
  @JsonProperty({ name: 'data', cls: AppHeGroup })
  public results: AppHeGroup[] = undefined;
}
