/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceDisplayCrumb
 * @export
 * @class DeviceDisplayCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceDisplayCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_display_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_display_event_timestamp)
  public eventTimestamp: number = undefined;

  /**
   * Creates an instance of DeviceDisplayCrumb.
   * @param {...Array<Partial<DeviceDisplayCrumb>>} args
   * @memberof DeviceDisplayCrumb
   */
  constructor(...args: Array<Partial<DeviceDisplayCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
