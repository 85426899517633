/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { CrashCrumb } from './crash-crumb.model';

/**
 * GroupedCrashIosCrumb
 * @export
 * @class GroupedCrashIosCrumb
 */
@Serializable
export class GroupedCrashIosCrumb extends CrashCrumb {
  // for grouped crash crumbs only
  @JsonProperty('apteligent.grouped_crash_ios.threshold_86_0')
  public crashGroupApple: string = undefined;

  @JsonProperty('apteligent.grouped_crash_ios.threshold_92_5')
  public crashGroupAndroid: string = undefined;

  @JsonProperty('apteligent.grouped_crash_ios.crash_group')
  public crashGroup: string = undefined;

  /**
   * Creates an instance of GroupedCrashIosCrumb.
   * @param {...Array<Partial<GroupedCrashIosCrumb>>} args
   * @memberof GroupedCrashIosCrumb
   */
  constructor(...args: Array<Partial<GroupedCrashIosCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
