/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { RuleGroup } from '@ws1c/intelligence-models/filter';
import { StandardDashboardType } from './dashboard.enum';
import { StandardDashboardGlobalFilter } from './standard-dashboard-global-filter.model';
import { TrendDateRange } from './trend-date-range.model';

/**
 * Standard Dashboard Requset Model Object
 *
 * @export
 * @class StandardDashboardRequest
 */
@Serializable
export class StandardDashboardRequest {
  @JsonProperty({ name: 'standard_dashboard_type', excludeToJson: true })
  public standardDashboardType?: StandardDashboardType;

  @JsonProperty({ name: 'global_filter', cls: StandardDashboardGlobalFilter })
  public globalFilter: StandardDashboardGlobalFilter;

  @JsonProperty({ name: 'additional_params', customConverter: defaultValueConverterFactory() })
  public additionalParams?: any;

  /**
   * Creates an instance of StandardDashboardRequest
   * @param {StandardDashboardType} standardDashboardType
   * @param {RuleGroup} ruleGroup
   * @param {TrendDateRange} trendDateRange
   * @param {*} additionalParams
   * @memberOf {StandardDashboardRequest}
   */
  constructor(
    standardDashboardType?: StandardDashboardType,
    ruleGroup?: RuleGroup,
    trendDateRange?: TrendDateRange,
    additionalParams: any = {},
  ) {
    this.standardDashboardType = standardDashboardType;
    this.globalFilter = new StandardDashboardGlobalFilter({
      ruleGroup,
      dateRange: trendDateRange,
    });
    this.additionalParams = additionalParams;
  }
}
