/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter } from '@dpa/ui-common';

/**
 * preserveDefaultValueConverter
 * @type CustomConverter
 */
export const preserveDefaultValueConverter: CustomConverter = {
  fromJson(data: any, instance?: any, defaultValue?: any): any {
    return data ?? defaultValue;
  },
  toJson(data: any): any {
    return data;
  },
};
