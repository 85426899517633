/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ThresholdSettingType
 * @export
 * @enum {string}
 */
export enum ThresholdSettingType {
  DEEM_VIRTUAL_EXPERIENCE_SCORE = 'DEEM_VIRTUAL_EXPERIENCE_SCORE',
  DEEM_PHYSICAL_EXPERIENCE_SCORE = 'DEEM_EXPERIENCE_SCORE',
}
