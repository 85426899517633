/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * UserFlowDashboardType
 * @export
 * @enum {string}
 */
export enum UserFlowDashboardType {
  // For User Flow Dashboard
  USER_FLOWS = 'USER_FLOWS',
  USER_FLOW_DETAILS = 'USER_FLOW_DETAILS',
}

/**
 * UserFlowWidgetSubtype
 * @export
 * @enum {string}
 */
export enum UserFlowWidgetSubtype {
  // For User Flow Dashboard
  USER_FLOWS_ALL_OCCURRENCES_BY_STATE_HISTOGRAM = 'USER_FLOWS_ALL_OCCURRENCES_BY_STATE_HISTOGRAM',
  USER_FLOWS_OCCURRENCES_TOTAL_COUNT = 'USER_FLOWS_OCCURRENCES_TOTAL_COUNT',
  USER_FLOWS_OCCURRENCES_TOTAL_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_OCCURRENCES_TOTAL_COUNT_PREVIOUS_PERIOD',

  USER_FLOWS_FAILURE_RATE = 'USER_FLOWS_FAILURE_RATE',
  USER_FLOWS_ALL_OCCURRENCES_HISTOGRAM = 'USER_FLOWS_ALL_OCCURRENCES_HISTOGRAM',

  USER_FLOWS_ALL_CRASHES_HISTOGRAM = 'USER_FLOWS_ALL_CRASHES_HISTOGRAM',
  USER_FLOWS_CRASHES_TOTAL_COUNT = 'USER_FLOWS_CRASHES_TOTAL_COUNT',
  USER_FLOWS_CRASHES_TOTAL_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_CRASHES_TOTAL_COUNT_PREVIOUS_PERIOD',

  // for the list of user flow cards in the side bar
  USER_FLOWS_OCCURRENCES_BY_NAME = 'USER_FLOWS_OCCURRENCES_BY_NAME',
  USER_FLOWS_OCCURRENCES_BY_NAME_AVERAGE_ACTIVE_TIME = 'USER_FLOWS_OCCURRENCES_BY_NAME_AVERAGE_ACTIVE_TIME',

  // summary widgets on user flow details page
  USER_FLOWS_OCCURRENCES_BY_NAME_COUNT = 'USER_FLOWS_OCCURRENCES_BY_NAME_COUNT',
  USER_FLOWS_OCCURRENCES_BY_NAME_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_OCCURRENCES_BY_NAME_COUNT_PREVIOUS_PERIOD',
  USER_FLOWS_CRASHES_BY_NAME_COUNT = 'USER_FLOWS_CRASHES_BY_NAME_COUNT',
  USER_FLOWS_CRASHES_BY_NAME_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_CRASHES_BY_NAME_COUNT_PREVIOUS_PERIOD',
  USER_FLOWS_TIMEOUTS_BY_NAME_COUNT = 'USER_FLOWS_TIMEOUTS_BY_NAME_COUNT',
  USER_FLOWS_TIMEOUTS_BY_NAME_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_TIMEOUTS_BY_NAME_COUNT_PREVIOUS_PERIOD',
  USER_FLOWS_FAILURES_BY_NAME_COUNT = 'USER_FLOWS_FAILURES_BY_NAME_COUNT',
  USER_FLOWS_FAILURES_BY_NAME_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_FAILURES_BY_NAME_COUNT_PREVIOUS_PERIOD',
  USER_FLOWS_SUCCESS_BY_NAME_COUNT = 'USER_FLOWS_SUCCESS_BY_NAME_COUNT',
  USER_FLOWS_SUCCESS_BY_NAME_COUNT_PREVIOUS_PERIOD = 'USER_FLOWS_SUCCESS_BY_NAME_COUNT_PREVIOUS_PERIOD',
  USER_FLOWS_ACTIVE_USERS_COUNT_BY_NAME = 'USER_FLOWS_ACTIVE_USERS_COUNT_BY_NAME',
  USER_FLOWS_ACTIVE_USERS_COUNT_BY_NAME_PREVIOUS_PERIOD = 'USER_FLOWS_ACTIVE_USERS_COUNT_BY_NAME_PREVIOUS_PERIOD',
  __USER_FLOWS_FAILURE_RATE_HISTORICAL = '__USER_FLOWS_FAILURE_RATE_HISTORICAL',
}
