/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * MarketplaceResourceActionType
 *
 * @export
 * @enum {number}
 */
export enum MarketplaceResourceActionType {
  ADD_TO_WORKSPACE,
  DEPLOY,
  DOWNLOAD,
}
