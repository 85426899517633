/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DevicesProfileOverviewTab
 * @export
 * @enum {string}
 */
export enum DevicesProfileOverviewTab {
  TIMELINE = 'timeline',
  SUMMARY = 'summary',
}
