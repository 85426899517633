/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { LookupConfig } from '@ws1c/intelligence-models/connection';
import { MetaFormField } from '@ws1c/intelligence-models/meta-form';

/**
 * AutomationActionFieldForLookup
 * @export
 * @class AutomationActionFieldForLookup
 */
@Serializable
export class AutomationActionFieldForLookup {
  @JsonProperty({ name: 'field', cls: MetaFormField })
  public field: MetaFormField = undefined;

  @JsonProperty({ name: 'lookupConfig', cls: LookupConfig })
  public lookupConfig?: LookupConfig = undefined;

  /**
   * Creates an instance of AutomationActionFieldForLookup.
   * @param {Array<Partial<AutomationActionFieldForLookup>>} args
   * @memberof AutomationActionFieldForLookup
   */
  constructor(...args: Array<Partial<AutomationActionFieldForLookup>>) {
    Object.assign(this, ...args);
  }
}
