/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * UIPreference
 *
 * @export
 * @enum {string}
 */
export enum UIPreference {
  DASHBOARD_VIEW_TYPE = 'dashboard.view.type',
  DASHBOARD_VIEW_SORT = 'dashboard.view.sort',
  IS_AUTOMATION_INTRO_PAGE_VISITED = 'automation.intro.visited',
  IS_REPORT_INTRO_PAGE_VISITED = 'report.intro.visited',
  IS_DASHBOARD_INTRO_PAGE_VISITED = 'dashboard.intro.visited',
  IS_DEEM_PLAYBOOKS_INTRO_PAGE_VISITED = 'deem.playbooks.intro.visited',
  IS_UI_TELEMETRY_BANNER_CLOSED = 'ui.telemetry.banner.closed',
  IS_UI_TELEMETRY_BANNER_VISITED = 'ui.telemetry.banner.visited',
  HOME_PAGE_SECTION_ORDER = 'home.page.section.order',
  HOME_PAGE_SECTION_HIDDEN = 'home.page.section.hidden',
  IS_REPORT_RETENTION_INTRO_MODAL_VISITED = 'report.retention.intro.modal.visited',
  LAST_SELECTED_KEY_SELECTOR_VIEW = 'last.selected.key.selector.view',
  REPORT_VIEW_SORT = 'report.view.sort',
  WORKFLOW_VIEW_TYPE = 'workflow.view.type',
  WORKFLOW_VIEW_SORT = 'workflow.view.sort',
  GLOBAL_ORCHESTRATOR_SELECTED_VIEW_TYPE = 'global_orchestrator.selected.view.type',

  // Secondary Navigation
  SECONDARY_NAVIGATION_GROUPS_EXPANDED = 'secondary.navigation.groups.expanded',
  SECONDARY_NAVIGATION_COLLAPSED = 'secondary.navigation.collapsed',

  // Trial Banner
  TRIAL_BANNER_SNOOZE_START_TIME = 'trial.banner.snooze.starttime',
  EXPIRED_BANNER_HIDDEN = 'ui.expired.banner.hidden',

  // Notification Bell Tray
  IS_NOTIFICATION_BELL_TRAY_ALERT_DISMISSED = 'notification.bell.tray.alert.dismissed',

  // Hub Notifications
  HUB_NOTIFICATIONS_LIST_FILTER = 'hub.notifications.list.filter',
  HUB_NOTIFICATIONS_LIST_SORT = 'hub.notifications.list.sort',

  // Apteligent Banner
  HIDE_APTELIGENT_BANNER = 'hide.apteligent.banner',

  // Trust Network Banner
  HIDE_TRUST_NETWORK_BANNER = 'hide.trust.network.banner',

  // DEEM Surveys
  DEEM_SURVEYS_LIST_SORT = 'deem.surveys.list.sort',

  // Standard Dashboard UEM V2 Toggle List
  UI_UEM_V2_DASHBOARD_TOGGLE_ENABLED = 'ui.uem.v2.dashboard.toggle.enabled',
}
