/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';

export interface Assets {
  [key: string]: string;
}

/**
 * UI
 *
 * @export
 * @class UI
 */
@Serializable
export class UI {
  @JsonProperty({ name: 'assets', customConverter: defaultValueConverterFactory() })
  public assets: Assets = undefined;

  @JsonProperty({ name: 'ui_preferences', customConverter: defaultValueConverterFactory() })
  public uiPreferences: GenericObject = undefined;

  // Add back session_attributes due to missing user account info from UEM log-in
  @JsonProperty({ name: 'session_attributes', customConverter: defaultValueConverterFactory() })
  public sessionAttributes: GenericObject = undefined;

  @JsonProperty('token_endpoint')
  public tokenEndpoint: string = undefined;

  @JsonProperty({ name: 'system_object_ids', customConverter: defaultValueConverterFactory() })
  public systemObjectIds: GenericObject = undefined;

  @JsonProperty({ name: 'limits', customConverter: defaultValueConverterFactory() })
  public limits: GenericObject = undefined;

  /**
   * Creates an instance of UI
   * @param {any} args
   * @memberof UI
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
