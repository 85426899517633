/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

export const INTEGRATION_ENTITY_ATTRIBUTE_REGEX: RegExp = /\w+\.\w+\.\w+/;

/**
 *
 * get fully qualitied names for attributes
 * @export
 * @param {string} integration
 * @param {string} entity
 * @param {string} [columnName]
 * @returns {string}
 */
export function getFQN(integration: string, entity: string, columnName?: string): string {
  return `${integration}.${entity}.${columnName}`;
}

/**
 * getFQNFunction
 * @export
 * @param {string} integration
 * @param {string} entity
 * @returns {Function}
 */
export function getFQNFunction(integration: string, entity: string): Function {
  return (name: string) => getFQN(integration, entity, name);
}

/**
 * getFQNForColumns
 * @export
 * @param {string} integration
 * @param {string} entity
 * @param {string[]} columnsNames
 * @returns {string[]}
 */
export function getFQNForColumns(integration: string, entity: string, columnsNames: string[]): string[] {
  return columnsNames.map((columnName: string) => getFQN(integration, entity, columnName));
}

/**
 * getEntityFromFQN
 * @export
 * @param {string} colName
 * @returns {string}
 */
export function getEntityFromFQN(colName: string): string {
  return colName.split('.')[1];
}

/**
 * Tests if attribute string or string containing attribute is fully qualified or not
 * isV2Attribute
 * @export
 * @param {string} attributeStr
 * @returns {boolean}
 */
export function isV2Attribute(attributeStr: string): boolean {
  const regex = new RegExp(INTEGRATION_ENTITY_ATTRIBUTE_REGEX, 'g');
  return regex.test(attributeStr);
}

/**
 * getShortColumnName
 * @export
 * @param {string} name
 * @returns {string}
 */
export function getShortColumnName(name: string): string {
  return name?.split('.').pop();
}

/**
 * getFQNFromMaskedName
 * @export
 * @param {string} integration
 * @param {string} entity
 * @param {string} maskedName
 * @returns {string[]}
 */
export function getFQNFromMaskedName(integration: string, entity: string, maskedName: string): string {
  const regx: RegExp = /integration.entity/g;
  return maskedName.replace(regx, `${integration}.${entity}`);
}
