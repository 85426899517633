/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';
import { MarketplaceResourceTag } from './marketplace-resource-tag.model';
import { MarketplaceResourceType } from './marketplace-resource-type.enum';

/**
 * MarketplaceSearchRequest
 * @export
 * @class MarketplaceSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class MarketplaceSearchRequest extends GenericSearchRequest {
  @JsonProperty('resource_types_to_match')
  public resourceTypes: MarketplaceResourceType[] = undefined;

  @JsonProperty({ name: 'tags_to_match', cls: MarketplaceResourceTag })
  public tags: MarketplaceResourceTag[] = undefined;

  /**
   * Creates an instance of MarketplaceSearchRequest
   * @param {Array<Partial<MarketplaceSearchRequest>>} args
   * @memberof MarketplaceSearchRequest
   */
  constructor(...args: Array<Partial<MarketplaceSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
