/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightDashboardType
 * @export
 * @enum {string}
 */
export enum InsightDashboardType {
  // For Insight Dashboard
  INSIGHT_IMPACT_APP_INSTALL_FAILED_DEVICES_COUNT = 'INSIGHT_IMPACT_APP_INSTALL_FAILED_DEVICES_COUNT',
  INSIGHT_IMPACT_APP_INSTALL_PENDING_DEVICES_COUNT = 'INSIGHT_IMPACT_APP_INSTALL_PENDING_DEVICES_COUNT',
  INSIGHT_IMPACT_APPS_DEVICES_COUNT = 'INSIGHT_IMPACT_APPS_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_ANDROID_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_ANDROID_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_IOS_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_IOS_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_NET_ERROR_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_NET_ERROR_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_APPS = 'INSIGHT_IMPACTED_DEVICES_APPS',
  INSIGHT_IMPACT_DURATION = 'INSIGHT_IMPACT_DURATION',
  INSIGHT_IMPACT_DURATION_TOTAL = 'INSIGHT_IMPACT_DURATION_TOTAL',
  INSIGHT_IMPACT_DURATION_DEVICES_COUNT = 'INSIGHT_IMPACT_DURATION_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_DURATION = 'INSIGHT_IMPACTED_DEVICES_DURATION',
  INSIGHT_IMPACT_CPU = 'INSIGHT_IMPACT_CPU',
  INSIGHT_IMPACT_CPU_TOTAL = 'INSIGHT_IMPACT_CPU_TOTAL',
  INSIGHT_IMPACT_CPU_DEVICES_COUNT = 'INSIGHT_IMPACT_CPU_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_CPU_UTILIZATION = 'INSIGHT_IMPACTED_DEVICES_CPU_UTILIZATION',
  INSIGHT_IMPACT_FAILED_SSO_LOGIN_DEVICES_COUNT = 'INSIGHT_IMPACT_FAILED_SSO_LOGIN_DEVICES_COUNT',
  INSIGHT_IMPACT_HORIZON_LOGON_DEVICES_COUNT = 'INSIGHT_IMPACT_HORIZON_LOGON_DEVICES_COUNT',
  INSIGHT_IMPACT_HORIZON_SESSION = 'INSIGHT_IMPACT_HORIZON_SESSION',
  INSIGHT_IMPACT_HORIZON_SESSION_TOTAL = 'INSIGHT_IMPACT_HORIZON_SESSION_TOTAL',
  INSIGHT_IMPACT_OS_CRASHES = 'INSIGHT_IMPACT_OS_CRASHES',
  INSIGHT_IMPACT_OS_CRASHES_TOTAL = 'INSIGHT_IMPACT_OS_CRASHES_TOTAL',
  INSIGHT_IMPACT_OS_CRASHES_DEVICES_COUNT = 'INSIGHT_IMPACT_OS_CRASHES_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_APP_INSTALL_FAILED = 'INSIGHT_IMPACTED_DEVICES_APP_INSTALL_FAILED',
  INSIGHT_IMPACTED_DEVICES_APP_INSTALL_PENDING = 'INSIGHT_IMPACTED_DEVICES_APP_INSTALL_PENDING',
  INSIGHT_IMPACTED_DEVICES_APTELIGENT_ANDROID = 'INSIGHT_IMPACTED_DEVICES_APTELIGENT_ANDROID',
  INSIGHT_IMPACTED_DEVICES_APTELIGENT_IOS = 'INSIGHT_IMPACTED_DEVICES_APTELIGENT_IOS',
  INSIGHT_IMPACTED_DEVICES_APTELIGENT_NET_ERROR = 'INSIGHT_IMPACTED_DEVICES_APTELIGENT_NET_ERROR',
  INSIGHT_IMPACTED_DEVICES_FAILED_SSO_LOGIN = 'INSIGHT_IMPACTED_DEVICES_FAILED_SSO_LOGIN',
  INSIGHT_IMPACTED_DEVICES_HORIZON_SESSION = 'INSIGHT_IMPACTED_DEVICES_HORIZON_SESSION',
  INSIGHT_IMPACTED_DEVICES_OS_CRASHES = 'INSIGHT_IMPACTED_DEVICES_OS_CRASHES',
}

/**
 * InsightWidgetSubtype
 * @export
 * @enum {string}
 */
export enum InsightWidgetSubtype {
  // For Insight Dashboard
  INSIGHT_COUNTS = 'INSIGHT_COUNTS',
  // Insights Impact - App Installs
  INSIGHT_IMPACT_APP_INSTALL_FAILED_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_APP_INSTALL_FAILED_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_APP_INSTALL_FAILED_TOTAL_DEVICES_COUNT_TREND = 'INSIGHT_IMPACT_APP_INSTALL_FAILED_TOTAL_DEVICES_COUNT_TREND',
  INSIGHT_IMPACT_APP_INSTALL_PENDING_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_APP_INSTALL_PENDING_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_APP_INSTALL_PENDING_TOTAL_DEVICES_COUNT_TREND = 'INSIGHT_IMPACT_APP_INSTALL_PENDING_TOTAL_DEVICES_COUNT_TREND',
  INSIGHT_IMPACTED_DEVICES_APP_INSTALL_FAILED_TABLE = 'INSIGHT_IMPACTED_DEVICES_APP_INSTALL_FAILED_TABLE',
  INSIGHT_IMPACTED_DEVICES_APP_INSTALL_PENDING_TABLE = 'INSIGHT_IMPACTED_DEVICES_APP_INSTALL_PENDING_TABLE',
  __INSIGHT_IMPACT_APP_INSTALL_FAILED = '__INSIGHT_IMPACT_APP_INSTALL_FAILED',
  __INSIGHT_IMPACT_APP_INSTALL_PENDING = '__INSIGHT_IMPACT_APP_INSTALL_PENDING',
  // Insights Impact - App Crashes/Hangs/Duration
  INSIGHT_IMPACT_APPS_BY_VERSION = 'INSIGHT_IMPACT_APPS_BY_VERSION',
  INSIGHT_IMPACT_APPS_BY_VERSION_TOTAL = 'INSIGHT_IMPACT_APPS_BY_VERSION_TOTAL',
  INSIGHT_IMPACT_APPS_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_APPS_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_APPS_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_APPS_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_APPS_TABLE = 'INSIGHT_IMPACTED_DEVICES_APPS_TABLE',
  __INSIGHT_IMPACT_APPS = '__INSIGHT_IMPACT_APPS',
  // Insights Impact - Apteligent
  INSIGHT_IMPACT_APTELIGENT_ANDROID_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_ANDROID_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_ANDROID_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_ANDROID_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_IOS_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_IOS_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_IOS_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_IOS_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_NET_ERROR_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_NET_ERROR_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_APTELIGENT_NET_ERROR_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_APTELIGENT_NET_ERROR_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_APTELIGENT_ANDROID_TABLE = 'INSIGHT_IMPACTED_DEVICES_APTELIGENT_ANDROID_TABLE',
  INSIGHT_IMPACTED_DEVICES_APTELIGENT_IOS_TABLE = 'INSIGHT_IMPACTED_DEVICES_APTELIGENT_IOS_TABLE',
  INSIGHT_IMPACTED_DEVICES_APTELIGENT_NET_ERROR_TABLE = 'INSIGHT_IMPACTED_DEVICES_APTELIGENT_NET_ERROR_TABLE',
  // Insights Impact - Boot / Shutdown duration
  INSIGHT_IMPACT_DURATION_BY_OS_VERSION = 'INSIGHT_IMPACT_DURATION_BY_OS_VERSION',
  INSIGHT_IMPACT_DURATION_BY_OS_VERSION_TOTAL = 'INSIGHT_IMPACT_DURATION_BY_OS_VERSION_TOTAL',
  INSIGHT_IMPACT_DURATION_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_DURATION_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_DURATION_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_DURATION_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_DURATION_TABLE = 'INSIGHT_IMPACTED_DEVICES_DURATION_TABLE',
  __INSIGHT_IMPACT_DURATION = '__INSIGHT_IMPACT_DURATION',
  // Insights Impact - OS Crashes
  INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION = 'INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION',
  INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION_TOTAL = 'INSIGHT_IMPACT_OS_CRASHES_BY_OS_VERSION_TOTAL',
  INSIGHT_IMPACT_OS_CRASHES_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_OS_CRASHES_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_OS_CRASHES_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_OS_CRASHES_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_OS_CRASHES_TABLE = 'INSIGHT_IMPACTED_DEVICES_OS_CRASHES_TABLE',
  __INSIGHT_IMPACT_OS_CRASHES = '__INSIGHT_IMPACT_OS_CRASHES',
  // Insights - CPU
  INSIGHT_IMPACT_CPU_BY_OS_VERSION = 'INSIGHT_IMPACT_CPU_BY_OS_VERSION',
  INSIGHT_IMPACT_CPU_BY_OS_VERSION_TOTAL = 'INSIGHT_IMPACT_CPU_BY_OS_VERSION_TOTAL',
  INSIGHT_IMPACT_CPU_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_CPU_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_CPU_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_CPU_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_CPU_UTILIZATION_TABLE = 'INSIGHT_IMPACTED_DEVICES_CPU_UTILIZATION_TABLE',
  __INSIGHT_IMPACT_CPU = '__INSIGHT_IMPACT_CPU',
  // Insights - Failed SSO Login
  INSIGHT_IMPACT_FAILED_SSO_LOGIN_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_FAILED_SSO_LOGIN_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_FAILED_SSO_LOGIN_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_FAILED_SSO_LOGIN_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACTED_DEVICES_FAILED_SSO_LOGIN_TABLE = 'INSIGHT_IMPACTED_DEVICES_FAILED_SSO_LOGIN_TABLE',
  __INSIGHT_IMPACT_FAILED_SSO_LOGIN = '__INSIGHT_IMPACT_FAILED_SSO_LOGIN',
  // Insights - Horizon
  INSIGHT_IMPACT_HORIZON_LOGON_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACT_HORIZON_LOGON_IMPACTED_DEVICES_COUNT',
  INSIGHT_IMPACT_HORIZON_LOGON_TOTAL_DEVICES_COUNT = 'INSIGHT_IMPACT_HORIZON_LOGON_TOTAL_DEVICES_COUNT',
  INSIGHT_IMPACT_HORIZON_SESSION_BY_POOL_ID = 'INSIGHT_IMPACT_HORIZON_SESSION_BY_POOL_ID',
  INSIGHT_IMPACT_HORIZON_SESSION_TOTAL = 'INSIGHT_IMPACT_HORIZON_SESSION_TOTAL',
  INSIGHT_IMPACTED_DEVICES_HORIZON_SESSION_TABLE = 'INSIGHT_IMPACTED_DEVICES_HORIZON_SESSION_TABLE',
  __INSIGHT_IMPACT_HORIZON_SESSION = '__INSIGHT_IMPACT_HORIZON_SESSION',
  // Insight Impacted Devices
  INSIGHT_IMPACTED_DEVICES = 'INSIGHT_IMPACTED_DEVICES',
  INSIGHT_IMPACTED_DEVICES_COUNT = 'INSIGHT_IMPACTED_DEVICES_COUNT',
  INSIGHT_TOTAL_DEVICES_COUNT = 'INSIGHT_TOTAL_DEVICES_COUNT',
}
