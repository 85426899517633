/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceServicesCrumb
 * @export
 * @class DeviceServicesCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceServicesCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty('employee_experience.services.service_name')
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_service_name)
  public serviceName: string = undefined;

  @JsonProperty('employee_experience.services.image_name')
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_image_name)
  public imageName: string = undefined;

  @JsonProperty('employee_experience.services.duration_millis')
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_services_duration_millis)
  public duration: number = undefined;

  /**
   * Creates an instance of DeviceServicesCrumb.
   * @param {...Array<Partial<DeviceServicesCrumb>>} args
   * @memberof DeviceServicesCrumb
   */
  constructor(...args: Array<Partial<DeviceServicesCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
