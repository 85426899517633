/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ConnectorAction } from './connector-action.model';

/**
 * ConnectorsAllActionsResponse
 * @export
 * @class ConnectorsAllActionsResponse
 */
@Serializable
export class ConnectorsAllActionsResponse {
  @JsonProperty({ name: 'data', cls: ConnectorAction })
  public data: ConnectorAction[] = [];

  /**
   * Creates an instance of ConnectorActionsResponse.
   * @param {...Array<Partial<ConnectorsAllActionsResponse>>} args
   * @memberof ConnectorActionsResponse
   */
  constructor(...args: Array<Partial<ConnectorsAllActionsResponse>>) {
    Object.assign(this, ...args);
  }
}
