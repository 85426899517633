/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { AddOnType, IntegratedServiceType } from '@ws1c/intelligence-models/integrated-services';

export const TRIAL_SERVICES_IMAGES: Partial<Record<IntegratedServiceType | AddOnType, string>> = {
  [IntegratedServiceType.INTELLIGENCE]: 'assets/images/intelligence-premium-no-text.png',
  [AddOnType.INTELLIGENCE_DEEM]: 'assets/images/deem-no-text.png',
};
