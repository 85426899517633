/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Navigation Menu Item
 *
 * @export
 * @enum {NavigationMenuItem}
 */
export enum NavigationMenuItem {
  ADMINISTRATORS,
  INTEGRATIONS,
  INTEGRATIONS_CMS,
  SETTINGS,
  NOTIFICATION,
  // Workspace
  WORKSPACE,
  WORKSPACE_UEM,
  WORKSPACE_HORIZON,
  WORKSPACE_EXPERIENCE_MANAGEMENT,
  WORKSPACE_WORKSPACE_SECURITY,
  WORKSPACE_WORKSPACE_SECURITY_NO_CVE,
  // Marketplace
  MARKETPLACE,
  MARKETPLACE_INTEGRATIONS,
  MARKETPLACE_SOLUTIONS,
  MARKETPLACE_TEMPLATES,
}
