/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ResourceTemplate } from '@ws1c/intelligence-models/template/resource-template.model';

/**
 * SensorTemplate
 * @export
 * @class SensorTemplate
 * @extends ResourceTemplate
 */
@Serializable
export class SensorTemplate extends ResourceTemplate {
  @JsonProperty('response_data_type')
  public returnType: string = undefined;
}
