/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightStatus
 * @export
 * @enum {string}
 */
export enum InsightStatus {
  ACTIVE = 'ACTIVE',
  DISMISSED = 'DISMISSED',
}
