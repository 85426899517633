/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Tags
 *
 * @export
 * @class Tags
 */
@Serializable
export class Tags {
  @JsonProperty({ name: 'platform' })
  public platforms: string[] = undefined;

  /**
   * Creates an instance of Tags.
   * @param {Array<Partial<Tags>>} args
   * @memberof Tags
   */
  constructor(...args: Array<Partial<Tags>>) {
    Object.assign(this, ...args);
  }
}
