/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DATA_SOURCES_CARD_TYPE
 * @export
 * @enum {string}
 */
export enum DATA_SOURCES_CARD_TYPE {
  CONNECTION = 'CONNECTION',
  CONNECTION_TENANT = 'CONNECTION_TENANT',
  DESKTOP_TELEMETRY = 'DESKTOP_TELEMETRY',
  EVENT_FORWARDER_CONNECTION = 'EVENT_FORWARDER_CONNECTION',
  INTEGRATION = 'INTEGRATION',
  SERVICENOW_ITSM = 'SERVICENOW_ITSM',
  MOBILE_TELEMETRY = 'MOBILE_TELEMETRY',
  OAUTH_CONNECTION = 'OAUTH_CONNECTION',
  PARTNER_CONNECTION = 'PARTNER_CONNECTION',
  UAG = 'UAG',
}
