/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * HorizonSessionStatus
 *
 * @exports
 * @enum {string}
 */
export enum HorizonSessionStatus {
  ACTIVE = 'Active',
  IDLE = 'Idle',
  DISCONNECTED = 'Disconnected',
}

/**
 * HorizonHealthLevel
 * @export
 * @enum {string}
 */
export enum HorizonHealthLevel {
  CRITICAL = 'Critical',
  WARNING = 'Warning',
  NORMAL = 'Normal',
}

/**
 * HorizonPodType
 * @export
 * @enum {string}
 */
export enum HorizonPodType {
  AZURE = 'AZURE',
  VMWARE_SDDC = 'VMWARE_SDDC',
}

/**
 * HorizonDeploymentType
 * @export
 * @enum {string}
 */
export enum HorizonDeploymentType {
  AWS = 'VMware Cloud on AWS',
  AZURE = 'Microsoft Azure',
  AVS = 'Azure VMware Solution',
  DELL_EMC = 'VMware Cloud on Dell EMC',
  GCVE = 'Google Cloud VMware Engine',
  OCVS = 'Oracle Cloud VMware Solution',
  PRIVATE_DATA_CENTER = 'Private Data Center',
}
