/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/**
 * Type of action performed on individual reports
 * @export
 * @enum ReportAction
 */
export enum ReportAction {
  ADD_TO_BOOKMARKS,
  AUTOMATE,
  COPY,
  BULK_DELETE,
  DELETE,
  EDIT,
  SAVE_AS_TEMPLATE,
  REMOVE_FROM_BOOKMARKS,
  RUN,
  SHARE,
  SCHEDULE,
  TRANSFER_OWNERSHIP,
}
