/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * PendoEventMarketplaceTemplatesSelectedSource
 * @export
 * @enum {string}
 */
export enum PendoEventMarketplaceTemplatesSelectedSource {
  ADD_TO_WORKSPACE = 'Add to Workspace',
  DEPLOY = 'Deploy',
  DOWNLOAD = 'Download',
  FILTER = 'Filter',
  PREVIEW = 'Preview',
}
