/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ResourceDeployValidationResponse - Response model for validating the script and sensors deployment.
 * @export
 * @class ResourceDeployValidationResponse
 */
@Serializable
export class ResourceDeployValidationResponse {
  @JsonProperty('validation_messages')
  public validationMessages: string[] = [];

  /**
   * Creates an instance of ResourceDeployValidationResponse
   * @param {Array<Partial<ResourceDeployValidationResponse>>} args
   * @memberof ResourceDeployValidationResponse
   */
  constructor(...args: Array<Partial<ResourceDeployValidationResponse>>) {
    Object.assign(this, ...args);
  }
}
