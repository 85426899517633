/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChartDrilldownEvent } from './chart-drilldown-event.interface';
import { WidgetDetailDefinition } from './widget-detail-definition.model';

export enum WidgetDetailPageSkinType {
  APPS,
  CARBON_BLACK,
  CUSTOM,
  CVE,
  CVE_DASHBOARD,
  DATA_EXPLORER,
  DEEM_INCIDENTS,
  DEEM_SURVEYS,
  DEVICES,
  DEVICE_DESKTOP_NETWORK,
  DEVICE_RISK,
  HORIZON_COUNT_TITAN,
  HORIZON_SESSIONS,
  HORIZON_SESSIONS_TITAN,
  LOGIN_RISK,
  OS_UPDATES,
  SECURITY_RISK,
  UAG_TUNNEL,
  USER_FLOWS,
  USER_RISK,
  WORKSPACE_ONE_MTD,
}

export interface WidgetDetailPage {
  widgetId?: string;
  dashboardId?: string;
  widgetDetailDefinition?: WidgetDetailDefinition;
  drilldownEvents?: ChartDrilldownEvent[];
  drilldownIndex?: number;
  skinType?: WidgetDetailPageSkinType;
}
