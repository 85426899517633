/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AppCrashGroup
 * @export
 * @class AppCrashGroup
 */
@Serializable
export class AppCrashGroup {
  @JsonProperty('bucket_id')
  public bucketId: string = undefined;

  @JsonProperty('count')
  public count: number = undefined;

  @JsonProperty('error_name')
  public errorName: string = undefined;

  @JsonProperty('error_reason')
  public errorReason: string = undefined;

  @JsonProperty('event_type')
  public eventType: string = undefined;

  @JsonProperty('error_type')
  public errorType: string = undefined;

  @JsonProperty('first_occurred')
  public firstOccurred: number = undefined;

  @JsonProperty('last_occurred')
  public lastOccurred: number = undefined;

  @JsonProperty('users_affected_count')
  public usersAffectedCount: number = undefined;

  /**
   * constructor
   * @param {Array<Partial<AppCrashGroup>>} args
   * @memberof AppCrashGroup
   */
  constructor(...args: Array<Partial<AppCrashGroup>>) {
    Object.assign(this, ...args);
  }

  /**
   * getLabel
   * @returns {string}
   */
  public getLabel(): string {
    return this.errorName || this.errorType || this.errorReason;
  }
}
