/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { DeemPlaybookType } from './deem-playbook-type.enum';
import { DeemPlaybookVersion } from './deem-playbook-version.model';

/**
 * DeemPlaybookCreateUpdateRequest
 * @export
 * @class DeemPlaybookCreateUpdateRequest
 */
@Serializable
export class DeemPlaybookCreateUpdateRequest {
  @JsonProperty('playbook_name')
  public playbookName: string = undefined;

  @JsonProperty({ name: 'playbook_type', customConverter: enumConverterFactory(DeemPlaybookType) })
  public playbookType: DeemPlaybookType = undefined;

  @JsonProperty({ name: 'playbook_version', cls: DeemPlaybookVersion })
  public playbookVersion: DeemPlaybookVersion = undefined;

  /**
   * Initializes instance of DeemPlaybookCreateUpdateRequest
   * @param {Array<Partial<DeemPlaybookCreateUpdateRequest>>} args
   * @memberof PlaybookCreateRequest
   */
  constructor(...args: Array<Partial<DeemPlaybookCreateUpdateRequest>>) {
    Object.assign(this, ...args);
  }
}
