/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Tags Type
 *
 * @export
 * @enum {number}
 */
export enum TAGS_TYPE {
  DEFAULT,
  INTEGRATION,
  BOOLEAN,
  CUSTOM_TEMPLATE,
}

/**
 * QUICK_FILTER_TAG_TYPE
 * @export
 * @enum {string}
 */
export enum QUICK_FILTER_TAG_TYPE {
  EXPERIENCE_SCORE = 'EXPERIENCE_SCORE',
  TEMPLATE = 'TEMPLATE',
}
