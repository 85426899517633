/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { CrashCrumb } from './crash-crumb.model';

/**
 * CrashIosCrumb
 * @export
 * @class CrashIosCrumb
 */
@Serializable
export class CrashIosCrumb extends CrashCrumb {
  @JsonProperty('apteligent.crash_ios.name')
  public name: string = undefined;

  @JsonProperty('apteligent.crash_ios.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.crash_ios.error_reason')
  public errorReason: string = undefined;

  // for IOS only
  @JsonProperty('apteligent.crash_ios.error_type')
  public errorType: string = undefined;

  @JsonProperty('apteligent.crash_ios.error_code')
  public errorCode: string = undefined;

  @JsonProperty('apteligent.crash_ios.crash_group')
  public crashGroup: string = undefined;

  /**
   * Creates an instance of CrashIosCrumb.
   * @param {...Array<Partial<CrashIosCrumb>>} args
   * @memberof CrashIosCrumb
   */
  constructor(...args: Array<Partial<CrashIosCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
