/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, Deserialize, deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import { omit, uniqueId } from 'lodash-es';

import { WorkflowConditionBranch, WorkflowConditionBranchKey } from '@ws1c/intelligence-models/automation/automation.enum';
import { enumConverterFactory, preserveDefaultValueConverter } from '@ws1c/intelligence-models/converters';
import { ActionTypeV2 } from './action-type.enum';
import { AutomationActionSettingsSection } from './automation-action-settings-section';
import { AutomationCondition } from './automation-condition.model';

const WORKFLOW_ACTION_KEY_PREFIX = 'workflow-action-';

/**
 * settingsBySectionConverter
 */
const settingsBySectionConverter: CustomConverter = {
  fromJson(data: any): any {
    return data;
  },
  toJson(data: any): any {
    // omitting 'SYSTEM' section in the case of automation created from template
    return omit(data, AutomationActionSettingsSection.SYSTEM);
  },
};

/**
 * actionDataConverter
 */
const actionDataConverter: CustomConverter = {
  fromJson(data: any): any {
    return deserialize(ActionData, data);
  },
  toJson(data: any): any {
    return data;
  },
};

/**
 * actionDataConverter
 * backend send grouped_actions as AutomationAction[][]
 * convert this to AutomationAction[] which is suitable for UI.
 */
const groupConverter: CustomConverter = {
  fromJson(data: any): any {
    return (data ?? []).map((actionArray: AutomationAction[]) => {
      return deserialize(AutomationAction, actionArray[0]);
    });
  },
  toJson(data: any): any {
    return (data ?? []).map((action: AutomationAction) => [action]);
  },
};

/**
 * decisionCasesConverter
 * @type {CustomConverter}
 */
export const decisionCasesConverter: CustomConverter = {
  fromJson(data: any): any {
    return deserialize(ConditionDecision, data);
  },
  toJson(data: any): any {
    if (!data?.[WorkflowConditionBranchKey.TRUE_BRANCH]?.length && !data?.[WorkflowConditionBranchKey.FALSE_BRANCH]?.length) {
      return;
    }
    return {
      true: (data?.[WorkflowConditionBranchKey.TRUE_BRANCH] || []).map((d: any) => d.toJSON()),
      false: (data?.[WorkflowConditionBranchKey.FALSE_BRANCH] || []).map((d: any) => d.toJSON()),
    };
  },
};

/**
 * AutomationAction
 * @export
 * @class AutomationAction
 */
@Serializable
export class AutomationAction {
  @JsonProperty({ name: 'id', excludeToJson: true })
  public id: string = undefined;

  @JsonProperty({ name: 'action_data', customConverter: actionDataConverter })
  public actionData = undefined;

  @JsonProperty({ name: 'uiId', excludeToJson: true, customConverter: preserveDefaultValueConverter })
  public uiId: string = uniqueId(WORKFLOW_ACTION_KEY_PREFIX);

  public parentInfo?: { uiId: string; branch?: WorkflowConditionBranch };

  /**
   * constructor
   * @param {Array<Partial<AutomationAction>>} args
   * @memberof AutomationAction
   */
  constructor(...args: Array<Partial<AutomationAction>>) {
    Object.assign(this, ...args);
  }

  /**
   * isAction
   * @type {boolean}
   */
  public get isAction(): boolean {
    return this.actionData?.type === ActionTypeV2.OPENAPI;
  }

  /**
   * isGroup
   * @type {boolean}
   */
  public get isGroup(): boolean {
    return this.actionData?.type === ActionTypeV2.SYSTEM_GROUP;
  }

  /**
   * isCondition
   * @type {boolean}
   */
  public get isCondition(): boolean {
    return this.actionData?.type === ActionTypeV2.SYSTEM_SWITCH;
  }

  /**
   * isStopWorkflow
   * @type {boolean}
   */
  public get isStopWorkflow(): boolean {
    return this.actionData?.type === ActionTypeV2.SYSTEM_TERMINATE;
  }

  /**
   * isValid
   * @param {Function} validityFn
   * @returns {boolean}
   * @memberof AutomationAction
   */
  public isValid(validityFn: (automationAction: AutomationAction) => true): boolean {
    if (this.isStopWorkflow) {
      return true;
    }
    if (this.isAction) {
      return validityFn(this);
    }

    const validityCheck = (automationAction: AutomationAction) => automationAction.isValid(validityFn);

    if (this.isGroup) {
      return this?.actionData?.groupedActions?.every(validityCheck);
    }

    if (this.isCondition) {
      return (
        this?.actionData?.decisionCases?.[WorkflowConditionBranchKey.TRUE_BRANCH]?.every(validityCheck) &&
        this?.actionData?.decisionCases?.[WorkflowConditionBranchKey.FALSE_BRANCH]?.every(validityCheck)
      );
    }
  }

  /**
   * create
   * @static
   * @param {Partial<AutomationAction>} existing
   * @param {Partial<ActionData>} actionMethod
   * @returns {AutomationAction}
   * @memberOf AutomationAction
   */
  public static create(existing: Partial<AutomationAction> = {}, actionMethod?: Partial<ActionData>): AutomationAction {
    return new AutomationAction({
      ...existing,
      actionData: new ActionData(existing.actionData, actionMethod),
    });
  }
}

/**
 * ConditionDecision
 * @export
 * @class ConditionDecision
 */
@Serializable
export class ConditionDecision {
  @JsonProperty({ name: 'true', cls: AutomationAction })
  public [WorkflowConditionBranchKey.TRUE_BRANCH]: AutomationAction[] = undefined;

  @JsonProperty({ name: 'false', cls: AutomationAction })
  public [WorkflowConditionBranchKey.FALSE_BRANCH]: AutomationAction[] = undefined;

  /**
   * constructor
   * @param {Array<Partial<ConditionDecision>>} args
   * @memberof ConditionDecision
   */
  constructor(...args: Array<Partial<ConditionDecision>>) {
    Object.assign(this, ...args);
  }

  /**
   * initConditionBranch
   * @memberof ConditionDecision
   */
  @Deserialize.after()
  public initConditionBranch?() {
    if (!Array.isArray(this[WorkflowConditionBranchKey.TRUE_BRANCH])) {
      this[WorkflowConditionBranchKey.TRUE_BRANCH] = [];
    }

    if (!Array.isArray(this[WorkflowConditionBranchKey.FALSE_BRANCH])) {
      this[WorkflowConditionBranchKey.FALSE_BRANCH] = [];
    }
  }
}

/**
 * ActionData
 * @exports
 * @class ActionData
 */
@Serializable
export class ActionData {
  @JsonProperty({ name: 'type', customConverter: enumConverterFactory(ActionTypeV2) })
  public type: ActionTypeV2 = ActionTypeV2.OPENAPI;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('action_template_id')
  public actionTemplateId: string = undefined;

  @JsonProperty('connector_config_id')
  public connectorConfigId: string = undefined;

  @JsonProperty({ name: 'data_by_section', customConverter: settingsBySectionConverter })
  public settingsBySection: any = undefined;

  @JsonProperty({ name: 'data', excludeToJson: true })
  public settings: any = undefined;

  @JsonProperty({ name: 'grouped_actions', customConverter: groupConverter })
  public groupedActions: AutomationAction[] = undefined;

  @JsonProperty({ name: 'expression', cls: AutomationCondition })
  public expression: AutomationCondition = undefined;

  @JsonProperty({ name: 'evaluator_type', customConverter: preserveDefaultValueConverter })
  public evaluatorType: string = 'JSONPATH';

  @JsonProperty({ name: 'decision_cases', customConverter: decisionCasesConverter })
  public decisionCases: ConditionDecision = undefined;

  /**
   * constructor
   * @param {Array<Partial<ActionData>>} args
   * @memberof ActionData
   */
  constructor(...args: Array<Partial<ActionData>>) {
    Object.assign(this, ...args);
  }

  /**
   * updateConditionType
   * @memberof ActionData
   */
  @Deserialize.after()
  public updateConditionType() {
    if (this.expression) {
      this.expression.conditionType = undefined;
    }
  }
}
