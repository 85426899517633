/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserDescriptor } from './user-descriptor.model';

/**
 * AccessConfiguration
 * @export
 * @class AccessConfiguration
 */
@Serializable
export class AccessConfiguration {
  @JsonProperty({ name: 'permit', cls: UserDescriptor })
  public permit: UserDescriptor[] = [];

  @JsonProperty({ name: 'deny', cls: UserDescriptor })
  public deny: UserDescriptor[] = [];

  /**
   * Creates an instance of AccessConfiguration.
   * @param {Array<Partial<AccessConfiguration>>} args
   * @memberof AccessConfiguration
   */
  constructor(...args: Array<Partial<AccessConfiguration>>) {
    Object.assign(this, ...args);
  }
}
