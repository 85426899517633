/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * DevicesProfileTabType
 * @export
 * @enum {string}
 */
export enum DevicesProfileTabType {
  EXPERIENCE = 'experience',
  OVERVIEW = 'overview',
  PROPERTIES = 'properties',
  SECURITY = 'security',
}
