/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedRequest, Serializable, SortOn } from '@dpa/ui-common';

import { LOAD_STATE } from './load-state.enum';
import { SearchTerm } from './search-term.model';

/**
 * Object containing the properties related to issuing a search.
 * @export
 * @class GenericSearchRequest
 * @extends {PagedRequest}
 */
@Serializable
export class GenericSearchRequest extends PagedRequest {
  /**
   * Do not pass this object if searchTerm.value is falsy
   */
  @JsonProperty({ name: 'search_term', cls: SearchTerm })
  public searchTerm?: SearchTerm = undefined;

  /**
   * Responses return table data
   * fields is the list of column names we want in that table
   */
  @JsonProperty('fields')
  public fields?: string[] = undefined;

  /**
   * The optional filter query if supported
   */
  @JsonProperty('filter')
  public filter?: string = undefined;

  /**
   * The optional flag to ignore case in search query
   */
  @JsonProperty('ignore_case')
  public ignoreCase?: boolean = undefined;

  /**
   * The list of fields on which to sort the paged request.
   */
  @JsonProperty({ name: 'sort_ons', cls: SortOn })
  public sortOns?: SortOn[] = undefined;

  @JsonProperty({ name: 'load_state', excludeToJson: true })
  public loadState?: LOAD_STATE = undefined;

  @JsonProperty({ name: 'search_terms', cls: SearchTerm })
  public searchTerms?: SearchTerm[] = undefined;

  /**
   * Creates an instance of GenericSearchRequest.
   * @param {any} args
   * @memberof GenericSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}
