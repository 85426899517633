/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';

/**
 * Org Settings Model
 *
 * @export
 * @class OrgSettings
 */
@Serializable
export class OrgSettings {
  @JsonProperty({ name: 'preferences', customConverter: defaultValueConverterFactory() })
  public preferences: GenericObject = undefined;

  /**
   * Creates an instance of OrgSettings
   * @param {Array<Partial<OrgSettings>>} args - initialization arguments for OrgSettings object
   * @memberof OrgSettings
   */
  constructor(...args: Array<Partial<OrgSettings>>) {
    Object.assign(this, ...args);
  }
}
