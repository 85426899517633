/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * WidgetSequence
 * @export
 * @enum {number}
 */
export enum WidgetSequence {
  MAIN,
  OVERLAY,
}
