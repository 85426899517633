/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * NetworkInsightEvent
 * @export
 * @class NetworkInsightEvent
 */
@Serializable
export class NetworkInsightEvent {
  @JsonProperty('request_url_host')
  public requestUrlHost: string = undefined;

  @JsonProperty('error_count_http')
  public errorCountHttp: number = undefined;

  @JsonProperty('error_count_sdk')
  public errorCountSdk: number = undefined;

  @JsonProperty('request_total')
  public requestTotal: number = undefined;

  @JsonProperty('average_latency_ms')
  public averageLatencyMs: number = undefined;

  @JsonProperty('average_data_in_bytes')
  public averageDataInBytes: number = undefined;

  @JsonProperty('average_data_out_bytes')
  public averageDataOutBytes: number = undefined;

  /**
   * totalErrorCount
   * @returns {number}
   * @memberof NetworkInsightEvent
   */
  public get totalErrorCount(): number {
    return this.errorCountHttp + this.errorCountSdk;
  }

  /**
   * errorRate
   * @returns {number}
   * @memberof NetworkInsightEvent
   */
  public get errorRate(): number {
    return this.totalErrorCount / this.requestTotal;
  }
}

/**
 * NetworkInsightsSearchResponse
 * @export
 * @class NetworkInsightsSearchResponse
 */
@Serializable
export class NetworkInsightsSearchResponse {
  @JsonProperty({ name: 'data', cls: NetworkInsightEvent })
  public results: NetworkInsightEvent[] = undefined;

  /**
   * NetworkInsightsSearchResponse.
   * @param {Array<Partial<NetworkInsightsSearchResponse>>} args
   * @memberof NetworkInsightsSearchResponse
   */
  constructor(...args: Array<Partial<NetworkInsightsSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
