/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * AutomationSearchRequest
 * @export
 * @class AutomationSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class AutomationSearchRequest extends GenericSearchRequest {
  /**
   * Creates an instance of AutomationSearchRequest.
   * @param {Array<Partial<AutomationSearchRequest>>} args
   * @memberof AutomationSearchRequest
   */
  constructor(...args: Array<Partial<AutomationSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
