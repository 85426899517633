/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
/**
 * IntelligenceServiceTypeLabel
 * @export
 * @enum {string}
 */
export enum IntelligenceServiceTypeLabel {
  HORIZON = 'Horizon',
  HORIZON_FULL = 'Horizon',
  INTELLIGENCE = 'Intelligence',
  INTELLIGENCE_FULL = 'Workspace ONE Intelligence',
  WS1_ACCESS = 'Access and Identity',
  WS1_ACCESS_FULL = 'Workspace ONE Access and Identity Services',
  WS1_UEM = 'UEM',
  WS1_UEM_FULL = 'UEM',
  WS1_CLOUD = 'Cloud',
  WS1_CLOUD_FULL = 'Cloud',
}
