/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumUpperCaseConverterFactory } from '@ws1c/intelligence-models/converters';
import { MarketplaceResourceAggregateType } from './marketplace-resource-aggregate-type.enum';

/**
 * MarketplaceResourceAggregate
 * @export
 * @class MarketplaceResourceAggregate
 */
@Serializable
export class MarketplaceResourceAggregate {
  @JsonProperty({ name: 'event_type', customConverter: enumUpperCaseConverterFactory(MarketplaceResourceAggregateType) })
  public eventType: string = undefined;

  @JsonProperty('count')
  public count?: number = undefined;

  /**
   * Creates an instance of MarketplaceResourceAggregate.
   * @param {Array<Partial<MarketplaceResourceAggregate>>} args
   * @memberof MarketplaceResourceAggregate
   */
  constructor(...args: Array<Partial<MarketplaceResourceAggregate>>) {
    Object.assign(this, ...args);
  }
}
