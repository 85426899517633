/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { AggregationColumn } from './aggregation-column.model';
import { Column } from './column.model';

/**
 * AggregationAttributesResponse
 * @export
 * @class AggregationAttributesResponse
 */
@Serializable
export class AggregationAttributesResponse {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty({ name: 'attribute_list', cls: Column })
  public attributeList: Column[] = undefined;

  @JsonProperty({ name: 'aggregation_attribute_list', cls: AggregationColumn })
  public aggregationAttributesList: AggregationColumn[] = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', customConverter: defaultValueConverterFactory() })
  public filterConditionsNestedRules?: GenericObject = undefined;

  /**
   * Creates an instance of AggregationAttributesResponse.
   * @param {Array<Partial<AggregationAttributesResponse>>} args
   * @memberof AggregationAttributesResponse
   */
  constructor(...args: Array<Partial<AggregationAttributesResponse>>) {
    Object.assign(this, ...args);
  }
}
