/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { SolutionConfigType } from '@ws1c/intelligence-models/solution/solution.enum';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';

/**
 * SolutionStepConfig
 * @export
 * @class SolutionStepConfig
 */
@Serializable
export class SolutionStepConfig extends UserTouchDetails {
  @JsonProperty('solution')
  public type: SolutionConfigType = undefined;

  @JsonProperty({ name: 'config', customConverter: defaultValueConverterFactory() })
  public config: any = undefined;

  /**
   * SolutionStepConfig
   * @param {Array<Partial<SolutionStepConfig>>} args
   * @memberof SolutionStepConfig
   */
  constructor(...args: Array<Partial<SolutionStepConfig>>) {
    super(...args);
    Object.assign(this, ...args);
  }
}
