/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Connector } from './connector.model';

/**
 * ConnectorsWithActionsResponse
 * @export
 * @class ConnectorsWithActionsResponse
 */
@Serializable
export class ConnectorsWithActionsResponse {
  @JsonProperty({ name: 'data', cls: Connector })
  public data: Connector[] = [];

  /**
   * Creates an instance of ConnectorsWithActionsResponse.
   * @param {...Array<Partial<ConnectorsWithActionsResponse>>} args
   * @memberof ConnectorsWithActionsResponse
   */
  constructor(...args: Array<Partial<ConnectorsWithActionsResponse>>) {
    Object.assign(this, ...args);
  }
}
