/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, JsonProperty, Serializable } from '@dpa/ui-common';

import { DashboardConfig } from '@ws1c/intelligence-models/dashboard/dashboard.config';
import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';
import { CveDetailPageType } from './cve.enum';

/**
 * Cve
 * @export
 * @class Cve
 */
@Serializable
export class Cve {
  @JsonProperty('cve_id')
  public id: string = undefined;

  @JsonProperty('cvss_score_v3')
  public score: number = undefined;

  @JsonProperty('cve_published_on')
  public publishedOn: number = undefined;

  @JsonProperty('devices_affected')
  public devicesAffected: number = undefined;

  @JsonProperty('knowledge_base_ids')
  public knowledgeBaseIds: string[] = undefined;

  /**
   * Creates an instance of Cve.
   * @param {Partial<Cve>} args
   * @memberof Cve
   */
  constructor(args?: Partial<Cve>) {
    Object.assign(this, args);
  }
}

/**
 * CveSearchRequest
 * @export
 * @class CveSearchRequest
 */
@Serializable
export class CveSearchRequest extends GenericSearchRequest {
  @JsonProperty('cve_id_filter')
  public cveIdFilter: string[] = [];

  @JsonProperty('minimum_cvss_score')
  public minimumCvssScore: number = 0;

  @JsonProperty('size_limit')
  public sizeLimit: number = DashboardConfig.DEFAULT_CVS_SEARCH_REQUEST_SIZE_LIMIT;

  /**
   * Creates an instance of CveSearchRequest.
   * @param {any} args
   * @memberof CveSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * CveSearchResponse
 * @export
 * @class CveSearchResponse
 */
@Serializable
export class CveSearchResponse {
  @JsonProperty({ name: 'data', cls: Cve })
  public data: Cve[] = undefined;

  /**
   * constructor
   * @param {Array<Partial<CveSearchResponse>>} args
   * @memberof CveSearchResponse
   */
  constructor(...args: Array<Partial<CveSearchResponse>>) {
    Object.assign(this, ...args);
  }
}

export interface CveDetailPage {
  cve: Cve;
  cveDetailPageType: CveDetailPageType;
  detailsReturnCrumbs: BreadCrumb[];
}
