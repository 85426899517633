/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserAccess } from './user-access.model';

/**
 * ShareAccess
 * @export
 * @class ShareAccess
 */
@Serializable
export class ShareAccess {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty({ name: 'details', cls: UserAccess })
  public details: UserAccess[] = undefined;

  /**
   * Creates an instance of ShareAccess.
   * @param {Array<Partial<ShareAccess>>} args
   * @memberof ShareAccess
   */
  constructor(...args: Array<Partial<ShareAccess>>) {
    Object.assign(this, ...args);
  }
}
