/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Feature Step Model
 * @export
 * @class FeatureStep
 */
@Serializable
export class FeatureStep {
  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('doc_url')
  public docUrl: string = undefined;

  @JsonProperty('show_in_home_screen')
  public showInHomeScreen: boolean = undefined;

  @JsonProperty('tech_zone_url')
  public techZoneUrl: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  /**
   * Creates an instance of FeatureStep
   * @param {any} args - initialization arguments for object
   * @memberof FeatureStep
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
