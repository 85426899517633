/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DataExplorerDashboardType
 * @export
 * @enum {string}
 */
export enum DataExplorerDashboardType {
  DATA_EXPLORER = 'DATA_EXPLORER',
}

/**
 * DataExploreWidgetSubtype
 * @export
 * @enum {number}
 */
export enum DataExplorerWidgetSubtype {
  DEFAULT_WIDGET = 'DEFAULT_WIDGET',
}
