/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { MetaFormFieldPresentationType } from './meta-form-field-presentation-type.enum';

/**
 * MetaFormConfig
 *
 * @export
 * @class MetaFormConfig
 */
export class MetaFormConfig {
  public static readonly inputTypeByPresentationType: Partial<Record<MetaFormFieldPresentationType, string>> = {
    [MetaFormFieldPresentationType.INPUT]: 'text',
    [MetaFormFieldPresentationType.TEXTAREA]: 'text',
    [MetaFormFieldPresentationType.URL]: 'url',
    [MetaFormFieldPresentationType.PHONE]: 'tel',
    [MetaFormFieldPresentationType.NUMBER]: 'number',
    [MetaFormFieldPresentationType.PASSWORD]: 'password',
    [MetaFormFieldPresentationType.EMAIL]: 'email',
  };
}
