/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { TrendDefinition } from '@ws1c/intelligence-models/dashboard/trend-definition.model';
import { WidgetPosition } from '@ws1c/intelligence-models/dashboard/widget-position.model';
import { Template } from '@ws1c/intelligence-models/template/template.model';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { getUniqueId } from '@ws1c/intelligence-models/utils';

/**
 * Aggregation Widget Template Model Object.
 *
 * @export
 * @class WidgetTemplate
 */
@Serializable
export class WidgetTemplate extends Template {
  @JsonProperty('i18n_name')
  public name: string = undefined;

  @JsonProperty('i18n_description')
  public description: string = undefined;

  @JsonProperty('chart_type')
  public chartType: string = undefined;

  @JsonProperty({ name: 'top_left', cls: WidgetPosition })
  public topLeft: WidgetPosition = undefined;

  @JsonProperty({ name: 'bottom_right', cls: WidgetPosition })
  public bottomRight: WidgetPosition = undefined;

  @JsonProperty({ name: 'trend_definition', cls: TrendDefinition })
  public trendDefinition: TrendDefinition = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * Creates an instance of WidgetTemplate.
   * @param {any} args
   * @memberof WidgetTemplate
   */
  constructor(...args) {
    super(...args);
    Object.assign(this, ...args);
  }

  /**
   * Get integration from trendDefinition
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get integration(): string {
    return this.trendDefinition?.integration ?? '';
  }

  /**
   * getter for overlayChartType
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get overlayChartType(): string {
    return this.chartType;
  }

  /**
   * Get entity from trendDefinition
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get entity(): string {
    return this.trendDefinition?.entity ?? '';
  }

  /**
   * templateId
   *
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get templateId(): string {
    return this.id || this.name;
  }

  /**
   * label
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get label(): string {
    return this.name;
  }

  /**
   * templateKey
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get templateKey(): string {
    return this.id || this.name;
  }

  /**
   * marketplaceResourceId
   * @readonly
   * @type {string}
   * @memberof WidgetTemplate
   */
  public get marketplaceResourceId(): string {
    const name = this.templateKey.split('.').pop();
    return getUniqueId(this.integration, this.entity, name);
  }
}
