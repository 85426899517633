/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AvailableSKUService } from './available-sku-service.model';
import { FeatureSetup } from './feature-setup.model';
import { IntegratedService } from './integrated-service.model';

/**
 * Integrated Services Response Model
 * @export
 * @class IntegratedServicesResponse
 */
@Serializable
export class IntegratedServicesResponse {
  @JsonProperty({ name: 'available_services', cls: AvailableSKUService })
  public availableSKUServices: AvailableSKUService[] = undefined;

  @JsonProperty({ name: 'basic_features', cls: FeatureSetup })
  public basicFeatures: FeatureSetup[] = undefined;

  @JsonProperty({ name: 'services', cls: IntegratedService })
  public services: IntegratedService[] = undefined;

  /**
   * Creates an instance of IntegratedServicesResponse
   * @param {Array<Partial<IntegratedServicesResponse>>} args - initialization arguments for object
   * @memberof IntegratedServicesResponse
   */
  constructor(...args: Array<Partial<IntegratedServicesResponse>>) {
    Object.assign(this, ...args);
  }
}
