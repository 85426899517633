/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { AccountFeature } from './account-feature.enum';
import { AccountPermission } from './account-perm.enum';

export const FEATURES = [
  AccountFeature.DASHBOARDS,
  AccountFeature.AUTOMATIONS,
  AccountFeature.REPORTS,
  AccountFeature.INTEGRATIONS,
  AccountFeature.SETTINGS,
];

export const roleToFeatureMapping: any = {
  COMMUNICATOR: {
    [AccountFeature.DASHBOARDS]: AccountPermission.READ_WRITE,
    [AccountFeature.REPORTS]: AccountPermission.READ_WRITE,
  },
  AUTOMATOR: {
    [AccountFeature.AUTOMATIONS]: AccountPermission.READ_WRITE,
    [AccountFeature.INTEGRATIONS]: AccountPermission.RESTRICTED_READ_WRITE,
  },
  MODERATOR: {
    [AccountFeature.DASHBOARDS]: AccountPermission.READ_MANAGE,
    [AccountFeature.REPORTS]: AccountPermission.READ_MANAGE,
    [AccountFeature.INTEGRATIONS]: AccountPermission.READ_WRITE,
    [AccountFeature.SETTINGS]: AccountPermission.READ_WRITE,
  },
  AUDITOR: {
    [AccountFeature.DASHBOARDS]: AccountPermission.READ,
    [AccountFeature.REPORTS]: AccountPermission.READ,
    [AccountFeature.AUTOMATIONS]: AccountPermission.READ,
    [AccountFeature.INTEGRATIONS]: AccountPermission.READ,
    [AccountFeature.SETTINGS]: AccountPermission.READ,
  },
};
