/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import { mapValues } from 'lodash-es';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';
import { ComposeConfigIndex } from './trend-composer.interface';
import { TrendDefinition } from './trend-definition.model';

export interface TrendDefinitionIndex {
  [key: string]: TrendDefinition;
}

/**
 * trendDefinitionIndexConverterFactory
 * @returns {CustomConverter}
 */
export function trendDefinitionIndexConverterFactory(): CustomConverter {
  return {
    fromJson(trendDefinitionIndex: object): object {
      return mapValues(trendDefinitionIndex, (trendDefinitionData: any) => {
        return deserialize(TrendDefinition, trendDefinitionData);
      });
    },
    toJson(data: TrendDefinitionIndex): object {
      return data;
    },
  } as CustomConverter;
}

/**
 * CompositeTrendDefinition
 * @export
 * @class CompositeTrendDefinition
 */
@Serializable
export class CompositeTrendDefinition {
  @JsonProperty('main_name')
  public mainName: string = undefined;

  @JsonProperty({ name: 'compose_configs', customConverter: defaultValueConverterFactory() })
  public composeConfigs: ComposeConfigIndex = undefined;

  @JsonProperty({ name: 'trend_definitions', customConverter: trendDefinitionIndexConverterFactory() })
  public trendDefinitions: TrendDefinitionIndex = undefined;

  /**
   * CompositeTrendDefinition.
   * @param {Array<Partial<CompositeTrendDefinition>>} args
   * @memberof CompositeTrendDefinition
   */
  constructor(...args: Array<Partial<CompositeTrendDefinition>>) {
    Object.assign(this, ...args);
  }
}
