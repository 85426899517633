/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

export enum AutomationDialogMode {
  CLOSE,
  COPY,
  DELETE,
  EDIT_CONFIRMATION,
  PREVIEW,
  RENAME,
  RUN,
  SAVE_AND_ENABLE,
  SAVE_DRAFT,
  TEST,
  TOGGLE,
  UPDATE,
}
