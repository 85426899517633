/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

export enum PendoEventInsightsSelectedSource {
  // Insights
  IMPACTED_DEVICE_NAME = 'Impacted Device Name',
  CONFIRM_CREATE_INCIDENT = 'Confirm Create Incident',
  CREATE_INCIDENT = 'Create Incident',
  VIEW_IMPACTED_DEVICES = 'View Impacted Devices',
  DISMISS_INSIGHT = 'Dismiss Insight',
  INITIATE_SEARCH = 'Initiate Search',
  MAKE_INSIGHT_ACTIVE = 'Make Insight Active',
  OPEN_INSIGHT = 'Open Insight',
  TOGGLE_CUSTOM_FILTER = 'Toggle Custom Filter',
  VIEW_IMPACT_BY_GROUP = 'View Impact By Group',
}
