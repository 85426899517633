/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * SolutionSettingMetricHolder
 *
 * @export
 * @class SolutionSettingMetricHolder
 */
@Serializable
export abstract class SolutionSettingMetricHolder {
  @JsonProperty('type')
  public type: string = undefined;
}
