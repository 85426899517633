/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, JsonProperty, Serializable } from '@dpa/ui-common';
import { flatten, map } from 'lodash-es';

/**
 * suggestionSearchResponseFlattener
 * @export
 * @type {CustomConverter}
 */
export const suggestionSearchResponseFlattener: CustomConverter = {
  fromJson(suggestionStringsByGroupName: { [key: string]: string }): any[] {
    return flatten(
      map(suggestionStringsByGroupName, (suggestionStrings: string[], groupName: string) => {
        return suggestionStrings.map((suggestionString: string) => {
          return {
            value: suggestionString,
            groupName: groupName === 'all' ? undefined : groupName,
          } as SuggestionSearchItem;
        });
      }),
    );
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * SuggestionSearchResponse
 * @export
 * @class SuggestionSearchResponse
 */
@Serializable
export class SuggestionSearchResponse {
  @JsonProperty({ name: 'suggestions', customConverter: suggestionSearchResponseFlattener })
  public values: SuggestionSearchItem[] = undefined;
}

/**
 * SuggestionSearchItem
 * @export
 */
export interface SuggestionSearchItem {
  value: string;
  groupName: string;
}
