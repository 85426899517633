/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { TrialStatus } from './trial-status.enum';

/**
 * Trial Activtion Detail Model
 *
 * @export
 * @class TrialActivationDetail
 */
@Serializable
export class TrialActivationDetail {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('request_id')
  public requestId: string = undefined;

  @JsonProperty({ name: 'request_status', customConverter: enumConverterFactory(TrialStatus), excludeToJson: true })
  public requestStatus: TrialStatus = undefined;

  /**
   * Creates an instance of TrialActivationDetail
   * @param {Array<Partial<TrialActivationDetail>>} args - Initialization arguments for object
   * @memberof TrialActivationDetail
   */
  constructor(...args: Array<Partial<TrialActivationDetail>>) {
    Object.assign(this, ...args);
  }
}
