/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { Comment } from './comment.model';

/**
 * CommentsResponse
 * @export
 * @class CommentsResponse
 * @implements PagedResponse
 */
@Serializable
export class CommentsResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: Comment })
  public results: Comment[] = [];

  /**
   * Creates an instance of CommentsResponse.
   * @param {...Array<Partial<CommentsResponse>>} args
   * @memberof CommentsResponse
   */
  constructor(...args: Array<Partial<CommentsResponse>>) {
    Object.assign(this, ...args);
  }
}
