/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemHealth
 * @exports
 * @enum {string}
 */
export enum DeemHealth {
  GOOD = 'Good',
  NEUTRAL = 'Neutral',
  POOR = 'Poor',
}
