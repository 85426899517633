/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Org Disabled Code
 *
 * @export
 * @enum {string}
 */
export enum OrgDisabledCode {
  INVITE_REDEMPTION_FAILED = 'invite.redemption.failed',
  INVITE_REDEMPTION_FAILED_RESTRICTED_TENANT = 'invite.redemption.failed.restricted.tenant',
  INVITE_REDEMPTION_FAILED_MULTIPLE_UEM_TENANT = 'invite.redemption.failed.multiple.uem.tenant',
  LICENSE_EXPIRED = 'org.license.expired',
  TRIAL_EXPIRED = 'org.trial.expired',
  ORG_CONFIGURATION_EXIST = 'org.configuration.exist',
  ORG_DISABLED = 'org.disabled',
}
