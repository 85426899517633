/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { AutomationCondition } from '@ws1c/intelligence-models/automation/automation-condition.model';
import { EvaluationType } from '@ws1c/intelligence-models/automation/evaluation-type.enum';
import { Template } from '@ws1c/intelligence-models/template/template.model';
import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';

/**
 * AutomationTemplate
 * @export
 * @class AutomationTemplate
 */
@Serializable
export class AutomationTemplate extends Template {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = '';

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('entity')
  public targetEntity: string = '';

  @JsonProperty({ name: 'service_types', excludeToJson: true })
  public serviceTypes: string[] = undefined;

  @JsonProperty({ name: 'connector_ids', excludeToJson: true })
  public connectorIds: string[] = undefined;

  @JsonProperty({ name: 'condition', cls: AutomationCondition })
  public condition: AutomationCondition = undefined;

  // Used when programmatically selecting template
  public evaluationType: EvaluationType;
  public params: GenericObject;

  /**
   * Getter to get unique categoryId
   *
   * @readonly
   * @type {string}
   * @memberof AutomationTemplate
   */
  public get categoryId(): string {
    return getUniqueId(this.integration, this.targetEntity);
  }

  /**
   * Creates an instance of AutomationTemplate
   * @param {Array<Partial<AutomationTemplate>>} args
   * @memberof AutomationTemplate
   */
  constructor(...args: Array<Partial<AutomationTemplate>>) {
    super(...args);
    Object.assign(this, ...args);
  }

  /**
   * templateId
   *
   * @readonly
   * @type {string}
   * @memberof AutomationTemplate
   */
  public get templateId(): string {
    return this.id || this.name;
  }

  /**
   * templateKey
   * @readonly
   * @type {string}
   * @memberof AutomationTemplate
   */
  public get templateKey(): string {
    return this.id || this.name;
  }

  /**
   * entity
   * @readonly
   * @type {string}
   * @memberof AutomationTemplate
   */
  public get entity(): string {
    return this.targetEntity;
  }
}
