/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import {
  BaseRuleGroup,
  CustomConverter,
  deserialize,
  GenericObject,
  JsonProperty,
  RuleGroupOperator,
  Serializable,
  serialize,
} from '@dpa/ui-common';
import { forEach, groupBy, isEmpty } from 'lodash-es';

import { DashboardConfig } from '@ws1c/intelligence-models/dashboard/dashboard.config';
import { ORG_GROUP_ATTRIBUTES_MAPPING } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { Column, ColumnIndex } from '@ws1c/intelligence-models/integration-meta/column.model';
import { Tag } from '@ws1c/intelligence-models/template/tag.model';
import { getEntityFromFQN, getFQN } from '@ws1c/intelligence-models/utils/attributes-utils';
import { FilterRule } from './filter-rule.model';
import { SuggestionFilterBy } from './suggestion';

const rulesConverter: CustomConverter = {
  fromJson(data: any): any {
    return data?.map((rule) => {
      if (rule.rules) {
        return deserialize(RuleGroup, rule);
      } else {
        return deserialize(FilterRule, rule);
      }
    });
  },
  toJson(data: any): any {
    return data?.map((rule) => {
      return serialize(rule);
    });
  },
};

/**
 * RuleGroup
 * @export
 * @class RuleGroup
 */
@Serializable
export class RuleGroup extends BaseRuleGroup<FilterRule, RuleGroup> {
  public static readonly FILTER_CONDITIONS_FOR_TAGS: string[] = [FilterRule.FILTER_CONDITION.includes, FilterRule.FILTER_CONDITION.equals];

  @JsonProperty({ name: 'rules', customConverter: rulesConverter })
  public rules: Array<FilterRule | RuleGroup> = [];

  @JsonProperty({ name: 'operator' })
  public operator: string = RuleGroupOperator.AND;

  /**
   * constructor
   * @param {Partial<RuleGroup>} arg
   * @memberof RuleGroup
   */
  constructor(arg?: Partial<RuleGroup>);
  /**
   * constructor
   * @param {Array<FilterRule | RuleGroup>} rules
   * @param {string} operator
   * @memberof RuleGroup
   */
  constructor(rules: Array<RuleGroup | FilterRule>, operator?: RuleGroupOperator | string);
  /**
   * constructor
   * @param {Partial<RuleGroup> | Array<RuleGroup | FilterRule>} rules
   * @param {string} operator
   * @memberof RuleGroup
   */
  constructor(rules?: Partial<RuleGroup> | Array<RuleGroup | FilterRule>, operator: RuleGroupOperator | string = RuleGroupOperator.AND) {
    super();
    if (rules) {
      if (Array.isArray(rules)) {
        this.rules = rules;
        this.operator = operator;
      } else {
        Object.assign(this, rules);
      }
    }
  }

  /**
   * isValidOrEmpty
   * @param {GenericObject} allColumnsByName
   * @returns {boolean}
   * @memberof RuleGroup
   */
  public isValidOrEmpty(allColumnsByName: GenericObject): boolean {
    return this.rules.every((rule: FilterRule | RuleGroup) => rule.isValidOrEmpty(allColumnsByName));
  }

  /**
   * isEmpty
   * @param {ColumnIndex} allColumnsByName
   * @returns {boolean}
   * @memberof RuleGroup
   */
  public isEmpty(allColumnsByName: ColumnIndex): boolean {
    return this.rules.every((rule: FilterRule | RuleGroup) => rule.isEmpty(allColumnsByName));
  }

  /**
   * hasInvalidAndNotEmptyRules
   * @param {GenericObject} allColumnsByName
   * @returns {boolean}
   * @memberof RuleGroup
   */
  public hasInvalidAndNotEmptyRules(allColumnsByName: GenericObject): boolean {
    return this.rules.some((rule: FilterRule | RuleGroup) => {
      if (RuleGroup.isRuleGroup(rule)) {
        return rule.hasInvalidAndNotEmptyRules(allColumnsByName);
      }
      return !rule.isValid(allColumnsByName) && !rule.isEmpty(allColumnsByName);
    });
  }

  /**
   * ruleNames
   * @readonly
   * @type {string[]}
   * @memberof RuleGroup
   */
  public get ruleNames(): string[] {
    return this.rules?.reduce((names: string[], rule: FilterRule | RuleGroup) => {
      if (rule instanceof FilterRule) {
        names.push(rule.attribute);
      } else {
        names = names.concat(rule.ruleNames);
      }
      return names;
    }, []);
  }

  /**
   * getUniqueRuleGroupEntities
   * @readonly
   * @type {Set<string>}
   * @memberof RuleGroup
   */
  public getUniqueRuleGroupEntities(): Set<string> {
    return this.rules?.reduce((entities: Set<string>, rule: FilterRule | RuleGroup) => {
      if (RuleGroup.isRuleGroup(rule)) {
        return new Set([...rule.getUniqueRuleGroupEntities(), ...entities]);
      }
      if (rule.attribute) {
        entities.add(getEntityFromFQN(rule.attribute));
      }
      return entities;
    }, new Set<string>());
  }

  /**
   * isCrossCategory
   * @readonly
   * @type {boolean}
   * @memberof RuleGroup
   */
  public get isCrossCategory(): boolean {
    const entities = new Set();
    const integrations = new Set();
    this.ruleNames.forEach((ruleName: string) => {
      const [entity, integration] = ruleName?.split('.');
      entities.add(entity);
      integrations.add(integration);
    });
    return entities.size > 1 || integrations.size > 1;
  }

  /**
   * getFilterCount
   * @param {GenericObject} allColumnsByName
   * @param {boolean} [ignoreValidation=false]
   * @returns {number}
   * @memberof RuleGroup
   */
  public getFilterCount(allColumnsByName: GenericObject, ignoreValidation = false): number {
    let count = 0;

    if (!this.rules?.length || (!ignoreValidation && !allColumnsByName)) {
      return count;
    }

    for (const ruleOrRuleGroup of this.rules) {
      if (RuleGroup.isRuleGroup(ruleOrRuleGroup)) {
        count += ruleOrRuleGroup.getFilterCount(allColumnsByName, ignoreValidation);
      } else {
        if (ignoreValidation) {
          count++;
        }
        if (!ignoreValidation && ruleOrRuleGroup.isValid(allColumnsByName)) {
          count++;
        }
      }
    }
    return count;
  }

  /**
   * isRuleGroup
   * @param {GenericObject} group
   * @returns {boolean}
   * @memberof RuleGroup
   */
  public static isRuleGroup(group: GenericObject): group is RuleGroup {
    return group instanceof RuleGroup;
  }

  /**
   * modifyRuleForStandardDashboardV2
   * @static
   * @param {RuleGroup} ruleGroup
   * @returns {RuleGroup}
   * @memberof RuleGroup
   */
  public static modifyRuleForStandardDashboardV2(ruleGroup: RuleGroup): RuleGroup {
    const rules = ruleGroup?.rules;
    if (!rules) {
      return ruleGroup;
    }
    return new RuleGroup(
      rules.map((rule: FilterRule | RuleGroup) => {
        if (RuleGroup.isRuleGroup(rule)) {
          return RuleGroup.modifyRuleForStandardDashboardV2(rule);
        }
        const attributesFQN = rule.attribute?.split('.');
        if (attributesFQN?.length !== 3 || DashboardConfig.CROSS_ENTITY_INTEGRATIONS.includes(attributesFQN[0])) {
          return rule;
        }
        return new FilterRule({
          ...rule,
          attribute: getFQN(DashboardConfig.Integration, DashboardConfig.Entity, attributesFQN[2]),
        });
      }),
      ruleGroup.operator,
    );
  }

  /**
   * For Custom dashboard global filter the column will be FQN of a particular category
   * This static converts the rule group based on the integration and entity passed
   * @static
   * @param {RuleGroup} ruleGroup
   * @param {string} integration
   * @param {string} entity
   * @param {ColumnIndex} [columnsByName={}]
   * @returns {RuleGroup}
   * @memberof RuleGroup
   */
  public static transformGroupForAllCategories(
    ruleGroup: RuleGroup,
    integration: string,
    entity: string,
    columnsByName: ColumnIndex = {},
  ): RuleGroup {
    const rules = ruleGroup?.rules;
    if (!rules?.every((rule: FilterRule) => rule.attribute)) {
      return ruleGroup;
    }
    return new RuleGroup(
      rules.map((rule: FilterRule | RuleGroup) => {
        if (RuleGroup.isRuleGroup(rule)) {
          return RuleGroup.transformGroupForAllCategories(rule, integration, entity, columnsByName);
        }
        let attribute = rule.attribute?.split('.')[2];
        // Map to the integration specific attributes
        if (!columnsByName[getFQN(integration, entity, attribute)] && ORG_GROUP_ATTRIBUTES_MAPPING[attribute]) {
          attribute = ORG_GROUP_ATTRIBUTES_MAPPING[attribute];
        }
        return new FilterRule({
          ...rule,
          attribute: getFQN(integration, entity, attribute),
        });
      }),
      ruleGroup.operator,
    );
  }

  /**
   * convertTagsToRuleGroup
   * @static
   * @param {Tag[]} filterTags
   * @param {Record<string, Tag>} selectedTagsByName
   * @param {Column[]} columns
   * @param {RuleGroup} [existingRuleGroup]
   * @returns {RuleGroup}
   * @memberof RuleGroup
   */
  public static convertTagsToRuleGroup(
    filterTags: Tag[],
    selectedTagsByName: Record<string, Tag>,
    columns: Column[],
    existingRuleGroup?: RuleGroup,
  ): RuleGroup {
    const tagsbyAttribute: Record<string, Tag[]> = groupBy(Object.values(selectedTagsByName), (tag: Tag) => tag.attribute);
    if (existingRuleGroup) {
      const tagAttributesSet: Set<string> = new Set(filterTags.map((tag: Tag) => tag.attribute));
      existingRuleGroup.rules = existingRuleGroup?.rules.filter((existingRule: FilterRule) => !existingRule.isMissingFields());
      if (columns.some((column: Column) => tagAttributesSet.has(column.attributeName))) {
        existingRuleGroup.rules = existingRuleGroup?.rules.filter(
          (existingRule: FilterRule) => !tagAttributesSet.has(existingRule?.attribute),
        );
      }
    }
    const ruleGroup: RuleGroup = existingRuleGroup ?? new RuleGroup();
    if (isEmpty(tagsbyAttribute)) {
      return ruleGroup;
    }
    const selectedTags = Object.values(selectedTagsByName ?? {});
    if (!selectedTags.length) {
      return ruleGroup;
    }
    forEach(tagsbyAttribute, (tags: Tag[], attribute: string) => {
      const matchedColumn: Column = columns.find((column: Column) => column.attributeName === attribute);
      if (matchedColumn) {
        const rule: FilterRule = FilterRule.convertTagToFilterRule(tags, matchedColumn);
        ruleGroup.rules.push(rule);
      }
    });
    return ruleGroup;
  }

  /**
   * convertRuleGroupToTags
   * If the RuleGroup cannot be converted to tags, return empty array
   * @static
   * @param {RuleGroup} ruleGroup
   * @param {Tag[]} filterTags
   * @returns {Tag[]}
   * @memberof RuleGroup
   */
  public static convertRuleGroupToTags(ruleGroup: RuleGroup, filterTags: Tag[]): Tag[] {
    if (!RuleGroup.canRuleGroupConvertToTags(ruleGroup, filterTags)) {
      return [];
    }
    const tags: Tag[] = [];
    ruleGroup?.rules?.forEach((rule: FilterRule) => {
      tags.push(...FilterRule.convertFilterRuleToTags(rule, filterTags));
    });
    return tags;
  }

  /**
   * convertRuleGroupToSuggestionFilterBy
   * Convert RuleGroup to SuggestionFilterBy array for suggestion API. Due to suggestion API limitation, only filter rules
   * with AND operation can be converted to sugegstion criteria. If it cannot be converted, empty array would be returned.
   * @static
   * @param {RuleGroup} ruleGroup
   * @returns {SuggestionFilterBy[]}
   * @memberof RuleGroup
   */
  public static convertRuleGroupToSuggestionFilterBy(ruleGroup: RuleGroup): SuggestionFilterBy[] {
    const filterBys: SuggestionFilterBy[] = [];
    if (
      !ruleGroup?.rules?.length ||
      !ruleGroup?.rules.every(
        (rule: FilterRule) => rule instanceof FilterRule && RuleGroup.FILTER_CONDITIONS_FOR_TAGS.includes(rule?.condition),
      ) ||
      ruleGroup.operator !== RuleGroupOperator.AND
    ) {
      return [];
    }
    ruleGroup.rules.forEach((rule: FilterRule) => {
      const ruleValues: string[] = rule.data instanceof Array ? rule.data : [rule.data];
      const existFilterBy: SuggestionFilterBy = filterBys.find((item: SuggestionFilterBy) => item.fieldName === rule.attribute);
      if (existFilterBy) {
        existFilterBy.values.push(...ruleValues);
      } else {
        filterBys.push(
          new SuggestionFilterBy({
            fieldName: rule.attribute,
            values: ruleValues,
          }),
        );
      }
    });
    return filterBys;
  }

  /**
   * canRuleGroupConvertToTags
   * The rule group cannot convert to tag in below scenarios:
   * 1) the RuleGroup operator is not AND
   * 2) the RuleGroup has more than one level rules (meaning nested)
   * 3) the RuleGroup contains rule of attribute which is not included in fitlerTags
   * 4) the RuleGroup rules are all about attributes which included in fitlerTags, but
   * the filter conditions are not INCLUDES or EQUAL
   * 5) the RuleGroup rules are all about attributes which included in fitlerTags, filter
   * condition also meets the criteria, but some filter values are not included in filterTags.
   * E.g. Filter Tags: 'Windows', but rule group is "Platform IN 'MacOS'".
   * 6) the RuleGroup contains more than one rule for one attribute. E,g. Device Health
   * Score equals Good AND Device Health Score equals Neutral
   * @static
   * @param {RuleGroup} ruleGroup
   * @param {Tag[]} filterTags
   * @returns {boolean}
   * @memberof RuleGroup
   */
  public static canRuleGroupConvertToTags(ruleGroup: RuleGroup, filterTags: Tag[]): boolean {
    if (!filterTags) {
      return false;
    }
    const tagAttributesSet: Set<string> = new Set(filterTags.map((tag: Tag) => tag.attribute));
    const tagLabelsSet: Set<string> = new Set(filterTags.map((tag: Tag) => tag.label));
    const attributeInRule: Set<string> = new Set();
    if (ruleGroup.operator !== RuleGroupOperator.AND) {
      return false;
    }
    return ruleGroup.rules.every((rule: FilterRule) => {
      const attributeRuleNotDup: boolean = !attributeInRule.has(rule.attribute);
      const ruleValues = rule.data instanceof Array ? rule.data : [rule.data];
      attributeInRule.add(rule.attribute);
      const isTagFilterOnly: boolean =
        tagAttributesSet.has(rule?.attribute) &&
        RuleGroup.FILTER_CONDITIONS_FOR_TAGS.includes(rule?.condition) &&
        ruleValues.every((value: string) => tagLabelsSet.has(value));
      return rule instanceof FilterRule && attributeRuleNotDup && isTagFilterOnly;
    });
  }

  /**
   * getNewRuleGroupWithExtend
   *
   * @param {Partial<RuleGroup>} arg
   * @returns {RuleGroup}
   * @memberof RuleGroup
   */
  public getNewRuleGroupWithExtend(arg: Partial<RuleGroup> = {}): RuleGroup {
    return new RuleGroup({
      ...this,
      ...arg,
    });
  }
}
