/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { ErrorHandlingConfig } from '@ws1c/intelligence-models/automation/error-handling-config.model';
import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { MetaForm, MetaFormField, MetaFormFieldPresentationType, MetaFormSection } from '@ws1c/intelligence-models/meta-form';
import { ConnectorActionMetadata } from './connector-action-metadata.model';
import { ConnectorActionSection } from './connector-action-section.model';
import { ConnectorActionType } from './connector-action-type.enum';
import { JsonSchemaMetadata } from './json-schema-metadata.model';

export const sectionConverter: CustomConverter = {
  fromJson(data: GenericObject): GenericObject {
    if (!data) {
      return;
    }
    return Object.keys(data).reduce((accum, key) => {
      accum[key] = data[key].map((section: GenericObject) => deserialize(ConnectorActionSection, section));
      return accum;
    }, {});
  },
  toJson(): GenericObject {
    return undefined;
  },
};

/**
 * ConnectorAction
 * @export
 * @class ConnectorAction
 */
@Serializable
export class ConnectorAction {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('connector_id')
  public connectorId: string = undefined;

  @JsonProperty('operation_id')
  public operationId: string = undefined;

  @JsonProperty('version')
  public version: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('http_method')
  public httpMethod: string = undefined;

  @JsonProperty('path')
  public path: string = undefined;

  @JsonProperty({ name: 'type', customConverter: enumConverterFactory(ConnectorActionType) })
  public type: ConnectorActionType = undefined;

  @JsonProperty({ name: 'sections', customConverter: sectionConverter })
  public sections: Record<string, ConnectorActionSection[]> = {};

  @JsonProperty({ name: 'metadata', cls: ConnectorActionMetadata })
  public metadata: ConnectorActionMetadata = undefined;

  @JsonProperty({ name: 'error_handling_config', cls: ErrorHandlingConfig })
  public errorHandlingConfig?: ErrorHandlingConfig = undefined;

  /**
   * Creates an instance of ConnectorAction.
   * @param {...Array<Partial<ConnectorAction>>} args
   * @memberof ConnectorAction
   */
  constructor(...args: Array<Partial<ConnectorAction>>) {
    Object.assign(this, ...args);
  }

  /**
   * sectionFields
   * @readonly
   * @type {MetaFormField[]}
   * @memberof ConnectorAction
   */
  public get sectionFields(): MetaFormField[] {
    return this.sectionsMetaForm.sections.map((section: MetaFormSection) => section.fields).flat(1);
  }

  /**
   * visibleSectionFields
   * @readonly
   * @type {MetaFormField[]}
   * @memberof ConnectorAction
   */
  public get visibleSectionFields(): MetaFormField[] {
    return this.sectionFields.filter((field: MetaFormField) => field.presentationType !== MetaFormFieldPresentationType.HIDDEN);
  }

  /**
   * sectionsMetaForm
   * @readonly
   * @type {MetaForm}
   * @memberof ConnectorAction
   */
  public get sectionsMetaForm(): MetaForm {
    const actionSections = Object.values(this.sections).flat(1);
    const sections = actionSections.map(
      (section: ConnectorActionSection) =>
        new MetaFormSection({
          type: section.sectionType,
          fields: section.metadata?.map(
            (field: JsonSchemaMetadata) =>
              new MetaFormField({
                ...field,
                required: section.schema.required?.includes(field.name),
                dataType: section.schema.properties[field.name]?.type,
                name: field.name,
              }),
          ),
        }),
    );
    return new MetaForm({ sections });
  }

  /**
   * key
   * @readonly
   * @type {string}
   * @memberof ConnectorAction
   */
  public get key(): string {
    return this.operationId;
  }
}
