/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { IdentifiersRequest } from './identifiers-request.model';
import { UserAdminAccount } from './user-admin-account.model';

/**
 * TransferOwnershipRequest
 * @export
 * @class TransferOwnershipRequest
 */
@Serializable
export class TransferOwnershipRequest {
  @JsonProperty({ name: 'new_owner_descriptor', cls: UserAdminAccount })
  public newOwner: UserAdminAccount = undefined;

  @JsonProperty({ name: 'bulk_id_request', cls: IdentifiersRequest })
  public identifiersRequest: IdentifiersRequest = undefined;

  /**
   * constructor
   * @param {Array<Partial<TransferOwnershipRequest>>} args
   * @memberof TransferOwnershipRequest
   */
  constructor(...args: Array<Partial<TransferOwnershipRequest>>) {
    Object.assign(this, ...args);
  }
}
