/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { RuleGroup } from '@ws1c/intelligence-models/filter';
import { ActionTypeV2 } from './action-type.enum';
import { ActionData, AutomationAction } from './automation-action.model';
import { WorkflowConditionBranch, WorkflowConditionBranchKey, WorkflowTaskType } from './automation.enum';
import { Automation } from './automation.model';
import { NodeNamePrefixes } from './node-name-prefixes.enum';
import { ConditionTaskInnerCountInfo, WorkflowTasksCount } from './workflow-task-types';

/**
 * WorkflowTaskUtil
 * @export
 * @class WorkflowTaskUtil
 */
export class WorkflowTaskUtil {
  /**
   * addNewTask
   * @static
   * @param {Automation} automation
   * @param {AutomationAction} newTask
   * @param {number} insertAtIndex
   * @param {AutomationAction} parentTask
   * @param {WorkflowConditionBranch} conditionBranch
   * @memberof WorkflowTaskUtil
   */
  public static addNewTask(
    automation: Automation,
    newTask: AutomationAction,
    insertAtIndex: number,
    parentTask?: AutomationAction,
    conditionBranch?: WorkflowConditionBranch,
  ) {
    let tasks: AutomationAction[];

    if (parentTask) {
      newTask.parentInfo = {
        uiId: parentTask.uiId,
        branch: conditionBranch,
      };
    }

    if (parentTask) {
      if (parentTask.isGroup) {
        tasks = parentTask.actionData.groupedActions;
      } else if (parentTask.isCondition) {
        tasks = parentTask.actionData.decisionCases[WorkflowConditionBranchKey[conditionBranch]];
      }
    } else {
      tasks = automation.actions;
    }

    // if `insertAtIndex` isn't supplied, always insert at the end of the `actions` array
    const index = insertAtIndex !== undefined ? insertAtIndex : tasks.length;
    const newTasks = [...tasks.slice(0, index), newTask, ...tasks.slice(index)];

    if (parentTask) {
      if (parentTask.isGroup) {
        parentTask.actionData.groupedActions = newTasks;
      } else if (parentTask.isCondition) {
        parentTask.actionData.decisionCases = {
          ...parentTask.actionData.decisionCases,
          [WorkflowConditionBranchKey[conditionBranch]]: newTasks,
        };
      }
    } else {
      automation.actions = newTasks;
    }
  }

  /**
   * deleteWorkflowTask
   * @static
   * @param {Automation} automation
   * @param {AutomationAction} task
   * @memberof WorkflowTaskUtil
   */
  public static deleteWorkflowTask(automation: Automation, task: AutomationAction) {
    const { uiId: parentUiId, branch } = task?.parentInfo ?? {};

    automation.setTaskByUiId(task.uiId);

    const filterFn = (action: AutomationAction) => task.uiId !== action.uiId;

    if (parentUiId) {
      const parentWorkflow = automation.getTaskByUiId(parentUiId);
      if (parentWorkflow.isCondition) {
        parentWorkflow.actionData.decisionCases = {
          ...parentWorkflow.actionData.decisionCases,
          [WorkflowConditionBranchKey[branch]]:
            parentWorkflow.actionData.decisionCases[WorkflowConditionBranchKey[branch]].filter(filterFn),
        };
      } else if (parentWorkflow.isGroup) {
        parentWorkflow.actionData.groupedActions = parentWorkflow.actionData.groupedActions.filter(filterFn);
      }
      return;
    }

    automation.actions = automation.actions.filter(filterFn);
  }

  /**
   * updateRefUpTheTree
   * @static
   * @param {Automation} automation
   * @param {AutomationAction} task
   * @param {boolean} skipSelf
   * @memberof WorkflowTaskUtil
   */
  public static updateRefUpTheTree(automation: Automation, task: AutomationAction, skipSelf?: boolean) {
    if (!task) {
      return;
    }

    const { uiId: parentUiId, branch } = task.parentInfo ?? {};
    const parentTask = parentUiId && automation.getTaskByUiId(parentUiId);

    if (skipSelf) {
      WorkflowTaskUtil.updateRefUpTheTree(automation, parentTask);
      return;
    }

    const newTask = AutomationAction.create(task);
    automation.setTaskByUiId(newTask.uiId, newTask);

    const taskUpdateFn = (action: AutomationAction) => (action.uiId === task.uiId ? newTask : action);
    if (parentTask) {
      if (parentTask.isGroup) {
        parentTask.actionData.groupedActions = parentTask.actionData.groupedActions.map(taskUpdateFn);
      } else if (parentTask.isCondition) {
        parentTask.actionData.decisionCases = {
          ...parentTask.actionData.decisionCases,
          [WorkflowConditionBranchKey[branch]]: parentTask.actionData.decisionCases[WorkflowConditionBranchKey[branch]].map(taskUpdateFn),
        };
      }
      WorkflowTaskUtil.updateRefUpTheTree(automation, parentTask);
      return;
    }
    automation.actions = automation.actions.map(taskUpdateFn);
    automation.allActions = WorkflowTaskUtil.getAutomationAllActions(automation.actions);
  }

  /** getAutomationAllActions
   * @static
   * @param {AutomationAction[]} actions
   * @returns {AutomationAction[]}
   * @memberOf WorkflowTaskUtil
   */
  public static getAutomationAllActions(actions: AutomationAction[]): AutomationAction[] {
    return (actions ?? []).reduce((acc: AutomationAction[], currentValue: AutomationAction) => {
      if (currentValue.isStopWorkflow) {
        return acc;
      }
      if (currentValue.isGroup) {
        acc.push(...(currentValue.actionData?.groupedActions ?? []));
      } else if (currentValue.isCondition) {
        acc.push(...(this.getAutomationAllActions(currentValue.actionData.decisionCases.trueCase) ?? []));
        acc.push(...(this.getAutomationAllActions(currentValue.actionData.decisionCases.falseCase) ?? []));
      } else {
        acc.push(currentValue);
      }
      return acc;
    }, []);
  }

  /**
   * create
   * @static
   * @param {Automation} automation
   * @param {WorkflowTaskType} workflowTaskType
   * @param {AutomationAction} parentWorkflowTask
   * @param {WorkflowConditionBranch} parentConditionBranch
   * @param {Partial<ActionData>} payload
   * @param {number} nameIndex
   * @returns {AutomationAction}
   * @memberOf WorkflowTaskUtil
   */
  public static create(
    automation: Automation,
    workflowTaskType: WorkflowTaskType,
    parentWorkflowTask?: AutomationAction,
    parentConditionBranch?: WorkflowConditionBranch,
    payload: Partial<ActionData> = {},
    nameIndex?: number,
  ): AutomationAction {
    switch (workflowTaskType) {
      case WorkflowTaskType.ACTION:
        payload.type = ActionTypeV2.OPENAPI;
        break;
      case WorkflowTaskType.GROUP:
        payload.type = ActionTypeV2.SYSTEM_GROUP;
        payload.name ??= `${NodeNamePrefixes.GROUP_NAME_PREFIX} ${nameIndex}`;
        payload.groupedActions ??= [];
        break;
      case WorkflowTaskType.CONDITION:
        payload.type = ActionTypeV2.SYSTEM_SWITCH;
        payload.name ??= `${NodeNamePrefixes.CONDITION_NAME_PREFIX} ${nameIndex}`;
        payload.decisionCases ??= {
          [WorkflowConditionBranchKey.TRUE_BRANCH]: [],
          [WorkflowConditionBranchKey.FALSE_BRANCH]: [],
        };
        break;
      case WorkflowTaskType.STOP_WORKFLOW:
        payload.type = ActionTypeV2.SYSTEM_TERMINATE;
        break;
    }

    const task = AutomationAction.create(undefined, payload);

    if (parentWorkflowTask) {
      task.parentInfo = {
        uiId: parentWorkflowTask.uiId,
        branch: parentConditionBranch,
      };
    }

    automation.setTaskByUiId(task.uiId, task);
    return task;
  }

  /**
   * getInnerTasksCountOfConditionTask
   * @static
   * @param {AutomationAction} workflowTask
   * @returns {ConditionTaskInnerCountInfo}
   * @memberof WorkflowTaskUtil
   */
  public static getInnerTasksCountOfConditionTask(workflowTask: AutomationAction): ConditionTaskInnerCountInfo {
    const returnValue: ConditionTaskInnerCountInfo = {
      then: { actions: 0, groups: 0, conditions: 0, all: 0 },
      else: { actions: 0, groups: 0, conditions: 0, all: 0 },
      total: { actions: 0, groups: 0, conditions: 0, all: 0 },
    };

    const updateCount = (task: AutomationAction, branch: WorkflowConditionBranch) => {
      const branchInfo = branch === WorkflowConditionBranch.TRUE_BRANCH ? returnValue.then : returnValue.else;
      let key: keyof WorkflowTasksCount;
      if (task.isAction) {
        key = 'actions';
      } else if (task.isGroup) {
        key = 'groups';
      } else if (task.isCondition) {
        key = 'conditions';
      }

      if (key) {
        branchInfo[key] += 1;
        branchInfo.all += 1;
        returnValue.total[key] += 1;
        returnValue.total.all += 1;
      }
    };

    (workflowTask?.actionData?.decisionCases?.[WorkflowConditionBranchKey.TRUE_BRANCH] || []).forEach((task: AutomationAction) =>
      updateCount(task, WorkflowConditionBranch.TRUE_BRANCH),
    );
    (workflowTask?.actionData?.decisionCases?.[WorkflowConditionBranchKey.FALSE_BRANCH] || []).forEach((task: AutomationAction) =>
      updateCount(task, WorkflowConditionBranch.FALSE_BRANCH),
    );

    return returnValue;
  }

  /**
   * isConditionWarningVisible
   * @static
   * @param {Automation} automation
   * @param {AutomationAction} workflowTask
   * @returns {boolean}
   * @memberof WorkflowTaskUtil
   */
  public static isConditionWarningVisible(automation: Automation, workflowTask: AutomationAction): boolean {
    const triggerRuleEntities: Set<string> = automation?.condition?.ruleGroup?.getUniqueRuleGroupEntities();
    const conditionEntities: Set<string> = workflowTask?.actionData?.expression?.ruleGroup?.getUniqueRuleGroupEntities();
    if (!triggerRuleEntities || !conditionEntities) {
      return;
    }
    const diff: Set<string> = new Set(conditionEntities);
    triggerRuleEntities.forEach((entity: string) => {
      diff.delete(entity);
    });
    return diff.size > 0;
  }

  /**
   * getRuleGroupEntities
   * @static
   * @param {RuleGroup} ruleGroup
   * @returns {string}
   * @memberof WorkflowTaskUtil
   */
  public static getRuleGroupEntities(ruleGroup: RuleGroup): string {
    const entitySet = ruleGroup?.getUniqueRuleGroupEntities();
    if (!entitySet) {
      return '';
    }
    return Array.from(entitySet).join(', ');
  }
}
