/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { BreadCrumb, JsonProperty, Serializable } from '@dpa/ui-common';

import { Entity } from '@ws1c/intelligence-models/entity.model';
import { DeemAppDetailsPageTab } from './deem-app-details-page-tab.enum';

/**
 * DeemAppDetailsPage
 * @export
 * @class DeemAppDetailsPage
 */
@Serializable
export class DeemAppDetailsPage {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('package_id')
  public packageId: string = undefined;

  @JsonProperty('selected_tab')
  public selectedTab: DeemAppDetailsPageTab = undefined;

  @JsonProperty({ name: 'bread_crumbs', cls: BreadCrumb })
  public breadCrumbs: BreadCrumb[] = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('entity')
  public entity: Entity = undefined;

  /**
   * constructor
   * @param {Array<Partial<DeemAppDetailsPage>>} args
   * @memberof DeemAppDetailsPage
   */
  constructor(...args: Array<Partial<DeemAppDetailsPage>>) {
    Object.assign(this, ...args);
  }

  /**
   * nameLabel
   * @returns {string}
   * @memberof DeemAppDetailsPage
   */
  public get nameLabel(): string {
    return this.name;
  }

  /**
   * packageIdLabel
   * @returns {string}
   * @memberof DeemAppDetailsPage
   */
  public get packageIdLabel(): string {
    return this.packageId;
  }
}
