/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { ReportDateRange } from '@ws1c/intelligence-models/reports/report-date-range.model';
import { ReportType } from '@ws1c/intelligence-models/reports/report.enum';

/**
 * ReportTemplateRequest - request model to create/update a report template
 * @export
 * @class ReportTemplateRequest
 */
@Serializable
export class ReportTemplateRequest {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description?: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('entity')
  public entity: string = undefined;

  @JsonProperty('filter')
  public filter: string = undefined;

  @JsonProperty({ name: 'filter_condition_nested_rules', excludeToJson: true })
  public filterCondition: string = undefined;

  @JsonProperty('column_names')
  public _columnNames: string[] = undefined;

  @JsonProperty({ name: 'report_type', customConverter: enumConverterFactory(ReportType) })
  public reportType: ReportType = undefined;

  @JsonProperty({ name: 'date_range', cls: ReportDateRange })
  public dateRange: ReportDateRange = undefined;

  @JsonProperty({ name: 'join_entities_by_integration', customConverter: defaultValueConverterFactory() })
  public joinEntitiesByIntegration: any = {};

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  /**
   * Creates an instance of ReportTemplateRequest.
   * @param {...Array<Partial<ReportTemplateRequest>>} args
   * @memberof ReportTemplateRequest
   */
  constructor(...args: Array<Partial<ReportTemplateRequest>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for columnNames
   * @type {string[]}
   * @memberof ReportTemplateRequest
   */
  public get columnNames() {
    return this._columnNames;
  }

  /**
   * Setter for columnNames
   * @param {string[]} columnNames
   * @memberof ReportTemplateRequest
   */
  public set columnNames(columnNames: string[]) {
    this._columnNames = columnNames;
  }
}
