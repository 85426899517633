/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * PerformanceIndicatorsDashboardType
 * @export
 * @enum {string}
 */
export enum PerformanceIndicatorsDashboardType {
  // For RCA Dashboard
  ROOT_CAUSE_ANALYSIS_DESKTOP_APP = 'ROOT_CAUSE_ANALYSIS_DESKTOP_APP',
  ROOT_CAUSE_ANALYSIS_DESKTOP_HEALTH = 'ROOT_CAUSE_ANALYSIS_DESKTOP_HEALTH',
  ROOT_CAUSE_ANALYSIS_MOBILE_APP = 'ROOT_CAUSE_ANALYSIS_MOBILE_APP',
}

/**
 * PerformanceIndicatorsWidgetSubtype
 * @export
 * @enum {string}
 */
export enum PerformanceIndicatorsWidgetSubtype {
  // Root Cause Analysis - Desktop App
  RCA_DESKTOP_APP_CRASH_RATE = 'RCA_DESKTOP_APP_CRASH_RATE',
  RCA_DESKTOP_APP_CRASH_COUNT = 'RCA_DESKTOP_APP_CRASH_COUNT',
  RCA_DESKTOP_APP_HANG_RATE = 'RCA_DESKTOP_APP_HANG_RATE',
  RCA_DESKTOP_APP_HANG_COUNT = 'RCA_DESKTOP_APP_HANG_COUNT',
  // Root Cause Analysis - Desktop Health
  RCA_DESKTOP_HEALTH_OS_CRASHES = 'RCA_DESKTOP_HEALTH_OS_CRASHES',
  RCA_DESKTOP_HEALTH_BOOT_TIME = 'RCA_DESKTOP_HEALTH_BOOT_TIME',
  RCA_DESKTOP_HEALTH_SHUTDOWN_TIME = 'RCA_DESKTOP_HEALTH_SHUTDOWN_TIME',
  RCA_DESKTOP_HEALTH_BATTERY_HEALTH = 'RCA_DESKTOP_HEALTH_BATTERY_HEALTH',
  // Root Cause Analysis - Mobile App
  RCA_MOBILE_APP_APP_CRASH_RATE = 'RCA_MOBILE_APP_APP_CRASH_RATE',
  RCA_MOBILE_APP_HANDLED_EXCEPTIONS = 'RCA_MOBILE_APP_HANDLED_EXCEPTIONS',
  RCA_MOBILE_APP_NETWORK_ERRORS = 'RCA_MOBILE_APP_NETWORK_ERRORS',
  RCA_MOBILE_APP_RESPONSE_TIME = 'RCA_MOBILE_APP_RESPONSE_TIME',
}
