/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntelligenceServiceType
 * @export
 * @enum {string}
 */
export enum IntelligenceServiceType {
  HORIZON = 'HORIZON',
  INTELLIGENCE = 'INTELLIGENCE',
  WS1_ACCESS = 'WS1_ACCESS',
  WS1_UEM = 'WS1_UEM',
  WS1_CLOUD = 'WS1_CLOUD',
}
