/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * GenericCrumb
 * @export
 * @class GenericCrumb
 */
@Serializable
export class GenericCrumb {
  @JsonProperty('event_name')
  public eventName: string = undefined;

  @JsonProperty('event_status')
  public eventStatus: string = undefined;

  @JsonProperty('event_timestamp')
  public eventTimestamp: number = undefined;

  /**
   * Creates an instance of DeviceCrumb.
   * @param {...Array<Partial<GenericCrumb>>} args
   * @memberof DeviceCrumb
   */
  constructor(...args: Array<Partial<GenericCrumb>>) {
    Object.assign(this, ...args);
  }

  /**
   * name
   * @readonly
   * @type {string}
   * @memberof DeviceCrumb
   */
  public get name(): string {
    return this.eventName;
  }
}
