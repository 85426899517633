/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Serializable } from '@dpa/ui-common';

import { Threshold } from '@ws1c/intelligence-models/threshold/threshold.model';

/**
 * DeemUxScoreThreshold
 * @export
 * @class DeemUxScoreThreshold
 * @extends {Threshold}
 */
@Serializable
export class DeemUxScoreThreshold extends Threshold {
  public isReversed: boolean = false;

  /**
   * Creates an instance of DeemUxScoreThreshold.
   * @param {Array<Partial<DeemUxScoreThreshold>>} args
   * @memberof DeemUxScoreThreshold
   */
  constructor(...args: Array<Partial<DeemUxScoreThreshold>>) {
    super();
    Object.assign(this, ...args);
  }
}
