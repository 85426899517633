/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * ReportTemplateSearchRequest
 * @export
 * @class ReportTemplateSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class ReportTemplateSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'report_template_id', excludeToJson: true })
  public reportTemplateId?: string = undefined;

  @JsonProperty('entities_by_integration')
  public entitiesByIntegration: any;

  @JsonProperty('join_entities_by_integration')
  public joinEntitiesByIntegration: any;

  /**
   * Creates an instance of ReportTemplateSearchRequest.
   * @param {Array<Partial<ReportTemplateSearchRequest>>} args
   * @memberof ReportTemplateSearchRequest
   */
  constructor(...args: Array<Partial<ReportTemplateSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
