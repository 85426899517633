/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { CounterDefinition } from './counter-definition.model';
import { CounterResult } from './counter-result.model';

/**
 * Counter Model Object
 * A counter represents an aggregation definition and its result.
 * The counter will be placed in an appropriate bucket.
 *
 * @export
 * @class Counter
 */
@Serializable
export class Counter {
  @JsonProperty({ name: 'definition', cls: CounterDefinition })
  public definition: CounterDefinition = undefined;

  @JsonProperty({ name: 'result', cls: CounterResult })
  public result: CounterResult = undefined;

  /**
   * constructor
   * @param {Array<Partial<Counter>>} args
   * @memberof Counter
   */
  constructor(...args: Array<Partial<Counter>>) {
    Object.assign(this, ...args);
  }
}
