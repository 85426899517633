/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SearchTerm } from '@ws1c/intelligence-models/search-term.model';

/**
 * ConnectorsAllActionsRequest
 *
 * @export
 * @class ConnectorsAllActionsRequest
 */
@Serializable
export class ConnectorsAllActionsRequest {
  @JsonProperty({ name: 'search_terms', cls: SearchTerm })
  public searchTerms: SearchTerm[] = undefined;

  /**
   * Initializes instance of ConnectorsAllActionsRequest
   * @param {Array<Partial<ConnectorsAllActionsRequest>>} args
   * @memberof ConnectorsAllActionsRequest
   */
  constructor(...args: Array<Partial<ConnectorsAllActionsRequest>>) {
    Object.assign(this, ...args);
  }
}
