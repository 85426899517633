/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { MetaFormField } from '@ws1c/intelligence-models/meta-form/meta-form-field.model';

/**
 * Counter Result Model Object
 * Resultant type and value of a counter definition
 *
 * @export
 * @class CounterResult
 */
@Serializable
export class CounterResult {
  @JsonProperty({ name: 'column_basic_view', cls: MetaFormField })
  public reportColumnView: MetaFormField = undefined;

  public value: any = undefined;

  /**
   * constructor
   * @param {Array<Partial<CounterResult>>} args
   * @memberof CounterResult
   */
  constructor(...args: Array<Partial<CounterResult>>) {
    Object.assign(this, ...args);
  }
}
