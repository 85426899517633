/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { Connector } from './connector.model';

/**
 * ConnectorsSearchResponse
 * @export
 * @class ConnectorsSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class ConnectorsSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: Connector })
  public results: Connector[] = [];

  /**
   * Creates an instance of ConnectorsSearchResponse.
   * @param {Array<Partial<ConnectorsSearchResponse>>} args
   * @memberof ConnectorsSearchResponse
   */
  constructor(...args: Array<Partial<ConnectorsSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
