/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightUnit
 * @export
 * @enum {string}
 */
export enum InsightUnit {
  MILLIS = 'MILLIS',
  PERCENT = 'PERCENT',
  SECONDS = 'SECONDS',
}
