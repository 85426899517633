/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { preserveDefaultValueConverter } from '@ws1c/intelligence-models/converters/preserve-default-value.converter';
import { NameLabel } from '@ws1c/intelligence-models/name-label.model';
import { TAGS_TYPE } from '@ws1c/intelligence-models/tags.enum';

/**
 * Tag Domain Object
 *
 * @export
 * @class Tag
 */
@Serializable
export class Tag {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('attribute')
  public attribute?: string = undefined;

  @JsonProperty({ name: 'subtags', cls: NameLabel })
  public subtags?: NameLabel[] = undefined;

  @JsonProperty({ name: 'selectedSubTags' })
  public selectedSubTags?: string[] = undefined;

  @JsonProperty({ name: 'isHidden', customConverter: preserveDefaultValueConverter })
  public isHidden?: boolean = false;

  public type?: TAGS_TYPE = TAGS_TYPE.DEFAULT;

  /**
   * Creates an instance of Tag.
   * @param {any} args
   * @memberof Tag
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
