/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Ws1BrokerDirectory } from './ws1-broker-directory.model';
import { WS1BrokerLink } from './ws1-broker-link.model';

/**
 * Ws1BrokerAllDirectories
 * @export
 * @class Ws1BrokerAllDirectories
 */
@Serializable
export class Ws1BrokerAllDirectories {
  @JsonProperty({ name: 'items', cls: Ws1BrokerDirectory })
  public items: Ws1BrokerDirectory[] = [];

  @JsonProperty({ name: '_links', cls: WS1BrokerLink })
  public links: WS1BrokerLink = undefined;

  /**
   * Creates an instance of Ws1BrokerAllDirectories.
   * @param {<Partial<Ws1BrokerAllDirectories>>} args
   * @memberof Ws1BrokerAllDirectories
   */
  constructor(...args: Array<Partial<Ws1BrokerAllDirectories>>) {
    Object.assign(this, ...args);
  }
}
