/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { DeemPlaybookStepItemBase } from './deem-playbook-step-item-base.model';

/**
 * DeemPlaybookStepItemLink
 * @export
 * @class DeemPlaybookStepItemLink
 * @extends DeemPlaybookStepItemBase
 */
@Serializable
export class DeemPlaybookStepItemLink extends DeemPlaybookStepItemBase {
  @JsonProperty('url')
  public url: string = undefined;

  @JsonProperty('display_text')
  public displayText: string = undefined;

  /**
   * constructor
   * @param {Partial<DeemPlaybookStepItemLink>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybookStepItemLink>>) {
    super();
    Object.assign(this, ...args);
  }
}
