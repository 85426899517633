/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemIncidentStatus
 * @export
 * @enum {string}
 */
export enum DeemIncidentStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}
