/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { DashboardConfig } from './dashboard/dashboard.config';
import { DEVICE_PLATFORM } from './dashboard/device-platform.constant';
import { isPlatformSupported } from './utils/common';

/**
 * Device Model Object
 *
 * @export
 * @class Device
 */
@Serializable
export class Device {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('model')
  public model: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('serial_number')
  public serialNumber: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  /**
   * constructor
   * @param {Array<Partial<Device>>} args
   * @memberof Device
   */
  constructor(...args: Array<Partial<Device>>) {
    Object.assign(this, ...args);
  }

  /**
   * isDesktopDevice
   * @type {boolean}
   * @memberof Device
   */
  public get isDesktopDevice(): boolean {
    return isPlatformSupported(this, DashboardConfig.DESKTOP_OS);
  }

  /**
   * isMobileDevice
   * @type {boolean}
   * @memberof Device
   */
  public get isMobileDevice(): boolean {
    return isPlatformSupported(this, DashboardConfig.MOBILE_OS);
  }

  /**
   * isAndroidDevice
   * @type {boolean}
   * @memberof Device
   */
  public get isAndroidDevice(): boolean {
    return isPlatformSupported(this, [DEVICE_PLATFORM.ANDROID]);
  }

  /**
   * isIosDevice
   * @type {boolean}
   * @memberof Device
   */
  public get isIosDevice(): boolean {
    return isPlatformSupported(this, [DEVICE_PLATFORM.APPLE]);
  }
}
