/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { WidgetRangeType } from './dashboard.enum';

/**
 * WidgetRangeFilter
 * @export
 * @class WidgetRangeFilter
 */
@Serializable
export class WidgetRangeFilter {
  @JsonProperty({ name: 'range_type', customConverter: enumConverterFactory(WidgetRangeType) })
  public type: WidgetRangeType = undefined;

  @JsonProperty('min')
  public min: number = undefined;

  @JsonProperty('max')
  public max: number = undefined;

  /**
   * Creates an instance of WidgetRangeFilter.
   * @param {...Array<Partial<WidgetRangeFilter>>} args
   * @memberof WidgetRangeFilter
   */
  constructor(...args: Array<Partial<WidgetRangeFilter>>) {
    Object.assign(this, ...args);
  }
}
