/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Template } from '@ws1c/intelligence-models/template/template.model';

/**
 * ResourceTemplate - Base template for script and sensors.
 * @export
 * @class ResourceTemplate
 * @extends Template
 */
@Serializable
export class ResourceTemplate extends Template {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('short_description')
  public shortDescription: string = undefined;

  @JsonProperty('long_description')
  public longDescription: string = undefined;

  @JsonProperty('integrations_required')
  public integrationsRequired: string[] = undefined;

  @JsonProperty('prerequisites')
  public prerequisites: string[] = undefined;

  @JsonProperty('variables')
  public variables: Array<Record<string, string>> = undefined;

  @JsonProperty('language')
  public language: string = undefined;

  @JsonProperty('platform')
  public platform: string = undefined;

  @JsonProperty('execution_architecture')
  public executionArchitecture: string = undefined;

  @JsonProperty('execution_context')
  public executionContext: string = undefined;

  @JsonProperty('code')
  public code: string = undefined;

  @JsonProperty('display_id')
  public displayId: string = undefined;

  /**
   * Creates an instance of ResourceTemplate
   * @param {Array<Partial<ResourceTemplate>>} args
   * @memberof ResourceTemplate
   */
  constructor(...args: Array<Partial<ResourceTemplate>>) {
    super(...args);
    Object.assign(this, ...args);
  }

  /**
   * templateId
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get templateId(): string {
    return this.id;
  }

  /**
   * label
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get label(): string {
    return this.name;
  }

  /**
   * integration
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get integration(): string {
    return this.integrationsRequired?.[0];
  }

  /**
   * entity
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get entity(): string {
    return '';
  }

  /**
   * templateKey
   * Returns a field which is static across all environments.
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get templateKey(): string {
    return this.displayId;
  }

  /**
   * description
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get description(): string {
    return this.shortDescription;
  }

  /**
   * marketplaceResourceId
   * @readonly
   * @type {string}
   * @memberof ResourceTemplate
   */
  public get marketplaceResourceId(): string {
    return this.templateKey;
  }
}
