/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * MarketplaceResourceTagConstant
 * @export
 * @class MarketplaceResourceTagConstant
 */
export class MarketplaceResourceTagsConstant {
  public static readonly EMPLOYEE_EXPERIENCE: string = 'Employee Experience';
  public static readonly FRONTLINE: string = 'Frontline';
  public static readonly INTEGRATIONS: string = 'Integrations';
  public static readonly EXPERIENCE_ANALYTICS: string = 'Experience Analytics';
}
