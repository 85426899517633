/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, TimeOfDay } from '@dpa/ui-common';

import { Frequency } from '@ws1c/intelligence-models/frequency.enum';
import { CronExpressionData, CronExpressionDetail, Hourly, Monthly, Weekly } from '@ws1c/intelligence-models/scheduled-report.model';

/**
 * Converts cronExpressionData to CronExpressionDetail
 *
 * @param {GenericObject} scheduleData
 * @returns {CronExpressionDetail}
 */
export const convertCronExpressionData = (scheduleData: GenericObject): CronExpressionDetail => {
  const startTimeOfDay: TimeOfDay = scheduleData.cronExpressionDetail.startTimeOfDay;
  const frequency = parseInt(scheduleData.cronExpressionDetail.frequency, 10);
  const cronExpressionDetail: CronExpressionDetail = new CronExpressionDetail({
    frequency: Frequency[frequency],
    hour: startTimeOfDay.getMilitaryHour(),
    minute: startTimeOfDay.minute,
  });
  switch (frequency) {
    case Frequency.ONCE:
      cronExpressionDetail.hour = undefined;
      cronExpressionDetail.minute = undefined;
      break;
    case Frequency.HOURLY:
      cronExpressionDetail.hour = undefined;
      cronExpressionDetail.minute = undefined;
      cronExpressionDetail.hourly = new Hourly({
        interval: parseInt(scheduleData.cronExpressionDetail.hourly.interval, 10),
      });
      break;
    case Frequency.WEEKLY:
      const daysOfWeek = [];
      Object.keys(scheduleData.cronExpressionDetail.weekly).forEach((key) => {
        if (scheduleData.cronExpressionDetail.weekly[key]) {
          daysOfWeek.push(key.toUpperCase());
        }
      });
      cronExpressionDetail.weekly = new Weekly({ daysOfWeek });
      break;
    case Frequency.MONTHLY:
      cronExpressionDetail.monthly = new Monthly({
        dayOfMonth: parseInt(scheduleData.cronExpressionDetail.monthly.dayOfMonth, 10),
      });
      break;
  }
  return cronExpressionDetail;
};

/**
 * Converts cronExpressionDetail to CronExpressionData
 *
 * @param {CronExpressionDetail} cronExpressionDetail
 * @param {string} start
 * @param {string|undefined} end
 * @returns {CronExpressionData}
 */
export const convertCronExpressionDetail = (
  cronExpressionDetail: CronExpressionDetail,
  start: number,
  end: number | undefined,
): CronExpressionData => {
  const startTimeOfDay = TimeOfDay.createFromTimestamp(+start);
  const endDate = end ? new Date(end) : null;

  const cronExpressionData: CronExpressionData = {
    frequency: Frequency[cronExpressionDetail.frequency],
    startTimeOfDay,
    end: endDate,
    requiredEnDate: !!endDate,
  };

  switch (parseInt(Frequency[cronExpressionDetail.frequency], 10)) {
    case Frequency.WEEKLY:
      const weekDays = {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
      };
      // @ts-ignore for now (daysOfWeek is defined as number[])
      cronExpressionDetail.weekly.daysOfWeek.forEach((day: string) => {
        weekDays[day.toLowerCase()] = true;
      });
      cronExpressionData.weekly = weekDays;
      break;
    case Frequency.HOURLY:
      Object.assign(cronExpressionData, {
        hourly: cronExpressionDetail.hourly,
      });
      break;
    case Frequency.MONTHLY:
      Object.assign(cronExpressionData, {
        monthly: {
          dayOfMonth: cronExpressionDetail.monthly.dayOfMonth,
        },
      });
      break;
  }
  return cronExpressionData;
};
