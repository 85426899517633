/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemPlaybookType
 * @export
 * @enum {string}
 */
export enum DeemPlaybookType {
  SINGLE_DEVICE = 'SINGLE_DEVICE',
  MULTIPLE_DEVICE = 'MULTIPLE_DEVICE',
  APPLICATION = 'APPLICATION',
}
