/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

export enum AlertBannerTarget {
  APP,
  PAGE,
  MODAL,
  SIDEBAR,
  SECTION,
}
