/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * SuggestionFilterBy
 * @export
 * @class SuggestionFilterBy
 */
@Serializable
export class SuggestionFilterBy {
  @JsonProperty('field_query_attribute')
  public fieldName: string;

  @JsonProperty('values')
  public values: string[];

  /**
   * constructor
   * @param {any[]} args
   * @memberof SuggestionFilterBy
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
