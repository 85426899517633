/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * RoleName
 *
 * @export
 * @enum
 */
export enum RoleName {
  COMMUNICATOR,
  AUTOMATOR,
  MODERATOR,
  AUDITOR,
}
