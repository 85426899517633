/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntegrationResultCode
 * @exports
 * @enum {string}
 */
export enum IntegrationResultCode {
  OK = 'OK',
  UNKNOWN = 'UNKNOWN',
  ERROR_FAILED_INTEL_ONBOARD_OPT_IN = 'ERROR_FAILED_INTEL_ONBOARD_OPT_IN',
  ERROR_FAILED_INTEL_ONBOARD_OPT_OUT = 'ERROR_FAILED_INTEL_ONBOARD_OPT_OUT',
  ERROR_FAILED_INTEL_ONBOARD_OPT_IN_TENANT_BINDING = 'ERROR_FAILED_INTEL_ONBOARD_OPT_IN_TENANT_BINDING',
  ERROR_FAILED_INTEL_ONBOARD_OPT_IN_INVALID_DATA = 'ERROR_FAILED_INTEL_ONBOARD_OPT_IN_INVALID_DATA',
  ERROR_FAILED_INTEL_ONBOARD_CROSS_REGION_CHECK = 'ERROR_FAILED_INTEL_ONBOARD_CROSS_REGION_CHECK',
  ERROR_FAILED_INTEL_ONBOARD_ALREADY_OPT_IN = 'ERROR_FAILED_INTEL_ONBOARD_ALREADY_OPT_IN',
  ERROR_FAILED_INTEL_ONBOARD_HORIZON_NOT_OPT_IN_MONITORING_SERVICE = 'ERROR_FAILED_INTEL_ONBOARD_HORIZON_NOT_OPT_IN_MONITORING_SERVICE',
  ERROR_FAILED_INTEL_ONBOARD_INVALID_CALLBACKURL = 'ERROR_FAILED_INTEL_ONBOARD_INVALID_CALLBACKURL',
  ERROR_FAILED_INTEL_ONBOARD_DECLINE = 'ERROR_FAILED_INTEL_ONBOARD_DECLINE',
  ERROR_FAILED_INTEL_ONBOARD_DEPENDENCY_FAIL = 'ERROR_FAILED_INTEL_ONBOARD_DEPENDENCY_FAIL',

  // Hub service
  OPT_IN_DENIED_ERROR_CODE = 'OPT_IN_DENIED_ERROR_CODE',
  EXPIRED_TOKEN_ERROR_CODE = 'EXPIRED_TOKEN_ERROR_CODE',
  TENANT_REGISTRATION_FAILURE_ERROR_CODE = 'TENANT_REGISTRATION_FAILURE_ERROR_CODE',
  TENANT_ALREADY_INTEGRATED_ERROR_CODE = 'TENANT_ALREADY_INTEGRATED_ERROR_CODE',
  INTELLIGENCE_DOMAIN_MISMATCH_ERROR_CODE = 'INTELLIGENCE_DOMAIN_MISMATCH_ERROR_CODE',
  INTELLIGENCE_ADAPTER_CREATION_FAILURE_ERROR_CODE = 'INTELLIGENCE_ADAPTER_CREATION_FAILURE_ERROR_CODE',
  OAUTH_CLIENT_CREATION_FAILURE_ERROR_CODE = 'OAUTH_CLIENT_CREATION_FAILURE_ERROR_CODE',
  SERVICE_CONFIG_CREATION_FAILURE_ERROR_CODE = 'SERVICE_CONFIG_CREATION_FAILURE_ERROR_CODE',
  INVALID_SERVICE_TYPE_ERROR_CODE = 'INVALID_SERVICE_TYPE_ERROR_CODE',
  INVALID_SERVICE_CREDENTIALS_ERROR_CODE = 'INVALID_SERVICE_CREDENTIALS_ERROR_CODE',
}
