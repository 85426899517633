/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * TrendResultValuePairType
 * @export
 * @enum {string}
 */
export enum TrendResultValuePairType {
  ACTIVE_INACTIVE = 'ACTIVE_INACTIVE',
  DATETIME = 'DATETIME',
  FILE_SIZE = 'FILE_SIZE',
  PERCENTAGE = 'PERCENTAGE',
  STRING = 'STRING',
  YES_NO = 'YES_NO',
}
