/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserGroupDescriptor } from './user-group-descriptor';

/**
 * AccountGroupRolesRequest
 * @export
 * @class AccountGroupRolesRequest
 */
@Serializable
export class AccountGroupRolesRequest {
  @JsonProperty('role_ids')
  public roleIds: string[] = undefined;

  @JsonProperty({ name: 'user_group_descriptor', cls: UserGroupDescriptor })
  public userGroupDescriptor: UserGroupDescriptor = undefined;

  /**
   * Creates an instance of AccountGroupRolesRequest.
   * @param {Array<Partial<AccountGroupRolesRequest>>} args
   * @memberof AccountGroupRolesRequest
   */
  constructor(...args: Array<Partial<AccountGroupRolesRequest>>) {
    Object.assign(this, ...args);
  }
}
