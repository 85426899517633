/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { UserDescriptor } from '@ws1c/intelligence-models/user-descriptor.model';
import { AccessType } from './access-type.enum';

/**
 * UserAccess
 * @exports
 * @class UserAccess
 */
@Serializable
export class UserAccess {
  @JsonProperty({ name: 'user_descriptor', cls: UserDescriptor })
  public userDescriptor?: UserDescriptor = undefined;

  @JsonProperty({ name: 'account_id', excludeToJson: true })
  public accountId?: string = undefined;

  @JsonProperty({ name: 'account_access_level', customConverter: enumConverterFactory(AccessType) })
  public accessLevel: AccessType = undefined;

  /**
   * Creates an instance of UserAccess.
   * @param {Array<Partial<UserAccess>>} args
   * @memberof UserAccess
   */
  constructor(...args: Array<Partial<UserAccess>>) {
    Object.assign(this, ...args);
  }
}
