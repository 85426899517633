/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { ResultState } from './automation.enum';

/**
 * AutomationTestActionResponse
 *
 * @export
 * @class AutomationTestActionResponse
 */
export class AutomationTestActionResponse {
  @JsonProperty({ name: 'execution_status', customConverter: enumConverterFactory(ResultState) })
  public resultState: string = undefined;

  @JsonProperty('http_status')
  public httpsStatus: string = undefined;

  @JsonProperty('response_status_code')
  public responseStatusCode: number = undefined;

  @JsonProperty('response_reason_phrase')
  public responseReasonPhrase: string = undefined;

  @JsonProperty('api_response_data')
  public data: any[] = undefined;

  @JsonProperty('error_message')
  public errorMessage?: string = undefined;

  public rawResponse?: any;

  /**
   * Creates an instance of AutomationTestActionResponse.
   * @param {...Array<Partial<AutomationTestActionResponse>>} args
   * @memberof AutomationTestActionResponse
   */
  constructor(...args: Array<Partial<AutomationTestActionResponse>>) {
    Object.assign(this, ...args);
  }
}
