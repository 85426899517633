/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/**
 * BlockerStatus
 * @export
 * @enum {number}
 */
export enum BlockerStatus {
  SKIP_NEXT_BLOCK,
  BLOCKING,
  PENDING_APPROVAL,
  APPROVED,
}
