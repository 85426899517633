/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Column } from './column.model';

/**
 * EntityColumns
 * @export
 * @class EntityColumns
 */
export class EntityColumns {
  public columns: Column[] = [];
  public name: string;
  public label: string;

  /**
   * Creates an instance of EntityColumns.
   * @param {Array<Partial<EntityColumns>>} args
   * @memberof EntityColumns
   */
  constructor(...args: Array<Partial<EntityColumns>>) {
    Object.assign(this, ...args);
  }
}
