/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';

/**
 * Integration Action
 * @export
 * @class IntegrationAction
 */
@Serializable
export class IntegrationAction {
  @JsonProperty('action_name')
  public actionName: string = undefined;

  @JsonProperty('action_description')
  public actionDescription: string = undefined;

  @JsonProperty('total_automation_count')
  public totalAutomationCount: number = undefined;

  @JsonProperty({ name: 'last_automation_run_on', customConverter: dateFormatConverter, excludeToJson: true })
  public lastAutomationRunOn: number = undefined;

  @JsonProperty('automation_run_count_in_last24_hrs')
  public automationRunCountInLast24Hrs: number = undefined;

  @JsonProperty('automation_error_count_in_last24_hrs')
  public automationErrorCountInLast24Hrs: number = undefined;

  /**
   * Creates an instance of IntegrationAction
   * @param {Array<Partial<IntegrationAction>>} args
   * @memberof IntegrationAction
   */
  constructor(...args: Array<Partial<IntegrationAction>>) {
    Object.assign(this, ...args);
  }
}
