/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

import { UserAdminAccount } from './user-admin-account.model';

/**
 * UserAdminAccountSearchResponse
 *
 * @exports
 * @class UserAdminAccountSearchResponse
 * @implements {PagedResponse}
 */
export class UserAdminAccountSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: UserAdminAccount })
  public results: UserAdminAccount[] = [];

  /**
   * Creates an instance of UserAdminAccountSearchResponse.
   * @param {Array<Partial<UserAdminAccountSearchResponse>>} args
   * @memberof UserAdminAccountSearchResponse
   */
  constructor(...args: Array<Partial<UserAdminAccountSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
