/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * VerticalDirection
 *
 * @exports
 * @enum {number}
 */
export enum VerticalDirection {
  UP,
  DOWN,
}
