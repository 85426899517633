/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter } from '@dpa/ui-common';
import { isString } from 'lodash-es';

/**
 * dateFormatConverter
 * Convert date time from ISO 8601 format to number
 */
export const dateFormatConverter: CustomConverter = {
  fromJson(date: any): number {
    if (isString(date)) {
      // Convert date string (ISO 8601 format) to UTC timestamp
      return Date.parse(date);
    }
    return date;
  },
  toJson(date: number): number {
    return date;
  },
};
