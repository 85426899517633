/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { InsightType } from './insight-type.enum';
import { InsightUnit } from './insight-unit.enum';
import { InsightsProperties } from './insights-properties.interface';

/**
 * InsightsConfig
 * @export
 * @class InsightsConfig
 */
export class InsightsConfig {
  public static insightsPropertiesMap: Record<InsightType, InsightsProperties> = {
    [InsightType.APP_CRASHES]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APP_CRASHES',
      eventName: 'Application Crash',
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APP_HANGS]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APP_HANGS',
      eventName: 'Application Hang',
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APP_INSTALL_FAILED]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APP_INSTALL_FAILED',
      eventName: null,
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APP_INSTALL_PENDING]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APP_INSTALL_PENDING',
      eventName: null,
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APP_USAGE]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APP_USAGE',
      eventName: 'Application Foreground',
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APTELIGENT_CRASH_ANDROID]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APTELIGENT_CRASH_ANDROID',
      eventName: null,
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APTELIGENT_CRASH_IOS]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APTELIGENT_CRASH_IOS',
      eventName: null,
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.APTELIGENT_NET_ERROR]: {
      insightTypeLabel: 'INSIGHTS_TYPES.APTELIGENT_NET_ERROR',
      eventName: null,
      icon: 'applications',
      isDurationInsight: false,
    },
    [InsightType.BOOT_DURATION]: {
      insightTypeLabel: 'INSIGHTS_TYPES.DURATION',
      eventName: 'Boot',
      icon: 'devices',
      isDurationInsight: true,
      unit: InsightUnit.MILLIS,
    },
    [InsightType.CPU_UTILIZATION]: {
      insightTypeLabel: 'INSIGHTS_TYPES.CPU_UTILIZATION',
      eventName: 'Performance Counters',
      icon: 'devices',
      isDurationInsight: false,
      unit: InsightUnit.PERCENT,
    },
    [InsightType.FAILED_SSO_LOGIN]: {
      insightTypeLabel: 'INSIGHTS_TYPES.FAILED_SSO_LOGIN',
      eventName: null,
      icon: 'user',
      isDurationInsight: false,
    },
    [InsightType.HORIZON_SESSION_LOGON_DURATION]: {
      insightTypeLabel: 'INSIGHTS_TYPES.DURATION',
      eventName: null,
      icon: 'cloud-network',
      isDurationInsight: true,
      unit: InsightUnit.SECONDS,
    },
    [InsightType.OS_CRASHES]: {
      insightTypeLabel: 'INSIGHTS_TYPES.OS_CRASHES',
      eventName: 'System Crash',
      icon: 'devices',
      isDurationInsight: false,
    },
    [InsightType.SHUTDOWN_DURATION]: {
      insightTypeLabel: 'INSIGHTS_TYPES.DURATION',
      eventName: 'Shutdown',
      icon: 'devices',
      isDurationInsight: true,
      unit: InsightUnit.MILLIS,
    },
  };
}
