/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

export enum PendoEventSamSelectedSource {
  // SAM
  COMPARE_PRODUCTS = 'Compare Products',
  OPEN_DEVICE_PROFILE = 'Open Device Profile',
  VIEW_PRODUCT_DETAIL = 'View Product Detail',
}
