/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceUserActionsCrumb
 * @export
 * @class DeviceUserActionsCrumb
 * @extends {GenericCrumb}
 */
@Serializable
export class DeviceUserActionsCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_user)
  public user: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_logon_type)
  public logonType: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_sid)
  public sessionId: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_user_actions_duration_millis)
  public duration: number = undefined;

  /**
   * Creates an instance of DeviceUserActionsCrumb.
   * @param {...Array<Partial<DeviceUserActionsCrumb>>} args
   * @memberof DeviceUserActionsCrumb
   */
  constructor(...args: Array<Partial<DeviceUserActionsCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
