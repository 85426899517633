/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * UserPreferenceLimits
 * All available UserPreferenceLimits
 *
 * @export
 * @class UserPreferenceLimits
 */
export class UserPreferenceLimits {
  public static readonly CONTENT_SEARCH_SORTED_RESULT_COUNT_UPPER_LIMIT: string = 'content_search_sorted_result_count_upper_limit';
}
