/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * DeviceUser Model Object
 *
 * @export
 * @class DeviceUser
 */
@Serializable
export class DeviceUser {
  @JsonProperty('user_guid')
  public id: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('user_name')
  public userName: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('last_active')
  public lastActive: number = undefined;

  /**
   * constructor
   * @param {Array<Partial<DashboardUser>>} args
   * @memberof User
   */
  constructor(...args: Array<Partial<DeviceUser>>) {
    Object.assign(this, ...args);
  }
}
