/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

export interface NetEventsLogTab {
  name: string;
  labelKey: string;
  entityId: string;
}

/**
 * NetEventsLogTabName
 * @export
 * @class NetEventsLogTabName
 */
export class NetEventsLogTabName {
  public static NET_EVENT: string = 'NET_EVENT';
  public static NET_ERROR: string = 'NET_ERROR';
}
