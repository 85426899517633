/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { ManagedConnector } from './managed-connector.model';

/**
 * ManagedConnectorsSearchResponse
 * @export
 * @class ManagedConnectorsSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class ManagedConnectorsSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: ManagedConnector })
  public results: ManagedConnector[] = [];

  /**
   * Creates an instance of ManagedConnectorsSearchResponse.
   * @param {Array<Partial<ManagedConnectorsSearchResponse>>} args
   * @memberof ManagedConnectorsSearchResponse
   */
  constructor(...args: Array<Partial<ManagedConnectorsSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
