/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Frequency } from './frequency.enum';

/**
 * AppConstants
 * @export
 * @class AppConstants
 */
export class AppConstants {
  public static readonly SCHEDULE_FREQUENCIES = [
    { value: Frequency.HOURLY, labelKey: 'SCHEDULE_CUSTOMIZE.RECURRENCE_HOURLY' },
    { value: Frequency.DAILY, labelKey: 'SCHEDULE_CUSTOMIZE.RECURRENCE_DAILY' },
    { value: Frequency.WEEKLY, labelKey: 'SCHEDULE_CUSTOMIZE.RECURRENCE_WEEKLY' },
    { value: Frequency.MONTHLY, labelKey: 'SCHEDULE_CUSTOMIZE.RECURRENCE_MONTHLY' },
  ];

  public static readonly ONE_MINUTE_IN_MILLIS = 60 * 1000;
  public static readonly ONE_HOUR_IN_MILLIS = 60 * AppConstants.ONE_MINUTE_IN_MILLIS;
  public static readonly ONE_DAY_IN_MILLIS = 24 * AppConstants.ONE_HOUR_IN_MILLIS;
  public static readonly ONE_WEEK_IN_MILLIS = AppConstants.ONE_DAY_IN_MILLIS * 7;

  public static SECONDS_PER_MINUTE = 60;

  public static SECONDS_PER_5_MINUTES = 300;

  public static SECONDS_PER_15_MINUTES = 900;

  public static SECONDS_PER_30_MINUTES = 1800;

  public static SECONDS_PER_HOUR = 3600;

  public static SECONDS_PER_4_HOURS = 14400;

  public static SECONDS_PER_12_HOURS = 43200;

  public static SECONDS_PER_DAY = 86400;

  public static MINUTES_PER_DAY = 1440;

  public static DEFAULT_POLICY_SYSTEM_ID_KEY = 'PERMIT_ALL_DATA_ACCESS_POLICY';

  // The size of the items in the list (in pixels) - see https://material.angular.io/cdk/scrolling/api
  public static readonly ITEM_SIZE_80 = 80;

  public static readonly ITEM_SIZE_86 = 86;

  public static readonly HTTPS_PREFIX = 'https://';
}
