/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * SolutionSetupCardType
 * @export
 */
export enum SolutionSetupCardType {
  DESKTOP_EXPERIENCE_MANAGEMENT,
  MOBILE_EXPERIENCE_MANAGEMENT,
  HORIZON_CLOUD_SERVICES,
  FRONTLINE_WORKER_MANAGEMENT,
}
