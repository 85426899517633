/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * NavigationPreviewModalType
 *
 * @export
 * @enum {NavigationPreviewModalType}
 */
export enum NavigationPreviewModalType {
  AUTOMATION,
  INCIDENT,
  INSIGHT,
  SURVEY,
}
