/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

/**
 * NameLabel
 *
 * @export
 * @class NameLabel
 */
export class NameLabel {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  /**
   * Creates an instance of NameLabel.
   * @param {Array<Partial<NameLabel>>} args
   * @memberof NameLabel
   */
  constructor(...args: Array<Partial<NameLabel>>) {
    Object.assign(this, ...args);
  }
}
