/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters';

/**
 * UI Settings
 *
 * @export
 * @class UISettings
 */
@Serializable
export class UISettings {
  @JsonProperty({ name: 'preferences', customConverter: defaultValueConverterFactory() })
  public preferences: GenericObject = undefined;

  /**
   * Initializes UI Settings instance
   * @param {Array<Partial<UISettings>>} args - Initialization arguments for UISettings object
   * @memberof UISettings
   */
  constructor(...args: Array<Partial<UISettings>>) {
    Object.assign(this, ...args);
  }
}
