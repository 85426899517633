/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * ThresholdLevel
 * @export
 * @enum {string}
 */
export enum ThresholdLevel {
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
  BAD = 'BAD',
  POOR = 'POOR',
  GOOD = 'GOOD',
  NEUTRAL = 'NEUTRAL',
  MAX = 'MAX',
}
