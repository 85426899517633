/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';

/**
 * TrialBanner
 *
 * @export
 * @class TrialBanner
 */
@Serializable
export class TrialBanner {
  @JsonProperty('show_banner')
  public showBanner: boolean = undefined;

  @JsonProperty('active')
  public active: boolean = undefined;

  @JsonProperty('declined')
  public declined: boolean = undefined;

  @JsonProperty('expired')
  public expired: boolean = undefined;

  @JsonProperty({ name: 'expiration', customConverter: dateFormatConverter })
  public expiration: number = undefined;

  @JsonProperty({ name: 'start_date', customConverter: dateFormatConverter })
  public startDate: number = undefined;

  /**
   * Creates an instance of TrialBanner.
   * @param {...Array<Partial<TrialBanner>>} args
   * @memberof TrialBanner
   */
  constructor(...args: Array<Partial<TrialBanner>>) {
    Object.assign(this, ...args);
  }
}
