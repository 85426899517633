/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * MetaFormFieldGroups
 *
 * @export
 * @class MetaFormFieldGroups
 */
@Serializable
export class MetaFormFieldGroups {
  public static UNKNOWN_GROUP = '00000000-0000-0000-0000-000000000000';

  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;
}
