/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Layout Type
 *
 * @export
 * @enum {string}
 */
export enum LayoutType {
  SPLIT_VIEW = 'split',
  CARD_VIEW = 'card',
  GRID_VIEW = 'grid',
}
