/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Accept EULA Model Object
 *
 * @export
 * @class AcceptEula
 */
@Serializable
export class AcceptEula {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('email_address')
  public emailAddress: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  @JsonProperty('company_name')
  public companyName: string = undefined;

  @JsonProperty('postal_address')
  public postalAddress: string = undefined;

  /**
   * Creates an instance of AcceptEula
   * @param {any} args
   * @memberof AcceptEula
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
