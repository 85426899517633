/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter } from '@dpa/ui-common';

/**
 * Return a CustomConverter that converts enums
 *
 * @export
 * @param {any} enumModel
 * @returns {CustomConverter}
 */
export function enumConverterFactory(enumModel: any): CustomConverter {
  return {
    fromJson: (stringData: string) => enumModel[stringData],
    toJson: (enumeration: number) => enumModel[enumeration],
  } as CustomConverter;
}

/**
 * Return a CustomConverter that converts enums with uppercased parameter
 *
 * @export
 * @param {any} enumModel
 * @returns {CustomConverter}
 */
export function enumUpperCaseConverterFactory(enumModel: any): CustomConverter {
  return {
    fromJson: (stringData: string) => {
      return enumModel[stringData?.toUpperCase()];
    },
    toJson: (enumeration: number) => enumModel[enumeration],
  } as CustomConverter;
}

/**
 * Return a CustomConverter that converts enums with multi-words and uppercased parameter
 *
 * @export
 * @param {any} enumModel
 * @returns {CustomConverter}
 */
export function enumMultiWordUpperCaseConverterFactory(enumModel: any): CustomConverter {
  return {
    fromJson: (stringData: string) => enumModel[stringData?.split(' ')?.join('_')?.toUpperCase()],
    toJson: (enumeration: string) => enumModel[enumeration?.split(' ')?.join('_')?.toUpperCase()],
  } as CustomConverter;
}

/**
 * Return a CustomConverter that converts enums array
 *
 * @export
 * @param {any} enumModel
 * @returns {CustomConverter}
 */
export function enumArrayConverterFactory(enumModel: any): CustomConverter {
  return {
    fromJson: (stringArray: string[]) => {
      return stringArray?.map((stringData: string) => enumModel[stringData]);
    },
    toJson: (enumerationArray: string[] | number[]) => {
      return enumerationArray?.map((enumeration: string | number) => enumModel[enumeration]);
    },
  } as CustomConverter;
}

/**
 * Return a CustomConverter that strips out non-numeric characters
 *
 * @export
 * @returns {CustomConverter}
 */
export function stripNonNumericConverterFactory(): CustomConverter {
  return {
    fromJson(numericString: string): string {
      return numericString;
    },
    toJson(stringWithNonNumeric: string): string {
      return stringWithNonNumeric.replace(/\D/g, '');
    },
  } as CustomConverter;
}

/**
 * Return a CustomConverter that converts defaultValue data
 *
 * @export
 * @returns {CustomConverter}
 */
export function defaultValueConverterFactory(): CustomConverter {
  return {
    fromJson(data: object): object {
      return data;
    },
    toJson(data: object): object {
      return data;
    },
  } as CustomConverter;
}
