/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Type of action performed on an individual automation
 * @export
 * @enum AutomationActionType
 */
export enum AutomationActionType {
  ADD_TO_BOOKMARKS,
  COPY,
  DELETE,
  EDIT,
  PREVIEW,
  REMOVE_FROM_BOOKMARKS,
  RENAME,
  RUN,
  TOGGLE,
  VIEW,
  VIEW_SOURCE_OBJECT,
}

/**
 * Type of automation result state
 * @export
 * @enum ResultState
 */
export enum ResultState {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  COMPLETED = 'COMPLETED',
}

/**
 * Type of Automation Task
 * @export
 * @enum {string}
 */
export enum WorkflowTaskType {
  CONDITION = 'condition',
  GROUP = 'group',
  ACTION = 'action',
  STOP_WORKFLOW = 'stop_workflow',
}

/**
 * WorkflowConditionBranch
 * @export
 * @enum {string}
 */
export enum WorkflowConditionBranch {
  TRUE_BRANCH = 'TRUE_BRANCH',
  FALSE_BRANCH = 'FALSE_BRANCH',
}

/**
 * WorkflowConditionBranchKey
 * @export
 * @enum {string}
 */
export enum WorkflowConditionBranchKey {
  TRUE_BRANCH = 'trueCase',
  FALSE_BRANCH = 'falseCase',
}
