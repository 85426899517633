/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { AccessType } from '@ws1c/intelligence-models/common/access-type.enum';
import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';
import { WidgetSummary } from './widget-summary.model';

/**
 * @exports
 * @enum {string}
 */
export enum DashboardActionType {
  ADD_TO_BOOKMARKS = 'ADD_TO_BOOKMARKS',
  ADD_WIDGET = 'ADD_WIDGET',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
  EDIT = 'EDIT',
  EXPORT = 'EXPORT',
  REMOVE_FROM_BOOKMARKS = 'REMOVE_FROM_BOOKMARKS',
  RENAME = 'RENAME',
  SHARE = 'SHARE',
  TRANSFER_OWNERSHIP = 'TRANSFER_OWNERSHIP',
  VIEW = 'VIEW',
}

/**
 * Emitted event that contains the dashboard and the type of action
 * @export
 * @interface DashboardActionEvent
 */
export interface DashboardActionEvent {
  action: DashboardActionType;
  data: Dashboard;
}

/**
 * Dashboard Model Object
 * Basic Dashboard definition.
 *
 * @export
 * @class Dashboard
 */
@Serializable
export class Dashboard {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('dashboard_type')
  public dashboardType: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'dashboard_widget_summary', cls: WidgetSummary })
  public widgetSummary: WidgetSummary = undefined;

  @JsonProperty('thumbnail_widget_id')
  public thumbnailId: string = undefined;

  @JsonProperty({ name: 'share_count', excludeToJson: true })
  public shareCount: number = undefined;

  @JsonProperty({ name: 'shared_dashboard', excludeToJson: true })
  public isSharedDashboard: boolean = false;

  @JsonProperty({ name: 'owner', excludeToJson: true })
  public isOwner: boolean = false;

  @JsonProperty({ name: 'account_access_level', customConverter: enumConverterFactory(AccessType), excludeToJson: true })
  public accessLevel: AccessType = undefined;

  @JsonProperty('incident_id')
  public incidentId?: string = undefined;

  public bookmarked: boolean = false;

  /**
   * isExisting
   * @readonly
   * @type {boolean}
   * @memberOf Dashboard
   */
  public get isExisting(): boolean {
    return !!this.dashboardType;
  }

  /**
   * Creates an instance of Dashboard
   * @param {Array<Partial<Dashboard>>} args
   * @memberof Dashboard
   */
  constructor(...args: Array<Partial<Dashboard>>) {
    Object.assign(this, ...args);
  }

  /**
   * isOwnerOrHasFullAccess
   * @readonly
   * @type {boolean}
   * @memberof Dashboard
   */
  public get isOwnerOrHasFullAccess(): boolean {
    return this.isOwner || this.accessLevel === AccessType.FULL;
  }

  /**
   * isOwnerOrIsShared
   * @readonly
   * @type {boolean}
   * @memberof Dashboard
   */
  public get isOwnerOrIsShared(): boolean {
    return this.isOwner || this.isSharedDashboard;
  }

  /**
   * objectAccessLevel
   * @readonly
   * @type {AccessType}
   * @memberof Dashboard
   */
  public get objectAccessLevel(): AccessType {
    if (this.isOwner) {
      return AccessType.OWNER;
    }
    if (this.isSharedDashboard) {
      return this.accessLevel;
    }
    // if the user is not owner and it is not shared with him then access level is Manage
    return AccessType.MANAGE;
  }
}
