/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * UserPreferenceAssets
 * All available UserPreferenceAssets
 *
 * @export
 * @class UserPreferenceAssets
 */
export class UserPreferenceAssets {
  public static readonly AIRWATCH_HISTORICAL_WIDGETS_DEPRECATION_LINK: string = 'AIRWATCH_HISTORICAL_WIDGETS_DEPRECATION_LINK';
  public static readonly ASSIST_ARCHITECTURE_GUIDE_LINK: string = 'ASSIST_ARCHITECTURE_GUIDE_LINK';
  public static readonly ASSIST_DISTRIBUTED_WORKFORCE_GUIDE_LINK: string = 'ASSIST_DISTRIBUTED_WORKFORCE_GUIDE_LINK';
  public static readonly ASSIST_OVERVIEW_VIDEO_LINK: string = 'ASSIST_OVERVIEW_VIDEO_LINK';
  public static readonly ASSIST_REMOTE_SETUP_VIDEO_LINK: string = 'ASSIST_REMOTE_SETUP_VIDEO_LINK';
  public static readonly AUTOMATIONS_LEARN_MORE_LINK: string = 'AUTOMATIONS_LEARN_MORE_LINK';
  public static readonly CONSUMER_APPS_USER_GUIDE_LINK: string = 'CONSUMER_APPS_USER_GUIDE_LINK';
  public static readonly CONSUMER_APPS_SDK_GUIDE_LINK: string = 'CONSUMER_APPS_SDK_GUIDE_LINK';
  public static readonly CONSUMER_APPS_PRODUCT_PAGE_LINK: string = 'CONSUMER_APPS_PRODUCT_PAGE_LINK';
  public static readonly CONTACT_SALES_LINK: string = 'CONTACT_SALES_LINK';
  public static readonly CUSTOM_CONNECTORS_SAMPLES_LINK: string = 'CUSTOM_CONNECTORS_SAMPLES_LINK';
  public static readonly CUSTOM_CONNECTORS_USER_GUIDE_LINK: string = 'CUSTOM_CONNECTORS_USER_GUIDE_LINK';
  public static readonly CUSTOMER_CONNECT_DOWNLOADS_LINK: string = 'CUSTOMER_CONNECT_DOWNLOADS_LINK';
  public static readonly CUSTOMER_CONNECT_LINK: string = 'CUSTOMER_CONNECT_LINK';
  public static readonly CUSTOMER_CONNECT_SUPPORT_LINK: string = 'CUSTOMER_CONNECT_SUPPORT_LINK';
  public static readonly CVE_SOLUTION_DATA_DEFINITIONS_LINK: string = 'CVE_SOLUTION_DATA_DEFINITIONS_LINK';
  public static readonly CVE_SOLUTION_OVERVIEW_LINK: string = 'CVE_SOLUTION_OVERVIEW_LINK';
  public static readonly CVE_SOLUTION_USE_CASE_LINK: string = 'CVE_SOLUTION_USE_CASE_LINK';
  public static readonly DASHBOARDS_LEARN_MORE_LINK: string = 'DASHBOARDS_LEARN_MORE_LINK';
  public static readonly DEEM_INSIGHTS_OVERVIEW_VIDEO_LINK: string = 'DEEM_INSIGHTS_OVERVIEW_VIDEO_LINK';
  public static readonly DEEM_OVERVIEW_LINK: string = 'DEEM_OVERVIEW_LINK';
  public static readonly DEEM_OVERVIEW_LEARN_MORE_VIDEO_LINK: string = 'DEEM_OVERVIEW_LEARN_MORE_VIDEO_LINK';
  public static readonly DEEM_OVERVIEW_SOLUTION_ARCHITECTURE_LINK: string = 'DEEM_OVERVIEW_SOLUTION_ARCHITECTURE_LINK';
  public static readonly DIGITAL_WORKSPACE_TECHZONE_LINK: string = 'DIGITAL_WORKSPACE_TECHZONE_LINK';
  public static readonly EXPERIENCE_SCORE_DASHBOARD_OVERVIEW_VIDEO_LINK: string = 'EXPERIENCE_SCORE_DASHBOARD_OVERVIEW_VIDEO_LINK';
  public static readonly FILTER_DESCRIPTIONS_LINK: string = 'FILTER_DESCRIPTIONS_LINK';
  public static readonly HORIZON_CLOUD_OVERVIEW_LINK: string = 'HORIZON_CLOUD_OVERVIEW_LINK';
  public static readonly HORIZON_EMPLOYEE_ENGAGEMENT_GUIDE_LINK: string = 'HORIZON_EMPLOYEE_ENGAGEMENT_GUIDE_LINK';
  public static readonly INCIDENTS_OVERVIEW_LINK: string = 'INCIDENTS_OVERVIEW_LINK';
  public static readonly INCIDENTS_OVERVIEW_WATCH_VIDEO_LINK: string = 'INCIDENTS_OVERVIEW_WATCH_VIDEO_LINK';
  public static readonly INSIGHTS_NOW_AVAILABLE_LINK: string = 'INSIGHTS_NOW_AVAILABLE_LINK';
  public static readonly INSIGHTS_USER_GUIDE_LINK: string = 'INSIGHTS_USER_GUIDE_LINK';
  public static readonly INTELLIGENT_HUB_DOC_URL: string = 'INTELLIGENT_HUB_DOC_URL';
  public static readonly INTELLIGENT_HUB_OVERVIEW_VIDEO_LINK: string = 'INTELLIGENT_HUB_OVERVIEW_VIDEO_LINK';
  public static readonly ITSM_CONNECTOR_OVERVIEW_VIDEO_LINK: string = 'ITSM_CONNECTOR_OVERVIEW_VIDEO_LINK';
  public static readonly ITSM_CONNECTOR_SERVICENOW_GUIDE_LINK: string = 'ITSM_CONNECTOR_SERVICENOW_GUIDE_LINK';
  public static readonly ITSM_CONNECTOR_SERVICENOW_LINK: string = 'ITSM_CONNECTOR_SERVICENOW_LINK';
  public static readonly ITSM_CONNECTOR_SERVICENOW_WORKFLOW_GUIDE_LINK: string = 'ITSM_CONNECTOR_SERVICENOW_WORKFLOW_GUIDE_LINK';
  public static readonly MOBILE_APPS_ANALYTICS_VIDEO_LINK: string = 'MOBILE_APPS_ANALYTICS_VIDEO_LINK';
  public static readonly MARKETPLACE_RESOURCE_DEPLOY_LEARN_MORE_LINK: string = 'MARKETPLACE_RESOURCE_DEPLOY_LEARN_MORE_LINK';
  public static readonly MARKETPLACE_RESOURCE_DOWNLOAD_LEARN_MORE_LINK: string = 'MARKETPLACE_RESOURCE_DOWNLOAD_LEARN_MORE_LINK';
  public static readonly OMNISSA_PRIVACY_POLICY_LINK: string = 'OMNISSA_PRIVACY_POLICY_LINK';
  public static readonly REPORTS_AND_DASHBOARDS_TUTORIAL_LINK: string = 'REPORTS_AND_DASHBOARDS_TUTORIAL_LINK';
  public static readonly REPORTS_LEARN_MORE_LINK: string = 'REPORTS_LEARN_MORE_LINK';
  public static readonly REPORTS_SHARING_DAP_HELP_LINK: string = 'REPORTS_SHARING_DAP_HELP_LINK';
  public static readonly SDK_DOWNLOAD_LINK: string = 'SDK_DOWNLOAD_LINK';
  public static readonly SDK_SETUP_INSTRUCTIONS_URL: string = 'SDK_SETUP_INSTRUCTIONS_URL';
  public static readonly SURVEYS_MICO_SENTIMENT_LINK: string = 'SURVEYS_MICO_SENTIMENT_LINK';
  public static readonly SURVEYS_OVERVIEW_LINK: string = 'SURVEYS_OVERVIEW_LINK';
  public static readonly SURVEYS_OVERVIEW_WATCH_VIDEO_LINK: string = 'SURVEYS_OVERVIEW_WATCH_VIDEO_LINK';
  public static readonly SURVEYS_USE_CASES_LINK: string = 'SURVEYS_USE_CASES_LINK';
  public static readonly VANITY_URL_TECHZONE_LINK: string = 'VANITY_URL_TECHZONE_LINK';
  public static readonly WORKFLOW_CONDITION_ENTITY_WARNING_LEARN_MORE_LINK: string = 'WORKFLOW_CONDITION_ENTITY_WARNING_LEARN_MORE_LINK';
  public static readonly WORKSPACE_ONE_ACCESS_DOC_URL: string = 'WORKSPACE_ONE_ACCESS_DOC_URL';
  public static readonly WORKSPACE_ONE_ACCESS_TENANT_RENAME_LEARN_MORE_URL: string = 'WORKSPACE_ONE_ACCESS_TENANT_RENAME_LEARN_MORE_URL';
  public static readonly WORKSPACE_ONE_ASSIST_HORIZON_BLOG_LINK: string = 'WORKSPACE_ONE_ASSIST_HORIZON_BLOG_LINK';
  public static readonly WORKSPACE_ONE_ASSIST_HORIZON_VIDEO_LINK: string = 'WORKSPACE_ONE_ASSIST_HORIZON_VIDEO_LINK';
  public static readonly WORKSPACE_ONE_ASSIST_LINK: string = 'WORKSPACE_ONE_ASSIST_LINK';
  public static readonly WORKSPACE_ONE_ASSIST_OVERVIEW_LINK: string = 'WORKSPACE_ONE_ASSIST_OVERVIEW_LINK';
  public static readonly WORKSPACE_ONE_CLOUD_GETTING_STARTED_ADMIN_HUB_LINK: string = 'WORKSPACE_ONE_CLOUD_GETTING_STARTED_ADMIN_HUB_LINK';
  public static readonly WORKSPACE_ONE_CLOUD_RBAC_LEARN_MORE_LINK: string = 'WORKSPACE_ONE_CLOUD_RBAC_LEARN_MORE_LINK';
  public static readonly WORKSPACE_ONE_CONTENT_ANDROID_LINK: string = 'WORKSPACE_ONE_CONTENT_ANDROID_LINK';
  public static readonly WORKSPACE_ONE_CONTENT_IOS_LINK: string = 'WORKSPACE_ONE_CONTENT_IOS_LINK';
  public static readonly WORKSPACE_ONE_DEEM_DESKTOP_TELEMETRY_LINK: string = 'WORKSPACE_ONE_DEEM_DESKTOP_TELEMETRY_LINK';
  public static readonly WORKSPACE_ONE_DEEM_MOBILE_TELEMETRY_LINK: string = 'WORKSPACE_ONE_DEEM_MOBILE_TELEMETRY_LINK';
  public static readonly WORKSPACE_ONE_DEEM_LEARN_MORE_LINK: string = 'WORKSPACE_ONE_DEEM_LEARN_MORE_LINK';
  public static readonly WORKSPACE_ONE_DEEM_REQUIREMENT_LINK: string = 'WORKSPACE_ONE_DEEM_REQUIREMENT_LINK';
  public static readonly WORKSPACE_ONE_DOCUMENTATION_LINK: string = 'WORKSPACE_ONE_DOCUMENTATION_LINK';
  public static readonly WORKSPACE_ONE_IDEAS_LINK: string = 'WORKSPACE_ONE_IDEAS_LINK';
  public static readonly WORKSPACE_ONE_INTELLIGENCE_DOCUMENTATION_LINK: string = 'WORKSPACE_ONE_INTELLIGENCE_DOCUMENTATION_LINK';
  public static readonly WORKSPACE_ONE_INTELLIGENCE_OVERVIEW_LINK: string = 'WORKSPACE_ONE_INTELLIGENCE_OVERVIEW_LINK';
  public static readonly WORKSPACE_ONE_INTELLIGENCE_REGIONS_LINK: string = 'WORKSPACE_ONE_INTELLIGENCE_REGIONS_LINK';
  public static readonly WORKSPACE_ONE_INTELLIGENCE_RELEASE_NOTES_LINK: string = 'WORKSPACE_ONE_INTELLIGENCE_RELEASE_NOTES_LINK';
  public static readonly WORKSPACE_ONE_INTELLIGENT_HUB_LINK: string = 'WORKSPACE_ONE_INTELLIGENT_HUB_LINK';
  public static readonly WORKSPACE_ONE_MOBILE_APPS_LEARN_MORE_LINK: string = 'WORKSPACE_ONE_MOBILE_APPS_LEARN_MORE_LINK';
  public static readonly WORKSPACE_ONE_PRODUCTIVITY_APPS_OVERVIEW_LINK: string = 'WORKSPACE_ONE_PRODUCTIVITY_APPS_OVERVIEW_LINK';
  public static readonly WORKSPACE_ONE_SUPPORT_LINK: string = 'WORKSPACE_ONE_SUPPORT_LINK';
  public static readonly WORKSPACE_ONE_UEM_DOC_URL: string = 'WORKSPACE_ONE_UEM_DOC_URL';
  public static readonly WORKSPACE_ONE_UEM_LEARN_MORE_LINK: string = 'WORKSPACE_ONE_UEM_LEARN_MORE_LINK';
}
