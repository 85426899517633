/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * FacetType
 *
 * @export
 * @enum {string}
 */
export enum FacetType {
  TAGS = 'TAGS',
  RESOURCE_TYPE = 'RESOURCE_TYPE',
}
