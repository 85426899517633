/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Directory } from '@ws1c/intelligence-models//directory.model';

/**
 * User
 *
 * @export
 * @class User
 */
@Serializable
export class User {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('org_id')
  public orgId: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  @JsonProperty('created_at')
  public createdAt: string = undefined;

  @JsonProperty({ name: 'directory_user', cls: Directory })
  public directoryUser: Directory = undefined;

  @JsonProperty('active')
  public active: boolean = undefined;

  @JsonProperty('airwatch_identity')
  public isUemIdentity: boolean = undefined;

  @JsonProperty('csp_account_merge_status')
  public cspAccountMergeStatus: boolean = undefined;

  /**
   * Creates an instance of User.
   * @param {...Array<Partial<User>>} args
   * @memberof User
   */
  constructor(...args: Array<Partial<User>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter fullName
   *
   * @readonly
   * @type {string}
   * @memberof User
   */
  public get fullName(): string {
    return this.displayName || `${this.firstName} ${this.lastName}`;
  }
}
