/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * ActionIdSearchData
 * @export
 * @class ActionIdSearchData
 */
@Serializable
export class ActionIdSearchData {
  @JsonProperty('workflow_action_template_ids')
  public actionTemplateIds: string[] = [];

  /**
   * Creates an instance of ActionIdSearchData
   * @param {any} args
   * @memberof ActionIdSearchData
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}

/**
 * AutomationsByActionIdSearchRequest Model Object
 * @export
 * @class AutomationsByActionIdSearchRequest
 */
@Serializable
export class AutomationsByActionIdSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'search_data', cls: ActionIdSearchData })
  public searchData: ActionIdSearchData = undefined;

  /**
   * Creates an instance of AutomationsByActionIdSearchRequest
   * @param {any} args
   * @memberof AutomationsByActionIdSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}
