/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * DashboardColors
 * @export
 * @enum {string}
 */
export enum DashboardColors {
  red = '#c92100',
  orange = '#f38b00',
  navyBlue = '#004a70',
  babyBlue = '#89cbdf',
  purple = '#9460b8',
  yellow = '#efc006',
  pink = '#ffc0cb',
  green = '#62a420',
}
