/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

/**
 * LookupConfig
 * @export
 * @class LookupConfig
 */
export class LookupConfig {
  @JsonProperty('helper_action_template_id')
  public actionTemplateId: string = undefined;

  @JsonProperty('operation_id')
  public operationId: string = undefined;

  @JsonProperty('option_text_expression')
  public lookupText: string = undefined;

  @JsonProperty('option_value_expression')
  public lookupValue: string = undefined;

  /**
   * Creates an instance of LookupConfig
   *
   * @param {Array<Partial<LookupConfig>>} args
   * @memberof LookupConfig
   */
  constructor(...args: Array<Partial<LookupConfig>>) {
    Object.assign(this, ...args);
  }
}
