/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { LabelValueDescription } from '@ws1c/intelligence-models/common/label-value-description.interface';
import { EvaluationType } from './evaluation-type.enum';

/**
 * Evaluation Type config
 *
 * @export
 * @const {Record<EvaluationType, LabelValueDescription>}
 */
export const EVALUATION_TYPE_CONFIG: Record<EvaluationType, LabelValueDescription<EvaluationType>> = {
  [EvaluationType.AUTOMATIC]: {
    label: 'AUTOMATION_ACTIONS.AUTOMATIC',
    description: 'AUTOMATION_ACTIONS.AUTOMATIC_DESCRIPTION',
    value: EvaluationType.AUTOMATIC,
  },
  [EvaluationType.SCHEDULED]: {
    label: 'AUTOMATION_ACTIONS.SCHEDULE',
    description: 'AUTOMATION_ACTIONS.SCHEDULE_DESCRIPTION',
    value: EvaluationType.SCHEDULED,
  },
  [EvaluationType.MANUAL]: {
    label: 'AUTOMATION_ACTIONS.MANUAL',
    description: 'AUTOMATION_ACTIONS.MANUAL_DESCRIPTION',
    value: EvaluationType.MANUAL,
  },
};
