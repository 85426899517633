/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ConnectorAuthType
 * @export
 * @enum {string}
 */
export enum ConnectorAuthType {
  NOAUTH = 'noauth',
  BASIC = 'basic',
  OAUTH2 = 'oauth2',
}
