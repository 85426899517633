/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { RequestStatusType } from './account/request-status-type.enum';
import { enumConverterFactory } from './converters/converter-factory';

/**
 * CspAccountRequest
 *
 * @export
 * @class CspAccountRequest
 */
@Serializable
export class CspAccountRequest {
  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty({ name: 'csp_migration_status', customConverter: enumConverterFactory(RequestStatusType) })
  public cspMigrationStatus: RequestStatusType = undefined;

  /**
   * Initializes instance of Request
   * @param {Array<Partial<CspAccountRequest>>} args - object initialization arguments
   * @memberof CspAccountRequest
   */
  constructor(...args: Array<Partial<CspAccountRequest>>) {
    Object.assign(this, ...args);
  }
}
