/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntegrationTabType
 * @export
 * @enum {string}
 */
export enum IntegrationTabType {
  DATA_SOURCES = 'data-sources',
  WORKFLOW_CONNECTORS = 'workflow-connectors',
}
