/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { UserDetails } from '@ws1c/intelligence-models/user-details.model';

/**
 * Comment
 * @export
 * @class Comment
 */
@Serializable
export class Comment {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('resource_id')
  public resourceId: string = undefined;

  @JsonProperty('data')
  public text: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public author: UserDetails = undefined;

  /**
   * Creates an instance of Comment.
   * @param {...Array<Partial<Comment>>} args
   * @memberof Comment
   */
  constructor(...args: Array<Partial<Comment>>) {
    Object.assign(this, ...args);
  }
}
