/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ComparisonQueryResponse } from '@ws1c/intelligence-models/dashboard';

/**
 * Integration Usage Counts Model Object
 * @export
 * @class IntegrationObjectsCounts
 */
@Serializable
export class IntegrationObjectsCounts {
  @JsonProperty('DASHBOARD')
  public dashboard: number = undefined;

  @JsonProperty('AUTOMATION')
  public automation: number = undefined;

  @JsonProperty('REPORT')
  public report: number = undefined;

  /**
   * Creates an instance of IntegrationObjectsCounts
   * @param {Array<Partial<IntegrationObjectsCounts>>} args
   * @memberof IntegrationObjectsCounts
   */
  constructor(...args: Array<Partial<IntegrationObjectsCounts>>) {
    Object.assign(this, ...args);
  }

  /**
   * dashboardCount
   * @readonly
   * @type {ComparisonQueryResponse}
   * @memberof IntegrationObjectsCounts
   */
  public get dashboardCount(): ComparisonQueryResponse {
    return {
      lastCount: this.dashboard,
    } as ComparisonQueryResponse;
  }

  /**
   * automationCount
   * @readonly
   * @type {ComparisonQueryResponse}
   * @memberof IntegrationObjectsCounts
   */
  public get automationCount(): ComparisonQueryResponse {
    return {
      lastCount: this.automation,
    } as ComparisonQueryResponse;
  }

  /**
   * reportCount
   * @readonly
   * @type {ComparisonQueryResponse}
   * @memberof IntegrationObjectsCounts
   */
  public get reportCount(): ComparisonQueryResponse {
    return {
      lastCount: this.report,
    } as ComparisonQueryResponse;
  }
}
