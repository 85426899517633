/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * SecurityDashboardType
 * @export
 * @enum {string}
 */
export enum SecurityDashboardType {
  // For Security Risks Dashboard
  SECURITY = 'SECURITY',
  SECURITY_VULNERABILITIES_MACOS = 'SECURITY_VULNERABILITIES_MACOS',
}

/**
 * SecurityWidgetType
 * @export
 * @enum {string}
 */
export enum SecurityWidgetType {
  // For Security Risks Dashboard
  SECURITY_THREAT = 'SECURITY_THREAT',
  SECURITY_POLICY_RISK = 'SECURITY_POLICY_RISK',
  SECURITY_VULNERABILITIES_SUMMARY = 'SECURITY_VULNERABILITIES_SUMMARY',
  SECURITY_DEVICES = 'SECURITY_DEVICES',

  // For Security Risks Dashboard -> Vulnerability Summary -> Windows and Mac Dashboards
  SECURITY_WINDOWS_VULNERABILITIES = 'SECURITY_WINDOWS_VULNERABILITIES',
  SECURITY_MACOS_VULNERABILITIES = 'SECURITY_MACOS_VULNERABILITIES',
}

/**
 * SecurityWidgetSubtype
 * @export
 * @enum {string}
 */
export enum SecurityWidgetSubtype {
  // For Security Risks Dashboard
  SECURITY_ENROLLED_DEVICES_TOTAL_COUNT = 'SECURITY_ENROLLED_DEVICES_TOTAL_COUNT',
  // compromised
  SECURITY_COMPROMISED_DEVICES_BY_DEVICE_MODEL_COUNT = 'SECURITY_COMPROMISED_DEVICES_BY_DEVICE_MODEL_COUNT',
  SECURITY_COMPROMISED_DEVICES_BY_ORGANIZATION_GROUP_COUNT = 'SECURITY_COMPROMISED_DEVICES_BY_ORGANIZATION_GROUP_COUNT',
  SECURITY_COMPROMISED_DEVICES_BY_OWNERSHIP_COUNT = 'SECURITY_COMPROMISED_DEVICES_BY_OWNERSHIP_COUNT',
  SECURITY_COMPROMISED_DEVICES_BY_PLATFORM_COUNT = 'SECURITY_COMPROMISED_DEVICES_BY_PLATFORM_COUNT',
  SECURITY_COMPROMISED_DEVICES_HISTORICAL = 'SECURITY_COMPROMISED_DEVICES_HISTORICAL',
  SECURITY_COMPROMISED_DEVICES_HISTORICAL_CHANGE_EVENTS = 'SECURITY_COMPROMISED_DEVICES_HISTORICAL_CHANGE_EVENTS',
  SECURITY_COMPROMISED_DEVICES_SUMMARY = 'SECURITY_COMPROMISED_DEVICES_SUMMARY',
  SECURITY_COMPROMISED_DEVICES_UPDATES_TOTAL_COUNT = 'SECURITY_COMPROMISED_DEVICES_UPDATES_TOTAL_COUNT',
  SECURITY_COMPROMISED_DEVICES_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD = 'SECURITY_COMPROMISED_DEVICES_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD',
  __SECURITY_COMPROMISED_DEVICES_RATE = '__SECURITY_COMPROMISED_DEVICES_RATE',
  __SECURITY_COMPROMISED_DEVICES_RATE_PREVIOUS_PERIOD = '__SECURITY_COMPROMISED_DEVICES_RATE_PREVIOUS_PERIOD',
  // unencrypted
  SECURITY_ENCRYPTION_STATUS_BY_PLATFORM_COUNT = 'SECURITY_ENCRYPTION_STATUS_BY_PLATFORM_COUNT',
  SECURITY_ENCRYPTION_STATUS_BY_DEVICE_MODEL_COUNT = 'SECURITY_ENCRYPTION_STATUS_BY_DEVICE_MODEL_COUNT',
  SECURITY_ENCRYPTION_STATUS_BY_ORGANIZATION_GROUP_COUNT = 'SECURITY_ENCRYPTION_STATUS_BY_ORGANIZATION_GROUP_COUNT',
  SECURITY_ENCRYPTION_STATUS_BY_OWNERSHIP_COUNT = 'SECURITY_ENCRYPTION_STATUS_BY_OWNERSHIP_COUNT',
  SECURITY_ENCRYPTION_STATUS_HISTORICAL = 'SECURITY_ENCRYPTION_STATUS_HISTORICAL',
  SECURITY_ENCRYPTION_STATUS_HISTORICAL_CHANGE_EVENTS = 'SECURITY_ENCRYPTION_STATUS_HISTORICAL_CHANGE_EVENTS',
  SECURITY_ENCRYPTION_STATUS_UPDATES_TOTAL_COUNT = 'SECURITY_ENCRYPTION_STATUS_UPDATES_TOTAL_COUNT',
  SECURITY_ENCRYPTION_STATUS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD = 'SECURITY_ENCRYPTION_STATUS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD',
  __SECURITY_ENCRYPTION_DEVICES_RATE = '__SECURITY_ENCRYPTION_DEVICES_RATE',
  __SECURITY_ENCRYPTION_DEVICES_RATE_PREVIOUS_PERIOD = '__SECURITY_ENCRYPTION_DEVICES_RATE_PREVIOUS_PERIOD',

  // Device Risk
  SECURITY_DEVICE_RISK_COUNT = 'SECURITY_DEVICE_RISK_COUNT',
  SECURITY_DEVICE_RISK_INDICATORS = 'SECURITY_DEVICE_RISK_INDICATORS',

  // For Policy Risk
  SECURITY_ENCRYPTION_STATUS = 'SECURITY_ENCRYPTION_STATUS',
  SECURITY_ENCRYPTION_STATUS_SUMMARY = 'SECURITY_ENCRYPTION_STATUS_SUMMARY',
  SECURITY_PASSCODE_RISK = 'SECURITY_PASSCODE_RISK',
  SECURITY_PASSCODE_RISK_SUMMARY = 'SECURITY_PASSCODE_RISK_SUMMARY',

  // CVE
  SECURITY_VULNERABILITIES_SUMMARY = 'SECURITY_VULNERABILITIES_SUMMARY',
  SECURITY_VULNERABILITIES_SUMMARY_LIST = 'SECURITY_VULNERABILITIES_SUMMARY_LIST',
  SECURITY_TOP_VULNERABILITIES = 'SECURITY_TOP_VULNERABILITIES',
  SECURITY_VULNERABILITIES_TOP_BY_DEVICES_IMPACTED = 'SECURITY_VULNERABILITIES_TOP_BY_DEVICES_IMPACTED',
  SECURITY_THREAT = 'SECURITY_THREAT',
  SECURITY_THREAT_SUMMARY = 'SECURITY_THREAT_SUMMARY',
  SECURITY_VULNERABILITIES_SUMMARY_BY_CVSS_SCORE_FOR_WINDOWS = 'SECURITY_VULNERABILITIES_SUMMARY_BY_CVSS_SCORE_FOR_WINDOWS',
  SECURITY_VULNERABILITIES_SUMMARY_BY_CVSS_SCORE_FOR_MACOS = 'SECURITY_VULNERABILITIES_SUMMARY_BY_CVSS_SCORE_FOR_MACOS',
  MACOS_SECURITY_VULNERABILITIES_SUMMARY = 'MACOS_SECURITY_VULNERABILITIES_SUMMARY',
  MACOS_SECURITY_VULNERABILITIES_SUMMARY_LIST = 'MACOS_SECURITY_VULNERABILITIES_SUMMARY_LIST',
  MACOS_SECURITY_VULNERABILITIES_TOP_BY_DEVICES_IMPACTED = 'MACOS_SECURITY_VULNERABILITIES_TOP_BY_DEVICES_IMPACTED',
  MACOS_SECURITY_KNOWN_VULNERABILITIES = 'MACOS_SECURITY_KNOWN_VULNERABILITIES',
  __SECURITY_VULNERABILITIES_SUMMARY = '__SECURITY_VULNERABILITIES_SUMMARY',
}
