/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * SourceObjectType
 * @export
 * @enum {string}
 */
export enum SourceObjectType {
  INSIGHT = 'INSIGHT',
  INCIDENT = 'INCIDENT',
  SURVEY = 'SURVEY',
}
