/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * SearchTagFilterType
 *
 * @exports
 * @enum {SearchTagFilterType}
 */
export enum SearchTagFilterType {
  BOOKMARKED = 'bookmarked',
  CREATED_BY_ME = 'createdByMe',
  SHARED_WITH_ME = 'sharedWithMe',
  UNOWNED = 'unowned',
  INCIDENTS = 'incident',
  SURVEYS = 'survey',
  WORKFLOWS = 'workflow',
  FRONTLINE = 'frontline',
}
