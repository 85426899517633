/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { decodeAsString } from './base64-utils';

/**
 * CrlfFormatter
 * @export
 * @class CrlfFormatter
 */
export class CrlfFormatter {
  public static readonly CRLF = '\r\n';
  public static readonly LF = '\n';

  /**
   * ensureCRLF
   * @param {string} code
   * @returns {string}
   * @memberof CrlfFormatter
   */
  public static ensureCRLF(code: string): string {
    const text: string = decodeAsString(code);
    const crlfIndex = text.indexOf(CrlfFormatter.CRLF);
    const lfIndex = text.indexOf(CrlfFormatter.LF);
    if (lfIndex !== -1 && crlfIndex === -1) {
      // The text contains LF line endings, convert to CRLF
      return text.replace(/\n/g, CrlfFormatter.CRLF);
    }
    return text;
  }
}
