/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Counter Definition Model Object
 *
 * @export
 * @class CounterDefinition
 */
@Serializable
export class CounterDefinition {
  @JsonProperty('aggregate_attribute')
  public aggregateAttribute: string = undefined;

  @JsonProperty('aggregation_function')
  public aggregationFunction: string = undefined;

  /**
   * constructor
   * @param {Partial<CounterDefinition>[]} args
   * @memberof CounterDefinition
   */
  constructor(...args: Array<Partial<CounterDefinition>>) {
    Object.assign(this, ...args);
  }
}
