/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * TrialDurationColors
 * @export
 * @enum {string}
 */
export enum TrialDurationColors {
  PERCENT_LEFT_MORE_THAN_75 = 'bg-green',
  PERCENT_LEFT_50_TO_75 = 'bg-yellow',
  PERCENT_LEFT_25_TO_50 = 'bg-orange',
  PERCENT_LEFT_0_TO_25 = 'bg-red',
  EXPIRED = 'bg-grey',
}
