/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { UserDetails } from './user-details.model';

/**
 * Single Run Report Request.
 * @export
 * @class SingleRunReportRequest
 */
@Serializable
export class SingleRunReportRequest {
  @JsonProperty('report_id')
  public reportId: string = undefined;

  @JsonProperty({ name: 'created_at', excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  /**
   * Creates an instance of SingleRunReportRequest.
   * @param {any} args
   * @memberof SingleRunReportRequest
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
