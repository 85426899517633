/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { sortBy } from 'lodash-es';

import { Category, IntegrationCategories } from '@ws1c/intelligence-models/category.model';
import { ItemGroup } from '@ws1c/intelligence-models/item-group.model';
import { NameLabel } from '@ws1c/intelligence-models/name-label.model';
import { Tag } from './tag.model';
import { Template } from './template.model';

export type TemplateGroupItem = Template | Category;

/**
 * TemplateGroup
 * @export
 * @class TemplateGroup
 * @extends {ItemGroup}
 */
export class TemplateGroup extends ItemGroup {
  public items: TemplateGroupItem[];

  /**
   * Creates an instance of TemplateGroup.
   * @param {...Array<Partial<TemplateGroup>>} args
   * @memberof TemplateGroup
   */
  constructor(...args: Array<Partial<TemplateGroup>>) {
    super();
    Object.assign(this, ...args);
  }

  /**
   * buildCategoriesByIntegration
   * @static
   * @param {IntegrationCategories[]} integrationCatsList
   * @returns {TemplateGroup[]}
   * @memberof TemplateGroup
   */
  public static buildCategoriesByIntegration(integrationCatsList: IntegrationCategories[]): TemplateGroup[] {
    const groups = integrationCatsList
      .map(
        ({ integration, categories }: IntegrationCategories) =>
          new TemplateGroup({
            key: new NameLabel({
              name: integration.name,
              label: integration.label,
            }),
            items: categories,
          }),
      )
      .filter((group: TemplateGroup) => group.items?.length);
    return sortBy(groups, ['key', 'name']);
  }

  /**
   * buildTemplatesByIntegration
   * @static
   * @param {IntegrationCategories[]} integrationCatsList
   * @param {Template[]} templates
   * @returns {TemplateGroup[]}
   * @memberof TemplateGroup
   */
  public static buildTemplatesByIntegration(integrationCatsList: IntegrationCategories[], templates: Template[]): TemplateGroup[] {
    const groups = integrationCatsList
      .map(
        ({ integration }: IntegrationCategories) =>
          new TemplateGroup({
            key: new NameLabel({
              name: integration.name,
              label: integration.label,
            }),
            items: templates.filter((template: Template) => template.integration === integration.name),
          }),
      )
      .filter((group: TemplateGroup) => group.items?.length);
    return sortBy(groups, ['key', 'name']);
  }

  /**
   * buildTemplatesByCategory
   * @static
   * @param {Tag[]} tags
   * @param {Template[]} templates
   * @returns {TemplateGroup[]}
   * @memberof TemplateGroup
   */
  public static buildTemplatesByCategory(tags: Tag[], templates: Template[]): TemplateGroup[] {
    const groups = tags
      .map(
        (tag: Tag) =>
          new TemplateGroup({
            key: new NameLabel({
              name: tag.name,
              label: tag.label,
            }),
            items: templates.filter((template: Template) => Object.keys(template.tags).includes(tag.name)),
          }),
      )
      .filter((group: TemplateGroup) => group.items?.length);
    return sortBy(groups, ['key', 'name']);
  }

  /**
   * iconShape
   * @readonly
   * @type {string}
   * @memberof TemplateCreationGroup
   */
  public get iconShape(): string {
    const iconShapeByTemplateGroupName = {
      apps: 'applications',
      devices: 'devices',
      os_updates: 'sync',
      platform: 'layers',
      product: 'image',
      security: 'shield-check',
    };
    return iconShapeByTemplateGroupName[this.key?.name] ?? '';
  }
}
