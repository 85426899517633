/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { LookupConfig } from '@ws1c/intelligence-models/connection';
import { JsonSchemaMetadata } from '@ws1c/intelligence-models/connector/json-schema-metadata.model';

/**
 * JsonSchemaAutomationActionFieldForLookup
 * @export
 * @class JsonSchemaAutomationActionFieldForLookup
 */
@Serializable
export class JsonSchemaAutomationActionFieldForLookup {
  @JsonProperty({ name: 'field', cls: JsonSchemaMetadata })
  public field: JsonSchemaMetadata = undefined;

  @JsonProperty({ name: 'lookupConfig', cls: LookupConfig })
  public lookupConfig?: LookupConfig = undefined;

  /**
   * Creates an instance of JsonSchemaAutomationActionFieldForLookup.
   * @param {Array<Partial<JsonSchemaAutomationActionFieldForLookup>>} args
   * @memberof JsonSchemaAutomationActionFieldForLookup
   */
  constructor(...args: Array<Partial<JsonSchemaAutomationActionFieldForLookup>>) {
    Object.assign(this, ...args);
  }
}
