/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { find, get, map, set } from 'lodash-es';

import { NgxSingleData } from './ngx-chart-data-builder.model';
import { NgxChartLabels } from './ngx-chart-labels.model';
import { KeyValuePair, StartEndDateStr, StartEndDateStrIndex } from './ngx-common.interface';
import { NgxTrendResultFlattener } from './ngx-trend-result-flattener.model';

export interface TableTooltip {
  filters: KeyValuePair[];
  rollingDateBucket: StartEndDateStr;
  tableData: KeyValuePair[];
  tableGroupedBy: string;
  tableGroupedByLabel: string;
  counterValue: any;
  counterFormattedValue: string;
}

/**
 * NgxTooltipBuilder
 * @export
 * @class NgxTooltipBuilder
 */
export class NgxTooltipBuilder {
  public tooltipCache: any = {};

  /**
   * constructor
   * @param {string[]} groupBys
   * @param {boolean} isRolling
   * @param {NgxChartLabels} labels
   * @param {StartEndDateStrIndex} startEndDateStrByDateValue
   * @param {NgxSingleData} rootNode
   * @memberof NgxTooltipBuilder
   */
  constructor(
    private groupBys: string[],
    private isRolling: boolean,
    private labels: NgxChartLabels,
    private startEndDateStrByDateValue: StartEndDateStrIndex,
    private rootNode: NgxSingleData,
  ) {}

  /**
   * getTooltip
   * @param {any} modelValue
   * @param {any[]} extraFilters - used by bubble chart to add additional info to the tooltip
   * @param {any[]} groupByValues
   * @returns {TableTooltip}
   * @memberof NgxTooltipBuilder
   */
  public getTooltip(modelValue: any, extraFilters: any[] = [], ...groupByValues: any[]): TableTooltip {
    groupByValues = groupByValues.filter((item) => !Array.isArray(item));
    let cachedTooltip: TableTooltip = get(this.tooltipCache, groupByValues);
    if (!cachedTooltip) {
      cachedTooltip = this.buildTooltip(modelValue, groupByValues);
      set(this.tooltipCache, groupByValues, cachedTooltip);
    }
    return {
      ...cachedTooltip,
      filters: [...cachedTooltip.filters, ...extraFilters],
    };
  }

  /**
   * buildTooltip
   * @param {any} modelValue
   * @param {any[]} groupByValues
   * @returns {TableTooltip}
   * @memberof NgxTooltipBuilder
   */
  public buildTooltip(modelValue: any, groupByValues: any[]): TableTooltip {
    const filters = groupByValues
      .filter(Boolean)
      .map((groupByValue: string, index: number) => {
        const attributeKey: string = this.groupBys[index];
        return {
          keyStr: attributeKey,
          keyLabel: this.labels.byFlatKey[attributeKey],
          value: groupByValue,
          rawValue: groupByValue,
        } as KeyValuePair;
      })
      .filter((tooltipFilter: KeyValuePair) => {
        return tooltipFilter.keyStr !== NgxTrendResultFlattener.FAKE_GROUP_BY_KEY;
      });

    const dateFilter = filters.find((filter: KeyValuePair) => filter.keyStr === NgxTrendResultFlattener.DATE_KEY);

    let rollingDateBucket: StartEndDateStr;
    if (this.isRolling) {
      rollingDateBucket = {
        ...this.startEndDateStrByDateValue[dateFilter?.value ?? ''],
      } as StartEndDateStr;
    }

    if (dateFilter) {
      const { startDateStr, endDateStr } = this.startEndDateStrByDateValue[dateFilter?.value ?? ''] ?? {};

      if (startDateStr !== endDateStr) {
        dateFilter.value = `${startDateStr} - ${endDateStr}`;
      }
    }

    // UnstackedLineChart tooltip uses an object
    const counterValue = modelValue?.value ?? modelValue;
    // UnstackedLineChart with min/max (range)
    let counterFormattedValue = this.labels.counterFormatter(counterValue);
    if (modelValue && typeof modelValue === 'object' && 'min' in modelValue && 'max' in modelValue) {
      const range: string = [this.labels.counterFormatter(modelValue.min), this.labels.counterFormatter(modelValue.max)].join(' - ');
      counterFormattedValue = range;
    }
    return {
      filters,
      rollingDateBucket,
      ...this.getTableData(groupByValues),
      counterValue,
      counterFormattedValue,
    } as TableTooltip;
  }

  /**
   * getTableData
   * @param {any[]} groupByValues
   * @returns {Partial<TableTooltip>}
   * @memberof NgxTooltipBuilder
   */
  public getTableData(groupByValues: any[]): Partial<TableTooltip> {
    if (groupByValues.length >= this.groupBys.length) {
      return {} as Partial<TableTooltip>;
    }
    const tableGroupedBy = this.groupBys[groupByValues.length];
    const tableGroupedByLabel = this.labels.byFlatKey[tableGroupedBy];
    const tableData: KeyValuePair[] = this.getFlatTrendResultValueTable(groupByValues);
    return {
      tableGroupedBy,
      tableGroupedByLabel,
      tableData,
    } as Partial<TableTooltip>;
  }

  /**
   * getFlatTrendResultValueTable
   * @param {any[]} groupByValues
   * @returns {KeyValuePair[]}
   * @memberof NgxTooltipBuilder
   */
  public getFlatTrendResultValueTable(groupByValues: any[]): KeyValuePair[] {
    const seriesNode = this.getSeriesNodeByGroupByValues(this.rootNode, groupByValues);
    if (!seriesNode) {
      return [];
    }
    return map(seriesNode.series, (singleData: NgxSingleData) => {
      return {
        keyLabel: singleData.name,
        value: this.labels.counterFormatter(singleData.value),
        rawValue: singleData.value,
      } as KeyValuePair;
    })
      .filter((tableItem: KeyValuePair) => Boolean(tableItem.value))
      .sort((tableItem1: KeyValuePair, tableItem2: KeyValuePair) => tableItem2.rawValue - tableItem1.rawValue);
  }

  /**
   * getSeriesNodeByGroupByValues
   * @param {NgxSingleData} rootNode
   * @param {any[]} groupByValues
   * @returns {NgxSingleData}
   */
  public getSeriesNodeByGroupByValues(rootNode: NgxSingleData, groupByValues: any[]): NgxSingleData {
    let node = rootNode;
    for (const groupByValue of groupByValues) {
      node = find(node.series, (seriesNode: NgxSingleData) => seriesNode.name === groupByValue);
      if (!node) {
        return;
      }
    }
    return node;
  }
}
