/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

import { NameLabel } from './name-label.model';

/**
 * ItemGroup
 * @export
 * @class ItemGroup
 */
export class ItemGroup {
  public key: NameLabel;
  public items: GenericObject[];

  /**
   * Creates an instance of ItemGroup.
   * @param {...Array<Partial<ItemGroup>>} args
   * @memberof ItemGroup
   */
  constructor(...args: Array<Partial<ItemGroup>>) {
    Object.assign(this, ...args);
  }

  /**
   * name
   * @readonly
   * @type {string}
   * @memberof TemplateGroup2
   */
  public get name(): string {
    return this.key?.name;
  }

  /**
   * label
   * @readonly
   * @type {string}
   * @memberof TemplateGroup2
   */
  public get label(): string {
    return this.key?.label;
  }
}
