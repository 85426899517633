/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ResourceType
 *
 * @export
 * @enum {ResourceType}
 */
export enum ResourceType {
  INCIDENTS = 'incidents',
  INSIGHTS = 'insights',
}
