/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { IntegrationResultCode } from './integration.enum';
import { Integration } from './integration.model';

export const ERROR_LOG_FILE_NAME: string = 'error_log.json';

export const INTEGRATION_TYPE_TO_LABEL_MAP: Record<string, string> = {
  [Integration.AIRWATCH]: 'CURRENT_USER.WS1_UEM_CONSOLE',
  [Integration.IDM]: 'CURRENT_USER.WS1_ACCESS_CONSOLE',
  [Integration.HORIZON]: 'CURRENT_USER.HORIZON_CONSOLE',
  [Integration.HUB]: 'CURRENT_USER.HUB_SERVICES_CONSOLE',
};

export const INTEGRATION_RESULT_CODE_TO_DESP: Record<string, Record<string, string>> = {
  [Integration.HORIZON]: {
    [IntegrationResultCode.UNKNOWN]: 'AUTHORIZE_FAILURE_UNKNOWN_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_OPT_IN]: 'AUTHORIZE_FAILURE_OPT_IN_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_OPT_OUT]: 'AUTHORIZE_FAILURE_OPT_OUT_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_OPT_IN_TENANT_BINDING]: 'AUTHORIZE_FAILURE_OPT_IN_TENENT_BINDING_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_OPT_IN_INVALID_DATA]: 'AUTHORIZE_FAILURE_OPT_IN_INVALID_DATA_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_CROSS_REGION_CHECK]: 'AUTHORIZE_FAILURE_CROSS_REGION_CHECK_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_ALREADY_OPT_IN]: 'AUTHORIZE_FAILURE_ALREADY_OPT_IN_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_HORIZON_NOT_OPT_IN_MONITORING_SERVICE]:
      'AUTHORIZE_FAILURE_MONITORING_SERVICE_DISABLED_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_INVALID_CALLBACKURL]: 'AUTHORIZE_FAILURE_INVALID_CALLBACKURL_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_DECLINE]: 'AUTHORIZE_FAILURE_DECLINE_DESCRIPTION',
    [IntegrationResultCode.ERROR_FAILED_INTEL_ONBOARD_DEPENDENCY_FAIL]: 'AUTHORIZE_FAILURE_DEPENDENCY_FAIL_DESCRIPTION',
  },
  [Integration.HUB]: {
    [IntegrationResultCode.UNKNOWN]: 'AUTHORIZE_FAILURE_UNKNOWN_DESCRIPTION',
    [IntegrationResultCode.OPT_IN_DENIED_ERROR_CODE]: 'AUTHORIZE_FAILURE_OPT_IN_DENIED_DESCRIPTION',
    [IntegrationResultCode.EXPIRED_TOKEN_ERROR_CODE]: 'AUTHORIZE_FAILURE_EXPIRED_TOKEN_DESCRIPTION',
    [IntegrationResultCode.TENANT_REGISTRATION_FAILURE_ERROR_CODE]: 'AUTHORIZE_FAILURE_TENANT_REGISTRATION_DESCRIPTION',
    [IntegrationResultCode.TENANT_ALREADY_INTEGRATED_ERROR_CODE]: 'AUTHORIZE_FAILURE_TENANT_ALREADY_INTEGRATED_DESCRIPTION',
    [IntegrationResultCode.INTELLIGENCE_DOMAIN_MISMATCH_ERROR_CODE]: 'AUTHORIZE_FAILURE_INTELLIGENCE_DOMAIN_MISMATCH_DESCRIPTION',
    [IntegrationResultCode.INTELLIGENCE_ADAPTER_CREATION_FAILURE_ERROR_CODE]: 'AUTHORIZE_FAILURE_INTELLIGENCE_ADAPTER_CREATION_DESCRIPTION',
    [IntegrationResultCode.OAUTH_CLIENT_CREATION_FAILURE_ERROR_CODE]: 'AUTHORIZE_FAILURE_OAUTH_CLIENT_CREATION_DESCRIPTION',
    [IntegrationResultCode.SERVICE_CONFIG_CREATION_FAILURE_ERROR_CODE]: 'AUTHORIZE_FAILURE_SERVICE_CONFIG_CREATION_DESCRIPTION',
    [IntegrationResultCode.INVALID_SERVICE_TYPE_ERROR_CODE]: 'AUTHORIZE_FAILURE_INVALID_SERVICE_TYPE_DESCRIPTION',
    [IntegrationResultCode.INVALID_SERVICE_CREDENTIALS_ERROR_CODE]: 'AUTHORIZE_FAILURE_INVALID_SERVICE_CREDENTIALS_DESCRIPTION',
  },
};
