/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * AllHostTab
 * @export
 * @enum {string}
 */
export enum AllHostTab {
  HTTP_ERRORS = 'HTTP_ERRORS',
  NUMBER_OF_CALLS = 'NUMBER_OF_CALLS',
  ERROR_RATE = 'ERROR_RATE',
  AVG_LATENCY = 'AVG_LATENCY',
  BYTES_RECEIVED = 'BYTES_RECEIVED',
  BYTES_SENT = 'BYTES_SENT',
}
