/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';

/**
 * Threshold
 * @export
 * @class Threshold
 */
@Serializable
export class Threshold {
  @JsonProperty('unit')
  public unit: string = undefined;

  @JsonProperty({ name: 'values', customConverter: defaultValueConverterFactory() })
  public values: { [thresholdLevel: string]: number } = undefined;

  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty('precision')
  public precision?: number = undefined;

  /**
   * Creates an instance of Threshold.
   * @param {Array<Partial<Threshold>>} args
   * @memberof Threshold
   */
  constructor(...args: Array<Partial<Threshold>>) {
    Object.assign(this, ...args);
  }
}
