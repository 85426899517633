/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import { flatMap } from 'lodash-es';

import { dateFormatConverter, enumMultiWordUpperCaseConverterFactory } from '@ws1c/intelligence-models/converters';
import { MarketplaceResourceProperty } from '@ws1c/intelligence-models/marketplace/marketplace-resource-property.enum';
import { getUniqueId } from '@ws1c/intelligence-models/utils';
import { MarketplaceResourceAggregateType } from './marketplace-resource-aggregate-type.enum';
import { MarketplaceResourceAggregate } from './marketplace-resource-aggregate.model';
import { MarketplaceResourceLabelType } from './marketplace-resource-label-type.enum';
import { MarketplaceResourceLabel } from './marketplace-resource-label.model';
import { MarketplaceResourceMetadata } from './marketplace-resource-metadata.model';
import { MarketplaceResourceTag } from './marketplace-resource-tag.model';
import { MarketplaceResourceType } from './marketplace-resource-type.enum';

/**
 * MarketplaceResource
 * @export
 * @class MarketplaceResource
 */
@Serializable
export class MarketplaceResource {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('icon_url')
  public iconUrl: string = undefined;

  @JsonProperty({ name: 'resource_type', customConverter: enumMultiWordUpperCaseConverterFactory(MarketplaceResourceType) })
  public resourceType: MarketplaceResourceType = undefined;

  @JsonProperty({ name: 'tags', cls: MarketplaceResourceTag })
  public tags: MarketplaceResourceTag[] = undefined;

  @JsonProperty('resource_lookup_key')
  public resourceLookupKey: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty({ name: 'resource_aggregates', cls: MarketplaceResourceAggregate })
  public resourceAggregates: MarketplaceResourceAggregate[] = [];

  @JsonProperty('entity')
  public entity: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'resource_details', cls: MarketplaceResourceMetadata })
  public resourceDetails: MarketplaceResourceMetadata[] = undefined;

  @JsonProperty({ name: 'resource_labels', cls: MarketplaceResourceLabel })
  public resourceLabels: MarketplaceResourceLabel[] = [];

  public categoryId: string;

  public code: string;

  public entitiesByIntegration: Record<string, string[]>;

  public flatTags: string[];

  public id: string;

  public sourceTemplateId: string;

  public integrationsRequired: string[];

  public metadataByProperty: Record<string, string>;

  public showAddToWorkspaceButton: boolean;

  public showDeployButton: boolean;

  public showDownloadButton: boolean;

  public templateId: string;

  public prerequisites: string[];

  public variables: Array<Record<string, string>>;

  public widgetImageUrls: string[];

  public actionCountByAggregateType: Record<string, number> = {};

  public usageCount: number;

  public customTagText: string;

  public customTagType: MarketplaceResourceLabelType;

  /**
   * Creates an instance of MarketplaceResource.
   * @param {Array<Partial<MarketplaceResource>>} args
   * @memberof MarketplaceResource
   */
  constructor(...args: Array<Partial<MarketplaceResource>>) {
    Object.assign(this, ...args);
  }

  /**
   * updateProperties
   * @memberof MarketplaceResource
   */
  @Deserialize.after()
  public updateProperties() {
    this.flatTags = flatMap(this.tags, (tag: MarketplaceResourceTag) => {
      if (tag.subCategories?.length) {
        return tag.subCategories;
      }
      return tag.category;
    });
    this.metadataByProperty =
      this.resourceDetails?.reduce(
        (accum: Record<string, string>, metadata: MarketplaceResourceMetadata) => ({
          ...accum,
          [metadata.property]: metadata.value,
        }),
        {},
      ) ?? {};

    this.categoryId = getUniqueId(this.integration, this.entity);
    this.id = this.computeId();
    this.sourceTemplateId = this.computeSourceTemplateId();
    this.entitiesByIntegration = this.getEntitiesByIntegration();
    this.widgetImageUrls = this.getWidgetImageUrls();
    this.integrationsRequired = this.getIntegrationsRequired();
    this.prerequisites = this.getPrerequisites();
    this.variables = this.getVariables();
    this.code = this.getCode();
    this.showAddToWorkspaceButton = [
      MarketplaceResourceType.AUTOMATION_TEMPLATE,
      MarketplaceResourceType.WIDGET_TEMPLATE,
      MarketplaceResourceType.DASHBOARD_TEMPLATE,
      MarketplaceResourceType.REPORT_TEMPLATE,
    ].includes(this.resourceType);
    this.showDownloadButton = [MarketplaceResourceType.UEM_FREESTYLE_WORKFLOW_TEMPLATE].includes(this.resourceType);
    this.showDeployButton = [MarketplaceResourceType.SCRIPT_TEMPLATE, MarketplaceResourceType.SENSOR_TEMPLATE].includes(this.resourceType);
    this.templateId = this.getTemplateId();
    this.actionCountByAggregateType = this.resourceAggregates?.reduce(
      (actionCountByAggregateType: Record<string, number>, resourceAggregate: MarketplaceResourceAggregate) => {
        actionCountByAggregateType[resourceAggregate.eventType] = resourceAggregate.count;
        return actionCountByAggregateType;
      },
      {},
    );
    this.usageCount = this.showDeployButton
      ? this.actionCountByAggregateType?.[MarketplaceResourceAggregateType.DEPLOYED]
      : this.actionCountByAggregateType?.[MarketplaceResourceAggregateType.SAVED];
    if (!this.resourceLabels?.length) {
      return;
    }
    this.customTagText = this.resourceLabels[0].label;
    this.customTagType = this.resourceLabels[0].name;
  }

  /**
   * computeId
   * @returns {string}
   * @memberof MarketplaceResource
   */
  public computeId(): string {
    if (this.integration === 'null' || this.entity === 'null') {
      return getUniqueId(this.resourceLookupKey);
    }
    if (this.integration && this.entity) {
      return getUniqueId(this.integration, this.entity, this.resourceLookupKey);
    }
    return getUniqueId(this.resourceLookupKey);
  }

  /**
   * computeSourceTemplateId
   * @returns {string}
   * @memberof MarketplaceResource
   */
  public computeSourceTemplateId(): string {
    if (
      ![
        MarketplaceResourceType.AUTOMATION_TEMPLATE,
        MarketplaceResourceType.REPORT_TEMPLATE,
        MarketplaceResourceType.WIDGET_TEMPLATE,
      ].includes(this.resourceType)
    ) {
      return;
    }
    return this.getFQN(this.integration, this.entity, this.resourceLookupKey);
  }

  /**
   * getEntitiesByIntegration
   * @returns {Record<string, string[]>}
   * @memberof MarketplaceResource
   */
  public getEntitiesByIntegration(): Record<string, string[]> {
    const json = this.metadataByProperty[MarketplaceResourceProperty.ENTITIES_BY_INTEGRATION];
    return json ? JSON.parse(json) : { [this.integration]: [this.entity] };
  }

  /**
   * getWidgetImageUrls
   * @returns {string[]}
   * @memberof MarketplaceResource
   */
  public getWidgetImageUrls(): string[] {
    const json = this.metadataByProperty[MarketplaceResourceProperty.WIDGET_IMAGE_URLS];
    return json ? JSON.parse(json) : [];
  }

  /**
   * getIntegrationsRequired
   * @returns {string[]}
   * @memberof MarketplaceResource
   */
  public getIntegrationsRequired(): string[] {
    const json = this.metadataByProperty[MarketplaceResourceProperty.INTEGRATIONS_REQUIRED];
    return json ? JSON.parse(json) : [];
  }

  /**
   * getPrerequisites
   * @returns {string[]}
   * @memberof MarketplaceResource
   */
  public getPrerequisites(): string[] {
    const json = this.metadataByProperty[MarketplaceResourceProperty.PREREQUISITES];
    return json ? JSON.parse(json) : [];
  }

  /**
   * getVariables
   * @returns {Array<Record<string, string>>}
   * @memberof MarketplaceResource
   */
  public getVariables(): Array<Record<string, string>> {
    const json = this.metadataByProperty[MarketplaceResourceProperty.VARIABLES];
    return json ? JSON.parse(json) : [];
  }

  /**
   * getCode
   * @returns {string}
   * @memberof MarketplaceResource
   */
  public getCode(): string {
    return this.metadataByProperty[MarketplaceResourceProperty.CODE] || '';
  }

  /**
   * getTemplateId
   * @returns {string}
   * @memberof MarketplaceResource
   */
  public getTemplateId(): string {
    return this.metadataByProperty[MarketplaceResourceProperty.TEMPLATE_ID] || '';
  }

  /**
   * getFQN
   * @param {string[]} params
   * @returns {string}
   * @memberof MarketplaceResource
   */
  private getFQN(...params: string[]): string {
    return (params || []).filter((element) => element && element !== 'null').join('.');
  }
}
