/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';

import { defaultValueConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';

/**
 * Org Health Metric Model Object
 *
 * @export
 * @class OrgHealthMetric
 */
@Serializable
export class OrgHealthMetric {
  @JsonProperty('category')
  public category: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('key')
  public key: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('status')
  public status: string = undefined;

  @JsonProperty('sub_key')
  public subKey: string = undefined;

  @JsonProperty({ name: 'value', customConverter: defaultValueConverterFactory() })
  public value: any = undefined;

  // Error Log metric key identifier
  private static readonly errorLogMetricKey = 'integration.statistics.error.log';

  /**
   * Creates an instance of OrgHealthMetric
   * @param {Array<Partial<OrgHealthMetric>>} args - initialization arguments for OrgHealthMetric object
   * @memberof OrgHealthMetric
   */
  constructor(...args: Array<Partial<OrgHealthMetric>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter for metricHasErrorLog
   * Return true only if the metric is error log metric and has error log present
   *
   * @readonly
   * @returns {boolean}
   * @memberof OrgHealthMetric
   */
  public get metricHasErrorLog(): boolean {
    return this.key === OrgHealthMetric.errorLogMetricKey && !isEmpty(this.value);
  }
}
