/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppsDashboardType
 * @export
 * @enum {string}
 */
export enum AppsDashboardType {
  // For Apps Dashboard
  APP_DEPLOYMENT = 'APP_DEPLOYMENT',
  APP_ERROR_ANDROID = 'APP_ERROR_ANDROID',
  APP_ERROR_IOS = 'APP_ERROR_IOS',
  APP_ERRORS_ANDROID = 'APP_ERRORS_ANDROID',
  APP_ERRORS_IOS = 'APP_ERRORS_IOS',
  APP_EXPERIENCE_ANDROID = 'APP_EXPERIENCE_ANDROID',
  APP_EXPERIENCE_IOS = 'APP_EXPERIENCE_IOS',
  APP_HANDLED_EXCEPTION_ANDROID = 'APP_HANDLED_EXCEPTION_ANDROID',
  APP_HANDLED_EXCEPTION_IOS = 'APP_HANDLED_EXCEPTION_IOS',
  APP_HANDLED_EXCEPTIONS_ANDROID = 'APP_HANDLED_EXCEPTIONS_ANDROID',
  APP_HANDLED_EXCEPTIONS_IOS = 'APP_HANDLED_EXCEPTIONS_IOS',
  APP_PLUGIN_EXCEPTION = 'APP_PLUGIN_EXCEPTION',
  APP_PLUGIN_EXCEPTIONS = 'APP_PLUGIN_EXCEPTIONS',
  APP_SUMMARY = 'APP_SUMMARY',
  STANDALONE_APTELIGENT_APP_SUMMARY = 'STANDALONE_APTELIGENT_APP_SUMMARY',
  IDM_APPS = 'IDM_APPS',
}

/**
 * AppsWidgetType
 * @export
 * @enum {string}
 */
export enum AppsWidgetType {
  // For Airwatch App Details Dashboard
  APP_DEPLOYMENT_APP_VERSION_OVER_TIME = 'APP_DEPLOYMENT_APP_VERSION_OVER_TIME',
  APP_DEPLOYMENT_INSTALL_OVER_TIME = 'APP_DEPLOYMENT_INSTALL_OVER_TIME',
  APP_DEPLOYMENT_MISSING_APP_REASON = 'APP_DEPLOYMENT_MISSING_APP_REASON',
  APP_DEPLOYMENT_MISSING_APP_SUMMARY = 'APP_DEPLOYMENT_MISSING_APP_SUMMARY',
}

/**
 * AppsWidgetSubtype
 * @export
 * @enum {string}
 */
export enum AppsWidgetSubtype {
  // For Apps Dashboard
  APTELIGENT_APP_UNIQUE_USERS_LAST_24_HOURS = 'APTELIGENT_APP_UNIQUE_USERS_LAST_24_HOURS',
  APTELIGENT_APP_UNIQUE_USERS_LAST_24_HOURS_OFFSET_1 = 'APTELIGENT_APP_UNIQUE_USERS_LAST_24_HOURS_OFFSET_1',
  APTELIGENT_APP_UNIQUE_USERS_LAST_28_DAYS = 'APTELIGENT_APP_UNIQUE_USERS_LAST_28_DAYS',
  APTELIGENT_APP_UNIQUE_USERS_LAST_28_DAYS_OFFSET_1 = 'APTELIGENT_APP_UNIQUE_USERS_LAST_28_DAYS_OFFSET_1',
  APTELIGENT_APP_LOADS_LAST_28_DAYS = 'APTELIGENT_APP_LOADS_LAST_28_DAYS',
  APTELIGENT_APP_LOADS_LAST_28_DAYS_OFFSET_1 = 'APTELIGENT_APP_LOADS_LAST_28_DAYS_OFFSET_1',
  APTELIGENT_APP_LOADS = 'APTELIGENT_APP_LOADS',
  APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS = 'APTELIGENT_APP_ROLLING_DAILY_ACTIVE_USERS',
  APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS = 'APTELIGENT_APP_ROLLING_MONTHLY_ACTIVE_USERS',
  __APTELIGENT_APP_ROLLING_DAU_OVER_MAU = '__APTELIGENT_APP_ROLLING_DAU_OVER_MAU',

  APP_ERRORS_BY_EVENT_NAME = 'APP_ERRORS_BY_EVENT_NAME',
  APP_ERRORS_CRASH_EVENTS_COUNT = 'APP_ERRORS_CRASH_EVENTS_COUNT',
  APP_ERRORS_CRASH_EVENTS_COUNT_PREVIOUS_PERIOD = 'APP_ERRORS_CRASH_EVENTS_COUNT_PREVIOUS_PERIOD',
  APP_ERRORS_HANG_EVENTS_COUNT = 'APP_ERRORS_HANG_EVENTS_COUNT',
  APP_ERRORS_HANG_EVENTS_COUNT_PREVIOUS_PERIOD = 'APP_ERRORS_HANG_EVENTS_COUNT_PREVIOUS_PERIOD',
  APP_ERRORS_COMPLETE_EVENTS_COUNT = 'APP_ERRORS_COMPLETE_EVENTS_COUNT',
  APP_ERRORS_COMPLETE_EVENTS_COUNT_PREVIOUS_PERIOD = 'APP_ERRORS_COMPLETE_EVENTS_COUNT_PREVIOUS_PERIOD',

  // For app detail errors widget with header and footer data
  APP_ERRORS_CRASHES_HISTORICAL = 'APP_ERRORS_CRASHES_HISTORICAL',
  APP_ERRORS_HANDLED_EXCEPTIONS_HISTORICAL = 'APP_ERRORS_HANDLED_EXCEPTIONS_HISTORICAL',
  APP_ERRORS_CRASHES_COUNT = 'APP_ERRORS_CRASHES_COUNT',
  APP_ERRORS_TOTAL_COUNT = 'APP_ERRORS_TOTAL_COUNT',
  APP_ERRORS_CRASHES_COUNT_PREVIOUS_PERIOD = 'APP_ERRORS_CRASHES_COUNT_PREVIOUS_PERIOD',
  APP_ERRORS_TOTAL_COUNT_PREVIOUS_PERIOD = 'APP_ERRORS_TOTAL_COUNT_PREVIOUS_PERIOD',
  APP_ERRORS_CRASHES_BY_OS_VERSION_COUNT = 'APP_ERRORS_CRASHES_BY_OS_VERSION_COUNT',
  APP_ERRORS_CRASHES_BY_ERROR_TYPE_COUNT = 'APP_ERRORS_CRASHES_BY_ERROR_TYPE_COUNT',
  APP_ERRORS_CRASHES_BY_DEVICE_MODEL_COUNT = 'APP_ERRORS_CRASHES_BY_DEVICE_MODEL_COUNT',
  APP_ERRORS_CRASHES_BY_APP_VERSION_COUNT = 'APP_ERRORS_CRASHES_BY_APP_VERSION_COUNT',
  APP_ERRORS_HE_CRASHES_COUNT = 'APP_ERRORS_HE_CRASHES_COUNT',
  APP_ERRORS_HE_CRASHES_COUNT_PREVIOUS_PERIOD = 'APP_ERRORS_HE_CRASHES_COUNT_PREVIOUS_PERIOD',
  APP_ERRORS_EXCEPTION_LIST = 'APP_ERRORS_EXCEPTION_LIST',
  __APP_ERRORS_CRASH_RATE = '__APP_ERRORS_CRASH_RATE',
  __APP_ERRORS_CRASH_RATE_PREVIOUS_PERIOD = '__APP_ERRORS_CRASH_RATE_PREVIOUS_PERIOD',

  // For app detail error widgets
  APP_ERROR_OCCURENCES_HISTORICAL = 'APP_ERROR_OCCURENCES_HISTORICAL',
  APP_ERROR_USERS_AFFECTED_COUNT = 'APP_ERROR_USERS_AFFECTED_COUNT',
  APP_ERROR_OCCURENCES_BY_APP_VERSION_COUNT = 'APP_ERROR_OCCURENCES_BY_APP_VERSION_COUNT',
  APP_ERROR_OCCURENCES_BY_DEVICE_MODEL_COUNT = 'APP_ERROR_OCCURENCES_BY_DEVICE_MODEL_COUNT',
  APP_ERROR_OCCURENCES_BY_OS_VERSION_COUNT = 'APP_ERROR_OCCURENCES_BY_OS_VERSION_COUNT',
  APP_ERROR_OCCURENCES_BY_CARRIER_COUNT = 'APP_ERROR_OCCURENCES_BY_CARRIER_COUNT',
  APP_ERROR_OCCURENCES_BY_ORIENTATION_COUNT = 'APP_ERROR_OCCURENCES_BY_ORIENTATION_COUNT',
  APP_ERROR_OCCURENCES_BY_LOCALE_COUNT = 'APP_ERROR_OCCURENCES_BY_LOCALE_COUNT',
  APP_ERROR_OCCURENCES_BY_ONLINE_COUNT = 'APP_ERROR_OCCURENCES_BY_ONLINE_COUNT',
  APP_ERROR_OCCURENCES_BY_MEMORY_USAGE = 'APP_ERROR_OCCURENCES_BY_MEMORY_USAGE',
  APP_ERROR_OCCURENCES_BY_DISK_SPACE_FREE = 'APP_ERROR_OCCURENCES_BY_DISK_SPACE_FREE',
  APP_ERROR_OCCURENCES_BY_DISK_SPACE_TOTAL = 'APP_ERROR_OCCURENCES_BY_DISK_SPACE_TOTAL',
  APP_ERROR_USER_LIST = 'APP_ERROR_USER_LIST',
  __APP_ERROR_OCCURENCES_BY_MEMORY_USAGE_SNAPSHOT = '__APP_ERROR_OCCURENCES_BY_MEMORY_USAGE_SNAPSHOT',
  __APP_ERROR_OCCURENCES_BY_DISK_SPACE_FREE_SNAPSHOT = '__APP_ERROR_OCCURENCES_BY_DISK_SPACE_FREE_SNAPSHOT',
  __APP_ERROR_OCCURENCES_BY_DISK_SPACE_TOTAL_SNAPSHOT = '__APP_ERROR_OCCURENCES_BY_DISK_SPACE_TOTAL_SNAPSHOT',

  // For Airwatch App Summary Dashboard
  APP_SUMMARY_TOTAL_APPS_SUMMARY = 'APP_SUMMARY_TOTAL_APPS_SUMMARY',
  APP_SUMMARY_APPS_BY_PLATFORM = 'APP_SUMMARY_APPS_BY_PLATFORM',
  APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS = 'APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS',
  APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS_OFFSET_1 = 'APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS_OFFSET_1',
  APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS_TREND = 'APP_SUMMARY_DEPLOYED_MOBILE_NATIVE_APPS_TREND',
  APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS = 'APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS',
  APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS_OFFSET_1 = 'APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS_OFFSET_1',
  APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS_TREND = 'APP_SUMMARY_DEPLOYED_DESKTOP_NATIVE_APPS_TREND',
  APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS = 'APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS',
  APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS_OFFSET_1 = 'APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS_OFFSET_1',
  APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS_TREND = 'APP_SUMMARY_DEPLOYED_IDP_ENABLED_APPS_TREND',
  APP_SUMMARY_TOP_10_APPS_BY_PLATFORM = 'APP_SUMMARY_TOP_10_APPS_BY_PLATFORM',
  APP_SUMMARY_DEPLOYED_APP_TYPES_BY_PLATFORM = 'APP_SUMMARY_DEPLOYED_APP_TYPES_BY_PLATFORM',
  APP_SUMMARY_TOP_5_APPS_BY_TOTAL_LAUNCHES = 'APP_SUMMARY_TOP_5_APPS_BY_TOTAL_LAUNCHES',
  APP_SUMMARY_TOP_5_APPS_BY_UNIQUE_USERS = 'APP_SUMMARY_TOP_5_APPS_BY_UNIQUE_USERS',
  APP_SUMMARY_DAU_SDK_APPS = 'APP_SUMMARY_DAU_SDK_APPS',
  APP_SUMMARY_MAU_TOP_10_WINDOWS_APPS = 'APP_SUMMARY_MAU_TOP_10_WINDOWS_APPS',

  // Apteligent standalone dashboard
  STANDALONE_APTELIGENT_APP_SUMMARY_DAU_SDK_APPS = 'STANDALONE_APTELIGENT_APP_SUMMARY_DAU_SDK_APPS',
  STANDALONE_APTELIGENT_APP_SUMMARY_SDK_APPS_BY_DEVICE_MODEL = 'STANDALONE_APTELIGENT_APP_SUMMARY_SDK_APPS_BY_DEVICE_MODEL',
  STANDALONE_APTELIGENT_APP_SUMMARY_SDK_APPS_BY_PLATFORM = 'STANDALONE_APTELIGENT_APP_SUMMARY_SDK_APPS_BY_PLATFORM',

  // For Airwatch App Details Dashboard
  APP_DEPLOYMENT_APP_VERSION_OVER_TIME = 'APP_DEPLOYMENT_APP_VERSION_OVER_TIME',
  APP_DEPLOYMENT_INSTALL_OVER_TIME = 'APP_DEPLOYMENT_INSTALL_OVER_TIME',
  APP_DEPLOYMENT_APP_VERSION_SUMMARY = 'APP_DEPLOYMENT_APP_VERSION_SUMMARY',
  APP_DEPLOYMENT_TOTAL_DEVICE_COUNT_SUMMARY = 'APP_DEPLOYMENT_TOTAL_DEVICE_COUNT_SUMMARY',
  APP_DEPLOYMENT_TOTAL_INSTALLS_SUMMARY = 'APP_DEPLOYMENT_TOTAL_INSTALLS_SUMMARY',
  APP_DEPLOYMENT_HISTORICAL_CHANGE_EVENTS = 'APP_DEPLOYMENT_HISTORICAL_CHANGE_EVENTS',
  APP_DEPLOYMENT_FAILED_HISTORICAL_CHANGE_EVENTS = 'APP_DEPLOYMENT_FAILED_HISTORICAL_CHANGE_EVENTS',
  APP_DEPLOYMENT_INSTALL_FAILED_TREND = 'APP_DEPLOYMENT_INSTALL_FAILED_TREND',
  APP_DEPLOYMENT_FAILED_BY_OS_VERSION_COUNT = 'APP_DEPLOYMENT_FAILED_BY_OS_VERSION_COUNT',
  APP_DEPLOYMENT_FAILED_BY_OWNERSHIP_COUNT = 'APP_DEPLOYMENT_FAILED_BY_OWNERSHIP_COUNT',
  APP_DEPLOYMENT_FAILED_BY_DEVICE_MODEL_COUNT = 'APP_DEPLOYMENT_FAILED_BY_DEVICE_MODEL_COUNT',
  APP_DEPLOYMENT_FAILED_BY_ORGANIZATION_GROUP_COUNT = 'APP_DEPLOYMENT_FAILED_BY_ORGANIZATION_GROUP_COUNT',
  APP_DEPLOYMENT_FAILED_UPDATES_TOTAL_COUNT = 'APP_DEPLOYMENT_FAILED_UPDATES_TOTAL_COUNT',
  APP_DEPLOYMENT_FAILED_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD = 'APP_DEPLOYMENT_FAILED_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD',
  APP_DEPLOYMENT_MANAGED_APPS_TOTAL_COUNT = 'APP_DEPLOYMENT_MANAGED_APPS_TOTAL_COUNT',
  __APP_DEPLOYMENT_FAILED_UPDATES_RATE = '__APP_DEPLOYMENT_FAILED_UPDATES_RATE',
  __APP_DEPLOYMENT_FAILED_UPDATES_RATE_PREVIOUS_PERIOD = '__APP_DEPLOYMENT_FAILED_UPDATES_RATE_PREVIOUS_PERIOD',
  APP_DEPLOYMENT_BY_OS_VERSION_COUNT = 'APP_DEPLOYMENT_BY_OS_VERSION_COUNT',
  APP_DEPLOYMENT_BY_OWNERSHIP_COUNT = 'APP_DEPLOYMENT_BY_OWNERSHIP_COUNT',
  APP_DEPLOYMENT_BY_DEVICE_MODEL_COUNT = 'APP_DEPLOYMENT_BY_DEVICE_MODEL_COUNT',
  APP_DEPLOYMENT_BY_ORGANIZATION_GROUP_COUNT = 'APP_DEPLOYMENT_BY_ORGANIZATION_GROUP_COUNT',
  APP_DEPLOYMENT_UPDATES_TOTAL_COUNT = 'APP_DEPLOYMENT_UPDATES_TOTAL_COUNT',
  APP_DEPLOYMENT_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD = 'APP_DEPLOYMENT_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD',
  APP_DEPLOYMENT_UPDATES_TREND = 'APP_DEPLOYMENT_UPDATES_TREND',
  __APP_DEPLOYMENT_UPDATES_RATE = '__APP_DEPLOYMENT_UPDATES_RATE',
  __APP_DEPLOYMENT_UPDATES_RATE_PREVIOUS_PERIOD = '__APP_DEPLOYMENT_UPDATES_RATE_PREVIOUS_PERIOD',

  // For app detail experience
  APP_EXPERIENCE_APP_SCORE_OVER_TIME = 'APP_EXPERIENCE_APP_SCORE_OVER_TIME',
  APP_EXPERIENCE_APP_SCORE_BY_APP_VERSION = 'APP_EXPERIENCE_APP_SCORE_BY_APP_VERSION',
  __CHANGE_IN_APP_CRASH_RATE = '__CHANGE_IN_APP_CRASH_RATE',
  APP_EXPERIENCE_APP_CRASH_RATE = 'APP_EXPERIENCE_APP_CRASH_RATE',
  APP_EXPERIENCE_APP_CRASH_RATE_PREVIOUS_PERIOD = 'APP_EXPERIENCE_APP_CRASH_RATE_PREVIOUS_PERIOD',
  APP_EXPERIENCE_APP_CRASH_OVER_TIME = 'APP_EXPERIENCE_APP_CRASH_OVER_TIME',
  __CHANGE_IN_APP_LOAD_RATE = '__CHANGE_IN_APP_LOAD_RATE',
  APP_EXPERIENCE_APP_LOADS = 'APP_EXPERIENCE_APP_LOADS',
  APP_EXPERIENCE_APP_LOADS_PREVIOUS_PERIOD = 'APP_EXPERIENCE_APP_LOADS_PREVIOUS_PERIOD',
  APP_EXPERIENCE_APP_LOADS_OVER_TIME = 'APP_EXPERIENCE_APP_LOADS_OVER_TIME',
  __CHANGE_IN_APP_DAU_RATE = '__CHANGE_IN_APP_DAU_RATE',
  APP_EXPERIENCE_APP_DAU = 'APP_EXPERIENCE_APP_DAU',
  APP_EXPERIENCE_APP_DAU_PREVIOUS_PERIOD = 'APP_EXPERIENCE_APP_DAU_PREVIOUS_PERIOD',
  APP_EXPERIENCE_APP_DAU_OVER_TIME = 'APP_EXPERIENCE_APP_DAU_OVER_TIME',
  __CHANGE_IN_APP_NETWORK_ERROR_RATE = '__CHANGE_IN_APP_NETWORK_ERROR_RATE',
  APP_EXPERIENCE_APP_NETWORK_ERROR_RATE = 'APP_EXPERIENCE_APP_NETWORK_ERROR_RATE',
  APP_EXPERIENCE_APP_NETWORK_ERROR_RATE_PREVIOUS_PERIOD = 'APP_EXPERIENCE_APP_NETWORK_ERROR_RATE_PREVIOUS_PERIOD',
  APP_EXPERIENCE_APP_NETWORK_ERROR_OVER_TIME = 'APP_EXPERIENCE_APP_NETWORK_ERROR_OVER_TIME',
  __CHANGE_IN_APP_HANDLED_EXCEPTION_RATE = '__CHANGE_IN_APP_HANDLED_EXCEPTION_RATE',
  APP_EXPERIENCE_APP_HANDLED_EXCEPTION_RATE = 'APP_EXPERIENCE_APP_HANDLED_EXCEPTION_RATE',
  APP_EXPERIENCE_APP_HANDLED_EXCEPTION_RATE_PREVIOUS_PERIOD = 'APP_EXPERIENCE_APP_HANDLED_EXCEPTION_RATE_PREVIOUS_PERIOD',
  APP_EXPERIENCE_APP_HANDLED_EXCEPTION_OVER_TIME = 'APP_EXPERIENCE_APP_HANDLED_EXCEPTION_OVER_TIME',

  // For Idm App Summary Dashboard
  IDM_APP_LAUNCH_SUMMARY = 'IDM_APP_LAUNCH_SUMMARY',
  IDM_APP_UNIQUE_USERS_SUMMARY_24_HOURS = 'IDM_APP_UNIQUE_USERS_SUMMARY_24_HOURS',
  IDM_APP_UNIQUE_USERS_SUMMARY_30_DAYS = 'IDM_APP_UNIQUE_USERS_SUMMARY_30_DAYS',
  IDM_APP_LAUNCH = 'IDM_APP_LAUNCH',
  IDM_APP_UNIQUE_USERS = 'IDM_APP_UNIQUE_USERS',
}
