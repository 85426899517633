/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationTemplateName
 *
 * @export
 * @enum {string}
 */
export enum AutomationTemplateName {
  HUB_DEVICE_SURVEY_AIRWATCH_APPLICATION = 'HUB_DEVICE_SURVEY_AIRWATCH_APPLICATION',
  HUB_DEVICE_SURVEY_AIRWATCH_DEVICE = 'HUB_DEVICE_SURVEY_AIRWATCH_DEVICE',
  HUB_DEVICE_SURVEY_INTERNAL_APP_ACTIVITY_V2 = 'HUB_DEVICE_SURVEY_INTERNAL_APP_ACTIVITY_V2',
}
