/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { SensorTemplate } from './sensor-template.model';

/**
 * SensorTemplateSearchResponse
 *
 * @export
 * @class SensorTemplateSearchResponse
 * @implements {PagedResponse}
 */
@Serializable
export class SensorTemplateSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: SensorTemplate })
  public results: SensorTemplate[] = [];

  /**
   * Creates an instance of SensorTemplateSearchResponse
   * @param {Array<Partial<SensorTemplateSearchResponse>>} args
   * @memberof SensorTemplateSearchResponse
   */
  constructor(...args: Array<Partial<SensorTemplateSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
