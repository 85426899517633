/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';

/**
 * AvailableServiceSearchRequest Model Object
 * @export
 * @class AvailableServiceSearchRequest
 */
@Serializable
export class AvailableServiceSearchRequest extends GenericSearchRequest {
  @JsonProperty('automation_supported')
  public automationSupported?: boolean = undefined;

  /**
   * Creates an instance of AvailableServiceSearchRequest
   * @param {any} args
   * @memberof AvailableServiceSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}
