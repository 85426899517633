/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { SolutionSettingMetricHolder } from '@ws1c/intelligence-models/solution/solution-setting-metric-holder.model';
import { DeemUxScoreThreshold } from './deem-ux-score-threshold.model';
import { Tags } from './tags.model';

/**
 * ThresholdMetricHolder
 *
 * @export
 * @class ThresholdMetricHolder
 */
@Serializable
export class ThresholdMetricHolder extends SolutionSettingMetricHolder {
  @JsonProperty({ name: 'threshold', cls: DeemUxScoreThreshold })
  public threshold: DeemUxScoreThreshold = undefined;

  @JsonProperty({ name: 'tags', cls: Tags })
  public tags: Tags = undefined;

  /**
   * Creates an instance of ThresholdMetricHolder.
   * @param {Array<Partial<ThresholdMetricHolder>>} args
   * @memberof ThresholdMetricHolder
   */
  constructor(...args: Array<Partial<ThresholdMetricHolder>>) {
    super();
    Object.assign(this, ...args);
  }
}
