/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationStatus
 * @export
 * @enum {string}
 */
export enum AutomationStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
