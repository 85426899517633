/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * SolutionStepGroup
 * @export
 * @enum {string}
 */
export enum SolutionStepGroup {
  REVIEW_SETTINGS = 'review_settings',
  DEFINE_DATA_SOURCE = 'define_data_source',
  REVIEW_UX_SCORE_DEFINITION = 'review_ux_score_definition',
  DESKTOP_EXPERIENCE_MANAGEMENT = 'desktop_experience_management',
  MOBILE_EXPERIENCE_MANAGEMENT = 'mobile_experience_management',
  MOBILE_APP_TELEMETRY = 'mobile_app_telemetry',
  HORIZON_CLOUD_SERVICES = 'horizon_cloud_services',
  FRONTLINE_WORKER_MANAGEMENT = 'frontline_worker_management',
}
