/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * IntegrationGroup
 * @export
 * @class IntegrationGroup
 */
@Serializable
export class IntegrationGroup {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('directory_group_id')
  public directoryGroupId: string = undefined;

  @JsonProperty('directory_type')
  public directoryType: string = undefined;

  @JsonProperty('directory_id')
  public directoryId: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  /**
   * Creates an instance of IntegrationGroup.
   * @param {Array<Partial<IntegrationGroup>>} args
   * @memberof IntegrationGroup
   */
  constructor(...args: Array<Partial<IntegrationGroup>>) {
    Object.assign(this, ...args);
  }
}

/**
 * IntegrationGroupSearchRequest
 * @export
 * @interface IntegrationGroupSearchRequest
 */
export interface IntegrationGroupSearchRequest {
  id: string;
  searchTerm: string;
}
