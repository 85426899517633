/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ConnectorConfigStatus
 * @export
 * @enum {string}
 */
export enum ConnectorConfigStatus {
  AUTHORIZED = 'AUTHORIZED',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  FAILED_TO_CONNECT = 'FAILED_TO_CONNECT',
}
