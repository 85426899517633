/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

import { DataAccessPolicy } from './data-access-policy.model';

/**
 * DataAccessPolicySearchResponse
 *
 * @export
 * @class DataAccessPolicySearchResponse
 * @implements {PagedResponse}
 */
export class DataAccessPolicySearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: DataAccessPolicy })
  public results: DataAccessPolicy[] = [];

  /**
   * Creates an instance of DataAccessPolicySearchResponse.
   * @param {Array<Partial<DataAccessPolicySearchResponse>>} args
   * @memberof DataAccessPolicySearchResponse
   */
  constructor(...args: Array<Partial<DataAccessPolicySearchResponse>>) {
    Object.assign(this, ...args);
  }
}
