/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * DirectoryType
 * @export
 * @enum {string}
 */
export enum DirectoryType {
  AIRWATCH = 'AIRWATCH',
  AZURE = 'AZURE',
  CSP = 'CSP',
  IDM = 'IDM',
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  SYNCED_ADMIN_USERS = 'SYNCED_ADMIN_USERS',
}
