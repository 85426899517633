/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * MarketplaceResourceTag
 * @export
 * @class MarketplaceResourceTag
 */
@Serializable
export class MarketplaceResourceTag {
  @JsonProperty('category')
  public category: string = undefined;

  @JsonProperty('sub_categories')
  public subCategories?: string[] = undefined;

  /**
   * Creates an instance of MarketplaceResourceTag.
   * @param {Array<Partial<MarketplaceResourceTag>>} args
   * @memberof MarketplaceResourceTag
   */
  constructor(...args: Array<Partial<MarketplaceResourceTag>>) {
    Object.assign(this, ...args);
  }
}
