/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * HorizonCounterType
 * @export
 * @enum {string}
 */
export enum HorizonCounterType {
  SINGLE = 'SINGLE',
  MIXED = 'MIXED',
}
