/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { ComposeFunction } from './dashboard.enum';

/**
 * HorizonDashboardConfig
 * @export
 * @class HorizonDashboardConfig
 */
export class HorizonDashboardConfig {
  public static readonly COMPOSE_CONFIGS_BY_HORIZON_DASHBOARD = {
    HORIZON_SUMMARY: {
      __HORIZON_TOTAL_LOCATION_COUNT: {
        dependencies: ['HORIZON_TOTAL_LOCATION_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.LOCATIONS'],
        },
      },
      __HORIZON_TOTAL_DEPLOYMENT_COUNT: {
        dependencies: ['HORIZON_TOTAL_DEPLOYMENT_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.DEPLOYMENTS'],
        },
      },
      __HORIZON_TOTAL_POD_COUNT: {
        dependencies: ['HORIZON_TOTAL_POD_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.PODS'],
        },
      },
      __HORIZON_TOTAL_POOL_COUNT: {
        dependencies: ['HORIZON_TOTAL_POOL_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.POOLS'],
        },
      },
      __HORIZON_TOTAL_TOPO_COUNT: {
        dependencies: [
          '__HORIZON_TOTAL_LOCATION_COUNT',
          '__HORIZON_TOTAL_DEPLOYMENT_COUNT',
          '__HORIZON_TOTAL_POD_COUNT',
          '__HORIZON_TOTAL_POOL_COUNT',
        ],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __HORIZON_TOTAL_SITE_COUNT_TITAN: {
        dependencies: ['HORIZON_TOTAL_SITE_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.SITES'],
        },
      },
      __HORIZON_TOTAL_EDGE_COUNT_TITAN: {
        dependencies: ['HORIZON_TOTAL_EDGE_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.EDGES'],
        },
      },
      __HORIZON_TOTAL_POOL_TEMPLATE_COUNT_TITAN: {
        dependencies: ['HORIZON_TOTAL_POOL_TEMPLATE_COUNT'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['STANDARD_DASHBOARD.HORIZON.POOLS'],
        },
      },
      __HORIZON_TOTAL_TOPO_COUNT_TITAN: {
        dependencies: ['__HORIZON_TOTAL_SITE_COUNT_TITAN', '__HORIZON_TOTAL_EDGE_COUNT_TITAN', '__HORIZON_TOTAL_POOL_TEMPLATE_COUNT_TITAN'],
        composeFunction: ComposeFunction.MERGE_RESULTS,
      },
      __HORIZON_UTILIZATION_FOR_VMWARE_SDDC: {
        dependencies: [
          'HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC_PODS',
          'HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC_PODS',
          'HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC_PODS',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 1,
          seriesDataType: 'STRING', // seriesDataType has effect on drilldown
          legendLabel: 'Utilization',
          seriesName: '_merged_series',
          seriesValues: ['CPU', 'Memory', 'Disk'],
        },
      },
      __HORIZON_UTILIZATION_FOR_AZURE: {
        dependencies: [
          'HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_PODS',
          'HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_PODS',
          'HORIZON_CAPACITY_USAGE_FOR_AZURE_PODS',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Utilization',
          seriesName: '_merged_series',
          seriesValues: ['Desktop Session Utilization', 'Application Session Utilization', 'Capacity'],
        },
      },
      __HORIZON_TITAN_CAPACITY: {
        dependencies: ['HORIZON_TITAN_VM_COUNT', 'HORIZON_TITAN_CAPACITY'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __HORIZON_MULTI_SESSION_UTILIZATION: {
        dependencies: ['HORIZON_TITAN_RDSH_SESSION_SUMMARY_BY_SESSION_TYPE', 'HORIZON_TITAN_RDSH_SESSION_CAPACITY'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __HORIZON_SINGLE_SESSION_UTILIZATION: {
        dependencies: ['HORIZON_TITAN_VDI_SESSION_SUMMARY_BY_SESSION_TYPE', 'HORIZON_TITAN_VDI_SESSION_CAPACITY'],
        composeFunction: ComposeFunction.COUNT_RATIO,
      },
      __HORIZON_TITAN_CAPACITY_RENAME: {
        dependencies: ['__HORIZON_TITAN_CAPACITY'],
        composeFunction: ComposeFunction.RENAME_COUNTERS,
        composeFunctionParams: {
          counterAttributes: [COLUMN_NAMES.byName.horizon_utilization_ratio],
        },
      },
      __HORIZON_MULTI_SESSION_UTILIZATION_RENAME: {
        dependencies: ['__HORIZON_MULTI_SESSION_UTILIZATION'],
        composeFunction: ComposeFunction.RENAME_COUNTERS,
        composeFunctionParams: {
          counterAttributes: [COLUMN_NAMES.byName.horizon_utilization_ratio],
        },
      },
      __HORIZON_SINGLE_SESSION_UTILIZATION_RENAME: {
        dependencies: ['__HORIZON_SINGLE_SESSION_UTILIZATION'],
        composeFunction: ComposeFunction.RENAME_COUNTERS,
        composeFunctionParams: {
          counterAttributes: [COLUMN_NAMES.byName.horizon_utilization_ratio],
        },
      },
      __HORIZON_TITAN_UTILIZATION: {
        dependencies: [
          '__HORIZON_MULTI_SESSION_UTILIZATION_RENAME',
          '__HORIZON_SINGLE_SESSION_UTILIZATION_RENAME',
          '__HORIZON_TITAN_CAPACITY_RENAME',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          insertSeriesIndex: 0,
          seriesDataType: 'STRING',
          legendLabel: 'Utilization',
          seriesName: '_merged_series',
          seriesValues: ['Multi Session', 'Single Session', 'Capacity'],
        },
      },
      __HORIZON_UTILIZATION_HISTORICAL_FOR_AZURE: {
        dependencies: [
          'HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL',
          'HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL',
          'HORIZON_CAPACITY_USAGE_FOR_AZURE_HISTORICAL',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Utilization',
          seriesName: '_merged_series',
          seriesValues: ['Desktop Session Utilization', 'Application Session Utilization', 'Capacity'],
        },
      },
      __HORIZON_SESSION_COUNT_BY_ISSUE: {
        dependencies: [
          'HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE',
          'HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE',
          'HORIZON_SESSION_ISSUES_BY_LOGON_TYPE',
        ],
        composeFunction: ComposeFunction.MERGE_SERIES,
        composeFunctionParams: {
          seriesDataType: 'STRING',
          legendLabel: 'Issue',
          seriesName: '_merged_series',
          seriesValues: ['Latency', 'Protocol', 'Slow Logon'],
        },
      },
      __HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS: {
        dependencies: ['HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['Sessions'],
        },
      },
      __HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS: {
        dependencies: ['HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS'],
        composeFunction: ComposeFunction.RENAME_COUNTER_LABELS,
        composeFunctionParams: {
          counterLabels: ['Impacted Sessions'],
        },
      },
    },
  };
}
