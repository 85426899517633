/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { WidgetTemplate } from '@ws1c/intelligence-models/template/widget/widget-template.model';
import { AggregationWidget } from './aggregation-widget.model';
import { TrendDateRange } from './trend-date-range.model';

/**
 * Trend Date Range Update Request Model Object
 * Request format for updating trend date range
 *
 * @export
 * @class TrendDateRangeUpdateRequest
 */
export class TrendDateRangeUpdateRequest {
  public widget: WidgetTemplate | AggregationWidget = null;
  public trendDateRange: TrendDateRange;

  /**
   * Creates an instance of TrendDateRangeUpdateRequest
   * @param {WidgetTemplate | AggregationWidget} widget
   * @param {TrendDateRange} trendDateRange
   * @memberOf TrendDateRangeUpdateRequest
   */
  constructor(widget: WidgetTemplate | AggregationWidget, trendDateRange: TrendDateRange) {
    this.widget = widget;
    this.trendDateRange = trendDateRange;
  }
}
