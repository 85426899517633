/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Threshold Types.
 * @export
 * @enum {string}
 */
export enum ThresholdType {
  DEVICES = 'devices',
}
