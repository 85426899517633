/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, GenericObject, JsonProperty, Serializable } from '@dpa/ui-common';
import { each } from 'lodash-es';

import { Trend } from './trend.model';

const trendByIdConverter: CustomConverter = {
  fromJson(data: GenericObject): Record<string, Trend> {
    const trendById = {};
    each(data, (value: GenericObject, key: string) => {
      trendById[key] = deserialize(Trend, value);
    });
    return trendById;
  },
  toJson(trendById: Record<string, Trend>): Record<string, Trend> {
    return trendById;
  },
};

/**
 * TrendPreviewResponse
 * @export
 * @class Trend
 */
@Serializable
export class TrendPreviewResponse {
  @JsonProperty({ name: 'data', customConverter: trendByIdConverter })
  public trendById: Record<string, Trend> = {};

  /**
   * Creates an instance of TrendPreviewResponse.
   * @param {Array<Partial<TrendPreviewResponse>>} args
   * @memberof TrendPreviewResponse
   */
  constructor(...args: Array<Partial<TrendPreviewResponse>>) {
    Object.assign(this, ...args);
  }
}
