/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationWorkflowExecutionStatus
 *
 * @export
 * @enum {string}
 */
export enum AutomationWorkflowExecutionStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
}
