/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, GenericObject } from '@dpa/ui-common';
import { keys } from 'lodash-es';

/**
 * dataBySectionConverter
 */
export const dataBySectionConverter: CustomConverter = {
  fromJson: (obj: GenericObject): GenericObject => {
    return obj;
  },
  toJson: (obj: GenericObject): GenericObject => {
    const sectionKeys = keys(obj);
    if (!sectionKeys.length) {
      return undefined;
    }
    return sectionKeys.reduce((result: GenericObject, sectionKey: string): GenericObject => {
      result[sectionKey] = obj[sectionKey].reduce((searchObj: any, field: any) => {
        searchObj[field.name] = field.value;
        return searchObj;
      }, {});
      return result;
    }, {});
  },
};
