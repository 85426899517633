/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * MetaFormFieldValidators
 *
 * @export
 * @class MetaFormFieldValidators
 */
export class MetaFormFieldValidators {
  public static readonly PHONE_NUMBER_REGEX = new RegExp('^[0-9]*$');
}
