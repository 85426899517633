/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

import { IntegrationObject } from './integration-object.model';

/**
 * IntegrationObjectsSearchResponse
 * @export
 * @class IntegrationObjectsSearchResponse
 * @implements {PagedResponse}
 */
export class IntegrationObjectsSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: IntegrationObject })
  public results: IntegrationObject[] = [];

  /**
   * Creates an instance of IntegrationObjectsSearchResponse.
   * @param {Array<Partial<IntegrationObjectsSearchResponse>>} args
   * @memberof IntegrationObjectsSearchResponse
   */
  constructor(...args: Array<Partial<IntegrationObjectsSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
