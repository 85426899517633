/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter } from '@dpa/ui-common';

/**
 * Attribute
 * @export
 * @class Attribute
 */
export class Attribute {
  public integration: string = undefined;
  public entity: string = undefined;
  public name: string = undefined;
  public fullyQualifiedName: string = undefined;
  public isNormalized: boolean = false;

  /**
   * Creates an instance of Attribute.
   * @param {Array<Partial<Attribute>>} args
   * @memberof Operator
   */
  constructor(...args: Array<Partial<Attribute>>) {
    Object.assign(this, ...args);
  }
}

/**
 * attributesConverter
 * @export
 * @type {CustomConverter}
 */
export const attributesConverter: CustomConverter = {
  fromJson(data: string = ''): Attribute {
    let integration;
    let entity;
    let name;
    if (data.includes('.')) {
      [integration, entity, name] = data.split('.');
    } else {
      name = data;
    }
    const fullyQualifiedName = data;
    // _ca_ prefix is for custom attributes
    // match name that starts with "_" but not "_ca_"
    const isNormalized = /^_(?!ca_).*/.test(name);
    return new Attribute({
      integration,
      entity,
      name,
      fullyQualifiedName,
      isNormalized,
    });
  },
  toJson(attribute: Attribute): string {
    return attribute.fullyQualifiedName;
  },
};
