/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * AutomationWizardMode
 *
 * @export
 * @enum {number}
 */
export enum AutomationWizardMode {
  CLOSE,
  CREATE,
  CREATE_FROM_OTHER_SOURCE,
  EDIT,
  EDIT_FROM_AUTOMATION_DETAILS,
  EDIT_FROM_OTHER_SOURCE,
}
