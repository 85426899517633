/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * IntroModalMode
 *
 * @export
 * @enum {number}
 */
export enum IntroModalMode {
  ACTIVATE_TRIAL,
  ACTIVATED_TRIAL,
  ACTIVATING_TRIAL,
  CLOSE,
  OPEN,
}

/**
 * TrialBannerState
 *
 * @export
 * @enum {number}
 */
export enum TrialBannerState {
  ALMOST_EXPIRED,
  COUNTDOWN,
  DECLINED,
  DECLINED_DISMISSED,
  EXPIRED,
  HIDDEN,
  STARTED,
}
