/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { IntegrationReprovisionStatus } from './integration-reprovisioning-status.model';

/**
 * IntegrationReprovisioningStatusResponse
 * @export
 * @class IntegrationReprovisioningStatusResponse
 */
@Serializable
export class IntegrationReprovisioningStatusResponse {
  @JsonProperty({ name: 'data', cls: IntegrationReprovisionStatus })
  public data: IntegrationReprovisionStatus[] = [];
}
