/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { Trend } from './trend.model';
import { WidgetSequence } from './widget-sequence.enum';

/**
 * WidgetDataset
 *
 * @export
 * @class WidgetDataset
 */
@Serializable
export class WidgetDataset {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('widget_id')
  public widgetId: string = undefined;

  @JsonProperty({ name: 'trend', cls: Trend })
  public trend: Trend = undefined;

  @JsonProperty('chart_type')
  public chartType: string = undefined;

  @JsonProperty('data_projection_fields')
  public widgetColumns: string[] = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty('overlay_sequence')
  public overlaySequence: number = WidgetSequence.MAIN;

  /**
   * Creates an instance of WidgetDataset.
   * @param {Array<Partial<WidgetDataset>>} args
   * @memberof WidgetDataset
   */
  constructor(...args: Array<Partial<WidgetDataset>>) {
    Object.assign(this, ...args);
  }

  /**
   * trendMode
   * @readonly
   * @type {string}
   * @memberof WidgetDataset
   */
  public get trendMode(): string {
    return this.trend?.trendDefinition?.trendMode;
  }

  /**
   * filterCondition
   * @readonly
   * @type {any}
   * @memberof WidgetDataset
   */
  public get ruleGroup(): any {
    return this.trend?.trendDefinition?.ruleGroup;
  }

  /**
   * categoryId
   * @readonly
   * @type {string}
   * @memberof WidgetDataset
   */
  public get categoryId(): string {
    return this.trend?.trendDefinition?.categoryId;
  }
}
