/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Org Preference
 *
 * @export
 * @enum {string}
 */
export enum OrgPreference {
  ABORTED_ONBOARDING_PREFERENCES = 'aborted.onboarding.preferences',
  ACCESS_TENANT_REPROVISION_STEP_COMPLETED = 'access.tenant.reprovision.step.completed',
  CSP_FEDERATION_ACCESS_REQUESTED = 'csp.federation.setup.access.requested',
  SERVICE_AUTO_OPT_IN_ACKNOWLEDGED = 'service.auto.optin.acknowledged',
  SERVICE_MANUAL_OPT_IN_PERFORMED = 'service.manual.optin.performed',
  TRIAL_CUSTOMER_QUESTIONNAIRE_STEP_COMPLETED = 'trial.customer.questionnaire.step.completed',
  BROKER_ACCESS_BROWN_FIELD_CUSTOMER = 'broker.access.brownfield.customer',
}
