/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * FilterConfiguration
 * @export
 * @class FilterConfiguration
 */
@Serializable
export class FilterConfiguration {
  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty('organization_group_ids')
  public organizationGroupIds: number[] = undefined;

  @JsonProperty('root_organization_group_ids')
  public rootOrganizationGroupIds: number[] = undefined;

  /**
   * Creates an instance of FilterConfiguration.
   * @param {Array<Partial<FilterConfiguration>>} args
   * @memberof FilterConfiguration
   */
  constructor(...args: Array<Partial<FilterConfiguration>>) {
    Object.assign(this, ...args);
  }
}
