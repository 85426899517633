/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Deserialize, JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { AvailableServiceTenant } from './available-service-tenant.model';
import { CompatibilityResponse } from './compatibility-response.model';
import { ServicePermissionType, ServiceType } from './connection.enum';
import { IntegrationFilterStatus } from './integration-filter-status.interface';

export interface CustomConnectionData {
  service: Partial<AvailableService>;
  settings?: any;
}

/**
 * ServicePermission
 * @export
 * @class ServicePermission
 */
@Serializable
export class ServicePermission {
  @JsonProperty({ name: 'permission_type', customConverter: enumConverterFactory(ServicePermissionType) })
  public permissionType: ServicePermissionType = undefined;

  @JsonProperty('label')
  public label: string = undefined;
}

/**
 * AvailableService
 * @export
 * @class AvailableService
 */
@Serializable
export class AvailableService {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('service_config_id')
  public serviceConfigId?: string = undefined;

  @JsonProperty('service_subtype')
  public key: string = undefined;

  @JsonProperty('service_type')
  public serviceSubtype: string = undefined;

  @JsonProperty('managed')
  public managed: boolean = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('icon_link')
  public iconLink: string = undefined;

  @JsonProperty('config_required')
  public configRequired: boolean = undefined;

  @JsonProperty({ name: 'service_permissions', cls: ServicePermission })
  public servicePermissions: ServicePermission[] = [];

  @JsonProperty('setup_info_url')
  public setupInfoUrl: string = undefined;

  @JsonProperty('automation_supported')
  public automationSupported: boolean = undefined;

  @JsonProperty('ingestion_supported')
  public ingestionSupported: boolean = undefined;

  @JsonProperty('trust_network_service_type')
  public trustNetworkServiceType: boolean = undefined;

  @JsonProperty('status_check_success')
  public statusCheckSuccess: boolean = undefined;

  @JsonProperty({ name: 'status_last_check_at', customConverter: dateFormatConverter })
  public statusLastChecked: number = undefined;

  @JsonProperty('automation_count')
  public automationCount: number = undefined;

  @JsonProperty({ name: 'compatibility_response', cls: CompatibilityResponse })
  public compatibilityResponse: CompatibilityResponse = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'tenant_details_views', cls: AvailableServiceTenant })
  public tenants: AvailableServiceTenant[] = [];

  public upgradeRequired: boolean = false;

  // Flag to denote whether multiTenancy is enabled or not for this service, set in the selector while returning trust network services
  public multiTenancyEnabled: boolean = false;

  public hasSingleTenant: boolean = false;

  public hasMultipleTenants: boolean = false;

  /**
   * Creates an instance of AvailableService
   * @param {Array<Partial<AvailableService>>} args
   * @memberof AvailableService
   */
  constructor(...args: Array<Partial<AvailableService>>) {
    Object.assign(this, ...args);
  }

  /**
   * updateProperties
   * @memberof AvailableService
   */
  @Deserialize.after()
  public updateProperties() {
    this.hasSingleTenant = this.tenants?.length === 1;
    this.hasMultipleTenants = this.tenants?.length > 1;
  }

  /**
   * Getter for multi-tenancy is enabled and multiple tenants are present
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get hasMultipleTenantsWithMultiTenancyEnabled(): boolean {
    return this.hasMultipleTenants && this.multiTenancyEnabled;
  }

  /**
   * Getter for composite key from key and serviceSubtype
   * @readonly
   * @type {string}
   * @memberof AvailableService
   * @returns {string}
   */
  public get compositeServiceType(): string {
    return `${this.key}_${this.serviceSubtype}`;
  }

  /**
   * Getter to determine whether the service is a custom connection or not.
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get isCustomConnection(): boolean {
    return !this.managed;
  }

  /**
   * Getter that returns the icon background image.
   * @readonly
   * @type {string}
   * @memberof AvailableService
   * @returns {string}
   */
  public get iconBackgroundImage(): string {
    return this.key === ServiceType[ServiceType.OPENAPI] ? `url('${this.iconLink}')` : undefined;
  }

  /**
   * Getter to determine whether service type is OPENAPI.
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get isOpenApi(): boolean {
    return this.key === ServiceType[ServiceType.OPENAPI];
  }

  /**
   * Getter to determine whether config is or required from the user.
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get isUserConfigRequired(): boolean {
    if (this.serviceConfigId) {
      return false;
    }
    return this.configRequired;
  }

  /**
   * Getter to determine whether there is a service config and such is required by the user
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get hasConfigIdAndIsConfigRequired(): boolean {
    return !!this.serviceConfigId && this.configRequired;
  }

  /**
   * Getter to determine whether config is authorized
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get isAuthorized(): boolean {
    return !!this.serviceConfigId;
  }

  /**
   * Getter to determine whether config is not connected
   * @readonly
   * @type {boolean}
   * @memberof AvailableService
   * @returns {boolean}
   */
  public get isFailedConnection(): boolean {
    return this.isAuthorized && !this.statusCheckSuccess;
  }

  /**
   * Getter getStatusFilter
   * @readonly
   * @type {IntegrationFilterStatus}
   * @memberof AvailableService
   * @returns {IntegrationFilterStatus}
   */
  public get getStatusFilter(): IntegrationFilterStatus {
    return { isAuthorized: this.isAuthorized, isFailedConnection: this.isFailedConnection };
  }
}
