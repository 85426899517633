/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { DateTimeFormat, TimeUnit, TrendSpan } from '@dpa/ui-common';
import { isUndefined, mapValues } from 'lodash-es';
import moment from 'moment';

import { BucketingAttribute } from '@ws1c/intelligence-models/dashboard/bucketing-attribute.model';
import { ChronoUnit } from '@ws1c/intelligence-models/dashboard/chrono-unit.enum';
import { CounterDefinition } from '@ws1c/intelligence-models/dashboard/counter-definition.model';
import { Counter } from '@ws1c/intelligence-models/dashboard/counter.model';
import { TrendDefinition } from '@ws1c/intelligence-models/dashboard/trend-definition.model';
import { TrendResult } from '@ws1c/intelligence-models/dashboard/trend-result.model';
import { Trend } from '@ws1c/intelligence-models/dashboard/trend.model';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';

interface TrendResultFlattener {
  [keyString: string]: (trendResult: TrendResult) => any;
}

/**
 * NgxTrendResultFlattener
 * @export
 * @class NgxTrendResultFlattener
 */
export class NgxTrendResultFlattener {
  public static readonly momentUnitByChronoUnit = {
    [ChronoUnit[ChronoUnit.SECONDS]]: 'second',
    [ChronoUnit[ChronoUnit.MINUTES]]: 'minute',
    [ChronoUnit[ChronoUnit.HOURS]]: 'hour',
    [ChronoUnit[ChronoUnit.DAYS]]: 'day',
    [ChronoUnit[ChronoUnit.WEEKS]]: 'week',
    [ChronoUnit[ChronoUnit.MONTHS]]: 'month',
  };
  // Use Locale aware formats
  public static readonly dateFormatByChronoUnit = {
    [ChronoUnit[ChronoUnit.MILLIS]]: DateTimeFormat.MOMENT_TIME_WITH_SECOND_FORMAT,
    [ChronoUnit[ChronoUnit.SECONDS]]: DateTimeFormat.MOMENT_TIME_WITH_SECOND_FORMAT,
    [ChronoUnit[ChronoUnit.MINUTES]]: DateTimeFormat.MOMENT_TIME_FORMAT,
    [ChronoUnit[ChronoUnit.HOURS]]: DateTimeFormat.MOMENT_MEDIUM_DATETIME_FORMAT,
    [ChronoUnit[ChronoUnit.DAYS]]: DateTimeFormat.MOMENT_DATE_FORMAT,
    [ChronoUnit[ChronoUnit.WEEKS]]: DateTimeFormat.MOMENT_LONG_DATE_FORMAT,
    [ChronoUnit[ChronoUnit.MONTHS]]: DateTimeFormat.MOMENT_LONG_DATE_FORMAT,
    [ChronoUnit[ChronoUnit.YEARS]]: DateTimeFormat.MOMENT_FULL_YEAR_FORMAT,
  };

  public static START_STR = '__start_str_key';
  public static END_STR = '__end_str_key';
  public static START_DRILLDOWN_KEY = '__start_drilldown_key';
  public static END_DRILLDOWN_KEY = '__end_drilldown_key';
  public static DATE_KEY = '__date_key';
  public static COUNTER_KEY = '__counter_key';
  public static COUNTER_KEY_MULTI = '__counter_key_multi';
  public static FAKE_GROUP_BY_KEY = '__fake_group_by_key';
  public static RANGE_FLATTENER = '__range_flattener';

  /**
   * getCounterKey
   * @param {number} keyIndex
   * @returns {string}
   */
  public static getCounterKey(keyIndex: number): string {
    return keyIndex ? `${NgxTrendResultFlattener.COUNTER_KEY}_${keyIndex}` : NgxTrendResultFlattener.COUNTER_KEY;
  }

  public results: any[];
  public dateMomentFormatString: string;
  public dateMomentAxisFormatString: string;
  public formattedDateCache: Map<number, string> = new Map<number, string>();

  // This index is needed to convert time strings back into timestamps when drilling down
  public timestampsByFormattedTimes: { [formattedTimes: string]: number } = {};
  // This index is just for performance (moment.format is so slow)
  private formattedTimesByTimestamp: { [timestamp: number]: string } = {};

  /**
   * constructor
   * @param {Trend} trend
   * @memberof {NgxTrendResultFlattener}
   */
  constructor(private trend: Trend) {
    this.dateMomentFormatString = this.getDateMomentFormatString();
    this.dateMomentAxisFormatString = this.getDateMomentAxisFormatString();
    this.results = this.getFlatTrendResults();
  }

  /**
   * getFlatTrendResults
   * @returns {any[]}
   * @memberof {NgxTrendResultFlattener}
   */
  public getFlatTrendResults(): any[] {
    const trendResultFlattener: TrendResultFlattener = this.getTrendResultFlattener();
    return this.flattenTrendResults(trendResultFlattener);
  }

  /**
   * getTrendResultFlattener
   * @returns {TrendResultFlattener}
   * @memberof {NgxTrendResultFlattener}
   */
  public getTrendResultFlattener(): TrendResultFlattener {
    const trendDefinition: TrendDefinition = this.trend.trendDefinition;
    const trendResultFlattener: TrendResultFlattener = {
      // Prevents _.groupBy from casting to 'undefined' instead of counter label
      [NgxTrendResultFlattener.FAKE_GROUP_BY_KEY]: (trendResult: TrendResult) => trendResult.counters[0]?.result.reportColumnView.label,
      [NgxTrendResultFlattener.START_STR]: (trendResult: TrendResult) => this.formatDate(trendResult.startMillis),
      [NgxTrendResultFlattener.END_STR]: (trendResult: TrendResult) => this.formatDate(trendResult.endMillis - 1),
      [NgxTrendResultFlattener.START_DRILLDOWN_KEY]: this.getStartDrilldownFlattener(),
      [NgxTrendResultFlattener.END_DRILLDOWN_KEY]: (trendResult: TrendResult) => trendResult.endMillis,
      [NgxTrendResultFlattener.DATE_KEY]: this.getDateFlattener(),
      [NgxTrendResultFlattener.COUNTER_KEY]: (trendResult: TrendResult) => trendResult.counters[0]?.result.value,
      [NgxTrendResultFlattener.COUNTER_KEY_MULTI]: (trendResult: TrendResult) => {
        return trendResult.counters.map((counter: Counter) => counter.result.value);
      },
      [NgxTrendResultFlattener.RANGE_FLATTENER]: (trendResult: TrendResult) => {
        return {
          value: trendResult.counters[0]?.result?.value,
          min: trendResult.counters[1]?.result?.value ?? 0,
          max: trendResult.counters[2]?.result?.value ?? 0,
        };
      },
    };

    trendDefinition.counterDefinitions.forEach((counterDefinition: CounterDefinition, index: number) => {
      const counterKey = NgxTrendResultFlattener.getCounterKey(index);
      trendResultFlattener[counterKey] = (trendResult: TrendResult) => trendResult.counters[index]?.result.value;
    });

    const bucketingAttributes = trendDefinition.bucketingAttributes || [];
    bucketingAttributes.forEach((bucketingAttributeKey: string, index: number) => {
      trendResultFlattener[bucketingAttributeKey] = this.getBucketingAttributeFlattener(index);
    });
    return trendResultFlattener;
  }

  /**
   * formatDate - cache because moment.format() is pretty slow
   * @param {number} millis
   * @returns {string}
   * @memberof {NgxTrendResultFlattener}
   */
  public formatDate(millis: number): string {
    let dateString = this.formattedDateCache.get(millis);
    if (!dateString) {
      dateString = moment(millis).format(this.dateMomentFormatString);
      this.formattedDateCache.set(millis, dateString);
    }
    return dateString;
  }

  /**
   * formatDateAxis
   * Uses a slightly more granular date format
   * @param  {number} millis
   * @returns {string}
   */
  public formatDateAxis(millis: number): string {
    return moment(millis).format(this.dateMomentAxisFormatString);
  }

  /**
   * flattenTrendResults
   * @param {TrendResultFlattener} trendResultFlattener
   * @returns {any[]}
   * @memberof {NgxTrendResultFlattener}
   */
  public flattenTrendResults(trendResultFlattener: TrendResultFlattener): any[] {
    const trendResults: TrendResult[] = this.trend.trendResults ?? [];
    return trendResults.map((trendResult: TrendResult) => {
      return mapValues(trendResultFlattener, (flattenFunc: any) => flattenFunc(trendResult));
    });
  }

  /**
   * getBucketingAttributeFlattener
   * @param {number} bucketIndex
   * @returns {(trendResult: TrendResult) => string}
   */
  public getBucketingAttributeFlattener(bucketIndex: number): (trendResult: TrendResult) => string {
    return (trendResult: TrendResult) => {
      // For snapshot requests, if there are no results API returns a single unusual TrendResult
      // This TrendResult has no counter value, but provides the counter label
      // This TrendResult also has fake bucketing attributes
      // These fake bucketing attributes have no "value" attributes, and are only used for their "label" attributes
      const bucketingAttribute: BucketingAttribute = trendResult.bucketingAttributes[bucketIndex];
      if (!bucketingAttribute || isUndefined(bucketingAttribute.value)) {
        return '';
      }

      const unformattedValue = bucketingAttribute.value;
      const dataType = bucketingAttribute.reportColumnView.dataType;
      switch (dataType) {
        case DataType[DataType.BOOLEAN]:
          return Boolean(unformattedValue);
        case DataType[DataType.DATETIME]:
          let formattedTime = this.formattedTimesByTimestamp[unformattedValue];
          if (!formattedTime) {
            formattedTime = this.formatDate(unformattedValue);
            this.formattedTimesByTimestamp[unformattedValue] = formattedTime;
          }
          this.timestampsByFormattedTimes[formattedTime] = unformattedValue;
          return formattedTime;
        default:
          return unformattedValue;
      }
    };
  }

  /**
   * getDateFlattener
   * @returns {(trendResult: TrendResult) => string}
   * @memberof {NgxTrendResultFlattener}
   */
  public getDateFlattener() {
    return this.trend.trendDefinition.getIsRolling() ? this.getRollingDateFlattener() : this.getNormalDateFlattener();
  }

  /**
   * getNormalDateFlattener
   * @returns {(trendResult: TrendResult) => string}
   * @memberof {NgxTrendResultFlattener}
   */
  public getNormalDateFlattener() {
    const trendDateRange = this.trend.trendDefinition.dateRange;
    if (!trendDateRange) {
      return () => 0;
    }
    return (trendResult: TrendResult) => this.formatDate(trendResult.startMillis);
  }

  /**
   * getStartDrilldownFlattener
   * @returns {(trendResult: TrendResult) => number}
   * @memberof {NgxTrendResultFlattener}
   */
  public getStartDrilldownFlattener() {
    return this.trend.trendDefinition.getIsRolling()
      ? this.getRollingStartDrilldownFlattener()
      : (trendResult: TrendResult) => trendResult.startMillis;
  }

  /**
   * getRollingStartDrilldownFlattener
   * The "startMillis" of a typical TrendResult represents where the "bar" for that trend result starts
   * For a rolling TrendResult, it represents where the data for that trend result begins
   * This computes the "startMillis" of a rolling TrendResult using the endTime and rollingWindowInterval
   * @returns {(trendResult: TrendResult) => number}
   * @memberof {NgxTrendResultFlattener}
   */
  public getRollingStartDrilldownFlattener() {
    const trendDateRange = this.trend.trendDefinition.dateRange;
    const rollingWindowInterval: TrendSpan = trendDateRange.rollingWindow.rollingWindowInterval;
    const rollingWindowUnit: string = rollingWindowInterval.unit;
    const rollingWindowDuration: number = rollingWindowInterval.duration;
    const timeUnit: TimeUnit = TrendSpan.timeUnitsByName[rollingWindowUnit];
    const startOfUnit: string = NgxTrendResultFlattener.momentUnitByChronoUnit[rollingWindowUnit];

    /**
     * This represents how far to left-shift the endMillis to get the date used for a rolling chart
     * At the start of this process, I will be using the startOf function to round up to the closest unit
     * This is why I use "rollingWindowDuration - 1", the missing timeUnit.value is done through rounding
     */
    const offsetMillis: number = (rollingWindowDuration - 1) * timeUnit.value;

    return (trendResult: TrendResult) => {
      return (
        moment(trendResult.endMillis - 1)
          .startOf(startOfUnit as any)
          .valueOf() - offsetMillis
      );
    };
  }

  /**
   * getDateMomentFormatString
   * @returns {string}
   * @memberof {NgxTrendResultFlattener}
   */
  public getDateMomentFormatString(): string {
    // Some trendDateRanges do not provide samplingFrequency
    // They just have a startDateMillis and endDateMillis for getting a single result (acts more like a filter)
    // These are typically just used for metric charts which just shows a number
    const trendDateRange = this.trend.trendDefinition.dateRange;
    const samplingFrequency = trendDateRange && trendDateRange.getSamplingFrequency();
    if (!samplingFrequency) {
      return DateTimeFormat.MOMENT_DATE_FORMAT;
    }

    const timeUnit: TimeUnit = TrendSpan.timeUnitsByName[samplingFrequency.unit];
    return NgxTrendResultFlattener.dateFormatByChronoUnit[timeUnit.name] || DateTimeFormat.MOMENT_DATE_FORMAT;
  }

  /**
   * getDateMomentAxisFormatString
   * @returns {string}
   * @memberof {NgxTrendResultFlattener}
   */
  public getDateMomentAxisFormatString(): string {
    // Some trendDateRanges do not provide samplingFrequency
    // They just have a startDateMillis and endDateMillis for getting a single result (acts more like a filter)
    // These are typically just used for metric charts which just shows a number
    const trendDateRange = this.trend.trendDefinition.dateRange;
    const samplingFrequency = trendDateRange && trendDateRange.getSamplingFrequency();
    if (!samplingFrequency) {
      return DateTimeFormat.MOMENT_DATE_FORMAT;
    }

    const timeUnitIndex = TrendSpan.timeUnits.findIndex((timeUnit: TimeUnit) => {
      return timeUnit.name === samplingFrequency.unit;
    });

    // Use a slightly more granular date formatter for the axis
    const selectedTimeUnit = TrendSpan.timeUnits[Math.max(0, timeUnitIndex - 1)];
    return NgxTrendResultFlattener.dateFormatByChronoUnit[selectedTimeUnit.name] || DateTimeFormat.MOMENT_DATE_FORMAT;
  }

  /**
   * getRollingDateFlattener
   * @returns {(trendResult: TrendResult) => string}
   * @memberof {NgxTrendResultFlattener}
   */
  public getRollingDateFlattener(): (trendResult: TrendResult) => any {
    const startDrilldownFlattener = this.getRollingStartDrilldownFlattener();
    return (trendResult: TrendResult) => {
      const startDrilldownMillis = startDrilldownFlattener(trendResult);
      return this.formatDate(startDrilldownMillis);
    };
  }
}
