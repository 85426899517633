/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AirwatchAppSearchItem
 * @export
 * @class AirwatchAppSearchItem
 */
@Serializable
export class AirwatchAppSearchItem {
  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('package_id')
  public packageId?: string = undefined;

  @JsonProperty('platform')
  public platform?: string = undefined;

  @JsonProperty('type')
  public appType?: string = undefined;

  @JsonProperty('apteligent_app_id')
  public apteligentAppId: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('application')
  public application: string = undefined;

  @JsonProperty('hash')
  public hash: string = undefined;

  @JsonProperty('pfn')
  public pfn: string = undefined;

  @JsonProperty('productivity_app')
  public isProductivityApp?: boolean = undefined;

  /**
   * Creates an instance of AirwatchAppSearchItem.
   * @param {Array<Partial<AirwatchAppSearchItem>>} args
   * @memberof AirwatchAppSearchItem
   */
  constructor(...args: Array<Partial<AirwatchAppSearchItem>>) {
    Object.assign(this, ...args);
  }

  /**
   * getAppType
   * @returns {string}
   * @memberof AirwatchAppSearchItem
   */
  public getAppType(): string {
    return this.appType || 'UNKNOWN';
  }

  /**
   * nameLabel
   * @returns {string}
   * @memberof AirwatchAppSearchItem
   */
  public get nameLabel(): string {
    return this.name || this.application;
  }

  /**
   * packageIdLabel
   * @returns {string}
   * @memberof AirwatchAppSearchItem
   */
  public get packageIdLabel(): string {
    return this.packageId || this.pfn || this.hash;
  }
}
