/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, JsonProperty, Serializable, TimeOfDay } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { CronExpressionDetail } from '@ws1c/intelligence-models/scheduled-report.model';
import { convertCronExpressionData } from '@ws1c/intelligence-models/utils/cron-expression-util';

/**
 * AutomationSchedule
 * @export
 * @class AutomationSchedule
 */
@Serializable
export class AutomationSchedule {
  /**
   * createFromAutomationAndScheduleDetails
   * @param {GenericObject} cronExpressionData
   * @param {string|undefined} id
   * @returns {AutomationSchedule}
   */
  public static createFromAutomationAndScheduleDetails(cronExpressionData: GenericObject, id?: string | undefined): AutomationSchedule {
    const startTimeOfDay: TimeOfDay = cronExpressionData.cronExpressionDetail.startTimeOfDay;
    return new AutomationSchedule({
      id,
      start: startTimeOfDay.getTimestampForToday(),
      end: cronExpressionData.cronExpressionDetail.requiredEnDate ? cronExpressionData.cronExpressionDetail.end.getTime() : undefined,
      cronExpressionDetail: convertCronExpressionData(cronExpressionData),
    });
  }

  @JsonProperty('id')
  public id?: string = undefined;

  @JsonProperty({ name: 'start', customConverter: dateFormatConverter })
  public start: number = undefined;

  @JsonProperty({ name: 'end', customConverter: dateFormatConverter })
  public end?: number = undefined;

  @JsonProperty({ name: 'cron_expression_detail', cls: CronExpressionDetail })
  public cronExpressionDetail: CronExpressionDetail = undefined;

  /**
   * Creates an instance of AutomationSchedule.
   * @param {Array<Partial<AutomationSchedule>>} args
   * @memberof AutomationSchedule
   */
  constructor(...args: Array<Partial<AutomationSchedule>>) {
    Object.assign(this, ...args);
  }
}
