/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Available SKU Service Model
 *
 * @export
 * @class AvailableSKUService
 */
@Serializable
export class AvailableSKUService {
  @JsonProperty('associated_service_type')
  public associatedServiceType: string = undefined;

  @JsonProperty('doc_url')
  public docUrl: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('eligible_for_trial')
  public eligibleForTrial: boolean = undefined;

  @JsonProperty('eligible_for_in_product_trial')
  public eligibleForInProductTrial: boolean = undefined;

  @JsonProperty('short_title')
  public shortTitle: string = undefined;

  @JsonProperty('title')
  public title: string = undefined;

  /**
   * Creates an instance of AvailableSKUService
   * @param {Array<Partial<AvailableSKUService>>} args - initialization arguments for object
   * @memberof AvailableSKUService
   */
  constructor(...args: Array<Partial<AvailableSKUService>>) {
    Object.assign(this, ...args);
  }

  /**
   * type
   * @readonly
   * @type {string}
   * @memberof AvailableSKUService
   */
  public get type(): string {
    return this.associatedServiceType;
  }
}
