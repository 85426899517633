/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * NetErrorCrumb
 * @export
 * @class NetErrorCrumb
 */
@Serializable
export class NetErrorCrumb {
  @JsonProperty('apteligent.net_error.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.net_error._url')
  public url: string = undefined;

  @JsonProperty('apteligent.net_error._url_host')
  public urlHost: string = undefined;

  @JsonProperty('apteligent.net_error.http_method')
  public httpMethod: string = undefined;

  @JsonProperty('apteligent.net_error._net_error_msg')
  public netErrorMsg: string = undefined;

  @JsonProperty('apteligent.net_error.bytes_sent')
  public bytesSent: number = undefined;

  /**
   * Creates an instance of NetErrorCrumb.
   * @param {...Array<Partial<NetErrorCrumb>>} args
   * @memberof NetErrorCrumb
   */
  constructor(...args: Array<Partial<NetErrorCrumb>>) {
    Object.assign(this, ...args);
  }
}
