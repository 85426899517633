/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * WorkspaceOneMtdDashboardType
 * @export
 * @enum {string}
 */
export enum WorkspaceOneMtdDashboardType {
  WORKSPACE_ONE_MTD = 'WORKSPACE_ONE_MTD',
}

/**
 * WorkspaceOneMtdWidgetSubtype
 * @export
 * @enum {string}
 */
export enum WorkspaceOneMtdWidgetSubtype {
  WORKSPACE_ONE_MTD_ACTIVATION_STATUS = 'WORKSPACE_ONE_MTD_ACTIVATION_STATUS',
  WORKSPACE_ONE_MTD_COUNT_OF_ANDROID_DEVICES = 'WORKSPACE_ONE_MTD_COUNT_OF_ANDROID_DEVICES',
  // eslint-disable-next-line max-len
  WORKSPACE_ONE_MTD_COUNT_OF_DEVICES_WITH_UNRESOLVED_HIGH_SEVERITY_THREATS = 'WORKSPACE_ONE_MTD_COUNT_OF_DEVICES_WITH_UNRESOLVED_HIGH_SEVERITY_THREATS',
  WORKSPACE_ONE_MTD_COUNT_OF_IOS_DEVICES = 'WORKSPACE_ONE_MTD_COUNT_OF_IOS_DEVICES',
  WORKSPACE_ONE_MTD_DEVICES_BY_PLATFORM = 'WORKSPACE_ONE_MTD_DEVICES_BY_PLATFORM',
  WORKSPACE_ONE_MTD_DEVICES_BY_RISK_LEVEL = 'WORKSPACE_ONE_MTD_DEVICES_BY_RISK_LEVEL',
  WORKSPACE_ONE_MTD_DEVICES_NOT_SYNCED_WITHIN_LAST_7_DAYS = 'WORKSPACE_ONE_MTD_DEVICES_NOT_SYNCED_WITHIN_LAST_7_DAYS',
  WORKSPACE_ONE_MTD_DEVICES_WITH_UNRESOLVED_HIGH_SEVERITY_THREATS = 'WORKSPACE_ONE_MTD_DEVICES_WITH_UNRESOLVED_HIGH_SEVERITY_THREATS',
  WORKSPACE_ONE_MTD_THREATS_BY_APPLICATION = 'WORKSPACE_ONE_MTD_THREATS_BY_APPLICATION',
  WORKSPACE_ONE_MTD_THREATS_BY_DEVICE = 'WORKSPACE_ONE_MTD_THREATS_BY_DEVICE',
  WORKSPACE_ONE_MTD_THREATS_BY_PLATFORM = 'WORKSPACE_ONE_MTD_THREATS_BY_PLATFORM',
  WORKSPACE_ONE_MTD_THREATS_BY_SEVERITY = 'WORKSPACE_ONE_MTD_THREATS_BY_SEVERITY',
  WORKSPACE_ONE_MTD_THREAT_STATUS = 'WORKSPACE_ONE_MTD_THREAT_STATUS',
  WORKSPACE_ONE_MTD_THREAT_TRENDS = 'WORKSPACE_ONE_MTD_THREAT_TRENDS',
  WORKSPACE_ONE_MTD_TOTAL_MTD_DEVICES = 'WORKSPACE_ONE_MTD_TOTAL_MTD_DEVICES',
  WORKSPACE_ONE_MTD_TOTAL_UNRESOLVED_THREATS = 'WORKSPACE_ONE_MTD_TOTAL_UNRESOLVED_THREATS',
  WORKSPACE_ONE_MTD_UNRESOLVED_HIGH_THREATS = 'WORKSPACE_ONE_MTD_UNRESOLVED_HIGH_THREATS',
  WORKSPACE_ONE_MTD_UNRESOLVED_THREATS_OVER_LAST_WEEK = 'WORKSPACE_ONE_MTD_UNRESOLVED_THREATS_OVER_LAST_WEEK',
}
