/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { AccountRole, accountRolesConverter } from '@ws1c/intelligence-models/account-role.model';
import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { dateFormatConverter } from '@ws1c/intelligence-models/converters/date-format.converter';
import { DataAccessPolicy } from '@ws1c/intelligence-models/data-access-policy.model';
import { Directory } from '@ws1c/intelligence-models/directory.model';
import { RequestStatusType } from './request-status-type.enum';

/**
 * Account
 * @export
 * @class Account
 */
@Serializable
export class Account {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty({ name: 'active', excludeToJson: true })
  public active: boolean = undefined;

  @JsonProperty('azure_object_id')
  public azureObjectId: string = undefined;

  @JsonProperty('azure_on_premises_immutable_id')
  public azureOnPremImmutableId: string = undefined;

  @JsonProperty('airwatch_credential_sub')
  public airwatchCredentialSub: string = undefined;

  @JsonProperty('airwatch_user_name')
  public airwatchUserName: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('user_name')
  public userName: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  @JsonProperty({ name: 'integration', excludeToJson: true })
  public integration: string = undefined;

  @JsonProperty('integration_id')
  public integrationId: string = undefined;

  @JsonProperty({ name: 'csp_migration_status', customConverter: enumConverterFactory(RequestStatusType), excludeToJson: true })
  public cspMigrationStatus: RequestStatusType = undefined;

  @JsonProperty('csp_migration_email')
  public cspMigrationEmail: string = undefined;

  @JsonProperty({ name: 'data_access_policies', cls: DataAccessPolicy, excludeToJson: true })
  public dataAccessPolicies?: DataAccessPolicy[] = [];

  @JsonProperty({ name: 'roles', customConverter: accountRolesConverter, excludeToJson: true })
  public roles: AccountRole[] = undefined;

  @JsonProperty('role_ids')
  public roleIds: string[] = undefined;

  @JsonProperty({ name: 'directory_user', cls: Directory })
  public directory: Directory = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'last_synced_at', customConverter: dateFormatConverter, excludeToJson: true })
  public lastSyncedAt: number = undefined;

  /**
   * userInfo
   * @returns {string}
   * @memberof Account
   */
  public get userInfo(): string {
    return this.userName || this.airwatchUserName || this.displayName || this.email || '';
  }

  /**
   * Creates an instance of AccountSearchResponse.
   * @param {any} args
   * @memberof AccountSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }

  /**
   * roleIdsFromRoles
   * @returns {string[]}
   * @memberof Account
   */
  public roleIdsFromRoles(): string[] {
    return this.roles?.map((role: AccountRole) => role.roleId);
  }
}

/**
 * AccountSearchResponse
 *
 * @export
 * @class AccountSearchResponse
 */
@Serializable
export class AccountSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: Account })
  public results: Account[] = [];

  /**
   * Creates an instance of AccountSearchResponse.
   * @param {Array<Partial<AccountSearchResponse>>} args
   * @memberof AccountSearchResponse
   */
  constructor(...args: Array<Partial<AccountSearchResponse>>) {
    Object.assign(this, ...args);
  }
}
