/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { ReportConfigType } from '@ws1c/intelligence-models/reports/report-config-type.enum';

/**
 * ReportConfig
 * @export
 * @class ReportConfig
 */
@Serializable
export class ReportConfig {
  @JsonProperty('predefined_report_type')
  public predefinedReportType: string = undefined;

  @JsonProperty({ name: 'report_config_type', customConverter: enumConverterFactory(ReportConfigType) })
  public reportConfigType: ReportConfigType = undefined;

  @JsonProperty({ name: 'use_friendly_column_labels_for_csv' })
  public useFriendlyColumnLabelsForCSV: boolean = undefined;

  /**
   * Creates an instance of ReportConfig.
   * @param {...Array<Partial<ReportConfig>>} args
   * @memberof ReportConfig
   */
  constructor(...args: Array<Partial<ReportConfig>>) {
    Object.assign(this, ...args);
  }
}
