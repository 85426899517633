/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';

import { DataAccessPolicy } from './data-access-policy.model';

/**
 * DataAccessPolicyAssignPreview
 * @export
 * @class DataAccessPolicyAssignPreview
 */
@Serializable
export class DataAccessPolicyAssignPreview {
  @JsonProperty('accounts_to_move')
  public accountsToMove: { [key: string]: DataAccessPolicy[] };

  @JsonProperty('accounts_to_remove')
  public accountsToRemove: string[];

  /**
   * Creates an instance of DataAccessPolicyAssignPreview.
   * @param {Array<Partial<DataAccessPolicyAssignPreview>>} args
   * @memberof DataAccessPolicyAssignPreview
   */
  constructor(...args: Array<Partial<DataAccessPolicyAssignPreview>>) {
    Object.assign(this, ...args);
  }

  /**
   * isEmptyPreview
   * @type {boolean}
   * @memberof DataAccessPolicyAssignPreview
   */
  public get isEmptyPreview(): boolean {
    return isEmpty(this.accountsToMove) && isEmpty(this.accountsToRemove);
  }
}
