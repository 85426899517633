/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * Horizon Dashboard Type
 * @export
 * @enum {string}
 */
export enum HorizonDashboardType {
  // used for Horizon
  HORIZON_TREE_MAP = 'HORIZON_TREE_MAP',
  HORIZON_SUMMARY = 'HORIZON_SUMMARY',
  HORIZON_CONNECTIONS = 'HORIZON_CONNECTIONS',
  HORIZON_SESSIONS = 'HORIZON_SESSIONS',
  HORIZON_SESSIONS_HISTORICAL = 'HORIZON_SESSIONS_HISTORICAL',
  HORIZON_USERS = 'HORIZON_USERS',
  HORIZON_UTILIZATION_VMWARE_SDDC = 'HORIZON_UTILIZATION_VMWARE_SDDC',
  HORIZON_UTILIZATION_VMWARE_SDDC_HISTORICAL = 'HORIZON_UTILIZATION_VMWARE_SDDC_HISTORICAL',
  HORIZON_UTILIZATION_AZURE = 'HORIZON_UTILIZATION_AZURE',
  HORIZON_UTILIZATION_AZURE_HISTORICAL = 'HORIZON_UTILIZATION_AZURE_HISTORICAL',
  // used for Horizon Titan
  HORIZON_TITAN_TREE_MAP = 'HORIZON_TITAN_TREE_MAP',
  HORIZON_TITAN_SUMMARY = 'HORIZON_TITAN_SUMMARY',
  HORIZON_TITAN_CONNECTIONS = 'HORIZON_TITAN_CONNECTIONS',
  HORIZON_TITAN_SESSIONS = 'HORIZON_TITAN_SESSIONS',
  HORIZON_TITAN_SESSIONS_HISTORICAL = 'HORIZON_TITAN_SESSIONS_HISTORICAL',
  HORIZON_TITAN_USERS = 'HORIZON_TITAN_USERS',
  HORIZON_TITAN_UTILIZATION = 'HORIZON_TITAN_UTILIZATION',
}

/**
 * HorizonWidgetSubtype
 * @export
 * @enum {string}
 */
export enum HorizonWidgetSubtype {
  // Horizon - Summary
  __HORIZON_TOTAL_TOPO_COUNT = '__HORIZON_TOTAL_TOPO_COUNT',
  HORIZON_TOTAL_LOCATION_COUNT = 'HORIZON_TOTAL_LOCATION_COUNT',
  HORIZON_TOTAL_DEPLOYMENT_COUNT = 'HORIZON_TOTAL_DEPLOYMENT_COUNT',
  HORIZON_TOTAL_POD_COUNT = 'HORIZON_TOTAL_POD_COUNT',
  HORIZON_TOTAL_POOL_COUNT = 'HORIZON_TOTAL_POOL_COUNT',
  HORIZON_DEPLOYMENT_COUNT_BY_LOCATION_NAME = 'HORIZON_DEPLOYMENT_COUNT_BY_LOCATION_NAME',
  HORIZON_POD_COUNT_BY_DEPLOYMENT_TYPE = 'HORIZON_POD_COUNT_BY_DEPLOYMENT_TYPE',
  HORIZON_POOL_COUNT_BY_POD_NAME = 'HORIZON_POOL_COUNT_BY_POD_NAME',
  HORIZON_POOL_LIST_BY_POD_NAME = 'HORIZON_POOL_LIST_BY_POD_NAME',
  HORIZON_SESSION_TOTAL_ACTIVE_COUNT = 'HORIZON_SESSION_TOTAL_ACTIVE_COUNT',
  HORIZON_SESSION_TOTAL_COUNT = 'HORIZON_SESSION_TOTAL_COUNT',
  HORIZON_SESSION_UNIQUE_USER_COUNT = 'HORIZON_SESSION_UNIQUE_USER_COUNT',
  HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS = 'HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS',
  __HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS = '__HORIZON_SESSION_UNIQUE_USER_COUNT_BY_SESSIONS',
  HORIZON_SESSION_IMPACTED_USERS = 'HORIZON_SESSION_IMPACTED_USERS',
  HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS = 'HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS',
  __HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS = '__HORIZON_SESSION_IMPACTED_USERS_BY_SESSIONS',
  HORIZON_SESSION_SUMMARY_BY_SESSION_POOL_TYPE_AND_STATUS = 'HORIZON_SESSION_SUMMARY_BY_SESSION_POOL_TYPE_AND_STATUS',
  HORIZON_SESSION_IMPACTED_USERS_BY_POD = 'HORIZON_SESSION_IMPACTED_USERS_BY_POD',
  HORIZON_SESSION_IMPACTED_USERS_BY_POOL = 'HORIZON_SESSION_IMPACTED_USERS_BY_POOL',

  // Horizon - Summary - Titan
  __HORIZON_TOTAL_TOPO_COUNT_TITAN = '__HORIZON_TOTAL_TOPO_COUNT_TITAN',
  HORIZON_EDGE_COUNT_BY_SITE_NAME = 'HORIZON_EDGE_COUNT_BY_SITE_NAME',
  HORIZON_POOL_TEMPLATE_COUNT_BY_EDGE_NAME = 'HORIZON_POOL_TEMPLATE_COUNT_BY_EDGE_NAME',
  HORIZON_POOL_TEMPLATE_LIST_BY_EDGE_NAME = 'HORIZON_POOL_TEMPLATE_LIST_BY_EDGE_NAME',
  HORIZON_TOTAL_EDGE_COUNT = 'HORIZON_TOTAL_EDGE_COUNT',
  HORIZON_TOTAL_POOL_TEMPLATE_COUNT = 'HORIZON_TOTAL_POOL_TEMPLATE_COUNT',
  HORIZON_TOTAL_SITE_COUNT = 'HORIZON_TOTAL_SITE_COUNT',
  HORIZON_SESSION_IMPACTED_USERS_BY_EDGE = 'HORIZON_SESSION_IMPACTED_USERS_BY_EDGE',
  HORIZON_SESSION_IMPACTED_USERS_BY_POOLTEMPLATE = 'HORIZON_SESSION_IMPACTED_USERS_BY_POOLTEMPLATE',
  HORIZON_SESSION_SUMMARY_BY_SESSION_POOLTEMPLATE_TYPE_AND_STATUS = 'HORIZON_SESSION_SUMMARY_BY_SESSION_POOLTEMPLATE_TYPE_AND_STATUS',

  // Horizon - Summary - Utilization
  __HORIZON_UTILIZATION_FOR_VMWARE_SDDC = '__HORIZON_UTILIZATION_FOR_VMWARE_SDDC',
  HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC_PODS = 'HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC_PODS',
  HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC_PODS = 'HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC_PODS',
  HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC_PODS = 'HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC_PODS',
  __HORIZON_UTILIZATION_FOR_AZURE = '__HORIZON_UTILIZATION_FOR_AZURE',
  HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_PODS = 'HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_PODS',
  HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_PODS = 'HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_PODS',
  HORIZON_CAPACITY_USAGE_FOR_AZURE_PODS = 'HORIZON_CAPACITY_USAGE_FOR_AZURE_PODS',

  // Horizon - Summary - Utilization - Titan
  __HORIZON_TITAN_UTILIZATION = '__HORIZON_TITAN_UTILIZATION',
  HORIZON_TITAN_CAPACITY = 'HORIZON_TITAN_CAPACITY',
  HORIZON_TITAN_VM_COUNT = 'HORIZON_TITAN_VM_COUNT',
  HORIZON_TITAN_VDI_SESSION_CAPACITY = 'HORIZON_TITAN_VDI_SESSION_CAPACITY',
  HORIZON_TITAN_RDSH_SESSION_CAPACITY = 'HORIZON_TITAN_RDSH_SESSION_CAPACITY',
  HORIZON_TITAN_VDI_SESSION_SUMMARY_BY_SESSION_TYPE = 'HORIZON_TITAN_VDI_SESSION_SUMMARY_BY_SESSION_TYPE',
  HORIZON_TITAN_RDSH_SESSION_SUMMARY_BY_SESSION_TYPE = 'HORIZON_TITAN_RDSH_SESSION_SUMMARY_BY_SESSION_TYPE',

  // Horizon - Utilization - Sub Dashboard
  HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC = 'HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC',
  HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC_HISTORICAL = 'HORIZON_CPU_UTILIZATION_FOR_VMWARE_SDDC_HISTORICAL',
  HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC = 'HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC',
  HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC_HISTORICAL = 'HORIZON_MEMORY_UTILIZATION_FOR_VMWARE_SDDC_HISTORICAL',
  HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC = 'HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC',
  HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC_HISTORICAL = 'HORIZON_DISK_UTILIZATION_FOR_VMWARE_SDDC_HISTORICAL',
  HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE = 'HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE',
  HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE = 'HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE',
  __HORIZON_UTILIZATION_HISTORICAL_FOR_AZURE = '__HORIZON_UTILIZATION_HISTORICAL_FOR_AZURE',
  HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL = 'HORIZON_DESKTOP_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL',
  HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL = 'HORIZON_APPLICATION_SESSION_UTILIZATION_FOR_AZURE_HISTORICAL',
  HORIZON_CAPACITY_USAGE_FOR_AZURE_HISTORICAL = 'HORIZON_CAPACITY_USAGE_FOR_AZURE_HISTORICAL',

  // Horizon - Sessions
  HORIZON_SESSION_TOTAL_DISCONNECTED_COUNT = 'HORIZON_SESSION_TOTAL_DISCONNECTED_COUNT',
  HORIZON_SESSION_TOTAL_IDLE_COUNT = 'HORIZON_SESSION_TOTAL_IDLE_COUNT',
  HORIZON_SESSION_PERFORMANCE_BY_LOGON_DURATION = 'HORIZON_SESSION_PERFORMANCE_BY_LOGON_DURATION',
  HORIZON_SESSION_PERFORMANCE_BY_BLAST_LATENCY = 'HORIZON_SESSION_PERFORMANCE_BY_BLAST_LATENCY',
  HORIZON_SESSION_PERFORMANCE_BY_PCOIP_LATENCY = 'HORIZON_SESSION_PERFORMANCE_BY_PCOIP_LATENCY',
  HORIZON_VM_PERFORMANCE_BY_CPU_UTILIZATION = 'HORIZON_VM_PERFORMANCE_BY_CPU_UTILIZATION',
  HORIZON_VM_PERFORMANCE_BY_MEM_UTILIZATION = 'HORIZON_VM_PERFORMANCE_BY_MEM_UTILIZATION',
  HORIZON_VM_PERFORMANCE_BY_DISKIOPS_UTILIZATION = 'HORIZON_VM_PERFORMANCE_BY_DISKIOPS_UTILIZATION',
  HORIZON_VM_PERFORMANCE_BY_DISK_LATENCY = 'HORIZON_VM_PERFORMANCE_BY_DISK_LATENCY',

  // Horizon - Users
  HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE = 'HORIZON_SESSION_ISSUES_BY_LATENCY_TYPE',
  __HORIZON_SESSION_COUNT_BY_ISSUE = '__HORIZON_SESSION_COUNT_BY_ISSUE',
  HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE = 'HORIZON_SESSION_ISSUES_BY_PROTOCOL_TYPE',
  HORIZON_SESSION_ISSUES_BY_LOGON_TYPE = 'HORIZON_SESSION_ISSUES_BY_LOGON_TYPE',

  // Horizon - Connections
  HORIZON_SESSION_SUMMARY_BY_CLIENT_TYPE = 'HORIZON_SESSION_SUMMARY_BY_CLIENT_TYPE',
  HORIZON_SESSION_SUMMARY_BY_CLIENT_PROTOCOL = 'HORIZON_SESSION_SUMMARY_BY_CLIENT_PROTOCOL',

  // Horizon - Historical session
  HORIZON_SESSION_COUNT_BY_TYPE_POOL_TYPE_AND_STATUS_HISTORICAL = 'HORIZON_SESSION_COUNT_BY_TYPE_POOL_TYPE_AND_STATUS_HISTORICAL',

  // Horizon - Titan - Historical session
  // eslint-disable-next-line max-len
  HORIZON_SESSION_COUNT_BY_TYPE_POOLTEMPLATE_TYPE_AND_STATUS_HISTORICAL = 'HORIZON_SESSION_COUNT_BY_TYPE_POOLTEMPLATE_TYPE_AND_STATUS_HISTORICAL',
}
