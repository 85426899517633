/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * TenantType
 * @export
 * @enum {string}
 */
export enum TenantType {
  PRODUCTION = 'PRODUCTION',
  TRIAL = 'TRIAL',
}
