/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { DashboardConfig } from './dashboard.config';
import { DeviceCrumbType } from './device-crumb-type.enum';

/**
 * DeviceCrumbConfig
 * @export
 * @class DeviceCrumbConfig
 */
export class DeviceCrumbConfig {
  public static readonly deviceBreadCrumbEventListTimeSpan = 1000 * 60 * 5;
  public static readonly deviceBreadCrumbEventCategoryConfig = {
    [DeviceCrumbType.APPS]: {
      color: DashboardConfig.colors.orange,
      icon: 'application',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.APPS',
    },
    [DeviceCrumbType.DEVICES]: {
      color: DashboardConfig.colors.red,
      icon: 'devices',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICES',
    },
    [DeviceCrumbType.DEVICE_PERFORMANCE]: {
      color: DashboardConfig.colors.yellow,
      icon: 'dashboard',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DEVICE_PERFORMANCE',
    },
    [DeviceCrumbType.DISPLAY]: {
      color: DashboardConfig.colors.navyBlue,
      icon: 'display',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.DISPLAY',
    },
    [DeviceCrumbType.NET_EVENT]: {
      color: DashboardConfig.colors.green,
      icon: 'network-globe',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.NETWORK',
    },
    [DeviceCrumbType.OS_UPDATES]: {
      color: DashboardConfig.colors.babyBlue,
      icon: 'update',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.OS_UPDATES',
    },
    [DeviceCrumbType.SERVICES]: {
      color: DashboardConfig.colors.purple,
      icon: 'network-settings',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.SERVICES',
    },
    [DeviceCrumbType.USER_ACTIONS]: {
      color: DashboardConfig.colors.pink,
      icon: 'user',
      label: 'STANDARD_DASHBOARD.DEVICES_DETAILS.USER_ACTIONS',
    },
  };
}
