/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * AuthorizedGrantType
 * @export
 * @enum {string}
 */
export enum AuthorizedGrantType {
  AUTHORIZATION_CODE = 'AUTHORIZATION_CODE',
  CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}
