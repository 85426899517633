/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { OrgDetail } from './org-detail.model';

const filterItem = (item: OrgTreeNode, value: number): OrgTreeNode => {
  const isMatch = item.value === value;
  if (isMatch) {
    return item;
  } else {
    if (item.children && item.children.length > 0) {
      const children: OrgTreeNode[] = [];
      for (const child of item.children) {
        const newChild = filterItem(child, value);
        if (newChild) {
          children.push(newChild);
          break;
        }
      }
      if (children.length > 0) {
        return children[0];
      }
    }
  }
  return undefined;
};

/**
 * Org Health Metric Model Object
 *
 * @export
 * @class OrgHealthMetric
 */
@Serializable
export class OrgTreeNode {
  @JsonProperty('id')
  public value: number = undefined;

  @JsonProperty({ name: 'data', cls: OrgDetail })
  public data: OrgDetail = undefined;

  @JsonProperty({ name: 'children', cls: OrgTreeNode })
  public children?: OrgTreeNode[] = undefined;

  /**
   * Creates an instance of OrgTreeNode.
   * @param {Array<Partial<OrgTreeNode>>} args
   * @memberof OrgTreeNode
   */
  constructor(...args: Array<Partial<OrgTreeNode>>) {
    Object.assign(this, ...args);
  }

  /**
   * getTextByValue
   * @param {number} value
   * @returns {string}
   * @memberof OrgTreeNode
   */
  public getTextByValue(value: number): string {
    const item = filterItem(this, value);
    return item && item.data ? item.data.text : '';
  }

  /**
   * findParentNode
   * Find and return first parent (direct/indirect) node of current instance from an array of nodes
   *
   * @param {OrgTreeNode[]} nodes
   * @returns {OrgTreeNode}
   * @memberof OrgTreeNode
   */
  public findParentNode(nodes: OrgTreeNode[]): OrgTreeNode {
    return nodes.find((item: OrgTreeNode) => !!filterItem(item, this.value));
  }
}
