/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppDetailTabType
 * @export
 * @enum {string}
 */
export enum AppDetailTabType {
  ERRORS = 'ERRORS',
  EXPERIENCE = 'EXPERIENCE',
  HANDLED_EXCEPTION = 'HANDLED_EXCEPTION',
  NETWORK_INSIGHTS = 'NETWORK_INSIGHTS',
  NETWORK_INSIGHTS_DETAILS = 'NETWORK_INSIGHTS_DETAILS',
  OVERVIEW = 'OVERVIEW',
  PLUGIN_EXCEPTION = 'PLUGIN_EXCEPTION',
  SETTINGS = 'SETTINGS',
  USER_FLOWS = 'USER_FLOWS',
  USER_PROFILE = 'USER_PROFILE',
  USERS = 'USERS',
}
