/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from '@ws1c/intelligence-models/converters';
import { CompatibilityResponse } from './compatibility-response.model';

/**
 * AvailableServiceTenant
 * @export
 * @class AvailableServiceTenant
 */
@Serializable
export class AvailableServiceTenant {
  @JsonProperty('automation_count')
  public automationCount: number = undefined;

  @JsonProperty({ name: 'compatibility_response', cls: CompatibilityResponse })
  public compatibilityResponse: CompatibilityResponse = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('service_config_id')
  public serviceConfigId?: string = undefined;

  @JsonProperty('status_check_success')
  public statusCheckSuccess: boolean = undefined;

  @JsonProperty({ name: 'status_last_check_at', customConverter: dateFormatConverter })
  public statusLastChecked: number = undefined;

  @JsonProperty('tenant_id')
  public tenantId: string = undefined;

  /**
   * Creates an instance of AvailableServiceTenant
   * @param {Array<Partial<AvailableServiceTenant>>} args
   * @memberof AvailableServiceTenant
   */
  constructor(...args: Array<Partial<AvailableServiceTenant>>) {
    Object.assign(this, ...args);
  }
}
