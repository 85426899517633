/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * AppDetailUserProfileTab
 * @export
 * @enum {number}
 */
export enum AppDetailUserProfileTab {
  OVERVIEW,
  DEVICE,
}
