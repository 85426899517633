/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { COLUMN_NAMES } from '@ws1c/intelligence-models/integration-meta/column-names.constant';
import { GenericCrumb } from './generic-crumb.model';

/**
 * DeviceOsUpdatesCrumb
 * @export
 * @class DeviceOsUpdatesCrumb
 * @extends {DeviceCrumb}
 */
@Serializable
export class DeviceOsUpdatesCrumb extends GenericCrumb {
  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_name)
  public eventName: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_event_timestamp)
  public eventTimestamp: number = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_title)
  public title: string = undefined;

  @JsonProperty(COLUMN_NAMES.byFullyQualifiedName.employee_experience_os_updates_duration_millis)
  public duration: number = undefined;

  /**
   * Creates an instance of DeviceOsUpdatesCrumb.
   * @param {...Array<Partial<DeviceOsUpdatesCrumb>>} args
   * @memberof DeviceOsUpdatesCrumb
   */
  constructor(...args: Array<Partial<DeviceOsUpdatesCrumb>>) {
    super();
    Object.assign(this, ...args);
  }
}
