/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * OsSummaryDashboardType
 * @export
 * @enum {string}
 */
export enum OsSummaryDashboardType {
  // For Os Update Dashboard
  OS_SUMMARY = 'OS_SUMMARY',
  // used for the charts in each tab
  OS_VERSIONS = 'OS_VERSIONS',
  OS_WINDOWS_PATCHES = 'OS_WINDOWS_PATCHES',
}

/**
 * OsSummaryWidgetSubtype
 * @export
 * @enum {string}
 */
export enum OsSummaryWidgetSubtype {
  // For Os Update Dashboard
  OS_SUMMARY_DEVICES_BY_PLATFORM = 'OS_SUMMARY_DEVICES_BY_PLATFORM',
  OS_SUMMARY_VERSIONS_BY_PLATFORM = 'OS_SUMMARY_VERSIONS_BY_PLATFORM',
  OS_SUMMARY_BREAK_DOWN_BY_PLATFORM = 'OS_SUMMARY_BREAK_DOWN_BY_PLATFORM',
  OS_WINDOWS_PATCH_STATUS = 'OS_WINDOWS_PATCH_STATUS',
  OS_WINDOWS_PATCH_UPDATES_HISTORICAL = 'OS_WINDOWS_PATCH_UPDATES_HISTORICAL',
  OS_WINDOWS_PATCH_STATUS_BY_TIME_RANGE = 'OS_WINDOWS_PATCH_STATUS_BY_TIME_RANGE',
  OS_WINDOWS_PATCH_STATUS_BY_OS_VERSION_COUNT = 'OS_WINDOWS_PATCH_STATUS_BY_OS_VERSION_COUNT',
  OS_WINDOWS_PATCH_STATUS_BY_UPDATE_CLASSIFICATION_COUNT = 'OS_WINDOWS_PATCH_STATUS_BY_UPDATE_CLASSIFICATION_COUNT',
  OS_WINDOWS_PATCH_STATUS_BY_DEVICE_MODEL_COUNT = 'OS_WINDOWS_PATCH_STATUS_BY_DEVICE_MODEL_COUNT',
  OS_WINDOWS_PATCH_STATUS_BY_ORGANIZATION_GROUP_COUNT = 'OS_WINDOWS_PATCH_STATUS_BY_ORGANIZATION_GROUP_COUNT',
  OS_WINDOWS_PATCH_STATUS_UPDATES_TOTAL_COUNT = 'OS_WINDOWS_PATCH_STATUS_UPDATES_TOTAL_COUNT',
  OS_WINDOWS_PATCH_STATUS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD = 'OS_WINDOWS_PATCH_STATUS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD',
  __OS_WINDOWS_PATCH_STATUS_UPDATES_RATE = '__OS_WINDOWS_PATCH_STATUS_UPDATES_RATE',
  __OS_WINDOWS_PATCH_STATUS_UPDATES_RATE_PREVIOUS_PERIOD = '__OS_WINDOWS_PATCH_STATUS_UPDATES_RATE_PREVIOUS_PERIOD',
  // os_versions
  OS_VERSIONS_COUNT_SUMMARY = 'OS_VERSIONS_COUNT_SUMMARY',
  OS_VERSIONS_SUMMARY = 'OS_VERSIONS_SUMMARY',
  OS_WINDOWS_PATCH_COUNT = 'OS_WINDOWS_PATCH_COUNT',
  // widgets
  OS_VERSIONS_DEVICE_COUNT = 'OS_VERSIONS_DEVICE_COUNT',
  OS_VERSIONS_BY_TIME_RANGE = 'OS_VERSIONS_BY_TIME_RANGE',
  OS_VERSIONS_HISTORICAL_CHANGE_EVENTS = 'OS_VERSIONS_HISTORICAL_CHANGE_EVENTS',
  OS_VERSIONS_BY_OS_VERSION_COUNT = 'OS_VERSIONS_BY_OS_VERSION_COUNT',
  OS_VERSIONS_BY_DEVICE_MODEL_COUNT = 'OS_VERSIONS_BY_DEVICE_MODEL_COUNT',
  OS_VERSIONS_BY_ORGANIZATION_GROUP_COUNT = 'OS_VERSIONS_BY_ORGANIZATION_GROUP_COUNT',
  OS_VERSIONS_BY_OWNERSHIP_COUNT = 'OS_VERSIONS_BY_OWNERSHIP_COUNT',
  OS_VERSIONS_UPDATES_HISTORICAL = 'OS_VERSIONS_UPDATES_HISTORICAL',
  OS_VERSIONS_UPDATES_TOTAL_COUNT = 'OS_VERSIONS_UPDATES_TOTAL_COUNT',
  OS_VERSIONS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD = 'OS_VERSIONS_UPDATES_TOTAL_COUNT_PREVIOUS_PERIOD',
  OS_VERSIONS_DEVICE_TOTAL_COUNT = 'OS_VERSIONS_DEVICE_TOTAL_COUNT',
  __OS_VERSIONS_UPDATES_RATE = '__OS_VERSIONS_UPDATES_RATE',
  __OS_VERSIONS_UPDATES_RATE_PREVIOUS_PERIOD = '__OS_VERSIONS_UPDATES_RATE_PREVIOUS_PERIOD',
}
