/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { DashboardType } from '@ws1c/intelligence-models/dashboard/dashboard.enum';
import { Template } from '@ws1c/intelligence-models/template/template.model';
import { WidgetTemplate } from '@ws1c/intelligence-models/template/widget/widget-template.model';

/**
 * DashboardTemplate
 * @export
 * @class DashboardTemplate
 * @extends Template
 */
@Serializable
export class DashboardTemplate extends Template {
  @JsonProperty({ name: 'dashboard_type', customConverter: enumConverterFactory(DashboardType) })
  public dashboardType: DashboardType = undefined;

  @JsonProperty('i18n_name')
  public i18nName: string = undefined;

  @JsonProperty('i18n_description')
  public i18nDescription: string = undefined;

  @JsonProperty({ name: 'widgets', cls: WidgetTemplate })
  public widgets: WidgetTemplate[] = undefined;

  /**
   * Creates an instance of DashboardTemplate.
   * @param {Array<Partial<DashboardTemplate>>} args
   * @memberof DashboardTemplate
   */
  constructor(...args: Array<Partial<DashboardTemplate>>) {
    super(...args);
    Object.assign(this, ...args);
  }

  /**
   * label
   * @readonly
   * @type {string}
   * @memberof DashboardTemplate
   */
  public get label(): string {
    return this.i18nName;
  }

  /**
   * description
   * @readonly
   * @type {string}
   * @memberof DashboardTemplate
   */
  public get description() {
    return this.i18nDescription;
  }

  /**
   * templateId
   * @readonly
   * @type {string}
   * @memberof DashboardTemplate
   */
  public get templateId(): string {
    return this.id;
  }

  /**
   * integration
   * @readonly
   * @type {string}
   * @memberof DashboardTemplate
   */
  public get integration(): string {
    return '';
  }

  /**
   * entity
   * @readonly
   * @type {string}
   * @memberof DashboardTemplate
   */
  public get entity(): string {
    return '';
  }

  /**
   * templateKey
   *
   * @readonly
   * @type {string}
   * @memberof DashboardTemplate
   */
  public get templateKey(): string {
    return this.id;
  }
}
