/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * NavigationMenuGroup
 * @export
 * @enum {NavigationMenuGroup}
 */
export enum NavigationMenuGroup {
  BOOKMARKS,
  WORKSPACE_UEM,
  WORKSPACE_VIRTUAL_APPS_DESKTOP,
  WORKSPACE_EXPERIENCE_MANAGEMENT,
  WORKSPACE_WORKSPACE_SECURITY,
  MARKETPLACE_INTEGRATIONS,
  MARKETPLACE_SOLUTIONS,
  MARKETPLACE_TEMPLATES,
  NOTIFICATIONS,
}
