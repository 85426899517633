/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ReportRetentionDetails
 * @export
 * @class ReportRetentionDetails
 */
@Serializable
export class ReportRetentionDetails {
  @JsonProperty('report_retention_enabled')
  public reportRetentionEnabled: boolean = undefined;

  @JsonProperty('org_id')
  public orgId: string = undefined;

  @JsonProperty('report_retention_days')
  public reportRetentionDays: number = undefined;

  /**
   * Creates an instance of ReportRetentionDetails.
   * @param {Array<Partial<ReportRetentionDetails>>} args
   * @memberof ReportRetentionDetails
   */
  constructor(...args: Array<Partial<ReportRetentionDetails>>) {
    Object.assign(this, ...args);
  }
}
