/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ConnectionConfig } from './config.model';

/**
 * ConnectionIndex
 * @export
 */
export interface ConnectionIndex {
  [key: string]: Connection;
}

/**
 * Connection Model Object
 *
 * @export
 * @class Connection
 */
@Serializable
export class Connection {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('service_id')
  public serviceId: string = undefined;

  @JsonProperty('status_check_success')
  public statusCheckSuccess: boolean = undefined;

  @JsonProperty({ name: 'config', cls: ConnectionConfig })
  public config: ConnectionConfig = undefined;

  /**
   * Creates an instance of Connection
   * @param {any} args
   * @memberof Connection
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
