/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { cloneDeep, filter, remove } from 'lodash-es';

import { BucketSelection, ChartDrilldownEvent } from './chart-drilldown-event.interface';
import { CompositeTrendDefinition } from './composite-trend-definition.model';
import { DashboardConfig } from './dashboard.config';
import { ComposeFunction } from './dashboard.enum';
import { ComposeConfig } from './trend-composer.interface';
import { TrendDefinitionDrilldownApplier } from './trend-definition-drilldown-applier.model';

/**
 * CompositeTrendDrilldownApplier
 * @export
 * @class CompositeTrendDrilldownApplier
 */
export class CompositeTrendDrilldownApplier {
  /**
   * applyDrilldownEvents
   * @param {CompositeTrendDefinition} initialCompositeTrendDefinition
   * @param {ChartDrilldownEvent[]} activeWidgetDetailDrilldownEvents
   * @param {boolean} forTableTrend
   * @returns {CompositeTrendDefinition}
   * @memberof CompositeTrendDrilldownApplier
   */
  public applyDrilldownEvents(
    initialCompositeTrendDefinition: CompositeTrendDefinition,
    activeWidgetDetailDrilldownEvents: ChartDrilldownEvent[],
    forTableTrend: boolean = false,
  ): CompositeTrendDefinition {
    initialCompositeTrendDefinition = cloneDeep(initialCompositeTrendDefinition);
    activeWidgetDetailDrilldownEvents = cloneDeep(activeWidgetDetailDrilldownEvents) || [];

    return activeWidgetDetailDrilldownEvents.reduce(
      (compositeTrendDefinition: CompositeTrendDefinition, drilldownEvent: ChartDrilldownEvent) => {
        return this.applyDrilldownEvent(compositeTrendDefinition, drilldownEvent, forTableTrend);
      },
      initialCompositeTrendDefinition,
    );
  }

  /**
   * applyDrilldownEvent
   * @param {CompositeTrendDefinition} compositeTrendDefinition
   * @param {ChartDrilldownEvent} drilldownEvent
   * @param {boolean} forTableTrend
   * @param {string} activeSubtype
   * @returns {CompositeTrendDefinition}
   * @memberof CompositeTrendDrilldownApplier
   */
  public applyDrilldownEvent(
    compositeTrendDefinition: CompositeTrendDefinition,
    drilldownEvent: ChartDrilldownEvent,
    forTableTrend: boolean,
    activeSubtype: string = compositeTrendDefinition.mainName,
  ): CompositeTrendDefinition {
    const activeComposeConfig = compositeTrendDefinition.composeConfigs[activeSubtype];
    const activeTrendDefinition = compositeTrendDefinition.trendDefinitions[activeSubtype];

    if (activeComposeConfig) {
      switch (activeComposeConfig.composeFunction) {
        case ComposeFunction.MERGE_SERIES:
          this.mergeSeriesDrilldown(compositeTrendDefinition, drilldownEvent, activeComposeConfig, forTableTrend);
          break;
        case ComposeFunction.MERGE_PREVIOUS_PERIOD_SERIES:
          this.mergePreviousPeriodDrilldown(compositeTrendDefinition, drilldownEvent, activeComposeConfig);
          break;
      }
      activeComposeConfig.dependencies.forEach((dependency: string) => {
        this.applyDrilldownEvent(compositeTrendDefinition, drilldownEvent, forTableTrend, dependency);
      });
    }

    if (activeTrendDefinition) {
      const bucketingAttributes = new Set(activeTrendDefinition.bucketingAttributes);
      const nextSelectedBuckets = filter(drilldownEvent.selectedBuckets, (selection: BucketSelection) =>
        bucketingAttributes.has(selection.bucketName),
      );
      const newDrilldownEvent = {
        ...drilldownEvent,
        selectedBuckets: nextSelectedBuckets,
      } as ChartDrilldownEvent;

      const tdda = new TrendDefinitionDrilldownApplier();
      const nextTrendDefinition = tdda.applyDrilldownEventToTrendDefinition(activeTrendDefinition, newDrilldownEvent);
      Object.assign(activeTrendDefinition, nextTrendDefinition);
    }
    return compositeTrendDefinition;
  }

  /**
   * mergeSeriesDrilldown
   * @param {CompositeTrendDefinition} compositeTrendDefinition
   * @param {ChartDrilldownEvent} drilldownEvent
   * @param {ComposeConfig} activeComposeConfig
   * @param {boolean} forTableTrend
   * @memberof CompositeTrendDrilldownApplier
   */
  public mergeSeriesDrilldown(
    compositeTrendDefinition: CompositeTrendDefinition,
    drilldownEvent: ChartDrilldownEvent,
    activeComposeConfig: ComposeConfig,
    forTableTrend: boolean,
  ) {
    const mergeBucket = remove(drilldownEvent.selectedBuckets, (selectedBucket: BucketSelection) => {
      return selectedBucket.bucketName === activeComposeConfig.composeFunctionParams.seriesName;
    })[0];
    if (mergeBucket) {
      // If drilled down on a merged series, skip merging together with other series.
      const seriesIndex = activeComposeConfig.composeFunctionParams.seriesValues.indexOf(mergeBucket.selectedValue);
      activeComposeConfig.dependencies = [activeComposeConfig.dependencies[seriesIndex]];
      activeComposeConfig.composeFunction = ComposeFunction.NOOP;
    }

    // If focused on a list of merged series, adjust the composeConfig to only include focused values
    // The request for non-table trends is for all data (including unfocused series)
    if (
      forTableTrend &&
      drilldownEvent.setFocusedSeries &&
      drilldownEvent.setFocusedSeries.colorizedAttributeName === activeComposeConfig.composeFunctionParams.seriesName
    ) {
      const focusedValueSet = new Set(drilldownEvent.setFocusedSeries.seriesNames);
      const seriesValues = activeComposeConfig.composeFunctionParams.seriesValues;
      const nextDependencies = [];
      const nextSeriesValues = [];
      for (let i = 0; i < seriesValues.length; i++) {
        const seriesValue = seriesValues[i];
        if (focusedValueSet.has(seriesValue)) {
          nextDependencies.push(activeComposeConfig.dependencies[i]);
          nextSeriesValues.push(activeComposeConfig.composeFunctionParams.seriesValues[i]);
        }
      }
      activeComposeConfig.composeFunctionParams.seriesValues = nextSeriesValues;
      activeComposeConfig.dependencies = nextDependencies;
      activeComposeConfig.composeFunction = nextDependencies.length ? ComposeFunction.MERGE_SERIES : ComposeFunction.NOOP;
    }
  }

  /**
   * mergePreviousPeriodDrilldown
   * @param {CompositeTrendDefinition} compositeTrendDefinition
   * @param {ChartDrilldownEvent} drilldownEvent
   * @param {ComposeConfig} activeComposeConfig
   * @memberof CompositeTrendDrilldownApplier
   */
  public mergePreviousPeriodDrilldown(
    compositeTrendDefinition: CompositeTrendDefinition,
    drilldownEvent: ChartDrilldownEvent,
    activeComposeConfig: ComposeConfig,
  ) {
    const timePeriodBucket = remove(drilldownEvent.selectedBuckets, (selectedBucket: BucketSelection) => {
      return selectedBucket.bucketName === DashboardConfig.timePeriodBucketName;
    })[0];
    if (timePeriodBucket) {
      if (timePeriodBucket.selectedValue === DashboardConfig.selectedTimePeriodName) {
        // skip merging together with other series
        activeComposeConfig.dependencies = [activeComposeConfig.dependencies[0]];
        activeComposeConfig.composeFunction = ComposeFunction.NOOP;
      }
      if (timePeriodBucket.selectedValue === DashboardConfig.previousTimePeriodName) {
        // skip merging together with other series
        activeComposeConfig.dependencies = [activeComposeConfig.dependencies[1]];
        activeComposeConfig.composeFunction = ComposeFunction.NOOP;

        // need to remove start/end date since previous dates are dropped when merging with current period trend
        drilldownEvent.startDateMillis = undefined;
        drilldownEvent.endDateMillis = undefined;
      }
    }
  }
}
