/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { UserTouchDetails } from '@ws1c/intelligence-models/user-touch-details.model';
import { DeemPlaybookState } from './deem-playbook-state.enum';
import { DeemPlaybookStep } from './deem-playbook-step.model';
import { DeemPlaybookType } from './deem-playbook-type.enum';

/**
 * DeemPlaybook
 * @export
 * @class DeemPlaybook
 */
@Serializable
export class DeemPlaybook extends UserTouchDetails {
  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('id')
  public playbookVersionId: string = undefined;

  @JsonProperty('playbook_id')
  public playbookId: string = undefined;

  @JsonProperty('survey_id')
  public surveyId: string = undefined;

  @JsonProperty('org_id')
  public orgId: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty({ name: 'playbook_type', customConverter: enumConverterFactory(DeemPlaybookType) })
  public playbookType: DeemPlaybookType = undefined;

  @JsonProperty('version')
  public version: number = undefined;

  @JsonProperty({ name: 'state', customConverter: enumConverterFactory(DeemPlaybookState) })
  public state: DeemPlaybookState = undefined;

  @JsonProperty('incidents_deployed')
  public incidentsDeployed: number = undefined;

  @JsonProperty('incidents_resolved')
  public incidentsResolved: number = undefined;

  @JsonProperty({ name: 'step_summaries', cls: DeemPlaybookStep })
  public steps: DeemPlaybookStep[] = [];

  /**
   * constructor
   * @param {Partial<DeemPlaybook>[]} args
   */
  constructor(...args: Array<Partial<DeemPlaybook>>) {
    super();
    Object.assign(this, ...args);
  }
}
