/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * NetEventCrumb
 * @export
 * @class NetEventCrumb
 */
@Serializable
export class NetEventCrumb {
  @JsonProperty('apteligent.net_event.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.net_event._url')
  public url: string = undefined;

  @JsonProperty('apteligent.net_event._url_host')
  public urlHost: string = undefined;

  @JsonProperty('apteligent.net_event.http_method')
  public httpMethod: string = undefined;

  @JsonProperty('apteligent.net_event._latency')
  public latency: number = undefined;

  @JsonProperty('apteligent.net_event.http_status_code')
  public httpStatusCode: number = undefined;

  @JsonProperty('apteligent.net_event.bytes_received')
  public bytesReceived: number = undefined;

  @JsonProperty('apteligent.net_event.bytes_sent')
  public bytesSent: number = undefined;

  /**
   * Creates an instance of NetEventCrumb.
   * @param {...Array<Partial<NetEventCrumb>>} args
   * @memberof NetEventCrumb
   */
  constructor(...args: Array<Partial<NetEventCrumb>>) {
    Object.assign(this, ...args);
  }
}
