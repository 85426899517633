/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { AccountRole } from './account-role.model';
import { AccessType } from './common/access-type.enum';
import { DataAccessPolicy } from './data-access-policy.model';
import { UserDescriptor } from './user-descriptor.model';

/**
 * UserAdminAccount
 * @exports
 * @class UserAdminAccount
 */
@Serializable
export class UserAdminAccount {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('email')
  public email: string = undefined;

  @JsonProperty('first_name')
  public firstName: string = undefined;

  @JsonProperty('last_name')
  public lastName: string = undefined;

  @JsonProperty('display_name')
  public displayName: string = undefined;

  @JsonProperty('username')
  public userName: string = undefined;

  @JsonProperty('directory_id')
  public directoryId?: string = undefined;

  @JsonProperty('directory_user_id')
  public directoryUserId?: string = undefined;

  @JsonProperty('directory_type')
  public directoryType?: string = undefined;

  @JsonProperty({ name: 'user_descriptor', cls: UserDescriptor })
  public directory: UserDescriptor = undefined;

  @JsonProperty({ name: 'access_level', customConverter: enumConverterFactory(AccessType) })
  public accessLevel?: AccessType = undefined;

  public dataAccessPolicies: DataAccessPolicy[];

  public roles: AccountRole[];

  /**
   * userInfo
   * @returns {string}
   * @memberof UserAdminAccount
   */
  public get userInfo(): string {
    const email = this.email || 'n/a';
    return this.userName ? `${this.userName} (${email})` : email;
  }

  /**
   * fullName
   * @readonly
   * @type {string}
   * @memberof UserAdminAccount
   */
  public get fullName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }
    if (this.firstName || this.lastName) {
      return this.firstName ?? this.lastName;
    }
    return this.userName;
  }

  /**
   * directoryType18nName
   *
   * @readonly
   * @type {string}
   * @memberof UserAdminAccount
   */
  public get directoryType18nName(): string {
    if (this.directory) {
      return `INTEGRATIONS.DIRECTORY.${this.directory.directoryType}`;
    }
    return this.directoryType ? `INTEGRATIONS.DIRECTORY.${this.directoryType}` : 'INTEGRATIONS.DIRECTORY.SYNCED_ADMIN_USERS';
  }

  /**
   * Creates an instance of UserAdminAccount.
   * @param {Array<Partial<UserAdminAccount>>} args
   * @memberof UserAdminAccount
   */
  constructor(...args: Array<Partial<UserAdminAccount>>) {
    Object.assign(this, ...args);
  }
}
