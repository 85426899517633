/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ServiceLimitsOverrideType
 * @export
 * @enum {string}
 */
export enum ServiceLimitsOverrideType {
  ORG = 'ORG',
  ACCOUNT = 'ACCOUNT',
  SPECIFIC_ACCOUNT = 'SPECIFIC_ACCOUNT',
}
