/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Evaluation Type
 *
 * @export
 * @enum {string}
 */
export enum EvaluationType {
  AUTOMATIC = 'AUTOMATIC',
  SCHEDULED = 'SCHEDULED',
  MANUAL = 'MANUAL',
}
