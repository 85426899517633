/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse } from '@dpa/ui-common';

import { MarketplaceResource } from './marketplace-resource.model';

/**
 * MarketplaceSearchPagedResponse
 * @export
 * @class MarketplaceSearchPagedResponse
 * @implements {PagedResponse}
 */
export class MarketplaceSearchPagedResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: MarketplaceResource })
  public results: MarketplaceResource[] = [];

  /**
   * Creates an instance of MarketplaceSearchPagedResponse.
   * @param {Array<Partial<MarketplaceSearchPagedResponse>>} args
   * @memberof MarketplaceSearchPagedResponse
   */
  constructor(...args: Array<Partial<MarketplaceSearchPagedResponse>>) {
    Object.assign(this, ...args);
  }
}
