/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * ConnectorModalType
 * @export
 * @enum {number}
 */
export enum ConnectorModalType {
  ADD,
  AUTHORIZE,
  DELETE,
  EDIT_CONFIG,
  EDIT_CONNECTOR,
  TEST_ACTION,
  DELETE_ACTION,
  RENAME_ACTION,
}
