/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * CveDetailPageType
 * @export
 * @enum {string}
 */
export enum CveDetailPageType {
  WINDOWS_CVE = 'WINDOWS_CVE',
  MACOS_CVE = 'MACOS_CVE',
}
