/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty } from '@dpa/ui-common';

import { MetaFormField } from './meta-form-field.model';

/**
 * MetaFormSection Model Object
 *
 * @export
 * @class MetaFormSection
 */
export class MetaFormSection {
  @JsonProperty({ name: 'fields', cls: MetaFormField })
  public fields: MetaFormField[] = [];

  @JsonProperty('type')
  public type: string = undefined;

  @JsonProperty('label')
  public label: string = undefined;

  /**
   * Creates an instance of MetaFormSection
   *
   * @param {*} args
   * @memberof MetaFormSection
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
