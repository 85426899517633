/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from './converters/date-format.converter';
import { UserDetails } from './user-details.model';

/**
 * Integration Details Model
 *
 * @export
 * @class IntegrationDetails
 */
@Serializable
export class IntegrationDetails {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('tenant_id')
  public tenantId: string = undefined;

  @JsonProperty('tenant_name')
  public tenantName: string = undefined;

  @JsonProperty('tenant_uuid')
  public tenantUuid: string = undefined;

  @JsonProperty('source_system_id')
  public sourceSystemId: string = undefined;

  @JsonProperty('tenant_host')
  public tenantHost: string = undefined;

  @JsonProperty('tenant_url')
  public tenantUrl: string = undefined;

  @JsonProperty('active')
  public active: boolean = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty('admin_console_url')
  public adminConsoleUrl: string = undefined;

  @JsonProperty('service_api_url')
  public apiUrl: string = undefined;

  @JsonProperty('registration_source')
  public registrationSource: string = undefined;

  /**
   * Creates an instance of IntegrationDetails
   * @param {Array<Partial<IntegrationDetails>>} args - Initialization arguments for Integration Details object
   * @memberof IntegrationDetails
   */
  constructor(...args: Array<Partial<IntegrationDetails>>) {
    Object.assign(this, ...args);
  }
}
