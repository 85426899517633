/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * CveSlaRangeSetting
 * @export
 * @class CveSlaRangeSetting
 */
@Serializable
export class CveSlaRangeSetting {
  @JsonProperty('min_score')
  public minScore: number = 0.1;

  @JsonProperty('max_score')
  public maxScore: number = 10;

  @JsonProperty('devices_to_be_patched_percentage')
  public devicesToBePatchedPercentage: number = 100;

  @JsonProperty('remediation_timeframe_hours')
  public remediationTimeframeHours: number = 168;

  /**
   * Creates an instance of CveSlaRangeSetting.
   * @param {Array<Partial<CveSlaRangeSetting>>} args
   * @memberof CveSlaRangeSetting
   */
  constructor(...args: Array<Partial<CveSlaRangeSetting>>) {
    Object.assign(this, ...args);
  }

  /**
   * remediationTimeframeDays
   * @type {number}
   * @memberof CveSlaRangeSetting
   */
  public get remediationTimeframeDays(): number {
    return this.remediationTimeframeHours / 24;
  }

  /**
   * remediationTimeframeDays
   * @param {number} days
   * @memberof CveSlaRangeSetting
   */
  public set remediationTimeframeDays(days: number) {
    this.remediationTimeframeHours = days * 24;
  }

  /**
   * remediationTimeframeMillis
   * @type {number}
   * @memberof CveSlaRangeSetting
   */
  public get remediationTimeframeMillis(): number {
    return this.remediationTimeframeHours * 3600000;
  }
}
