/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { ConnectorAction } from '@ws1c/intelligence-models/connector/connector-action.model';
import { dateFormatConverter, enumConverterFactory } from '@ws1c/intelligence-models/converters';
import { ConnectorConfigStatus } from './connector-config-status.enum';

/**
 * Connector
 *
 * @export
 * @class Connector
 */
@Serializable
export class Connector {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('name')
  public name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('icon_link')
  public iconLink: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('managed')
  public managed: boolean = undefined;

  @JsonProperty('workflows_count')
  public workflowsCount: number = undefined;

  @JsonProperty({ name: 'status', customConverter: enumConverterFactory(ConnectorConfigStatus) })
  public status: ConnectorConfigStatus = undefined;

  @JsonProperty({ name: 'status_time', customConverter: dateFormatConverter, excludeToJson: true })
  public statusTime: number = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter, excludeToJson: true })
  public modifiedAt: number = undefined;

  @JsonProperty({ name: 'modified_by', excludeToJson: true })
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter, excludeToJson: true })
  public createdAt: number = undefined;

  @JsonProperty({ name: 'created_by', excludeToJson: true })
  public createdBy: string = undefined;

  @JsonProperty({ name: 'config_schema_id', excludeToJson: true })
  public configSchemaId: string = undefined;

  @JsonProperty({ name: 'connector_config_id', excludeToJson: true })
  public configId: string = undefined;

  @JsonProperty('config_required')
  public configRequired: boolean = undefined;

  @JsonProperty('setup_info_url')
  public setupInfoUrl?: boolean = undefined;

  @JsonProperty({ name: 'actions', cls: ConnectorAction })
  public actions?: ConnectorAction[] = undefined;

  /**
   * Creates an instance of Connector.
   * @param {...Array<Partial<Connector>>} args
   * @memberof Connector
   */
  constructor(...args: Array<Partial<Connector>>) {
    Object.assign(this, ...args);
  }

  /**
   * isAuthorized
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get isAuthorized(): boolean {
    return this.status === ConnectorConfigStatus.AUTHORIZED;
  }

  /**
   * isConfigured
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get isConfigured(): boolean {
    return this.status !== ConnectorConfigStatus.NOT_AUTHORIZED;
  }

  /**
   * iconBackgroundImage
   * @readonly
   * @type {string}
   * @memberof Connector
   */
  public get iconBackgroundImage(): string {
    return `url('${this.iconLink}')`;
  }

  /**
   * allowAuthorize
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get allowAuthorize(): boolean {
    return !this.isConfigured;
  }

  /**
   * allowEdit
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get allowEdit(): boolean {
    return this.configRequired && this.isConfigured;
  }

  /**
   * allowRename
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get allowRename(): boolean {
    return !this.managed;
  }

  /**
   * isMissingWorkflowsCount
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get isMissingWorkflowsCount(): boolean {
    return this.workflowsCount === -1;
  }

  /**
   * isWorkflowsCountZero
   * @readonly
   * @type {boolean}
   * @memberof Connector
   */
  public get isWorkflowsCountZero(): boolean {
    return this.workflowsCount === 0;
  }
}
