/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * CveDashboardType
 * @export
 * @enum {string}
 */
export enum CveDashboardType {
  // For CVE Dashboard
  CVE_SUMMARY = 'CVE_SUMMARY',
  CVE_WINDOWS_PLATFORM = 'CVE_WINDOWS_PLATFORM',
  CVE_WINDOWS_PLATFORM_DETAILS = 'CVE_WINDOWS_PLATFORM_DETAILS',
  CVE_IOS_PLATFORM = 'CVE_IOS_PLATFORM',
  CVE_IOS_PLATFORM_DETAILS = 'CVE_IOS_PLATFORM_DETAILS',
  CVE_MACOS_PLATFORM = 'CVE_MACOS_PLATFORM',
  CVE_MACOS_PLATFORM_DETAILS = 'CVE_MACOS_PLATFORM_DETAILS',
}

/**
 * CveWidgetSubtype
 * @export
 * @enum {string}
 */
export enum CveWidgetSubtype {
  // For CVE Dashboard - summary
  WINDOWS_VULNERABILITIES_BY_CVSS_SCORE = 'WINDOWS_VULNERABILITIES_BY_CVSS_SCORE',
  IOS_VULNERABILITIES_BY_CVSS_SCORE = 'IOS_VULNERABILITIES_BY_CVSS_SCORE',
  // CVE - dashboard
  VULNERABILITIES_WITH_CRITICAL_CVSS_COUNT = 'VULNERABILITIES_WITH_CRITICAL_CVSS_COUNT',
  VULNERABILITIES_WITH_HIGH_CVSS_COUNT = 'VULNERABILITIES_WITH_HIGH_CVSS_COUNT',
  VULNERABILITIES_WITH_MEDIUM_CVSS_COUNT = 'VULNERABILITIES_WITH_MEDIUM_CVSS_COUNT',
  VULNERABILITIES_WITH_LOW_CVSS_COUNT = 'VULNERABILITIES_WITH_LOW_CVSS_COUNT',
  CRITICAL_UPDATES_COUNT = 'CRITICAL_UPDATES_COUNT',
  VULNERABLE_DEVICES_COUNT = 'VULNERABLE_DEVICES_COUNT',
  INSTALL_FAILED_DEVICES_COUNT = 'INSTALL_FAILED_DEVICES_COUNT',
  VULNERABILITIES_BY_CVSS_SCORE = 'VULNERABILITIES_BY_CVSS_SCORE',
  IMPACTED_DEVICES_PER_CVE_GROUP_BY_KB_SUBJECT = 'IMPACTED_DEVICES_PER_CVE_GROUP_BY_KB_SUBJECT',
  IMPACTED_DEVICES_BY_KNOWN_VULNERABILITIES = 'IMPACTED_DEVICES_BY_KNOWN_VULNERABILITIES',
  IMPACTED_DEVICES_BY_UNKNOWN_VULNERABILITIES = 'IMPACTED_DEVICES_BY_UNKNOWN_VULNERABILITIES',
  INSTALL_STATUS_SUMMARY = 'INSTALL_STATUS_SUMMARY',
  CVES_PUBLISHED_LAST_7_DAYS_COUNT = 'CVES_PUBLISHED_LAST_7_DAYS_COUNT',
  CVE_LAST_SYNC_UP_TIME = 'CVE_LAST_SYNC_UP_TIME',
  WINDOWS_10_PATCH_STATUS = 'WINDOWS_10_PATCH_STATUS',
  IOS_PATCH_STATUS = 'IOS_PATCH_STATUS',
  DEVICES_BY_PATCH_STATUS_BY_SLA_RANGE = 'DEVICES_BY_PATCH_STATUS_BY_SLA_RANGE',
  // CVE - windows detail
  __VULNERABLE_DEVICES_COUNT_BY_KB = '__VULNERABLE_DEVICES_COUNT_BY_KB',
  DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER = 'DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER',
  NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER = 'NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE_BY_KB_NUMBER',
  NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE = 'NON_VULNERABLE_DEVICES_WITH_GIVEN_CVE',
  DEVICES_WITH_GIVEN_CVE = 'DEVICES_WITH_GIVEN_CVE',
  INSTALL_STATUS_DETAIL = 'INSTALL_STATUS_DETAIL',
  IMPACTED_DEVICES_BY_VULNERABILITY = 'IMPACTED_DEVICES_BY_VULNERABILITY',
  KB_INSTALL_TREND = 'KB_INSTALL_TREND',
}
