/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AssignRoleAccountsRequest
 * @export
 * @class AssignRoleAccountsRequest
 */
@Serializable
export class AssignRoleAccountsRequest {
  @JsonProperty('account_ids')
  public accountIds: string[] = undefined;

  /**
   * Initializes instance of AssignRoleAccountsRequest
   * @param {Array<Partial<AssignRoleAccountsRequest>>} args - object initialization arguments
   * @memberof AssignRoleAccountsRequest
   */
  constructor(...args: Array<Partial<AssignRoleAccountsRequest>>) {
    Object.assign(this, ...args);
  }
}
