/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * PendoWorkspaceOneCloudEvent
 * @export
 * @enum {string}
 */
export enum PendoWorkspaceOneCloudEvent {
  REQUEST_CSP_FEDERATION_ACCESS = 'REQUEST_CSP_FEDERATION_ACCESS',
}
