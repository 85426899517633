/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * AccessType
 * @export
 * @enum {string}
 */
export enum AccessType {
  READ = 'READ',
  FULL = 'FULL',
  OWNER = 'OWNER',
  MANAGE = 'MANAGE',
}
