/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { PagedRequest, SortOn } from '@dpa/ui-common';

/**
 * LocalDataGridSettings
 * Stores values used for sorting/filtering/paging locally
 * @export
 * @class LocalDataGridSettings
 */
export class LocalDataGridSettings {
  public filter?: string;
  public sortOns?: SortOn[];
  public pagedRequest?: PagedRequest;

  /**
   * constructor
   * @param {Array<Partial<LocalDataGridSettings>>} args
   * @memberof LocalDataGridSettings
   */
  constructor(...args: Array<Partial<LocalDataGridSettings>>) {
    Object.assign(this, ...args);
  }
}
