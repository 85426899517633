/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable, WebError } from '@dpa/ui-common';

import { LOAD_STATE } from './load-state.enum';

/**
 * WebRequest
 * @export
 * @class WebRequest
 */
@Serializable
export class WebRequest {
  @JsonProperty({ name: 'load_state', excludeToJson: true })
  public loadState?: LOAD_STATE = undefined;

  @JsonProperty({ name: 'web_error', excludeToJson: true })
  public webError?: WebError = undefined;
}
