/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * InsightOutlierType
 * @export
 * @enum {string}
 */
export enum InsightOutlierType {
  HIGH = 'HIGH',
  LOW = 'LOW',
}
