/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { TemplateRef } from '@angular/core';
import { DataDecorator, DateTimeFormat, MetricCardLegendStyle, MetricCardOrientation, Serializable } from '@dpa/ui-common';

/**
 * MetricTab
 * @export
 * @class MetricTab
 */
@Serializable
export class MetricTab {
  public value?: number = undefined;

  public formatType: DataDecorator = DataDecorator.DEFAULT;

  public dateTimeFormat: DateTimeFormat = undefined;

  public labelKey: string = undefined;

  public tooltipText: string = undefined;

  public legendStyle: MetricCardLegendStyle = undefined;

  public orientation: MetricCardOrientation = undefined;

  public iconColor: string = undefined;

  public sparkLineTemplate: TemplateRef<unknown> = undefined;

  /**
   * MetricTab
   * @param {Array<Partial<MetricTab>>} args
   * @memberof MetricTab
   */
  constructor(...args: Array<Partial<MetricTab>>) {
    Object.assign(this, ...args);
  }
}
