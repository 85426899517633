/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter } from '@dpa/ui-common';

import { QueryBuilder } from '@ws1c/intelligence-models/filter';

/**
 * filterConditionConverter
 * Work around nested complicated json structure
 */
export const filterConditionConverter: CustomConverter = {
  fromJson(data: object): object {
    return data;
  },
  toJson(): object {
    return undefined;
  },
};

/**
 * filterConditionToRuleGroupConverter
 * convert to ruleGroup
 */
export const filterConditionToRuleGroupConverter: CustomConverter = {
  fromJson(data: any): any {
    return QueryBuilder.parseRuleDefinitionTree(data);
  },
  toJson(): any {
    return undefined;
  },
};
