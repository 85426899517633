/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * NodeNamePrefixes
 * @export
 * @enum {string}
 */
export enum NodeNamePrefixes {
  CONDITION_NAME_PREFIX = 'Condition',
  GROUP_NAME_PREFIX = 'Group',
}
