/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { Account } from './account';
import { ServiceLimitsOverrideType } from './service-limits-override-type.enum';

/**
 * SystemLimitsAccountSummary
 * @export
 * @class SystemLimitsAccountSummary
 */
@Serializable
export class SystemLimitsAccountSummary {
  @JsonProperty({ name: 'account', cls: Account })
  public account: Account = undefined;

  @JsonProperty('count')
  public count: number = undefined;

  @JsonProperty('max')
  public max: number = undefined;

  @JsonProperty('override_type')
  public overrideType: ServiceLimitsOverrideType = undefined;

  /**
   * Creates an instance of SystemLimitsAccountSummary.
   * @param {Array<Partial<SystemLimitsAccountSummary>>} args
   * @memberof SystemLimitsAccountSummary
   */
  constructor(...args: Array<Partial<SystemLimitsAccountSummary>>) {
    Object.assign(this, ...args);
  }
}
