/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ALERT_BANNER_TYPE } from '@ws1c/intelligence-models/alert-banner';

/**
 * AutomationTestActionResult
 * @export
 * @class AutomationTestActionResult
 */
export class AutomationTestActionResult {
  public alert?: string = ALERT_BANNER_TYPE.SUCCESS;
  public success: boolean = true;
  public messages: string[] = [];
  public errorMessage?: string = '';
  public rawResponse?: any = {};
  public executionStatus?: string = '';

  /**
   * Creates an instance of AutomationTestActionResult.
   * @param {...Array<Partial<AutomationTestActionResult>>} args
   * @memberof AutomationTestActionResult
   */
  constructor(...args: Array<Partial<AutomationTestActionResult>>) {
    Object.assign(this, ...args);
  }
}
