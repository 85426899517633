/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * HandledExceptionAndroidCrumb
 * @export
 * @class HandledExceptionAndroidCrumb
 */
@Serializable
export class HandledExceptionAndroidCrumb {
  @JsonProperty('apteligent.handled_exception_android.event_timestamp')
  public eventTimestamp: number = undefined;

  @JsonProperty('apteligent.handled_exception_android._error_name')
  public errorName: string = undefined;

  @JsonProperty('apteligent.handled_exception_android.error_reason')
  public errorReason: string = undefined;

  /**
   * Creates an instance of HandledExceptionAndroidCrumb.
   * @param {...Array<Partial<HandledExceptionAndroidCrumb>>} args
   * @memberof HandledExceptionAndroidCrumb
   */
  constructor(...args: Array<Partial<HandledExceptionAndroidCrumb>>) {
    Object.assign(this, ...args);
  }
}
