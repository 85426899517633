/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * Return a unique id by joining param values and converting to UPPERCASE
 * Temporarily DUPLICATE for use by @ws1c/intelligence-models package
 *
 * @export
 * @param {string[]} params
 * @returns {string}
 */
export function getUniqueId(...params: string[]): string {
  return (params || []).join('_').toUpperCase();
}
