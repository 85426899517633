/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, DataUnit, JsonProperty, Serializable } from '@dpa/ui-common';
import { get, isEmpty } from 'lodash-es';

import { enumConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { Operator } from '@ws1c/intelligence-models/integration-meta/operator.model';
import { NameLabel } from '@ws1c/intelligence-models/name-label.model';
import { getUniqueId } from '@ws1c/intelligence-models/utils/get-unique-id';
import { Attribute, attributesConverter } from './attribute.model';
import { COLUMN_NAMES } from './column-names.constant';
import { DataType } from './data-type.model';

/**
 * classificationsConverter
 * classifications array will always contain one element.
 * Backend will continue to send it as an Array to maintain the existing contract
 * as per https://confluence-euc.eng.vmware.com/display/DHUB/API+Documentation+for+VMware+Workspace+ONE+Intelligence
 * classificationsConverter will convert the first/only element of the array to NameLabel object
 */
export const classificationsConverter: CustomConverter = {
  fromJson(classification: any): NameLabel {
    return !isEmpty(classification) ? new NameLabel(classification) : null;
  },
  toJson(classification: NameLabel): NameLabel[] {
    return [classification];
  },
};

/**
 * Column
 * @export
 * @class Column
 */
@Serializable
export class Column {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('label')
  public _label: string = undefined;

  @JsonProperty('name')
  public _name: string = undefined;

  @JsonProperty('description')
  public description: string = undefined;

  @JsonProperty('entity')
  public entity: string = undefined;

  @JsonProperty('integration')
  public integration: string = undefined;

  @JsonProperty('data_type')
  public dataType: string = undefined;

  @JsonProperty({ name: 'attribute', customConverter: attributesConverter })
  public attribute: Attribute = undefined;

  @JsonProperty('source_attribute')
  public sourceAttribute: string = undefined;

  @JsonProperty('path')
  public path: string = undefined;

  @JsonProperty({ name: 'supported_operators', cls: Operator })
  public supportedOperators: Operator[] = [];

  @JsonProperty('hidden_in_uiconditions')
  public isHiddenInConditions: boolean = false;

  @JsonProperty('hidden_in_uifilter')
  public isHiddenInFilter: boolean = false;

  @JsonProperty('hidden_in_uiselect')
  public isHiddenInColumnSelector: boolean = false;

  @JsonProperty('hidden_in_uigroupby')
  public isHiddenInGroupBy: boolean = false;

  // don't change the default value from undefined
  // required in ReportColumnMapper
  @JsonProperty('suggestion_supported')
  public suggestionSupported: boolean = undefined;

  @JsonProperty('groupable_by')
  public groupableBy: string = undefined;

  @JsonProperty({ name: 'classification', customConverter: classificationsConverter })
  public classification: NameLabel = undefined;

  @JsonProperty('sorting_supported')
  public sortingSupported: boolean = undefined;

  @JsonProperty('raw_attribute_name')
  public rawAttributeName: string = undefined;

  @JsonProperty({ name: 'data_unit', customConverter: enumConverterFactory(DataUnit) })
  public dataUnit: DataUnit = undefined;

  public styledString: string = undefined;
  public selected?: boolean = undefined;
  public className?: string = undefined;
  public sortByValue: string = undefined;
  public entityLabel: string = undefined;
  public error?: boolean = false;

  /**
   * Creates an instance of Column.
   * @param {Array<Partial<Column>>} args
   * @memberof Column
   */
  constructor(...args: Array<Partial<Column>>) {
    Object.assign(this, ...args);
  }

  /**
   * name
   * @type {string}
   * @memberof Column
   */
  public get name(): string {
    return this._name ?? get(this.attribute, 'name');
  }

  /**
   * name
   * @memberof Column
   */
  public set name(name: string) {
    this._name = name;
  }

  /**
   * columnName
   * @type {string}
   * @memberof Column
   */
  public get columnName(): string {
    return get(this.attribute, 'name', this.name);
  }

  /**
   * attributeName
   * @type {string}
   * @memberof Column
   */
  public get attributeName(): string {
    return get(this.attribute, 'fullyQualifiedName', this.name);
  }

  /**
   * attributeName - This is primarily used in specs to create attribute object by passing attributeName to constructor
   * @type {string}
   * @memberof Column
   */
  public set attributeName(name: string) {
    this.attribute = attributesConverter.fromJson(name);
  }

  /**
   * label
   * @type {string}
   * @memberof Column
   */
  public get label(): string {
    return this._label || this.columnName;
  }

  /**
   * label
   * @type {string}
   * @memberof Column
   */
  public set label(label: string) {
    this._label = label;
  }

  /**
   * isNormalized
   * @type {boolean}
   * @memberof Column
   */
  public get isNormalized(): boolean {
    return this.attribute && this.attribute.isNormalized;
  }

  /**
   * categoryId
   * @type {string}
   * @memberof Column
   */
  public get categoryId(): string {
    return getUniqueId(this.integration, this.entity);
  }

  /**
   * getColumnTooltip
   * @returns {string}
   * @memberof Column
   */
  public get tooltip(): string {
    const groupLabel = this.entityLabel ? this.entityLabel : this.classification && this.classification.label;
    return groupLabel ? `${groupLabel} > ${this.label}` : this.label;
  }

  /**
   * groupLabel
   * @type {string}
   * @memberof Column
   */
  public get groupLabel(): string {
    return this.entityLabel || this.classification?.label;
  }

  /**
   * isTreeField
   * @returns {boolean}
   * @memberof Column
   */
  public isTreeField(): boolean {
    return [
      COLUMN_NAMES.byName._airwatch_device__device_location_group_lineage_list,
      COLUMN_NAMES.byName.device_location_group_lineage_list,
      COLUMN_NAMES.byName.user_location_group_lineage_list,
    ].includes(this.columnName);
  }

  /**
   * isDateTimeField
   * @returns {boolean}
   * @memberof Column
   */
  public isDateTimeField(): boolean {
    return this.dataType === DataType[DataType.DATETIME];
  }
}

/**
 * ColumnIndex
 * @export
 * @interface ColumnIndex
 */
export interface ColumnIndex {
  [key: string]: Column;
}

/**
 * DataGridColumn - derived from Column for use with DataGrid
 * @export
 * @interface DataGridColumn
 */
export interface DataGridColumn {
  label: string;
  key: string;
  value: string;
  name: string;
  width: number;
  valueDecorator?: string;
  tooltip?: string;
  sortable?: boolean;
  className?: string;
  isUnsortable?: boolean;
  sortByValue?: string;
  isNormalized?: boolean;
  error?: boolean;
}
