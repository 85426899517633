/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { enumArrayConverterFactory } from '@ws1c/intelligence-models/converters/converter-factory';
import { GenericSearchRequest } from '@ws1c/intelligence-models/generic-search-request.model';
import { DeemPlaybookState } from './deem-playbook-state.enum';

/**
 * DeemPlaybookSearchRequest
 * @export
 * @class DeemPlaybookSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class DeemPlaybookSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'playbook_version_states', customConverter: enumArrayConverterFactory(DeemPlaybookState) })
  public playbookVersionStates: DeemPlaybookState[] = undefined;

  /**
   * Creates an instance of DeemPlaybookSearchRequest.
   * @param {any} args
   * @memberof DeemPlaybooksSearchRequest
   */
  constructor(...args: Array<Partial<DeemPlaybookSearchRequest>>) {
    super();
    Object.assign(this, ...args);
  }
}
