/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * DevicesDashboardType
 * @export
 * @enum {string}
 */
export enum DevicesDashboardType {
  // For Devices Dashboard
  DEVICES = 'DEVICES',
  DEVICES_OVERVIEW_MOBILE = 'DEVICES_OVERVIEW_MOBILE',
  DEVICES_OVERVIEW_DESKTOP = 'DEVICES_OVERVIEW_DESKTOP',
  DEVICES_PERFORMANCE_DESKTOP_BOOT_SHUTDOWN = 'DEVICES_PERFORMANCE_DESKTOP_BOOT_SHUTDOWN',
  DEVICES_PERFORMANCE_DESKTOP_SESSION = 'DEVICES_PERFORMANCE_DESKTOP_SESSION',
  DEVICES_PERFORMANCE_DESKTOP = 'DEVICES_PERFORMANCE_DESKTOP',
  DEVICES_ERRORS_DESKTOP = 'DEVICES_ERRORS_DESKTOP',
  DEVICES_DESKTOP_ERROR_DETAILS = 'DEVICES_DESKTOP_ERROR_DETAILS',
  DEVICES_DETAILS = 'DEVICES_DETAILS',
  DEVICES_PROFILE = 'DEVICES_PROFILE',
  DEVICES_PROFILE_ANDROID = 'DEVICES_PROFILE_ANDROID',
  DEVICES_PROFILE_IOS = 'DEVICES_PROFILE_IOS',
  DEVICES_PROFILE_APP_PERFORMANCE = 'DEVICES_PROFILE_APP_PERFORMANCE',
  DEVICES_PROFILE_DESTINATION_PERFORMANCE = 'DEVICES_PROFILE_DESTINATION_PERFORMANCE',
  DEVICES_PROFILE_MOBILE_BATTERY_PERFORMANCE = 'DEVICES_PROFILE_MOBILE_BATTERY_PERFORMANCE',
  DEVICES_PROFILE_MOBILE_MEMORY_PERFORMANCE = 'DEVICES_PROFILE_MOBILE_MEMORY_PERFORMANCE',
  DEVICES_PROFILE_MOBILE_STORAGE_PERFORMANCE = 'DEVICES_PROFILE_MOBILE_STORAGE_PERFORMANCE',
  DEVICES_PROFILE_MOBILE_NETWORK_PERFORMANCE = 'DEVICES_PROFILE_MOBILE_NETWORK_PERFORMANCE',
  DEVICES_PROFILE_MOBILE_NETWORK_BREAKDOWN_PERFORMANCE = 'DEVICES_PROFILE_MOBILE_NETWORK_BREAKDOWN_PERFORMANCE',
  DEVICES_PROFILE_BOOT_PERFORMANCE = 'DEVICES_PROFILE_BOOT_PERFORMANCE',
  DEVICES_PROFILE_DISK_PERFORMANCE = 'DEVICES_PROFILE_DISK_PERFORMANCE',
  DEVICES_PROFILE_ENERGY_PERFORMANCE = 'DEVICES_PROFILE_ENERGY_PERFORMANCE',
  DEVICES_PROFILE_MEMORY_PERFORMANCE = 'DEVICES_PROFILE_MEMORY_PERFORMANCE',
  DEVICES_PROFILE_NETWORK_PERFORMANCE = 'DEVICES_PROFILE_NETWORK_PERFORMANCE',
  DEVICES_PROFILE_URL_MONITORING_PERFORMANCE = 'DEVICES_PROFILE_URL_MONITORING_PERFORMANCE',
  DEVICES_PROFILE_OTHERS_PERFORMANCE = 'DEVICES_PROFILE_OTHERS_PERFORMANCE',
  DEVICES_PROFILE_PROCESSOR_PERFORMANCE = 'DEVICES_PROFILE_PROCESSOR_PERFORMANCE',
  DEVICES_PROFILE_EXPERIENCE_V2 = 'DEVICES_PROFILE_EXPERIENCE_V2',
  DEVICES_PROFILE_EXPERIENCE_APP_SCORE = 'DEVICES_PROFILE_EXPERIENCE_APP_SCORE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE',
  DEVICES_PROFILE_PROPERTIES = 'DEVICES_PROFILE_PROPERTIES',
  DEVICES_NETWORK_DESKTOP = 'DEVICES_NETWORK_DESKTOP',
  DEVICES_NETWORK_DESKTOP_SIGNAL = 'DEVICES_NETWORK_DESKTOP_SIGNAL',
  DEVICES_NETWORK_DESKTOP_SIGNAL_SSID = 'DEVICES_NETWORK_DESKTOP_SIGNAL_SSID',
  DEVICES_PROFILE_ZOOM_MEETINGS = 'DEVICES_PROFILE_ZOOM_MEETINGS',
  DEVICES_PROFILE_ZOOM_NETWORK = 'DEVICES_PROFILE_ZOOM_NETWORK',
  DEVICES_PROFILE_ZOOM_SUMMARY = 'DEVICES_PROFILE_ZOOM_SUMMARY',
}

/**
 * DevicesWidgetSubtype
 * @export
 * @enum {string}
 */
export enum DevicesWidgetSubtype {
  // DEVICES - overview
  __DEVICES_OVERVIEW_BATTERY_HEALTH_TREND = '__DEVICES_OVERVIEW_BATTERY_HEALTH_TREND',
  __DEVICES_OVERVIEW_CAPACITY_HEALTH_TREND = '__DEVICES_OVERVIEW_CAPACITY_HEALTH_TREND',
  __DEVICES_ENROLLMENT_TREND = '__DEVICES_ENROLLMENT_TREND',
  __DEVICES_TOTAL_ACTIVE_INACTIVE_COUNT_TREND = '__DEVICES_TOTAL_ACTIVE_INACTIVE_COUNT_TREND',
  DEVICES_TOTAL_ENROLLED_COUNT = 'DEVICES_TOTAL_ENROLLED_COUNT',
  DEVICES_TOTAL_ENROLLED_COUNT_OFFSET_1_TREND = 'DEVICES_TOTAL_ENROLLED_COUNT_OFFSET_1_TREND',
  DEVICES_TOTAL_ACTIVE_COUNT = 'DEVICES_TOTAL_ACTIVE_COUNT',
  DEVICES_TOTAL_INACTIVE_COUNT = 'DEVICES_TOTAL_INACTIVE_COUNT',
  DEVICES_TOTAL_ACTIVE_COUNT_TREND = 'DEVICES_TOTAL_ACTIVE_COUNT_TREND',
  DEVICES_TOTAL_INACTIVE_COUNT_TREND = 'DEVICES_TOTAL_INACTIVE_COUNT_TREND',
  DEVICES_TOTAL_CRASH_COUNT = 'DEVICES_TOTAL_CRASH_COUNT',
  DEVICES_TOTAL_CRASH_COUNT_OFFSET_1 = 'DEVICES_TOTAL_CRASH_COUNT_OFFSET_1',
  TOP_OS_CRASHES_BY_VERSION = 'TOP_OS_CRASHES_BY_VERSION',
  TOP_OS_CRASHES_BY_DEVICE_MODEL = 'TOP_OS_CRASHES_BY_DEVICE_MODEL',
  DEVICES_TOTAL_MOBILE_DEVICES_BY_PLATFORM = 'DEVICES_TOTAL_MOBILE_DEVICES_BY_PLATFORM',
  DEVICES_TOTAL_DESKTOP_DEVICES_BY_PLATFORM = 'DEVICES_TOTAL_DESKTOP_DEVICES_BY_PLATFORM',
  DEVICES_TOTAL_ACTIVE_COUNT_OFFSET_1_TREND = 'DEVICES_TOTAL_ACTIVE_COUNT_OFFSET_1_TREND',
  DEVICES_TOTAL_ACTIVE = 'DEVICES_TOTAL_ACTIVE',

  DEVICES_BY_OWNERSHIP = 'DEVICES_BY_OWNERSHIP',
  DEVICES_MODEL_BREAKDOWN = 'DEVICES_MODEL_BREAKDOWN',
  DEVICES_ENROLL_UNENROLL_OVER_TIME = 'DEVICES_ENROLL_UNENROLL_OVER_TIME',
  DEVICES_ENROLLMENT_HISTORICAL = 'DEVICES_ENROLLMENT_HISTORICAL',
  DEVICES_ENROLLMENT_BY_ORG_HISTORICAL = 'DEVICES_ENROLLMENT_BY_ORG_HISTORICAL',
  DEVICES_ENROLLMENT_HISTORICAL_CHANGE_EVENTS = 'DEVICES_ENROLLMENT_HISTORICAL_CHANGE_EVENTS',
  DEVICES_ENROLLMENT_ACTIVE_TREND = 'DEVICES_ENROLLMENT_ACTIVE_TREND',
  DEVICES_ENROLLMENT_INACTIVE_TREND = 'DEVICES_ENROLLMENT_INACTIVE_TREND',
  DEVICES_CAPACITY_WARNING_TREND = 'DEVICES_CAPACITY_WARNING_TREND',
  DEVICES_CAPACITY_CRITICAL_TREND = 'DEVICES_CAPACITY_CRITICAL_TREND',
  DEVICES_BATTERY_GOOD_TREND = 'DEVICES_BATTERY_GOOD_TREND',
  DEVICES_BATTERY_MEDIUM_TREND = 'DEVICES_BATTERY_MEDIUM_TREND',
  DEVICES_BATTERY_POOR_TREND = 'DEVICES_BATTERY_POOR_TREND',
  DEVICES_MAKER_BREAKDOWN = 'DEVICES_MAKER_BREAKDOWN',
  DEVICES_OS_VERSION = 'DEVICES_OS_VERSION',
  DEVICES_ACTIVE_OVER_TIME = 'DEVICES_ACTIVE_OVER_TIME',
  DEVICES_ENROLLMENT_BY_PLATFORM_COUNT = 'DEVICES_ENROLLMENT_BY_PLATFORM_COUNT',
  DEVICES_ENROLLMENT_BY_DEVICE_MODEL_COUNT = 'DEVICES_ENROLLMENT_BY_DEVICE_MODEL_COUNT',
  DEVICES_ENROLLMENT_BY_ORGANIZATION_GROUP_COUNT = 'DEVICES_ENROLLMENT_BY_ORGANIZATION_GROUP_COUNT',
  DEVICES_ENROLLMENT_BY_OWNERSHIP_COUNT = 'DEVICES_ENROLLMENT_BY_OWNERSHIP_COUNT',
  DEVICES_ENROLLMENT_BY_OS_VERSION_COUNT = 'DEVICES_ENROLLMENT_BY_OS_VERSION_COUNT',
  DEVICES_ENROLLMENT_ENROLL_TOTAL_COUNT = 'DEVICES_ENROLLMENT_ENROLL_TOTAL_COUNT',
  DEVICES_ENROLLMENT_ENROLL_TOTAL_COUNT_PREVIOUS_PERIOD = 'DEVICES_ENROLLMENT_ENROLL_TOTAL_COUNT_PREVIOUS_PERIOD',
  DEVICES_ENROLLMENT_UNENROLL_TOTAL_COUNT = 'DEVICES_ENROLLMENT_UNENROLL_TOTAL_COUNT',
  DEVICES_ENROLLMENT_UNENROLL_TOTAL_COUNT_PREVIOUS_PERIOD = 'DEVICES_ENROLLMENT_UNENROLL_TOTAL_COUNT_PREVIOUS_PERIOD',

  // DEVICES - performance
  DEVICES_PERFORMANCE_BOOT_TIME_AVERAGE = 'DEVICES_PERFORMANCE_BOOT_TIME_AVERAGE',
  DEVICES_PERFORMANCE_LOGIN_TIME_AVERAGE = 'DEVICES_PERFORMANCE_LOGIN_TIME_AVERAGE',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_AVERAGE = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_AVERAGE',
  DEVICES_PERFORMANCE_BOOT_TIME_MAX = 'DEVICES_PERFORMANCE_BOOT_TIME_MAX',
  DEVICES_PERFORMANCE_LOGIN_TIME_MAX = 'DEVICES_PERFORMANCE_LOGIN_TIME_MAX',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_MAX = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_MAX',
  DEVICES_PERFORMANCE_BOOT_TIME_WARNING = 'DEVICES_PERFORMANCE_BOOT_TIME_WARNING',
  DEVICES_PERFORMANCE_LOGIN_TIME_WARNING = 'DEVICES_PERFORMANCE_LOGIN_TIME_WARNING',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_WARNING = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_WARNING',
  DEVICES_PERFORMANCE_BOOT_TIME_CRITICAL = 'DEVICES_PERFORMANCE_BOOT_TIME_CRITICAL',
  DEVICES_PERFORMANCE_LOGIN_TIME_CRITICAL = 'DEVICES_PERFORMANCE_LOGIN_TIME_CRITICAL',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_CRITICAL = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_CRITICAL',
  DEVICES_PERFORMANCE_AVG_BOOT_BY_OS_VERSION = 'DEVICES_PERFORMANCE_AVG_BOOT_BY_OS_VERSION',
  DEVICES_PERFORMANCE_AVG_BOOT_BY_MODEL = 'DEVICES_PERFORMANCE_AVG_BOOT_BY_MODEL',
  DEVICES_PERFORMANCE_AVG_SHUTDOWN_BY_OS_VERSION = 'DEVICES_PERFORMANCE_AVG_SHUTDOWN_BY_OS_VERSION',
  DEVICES_PERFORMANCE_AVG_SHUTDOWN_BY_MODEL = 'DEVICES_PERFORMANCE_AVG_SHUTDOWN_BY_MODEL',
  DEVICES_BOOT_DEGRADATION = 'DEVICES_BOOT_DEGRADATION',
  DEVICES_SHUTDOWN_DEGRADATION = 'DEVICES_SHUTDOWN_DEGRADATION',
  __DEVICES_BOOT_TIME_BUBBLE_NO_ZEROS = '__DEVICES_BOOT_TIME_BUBBLE_NO_ZEROS',
  __DEVICES_LOGIN_TIME_BUBBLE_NO_ZEROS = '__DEVICES_LOGIN_TIME_BUBBLE_NO_ZEROS',
  __DEVICES_SHUTDOWN_TIME_BUBBLE_NO_ZEROS = '__DEVICES_SHUTDOWN_TIME_BUBBLE_NO_ZEROS',
  __DEVICES_BOOT_TIME_BUBBLE = '__DEVICES_BOOT_TIME_BUBBLE',
  __DEVICES_LOGIN_TIME_BUBBLE = '__DEVICES_LOGIN_TIME_BUBBLE',
  __DEVICES_SHUTDOWN_TIME_BUBBLE = '__DEVICES_SHUTDOWN_TIME_BUBBLE',
  __DEVICES_BOOT_DEGRADATION = '__DEVICES_BOOT_DEGRADATION',
  __DEVICES_SHUTDOWN_DEGRADATION = '__DEVICES_SHUTDOWN_DEGRADATION',

  DEVICES_PERFORMANCE_CPU_TEMPERATURE = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_STAT = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_STAT',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_OS_VERSION = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_OS_VERSION',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_PROCESSOR = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_PROCESSOR',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_MODEL = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_MODEL',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_ORG_GROUP = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_ORG_GROUP',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_DEVICES = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_DEVICES',
  DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_CPU_TEMPERATURE_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_CPU_TEMPERATURE_TABLE = '__DEVICES_PERFORMANCE_CPU_TEMPERATURE_TABLE',
  DEVICES_PERFORMANCE_CRASH = 'DEVICES_PERFORMANCE_CRASH',
  DEVICES_PERFORMANCE_CRASH_STAT = 'DEVICES_PERFORMANCE_CRASH_STAT',
  DEVICES_PERFORMANCE_CRASH_OS_VERSION = 'DEVICES_PERFORMANCE_CRASH_OS_VERSION',
  DEVICES_PERFORMANCE_CRASH_PROCESSOR = 'DEVICES_PERFORMANCE_CRASH_PROCESSOR',
  DEVICES_PERFORMANCE_CRASH_MODEL = 'DEVICES_PERFORMANCE_CRASH_MODEL',
  DEVICES_PERFORMANCE_CRASH_ORG_GROUP = 'DEVICES_PERFORMANCE_CRASH_ORG_GROUP',
  DEVICES_PERFORMANCE_CRASH_DEVICES = 'DEVICES_PERFORMANCE_CRASH_DEVICES',
  DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_CRASH_COUNT_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_CRASH_COUNT_TABLE = '__DEVICES_PERFORMANCE_CRASH_COUNT_TABLE',
  DEVICES_PERFORMANCE_CPU = 'DEVICES_PERFORMANCE_CPU',
  DEVICES_PERFORMANCE_CPU_STAT = 'DEVICES_PERFORMANCE_CPU_STAT',
  DEVICES_PERFORMANCE_CPU_OS_VERSION = 'DEVICES_PERFORMANCE_CPU_OS_VERSION',
  DEVICES_PERFORMANCE_CPU_PROCESSOR = 'DEVICES_PERFORMANCE_CPU_PROCESSOR',
  DEVICES_PERFORMANCE_CPU_MODEL = 'DEVICES_PERFORMANCE_CPU_MODEL',
  DEVICES_PERFORMANCE_CPU_ORG_GROUP = 'DEVICES_PERFORMANCE_CPU_ORG_GROUP',
  DEVICES_PERFORMANCE_CPU_DEVICES = 'DEVICES_PERFORMANCE_CPU_DEVICES',
  DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_CPU_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_CPU_TABLE = '__DEVICES_PERFORMANCE_CPU_TABLE',
  DEVICES_PERFORMANCE_GPU = 'DEVICES_PERFORMANCE_GPU',
  DEVICES_PERFORMANCE_GPU_STAT = 'DEVICES_PERFORMANCE_GPU_STAT',
  DEVICES_PERFORMANCE_GPU_OS_VERSION = 'DEVICES_PERFORMANCE_GPU_OS_VERSION',
  DEVICES_PERFORMANCE_GPU_PROCESSOR = 'DEVICES_PERFORMANCE_GPU_PROCESSOR',
  DEVICES_PERFORMANCE_GPU_MODEL = 'DEVICES_PERFORMANCE_GPU_MODEL',
  DEVICES_PERFORMANCE_GPU_ORG_GROUP = 'DEVICES_PERFORMANCE_GPU_ORG_GROUP',
  DEVICES_PERFORMANCE_GPU_DEVICES = 'DEVICES_PERFORMANCE_GPU_DEVICES',
  DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_GPU_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_GPU_TABLE = '__DEVICES_PERFORMANCE_GPU_TABLE',
  DEVICES_PERFORMANCE_MEMORY = 'DEVICES_PERFORMANCE_MEMORY',
  DEVICES_PERFORMANCE_MEMORY_STAT = 'DEVICES_PERFORMANCE_MEMORY_STAT',
  DEVICES_PERFORMANCE_MEMORY_OS_VERSION = 'DEVICES_PERFORMANCE_MEMORY_OS_VERSION',
  DEVICES_PERFORMANCE_MEMORY_PROCESSOR = 'DEVICES_PERFORMANCE_MEMORY_PROCESSOR',
  DEVICES_PERFORMANCE_MEMORY_MODEL = 'DEVICES_PERFORMANCE_MEMORY_MODEL',
  DEVICES_PERFORMANCE_MEMORY_ORG_GROUP = 'DEVICES_PERFORMANCE_MEMORY_ORG_GROUP',
  DEVICES_PERFORMANCE_MEMORY_DEVICES = 'DEVICES_PERFORMANCE_MEMORY_DEVICES',
  DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_MEMORY_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_MEMORY_TABLE = '__DEVICES_PERFORMANCE_MEMORY_TABLE',
  DEVICES_PERFORMANCE_BATTERY = 'DEVICES_PERFORMANCE_BATTERY',
  DEVICES_PERFORMANCE_BATTERY_STAT = 'DEVICES_PERFORMANCE_BATTERY_STAT',
  DEVICES_PERFORMANCE_BATTERY_OS_VERSION = 'DEVICES_PERFORMANCE_BATTERY_OS_VERSION',
  DEVICES_PERFORMANCE_BATTERY_PROCESSOR = 'DEVICES_PERFORMANCE_BATTERY_PROCESSOR',
  DEVICES_PERFORMANCE_BATTERY_MODEL = 'DEVICES_PERFORMANCE_BATTERY_MODEL',
  DEVICES_PERFORMANCE_BATTERY_ORG_GROUP = 'DEVICES_PERFORMANCE_BATTERY_ORG_GROUP',
  DEVICES_PERFORMANCE_BATTERY_DEVICES = 'DEVICES_PERFORMANCE_BATTERY_DEVICES',
  DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_BATTERY_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_BATTERY_TABLE = '__DEVICES_PERFORMANCE_BATTERY_TABLE',
  DEVICES_PERFORMANCE_BOOT_TIME = 'DEVICES_PERFORMANCE_BOOT_TIME',
  DEVICES_PERFORMANCE_BOOT_TIME_DEGRADATION = 'DEVICES_PERFORMANCE_BOOT_TIME_DEGRADATION',
  DEVICES_PERFORMANCE_BOOT_TIME_STAT = 'DEVICES_PERFORMANCE_BOOT_TIME_STAT',
  DEVICES_PERFORMANCE_BOOT_TIME_OS_VERSION = 'DEVICES_PERFORMANCE_BOOT_TIME_OS_VERSION',
  DEVICES_PERFORMANCE_BOOT_TIME_PROCESSOR = 'DEVICES_PERFORMANCE_BOOT_TIME_PROCESSOR',
  DEVICES_PERFORMANCE_BOOT_TIME_MODEL = 'DEVICES_PERFORMANCE_BOOT_TIME_MODEL',
  DEVICES_PERFORMANCE_BOOT_TIME_ORG_GROUP = 'DEVICES_PERFORMANCE_BOOT_TIME_ORG_GROUP',
  DEVICES_PERFORMANCE_BOOT_TIME_DEVICES = 'DEVICES_PERFORMANCE_BOOT_TIME_DEVICES',
  DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_BOOT_TIME_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_BOOT_TIME_TABLE = '__DEVICES_PERFORMANCE_BOOT_TIME_TABLE',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_STAT = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_STAT',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_OS_VERSION = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_OS_VERSION',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_PROCESSOR = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_PROCESSOR',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_MODEL = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_MODEL',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_ORG_GROUP = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_ORG_GROUP',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_DEVICES = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_DEVICES',
  DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE = 'DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE = '__DEVICES_PERFORMANCE_SHUTDOWN_TIME_SESSION_BY_DEVICE',
  __DEVICES_PERFORMANCE_SHUTDOWN_TIME_TABLE = '__DEVICES_PERFORMANCE_SHUTDOWN_TIME_TABLE',
  DEVICES_PERFORMANCE_TOTAL_DEVICES = 'DEVICES_PERFORMANCE_TOTAL_DEVICES',

  // DEVICES - errors
  LIST_OF_OS_CRASHES_FOR_WINDOWS = 'LIST_OF_OS_CRASHES_FOR_WINDOWS',
  LIST_OF_OS_CRASHES_FOR_MACOS = 'LIST_OF_OS_CRASHES_FOR_MACOS',
  DEVICES_ERRORS_DESKTOP = 'DEVICES_ERRORS_DESKTOP',
  DEVICES_ERRORS_DESKTOP_COUNT = 'DEVICES_ERRORS_DESKTOP_COUNT',
  DEVICES_ERRORS_DESKTOP_COUNT_PREVIOUS_PERIOD = 'DEVICES_ERRORS_DESKTOP_COUNT_PREVIOUS_PERIOD',
  DEVICES_BOOT_DESKTOP_COUNT = 'DEVICES_BOOT_DESKTOP_COUNT',
  DEVICES_BOOT_DESKTOP_COUNT_PREVIOUS_PERIOD = 'DEVICES_BOOT_DESKTOP_COUNT_PREVIOUS_PERIOD',
  DEVICES_SEND_CRASH_DETAILS_DESKTOP_COUNT = 'DEVICES_SEND_CRASH_DETAILS_DESKTOP_COUNT',
  __DEVICES_ERRORS_DESKTOP_RATIO = '__DEVICES_ERRORS_DESKTOP_RATIO',
  __DEVICES_ERRORS_DESKTOP_RATIO_PREVIOUS_PERIOD = '__DEVICES_ERRORS_DESKTOP_RATIO_PREVIOUS_PERIOD',

  DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES = 'DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES',
  DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_SUMMARY = 'DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_SUMMARY',
  DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_BY_DEVICE_MAKE = 'DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_BY_DEVICE_MAKE',
  DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_BY_DEVICE_MODEL = 'DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_BY_DEVICE_MODEL',
  DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_BY_OS_VERSION = 'DEVICES_DESKTOP_ERROR_DETAILS_OCCURENCES_BY_OS_VERSION',
  DEVICES_DESKTOP_ERROR_DETAILS_USERS_LIST = 'DEVICES_DESKTOP_ERROR_DETAILS_USERS_LIST',

  // DEVICES - network
  DEVICES_NETWORK_DESKTOP_WIFI = 'DEVICES_NETWORK_DESKTOP_WIFI',
  DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL = 'DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL',
  DEVICES_NETWORK_DESKTOP_UPLOAD = 'DEVICES_NETWORK_DESKTOP_UPLOAD',
  DEVICES_NETWORK_DESKTOP_DOWNLOAD = 'DEVICES_NETWORK_DESKTOP_DOWNLOAD',
  DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL = 'DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL',
  DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL = 'DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL',
  DEVICES_NETWORK_DESKTOP_SSID = 'DEVICES_NETWORK_DESKTOP_SSID',
  DEVICES_NETWORK_DESKTOP_PROTOCOL = 'DEVICES_NETWORK_DESKTOP_PROTOCOL',
  DEVICES_NETWORK_DESKTOP_FREQUENCY = 'DEVICES_NETWORK_DESKTOP_FREQUENCY',
  DEVICES_NETWORK_DESKTOP_OS_VERSION = 'DEVICES_NETWORK_DESKTOP_OS_VERSION',
  DEVICES_NETWORK_DESKTOP_DRIVER_ISSUES = 'DEVICES_NETWORK_DESKTOP_DRIVER_ISSUES',
  DEVICES_NETWORK_DESKTOP_MODEL = 'DEVICES_NETWORK_DESKTOP_MODEL',
  DEVICES_NETWORK_DESKTOP_DEVICES_BY_SSID = 'DEVICES_NETWORK_DESKTOP_DEVICES_BY_SSID',
  __DEVICES_NETWORK_DESKTOP_WIFI = '__DEVICES_NETWORK_DESKTOP_WIFI',
  __DEVICES_NETWORK_DESKTOP_UPLOAD = '__DEVICES_NETWORK_DESKTOP_UPLOAD',
  __DEVICES_NETWORK_DESKTOP_DOWNLOAD = '__DEVICES_NETWORK_DESKTOP_DOWNLOAD',
  __DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL = '__DEVICES_NETWORK_DESKTOP_WIFI_SIGNAL',
  __DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL = '__DEVICES_NETWORK_DESKTOP_DOWNLOAD_SIGNAL',
  __DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL = '__DEVICES_NETWORK_DESKTOP_UPLOAD_SIGNAL',
  __DEVICES_NETWORK_DESKTOP_PROTOCOL = '__DEVICES_NETWORK_DESKTOP_PROTOCOL',
  __DEVICES_NETWORK_DESKTOP_FREQUENCY = '__DEVICES_NETWORK_DESKTOP_FREQUENCY',
  __DEVICES_NETWORK_DESKTOP_OS_VERSION = '__DEVICES_NETWORK_DESKTOP_OS_VERSION',
  __DEVICES_NETWORK_DESKTOP_DRIVER_ISSUES = '__DEVICES_NETWORK_DESKTOP_DRIVER_ISSUES',
  __DEVICES_NETWORK_DESKTOP_MODEL = '__DEVICES_NETWORK_DESKTOP_MODEL',

  // DEVICES - experience
  DEVICES_DETAILS_APP_CRASH_COUNT = 'DEVICES_DETAILS_APP_CRASH_COUNT',
  DEVICES_DETAILS_OS_CRASH_COUNT = 'DEVICES_DETAILS_OS_CRASH_COUNT',
  DEVICES_DETAILS_HEALTH_OVER_TIME = 'DEVICES_DETAILS_HEALTH_OVER_TIME',
  DEVICES_DETAILS_RISK_OVER_TIME = 'DEVICES_DETAILS_RISK_OVER_TIME',
  DEVICES_DETAILS_COMPROMISED = 'DEVICES_DETAILS_COMPROMISED',
  DEVICES_DETAILS_ENROLLMENT_DATE = 'DEVICES_DETAILS_ENROLLMENT_DATE',
  DEVICES_DETAILS_BITLOCKER = 'DEVICES_DETAILS_BITLOCKER',
  DEVICES_DETAILS_BOOT_EVENT = 'DEVICES_DETAILS_BOOT_EVENT',
  DEVICES_DETAILS_USER_LOGON = 'DEVICES_DETAILS_USER_LOGON',
  DEVICES_DETAILS_NATIVE_APPS_INSTALLED = 'DEVICES_DETAILS_NATIVE_APPS_INSTALLED',
  DEVICES_DETAILS_DEVICE_TIMELINE = 'DEVICES_DETAILS_DEVICE_TIMELINE',
  DEVICES_DETAILS_LAST_SEEN = 'DEVICES_DETAILS_LAST_SEEN',
  __DEVICES_DETAILS_NATIVE_APPS_INSTALLED = '__DEVICES_DETAILS_NATIVE_APPS_INSTALLED',

  // DEVICES - profile
  DEVICES_PROFILE_LAST_SEEN = 'DEVICES_PROFILE_LAST_SEEN',
  DEVICES_PROFILE_LATEST_OS = 'DEVICES_PROFILE_LATEST_OS',
  DEVICES_PROFILE_COMPROMISED = 'DEVICES_PROFILE_COMPROMISED',
  DEVICES_PROFILE_ENROLLMENT_DATE = 'DEVICES_PROFILE_ENROLLMENT_DATE',
  DEVICES_PROFILE_BOOT_EVENT = 'DEVICES_PROFILE_BOOT_EVENT',
  DEVICES_PROFILE_LOGON_USER = 'DEVICES_PROFILE_LOGON_USER',
  DEVICES_PROFILE_WINDOWS_OS_UPDATE = 'DEVICES_PROFILE_WINDOWS_OS_UPDATE',
  DEVICES_PROFILE_WINDOWS_OS_PENDING = 'DEVICES_PROFILE_WINDOWS_OS_PENDING',
  DEVICES_PROFILE_LOGICAL_SPACE = 'DEVICES_PROFILE_LOGICAL_SPACE',
  DEVICES_PROFILE_USER_LOGON = 'DEVICES_PROFILE_USER_LOGON',
  DEVICES_PROFILE_LOGIN_HISTORY = 'DEVICES_PROFILE_LOGIN_HISTORY',
  __DEVICES_PROFILE_LOGIN_HISTORY = '__DEVICES_PROFILE_LOGIN_HISTORY',
  DEVICES_PROFILE_UEM_DEVICE_ID = 'DEVICES_PROFILE_UEM_DEVICE_ID',
  DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED = 'DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED',
  DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_CRASH_COUNT = 'DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_CRASH_COUNT',
  DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_HANG_COUNT = 'DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_HANG_COUNT',
  __DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_COUNT_GROUPS = '__DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_COUNT_GROUPS',
  DEVICES_PROFILE_EXPERIENCE_APP_SCORE = 'DEVICES_PROFILE_EXPERIENCE_APP_SCORE',
  __DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_COUNT_GROUPS_BY_SCORE = '__DEVICES_PROFILE_EXPERIENCE_MOST_APPS_USED_COUNT_GROUPS_BY_SCORE',
  __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS = '__DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS',
  __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM = '__DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM',
  __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_IOS = '__DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_IOS',
  __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_IOS_ZOOM = '__DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_IOS_ZOOM',
  __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ANDROID = '__DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ANDROID',
  __DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ANDROID_ZOOM = '__DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ANDROID_ZOOM',
  DEVICES_PROFILE_ZOOM_AVG_BITRATE_INPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_BITRATE_INPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_BITRATE_OUTPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_BITRATE_OUTPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_JITTER_INPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_JITTER_INPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_JITTER_OUTPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_JITTER_OUTPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_LATENCY_INPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_LATENCY_INPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_LATENCY_OUTPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_LATENCY_OUTPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_PACKET_LOSS_INPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_PACKET_LOSS_INPUT_CHART',
  DEVICES_PROFILE_ZOOM_AVG_PACKET_LOSS_OUTPUT_CHART = 'DEVICES_PROFILE_ZOOM_AVG_PACKET_LOSS_OUTPUT_CHART',
  DEVICES_PROFILE_ZOOM_MAX_PACKET_LOSS_INPUT_CHART = 'DEVICES_PROFILE_ZOOM_MAX_PACKET_LOSS_INPUT_CHART',
  DEVICES_PROFILE_ZOOM_MAX_PACKET_LOSS_OUTPUT_CHART = 'DEVICES_PROFILE_ZOOM_MAX_PACKET_LOSS_OUTPUT_CHART',
  DEVICES_PROFILE_ZOOM_MEETING_TIMES = 'DEVICES_PROFILE_ZOOM_MEETING_TIMES',
  DEVICES_PROFILE_ZOOM_CONTRIBUTOR_SCORES = 'DEVICES_PROFILE_ZOOM_CONTRIBUTOR_SCORES',
  DEVICES_PROFILE_ZOOM_MEETINGS = 'DEVICES_PROFILE_ZOOM_MEETINGS',

  // Device events for mobile
  _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW = '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW_SUCCESS = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW_SUCCESS',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW_FAILED = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_FLOW_FAILED',
  _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS = '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_CRASH = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_CRASH',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_LOAD = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_LOAD',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_INSTALLS = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_INSTALLS',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_HANDLED_EXCEPTIONS = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APPS_HANDLED_EXCEPTIONS',
  _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK = '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK_ERRORS = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK_ERRORS',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK_EVENTS = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK_EVENTS',
  _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS = '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS_FAILED = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS_FAILED',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS_APP_LAUNCH = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_LOGINS_APP_LAUNCH',
  _DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL = '_DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_TUNNEL',

  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APP = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_APP',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_DEVICE = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_DEVICE',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_NETWORK',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_OS_UPDATES = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_OS_UPDATES',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_ACTIONS = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_USER_ACTIONS',
  DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM = 'DEVICES_PROFILE_EXPERIENCE_DEVICE_EVENTS_ZOOM',
  DEVICES_PROFILE_DEVICE_PERFORMANCE = 'DEVICES_PROFILE_DEVICE_PERFORMANCE',
  DEVICES_PROFILE_DEVICE_PERFORMANCE_BATTERY_LEVEL = 'DEVICES_PROFILE_DEVICE_PERFORMANCE_BATTERY_LEVEL',
  DEVICES_PROFILE_DEVICE_PERFORMANCE_BATTERY_VOLTAGE = 'DEVICES_PROFILE_DEVICE_PERFORMANCE_BATTERY_VOLTAGE',
  DEVICES_PROFILE_DEVICE_PERFORMANCE_BATTERY_TEMPERATURE = 'DEVICES_PROFILE_DEVICE_PERFORMANCE_BATTERY_TEMPERATURE',
  DEVICES_PROFILE_DEVICE_PERFORMANCE_DEVICE_CPU = 'DEVICES_PROFILE_DEVICE_PERFORMANCE_DEVICE_CPU',
  DEVICES_PROFILE_DEVICE_PERFORMANCE_NETWORK_LATENCY = 'DEVICES_PROFILE_DEVICE_PERFORMANCE_NETWORK_LATENCY',
  DEVICES_PROFILE_DEVICE_PERFORMANCE_NETWORK_JITTER = 'DEVICES_PROFILE_DEVICE_PERFORMANCE_NETWORK_JITTER',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_CPU_USAGE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_CPU_USAGE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_CPU_TEMPERATURE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_CPU_TEMPERATURE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_MEMORY_USAGE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_MEMORY_USAGE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_AVG = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_AVG',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_NAMES = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_NAMES',
  __DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_USAGE = '__DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_USAGE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_0_USAGE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_0_USAGE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_1_USAGE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_1_USAGE',
  DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_2_USAGE = 'DEVICES_PROFILE_EXPERIENCE_PERFORMANCE_GPU_2_USAGE',

  // Device Timeline V2
  DEVICES_PROFILE_TIMELINE_DEVICE_APPS = 'DEVICES_PROFILE_TIMELINE_DEVICE_APPS',
  DEVICES_PROFILE_TIMELINE_DEVICE_APP_COMPARE = 'DEVICES_PROFILE_TIMELINE_DEVICE_APP_COMPARE',
  DEVICES_PROFILE_TIMELINE_DEVICE_APP_SUMMARY = 'DEVICES_PROFILE_TIMELINE_DEVICE_APP_SUMMARY',
  DEVICES_PROFILE_TIMELINE_DEVICE_SUMMARY = 'DEVICES_PROFILE_TIMELINE_DEVICE_SUMMARY',
  DEVICES_PROFILE_TIMELINE_DEVICE_SUMMARY_BATTERY = 'DEVICES_PROFILE_TIMELINE_DEVICE_SUMMARY_BATTERY',
  DEVICES_PROFILE_TIMELINE_DEVICE_SUMMARY_NETWORK = 'DEVICES_PROFILE_TIMELINE_DEVICE_SUMMARY_NETWORK',
  DEVICES_PROFILE_TIMELINE_DEVICE_DESTINATION_SUMMARY = 'DEVICES_PROFILE_TIMELINE_DEVICE_DESTINATION_SUMMARY',
  DEVICES_PROFILE_TIMELINE_DEVICE_NETWORK_BANNER_SUMMARY = 'DEVICES_PROFILE_TIMELINE_DEVICE_NETWORK_BANNER_SUMMARY',
  DEVICES_PROFILE_TIMELINE_DEVICE_NETWORK_SUMMARY_ETHERNET = 'DEVICES_PROFILE_TIMELINE_DEVICE_NETWORK_SUMMARY_ETHERNET',
  DEVICES_PROFILE_TIMELINE_DEVICE_NETWORK_SUMMARY_WIRELESS = 'DEVICES_PROFILE_TIMELINE_DEVICE_NETWORK_SUMMARY_WIRELESS',
  DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY = 'DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY',
  __DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY = '__DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUMMARY',
  // eslint-disable-next-line max-len
  DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUCCESSES_COUNT = 'DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_SUCCESSES_COUNT',
  // eslint-disable-next-line max-len
  DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_FAILURES_COUNT = 'DEVICES_PROFILE_TIMELINE_DEVICE_SYNTHETIC_URL_MONITORING_FAILURES_COUNT',
  DEVICES_PROFILE_TIMELINE_DEVICE_SCORE = 'DEVICES_PROFILE_TIMELINE_DEVICE_SCORE',
  __DEVICES_PROFILE_TIMELINE_EVENTS = '__DEVICES_PROFILE_TIMELINE_EVENTS',
  __DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM = '__DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM',

  // Device Timeline for Mobile - Android & Apple iOS
  __DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID = '__DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID',
  __DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID_ZOOM = '__DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID_ZOOM',
  __DEVICES_PROFILE_TIMELINE_EVENTS_IOS = '__DEVICES_PROFILE_TIMELINE_EVENTS_IOS',
  __DEVICES_PROFILE_TIMELINE_EVENTS_IOS_ZOOM = '__DEVICES_PROFILE_TIMELINE_EVENTS_IOS_ZOOM',
  _DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID = '_DEVICES_PROFILE_TIMELINE_EVENTS_ANDROID',
  _DEVICES_PROFILE_TIMELINE_EVENTS_IOS = '_DEVICES_PROFILE_TIMELINE_EVENTS_IOS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW = '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW',
  _DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_SUCCESS = '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_SUCCESS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_FAILED = '_DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_FAILED',
  DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_SUCCESS = 'DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_SUCCESS',
  DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_FAILED = 'DEVICES_PROFILE_TIMELINE_EVENTS_USER_FLOW_FAILED',
  _DEVICES_PROFILE_TIMELINE_EVENTS_APPS = '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_APPS_CRASH = '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS_CRASH',
  _DEVICES_PROFILE_TIMELINE_EVENTS_APPS_LOAD = '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS_LOAD',
  _DEVICES_PROFILE_TIMELINE_EVENTS_APPS_INSTALLS = '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS_INSTALLS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_APPS_HANDLED_EXCEPTIONS = '_DEVICES_PROFILE_TIMELINE_EVENTS_APPS_HANDLED_EXCEPTIONS',
  DEVICES_PROFILE_TIMELINE_EVENTS_APPS_CRASH = 'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_CRASH',
  DEVICES_PROFILE_TIMELINE_EVENTS_APPS_LOAD = 'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_LOAD',
  DEVICES_PROFILE_TIMELINE_EVENTS_APPS_INSTALLS = 'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_INSTALLS',
  DEVICES_PROFILE_TIMELINE_EVENTS_APPS_HANDLED_EXCEPTIONS = 'DEVICES_PROFILE_TIMELINE_EVENTS_APPS_HANDLED_EXCEPTIONS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK = '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
  _DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_ERRORS = '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_ERRORS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_EVENTS = '_DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_EVENTS',
  DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_ERRORS = 'DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_ERRORS',
  DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_EVENTS = 'DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK_EVENTS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS = '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS',
  _DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_FAILED = '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_FAILED',
  _DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_APP_LAUNCH = '_DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_APP_LAUNCH',
  DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_FAILED = 'DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_FAILED',
  DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_APP_LAUNCH = 'DEVICES_PROFILE_TIMELINE_EVENTS_LOGINS_APP_LAUNCH',
  _DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL = '_DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL',
  DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL = 'DEVICES_PROFILE_TIMELINE_EVENTS_TUNNEL',

  // Device Timeline for Desktop - Windows Desktop & Apple macOS
  DEVICES_PROFILE_TIMELINE_EVENTS_APPS = 'DEVICES_PROFILE_TIMELINE_EVENTS_APPS',
  DEVICES_PROFILE_TIMELINE_EVENTS_DEVICES = 'DEVICES_PROFILE_TIMELINE_EVENTS_DEVICES',
  DEVICES_PROFILE_TIMELINE_EVENTS_DISPLAY = 'DEVICES_PROFILE_TIMELINE_EVENTS_DISPLAY',
  DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK = 'DEVICES_PROFILE_TIMELINE_EVENTS_NETWORK',
  DEVICES_PROFILE_TIMELINE_EVENTS_OS_UPDATES = 'DEVICES_PROFILE_TIMELINE_EVENTS_OS_UPDATES',
  DEVICES_PROFILE_TIMELINE_EVENTS_SERVICES = 'DEVICES_PROFILE_TIMELINE_EVENTS_SERVICES',
  DEVICES_PROFILE_TIMELINE_EVENTS_USER_ACTIONS = 'DEVICES_PROFILE_TIMELINE_EVENTS_USER_ACTIONS',
  DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM = 'DEVICES_PROFILE_TIMELINE_EVENTS_ZOOM',
  // Device Timeline V2 - Experience
  DEVICES_PROFILE_EXPERIENCE_CRASH_SCORE = 'DEVICES_PROFILE_EXPERIENCE_CRASH_SCORE',
  DEVICES_PROFILE_EXPERIENCE_BOOT_SCORE = 'DEVICES_PROFILE_EXPERIENCE_BOOT_SCORE',
  DEVICES_PROFILE_EXPERIENCE_SHUTDOWN_SCORE = 'DEVICES_PROFILE_EXPERIENCE_SHUTDOWN_SCORE',
  DEVICES_PROFILE_EXPERIENCE_BATTERY_SCORE = 'DEVICES_PROFILE_EXPERIENCE_BATTERY_SCORE',
  DEVICES_PROFILE_EXPERIENCE_CPU_SCORE = 'DEVICES_PROFILE_EXPERIENCE_CPU_SCORE',
  DEVICES_PROFILE_EXPERIENCE_GPU_SCORE = 'DEVICES_PROFILE_EXPERIENCE_GPU_SCORE',
  DEVICES_PROFILE_EXPERIENCE_MEMORY_SCORE = 'DEVICES_PROFILE_EXPERIENCE_MEMORY_SCORE',
  DEVICES_PROFILE_EXPERIENCE_WIFI_SCORE = 'DEVICES_PROFILE_EXPERIENCE_WIFI_SCORE',
  DEVICES_PROFILE_EXPERIENCE_DOWNLOAD_SCORE = 'DEVICES_PROFILE_EXPERIENCE_DOWNLOAD_SCORE',
  DEVICES_PROFILE_EXPERIENCE_UPLOAD_SCORE = 'DEVICES_PROFILE_EXPERIENCE_UPLOAD_SCORE',
  // Device Timeline V2 - Properties
  DEVICES_PROFILE_AUDIO_VIDEO = 'DEVICES_PROFILE_AUDIO_VIDEO',
  DEVICES_PROFILE_PROPERTIES_NIC = 'DEVICES_PROFILE_PROPERTIES_NIC',
  DEVICES_PROFILE_PROPERTIES_NETWORK = 'DEVICES_PROFILE_PROPERTIES_NETWORK',
  DEVICES_PROFILE_PROPERTIES_HARDWARE = 'DEVICES_PROFILE_PROPERTIES_HARDWARE',
  // Device Timeline V2 - Summary
  __DEVICES_PROFILE_APP_SYSTEM = '__DEVICES_PROFILE_APP_SYSTEM',
  DEVICES_PROFILE_APP_SYSTEM_CPU = 'DEVICES_PROFILE_APP_SYSTEM_CPU',
  DEVICES_PROFILE_APP_SYSTEM_GPU_0 = 'DEVICES_PROFILE_APP_SYSTEM_GPU_0',
  DEVICES_PROFILE_APP_SYSTEM_GPU_1 = 'DEVICES_PROFILE_APP_SYSTEM_GPU_1',
  DEVICES_PROFILE_APP_SYSTEM_GPU_2 = 'DEVICES_PROFILE_APP_SYSTEM_GPU_2',
  DEVICES_PROFILE_APP_SYSTEM_MEMORY = 'DEVICES_PROFILE_APP_SYSTEM_MEMORY',
  __DEVICES_PROFILE_APP_NETWORK = '__DEVICES_PROFILE_APP_NETWORK',
  DEVICES_PROFILE_APP_NETWORK = 'DEVICES_PROFILE_APP_NETWORK',
  DEVICES_PROFILE_APP_DISK = 'DEVICES_PROFILE_APP_DISK',
  __DEVICES_PROFILE_DESTINATION_BY_URL = '__DEVICES_PROFILE_DESTINATION_BY_URL',
  DEVICES_PROFILE_DESTINATION_BY_URL_TOTAL = 'DEVICES_PROFILE_DESTINATION_BY_URL_TOTAL',
  DEVICES_PROFILE_DESTINATION_BY_URL_REDIRECT = 'DEVICES_PROFILE_DESTINATION_BY_URL_REDIRECT',
  DEVICES_PROFILE_DESTINATION_BY_URL_DNS = 'DEVICES_PROFILE_DESTINATION_BY_URL_DNS',
  DEVICES_PROFILE_DESTINATION_BY_URL_CONNECTION = 'DEVICES_PROFILE_DESTINATION_BY_URL_CONNECTION',
  DEVICES_PROFILE_DESTINATION_BY_URL_TLS = 'DEVICES_PROFILE_DESTINATION_BY_URL_TLS',
  DEVICES_PROFILE_DESTINATION_BY_URL_REQUEST = 'DEVICES_PROFILE_DESTINATION_BY_URL_REQUEST',
  DEVICES_PROFILE_DESTINATION_BY_URL_RESPONSE = 'DEVICES_PROFILE_DESTINATION_BY_URL_RESPONSE',
  DEVICES_PROFILE_DESTINATION_BY_URL_RENDER = 'DEVICES_PROFILE_DESTINATION_BY_URL_RENDER',
  DEVICES_PROFILE_NETWORK_SIGNAL = 'DEVICES_PROFILE_NETWORK_SIGNAL',
  __DEVICES_PROFILE_NETWORK_SPEED = '__DEVICES_PROFILE_NETWORK_SPEED',
  __DEVICES_PROFILE_SYNTHETIC_URL_TEST_RESULTS = '__DEVICES_PROFILE_SYNTHETIC_URL_TEST_RESULTS',
  DEVICES_PROFILE_AVG_TOTAL_RESPONSE_TIME = 'DEVICES_PROFILE_AVG_TOTAL_RESPONSE_TIME',
  DEVICES_PROFILE_AVG_NAME_RESPONSE_TIME = 'DEVICES_PROFILE_AVG_NAME_RESPONSE_TIME',
  DEVICES_PROFILE_NETWORK_DOWNLOAD = 'DEVICES_PROFILE_NETWORK_DOWNLOAD',
  DEVICES_PROFILE_AVG_SOCKET_CONNECTION_TIME = 'DEVICES_PROFILE_AVG_SOCKET_CONNECTION_TIME',
  DEVICES_PROFILE_AVG_TLS_SETUP_TIME = 'DEVICES_PROFILE_AVG_TLS_SETUP_TIME',
  DEVICES_PROFILE_AVG_HTTP_RESPONSE_TIME = 'DEVICES_PROFILE_AVG_HTTP_RESPONSE_TIME',
  DEVICES_PROFILE_NETWORK_UPLOAD = 'DEVICES_PROFILE_NETWORK_UPLOAD',
  __DEVICES_PROFILE_NETWORK_ERROR = '__DEVICES_PROFILE_NETWORK_ERROR',
  DEVICES_PROFILE_NETWORK_ERROR_INBOUND = 'DEVICES_PROFILE_NETWORK_ERROR_INBOUND',
  DEVICES_PROFILE_NETWORK_ERROR_OUTBOUND = 'DEVICES_PROFILE_NETWORK_ERROR_OUTBOUND',
  __DEVICES_PROFILE_ENERGY = '__DEVICES_PROFILE_ENERGY',
  DEVICES_PROFILE_ENERGY_TOTAL = 'DEVICES_PROFILE_ENERGY_TOTAL',
  DEVICES_PROFILE_ENERGY_CPU = 'DEVICES_PROFILE_ENERGY_CPU',
  DEVICES_PROFILE_ENERGY_DISK = 'DEVICES_PROFILE_ENERGY_DISK',
  DEVICES_PROFILE_ENERGY_DISPLAY = 'DEVICES_PROFILE_ENERGY_DISPLAY',
  DEVICES_PROFILE_ENERGY_NETWORK = 'DEVICES_PROFILE_ENERGY_NETWORK',
  DEVICES_PROFILE_ENERGY_SYSTEM = 'DEVICES_PROFILE_ENERGY_SYSTEM',
  DEVICES_PROFILE_ENERGY_SUMMARY = 'DEVICES_PROFILE_ENERGY_SUMMARY',
  // Processor
  __DEVICES_PROFILE_PROCESSOR_TIME_PERFORMANCE = '__DEVICES_PROFILE_PROCESSOR_TIME_PERFORMANCE',
  DEVICES_PROFILE_PROCESSOR_C1 = 'DEVICES_PROFILE_PROCESSOR_C1',
  DEVICES_PROFILE_PROCESSOR_C2 = 'DEVICES_PROFILE_PROCESSOR_C2',
  DEVICES_PROFILE_PROCESSOR_C3 = 'DEVICES_PROFILE_PROCESSOR_C3',
  DEVICES_PROFILE_PROCESSOR_TOTAL = 'DEVICES_PROFILE_PROCESSOR_TOTAL',
  __DEVICES_PROFILE_PROCESSOR_COUNTERS_PERFORMANCE = '__DEVICES_PROFILE_PROCESSOR_COUNTERS_PERFORMANCE',
  DEVICES_PROFILE_PROCESSOR_TOTAL_INTERRUPT = 'DEVICES_PROFILE_PROCESSOR_TOTAL_INTERRUPT',
  DEVICES_PROFILE_PROCESSOR_TOTAL_PRIVILEGED = 'DEVICES_PROFILE_PROCESSOR_TOTAL_PRIVILEGED',
  DEVICES_PROFILE_PROCESSOR_TOTAL_IDLE = 'DEVICES_PROFILE_PROCESSOR_TOTAL_IDLE',
  DEVICES_PROFILE_PROCESSOR_TOTAL_USER = 'DEVICES_PROFILE_PROCESSOR_TOTAL_USER',
  DEVICES_PROFILE_PROCESSOR_TOTAL_DPC = 'DEVICES_PROFILE_PROCESSOR_TOTAL_DPC',
  DEVICES_PROFILE_PROCESSOR_INTERRUPTS = 'DEVICES_PROFILE_PROCESSOR_INTERRUPTS',
  DEVICES_PROFILE_PROCESSOR_AVG = 'DEVICES_PROFILE_PROCESSOR_AVG',
  // GPU Temperature Battery
  DEVICES_PROFILE_OTHERS_TEMPERATURE = 'DEVICES_PROFILE_OTHERS_TEMPERATURE',
  DEVICES_PROFILE_OTHERS_DISK = 'DEVICES_PROFILE_OTHERS_DISK',
  __DEVICES_PROFILE_OTHERS_BATTERY = '__DEVICES_PROFILE_OTHERS_BATTERY',
  DEVICES_PROFILE_OTHERS_BATTERY_CHARGE = 'DEVICES_PROFILE_OTHERS_BATTERY_CHARGE',
  DEVICES_PROFILE_OTHERS_BATTERY_DISCHARGE = 'DEVICES_PROFILE_OTHERS_BATTERY_DISCHARGE',
  __DEVICES_PROFILE_OTHERS_GPU = '__DEVICES_PROFILE_OTHERS_GPU',
  DEVICES_PROFILE_OTHERS_GPU_0 = 'DEVICES_PROFILE_OTHERS_GPU_0',
  DEVICES_PROFILE_OTHERS_GPU_1 = 'DEVICES_PROFILE_OTHERS_GPU_1',
  DEVICES_PROFILE_OTHERS_GPU_2 = 'DEVICES_PROFILE_OTHERS_GPU_2',
  DEVICES_PROFILE_OTHERS_AVG = 'DEVICES_PROFILE_OTHERS_AVG',
  // Memory
  __DEVICES_PROFILE_MEMORY_COUNTERS = '__DEVICES_PROFILE_MEMORY_COUNTERS',
  DEVICES_PROFILE_MEMORY_PAGE = 'DEVICES_PROFILE_MEMORY_PAGE',
  DEVICES_PROFILE_MEMORY_PAGE_READ = 'DEVICES_PROFILE_MEMORY_PAGE_READ',
  DEVICES_PROFILE_MEMORY_PAGE_WRITE = 'DEVICES_PROFILE_MEMORY_PAGE_WRITE',
  __DEVICES_PROFILE_MEMORY_FAULTS = '__DEVICES_PROFILE_MEMORY_FAULTS',
  DEVICES_PROFILE_MEMORY_PAGE_FAULT = 'DEVICES_PROFILE_MEMORY_PAGE_FAULT',
  DEVICES_PROFILE_MEMORY_CACHE_FAULT = 'DEVICES_PROFILE_MEMORY_CACHE_FAULT',
  DEVICES_PROFILE_MEMORY_TRANSITION_FAULT = 'DEVICES_PROFILE_MEMORY_TRANSITION_FAULT',
  __DEVICES_PROFILE_MEMORY_USAGE = '__DEVICES_PROFILE_MEMORY_USAGE',
  DEVICES_PROFILE_MEMORY_USAGE = 'DEVICES_PROFILE_MEMORY_USAGE',
  DEVICES_PROFILE_MEMORY_PAGING_USAGE = 'DEVICES_PROFILE_MEMORY_PAGING_USAGE',
  DEVICES_PROFILE_MEMORY_COMMITED_USAGE = 'DEVICES_PROFILE_MEMORY_COMMITED_USAGE',
  DEVICES_PROFILE_MEMORY_AVG = 'DEVICES_PROFILE_MEMORY_AVG',
  // Physical Disk
  __DEVICES_PROFILE_DISK_QUEUE = '__DEVICES_PROFILE_DISK_QUEUE',
  DEVICES_PROFILE_DISK_QUEUE = 'DEVICES_PROFILE_DISK_QUEUE',
  DEVICES_PROFILE_DISK_QUEUE_READ = 'DEVICES_PROFILE_DISK_QUEUE_READ',
  DEVICES_PROFILE_DISK_QUEUE_WRITE = 'DEVICES_PROFILE_DISK_QUEUE_WRITE',
  __DEVICES_PROFILE_DISK_OPERATION = '__DEVICES_PROFILE_DISK_OPERATION',
  DEVICES_PROFILE_DISK_OPERATION = 'DEVICES_PROFILE_DISK_OPERATION',
  DEVICES_PROFILE_DISK_OPERATION_READ = 'DEVICES_PROFILE_DISK_OPERATION_READ',
  DEVICES_PROFILE_DISK_OPERATION_WRITE = 'DEVICES_PROFILE_DISK_OPERATION_WRITE',
  __DEVICES_PROFILE_DISK_OPERATION_TIME = '__DEVICES_PROFILE_DISK_OPERATION_TIME',
  DEVICES_PROFILE_DISK_OPERATION_TIME = 'DEVICES_PROFILE_DISK_OPERATION_TIME',
  DEVICES_PROFILE_DISK_OPERATION_TIME_READ = 'DEVICES_PROFILE_DISK_OPERATION_TIME_READ',
  DEVICES_PROFILE_DISK_OPERATION_TIME_WRITE = 'DEVICES_PROFILE_DISK_OPERATION_TIME_WRITE',
  __DEVICES_PROFILE_DISK_RESPONSE = '__DEVICES_PROFILE_DISK_RESPONSE',
  DEVICES_PROFILE_DISK_RESPONSE = 'DEVICES_PROFILE_DISK_RESPONSE',
  DEVICES_PROFILE_DISK_RESPONSE_READ = 'DEVICES_PROFILE_DISK_RESPONSE_READ',
  DEVICES_PROFILE_DISK_RESPONSE_WRITE = 'DEVICES_PROFILE_DISK_RESPONSE_WRITE',
  DEVICES_PROFILE_DISK_AVG = 'DEVICES_PROFILE_DISK_AVG',
  // BOOT
  DEVICES_PROFILE_BOOT_DEGRADATION = 'DEVICES_PROFILE_BOOT_DEGRADATION',
  // BATTERY
  DEVICES_PROFILE_BATTERY_CHARGING_RATE = 'DEVICES_PROFILE_BATTERY_CHARGING_RATE',
  DEVICES_PROFILE_BATTERY_LEVEL = 'DEVICES_PROFILE_BATTERY_LEVEL',
  DEVICES_PROFILE_BATTERY_VOLTAGE = 'DEVICES_PROFILE_BATTERY_VOLTAGE',
  DEVICES_PROFILE_BATTERY_TEMPERATURE = 'DEVICES_PROFILE_BATTERY_TEMPERATURE',
  // STORAGE
  DEVICES_PROFILE_STORAGE_SD_CARD_SPACE_FREE_BYTES = 'DEVICES_PROFILE_STORAGE_SD_CARD_SPACE_FREE_BYTES',
  DEVICES_PROFILE_STORAGE_SD_CARD_SPACE_TOTAL_BYTES = 'DEVICES_PROFILE_STORAGE_SD_CARD_SPACE_TOTAL_BYTES',
  DEVICES_PROFILE_STORAGE_DISK_SPACE_FREE_BYTES = 'DEVICES_PROFILE_STORAGE_DISK_SPACE_FREE_BYTES',
  DEVICES_PROFILE_STORAGE_DISK_SPACE_TOTAL_BYTES = 'DEVICES_PROFILE_STORAGE_DISK_SPACE_TOTAL_BYTES',
  // NETWORK
  DEVICES_PROFILE_NETWORK_ERRORS = 'DEVICES_PROFILE_NETWORK_ERRORS',
  DEVICES_PROFILE_NETWORK_WIFI_SIGNAL_STRENGTH = 'DEVICES_PROFILE_NETWORK_WIFI_SIGNAL_STRENGTH',
  DEVICES_PROFILE_NETWORK_WIFI_NETWORK_FREQUENCY = 'DEVICES_PROFILE_NETWORK_WIFI_NETWORK_FREQUENCY',
  DEVICES_PROFILE_NETWORK_MOBILE_SIGNAL_STRENGTH = 'DEVICES_PROFILE_NETWORK_MOBILE_SIGNAL_STRENGTH',
  DEVICES_PROFILE_NETWORK_LATENCY = 'DEVICES_PROFILE_NETWORK_LATENCY',
  DEVICES_PROFILE_NETWORK_JITTER = 'DEVICES_PROFILE_NETWORK_JITTER',
  DEVICES_PROFILE_NETWORK_DESTINATION = 'DEVICES_PROFILE_NETWORK_DESTINATION',
  DEVICES_PROFILE_NETWORK_DESTINATION_CALLS = 'DEVICES_PROFILE_NETWORK_DESTINATION_CALLS',
  DEVICES_PROFILE_NETWORK_DESTINATION_LATENCY = 'DEVICES_PROFILE_NETWORK_DESTINATION_LATENCY',
  DEVICES_PROFILE_NETWORK_DESTINATION_DATA_IN = 'DEVICES_PROFILE_NETWORK_DESTINATION_DATA_IN',
  DEVICES_PROFILE_NETWORK_DESTINATION_DATA_OUT = 'DEVICES_PROFILE_NETWORK_DESTINATION_DATA_OUT',
}
