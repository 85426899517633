/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * AppHeGroup
 * Currently has the same attributes as AppCrashGroup
 * @export
 * @class AppHeGroup
 */
@Serializable
export class AppHeGroup {
  @JsonProperty('bucket_id')
  public bucketId: string = undefined;

  @JsonProperty('count')
  public count: number = undefined;

  @JsonProperty('error_name')
  public errorName: string = undefined;

  @JsonProperty('error_reason')
  public errorReason: string = undefined;

  @JsonProperty('event_type')
  public eventType: string = undefined;

  @JsonProperty('error_type')
  public errorType: string = undefined;

  @JsonProperty('first_occurred')
  public firstOccurred: number = undefined;

  @JsonProperty('last_occurred')
  public lastOccurred: number = undefined;

  @JsonProperty('users_affected_count')
  public usersAffectedCount: number = undefined;

  /**
   * getLabel
   * @returns {string}
   */
  public getLabel(): string {
    return this.errorName || this.errorType || this.errorReason;
  }
}
