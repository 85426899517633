/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { isUndefined, remove } from 'lodash-es';

import { BucketSelection, ChartDrilldownEvent, FocusedSeries } from '@ws1c/intelligence-models/dashboard/chart-drilldown-event.interface';
import { FilterRule } from '@ws1c/intelligence-models/filter/filter-rule.model';
import { isNumberDataType } from '@ws1c/intelligence-models/helpers';
import { DataType } from '@ws1c/intelligence-models/integration-meta/data-type.model';
import { NgxSingleData } from './ngx-chart-data-builder.model';
import { StartEndMillisIndex } from './ngx-common.interface';
import { NgxTrendResultFlattener } from './ngx-trend-result-flattener.model';

/**
 * NgxDrilldownEventBuilder
 * @export
 * @class NgxDrilldownEventBuilder
 */
export class NgxDrilldownEventBuilder {
  public otherSeries: NgxSingleData[];

  /**
   * constructor
   * @param {string[]} groupBys
   * @param {StartEndMillisIndex} startEndDrilldownByDateValue
   * @param {any} labelsByFlatKey
   * @param {{ [bucketingAttributeKey: string]: string }} dataTypesByBucketingAttributeKey
   * @param {{ [formattedTime: string]: number }} timestampsByFormattedTimes
   * @param {any} colorsByAttribute
   * @param {number} colorizedLevel
   * @param {any} translators
   * @param {boolean} isInvertMode
   * @memberof NgxDrilldownEventBuilder
   */
  public constructor(
    private groupBys: string[],
    private startEndDrilldownByDateValue: StartEndMillisIndex,
    private labelsByFlatKey: any,
    private dataTypesByBucketingAttributeKey: { [bucketingAttributeKey: string]: string },
    private timestampsByFormattedTimes: { [formattedTime: string]: number },
    private colorsByAttribute: any,
    private colorizedLevel: number,
    private translators: any,
    private isInvertMode: boolean,
  ) {}

  /**
   * getEvent
   * ngxSelectEvents are weird, it can be a string or objects based on chart type and series
   * this handles the conversion to a standard ChartDrilldownEvent
   * @param {any} ngxSelectEvent
   * @param {boolean} useFocusedSeries
   * @returns {ChartDrilldownEvent}
   * @memberof NgxDrilldownEventBuilder
   */
  public getEvent(ngxSelectEvent: any, useFocusedSeries: boolean = true): ChartDrilldownEvent {
    let groupByValues: any[];
    // click events from the legend series are just strings
    if (typeof ngxSelectEvent === 'string') {
      // Chart legends can show/emit values for different bucketingAttributes
      const bucketName = this.groupBys[this.colorizedLevel];
      let selectedValue: any = ngxSelectEvent;
      if (this.dataTypesByBucketingAttributeKey[bucketName] === DataType[DataType.DATETIME]) {
        selectedValue = this.timestampsByFormattedTimes[ngxSelectEvent];
      }
      return this.applyDrilldownEventModifications(
        {
          selectedBuckets: [
            {
              bucketName,
              bucketColor: this.colorsByAttribute[ngxSelectEvent],
              bucketNameLabel: this.labelsByFlatKey[bucketName],
              bucketDataType: this.dataTypesByBucketingAttributeKey[bucketName],
              selectedValue,
            } as BucketSelection,
          ],
        } as ChartDrilldownEvent,
        useFocusedSeries,
      );
      // drilldown events for charts with no groupBys still return '' as the name
    } else if (!this.groupBys.length) {
      groupByValues = [];
    } else if (typeof ngxSelectEvent.series === 'string') {
      groupByValues = [ngxSelectEvent.series, ngxSelectEvent.name];
    } else {
      groupByValues = [ngxSelectEvent.name];
    }
    return this.applyDrilldownEventModifications(this.getEventFromGroupByValues(groupByValues), useFocusedSeries);
  }

  /**
   * getNextFocusedSeriesToggle
   * Clicking on series in the legend toggles focusedSeries
   * Clicking on a datapoint in the chart _sets_ focusedSeries
   * @param {FocusedSeries} focusedSeries
   * @param {string} toggleSeriesName
   * @returns {FocusedSeries}
   * @memberof NgxDrilldownEventBuilder
   */
  public getNextFocusedSeriesToggle(focusedSeries: FocusedSeries, toggleSeriesName: string): FocusedSeries {
    const bucketName = this.groupBys[this.colorizedLevel];
    if (!focusedSeries || focusedSeries.colorizedAttributeName !== bucketName) {
      return {
        colorizedAttributeName: bucketName,
        colorizedAttributeLabel: this.labelsByFlatKey[bucketName],
        seriesNames: [toggleSeriesName],
        seriesDataType: this.dataTypesByBucketingAttributeKey[bucketName],
      };
    }

    const nextFocusedSeries = {
      colorizedAttributeName: bucketName,
      colorizedAttributeLabel: this.labelsByFlatKey[bucketName],
      seriesNames: focusedSeries.seriesNames,
      seriesDataType: this.dataTypesByBucketingAttributeKey[bucketName],
    } as FocusedSeries;
    if (nextFocusedSeries.seriesNames.includes(toggleSeriesName)) {
      nextFocusedSeries.seriesNames = nextFocusedSeries.seriesNames.filter((seriesName: string) => seriesName !== toggleSeriesName);
    } else {
      nextFocusedSeries.seriesNames = [...nextFocusedSeries.seriesNames, toggleSeriesName];
    }
    return nextFocusedSeries;
  }

  /**
   * getEventFromGroupByValues
   * @param {any[]} groupByValues
   * @memberof NgxDrilldownEventBuilder
   * @returns {ChartDrilldownEvent}
   */
  public getEventFromGroupByValues(groupByValues: any[]): ChartDrilldownEvent {
    const selectedBuckets = groupByValues
      .map((groupByValue: any, index: number) => {
        const bucketName = this.groupBys[index];
        let selectedValue = groupByValue;
        const bucketDataType = this.dataTypesByBucketingAttributeKey[bucketName];
        switch (bucketDataType) {
          case DataType[DataType.DATETIME]:
            selectedValue = this.timestampsByFormattedTimes[groupByValue];
            break;
          case DataType[DataType.BOOLEAN]:
            selectedValue = selectedValue === 'true';
            break;
          case DataType[DataType.INTEGER]:
          case DataType[DataType.LONG]:
          case DataType[DataType.FLOAT]:
          case DataType[DataType.DOUBLE]:
            // escape OTHERS
            selectedValue = this.otherSeries?.length ? selectedValue : Number(selectedValue);
            break;
        }
        return {
          bucketName,
          bucketColor: this.colorsByAttribute[groupByValue],
          bucketNameLabel: this.labelsByFlatKey[bucketName],
          bucketDataType,
          // INTEL-40969 escape merged series due to conversion of its datatype and value mismatch
          selectedValue: isNaN(selectedValue) ? groupByValue : selectedValue,
        } as BucketSelection;
      })
      .filter((bucketSelection: BucketSelection) => {
        return !isUndefined(bucketSelection.selectedValue) && bucketSelection.bucketName !== NgxTrendResultFlattener.FAKE_GROUP_BY_KEY;
      });

    const dateTimeBucketSelection = remove(selectedBuckets, (selectedBucket: BucketSelection) => {
      return selectedBucket.bucketName === NgxTrendResultFlattener.DATE_KEY;
    })[0];
    let selectedDateValue: string;
    let startEndMillis: Partial<ChartDrilldownEvent>;
    if (dateTimeBucketSelection) {
      selectedDateValue = dateTimeBucketSelection.selectedValue;
      startEndMillis = this.startEndDrilldownByDateValue[dateTimeBucketSelection.selectedValue];
    }

    return {
      selectedBuckets,
      selectedDateValue,
      ...startEndMillis,
    } as ChartDrilldownEvent;
  }

  /**
   * applyDrilldownEventModifications
   * @param  {ChartDrilldownEvent} drilldownEvent
   * @param  {boolean} useFocusedSeries
   * @returns {ChartDrilldownEvent}
   * @memberof NgxDrilldownEventBuilder
   */
  public applyDrilldownEventModifications(drilldownEvent: ChartDrilldownEvent, useFocusedSeries: boolean = true): ChartDrilldownEvent {
    const drilldownWithOthersBucket = this.applyOthersBucket(drilldownEvent);
    drilldownWithOthersBucket.isInvertMode = this.isInvertMode;
    return useFocusedSeries ? this.convertSetFocusedSeries(drilldownWithOthersBucket) : drilldownWithOthersBucket;
  }

  /**
   * applyOthersBucket
   * @param  {ChartDrilldownEvent} drilldownEvent
   * @returns {ChartDrilldownEvent}
   * @memberof NgxDrilldownEventBuilder
   */
  public applyOthersBucket(drilldownEvent: ChartDrilldownEvent): ChartDrilldownEvent {
    // replaces "Others" bucket selection
    if (this.otherSeries && this.otherSeries.length) {
      const othersDrilldownBucket = remove(drilldownEvent.selectedBuckets, (selectedBucket: BucketSelection) => {
        return selectedBucket.selectedValue === this.translators.OTHERS();
      })[0];
      if (othersDrilldownBucket) {
        const filterRule = Object.assign(new FilterRule(), {
          label: othersDrilldownBucket.bucketNameLabel,
          attribute: othersDrilldownBucket.bucketName,
          condition: 'IN',
          data: this.otherSeries.map((otherSeries: NgxSingleData) =>
            isNumberDataType(othersDrilldownBucket.bucketDataType) ? Number(otherSeries.name) : otherSeries.name,
          ),
        });
        drilldownEvent.addFilters = [filterRule];
      }
    }
    return drilldownEvent;
  }

  /**
   * convertSetFocusedSeries
   * @param  {ChartDrilldownEvent} drilldownEvent
   * @returns {ChartDrilldownEvent}
   * @memberof NgxDrilldownEventBuilder
   */
  public convertSetFocusedSeries(drilldownEvent: ChartDrilldownEvent): ChartDrilldownEvent {
    const colorizedAttributeName = this.groupBys[this.colorizedLevel];
    const colorizedDrilldownBucket = remove(drilldownEvent.selectedBuckets, (selectedBucket: BucketSelection) => {
      return selectedBucket.bucketName === colorizedAttributeName;
    })[0];
    if (colorizedDrilldownBucket) {
      const seriesName = colorizedDrilldownBucket.selectedValue;
      drilldownEvent.setFocusedSeries = {
        seriesNames: [seriesName],
        seriesDataType: this.dataTypesByBucketingAttributeKey[colorizedAttributeName],
        colorizedAttributeName,
        colorizedAttributeLabel: colorizedDrilldownBucket.bucketNameLabel,
      };
    }
    return drilldownEvent;
  }

  /**
   * setOtherSeries
   * @param {NgxSingleData[]} otherSeries
   * @memberof NgxDrilldownEventBuilder
   */
  public setOtherSeries(otherSeries: NgxSingleData[]) {
    this.otherSeries = otherSeries;
  }
}
