/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { MarketplaceResourceType } from './marketplace-resource-type.enum';

/**
 * Maps marketplace resource to template page path
 */
export const MarketplaceResourceTemplatePath: Record<string, string> = {
  [MarketplaceResourceType.AUTOMATION_TEMPLATE]: 'automations',
  [MarketplaceResourceType.DASHBOARD_TEMPLATE]: 'dashboards',
  [MarketplaceResourceType.REPORT_TEMPLATE]: 'reports',
  [MarketplaceResourceType.SCRIPT_TEMPLATE]: 'scripts',
  [MarketplaceResourceType.SENSOR_TEMPLATE]: 'sensors',
  [MarketplaceResourceType.UEM_FREESTYLE_WORKFLOW_TEMPLATE]: 'freestyle-workflows',
  [MarketplaceResourceType.WIDGET_TEMPLATE]: 'widgets',
};
