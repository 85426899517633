/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * ServiceIdSearchData
 * @export
 * @class ServiceIdSearchData
 */
@Serializable
export class ServiceIdSearchData {
  @JsonProperty('service_ids')
  public serviceIds: string[] = [];

  /**
   * Creates an instance of ServiceIdSearchData
   * @param {any} args
   * @memberof ServiceIdSearchData
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
