/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

import { MetaFormField } from '@ws1c/intelligence-models/meta-form';
import { JsonSchemaAutomationActionFieldForLookup } from './json-schema-automation-action-field-meta-for-lookup.model';

/**
 * JsonSchemaAutomationActionFieldLookupRequestPayload
 * @export
 * @class JsonSchemaAutomationActionFieldLookupRequestPayload
 */
@Serializable
export class JsonSchemaAutomationActionFieldLookupRequestPayload {
  @JsonProperty({ name: 'fieldForLookup', cls: JsonSchemaAutomationActionFieldForLookup })
  public fieldForLookup: JsonSchemaAutomationActionFieldForLookup = undefined;

  @JsonProperty({ name: 'selectedLookupValue', cls: MetaFormField })
  public selectedLookupValue?: Partial<MetaFormField> = undefined;

  @JsonProperty('query')
  public query?: string = undefined;

  /**
   * Creates an instance of JsonSchemaAutomationActionFieldLookupRequestPayload
   * @param {Array<Partial<JsonSchemaAutomationActionFieldLookupRequestPayload>>} args
   * @memberof JsonSchemaAutomationActionFieldLookupRequestPayload
   */
  constructor(...args: Array<Partial<JsonSchemaAutomationActionFieldLookupRequestPayload>>) {
    Object.assign(this, ...args);
  }
}
