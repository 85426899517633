/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * WS1BrokerDirectoryType
 * @exports
 * @enum {string}
 */
export enum WS1BrokerDirectoryType {
  JIT = 'JIT',
  PROVISIONED = 'PROVISIONED',
}
