/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * DeemAppDetailsPageTab
 * @export
 * @enum {string}
 */
export enum DeemAppDetailsPageTab {
  OVERVIEW = 'overview',
  ERRORS = 'errors',
}
