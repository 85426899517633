/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * WorkflowConnectorsTab
 * @export
 * @enum {string}
 */
export enum WorkflowConnectorsTab {
  USAGE = 'usage',
  ACTIONS = 'actions',
  ERRORS = 'errors',
  ADMIN_ACTIVITY = 'admin-activity',
}
