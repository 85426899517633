/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * JsonSchemaType
 * @export
 * @enum {string}
 */
export enum JsonSchemaType {
  OBJECT = 'object',
  STRING = 'string',
  NUMBER = 'number',
  INTEGER = 'integer',
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  NULL = 'null',
}
