/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, PagedResponse, Serializable } from '@dpa/ui-common';

import { dateFormatConverter } from './converters/date-format.converter';
import { GenericSearchRequest } from './generic-search-request.model';
import { UserDetails } from './user-details.model';

/**
 * TrackingStatus Enum
 * @export
 * @enum {string}
 */
export enum TrackingStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  SUBMITTED = 'SUBMITTED',
  UNKNOWN = 'UNKNOWN',
  COMPLETED_WITH_DETAILS = 'COMPLETED_WITH_DETAILS',
  PENDING_COMPLETION = 'PENDING_COMPLETION',
  DOWNLOAD_SUBMITTED = 'DOWNLOAD_SUBMITTED',
  DOWNLOAD_INITIATED = 'DOWNLOAD_INITIATED',
}

/**
 * Download Report Record
 * @export
 * @class ReportTracking
 */
@Serializable
export class ReportTracking {
  @JsonProperty('id')
  public id: string = undefined;

  @JsonProperty('report_schedule_id')
  public reportScheduleId: string = undefined;

  @JsonProperty('report_id')
  public reportId: string = undefined;

  @JsonProperty('status')
  public status: string = undefined;

  @JsonProperty('location')
  public location: string = undefined;

  @JsonProperty('error_message')
  public errorMessage: string = undefined;

  @JsonProperty('processing_time_millis')
  public processingTimeMillis: number;

  @JsonProperty({ name: 'created_at', customConverter: dateFormatConverter })
  public createdAt: number = undefined;

  @JsonProperty('created_by')
  public createdBy: string = undefined;

  @JsonProperty({ name: 'created_by_details', cls: UserDetails, excludeToJson: true })
  public createdByDetails: UserDetails = undefined;

  @JsonProperty({ name: 'modified_at', customConverter: dateFormatConverter })
  public modifiedAt: number = undefined;

  @JsonProperty('modified_by')
  public modifiedBy: string = undefined;

  @JsonProperty({ name: 'modified_by_details', cls: UserDetails, excludeToJson: true })
  public modifiedByDetails: UserDetails = undefined;

  @JsonProperty('completion_details')
  public completionDetails: string = undefined;

  /**
   * Creates an instance of ReportTracking.
   * @param {...Array<Partial<ReportTracking>>} args
   * @memberof ReportTracking
   */
  constructor(...args: Array<Partial<ReportTracking>>) {
    Object.assign(this, ...args);
  }
}

/**
 * ReportTrackingSearchRequest
 * @export
 * @class ReportTrackingSearchRequest
 * @extends {GenericSearchRequest}
 */
@Serializable
export class ReportTrackingSearchRequest extends GenericSearchRequest {
  @JsonProperty({ name: 'report_id', excludeToJson: true })
  public reportId?: string = undefined;

  @JsonProperty({ name: 'schedule_id', excludeToJson: true })
  public scheduleId?: string = undefined;

  /**
   * Creates an instance of ReportTrackingSearchRequest.
   * @param {any} args
   * @memberof ReportTrackingSearchRequest
   */
  constructor(...args) {
    super();
    Object.assign(this, ...args);
  }
}

/**
 * ReportTrackingSearchResponse
 * @export
 * @class ReportTrackingSearchResponse
 */
@Serializable
export class ReportTrackingSearchResponse implements PagedResponse {
  @JsonProperty('page_size')
  public size: number = undefined;

  @JsonProperty('offset')
  public from: number = undefined;

  @JsonProperty('total_count')
  public total: number = undefined;

  @JsonProperty({ name: 'results', cls: ReportTracking })
  public results: ReportTracking[] = [];

  /**
   * Creates an instance of ReportTrackingSearchResponse.
   * @param {any} args
   * @memberof ReportTrackingSearchResponse
   */
  constructor(...args) {
    Object.assign(this, ...args);
  }
}
