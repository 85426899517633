/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * getNumberFormatter
 * @export
 * @param {string} locale
 * @param {Intl.NumberFormatOptions} options
 * @returns {Intl.NumberFormat}
 */
export function getNumberFormatter(locale: string, options?: Intl.NumberFormatOptions): Intl.NumberFormat {
  return new Intl.NumberFormat(locale.replace('_', '-'), { notation: 'compact', maximumFractionDigits: 1, ...options });
}
