/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CustomConverter, deserialize, JsonProperty, Serializable } from '@dpa/ui-common';
import { omit } from 'lodash-es';

import { AvailableEventForwarder } from './available-event-forwarder.model';
import { EventForwarderConnectionPrimaryProperties } from './event-forwarder-connection.enum';

/**
 * availableEventForwarderConverter
 * Convertor for metaFormFields, all properties coming from backend leaving primary once will be
 * pushed to metaFormFields which will be directly consumed by meta-form.
 * @type {CustomConverter}
 */
const availableEventForwarderConverter: CustomConverter = {
  fromJson(availableEventForwarderJson: any): AvailableEventForwarder {
    const availableEventForwarder: AvailableEventForwarder = deserialize(AvailableEventForwarder, availableEventForwarderJson);
    const metaFormJson: any = omit(availableEventForwarderJson, [
      EventForwarderConnectionPrimaryProperties.ID,
      EventForwarderConnectionPrimaryProperties.NAME,
      EventForwarderConnectionPrimaryProperties.STATUS_CHECK_SUCCESS,
      EventForwarderConnectionPrimaryProperties.MODIFIED_AT,
    ]);
    availableEventForwarder.metaFormFields = metaFormJson;
    return availableEventForwarder;
  },
  toJson(availableEventForwarder: AvailableEventForwarder): any {
    return availableEventForwarder;
  },
};

/**
 * AvailableEventForwarderWrapper
 * Wrapper to facilitate conversion of AvailableEventForwarder JSON
 * @export
 * @class AvailableEventForwarderWrapper
 */
@Serializable
export class AvailableEventForwarderWrapper {
  @JsonProperty({ name: 'availableEventForwarder', customConverter: availableEventForwarderConverter })
  public availableEventForwarder: AvailableEventForwarder = undefined;
}
