/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import {
  BaseRule,
  CustomConverter,
  DateTimeFormat,
  GenericObject,
  JsonProperty,
  RelativeTime,
  RelativeTimeUnit,
  Serializable,
} from '@dpa/ui-common';
import { find, isArray, isEmpty, isNil, isUndefined, map, replace, some } from 'lodash-es';
import moment from 'moment';

import { Column, ColumnIndex, DataType, Operator } from '@ws1c/intelligence-models/integration-meta';
import { OrgTreeNode } from '@ws1c/intelligence-models/org/org-tree-node.model';
import { Tag } from '@ws1c/intelligence-models/template/tag.model';
import { FilterRuleNormalized, FilterRuleNormalizedValue } from './filter-rule-normalized.interface';
import { FilterRuleConfig } from './filter-rule.config';

export enum RuleStatus {
  EMPTY,
  DRAFT,
  INVALID,
  VALID,
}

const dataConverter: CustomConverter = {
  fromJson(data: any): any {
    return data;
  },
  toJson(data: any): any {
    return data;
  },
};

const isDraftConverter: CustomConverter = {
  fromJson(value: boolean): boolean {
    return Boolean(value ?? true);
  },
  toJson(value: boolean): boolean {
    return Boolean(value ?? true);
  },
};

/**
 * FilterRule
 * @export
 * @class FilterRule
 */
@Serializable
export class FilterRule extends BaseRule<FilterRule> {
  public static readonly FILTER_CONDITION = {
    and: ' AND ',
    contains: 'CONTAINS',
    containsSubstring: 'CONTAINS SUBSTRING',
    equals: '=',
    greaterThan: '>',
    greaterThanOrEqualTo: '>=',
    includes: 'IN',
    isNotNull: 'IS NOT NULL',
    isNull: 'IS NULL',
    notEquals: '!=',
    notContainsSubstring: 'NOT CONTAINS SUBSTRING',
    notIncludes: 'NOT IN',
    notStartsWith: 'NOT STARTS WITH',
    notWithin: 'NOT WITHIN',
    startsWith: 'STARTS WITH',
    within: 'WITHIN',
    endsWith: 'ENDS WITH',
    notEndsWith: 'NOT ENDS WITH',
    constainsAllOf: 'CONTAINS ALL OF',
    constainsAnyOf: 'CONTAINS ANY OF',
    constainsNoneOf: 'CONTAINS NONE OF',
    between: 'BETWEEN',
    notBetween: 'NOT BETWEEN',
  };

  public static readonly collectionDataTypes = new Set<string>([
    DataType[DataType.STRINGLIST],
    DataType[DataType.STRINGSET],
    DataType[DataType.NUMBERLIST],
    DataType[DataType.NUMBERSET],
  ]);

  /**
   * listFromKeyValue
   * @param {Record<string, string>} keyValue
   * @param {any} dataTypesByBucket
   * @returns {FilterRule[]}
   * @memberof FilterRule
   */
  public static listFromKeyValue(keyValue: Record<string, string>, dataTypesByBucket?: any): FilterRule[] {
    return map(keyValue, (value: string, key: string) => {
      const dataType = dataTypesByBucket && dataTypesByBucket[key];
      const operator = FilterRule.collectionDataTypes.has(dataType)
        ? FilterRule.FILTER_CONDITION.contains
        : FilterRule.FILTER_CONDITION.equals;

      return Object.assign(new FilterRule(), {
        attribute: key,
        condition: !isUndefined(value) ? operator : 'IS NULL',
        data: [DataType[DataType.DOUBLE], DataType[DataType.INTEGER], DataType[DataType.FLOAT]].includes(dataType)
          ? parseFloat(replace(value, /\,/g, ''))
          : value,
      });
    });
  }

  @JsonProperty({ name: 'attribute' })
  public attribute: string = undefined;
  @JsonProperty({ name: 'condition' })
  public condition: string = undefined;
  @JsonProperty({ name: 'data', customConverter: dataConverter })
  public data: any = undefined;
  @JsonProperty({ name: 'dataType' })
  public dataType: string = undefined;
  @JsonProperty({ name: 'label' })
  public label: string = undefined;
  @JsonProperty({ name: 'entity' })
  public entity: string = undefined;
  @JsonProperty({ name: 'integration' })
  public integration: string = undefined;

  // added in by filter-group-rule component to allow drafting mode
  @JsonProperty({ name: 'isDraft', customConverter: isDraftConverter, excludeToJson: true })
  public isDraft: boolean = true;

  // this value should be serialized while setting to query params and deserialized while using query params
  @JsonProperty({ name: 'valueRequired' })
  public valueRequired: boolean = true;

  /**
   * constructor
   * @param {Array<Partial<FilterRule>>} args
   * @memberof FilterRule
   */
  constructor(...args: Array<Partial<FilterRule>>) {
    super();
    Object.assign(this, ...args);
    this.uuid = undefined;
  }

  /**
   * getter for isIPv4: Check for IPV4 or IPV4LIST dataType
   *
   * @returns {boolean}
   * @memberof FilterRule
   */
  public get isIPv4(): boolean {
    return [DataType[DataType.IPV4], DataType[DataType.IPV4LIST]].includes(this.dataType);
  }

  /**
   * getter for isNumberArray: Check for number dataType with include condition
   *
   * @returns {boolean}
   * @memberof FilterRule
   */
  public get isNumberArray(): boolean {
    return (
      [DataType.INTEGER, DataType.LONG, DataType.FLOAT, DataType.DOUBLE].includes(DataType[this.dataType]) &&
      this.condition === FilterRule.FILTER_CONDITION.includes
    );
  }

  /**
   * getter for isBoolean
   *
   * @returns {boolean}
   * @memberof FilterRule
   */
  public get isBoolean(): boolean {
    return this.dataType === 'BOOLEAN';
  }

  /**
   * isValid
   * @param {any} allColumnsByName
   * @returns {boolean}
   * @memberof FilterRule
   */
  public isValid(allColumnsByName: any): boolean {
    if (this.isMissingColumnName()) {
      return false;
    }
    const condition: Operator = this.getCondition(allColumnsByName);
    let isValidLength = true;

    if (this.data?.length < condition?.minLength) {
      isValidLength = false;
    }
    const hasRuleAttribute = !!this.attribute;
    const hasCondition = !!condition;
    const hasRuleValue = !this.valueRequired || this.doesValueExist(this.data);
    const hasValidInputsForRangeCondition = this.hasValidInputsForRangeCondition(condition);
    const hasValidInputsForIPV4 = this.hasValidInputsForIPV4(condition);
    const hasValidInputsForNumberArray = this.hasValidInputsForNumberArray();
    return (
      hasRuleAttribute &&
      hasRuleValue &&
      hasCondition &&
      hasValidInputsForRangeCondition &&
      hasValidInputsForIPV4 &&
      hasValidInputsForNumberArray &&
      isValidLength
    );
  }

  /**
   * isMissingFields
   * @memberof FilterRule
   * @returns {boolean}
   * @memberof FilterRule
   */
  public isMissingFields(): boolean {
    return some([isUndefined(this.condition), isUndefined(this.attribute), isUndefined(this.data)]);
  }

  /**
   * isMissingColumnName - "attribute" is really columnName
   * @returns {boolean}
   * @memberof FilterRule
   */
  public isMissingColumnName(): boolean {
    return isEmpty(this) || !this.attribute;
  }

  /**
   * getCondition
   * @param {ColumnIndex} allColumnsByName
   * @returns {Operator}
   * @memberof FilterRule
   */
  public getCondition(allColumnsByName: ColumnIndex): Operator {
    const column = allColumnsByName[this.attribute] || new Column();
    return find(column.supportedOperators, (supportedOperator: Operator) => {
      return supportedOperator.value === this.condition;
    });
  }

  /**
   * Get the name of the rule given the attribute/ name
   * @param {ColumnIndex} allColumnsByName
   * @returns {string} column label
   * @memberof FilterRule
   */
  public getColumnLabel(allColumnsByName: ColumnIndex): string {
    const column = allColumnsByName[this.attribute];
    return column?.label ?? this.attribute;
  }

  /**
   * getConditionLabel
   * @param {ColumnIndex} allColumnsByName
   * @returns {string} condition string or empty string
   * @memberof FilterRule
   */
  public getConditionLabel(allColumnsByName: ColumnIndex): string {
    if (!allColumnsByName) {
      return '';
    }

    const condition = this.getCondition(allColumnsByName);
    if (!condition?.label) {
      return '';
    }

    return condition.label.toLowerCase();
  }

  /**
   * doesValueExist
   * @param {any} data
   * @returns {boolean}
   * @memberof FilterRule
   */
  public doesValueExist(data: any): boolean {
    if (Array.isArray(data)) {
      return !!data.length;
    }
    return !isNil(data) && data !== '';
  }

  /**
   * if condition is between or not between then check the validity
   * @param {any} condition
   * @returns {boolean}
   * @memberof FilterRule
   */
  public hasValidInputsForRangeCondition(condition: any): boolean {
    // if the condition is not "between or not between" then we do not need to validate this and we return true
    if (!condition || !['BETWEEN', 'NOT_BETWEEN'].includes(condition.name)) {
      return true;
    }

    if (!Array.isArray(this.data) || this.data.length !== 2) {
      return false;
    }

    return this.doesValueExist(this.data[0]) && this.doesValueExist(this.data[1]);
  }

  /**
   * check for IPV4 value validation at filter-rule level
   * @param {Operator} condition
   * @returns {boolean}
   * @memberof FilterRule
   */
  public hasValidInputsForIPV4(condition: Operator): boolean {
    if (![DataType[DataType.IPV4], DataType[DataType.IPV4LIST]].includes(this.dataType) || !condition?.valueRequired) {
      return true;
    }
    if (Array.isArray(this.data)) {
      return this.data.every((ip: string) => FilterRuleConfig.IPV4_PATTERN.test(ip));
    } else {
      return FilterRuleConfig.IPV4_PATTERN.test(this.data);
    }
  }

  /**
   * hasValidInputsForNumberArray: validate rules with datatypes as number and operator as includes as it'll have number array
   * @returns {boolean}
   * @memberof FilterRule
   */
  public hasValidInputsForNumberArray(): boolean {
    if (this.isNumberArray && Array.isArray(this.data)) {
      return this.data.every((value: string) => FilterRuleConfig.NUMBER_PATTERN.test(value));
    }
    return true;
  }

  /**
   * getStatus
   *
   * @param {*} allColumnsByName
   * @returns {RuleStatus}
   * @memberof FilterRule
   */
  public getStatus(allColumnsByName: any): RuleStatus {
    if (!allColumnsByName || !this.attribute) {
      return RuleStatus.EMPTY;
    }

    if (this.isDraft) {
      return RuleStatus.DRAFT;
    }

    if (!this.isValid(allColumnsByName)) {
      return RuleStatus.INVALID;
    }

    return RuleStatus.VALID;
  }

  /**
   * isEmpty
   *
   * @param {GenericObject} allColumnsByName
   * @returns {boolean}
   * @memberof FilterRule
   */
  public isEmpty(allColumnsByName: GenericObject): boolean {
    return this.getStatus(allColumnsByName) === RuleStatus.EMPTY;
  }

  /**
   * isValidOrEmpty
   *
   * @param {GenericObject} allColumnsByName
   * @returns {boolean}
   * @memberof FilterRule
   */
  public isValidOrEmpty(allColumnsByName: GenericObject): boolean {
    return this.isValid(allColumnsByName) || this.isEmpty(allColumnsByName);
  }

  /**
   * getDateString
   * @param {number} data
   * @returns {string} date formatted like '01/01/1970 9:23 am'
   * @memberof FilterRule
   */
  public getDateString(data: number): string {
    const formatDate = moment.localeData().longDateFormat(DateTimeFormat.MOMENT_DATE_FORMAT);
    const dateStr = moment(data).format(formatDate);

    const formatTime = moment.localeData().longDateFormat(DateTimeFormat.MOMENT_TIME_FORMAT);
    const timeStr = moment(data).format(formatTime);

    return `${dateStr} ${timeStr}`;
  }

  /**
   * getNormalizedValue
   * @param {ColumnIndex} allColumnsByName
   * @param {OrgTreeNode} orgHierachy
   * @returns {FilterRuleNormalizedValue}
   * @memberof FilterRule
   */
  public getNormalizedValue(allColumnsByName: ColumnIndex, orgHierachy: OrgTreeNode): FilterRuleNormalizedValue {
    if (this.data === undefined || this.data === null || (Array.isArray(this.data) && !this.data.length)) {
      return '';
    }
    const column = allColumnsByName[this.attribute] || new Column();

    if (FilterRule.isRelativeRange(this.condition)) {
      const relativeTime: RelativeTime = this.data[0];
      let resource: string;
      switch (relativeTime.unit) {
        case RelativeTimeUnit[RelativeTimeUnit.MINUTES]:
          resource = 'COMMON_MESSAGES.LAST_COUNT_MINUTE_S';
          break;
        case RelativeTimeUnit[RelativeTimeUnit.HOURS]:
          resource = 'COMMON_MESSAGES.LAST_COUNT_HOUR_S';
          break;
        case RelativeTimeUnit[RelativeTimeUnit.DAYS]:
          resource = 'COMMON_MESSAGES.LAST_COUNT_DAY_S';
          break;
        case RelativeTimeUnit[RelativeTimeUnit.WEEKS]:
          resource = 'COMMON_MESSAGES.LAST_COUNT_WEEK_S';
          break;
        case RelativeTimeUnit[RelativeTimeUnit.MONTHS]:
          resource = 'COMMON_MESSAGES.LAST_COUNT_MONTH_S';
          break;
        case RelativeTimeUnit[RelativeTimeUnit.YEARS]:
          resource = 'COMMON_MESSAGES.LAST_COUNT_YEAR_S';
          break;
      }

      return resource
        ? {
            resource,
            data: {
              count: relativeTime.interval,
            },
          }
        : '';
    }

    if (Array.isArray(this.data)) {
      let data = this.data;
      if (column.isDateTimeField()) {
        data = this.data.map((item: number) => this.getDateString(item));
      } else if (column.isTreeField()) {
        data = orgHierachy ? this.data.map((orgId: number) => orgHierachy.getTextByValue(orgId)) : [];
      }
      return `(${data.join(', ')})`;
    } else if (column.isDateTimeField()) {
      return this.getDateString(this.data);
    }

    return this.data.toString();
  }

  /**
   * getNormalizedRule
   * @param {ColumnIndex} allColumnsByName
   * @param {OrgTreeNode} orgHierachy
   * @returns {FilterRuleNormalized}
   * @memberof FilterRule
   */
  public getNormalizedRule(allColumnsByName: ColumnIndex, orgHierachy: OrgTreeNode): FilterRuleNormalized {
    const name = this.getColumnLabel(allColumnsByName);
    const condition = this.getConditionLabel(allColumnsByName);
    const value = this.getNormalizedValue(allColumnsByName, orgHierachy);

    return {
      name,
      condition,
      value,
    };
  }

  /**
   * getInitialRuleFromColumn
   *
   * @static
   * @param {Column} column
   * @returns {FilterRule}
   * @memberof FilterRule
   */
  public static getInitialRuleFromColumn(column: Column): FilterRule {
    const operator = column.supportedOperators[0];
    return new FilterRule({
      condition: operator?.value,
      valueRequired: operator?.valueRequired,
      attribute: column.attributeName,
      entity: column.entity,
      integration: column.integration,
      label: column.label,
      data: undefined,
      dataType: column.dataType,
    });
  }

  /**
   * Check whether value is a relative range or not based on operator
   *
   * @static
   * @param {string} operator
   * @returns {boolean}
   * @memberof FilterRule
   */
  public static isRelativeRange(operator: string): boolean {
    return ['WITHIN', 'NOT WITHIN'].includes(operator);
  }

  /**
   * getNewRuleWithExtend
   *
   * @param {Partial<FilterRule>} arg
   * @returns {FilterRule}
   * @memberof FilterRule
   */
  public getNewRuleWithExtend(arg: Partial<FilterRule> = {}): FilterRule {
    return new FilterRule({
      ...this,
      ...arg,
    });
  }

  /**
   * isArrayOperator
   * @static
   * @param {string} operator
   * @returns {boolean}
   * @memberof FilterRule
   */
  public static isArrayOperator(operator: string): boolean {
    const FILTER_CONDITION = FilterRule.FILTER_CONDITION;
    return [
      FILTER_CONDITION.includes,
      FILTER_CONDITION.notIncludes,
      FILTER_CONDITION.constainsAllOf,
      FILTER_CONDITION.constainsAnyOf,
      FILTER_CONDITION.constainsNoneOf,
      FILTER_CONDITION.between,
      FILTER_CONDITION.notBetween,
    ].includes(operator);
  }

  /**
   * convertTagToFilterRule
   * @static
   * @param {Tag[]} tags
   * @param {Column} column
   * @returns {FilterRule}
   * @memberof FilterRule
   */
  public static convertTagToFilterRule(tags: Tag[], column: Column): FilterRule {
    return Object.assign(FilterRule.getInitialRuleFromColumn(column), {
      condition: FilterRule.FILTER_CONDITION.includes,
      data: tags.map((tag: Tag) => tag.label),
    });
  }

  /**
   * convertFilterRuleToTags
   * @static
   * @param {FilterRule} filterRule
   * @param {Tag[]} filterTags
   * @returns {Tag[]}
   * @memberof FilterRule
   */
  public static convertFilterRuleToTags(filterRule: FilterRule, filterTags: Tag[]): Tag[] {
    const filterTagsMap = new Map();
    filterTags.forEach((item: Tag) => {
      if (item.attribute !== filterRule.attribute) {
        return;
      }
      filterTagsMap.set(item.label, item);
    });

    let filterRuleData = filterRule.data;
    if (!isArray(filterRuleData)) {
      filterRuleData = [filterRuleData];
    }

    return filterRuleData.map((data: string) => {
      const matchingFilterTag: Tag = filterTagsMap.get(data);
      return new Tag({
        name: matchingFilterTag.name,
        label: data,
        attribute: filterRule.attribute,
        type: matchingFilterTag.type,
      });
    });
  }
}
