/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { JsonProperty, Serializable } from '@dpa/ui-common';

/**
 * Daily Email capacity Model Object
 *
 * @export
 * @class AutomationQuota
 */
@Serializable
export class AutomationQuota {
  @JsonProperty('usage')
  public usage: number = 0;

  @JsonProperty('quota')
  public quota: number = 0;

  @JsonProperty('type')
  public type: string = '';

  /**
   * Creates an instance of AutomationQuota.
   * @param {Array<Partial<AutomationQuota>>} args
   * @memberof AutomationQuota
   */
  constructor(...args: Array<Partial<AutomationQuota>>) {
    Object.assign(this, ...args);
  }

  /**
   * Getter to return the percentage of daily email capacity used
   * @readonly
   * @type {number}
   * @memberof AutomationQuota
   */
  public get percentage(): number {
    if (!this.quota) {
      return 0;
    }

    return Math.floor((this.usage / this.quota) * 100);
  }

  /**
   * Getter to return whether daily email capacity is reached
   * @readonly
   * @type {boolean}
   * @memberof AutomationQuota
   */
  public get isQuotaReached(): boolean {
    return this.percentage >= 100;
  }
}
